import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postRegistrationClient } from "../utils/api";

const TimerCode = (props) => {
  const ALL_SECOND = props.allSecond;
  const time = useMemo(() => props.time, [props.time]);
  // const minute = useMemo(() => Math.floor(time / 1000 / 60), [time]);
  const second = useMemo(() => Math.floor(time / 1000), [time]);

  const timeRef = useRef(time);
  const timerHandlerRef = useRef(null);
  const dispatch = useDispatch();

  function clenupTimer() {
    clearInterval(timerHandlerRef.current);
    props.setActiveTimer(false);
    props.setTime(ALL_SECOND);
  }

  useEffect(() => {
    timeRef.current = time;
    if (time < 1) {
      clenupTimer();
    }
  }, [time]);

  useEffect(() => {
    if (timeRef.current > 0 && props.activeTimer) {
      timerHandlerRef.current = setInterval(() => props.setTime(timeRef.current - 1000), 1000);
    }

    return () => {
      if (props.activeTimer) {
        clenupTimer();
      }
    };
  }, [props.activeTimer]);

  const addNullMin = (minute) => {
    minute = minute + "";
    if (minute.length < 2) {
      return "0" + minute;
    } else {
      return minute;
    }
  };

  return (
    <div className="numbers-timer">
      {props.activeTimer ? (
        <div className="send-again-text-cont">
          <span className="first-text-send-again">отправить заново</span>{" "}
          <span className="timer">{`(${addNullMin(second)})`}</span>
        </div>
      ) : (
        <div className="send-again-text-cont">
          <span className="second-text-send-again" onClick={() => props.onSendAgain()}>
            отправить заново
          </span>
        </div>
      )}
    </div>
  );
};
export default TimerCode;
