import { IonContent } from "@ionic/react";
import "../../less/css/feedback.css";
import { useEffect, useState } from "react";
import { FIELD_FOR_QUESTIONS, STATUSES_QUESTIONS } from "../../utils/array";
import Table from "../../ui/Table";
import ModalFilterQuestions from "../../ui/ModalsFilter/ModalFilterQuestions";
import ModalReviewsReport from "./ModalReviewsReport";
import ModalQuestionsDetails from "./ModalQuestionsDetails";
import { useSelector } from "react-redux";
import Toast from "../../ui/Toast";

const QuestionsContent = () => {
  const arrReviewsQuest = useSelector((state) => state.reviews);
  const questions = arrReviewsQuest.filter((el) => el.type === 1);

  const userPermissions = useSelector((state) => state.userPermissions.feedback?.questions);
  const columnsTable = useSelector((state) => state.columnsTable);

  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });

  const [focusElement, setFocusElement] = useState();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openModalReport, setOpenModalReport] = useState(false);
  const [openQuestionDetail, setOpenQuestionDetail] = useState(false);
  const [openMenuElem, setOpenMenuElem] = useState(false);

  const status = STATUSES_QUESTIONS;
  // const fieldArray = FIELD_FOR_QUESTIONS;
  const numberColumns = 7;
  const [fieldArray, setFieldArray] = useState();
  useEffect(() => {
    setFieldArray(JSON.parse(localStorage.getItem("column"))[numberColumns]);
  }, [columnsTable]);

  var arrayForExcel = [];

  for (let i = 0; i < questions?.length; i++) {
    let status =
      questions[i]?.status_id === 1 ? "Без ответа" : questions[i]?.status_id === 2 && "Обработан";
    let new_array = {
      Номер: questions[i]?.id,
      "Дата создания": questions[i]?.created_at,
      "Название тура": questions[i]?.product.name,
      Автор: `${questions[i]?.user?.last_name} ${questions[i]?.user.first_name} ${
        questions[i]?.user.middle_name ?? ""
      }`,
      "Телефон автора": questions[i]?.user?.phone,
      "Текст вопроса": questions[i]?.review_text,
      "Дата ответа": questions[i]?.response_date,
      "Текст ответа": questions[i]?.response_text,
      Статус: status,
    };
    arrayForExcel.push(new_array);
  }

  const menu = [
    {
      name: "Смотреть",
      icon: "design-personal-eye",
      click: (e) => {
        if (userPermissions[0]) {
          setOpenQuestionDetail(true);
          setOpenMenuElem(false);
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
      },
      status: [1, 2],
    },
    {
      name: "Пожаловаться",
      icon: "mood-sad",
      click: () => {
        if (userPermissions[2]) {
          setOpenModalReport(true);
          setOpenMenuElem(false);
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
      },
      status: [1, 2],
    },
  ];

  return (
    <>
      <IonContent className="page">
        <div className="questions-feedback-page">
          <div className="title">Вопросы</div>
          <Table
            onClick={() => {
              if (userPermissions[0]) {
                setOpenQuestionDetail(true);
              } else {
                setOpenToast(true);
                setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
              }
            }}
            openMenuElem={openMenuElem}
            setOpenMenuElem={setOpenMenuElem}
            openFilterModal={setOpenFilterModal}
            focus={setFocusElement}
            status={status}
            array={questions}
            header={fieldArray}
            numberColumns={numberColumns}
            menu={menu}
            permission={userPermissions}
            classNameTableSetting="questions"
            arrayForExcel={arrayForExcel}
            nameFile={`Таблица вопросов ${new Date().toLocaleDateString("ru")}`}
            searchPlaceholder="Поиск по названию, автору..."
          />
        </div>
      </IonContent>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
      <ModalFilterQuestions open={openFilterModal} onClose={setOpenFilterModal} />
      <ModalReviewsReport open={openModalReport} onClose={setOpenModalReport} />
      <ModalQuestionsDetails
        open={openQuestionDetail}
        onClose={setOpenQuestionDetail}
        element={focusElement}
        setFocusElement={setFocusElement}
        setOpenModalReport={setOpenModalReport}
      />
    </>
  );
};

export default QuestionsContent;
