import Slider from "../../ui/Slider";
import img1 from "../../image/temporary/1.png";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import backEmpty from "../../image/background-empty-table.svg";

const QuestionSummary = (props) => {
  const arrReviewsQuest = useSelector((state) => state.reviews);
  const questions = arrReviewsQuest?.filter((el) => el.type === 1 && el?.status_id === 1);
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div className="question-cont">
      <div className="title-cont">
        <div className="title">Новые вопросы</div>
        <div className="btn">
          <Button typebtn="outline" className="small cube" onClick={handlePrev}>
            <Icons icon="design-personal-chevron-left" />
          </Button>
          <Button typebtn="outline" className="small cube" onClick={handleNext}>
            <Icons icon="design-personal-chevron-right" />
          </Button>
        </div>
      </div>
      {questions.length ? (
        <Slider
          sliderRef={sliderRef}
          array={questions}
          type="question"
          slidesPerView={3.6}
          spaceBetween={56}
        />
      ) : (
        <div className="no-array">
          <img src={backEmpty} className="back-img" />
          <div className="content">
            <div className="text">Пока нет новых вопросов</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionSummary;
