import { useState, useMemo } from "react";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";

import "../less/css/custom.css";
import Icons from "./Icons";

const MultiSelectChip = (props) => {
  return (
    <div className="cont-select">
      <MultiSelect
        disabled={props.disabled}
        showSelectAll={false}
        showClear={false}
        emptyFilterMessage="Не найдено"
        emptyMessage="Не найдено"
        value={props.value}
        onChange={props.onChange}
        itemTemplate={props.itemTemplate}
        options={props.array}
        optionLabel={props.optionLabel || "name"}
        display={props.display || "chip"}
        placeholder={props.placeholder}
        maxSelectedLabels={props.maxSelectedLabels}
        className={`select ${props.className} ${!props.val ? "undef" : ""}`}
        dropdownIcon={<Icons icon="chevron-down" className={`icon-select ${props.typesel} `} />}
        // removeIcon={<Icons icon="close" className={`${props.typesel} `} />}
      />
    </div>
  );
};

export default MultiSelectChip;
