import "../../less/css/firstpage.css";
import { IonMenuToggle, IonModal } from "@ionic/react";
import { useMemo, useState } from "react";
import Close from "../../ui/Close";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import { getInitialDataAdmin } from "../../utils/api";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setModalLogin, setToken, setValCity } from "../../store/actions";
import LoginModal from "./LoginModal";
import Icons from "../../ui/Icons";
import SelectButton from "../../ui/SelectButton";

const HeaderFirstPage = (props) => {
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);

  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <>
      <div className={`header-first-page ${props.openQuickView.open ? "quick" : ""}`}>
        <div className="mobile-header">
          <Input
            onFocus={() => props.setOpenMobileSearch(true)}
            className="input-search-tour small"
            placeholder="Поиск на карте..."
            noImage={true}
          />
          <IonMenuToggle className="btn-menu-left" menu="menu-left">
            <Button className="small cube" typebtn="ghost">
              <Icons icon="menu" />
            </Button>
          </IonMenuToggle>
        </div>
        <div className="left-header">
          {!props.openQuickView.open && (
            <SelectButton
              value={props.valCity}
              array={props.arrayCity}
              className="elevated btn-top-first-page"
              onChange={(e) => {
                dispatch(setValCity(e.value));
              }}
            />
          )}
          {!props.openQuickView.open && (
            <SelectButton
              value={props.valDistance}
              array={props.arrayDistance}
              className="elevated btn-top-first-page"
              onChange={(e) => {
                props.setValDistance(e.value);
              }}
            />
          )}
        </div>
        <div className="right-header">
          <Button
            className="favourite medium "
            typebtn="elevated"
            onClick={() => history.push("/favorite")}
          >
            <Icons icon="heart" className="icon-elevated" />
          </Button>
          {token ? (
            <Button
              className="btn-login medium cube"
              typebtn="elevated"
              onClick={() => {
                if (token) {
                  if (user.operator_user) {
                    history.push("/partner/summary");
                  } else {
                    history.push("/client");
                  }
                }
              }}
            >
              <Icons icon="user" />
            </Button>
          ) : (
            <Button
              className="btn-login medium"
              typebtn="elevated"
              onClick={() => dispatch(setModalLogin({ open: true }))}
            >
              войти
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default HeaderFirstPage;
