import { useEffect, useMemo, useRef, useState } from "react";
import { MaskedInput } from "react-hook-mask";
import { InputMask } from "primereact/inputmask";

import Icons from "./Icons";
import Checkbox from "./Checkbox";
import { IonDatetime, IonDatetimeButton, IonModal } from "@ionic/react";
import { OverlayPanel } from "primereact/overlaypanel";

const Input = (props) => {
  const [valueInput, setValueInput] = useState(props.value ?? "");
  const [checkboxVal, setCheckboxVal] = useState();
  const [toggle, setToggle] = useState(props.toggle);
  const [focus, setFocus] = useState(false);
  const [drop, setDrop] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const invalid = useMemo(() => props.invalid, [props.invalid]);
  const [datetime, setDatetime] = useState(null);

  const op = useRef();
  useEffect(() => {
    if (props.value || props.value == 0) setValueInput(props.value);
  }, [props.value]);
  let filterArray = props.array?.filter((el) => el?.name?.includes(valueInput) === true);
  const mask = "+7 (XXX) XXX-XX-XX";
  const maskGenerator = {
    rules: new Map([["X", /\d/]]),
    generateMask: () => mask,
  };

  props.type === "select-checkbox" &&
    window.addEventListener("click", (e) => {
      const target = e.target;
      if (!target.closest(".dropdown") && !target.closest(".input")) {
        setDrop(false);
      }
    });
  return (
    <div
      id="dropdown"
      className={`cont-input ${props.className} ${focus ? "focus" : ""} ${
        props.disabled ? "disabled" : ""
      } ${invalid ? "invalid" : ""}`}
    >
      {props.type === "tel" ? (
        <MaskedInput
          disabled={props.disabled}
          name={props.name}
          className="input"
          maskGenerator={maskGenerator}
          value={valueInput}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
          onChange={(e) => {
            setValueInput(e);
            if (props.onChange) props.onChange(e);
          }}
          placeholder={props.placeholder}
        />
      ) : props.type === "email" ? (
        <input
          disabled={props.disabled}
          value={valueInput}
          name={props.name}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          className="input"
          placeholder={props.placeholder}
          onInput={(e) => (e.target.value = e.target.value.replace(/[^a-zA-Z0-9.@+-_]/g, ""))}
          onChange={(e) => {
            let value = e.target.value;
            setValueInput(value);
            if (props.onChange) props.onChange(value);
          }}
        />
      ) : props.type === "password" ? (
        toggle ? (
          <input
            disabled={props.disabled}
            value={valueInput}
            name={props.name}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            type="text"
            autoComplete="on"
            className={`input ${props.classNameInput}`}
            placeholder={props.placeholder}
            onChange={(e) => {
              let value = e.target.value;
              setValueInput(value);
              if (props.onChange) props.onChange(value);
            }}
          />
        ) : (
          <input
            disabled={props.disabled}
            value={valueInput}
            name={props.name}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            type="password"
            autoComplete="on"
            className="input"
            placeholder={props.placeholder}
            onChange={(e) => {
              let value = e.target.value;
              setValueInput(value);
              if (props.onChange) props.onChange(value);
            }}
          />
        )
      ) : props.type === "textarea" ? (
        <textarea
          disabled={props.disabled}
          // type={props.type}
          value={valueInput}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          name={props.name}
          className="textarea"
          placeholder={props.placeholder}
          onChange={(e) => {
            let value = e.target.value;
            setValueInput(value);
            if (props.onChange) props.onChange(value);
          }}
        />
      ) : props.type === "select-checkbox" ? (
        <>
          <input
            disabled={props.disabled}
            type={props.type}
            value={valueInput}
            onFocus={() => {
              setFocus(true);
              setDrop(true);
            }}
            // onBlur={() => setFocus(false)}
            name={props.name}
            className="input"
            placeholder={props.placeholder}
            onChange={(e) => {
              let value = e.target.value;
              setValueInput(value);
              if (props.onChange) props.onChange(value);
            }}
          />
          {drop && (
            <div className="dropdown">
              {filterArray.map((el, i) => {
                return (
                  <div
                    key={i}
                    className="cont-dropdown"
                    // onClick={() => {
                    //   props.onSelect([...props.select, el]);
                    // }}
                  >
                    <Checkbox
                      position="top"
                      name="create_tour"
                      htmlFor={`checkbox-${i + 1}`}
                      id={`checkbox-${i + 1}`}
                      checked={!!props.select?.find((w) => w.id === el.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          props.onSelect([...props.select, el]);
                        } else {
                          let arr = props.select;
                          let a = arr.indexOf(el.id);
                          arr.splice(a, 1);
                          props.onSelect([...arr]);
                        }
                      }}
                    >
                      <div className="cont-text">
                        <div className="text">{el.name}</div>
                        <div className="subtext">
                          {`${String(+el.basic_cost)?.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            " "
                          )} ₽ / ${+el.basic_volume} мест`}
                        </div>
                      </div>
                    </Checkbox>
                  </div>
                );
              })}
            </div>
          )}
        </>
      ) : props.type === "time" ? (
        <input
          disabled={props.disabled}
          type="time"
          value={valueInput}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          name={props.name}
          className="input time"
          placeholder={props.placeholder}
          onChange={(e) => {
            let value = e.target.value;
            setValueInput(value);
            if (props.onChange) props.onChange(value);
          }}
        />
      ) : props.type === "date" ? (
        // <input
        //   id="input"
        //   disabled={props.disabled}
        //   type="date"
        //   // type={focus || valueInput ? "date" : "text"}
        //   value={valueInput}
        //   onFocus={() => setFocus(true)}
        //   onBlur={() => setFocus(false)}
        //   name={props.name}
        //   // className={`input ${focus || valueInput ? "" : "date"}`}
        //   className={`input date`}
        //   // placeholder={props.placeholder}
        //   onChange={(e) => {
        //     console.log(e);
        //     let value = e.target.value;
        //     setValueInput(value);
        //     if (props.onChange) props.onChange(value);
        //   }}
        // />
        <>
          <InputMask
            id="input"
            disabled={props.disabled}
            name={props.name}
            // pattern={/^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)[0-9]{2}$/}
            // onFocus={(e) => op.current.toggle(e)}
            // onBlur={() => setFocus(false)}
            className={`input date`}
            value={valueInput}
            onChange={(e) => {
              var re = /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)[0-9]{2}$/;
              let value = e.target.value;
              if (re.test(value)) {
                setValueInput(value);
                let split = value.split(".");
                let format = new Date(+split[2], +split[1] - 1, +split[0]);
                if (
                  e.target.value &&
                  props.onChange &&
                  !e.target.value.includes("г") &&
                  !e.target.value.includes("д") &&
                  !e.target.value.includes("м")
                ) {
                  let timezone = new Date().getTimezoneOffset() * 60 * 1000;
                  let millesecond = format.getTime();
                  setDatetime(new Date(millesecond - timezone).toISOString());
                  props.onChange(format);
                }
              }
            }}
            mask="99.99.9999"
            placeholder={props.placeholder}
            slotChar="дд.мм.гггг"
          />
          <IonModal
            className="calendar"
            keepContentsMounted={true}
            isOpen={openPopover}
            onDidDismiss={() => setOpenPopover(false)}
          >
            <div className="cont-calendar">
              <IonDatetime
                id="datetime"
                presentation="date"
                firstDayOfWeek={1}
                showDefaultButtons={true}
                doneText="Принять"
                cancelText="Закрыть"
                locale="ru-RU"
                mode="ios"
                value={datetime}
                onIonChange={(e) => {
                  setValueInput(new Date(e.detail.value).toLocaleDateString("ru"));
                  setDatetime(null);
                  setOpenPopover(false);
                  props.onChange(e.detail.value);
                }}
              ></IonDatetime>
            </div>
          </IonModal>
          {/* <IonDatetime
            id="datetime"
            presentation="date"
            locale="ru-RU"
            value={valueInput}
          ></IonDatetime> */}
          {/* <OverlayPanel ref={op}>
          </OverlayPanel> */}
        </>
      ) : (
        <input
          autoFocus={props.autoFocus}
          disabled={props.disabled}
          type={props.type}
          value={valueInput}
          onFocus={() => {
            setFocus(true);
            if (props.onFocus) {
              props?.onFocus();
            }
          }}
          onBlur={() => {
            setFocus(false);
            if (props.onBlur) {
              props?.onBlur();
            }
          }}
          name={props.name}
          className="input"
          placeholder={props.placeholder}
          onChange={(e) => {
            let value = e.target.value;
            setValueInput(value);
            if (props.onChange) props.onChange(value);
          }}
        />
      )}

      {props.type === "date" && (
        <div className="cont-image" onClick={() => setOpenPopover(true)}>
          <Icons icon="calendar" className={props.classNameIcon} />
        </div>
      )}
      {props.noImage ? (
        <></>
      ) : props.type === "password" ? (
        <div className="cont-image" onClick={() => setToggle(!toggle)}>
          {toggle ? (
            <Icons icon="eye-off" className="icon-eye" />
          ) : (
            <Icons icon="eye" className="icon-eye" />
          )}
        </div>
      ) : (
        <div className="cont-image">
          <Icons icon={props.image} className={props.classNameIcon} />
        </div>
      )}
    </div>
  );
};

export default Input;
