import { IonModal } from "@ionic/react";
import Button from "../../../ui/Button";
import Close from "../../../ui/Close";
import { changeStatusProducts, getToursByID, getToursOperator } from "../../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { setProducts, setTourFocus } from "../../../store/actions";
import Toast from "../../../ui/Toast";
import { useState } from "react";

const ModalStartSelling = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  return (
    <>
      <IonModal
        className="modal-start-selling"
        isOpen={props.open}
        onWillDismiss={() => props.onClose(false)}
      >
        <Close onClick={() => props.onClose(false)} />
        <div className="body-modal-start-selling">
          <div className="title">Начать продажи</div>
          <div className="cont-text">
            <div className="text">
              Тур будет опубликован, вы сможете редактировать только расписание. Чтобы внести
              изменения в тур, потребуется перевести его в черновик и повторно пройти модерацию.
            </div>
          </div>
          <div className="btns">
            <Button typebtn="outline" className="small" onClick={() => props.onClose(false)}>
              Отмена
            </Button>
            <Button
              typebtn="bright"
              className="small"
              onClick={() => {
                if (props.element?.races.length) {
                  changeStatusProducts(token, props.element.id, 5).then(async (e) => {
                    if (e.data.errors) {
                    } else {
                      await getToursOperator(token).then((e) => {
                        if (e.data.data) {
                          dispatch(setProducts(e.data.data));
                        }
                      });
                      props.fromInfo &&
                        getToursByID(token, props.element.id).then((e) => {
                          dispatch(setTourFocus(e.data.data));
                        });
                    }
                  });
                } else {
                  setOpenToast(true);
                  setMessageToast({ text: "Нет актуальных дат", type: "warning" });
                }
                props.onClose(false);
              }}
            >
              Начать
            </Button>
          </div>
        </div>
      </IonModal>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </>
  );
};

export default ModalStartSelling;
