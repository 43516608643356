import { useState, useMemo, useRef, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import qs from "qs";

import "../less/css/custom.css";
import Icons from "./Icons";
import { AutoComplete } from "primereact/autocomplete";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import { useLocation } from "react-router";

const AutoCompleteSelect = (props) => {
  const location = useLocation();
  const ref = useRef(null);
  const array = useRef(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [filteredCities, setFilteredCities] = useState(null);
  const searchCity = (event) => {
    let query = event.query;
    let filteredItems = array.current?.filter(
      (item) => item.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    if (props.sleep) {
      if (event.query?.length < props.sleep) {
        setFilteredCities(null);
      } else {
        setFilteredCities(filteredItems);
      }
    } else {
      setFilteredCities(filteredItems);
    }
  };

  useAsyncEffect(() => {
    array.current = props.array;
  }, [props.array]);

  return (
    <div className="cont-autocomplete">
      <Icons
        icon={props.image ? `${props.image}` : props.noImage ? "" : `chevron-down`}
        className={`icon-select ${props.typesel} `}
      />

      <AutoComplete
        ref={ref}
        disabled={props.disabled}
        suggestions={filteredCities}
        completeMethod={searchCity}
        field={props.field || "name"}
        multiple={props.multiple}
        value={props.value}
        onBlur={props.onBlur}
        onKeyPress={props.onKeyPress}
        onChange={props.onChange}
        onClick={(e) => {
          ref.current?.search(e, "", "dropdown");
        }}
        options={props.array}
        itemTemplate={props.itemTemplate}
        placeholder={props.placeholder}
        className={`autocomplete ${props.className} ${!props.val ? "undef" : ""}`}
        loadingIcon={<svg className="load"></svg>}
      />
    </div>
  );
};

export default AutoCompleteSelect;
