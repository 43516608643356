export const DEFAULT_TOURS = {
  name: null,
  genres: null,
  product_class_id: null,
  product_duration_id: null,
  time_sales_stop: null,
  summa: null,
  available_places: null,
  number_adults: 1,
  number_children: 0,
  number_babies: 0,
  possible_invalid: 0,
  possible_elderly: 0,
  possible_animals: 0,
  user_id: null,
  return_percent: 25,
  time_after_purchase: 0,
  days_until_start: 0,
};

export const DEFAULT_OPTIONS = {
  basic_cost: null,
  basic_volume: null,
  description: null,
  name: null,
  status: 1,
};

export const DEFAULT_OPERATOR_USERS = {
  email: null,
  comment: null,
  checkbox: {
    showcase: {
      tours: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
      options: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
      promotions: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
    },
    finance: {
      orders: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
      operations: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
    },
    feedback: {
      reviews: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
      questions: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
      // support: {
      //   0: false,
      // },
    },
    settings: {
      company: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
      operator_users: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
    },
  },
};

export const DEFAULT_FAQ = {
  products: [],
  response_text: null,
  review_text: null,
  status_id: 2,
};

export const DEFAULT_POINT_TOURS = {
  name: null,
  description: null,
  order: null,
  lon1: null,
  lat1: null,
  point_type_id: null,
  // collect_time: null,
  // photo_point_type: null,
};

export const DEFAULT_PROMOTIONS = {
  name: null,
  start_date: null,
  end_date: null,
  description: null,
  color: null,
  type: null,
  status: null,
};

export const STATUSES_PROMOTIONS = [
  { name: "Активна", status: 1, color: "green" },
  { name: "Удалена", status: 2, color: "gray" },
];

export const STATUSES_TOURS = [
  { name: "Черновик", status: 1, color: "gray" },
  { name: "На модерации", status: 2, color: "orange" },
  { name: "Есть ошибки", status: 3, color: "red" },
  { name: "Готов к продаже", status: 4, color: "green" },
  { name: "Продаётся", status: 5, color: "green" },
  { name: "В архиве", status: 6, color: "gray" },
];

export const STATUSES_OPERATOR_USERS = [
  { name: "Активен", status: 1, color: "green" },
  { name: "Не активен", status: 2, color: "gray" },
  { name: "Удалён", status: 3, color: "gray" },
];

export const STATUSES_OPTIONS = [
  { name: "Не активна", status: 0, color: "gray" },
  { name: "Активна", status: 1, color: "green" },
];

export const STATUSES_ORDERS = [
  { name: "Не оплачен", status: 1, color: "gray" },
  { name: "Оплачен", status: 2, color: "gray" },
  { name: "Выплачен аванс", status: 3, color: "gray" },
  { name: "Заморожено", status: 4, color: "orange" },
  { name: "Отменён", status: 5, color: "red" },
  { name: "Завершён", status: 6, color: "green" },
  // { name: "Удалён", status: 7, color: "gray" },
];

export const STATUSES_OPERATIONS_HISTORY = [
  { name: "Успешно", status: 2, color: "green" },
  { name: "Ошибка", status: 3, color: "red" },
];

export const STATUSES_REVIEWS = [
  { name: "Без ответа", status: 1, color: "orange" },
  { name: "Обработан", name_full: "Обработанные", status: 2, color: "gray" },
];
export const STATUSES_QUESTIONS = [
  { name: "Без ответа", status: 1, color: "orange" },
  { name: "Обработан", name_full: "Обработанные", status: 2, color: "gray" },
];

export const STATUSES_FAQ = [
  { name: "Не опубликован", name_full: "Не опубликованные", status: 2, color: "gray" },
  { name: "Опубликован", name_full: "Опубликованные", status: 1, color: "green" },
];

export const FIELD_FOR_TOURS_RACES = [
  {
    field: ["start_date", "end_date"],
    name: "Даты",
    id: 1,
    type: "range",
    description: "Дата начала и окончания тура",
    disabled: true,
    default: true,
  },
  {
    field: "product",
    name: "Рейтинг",
    id: 4,
    type: "rate",
    description: "Рейтинг тура и количество отзывов",
    default: false,
  },
  {
    field: "duration",
    name: "Формат",
    id: 3,
    type: "duration",
    description: "Формат тура",
    default: false,
  },
  {
    field: "operator_options",
    name: "Опций",
    id: 2,
    type: "options",
    description: "Количество опций в день",
    default: true,
  },
  {
    field: "summa",
    name: "Цена",
    id: 5,
    type: "summa",
    description: "Цена одного места на день",
    default: true,
  },
  {
    field: "summa_discount",
    name: "Со скидкой",
    id: 6,
    type: "summa",
    description: "Финальная цена с учётом скидок на день",
    default: true,
  },
  {
    field: "number_travelers",
    name: "Мест",
    id: 7,
    type: "number_travelers",
    description: "Доступное количество заказов на день",
    default: true,
  },
  {
    field: "paid_travelers_count",
    name: "Занято",
    id: 8,
    type: "number_travelers",
    description: "Количество заказов (купленных мест)",
    default: true,
  },
  {
    field: "left",
    // field: "number_travelers",
    name: "Осталось",
    id: 9,
    type: "left",
    description: "Количество свободных мест",
    default: true,
  },
];

// export const FIELD_FOR_TOURS_RACES_OPTIONS = [

// ]

export const FIELD_FOR_TOURS = [
  {
    field: "photo",
    name: "Фото",
    id: 1,
    type: "image",
    description: "Фото обложка тура",
    noImage: true,
    default: true,
  },
  {
    field: "name",
    name: "Название",
    id: 2,
    type: "name",
    description: "Название тура",
    default: true,
  },
  {
    field: "duration",
    name: "Формат",
    id: 3,
    type: "child",
    description: "Формат тура",
    default: true,
  },
  {
    field: "summa",
    name: "Цена",
    id: 4,
    type: "price",
    description: "Стоимость тура по умолчанию",
    default: true,
  },
  {
    field: "available_places",
    name: "Мест",
    id: 5,
    type: "places",
    description: "Количество мест в туре по умолчанию",
    default: true,
  },
  {
    field: "created_at",
    name: "Создан",
    id: 6,
    type: "date",
    description: "Дата создания тура",
    disabled: true,
    default: true,
  },
  {
    field: "genres",
    name: "Жанр",
    id: 7,
    type: "badge",
    description: "Список жанров тура",
    noImage: true,
    default: false,
  },
  {
    field: "publication_date",
    name: "Опубликован",
    id: 8,
    type: "date",
    description: "Дата публикации тура",
    default: false,
  },
  {
    field: "rating",
    name: "Рейтинг",
    id: 9,
    type: "rate",
    description: "Суммарный рейтинг тура",
    default: false,
  },
  {
    field: "clients_reviews",
    name: "Отзывов",
    id: 10,
    type: "reviews",
    description: "Количество отзвывов",
    default: false,
  },
  {
    field: "favorites",
    name: "В избранном",
    id: 11,
    type: "number",
    description: "У скольких пользователей в избранном",
    default: false,
  },
  {
    field: "status",
    name: "Статус",
    id: 12,
    type: "status",
    description: "Текущий статус тура",
    disabled: true,
    default: true,
  },
];

export const FIELD_FOR_OPERATOR_USERS = [
  {
    field: "created_at",
    name: "Создан",
    id: 1,
    type: "date",
    description: "Дата создания учётной записи",
    disabled: true,
    default: true,
  },
  {
    field: "user",
    name: "ФИО",
    id: 2,
    type: "user_name",
    description: "ФИО сотрудника",
    disabled: true,
    default: true,
  },
  {
    field: "comment",
    name: "Примечание",
    noImage: true,
    id: 3,
    type: "comment",
    description: "Доп. информация о сотруднике",
    default: true,
  },
  {
    field: "user",
    name: "Email",
    id: 4,
    type: "user_email",
    description: "Email-адрес сотрудника",
    default: true,
  },
  {
    field: "operator_role",
    name: "Роль",
    id: 5,
    type: "text",
    description: "Роль в компании",
    default: true,
  },
  {
    field: "last_login",
    name: "Последний вход",
    id: 6,
    type: "date",
    description: "Дата и время последней авторизации",
    default: true,
  },
  {
    field: "status",
    name: "Статус",
    id: 7,
    type: "status",
    description: "Текущий статус аккаунта сотрудника",
    disabled: true,
    default: true,
  },
];

export const FIELD_FOR_OPTIONS = [
  {
    field: "pictures",
    name: "Фото",
    id: 1,
    type: "image",
    noImage: true,
    description: "Фото опции",
    default: true,
  },
  {
    field: "option",
    name: "Название",
    id: 2,
    type: "name_link",
    description: "Название опции",
    default: true,
  },
  {
    field: "basic_cost",
    name: "Цена",
    id: 3,
    type: "price",
    description: "Стоимость опции по умолчанию",
    default: true,
  },
  {
    field: "basic_volume",
    name: "Мест",
    id: 4,
    type: "places",
    description: "Количество мест в опции по умолчанию",
    default: true,
  },
  {
    field: "created_at",
    name: "Создан",
    id: 5,
    type: "date",
    description: "Дата создания опции",
    default: true,
    disabled: true,
  },
  {
    field: "status",
    name: "Статус",
    id: 6,
    type: "status",
    description: "Текущий статус опции",
    default: true,
    disabled: true,
  },
];

export const FIELD_FOR_ORDERS = [
  {
    field: "id",
    name: "№",
    id: 1,
    type: "id",
    description: "Порядковый номер заказа",
    default: true,
    disabled: true,
  },
  {
    field: "created_at",
    name: "Создан",
    id: 2,
    type: "date",
    description: "Дата создания заказа",
    default: true,
    disabled: true,
  },
  {
    field: "client",
    name: "Клиент",
    id: 3,
    type: "client_orders",
    description: "Имя и телефон покупателя",
    default: true,
  },
  {
    field: "product",
    name: "Название тура",
    id: 4,
    type: "child",
    description: "Название тура на момент создания заказа",
    default: true,
  },
  {
    field: "race",
    name: "Старт",
    id: 5,
    type: "weekday_start",
    description: "Дата начала тура",
    default: true,
  },
  {
    field: "race",
    name: "Завершение",
    id: 6,
    type: "weekday_end",
    description: "Дата завершения тура",
    default: true,
  },
  {
    field: "summa",
    name: "Оплачено",
    id: 7,
    type: "price",
    description: "Фактически уплаченная клиентом сумма",
    default: true,
  },
  {
    field: "commission_value",
    name: "Комиссия",
    id: 8,
    type: "comission",
    description: "Процент комиссии сервиса",
    default: true,
  },
  {
    field: "penalty",
    name: "Штраф",
    id: 9,
    type: "penalty",
    description: "Сумма штрафа при нарушении / отмене",
    default: true,
  },
  {
    field: "summa",
    name: "К выплате",
    id: 10,
    type: "price_topay",
    description: "Итоговая сумма к выплате со стороны сервиса",
    default: true,
  },
  // {
  //   field: "advance",
  //   name: "Выплачен аванс",
  //   id: 11,
  //   type: "price",
  //   description: "Сумма выплаченного аванса по заказу",
  // },
  {
    field: "participants",
    name: "Участников",
    id: 12,
    type: "participants",
    description: "Количество участников в заказе",
    default: false,
  },
  {
    field: "race",
    name: "Стоимость тура",
    id: 13,
    type: "price_race",
    description: "На момент создания заказа без скидки",
    default: false,
  },
  {
    field: "operator_options",
    name: "Опций",
    id: 14,
    type: "options",
    description: "Количество всех выбранных клиентом опций",
    default: false,
  },
  {
    field: "status",
    name: "Статус",
    id: 15,
    type: "status",
    description: "Текущий статус заказа",
    default: true,
    disabled: true,
  },
];

export const FIELD_FOR_OPERATIONS_HISTORY = [
  {
    field: "id",
    name: "№",
    id: 1,
    type: "id",
    description: "Порядковый номер заказа",
    default: true,
    disabled: true,
  },
  {
    field: "created_at",
    name: "Создан",
    id: 2,
    type: "date",
    description: "Дата создания операции",
    default: true,
    disabled: true,
  },
  {
    field: "type",
    name: "Тип",
    id: 3,
    type: "type_operations",
    description: "Тип операции",
    default: true,
  },
  {
    field: "summa",
    name: "Сумма",
    id: 4,
    type: "price",
    description: "Итоговая сумма к выплате на р/счёт",
    default: true,
  },
  {
    field: "status",
    name: "Статус",
    id: 5,
    type: "status",
    description: "Текущий статус операции",
    default: true,
    disabled: true,
  },
];

export const FIELD_FOR_REVIEWS = [
  {
    field: "id",
    name: "№",
    id: 1,
    type: "id",
    description: "Порядковый номер отзыва",
    default: true,
    disabled: true,
  },
  {
    field: "created_at",
    name: "Создан",
    id: 2,
    type: "date",
    description: "Дата создания отзыва",
    default: true,
    disabled: true,
  },
  {
    field: "tour_assessment",
    name: "Оценка",
    id: 3,
    type: "rate-rev",
    description: "Оценка тура от 1 до 5",
    default: true,
  },
  {
    field: "product",
    name: "Название тура",
    id: 4,
    type: "child",
    description: "Название тура, к которому оставлен отзыв",
    default: true,
  },
  {
    field: "user",
    name: "Автор",
    id: 5,
    type: "client_reviews",
    description: "Имя и телефон автора отзыва",
    default: true,
  },
  {
    field: "pictures",
    name: "Фото",
    id: 6,
    type: "photo_val",
    description: "Количество фотографий в отзыве",
    default: true,
  },
  {
    field: "video",
    name: "Видео",
    id: 7,
    type: "text",
    description: "Количество видео в отзыве",
    default: true,
  },
  {
    field: "review_text",
    name: "Текст отзыва",
    id: 8,
    type: "description",
    description: "Текстовое содержание отзыва",
    default: true,
  },
  {
    field: "response_date",
    name: "Дата ответа",
    id: 9,
    type: "date",
    description: "Дата ответа на отзыв",
    default: false,
  },
  {
    field: "response_text",
    name: "Текст ответа",
    id: 10,
    type: "description",
    description: "Текстовое содержание ответа на отзыв",
    default: false,
  },
  {
    field: "status_id",
    name: "Статус",
    id: 11,
    type: "status",
    description: "Текущий статус отзыва",
    default: true,
    disabled: true,
  },
];

export const FIELD_FOR_QUESTIONS = [
  {
    field: "id",
    name: "№",
    id: 1,
    type: "id",
    description: "Порядковый номер вопроса",
    default: true,
    disabled: true,
  },
  {
    field: "created_at",
    name: "Создан",
    id: 2,
    type: "date",
    description: "Дата создания вопроса",
    default: true,
    disabled: true,
  },
  {
    field: "product",
    name: "Название тура",
    id: 3,
    type: "child",
    description: "Название тура, к которому оставлен вопрос",
    default: true,
  },
  {
    field: "user",
    name: "Автор",
    id: 4,
    type: "client_reviews",
    description: "Имя и телефон автора вопроса",
    default: true,
  },
  {
    field: "review_text",
    name: "Вопрос",
    id: 5,
    type: "description",
    description: "Текст вопроса",
    default: true,
  },
  {
    field: "response_date",
    name: "Дата ответа",
    id: 6,
    type: "date",
    description: "Дата ответа на вопрос",
    default: false,
  },
  {
    field: "response_text",
    name: "Ответ",
    id: 7,
    type: "description",
    description: "Текст ответа на вопрос",
    default: false,
  },
  {
    field: "status_id",
    name: "Статус",
    id: 8,
    type: "status",
    description: "Текущий статус вопроса",
    default: true,
    disabled: true,
  },
];

export const FIELD_FOR_FAQ = [
  {
    field: "id",
    name: "№",
    id: 1,
    type: "id",
    description: "Порядковый номер вопроса",
    default: true,
    disabled: true,
  },
  {
    field: "created_at",
    name: "Создан",
    id: 2,
    type: "date",
    description: "Дата создания вопроса",
    default: true,
    disabled: true,
  },
  {
    field: "products",
    name: "В турах",
    id: 3,
    type: "tooltip",
    description: "Количество туров, в которых включён вопрос",
    default: true,
  },
  {
    field: "review_text",
    name: "Вопрос",
    id: 4,
    type: "description",
    description: "Текст вопроса",
    default: true,
    disabled: true,
  },
  {
    field: "response_text",
    name: "Ответ",
    id: 5,
    type: "description",
    description: "Текст ответа",
    default: true,
  },
  {
    field: "status_id",
    name: "Статус",
    id: 6,
    type: "status",
    description: "Текущий статус вопроса",
    default: true,
    disabled: true,
  },
];

export const COLORS_PROMOTIONS = [
  { color: "#FFCECE", id: 1 },
  { color: "#FFD390", id: 2 },
  { color: "#CFE37D", id: 3 },
  { color: "#9BEE87", id: 4 },
  { color: "#91EDB6", id: 5 },
  { color: "#98E7E7", id: 6 },
  { color: "#C3DBFF", id: 7 },
  { color: "#DDD4FF", id: 8 },
  { color: "#EED0F5", id: 9 },
  { color: "#F8CEE7", id: 10 },
];

export const ARRAY_CITY = [
  {
    name: "Москва",
    lon: 55.755824,
    lat: 37.617785,
  },
  {
    name: "Санкт-Петербург",
    lon: 59.949984,
    lat: 30.316677,
  },
  {
    name: "Новосибирск",
    lon: 55.016648,
    lat: 82.916667,
  },
  {
    name: "Екатеринбург",
    lon: 56.8333044,
    lat: 60.583333,
  },
  {
    name: "Казань",
    lon: 55.790832,
    lat: 49.114445,
  },
  {
    name: "Нижний Новгород",
    lon: 56.326938,
    lat: 44.0075,
  },
  {
    name: "Красноярск",
    lon: 56.011914,
    lat: 92.871389,
  },
  {
    name: "Челябинск",
    lon: 55.150141,
    lat: 61.399678,
  },
  {
    name: "Самара",
    lon: 53.183282,
    lat: 50.116667,
  },
  {
    name: "Уфа",
    lon: 54.733331,
    lat: 55.966667,
  },
];
