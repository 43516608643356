import { IonModal } from "@ionic/react";
import Select from "../Select";
import Input from "../Input";
import Button from "../Button";
import Close from "../Close";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import qs from "qs";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import MultiSelectChip from "../MultiSelectChip";
import { STATUSES_OPERATIONS_HISTORY } from "../../utils/array";

const ModalFilterReviews = (props) => {
  const history = useHistory();
  const urlSearch = qs.parse(history.location.search.substr(1));
  // const orders = useSelector((state) => state.orders);
  // orders?.forEach((el) => {
  //   el.name = `${el?.client?.user?.first_name} ${el?.client?.user?.last_name}`;
  // });

  // const res = orders?.reduce((o, i) => {
  //   if (!o.find((v) => v?.user_id == i?.user_id)) {
  //     o.push(i);
  //   }
  //   return o;
  // }, []);

  const DEFAULT = {
    rate_from: "",
    rate_until: "",
    photo_from: "",
    photo_until: "",
    video_from: "",
    video_until: "",
    response_from: "",
    response_until: "",
  };
  const [filterElements, setFilterElements] = useState(DEFAULT);

  const removeEmpty = (obj) =>
    Object.entries(obj)
      .filter(([, v]) => v != null && v !== "")
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

  const func = (obj1, obj2) => {
    let result = {};
    for (let prop in obj1) {
      if (
        obj2.hasOwnProperty(prop) ||
        prop === "page" ||
        prop === "date_from" ||
        prop === "date_until" ||
        prop === "name_client"
      )
        result[prop] = obj1[prop];
    }
    return result;
  };

  const onApply = () => {
    let data = urlSearch;
    let array = filterElements;
    let filter = removeEmpty(array);
    data = func(data, filter);
    let merge = Object.assign(data, filter);
    merge.page = 1;

    const params = new URLSearchParams(merge);
    history.push(`?${params.toString()}`);
    props.onClose(false);
  };
  useEffect(() => {
    setFilterElements(urlSearch);
  }, [props.open]);

  return (
    <IonModal
      className="filter-table-reviews"
      isOpen={props.open}
      onWillDismiss={() => {
        props.onClose(false);
        setFilterElements(DEFAULT);
      }}
    >
      <div className="body-modal">
        <Close
          onClick={() => {
            props.onClose(false);
            setFilterElements(DEFAULT);
          }}
        />
        <div className="title">Фильтры</div>
        <div className="body">
          <div className="line">
            <Input
              value={urlSearch?.rate_from ? filterElements?.rate_from : ""}
              name="rate_from"
              className="small input-short"
              placeholder="оценка от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, rate_from: e });
              }}
            />
            <Input
              value={urlSearch?.rate_until ? filterElements?.rate_until : ""}
              name="rate_until"
              className="small input-short"
              placeholder="оценка до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, rate_until: e });
              }}
            />
          </div>
          <div className="line">
            <Input
              value={urlSearch?.photo_from ? filterElements?.photo_from : ""}
              name="photo_from"
              className="small input-short"
              placeholder="фото от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, photo_from: e });
              }}
            />
            <Input
              value={urlSearch?.photo_until ? filterElements?.photo_until : ""}
              name="photo_until"
              className="small input-short"
              placeholder="фото до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, photo_until: e });
              }}
            />
          </div>
          <div className="line">
            <Input
              value={urlSearch?.video_from ? filterElements?.video_from : ""}
              name="video_from"
              className="small input-short"
              placeholder="видео от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, video_from: e });
              }}
            />
            <Input
              value={urlSearch?.video_until ? filterElements?.video_until : ""}
              name="video_until"
              className="small input-short"
              placeholder="видео до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, video_until: e });
              }}
            />
          </div>
          <div className="line ">
            <Input
              value={
                urlSearch?.response_from
                  ? filterElements?.response_from &&
                    new Date(+filterElements?.response_from).toLocaleDateString()
                  : ""
              }
              type="date"
              name="response_from"
              className="small input-short"
              placeholder="дата ответа от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, response_from: new Date(e).getTime() });
              }}
            />

            <Input
              value={
                urlSearch?.response_until
                  ? filterElements?.response_until &&
                    new Date(+filterElements?.response_until).toLocaleDateString()
                  : ""
              }
              type="date"
              name="response_until"
              className="small input-short"
              placeholder="дата ответа до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, response_until: new Date(e).getTime() });
              }}
            />
          </div>
        </div>
        <div className="buttons">
          <Button
            className="small"
            typebtn="outline"
            onClick={async () => {
              // props.onClose(false)
              // setFilterStatus(null);
              setFilterElements(DEFAULT);
              let data = urlSearch;
              let newd = {};
              newd.page = 1;
              if (data?.name_client) newd.name_client = data?.name_client;
              if (data?.date_from) newd.date_from = data?.date_from;
              if (data?.date_until) newd.date_until = data?.date_until;
              const params = new URLSearchParams(newd);
              history.push(`?${params.toString()}`);
            }}
          >
            Сбросить
          </Button>
          <Button
            className="small"
            typebtn="bright"
            onClick={() => {
              onApply();
            }}
          >
            Применить
          </Button>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalFilterReviews;
