import Button from "../../ui/Button";
import "../../less/css/client-profile.css";
// import ProfileInf from "./ProfileInf";
// import NotificationSettings from "./NotificationSettings";
import { IonContent } from "@ionic/react";
import { postLogout, postUpdateClient } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { setProfile, setToken } from "../../store/actions";
import { useState } from "react";
import Footer from "../../ui/Footer";
import Input from "../../ui/Input";
import AlertBox from "../../ui/AlertBox";
import Icons from "../../ui/Icons";
import ModalChangePassword from "../profile/ModalChangePassword";

import ModalPin from "../showcase/Modals/ModalPin";

export let globalinfo;

const ClientProfileContent = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const profile = useSelector((state) => state.profile);
  const [email, setEmail] = useState();
  const [changeEmail, setChangeEmail] = useState(false);
  const [alertEmail, setAlertEmail] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);

  const [modalPin, setmodalPin] = useState(false);

  const checkEmail = email
    ?.toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  let currentPhone = profile.phone;

  return (
    <IonContent className="page-guest">
      <div className="profile-client">
        <div className="body-prof">
          <div className="upper-line">
            <div className="title">Мой профиль</div>
          </div>
          <div className="lower-line">
            <form
              id="profile-inf-client"
              className="profile-inf"
              onSubmit={async (e) => {
                e.preventDefault();
                // setLoading(true);
                let documentInfo = {};
                let inputs = e.target.querySelectorAll("input");
                inputs.forEach(
                  (input) => (documentInfo[input.name] = input.value ? input.value : null)
                );
                let forSend = {
                  first_name: documentInfo.first_name,
                  last_name: documentInfo.last_name,
                  middle_name: documentInfo.middle_name,
                  email: documentInfo.email,
                  birth_date: documentInfo.birth_date,
                };
                if (profile?.phone?.replace(/\D/g, "") !== documentInfo.phone.replace(/\D/g, "")) {
                  forSend.phone = documentInfo.phone.replace(/\D/g, "");
                }

                globalinfo = forSend;
                console.log('globalinfo', globalinfo);

                if ((forSend.phone != null) && (currentPhone != forSend.phone)) {
                  postUpdateClient(token, forSend).then((e) => {
                    if (!e.data.errors) {
                      dispatch(setProfile(e.data.user));
                      changeEmail && setAlertEmail(true);
                    }
                  });
                  setmodalPin(true);
                  console.log('MODALMODALMODAL');
                } else {
                  postUpdateClient(token, forSend).then((e) => {
                    if (!e.data.errors) {
                      dispatch(setProfile(e.data.user));
                      changeEmail && setAlertEmail(true);
                    }
                  });
                  console.log('NETMODALNETMODALNETMODAL');
                }


                // if (pass && reppass && pass === reppass) {
                //   let arr = {
                //     password: pass,
                //     password_confirmation: reppass,
                //   };
                //   // postChangePassword(token, arr).then((e) => {
                //   //   console.log(e);
                //   // });
                // }
              }}
            >
              <div className="line-input">
                <div className="text">Фамилия</div>
                <Input
                  value={profile?.last_name}
                  name="last_name"
                  className="input-profile large"
                  placeholder="Фамилия"
                  noImage={true}
                />
              </div>
              <div className="line-input">
                <div className="text">Имя</div>
                <Input
                  value={profile?.first_name}
                  name="first_name"
                  className="input-profile large"
                  placeholder="Имя"
                  noImage={true}
                />
              </div>

              <div className="line-input">
                <div className="cont-text-descript">
                  <div className="text-disc">Отчество</div>
                  <div className="dop-text-disc">не обязательно</div>
                </div>
                <Input
                  value={profile?.middle_name}
                  name="middle_name"
                  className="input-profile large"
                  placeholder="Отчество"
                  noImage={true}
                />
              </div>

              <div className="line-input">
                <div className="text">Email</div>
                <Input
                  value={profile?.email}
                  name="email"
                  type="email"
                  className="input-profile large"
                  placeholder="company@mail.com"
                  noImage={true}
                  onChange={(e) => {
                    setEmail(e);
                    setChangeEmail(true);
                  }}
                />
              </div>
              {alertEmail && (
                <div className="alert-place">
                  <div className="repeat-cont">
                    <AlertBox
                      onClick={() => setChangeEmail(false)}
                      type="warning"
                      title={"Email был изменён"}
                      className="alert-profile-email"
                      text="Необходимо подтвердить изменения по ссылке, которую мы отправили на новый email"
                    />
                    <Button
                      className="small repeat-message"
                      typebtn="outline"
                      type="sumbit"
                      form="profile-inf-client"
                    >
                      <Icons icon="refresh" className="image icon-outline" />
                      <div>Выслать повторно</div>
                    </Button>
                  </div>
                </div>
              )}
              <div className="line-input">
                <div className="text">Телефон</div>
                <Input
                  value={profile?.phone}
                  name="phone"
                  className="input-profile large"
                  placeholder="+7 (___) ___-__-__"
                  noImage={true}
                  type="tel"
                />
              </div>
              <div className="line-input">
                <div className="text">Дата рождения</div>
                <Input
                  value={
                    profile?.birth_date ? new Date(profile?.birth_date).toLocaleDateString() : null
                  }
                  name="birth_date"
                  className="input-profile large"
                  placeholder="Дата рождения"
                  noImage={true}
                  type="date"
                />
              </div>
            </form>
            <Button
              loading={loading}
              disabled={loading}
              type="sumbit"
              form="profile-inf-client"
              className="btn-save medium "
              typebtn="bright"
            >
              Сохранить
            </Button>

            {/* <div className="profile-inf">
              <div className="line-input">
                <div className="text">Новый пароль</div>
                <Input
                  // name="password"
                  type="password"
                  toggle={false}
                  className="input-profile large"
                  placeholder="введите пароль..."
                  onChange={(e) => setPass(e)}
                />
              </div>
              <div className="line-input">
                <div className="text">Новый пароль ещё раз</div>
                <Input
                  // name="repeat-password"
                  type="password"
                  className="input-profile large"
                  placeholder="введите пароль..."
                  noImage={true}
                  onChange={(e) => setReppass(e)}
                />
              </div>
            </div> */}
          </div>
          <div className="cont-btn">
            <Button
              className="btn-logout medium"
              typebtn="outline"
              onClick={() => {
                setOpenChangePassword(true);
              }}
            >
              Изменить пароль
            </Button>
            <Button
              className="btn-logout medium"
              typebtn="outline"
              onClick={() => {
                postLogout(token)
                  .then((e) => {
                    dispatch(setToken(null));
                    localStorage.removeItem("token");
                    localStorage.removeItem("userInfo");
                    localStorage.removeItem("column");

                    history.push("/");
                    window.location.reload();
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }}
            >
              Выйти из аккаунта
            </Button>
          </div>
        </div>
        <Footer />
      </div>
      <ModalChangePassword
        open={openChangePassword}
        onClose={() => {
          setOpenChangePassword(false);
        }}
      />
      <ModalPin
        open={modalPin}
        onClose={() => {
          setmodalPin(false);
        }}
      />
    </IonContent>
  );
};

export default ClientProfileContent;
