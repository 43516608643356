import { IonModal } from "@ionic/react";
import { useMemo, useState } from "react";
import Close from "../../ui/Close";
import Toggle from "../../ui/Toggle";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { getFAQ, getFAQbyID, postFAQ, putFAQ } from "../../utils/api";
import { setFAQ, setFormFAQ } from "../../store/actions";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import Checkbox from "../../ui/Checkbox";
import { DEFAULT_FAQ } from "../../utils/array";

const ModalFAQDetails = (props) => {
  const [activeTabs, setActiveTabs] = useState(1);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const products = useSelector((state) => state.products);
  const productsForFAQ = products.filter((el) => !el.deleted_at === true);
  const formFAQ = useSelector((state) => state.formFAQ);
  useAsyncEffect(async () => {
    props.element &&
      (await getFAQbyID(token, props.element?.id).then((el) => {
        if (!el.data.errors) {
          dispatch(setFormFAQ(el.data.data));
        }
      }));
  }, [props.element]);

  const onSave = async () => {
    if (formFAQ.id) {
      let arrProd = [];
      for (let i = 0; i < formFAQ?.products?.length; i++) {
        formFAQ?.products[i].id
          ? arrProd.push(formFAQ?.products[i].id)
          : arrProd.push(formFAQ?.products[i]);
      }
      let arr = {
        response_text: formFAQ?.response_text,
        review_text: formFAQ?.review_text,
        status_id: arrProd?.length ? formFAQ?.status_id : 2,
        products: arrProd,
        product_id: arrProd.length ? arrProd[0] : productsForFAQ[0]?.id,
        type: 2,
      };
      delete arr.response_date;
      await putFAQ(token, formFAQ.id, arr).then(async (e) => {
        if (!e.data.errors) {
          await getFAQ(token).then((e) => {
            dispatch(setFAQ(e.data.data));
          });
          props.onClose(false);
          dispatch(setFormFAQ(DEFAULT_FAQ));
          props.setFocusElement(null);
        }
      });
    } else {
      let arr = {
        ...formFAQ,
        status_id: formFAQ?.products?.length ? formFAQ?.status_id : 2,
        type: 2,
        product_id: formFAQ?.products?.length ? formFAQ?.products?.[0] : productsForFAQ[0]?.id,
      };
      await postFAQ(token, arr).then(async (e) => {
        if (!e.data.errors) {
          await getFAQ(token).then((e) => {
            dispatch(setFAQ(e.data.data));
          });
          props.onClose(false);
          dispatch(setFormFAQ(DEFAULT_FAQ));
          props.setFocusElement(null);
        }
      });
    }
  };

  return (
    <IonModal
      className="modal-faq-details"
      isOpen={props.open}
      onWillDismiss={() => {
        props.onClose(false);
        dispatch(setFormFAQ(DEFAULT_FAQ));
        props.setFocusElement(null);
        setActiveTabs(1);
      }}
    >
      <Close
        onClick={() => {
          props.onClose(false);
          dispatch(setFormFAQ(DEFAULT_FAQ));
          props.setFocusElement(null);
          setActiveTabs(1);
        }}
      />
      <div className="body-faq-details">
        <div className="title">Частый вопрос</div>
        <div className="stages">
          <div
            className={`stage ${activeTabs === 1 ? "active" : ""}`}
            onClick={() => setActiveTabs(1)}
          >
            <div className="text-stage">Параметры</div>
          </div>
          <div
            className={`stage ${activeTabs === 2 ? "active" : ""}`}
            onClick={() => setActiveTabs(2)}
          >
            <div className="text-stage">В турах</div>
            <div className="val-stage">{formFAQ?.products?.length}</div>
          </div>
        </div>
        {activeTabs === 1 ? (
          <div className="cont-inputs">
            <Toggle
              name="status"
              checked={formFAQ?.status_id === 1 ? true : false}
              className="toggle-create-faq"
              text="Опубликован"
              onChange={(e) => {
                dispatch(
                  setFormFAQ({
                    ...formFAQ,
                    status_id: e.detail.checked === true ? 1 : 2,
                  })
                );
              }}
            />
            <div className="block-input">
              <div className="title-input">Вопрос</div>
              <Input
                value={formFAQ?.review_text || ""}
                placeholder="Текст частого вопроса..."
                noImage
                className="large area area-faq"
                type="textarea"
                onChange={(e) => {
                  dispatch(
                    setFormFAQ({
                      ...formFAQ,
                      review_text: e,
                    })
                  );
                }}
              />
            </div>
            <div className="block-input">
              <div className="title-input">Ответ</div>
              <Input
                value={formFAQ?.response_text || ""}
                placeholder="Текст ответа..."
                noImage
                className="large area area-faq"
                type="textarea"
                onChange={(e) => {
                  dispatch(
                    setFormFAQ({
                      ...formFAQ,
                      response_text: e,
                    })
                  );
                }}
              />
            </div>
          </div>
        ) : (
          <div className="array-products">
            {productsForFAQ?.map((el, i) => {
              let searchCheck = formFAQ?.products.find((q) => el.id === q.id);
              return (
                <Checkbox
                  checked={searchCheck}
                  key={i}
                  id={"checkbox"}
                  className="checkbox"
                  position="top"
                  onChange={(e) => {
                    if (e.target.checked) {
                      dispatch(setFormFAQ({ ...formFAQ, products: [...formFAQ.products, el.id] }));
                    } else {
                      let arr = formFAQ?.products;
                      let a = arr.indexOf(el.id);
                      arr.splice(a, 1);
                      dispatch(setFormFAQ({ ...formFAQ, products: [...arr] }));
                    }
                  }}
                >
                  <div className="cont-text">
                    <div className="text">{el.name}</div>
                    <div className="subtext">{el.duration?.name}</div>
                  </div>
                </Checkbox>
              );
            })}
          </div>
        )}
        <div className="btns">
          <Button
            typebtn="outline"
            className="small"
            onClick={() => {
              props.onClose(false);
              dispatch(setFormFAQ(DEFAULT_FAQ));
              props.setFocusElement(null);
              // if (formFAQ?.id) {
              //   let cbs = document.querySelectorAll("#checkbox");
              //   cbs.forEach((cb) => (cb.checked = false));

              //   // let index = inputArr.map(el => el.name).indexOf(searchName);

              //   console.log(props.element);
              //   dispatch(setFormFAQ({ ...props.element }));
              // } else {
              //   dispatch(setFormFAQ(DEFAULT_FAQ));
              //   let cbs = document.querySelectorAll("#checkbox");
              //   cbs.forEach((cb) => (cb.checked = false));
              // }
            }}
          >
            {/* Сбросить */}
            Отмена
          </Button>
          <Button
            // loading={loading}
            // disabled={loading}
            typebtn="bright"
            className={`small`} // ${loading ? "disabled" : ""}
            onClick={() => {
              onSave();
              // setLoading(true);
            }}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalFAQDetails;
