import "../less/css/custom.css";
import OK from "../image/social/OK.svg";
import VK from "../image/social/VK.svg";
import Telegram from "../image/social/Telegram.svg";

import visa from "../image/social/visa.svg";
import mir from "../image/social/mir.svg";
import mastercard from "../image/social/mastercard.svg";

import Icons from "./Icons";
import { setModalLoginPartner } from "../store/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

const Footer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div className="footer">
      <div className="cont-footer">
        <div className="first-line">
          <div className="left">
            <div
              className="text"
              onClick={() => {
                history.push("/");
                // window.location.reload();
              }}
            >
              Поиск тура
            </div>
            <div className="text" onClick={() => history.push("/partners")}>
              Партнёрам
            </div>
            <div className="text" onClick={() => history.push("/about")}>
              О компании
            </div>
          </div>
          <div className="right">
            <div className="text-muted">{`8 (800) 900-00-00`}</div>
            <div className="text-muted">help@smorodina.ru</div>
            <div className="social">
              <img src={VK} />
              <img src={OK} />
              <img src={Telegram} />
            </div>
          </div>
        </div>
        <div className="second-line">
          <div className="left">
            <div className="top">
              <div className="text">© 2023 ООО «КОПАТ»</div>
              <div className="text">Оферта</div>
              <div className="text" onClick={() => history.push("/information")}>
                Политика конфиденциальности
              </div>
              <div className="text">Согласие на обработку данных</div>
              <div className="text" onClick={() => dispatch(setModalLoginPartner({ open: true }))}>
                Вход для партнёров
              </div>
            </div>
            <div className="bottom">
              Полное или частичное копирование изображений и текстов возможно только с указанием
              активной ссылки на сайт smorodina.com
            </div>
          </div>
          <div className="right">
            <img src={visa} />
            <img src={mastercard} />
            <img src={mir} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
