import { IonModal } from "@ionic/react";
import Button from "../../../ui/Button";
import Close from "../../../ui/Close";
import Input from "../../../ui/Input";
import Icons from "../../../ui/Icons";
import Toggle from "../../../ui/Toggle";
import {
  deleteImageOptions,
  getOptions,
  getOptionsDirectory,
  postImageOptions,
  postOptions,
  postOptionsDirectory,
  putOptions,
} from "../../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import ModalDelete from "./ModalDelete";
import { setFormForCreateOption, setOptions, setOptionsDirectory } from "../../../store/actions";
import { DEFAULT_OPTIONS } from "../../../utils/array";
import Toast from "../../../ui/Toast";
import AutoCompleteSelect from "../../../ui/AutoCompleteSelect";
import { useAsyncEffect } from "../../../hooks/useAsyncEffect";
import { URL_ORIGIN } from "../../../utils/config";

const ModalCreateOptions = (props) => {
  const [loading, setLoading] = useState(false);
  const userPermissions = useSelector((state) => state.userPermissions.showcase?.options);
  const operator = useSelector((state) => state.operator);
  const optionsDirectory = useSelector((state) => state.optionsDirectory);
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  const [name, setName] = useState();
  const formForCreateOption = useSelector((state) => state.formForCreateOption);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [optionPhoto, setOptionPhoto] = useState();
  const [modalDeleteOperUser, setModalDeleteOperUser] = useState(false);
  const [typePhoto, setTypePhoto] = useState();

  useAsyncEffect(async () => {
    let a = await optionsDirectory?.find((el) => el?.id === formForCreateOption?.option?.id);
    setName(a);
  }, [formForCreateOption.option]);

  const onSave = async () => {
    if (formForCreateOption?.id) {
      let options = formForCreateOption;
      let findName = optionsDirectory?.find((q) => q.name === options?.name);
      delete options.pictures;
      delete options.promotions;
      if (findName || options?.for_option_id) {
        if (options?.for_option_id) {
          options.option_id = options?.for_option_id;
        } else {
          options.option_id = findName?.id;
        }
      } else {
        await postOptionsDirectory(token, { name: options?.name }).then((s) => {
          if (!s.data.errors) {
            options.option_id = s.data?.option?.id;
            if (!s.data.errors) {
              options.option_id = s.data?.option?.id;
              getOptionsDirectory(token).then((e) => {
                dispatch(setOptionsDirectory(e.data.data));
              });
            }
          }
        });
      }
      delete options.for_option_id;

      putOptions(token, options.id, options).then(async (e) => {
        if (optionPhoto) {
          const formDataOptionImage = new FormData();
          formDataOptionImage.append("operator_option_pictures[]", optionPhoto);
          postImageOptions(token, formDataOptionImage, e.data.operator_option.id).then(
            async (p) => {
              if (!p.data.errors) {
                await getOptions(token).then((g) => {
                  const arr = g.data.data;
                  arr?.forEach((el) => {
                    el.name = el?.option.name;
                  });
                  dispatch(setOptions(arr));
                  let arrFocus = g.data.data.find((el) => el.id === formForCreateOption?.id);
                  dispatch(setFormForCreateOption({ ...arrFocus }));
                });
              }
            }
          );
        } else {
          await getOptions(token).then((g) => {
            const arr = g.data.data;
            arr?.forEach((el) => {
              el.name = el?.option.name;
            });
            dispatch(setOptions(arr));
            let arrFocus = g.data.data.find((el) => el.id === formForCreateOption?.id);
            dispatch(setFormForCreateOption({ ...arrFocus }));
          });
        }
        setLoading(false);
        setOptionPhoto(null);
        // props.onClose(false);
      });
    } else {
      let forSend = formForCreateOption;
      let findName = optionsDirectory?.find((q) => q.name === forSend?.name);
      if (forSend?.status === null) {
        forSend.status = 0;
      }
      forSend.operator_id = operator?.id;
      if (findName || forSend?.for_option_id) {
        if (forSend?.for_option_id) {
          forSend.option_id = forSend?.for_option_id;
        } else {
          forSend.option_id = findName?.id;
        }
      } else {
        await postOptionsDirectory(token, { name: forSend?.name }).then((s) => {
          if (!s.data.errors) {
            forSend.option_id = s.data?.option?.id;
            getOptionsDirectory(token).then((e) => {
              dispatch(setOptionsDirectory(e.data.data));
            });
          }
        });
      }
      postOptions(token, forSend)
        .then(async (e) => {
          if (!e.data.errors) {
            if (optionPhoto) {
              const formDataOptionImage = new FormData();
              formDataOptionImage.append("operator_option_pictures[]", optionPhoto);
              postImageOptions(token, formDataOptionImage, e.data.operator_option.id).then(
                async (e) => {
                  if (!e.data.errors) {
                    await getOptions(token).then((g) => {
                      const arr = g.data.data;
                      arr?.forEach((el) => {
                        el.name = el?.option.name;
                      });
                      dispatch(setOptions(arr));
                      let arrFocus = g.data.data.find((el) => el.id === formForCreateOption?.id);
                      dispatch(setFormForCreateOption({ ...arrFocus }));
                    });
                  }
                }
              );
            } else {
              await getOptions(token).then((g) => {
                const arr = g.data.data;
                arr?.forEach((el) => {
                  el.name = el?.option.name;
                });
                dispatch(setOptions(arr));
                let arrFocus = g.data.data.find((el) => el.id === formForCreateOption?.id);
                dispatch(setFormForCreateOption({ ...arrFocus }));
              });
            }
            setLoading(false);
            setOptionPhoto(null);
            props.onClose(false);
          } else {
            setLoading(false);
            setOpenToast(true);
            setMessageToast({
              text: typeof e.data?.errors === "string" ? e.data?.errors : "Заполните все поля",
              type: "warning",
            });
          }
        })
        .catch((e) => {
          setLoading(false);
          setOpenToast(true);
          setMessageToast({
            text:
              typeof e.response.data?.errors === "string"
                ? e.response.data?.errors
                : "Ошибка создания опции",
            type: "error",
          });
        });
    }
  };

  return (
    <>
      <IonModal
        className="modal-create-options"
        isOpen={props.open}
        onWillDismiss={() => {
          props.onClose(false);
          setOptionPhoto(null);
          dispatch(setFormForCreateOption(DEFAULT_OPTIONS));
        }}
      >
        <Close
          onClick={() => {
            setOptionPhoto(null);
            props.onClose(false);
            dispatch(setFormForCreateOption(DEFAULT_OPTIONS));
          }}
        />
        <div className="body-modal-create-options" id="body-modal-create-options">
          <div className="title">Новая опция</div>
          <div className="description">Опция сохранится в вашем справочнике.</div>
          <AutoCompleteSelect
            noImage
            sleep={2}
            name="name"
            value={name}
            typesel="large"
            className="large select-name"
            placeholder="Название"
            array={optionsDirectory}
            onChange={(e) => {
              setName(e.value);
              if (typeof e.value === "string") {
                dispatch(setFormForCreateOption({ ...formForCreateOption, name: e.value }));
              }
              if (typeof e.value !== "string") {
                dispatch(
                  setFormForCreateOption({
                    ...formForCreateOption,
                    for_option_id: e.value?.id,
                    name: e.value?.name,
                  })
                );
              }
            }}
          />
          <div className="line">
            <Input
              name="basic_cost"
              value={+formForCreateOption?.basic_cost}
              className="large input-create-options"
              placeholder="Цена по умолч."
              noImage
              onChange={(e) => {
                dispatch(setFormForCreateOption({ ...formForCreateOption, basic_cost: e }));
              }}
            />
            <Input
              name="basic_volume"
              value={+formForCreateOption?.basic_volume}
              className="large input-create-options"
              placeholder="Мест по умолч."
              noImage
              onChange={(e) => {
                dispatch(setFormForCreateOption({ ...formForCreateOption, basic_volume: e }));
              }}
            />
          </div>
          <Input
            name="description"
            value={formForCreateOption?.description}
            className="large area area-create-options"
            type="textarea"
            placeholder="Описание"
            noImage
            onChange={(e) => {
              dispatch(setFormForCreateOption({ ...formForCreateOption, description: e }));
            }}
          />
          <div className="add-image-cont">
            {formForCreateOption?.pictures?.[0]?.photo ? (
              <div className="cont-image-option">
                <Button
                  typebtn="elevated"
                  className="small cube btn-del-img"
                  onClick={(e) => {
                    if (userPermissions[2]) {
                      setTypePhoto({ type: 2 });
                      setModalDeleteOperUser(true);
                    } else {
                      setOpenToast(true);
                      setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
                    }
                  }}
                >
                  <Icons icon="x" />
                </Button>
                <img
                  className="main-img"
                  src={`${URL_ORIGIN}${formForCreateOption?.pictures?.[0]?.photo}`}
                ></img>
              </div>
            ) : !optionPhoto ? (
              <div>
                <input
                  type="file"
                  name="file"
                  id="file-option-photo"
                  className="inputfile"
                  accept="image/jpeg, image/png"
                  // multiple
                  onChange={(e) => setOptionPhoto(e.target.files[0])}
                />
                <label className="image add-img" htmlFor="file-option-photo">
                  <Icons icon="photo-plus" />
                </label>
              </div>
            ) : (
              <div className="cont-image-option">
                <Button
                  typebtn="elevated"
                  className="small cube btn-del-img"
                  onClick={() => {
                    setTypePhoto({ type: 1 });
                    setModalDeleteOperUser(true);
                  }}
                >
                  <Icons icon="x" />
                </Button>
                <img className="main-img" src={URL.createObjectURL(optionPhoto)}></img>
              </div>
            )}
            <div className="description-add-img">
              <div className="title">Фото опции</div>
              <div className="cont-subtext">
                <div className="subtext">Рекомендуем не менее 400x400px.</div>
                <div className="subtext">Формат: JPG, PNG.</div>
                <div className="subtext">Макс. 5МБ.</div>
              </div>
            </div>
          </div>
          <Toggle
            disabled={!userPermissions?.[4]}
            name="status"
            checked={!formForCreateOption?.status}
            className="toggle-create-option"
            text="Деактивировать опцию"
            description="Если включено — не будет доступна для покупки"
            onChange={(e) =>
              dispatch(
                setFormForCreateOption({
                  ...formForCreateOption,
                  status: e.detail.checked === true ? 0 : 1,
                })
              )
            }
          />
          <div className="btns">
            <Button
              typebtn="outline"
              className={`small`}
              onClick={() => {
                setOptionPhoto(null);
                dispatch(setFormForCreateOption(DEFAULT_OPTIONS));
                props.onClose(false);
              }}
            >
              Отмена
            </Button>
            <Button
              disabled={loading}
              loading={loading}
              typebtn="bright"
              className={`small ${loading ? "disabled" : ""} btn-save`}
              onClick={() => {
                if (userPermissions[2]) {
                  onSave();
                  setLoading(true);
                } else {
                  setOpenToast(true);
                  setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
                }
              }}
            >
              Сохранить
            </Button>
          </div>
        </div>
      </IonModal>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
      <ModalDelete
        open={modalDeleteOperUser}
        onClose={setModalDeleteOperUser}
        title="Удаление фото"
        text="Вы действительно хотите удалить фото?"
        btn_delete="Да, удалить"
        onClick={() => {
          if (typePhoto.type === 1) {
            setOptionPhoto(null);
            setModalDeleteOperUser(false);
          }
          if (typePhoto.type === 2) {
            deleteImageOptions(
              token,
              formForCreateOption?.id,
              formForCreateOption?.pictures?.[0]?.id
            ).then(async (e) => {
              if (!e.data.errors) {
                await getOptions(token).then((g) => {
                  const arr = g.data.data;
                  arr?.forEach((el) => {
                    el.name = el?.option.name;
                  });
                  dispatch(setOptions(arr));
                  let arrFocus = g.data.data.find((el) => el.id === formForCreateOption?.id);
                  dispatch(setFormForCreateOption({ ...arrFocus }));
                });
                setModalDeleteOperUser(false);
              }
            });
          }
        }}
      />
    </>
  );
};

export default ModalCreateOptions;
