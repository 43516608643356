import { IonModal } from "@ionic/react";
import Select from "../Select";
import Input from "../Input";
import Button from "../Button";
import Close from "../Close";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import qs from "qs";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import MultiSelectChip from "../MultiSelectChip";

const ModalFilterOptions = (props) => {
  const history = useHistory();
  const urlSearch = qs.parse(history.location.search.substr(1));
  const DEFAULT = {
    price_from: "",
    price_until: "",
    seats_from: "",
    seats_until: "",
  };
  const [filterElements, setFilterElements] = useState(DEFAULT);

  const removeEmpty = (obj) =>
    Object.entries(obj)
      .filter(([, v]) => v != null && v !== "")
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

  const func = (obj1, obj2) => {
    let result = {};
    for (let prop in obj1) {
      if (
        obj2.hasOwnProperty(prop) ||
        prop === "page" ||
        prop === "date_from" ||
        prop === "date_until" ||
        prop === "name"
      )
        result[prop] = obj1[prop];
    }
    return result;
  };

  const onApply = () => {
    let data = urlSearch;
    let array = filterElements;
    let filter = removeEmpty(array);
    data = func(data, filter);
    let merge = Object.assign(data, filter);
    merge.page = 1;
    const params = new URLSearchParams(merge);
    history.push(`?${params.toString()}`);
    props.onClose(false);
  };

  useEffect(() => {
    setFilterElements(urlSearch);
  }, [props.open]);
  return (
    <IonModal
      className="filter-table-options"
      isOpen={props.open}
      onWillDismiss={() => {
        props.onClose(false);
        setFilterElements(DEFAULT);
      }}
    >
      <div className="body-modal">
        <Close
          onClick={() => {
            props.onClose(false);
            setFilterElements(DEFAULT);
          }}
        />
        <div className="title">Фильтры</div>
        <div className="body">
          <div className="line">
            <Input
              value={urlSearch?.price_from ? filterElements?.price_from : ""}
              name="price_from"
              className="small input-short"
              placeholder="цена от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, price_from: e });
              }}
            />
            <Input
              value={urlSearch?.price_until ? filterElements?.price_until : ""}
              name="price_until"
              className="small input-short"
              placeholder="цена до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, price_until: e });
              }}
            />
          </div>
          <div className="line">
            <Input
              value={urlSearch?.seats_from ? filterElements?.seats_from : ""}
              name="seats_from"
              className="small input-short"
              placeholder="мест от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, seats_from: e });
              }}
            />
            <Input
              value={urlSearch?.seats_until ? filterElements?.seats_until : ""}
              name="seats_until"
              className="small input-short"
              placeholder="мест до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, seats_until: e });
              }}
            />
          </div>
        </div>
        <div className="buttons">
          <Button
            className="small"
            typebtn="outline"
            onClick={async () => {
              setFilterElements(DEFAULT);
              let data = urlSearch;
              let newd = {};
              if (data?.name) newd.name = data?.name;
              newd.page = 1;
              if (data?.date_from) newd.date_from = data?.date_from;
              if (data?.date_until) newd.date_until = data?.date_until;
              const params = new URLSearchParams(newd);
              history.push(`?${params.toString()}`);
            }}
          >
            Сбросить
          </Button>
          <Button
            className="small"
            typebtn="bright"
            onClick={() => {
              onApply();
            }}
          >
            Применить
          </Button>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalFilterOptions;
