import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Icons from "../../ui/Icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import Button from "../../ui/Button";
import { useHistory } from "react-router";
import ChartLine from "../../ui/ChartLine";
import SelectButton from "../../ui/SelectButton";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { setOpenModalWithdraw } from "../../store/actions";

const FinanceElement = () => {
  const dispatch = useDispatch();
  const [refund, setRefund] = useState();
  const [refundYear, setRefundYear] = useState();
  const [orderGraph, setOrderGraph] = useState();
  const [orderGraphYear, setOrderGraphYear] = useState();
  const operator = useSelector((state) => state.operator);

  const [forGraphRefund, setForGraphRefund] = useState([]);
  const [valForGraphRefund, setValForGraphRefund] = useState(0);
  const [forGraphOrder, setForGraphOrder] = useState([]);
  const [valForGraphOrder, setValForGraphOrder] = useState(0);

  const operations = useSelector((state) => state.operations);
  const orders = useSelector((state) => state.orders);

  const typeRefund = operations?.filter((el) => el.type?.id === 3);
  const history = useHistory();
  const arrReviewsQuest = useSelector((state) => state.reviews);
  const reviews = arrReviewsQuest?.filter(
    (el) => el.type === 0 && el.status_id === 2 && !el.parent_id
  );
  const newReviews = arrReviewsQuest?.filter(
    (el) => el.type === 0 && el.status_id === 1 && !el.parent_id
  );
  const products = useSelector((state) => state.products);
  const promotions = useSelector((state) => state.promotions);
  const arrayProducts = products.filter((el) => !el.deleted_at === true);
  const arrayPromotions = useMemo(
    () => promotions.filter((el) => !el.deleted_at === true),
    [promotions]
  );

  const months = [
    { name: "январь", month: 1 },
    { name: "февраль", month: 2 },
    { name: "март", month: 3 },
    { name: "апрель", month: 4 },
    { name: "май", month: 5 },
    { name: "июнь", month: 6 },
    { name: "июль", month: 7 },
    { name: "август", month: 8 },
    { name: "сентябрь", month: 9 },
    { name: "октябрь", month: 10 },
    { name: "ноябрь", month: 11 },
    { name: "декабря", month: 12 },
  ];

  function createArrayYers() {
    const start = 2023;
    const end = +new Date().toLocaleDateString("ru", { year: "numeric" });
    const arr = [];

    for (let i = start; i <= end; ++i) {
      arr.push({ name: i });
    }
    return arr.reverse();
  }
  useEffect(() => {
    let serc = months.find(
      (el) => el.month === +new Date().toLocaleDateString("ru", { month: "numeric" })
    );
    setRefund(serc);
    setOrderGraph(serc);
    setOrderGraphYear({ name: +new Date().toLocaleDateString("ru", { year: "numeric" }) });
    setRefundYear({ name: +new Date().toLocaleDateString("ru", { year: "numeric" }) });
  }, []);

  useAsyncEffect(() => {
    let arr = typeRefund;
    let filter = arr?.filter(
      (el) =>
        `${+new Date(el?.updated_at).toLocaleDateString("ru", {
          month: "numeric",
        })}.${+new Date(el?.updated_at).toLocaleDateString("ru", {
          year: "numeric",
        })}` === `${refund?.month}.${refundYear?.name}`
    );
    let refundCalc =
      refundYear &&
      refund &&
      Array(new Date(refundYear?.name, refund?.month, 0).getDate())
        .fill()
        .map((e, i) => {
          let calc = 0;
          for (let j = 0; j < filter?.length; j++) {
            if (
              i + 1 ===
              +new Date(filter[j]?.updated_at).toLocaleDateString("ru", { day: "numeric" })
            ) {
              calc += +filter[j]?.summa;
            }
          }
          return calc;
        });
    let valRef = 0;
    refundCalc?.map((item) => (valRef += item));
    setValForGraphRefund(valRef);
    setForGraphRefund(refundCalc);
  }, [refund, refundYear, operations]);

  useAsyncEffect(() => {
    let arr = orders;
    let filter = arr?.filter(
      (el) =>
        `${+new Date(el?.created_at).toLocaleDateString("ru", {
          month: "numeric",
        })}.${+new Date(el?.created_at).toLocaleDateString("ru", {
          year: "numeric",
        })}` === `${orderGraph?.month}.${orderGraphYear?.name}`
    );
    let orderCalc =
      orderGraphYear &&
      orderGraph &&
      Array(new Date(orderGraphYear?.name, orderGraph?.month, 0).getDate())
        .fill()
        .map((e, i) => {
          let calc = 0;
          for (let j = 0; j < filter?.length; j++) {
            if (
              i + 1 ===
              +new Date(filter[j]?.created_at).toLocaleDateString("ru", { day: "numeric" })
            ) {
              calc += 1;
            }
          }
          return calc;
        });
    let valRef = 0;
    orderCalc?.map((item) => (valRef += item));
    setValForGraphOrder(valRef);
    setForGraphOrder(orderCalc);
  }, [orderGraph, orderGraphYear, orders]);
  return (
    <div className="finance">
      <div className="cont-frames">
        <div className="upper-frames">
          <div className="frame-fin">
            <div className="frame-fin-inf">
              <div className="title-cont">
                <span className="title-frame-fin">Депозит</span>
                <Icons icon="info-square-rounded" className="icon-muted-info info-icon" />
              </div>
              <div>
                <div className="cont-balance">
                  <div className="balance">{`${String(
                    operator?.balans ? +operator?.balans : 0
                  ).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}</div>
                </div>
              </div>
            </div>
            <div className="cont-look">
              <Button
                typebtn="ghost"
                className="small"
                onClick={() => dispatch(setOpenModalWithdraw(true))}
              >
                Вывести <Icons icon="arrow-right" className="icon-outline" />
              </Button>
            </div>
          </div>
          <div className="frame-fin">
            <div className="frame-fin-inf">
              <div className="title-cont">
                <span className="title-frame-fin">Возвраты</span>
                <SelectButton
                  value={refund}
                  array={months}
                  className="ghost select-finance"
                  onChange={(e) => {
                    setRefund(e.value);
                  }}
                />
                <SelectButton
                  value={refundYear}
                  array={createArrayYers()}
                  className="ghost select-finance-year"
                  onChange={(e) => {
                    setRefundYear(e.value);
                  }}
                />
                <Icons icon="info-square-rounded" className="icon-muted-info info-icon" />
              </div>
            </div>
            <div className="char">
              <div className="char-text">
                <div className="text">{`${String(valForGraphRefund).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  " "
                )} ₽`}</div>
                <div className="prc green">{`${0} %`}</div>
              </div>

              <ChartLine
                color="green"
                labels={new Date(refundYear?.name, refund?.month, 0).getDate()}
                data={forGraphRefund}
              />
            </div>
            <div className="cont-look">
              <Button
                typebtn="ghost"
                className="small"
                onClick={() => {
                  history.push("/partner/finance/operations?page=1");
                }}
              >
                Смотреть <Icons icon="arrow-right" className="icon-outline" />
              </Button>
            </div>
          </div>
          <div className="frame-fin">
            <div className="frame-fin-inf">
              <div className="title-cont">
                <span className="title-frame-fin">Заказы</span>
                <SelectButton
                  value={orderGraph}
                  array={months}
                  className="ghost select-finance"
                  onChange={(e) => {
                    setOrderGraph(e.value);
                  }}
                />
                <SelectButton
                  value={orderGraphYear}
                  array={createArrayYers()}
                  className="ghost select-finance-year"
                  onChange={(e) => {
                    setOrderGraphYear(e.value);
                  }}
                />
                <Icons icon="info-square-rounded" className="icon-muted-info info-icon" />
              </div>
            </div>
            <div className="char">
              <div className="char-text">
                <div className="text">{`${String(valForGraphOrder).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  " "
                )}`}</div>
                <div className="prc orange">{`${0} %`}</div>
              </div>
              <ChartLine
                color="orange"
                data={forGraphOrder}
                labels={new Date(orderGraphYear?.name, orderGraph?.month, 0).getDate()}
              />
            </div>
            <div className="cont-look">
              <Button
                typebtn="ghost"
                className="small"
                onClick={() => {
                  history.push("/partner/finance/orders?page=1");
                }}
              >
                Смотреть <Icons icon="arrow-right" className="icon-outline" />
              </Button>
            </div>
          </div>
        </div>
        <div className="lower-frames">
          <div className="frame-lower">
            <Icons icon="info-square-rounded" className="icon-muted-info info-icon" />
            <div className="title">Комиссия</div>
            <div className="value">{`${+operator?.commission_value || 0}%`}</div>
          </div>
          <div className="frame-lower">
            <Icons icon="info-square-rounded" className="icon-muted-info info-icon" />
            <div className="title">Туров</div>
            <div className="value">{arrayProducts?.length}</div>
          </div>
          <div className="frame-lower">
            <Icons icon="info-square-rounded" className="icon-muted-info info-icon" />
            <div className="title">Отзывов</div>
            <div className="value">
              {reviews?.length}
              <div className="new-val">{newReviews?.length ? `+${newReviews?.length}` : ""}</div>
            </div>
          </div>
          <div className="frame-lower">
            <Icons icon="info-square-rounded" className="icon-muted-info info-icon" />
            <div className="title">Акций</div>
            <div className="value">{arrayPromotions?.length}</div>
          </div>
          <div className="frame-lower">
            <Icons icon="info-square-rounded" className="icon-muted-info info-icon" />
            <div className="title">Штрафы</div>
            <div className="penalty">
              <div className="value">0</div>
              <Icons icon="succes" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceElement;
