import { IonModal } from "@ionic/react";
import Select from "../Select";
import Input from "../Input";
import Button from "../Button";
import Close from "../Close";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import qs from "qs";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import MultiSelectChip from "../MultiSelectChip";

const ModalFilterTableTours = (props) => {
  const history = useHistory();
  const urlSearch = qs.parse(history.location.search.substr(1));
  const [filterFormat, setFilterFormat] = useState();
  const [filterGenres, setFilterGenres] = useState();
  const DEFAULT = {
    price_from: "",
    price_until: "",
    seats_from: "",
    seats_until: "",
    public_from: "",
    public_until: "",
    rate_from: "",
    rate_until: "",
    reviews_from: "",
    reviews_until: "",
    favorite_from: "",
    favorite_until: "",
  };
  const [filterElements, setFilterElements] = useState(DEFAULT);
  const format = useSelector((state) => state.format);
  const genre = useSelector((state) => state.genre);

  const removeEmpty = (obj) =>
    Object.entries(obj)
      .filter(([, v]) => v != null && v !== "")
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

  useAsyncEffect(() => {
    if (!urlSearch?.format) {
      setFilterFormat(null);
    } else {
      let arrF = [];
      let urlF = urlSearch?.format?.split(",");
      for (let i = 0; i < urlF?.length; i++) {
        let find = format?.find((el) => el.id === +urlF[i]);
        arrF.push(find);
      }
      setFilterFormat(arrF);
    }
    if (!urlSearch?.genres) {
      setFilterGenres(null);
    } else {
      let arrG = [];
      let urlG = urlSearch?.genres?.split(",");
      for (let i = 0; i < urlG?.length; i++) {
        let find = genre?.find((el) => el.id === +urlG[i]);
        arrG.push(find);
      }
      setFilterGenres(arrG);
    }
  }, [props.open]);

  const func = (obj1, obj2) => {
    let result = {};
    for (let prop in obj1) {
      if (
        obj2.hasOwnProperty(prop) ||
        prop === "page" ||
        prop === "date_from" ||
        prop === "date_until" ||
        prop === "name"
      )
        result[prop] = obj1[prop];
    }
    return result;
  };

  const onApply = () => {
    let data = urlSearch;
    let array = filterElements;
    let filter = removeEmpty(array);
    data = func(data, filter);
    let merge = Object.assign(data, filter);
    merge.page = 1;
    if (filterFormat) {
      let f = [];
      for (let i = 0; i < filterFormat?.length; i++) {
        f.push(filterFormat[i]?.id);
      }
      if (f.length) {
        merge.format = f;
      } else {
        delete merge?.format;
      }
    }
    if (filterGenres) {
      let g = [];
      for (let i = 0; i < filterGenres?.length; i++) {
        g.push(filterGenres[i]?.id);
      }
      if (g.length) {
        merge.genres = g;
      } else {
        delete merge?.genres;
      }
    }

    const params = new URLSearchParams(merge);
    history.push(`?${params.toString()}`);
    props.onClose(false);
  };
  useEffect(() => {
    setFilterElements(urlSearch);
  }, [props.open]);

  return (
    <IonModal
      className="filter-table-tours"
      isOpen={props.open}
      onWillDismiss={() => {
        props.onClose(false);
        setFilterElements(DEFAULT);
      }}
    >
      <div className="body-modal">
        <Close
          onClick={() => {
            props.onClose(false);
            setFilterElements(DEFAULT);
          }}
        />
        <div className="title">Фильтры</div>
        <div className="body">
          {/* <Select
            typesel="small"
            className="small"
            onChange={(e) => console.log(e.target.value)}
            placeholder="компания: все"
          /> */}

          <MultiSelectChip
            value={filterFormat || ""}
            array={format}
            display="comma"
            val={filterFormat}
            typesel="small"
            className="small"
            onChange={(e) => {
              setFilterFormat(e.value);
            }}
            placeholder="формат: все"
          />
          <div className="line">
            <Input
              value={urlSearch?.price_from ? filterElements?.price_from : ""}
              name="price_from"
              className="small input-short"
              placeholder="цена от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, price_from: e });
              }}
            />
            <Input
              value={urlSearch?.price_until ? filterElements?.price_until : ""}
              name="price_until"
              className="small input-short"
              placeholder="цена до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, price_until: e });
              }}
            />
          </div>
          <div className="line">
            <Input
              value={urlSearch?.seats_from ? filterElements?.seats_from : ""}
              name="seats_from"
              className="small input-short"
              placeholder="мест от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, seats_from: e });
              }}
            />
            <Input
              value={urlSearch?.seats_until ? filterElements?.seats_until : ""}
              name="seats_until"
              className="small input-short"
              placeholder="мест до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, seats_until: e });
              }}
            />
          </div>
          <MultiSelectChip
            value={filterGenres || ""}
            array={genre}
            display="comma"
            val={filterGenres}
            typesel="small"
            className="small"
            onChange={(e) => {
              setFilterGenres(e.value);
              // let arr = [];
              // for (var i = 0; i < e.value.length; i++) {
              //   arr.push(e.value[i].id);
              // }
            }}
            placeholder="жанр: все"
          />
          <div className="line ">
            <Input
              value={
                urlSearch?.public_from
                  ? filterElements?.public_from &&
                    new Date(+filterElements?.public_from).toLocaleDateString()
                  : ""
              }
              type="date"
              name="public_from"
              className="small input-short"
              placeholder="опубликован от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, public_from: new Date(e).getTime() });
              }}
            />

            <Input
              value={
                urlSearch?.public_until
                  ? filterElements?.public_until &&
                    new Date(+filterElements?.public_until).toLocaleDateString()
                  : ""
              }
              type="date"
              name="public_until"
              className="small input-short"
              placeholder="опубликован до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, public_until: new Date(e).getTime() });
              }}
            />
          </div>
          <div className="line">
            <Input
              value={urlSearch?.rate_from ? filterElements?.rate_from : ""}
              name="rate_from"
              className="small input-short"
              placeholder="рейтинг от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, rate_from: e });
              }}
            />
            <Input
              value={urlSearch?.rate_until ? filterElements?.rate_until : ""}
              name="rate_until"
              className="small input-short"
              placeholder="рейтинг до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, rate_until: e });
              }}
            />
          </div>
          <div className="line">
            <Input
              value={urlSearch?.reviews_from ? filterElements?.reviews_from : ""}
              name="reviews_from"
              className="small input-short"
              placeholder="отзывов от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, reviews_from: e });
              }}
            />
            <Input
              value={urlSearch?.reviews_until ? filterElements?.reviews_until : ""}
              name="reviews_until"
              className="small input-short"
              placeholder="отзывов до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, reviews_until: e });
              }}
            />
          </div>
          <div className="line">
            <Input
              value={urlSearch?.favorite_from ? filterElements?.favorite_from : ""}
              name="favorite_from"
              className="small input-short"
              placeholder="в избранном от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, favorite_from: e });
              }}
            />
            <Input
              value={urlSearch?.favorite_until ? filterElements?.favorite_until : ""}
              name="favorite_until"
              className="small input-short"
              placeholder="в избранном до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, favorite_until: e });
              }}
            />
          </div>
        </div>
        <div className="buttons">
          <Button
            className="small"
            typebtn="outline"
            onClick={async () => {
              // props.onClose(false)
              setFilterFormat(null);
              setFilterGenres(null);
              setFilterElements(DEFAULT);
              let data = urlSearch;
              let newd = {};
              if (data?.name) newd.name = data?.name;
              newd.page = 1;
              if (data?.date_from) newd.date_from = data?.date_from;
              if (data?.date_until) newd.date_until = data?.date_until;
              const params = new URLSearchParams(newd);
              history.push(`?${params.toString()}`);
            }}
          >
            Сбросить
          </Button>
          <Button
            className="small"
            typebtn="bright"
            onClick={() => {
              onApply();
            }}
          >
            Применить
          </Button>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalFilterTableTours;
