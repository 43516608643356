import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "../less/ui/css/slider-tours.css";
import PreviewTour from "./PreviewTour";
import PreviewFeedback from "./PreviewFeedback";
import { useState } from "react";

const Slider = (props) => {
  const [shadowHideOne, setShadowHideOne] = useState(false);
  const [shadowHideTwo, setShadowHideTwo] = useState(true);

  return (
    <div className={`cont-slide ${props.className}`}>
      {shadowHideTwo && (
        <div
          className={`shadow1 ${
            props.type === "feedback" || props.type === "question" ? "fq" : ""
          }`}
        ></div>
      )}
      {shadowHideOne && (
        <div
          className={`shadow2 ${
            props.type === "feedback" || props.type === "question" ? "fq" : ""
          }`}
        ></div>
      )}
      <Swiper
        ref={props.sliderRef}
        onSlideChange={(e) => {
          if (e.translate !== 0) {
            setShadowHideOne(true);
          } else {
            setShadowHideOne(false);
          }
          if (e.snapGrid[e.snapGrid.length - 1] === e.translate * -1) {
            setShadowHideTwo(false);
          } else {
            setShadowHideTwo(true);
          }
        }}
        slidesPerView={props.slidesPerView || "auto"}
        spaceBetween={props.spaceBetween || 20}
        grabCursor={true}
        className="mySwiper"
      >
        {props.array.map((el, i) => {
          return (
            <SwiperSlide key={i}>
              {props.type === "draft" || props.type === "tours" ? (
                <PreviewTour el={el} className="slider" type={props.type} />
              ) : props.type === "feedback" || props.type === "question" ? (
                <PreviewFeedback el={el} type={props.type} />
              ) : undefined}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Slider;
