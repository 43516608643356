import { IonContent } from "@ionic/react";
import "../../less/css/feedback.css";
import { useEffect, useState } from "react";
import { FIELD_FOR_REVIEWS, STATUSES_REVIEWS } from "../../utils/array";
import Table from "../../ui/Table";
import ModalFilterReviews from "../../ui/ModalsFilter/ModalFilterReviews";
import ModalReviewsDetails from "./ModalReviewsDetails";
import ModalReviewsReport from "./ModalReviewsReport";
import { useSelector } from "react-redux";
import Toast from "../../ui/Toast";

const ReviewsContent = () => {
  const arrReviewsQuest = useSelector((state) => state.reviews);
  const reviews = arrReviewsQuest?.filter((el) => el.type === 0 && !el.parent_id);

  const userPermissions = useSelector((state) => state.userPermissions.feedback?.reviews);
  const columnsTable = useSelector((state) => state.columnsTable);

  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });

  const [focusElement, setFocusElement] = useState();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openModalReviewsDetails, setOpenModalReviewsDetails] = useState(false);
  const [openModalReport, setOpenModalReport] = useState(false);
  const [openMenuElem, setOpenMenuElem] = useState(false);

  const status = STATUSES_REVIEWS;
  // const fieldArray = FIELD_FOR_REVIEWS;
  const numberColumns = 6;
  const [fieldArray, setFieldArray] = useState();
  useEffect(() => {
    setFieldArray(JSON.parse(localStorage.getItem("column"))[numberColumns]);
  }, [columnsTable]);

  var arrayForExcel = [];

  for (let i = 0; i < reviews?.length; i++) {
    // let genres = ``;
    // for (let j = 0; j < products[i].genres.length; j++) {
    //   genres += `${products[i].genres[j]?.name}, `;
    // }
    let status =
      reviews[i]?.status_id === 1 ? "Без ответа" : reviews[i]?.status_id === 2 && "Обработан";
    let new_array = {
      Номер: reviews[i]?.id,
      "Дата создания": reviews[i]?.created_at,
      "Оценка тура": +reviews[i]?.tour_assessment,
      "Название тура": reviews[i]?.product.name,
      Автор: `${reviews[i]?.user?.last_name} ${reviews[i]?.user.first_name} ${
        reviews[i]?.user.middle_name ?? ""
      }`,
      "Телефон автора": reviews[i]?.user?.phone,
      "Количество фото": reviews[i]?.pictures.length,
      "Текст отзыва": reviews[i]?.review_text,
      "Дата ответа": reviews[i]?.response_date,
      "Текст ответа": reviews[i]?.response_text,
      Статус: status,
    };
    arrayForExcel.push(new_array);
  }

  const menu = [
    {
      name: "Смотреть",
      icon: "design-personal-eye",
      click: (e) => {
        if (userPermissions[0]) {
          setOpenModalReviewsDetails(true);
          setOpenMenuElem(false);
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
      },
      status: [1, 2],
    },
    {
      name: "Пожаловаться",
      icon: "mood-sad",
      click: () => {
        if (userPermissions[2]) {
          setOpenModalReport(true);
          setOpenMenuElem(false);
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
      },
      status: [1, 2],
    },
  ];

  return (
    <>
      <IonContent className="page">
        <div className="reviews-feedback-page">
          <div className="title">Отзывы</div>
          <Table
            onClick={() => {
              if (userPermissions[0]) {
                setOpenModalReviewsDetails(true);
              } else {
                setOpenToast(true);
                setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
              }
            }}
            openMenuElem={openMenuElem}
            setOpenMenuElem={setOpenMenuElem}
            openFilterModal={setOpenFilterModal}
            focus={setFocusElement}
            status={status}
            array={reviews}
            header={fieldArray}
            numberColumns={numberColumns}
            menu={menu}
            permission={userPermissions}
            classNameTableSetting="reviews"
            arrayForExcel={arrayForExcel}
            nameFile={`Таблица отзывов ${new Date().toLocaleDateString("ru")}`}
            searchPlaceholder="Поиск по названию, автору..."
          />
        </div>
      </IonContent>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
      <ModalFilterReviews open={openFilterModal} onClose={setOpenFilterModal} />
      <ModalReviewsDetails
        open={openModalReviewsDetails}
        onClose={setOpenModalReviewsDetails}
        element={focusElement}
        setFocusElement={setFocusElement}
        setOpenModalReport={setOpenModalReport}
      />
      <ModalReviewsReport open={openModalReport} onClose={setOpenModalReport} />
    </>
  );
};

export default ReviewsContent;
