import { IonModal } from "@ionic/react";
import Button from "../../../ui/Button";
import Close from "../../../ui/Close";

const ModalDelete = (props) => {
  return (
    <IonModal
      className={`modal-delete ${props.className ? props.className : ""}`}
      isOpen={props.open}
      onWillDismiss={() => props.onClose(false)}
    >
      <Close onClick={() => props.onClose(false)} />
      <div className="body-modal-delete">
        <div className="title">{props.title || "Удалить"}</div>
        <div className="cont-text">
          <div className="text">{props.text || "Действительно хотите удалить?"}</div>
        </div>
        <div className="btns">
          <Button typebtn="outline" className="small" onClick={() => props.onClose(false)}>
            Отмена
          </Button>
          <Button
            typebtn="bright"
            className="small"
            onClick={props.onClick}
            loading={props.loading}
            disabled={props.disabled}
          >
            {props.btn_delete || "Удалить"}
          </Button>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalDelete;
