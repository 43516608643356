import { IonModal } from "@ionic/react";
import Button from "../../../ui/Button";
import Close from "../../../ui/Close";
import { changeStatusProducts, getToursOperator } from "../../../utils/api";
import { setProducts } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const ModalArchiveTour = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  return (
    <IonModal
      className="modal-archive-tour"
      isOpen={props.open}
      onWillDismiss={() => props.onClose(false)}
    >
      <Close onClick={() => props.onClose(false)} />
      <div className="body-modal-archive-tour">
        <div className="title">Архивировать тур</div>
        <div className="cont-text">
          <div className="text">
            Тур в архиве снимается с публикации и недоступен для редактирования. При необходимости
            вы можете опубликовать тур или перевести в статус «Черновик».
          </div>
        </div>
        <div className="btns">
          <Button typebtn="outline" className="small" onClick={() => props.onClose(false)}>
            Отмена
          </Button>
          <Button
            typebtn="bright"
            className="small"
            onClick={() => {
              changeStatusProducts(token, props.element.id, 6).then(async (e) => {
                if (e.data.errors) {
                } else {
                  await getToursOperator(token).then((e) => {
                    if (e.data.data) {
                      dispatch(setProducts(e.data.data));
                    }
                  });
                }
              });
              props.onClose(false);
            }}
          >
            Подтвердить
          </Button>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalArchiveTour;
