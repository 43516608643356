import { useEffect } from "react";

export function useAsyncEffect(fn, dp, ret) {
  useEffect(() => {
    const asynFn = async () => await fn();
    try {
      asynFn();
    } catch (e) {
      console.log(e);
    }
    return ret?.();
  }, dp);
}
