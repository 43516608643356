import "../less/css/custom.css";
import Icons from "./Icons";

const AlertBox = (props) => {
  return (
    <div className={`cont-alert-box ${props.className} ${props.type}`}>
      {!props.noClose && (
        <div className="cont-close-alert icon-outline" onClick={props.onClick}>
          <Icons icon="close20px" />
        </div>
      )}
      <div className={`info ${props.noClose ? "noclose" : ""}`}>
        <Icons
          className="icon-alert-box"
          icon={
            props.type === "neutral"
              ? "star-neutral"
              : props.type === "primary"
              ? "star-primary"
              : props.type === "secondary"
              ? "star-secondary"
              : props.type === "info"
              ? "info-circle"
              : props.type === "success"
              ? "circle-check"
              : props.type === "warning"
              ? "alert-circle"
              : props.type === "danger" && "circle-x"
          }
        />
        <div className="cont-text">
          {props.title && <div className="title">{props.title}</div>}
          <div className="text">{props.text}</div>
        </div>
      </div>
    </div>
  );
};

export default AlertBox;
