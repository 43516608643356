import { IonToggle } from "@ionic/react";
import "../less/css/custom.css";

const Toggle = (props) => {
  return (
    <div className={`cont-toggle ${props.position ? props.position : ""}`}>
      <IonToggle
        value={1}
        name={props.name}
        disabled={props.disabled !== undefined ? props.disabled : false}
        className={`toggle-menu-change-table ${props.className ? props.className : ""}`}
        mode="ios"
        checked={props.checked}
        onIonChange={props.onChange}
      ></IonToggle>
      {props.text && (
        <div className="cont-text">
          <div className="text">{props.text}</div>
          {props.description && <div className="text-lower">{props.description}</div>}
        </div>
      )}
    </div>
  );
};

export default Toggle;
