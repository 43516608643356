import "../less/css/custom.css";
import Button from "./Button";
import Icons from "./Icons";

const Promotion = (props) => {
  return (
    <div className={`el-offer ${props.className}`}>
      <div className="cont-text">
        <span className="text-1">{props.title}</span>
        <span className="text-2">{props.text}</span>
      </div>
      <Button typebtn="ghost" className="small cont-look">
        <div className="look">Смотреть</div>
        <Icons icon="arrow-narrow-right" />
      </Button>

      <img className="image" src={props.image} />
    </div>
  );
};

export default Promotion;
