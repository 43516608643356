import { IonMenu, IonMenuToggle } from "@ionic/react";
import "../../less/css/firstpage.css";
import Button from "../../ui/Button";

import { useDispatch, useSelector } from "react-redux";

import { useHistory, useLocation } from "react-router";
import Icons from "../../ui/Icons";
import Select from "../../ui/Select";
import Vopros from "../../image/image-quick-view/vopros.svg";
import { Slider } from "primereact/slider";
import Checkbox from "../../ui/Checkbox";
import Input from "../../ui/Input";
import { useState } from "react";
import qs from "qs";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { setFilterFirstPage } from "../../store/actions";
import { toms } from "../../utils/utils";

const LeftMenuFilter = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const urlSearch = qs.parse(history.location.search.substr(1));
  const products = useSelector((state) => state.productsClients);

  const DEFAULT_RANGE = [0, 100000];
  const DEFAULT = {
    type: [],
    date_from: "",
    date_until: "",
    price_from: "",
    price_until: "",
    genres: [],
    class: [],
    suitable_arr: [],
  };

  const [filterElements, setFilterElements] = useState(DEFAULT);
  const filterFirstPage = useSelector((state) => state.filterFirstPage);
  const arrayCity = useSelector((state) => state.arrayMapCity);
  const genreArray = useSelector((state) => state.genre);
  const classArray = useSelector((state) => state.classes);
  const formatArray = useSelector((state) => state.format);
  const suitable = useSelector((state) => state.suitable);
  suitable?.sort(function (a, b) {
    return a.type_id - b.type_id;
  });
  // const pod = suitable?.filter((el) => el.type_id === 0);
  // const dos = suitable?.filter((el) => el.type_id === 1);
  const [sliderValue, setSliderValue] = useState(DEFAULT_RANGE);
  const [filterCity, setFilterCity] = useState();

  useAsyncEffect(async () => {
    let value = filterElements;
    let arr = [];
    for (let i = 0; i < suitable?.length; i++) {
      let find = urlSearch?.suitable_arr?.find((qe) => qe === suitable[i]?.id);
      if (find) {
        arr.push({ suitable_id: suitable[i]?.id, cnt: find?.cnt });
      } else {
        arr.push({ suitable_id: suitable[i]?.id, cnt: 0 });
      }
    }
    value.suitable_arr = arr;
    await setFilterElements({ ...value });
  }, [suitable, filterElements?.id]);

  console.log("filterElements", filterElements);

  const removeEmpty = (obj) =>
    Object.entries(obj)
      .filter(([, v]) => v != null && (v !== "" || v.length))
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

  const func = (obj1, obj2) => {
    let result = {};
    for (let prop in obj1) {
      if (obj2.hasOwnProperty(prop) || prop === "id") result[prop] = obj1[prop];
    }
    return result;
  };
  console.log(urlSearch);
  const onApply = () => {
    let data = urlSearch;
    let array = filterElements;
    let filter = removeEmpty(array);
    data = func(data, filter);
    let merge = Object.assign(data, filter);
    console.log(merge);
    !merge.class.length && delete merge.class;
    !merge.suitable_arr.length && delete merge.suitable_arr;
    !merge.genres.length && delete merge.genres;
    !merge.type.length && delete merge.type;
    merge.adults === 1 && delete merge.adults;
    !merge.babies && delete merge.babies;
    !merge.children && delete merge.children;
    const params = new URLSearchParams(merge);
    history.push(`?${params.toString()}`);
  };

  useAsyncEffect(async () => {
    products.forEach((e) => {
      let count = 0;
      let filter = e.details?.filter((el) => el?.point_type_id === 1);
      let sort = filter?.sort(function (a, b) {
        return a.order - b.order;
      })[0];
      let slice;
      if (sort?.collect_time) {
        slice = sort?.collect_time?.slice(0, 5);
      } else {
        slice = "23:59";
      }
      e.time_start = slice;
      for (let i = 0; i < e.races.length; i++) {
        if (
          e?.races[i].number_travelers !== 0 &&
          e?.races[i].number_travelers > e?.races[i].paid_travelers_count &&
          new Date(e?.races[i].start_date).getTime() + toms(slice) > new Date().getTime()
        ) {
          count += 1;
        }
      }
      e.count_races = count;
    });
    let arrayFilter = products?.filter((el) => el?.races?.length !== 0 && el?.count_races !== 0);
    // формат
    if (filterElements?.type?.length) {
      let arr = [];
      let arrSplit = filterElements?.type;
      for (let i = 0; i < arrSplit?.length; i++) {
        let filterEl = await arrayFilter?.filter((el) => el?.duration?.id === +arrSplit[i]);
        filterEl && arr.push(...filterEl);
      }
      arrayFilter = arr;
    }
    // классы
    if (filterElements?.class?.length) {
      let arr = [];
      let arrSplit = filterElements?.class;
      for (let i = 0; i < arrSplit?.length; i++) {
        let filterEl = await arrayFilter?.filter((el) => el?.class?.id === +arrSplit[i]);
        filterEl && arr.push(...filterEl);
      }
      arrayFilter = arr;
    }
    // жанры
    if (filterElements?.genres?.length) {
      let arr = [];
      let arrSplit = filterElements?.genres;
      for (let i = 0; i < arrSplit?.length; i++) {
        let filterEl = await arrayFilter?.filter(
          (el) => !!el?.genres.find((q) => q?.id === +arrSplit[i])
        );
        filterEl && arr.push(...filterEl);
      }
      arrayFilter = arr;
    }
    // даты
    if (filterElements?.date_from) {
      let arr = [];
      for (let i = 0; i < arrayFilter?.length; i++) {
        let filterEl = await arrayFilter[i].races?.filter(
          (q) => new Date(q.start_date).getTime() >= filterElements.date_from && !q.deleted_at
        );
        filterEl.length && arr.push(arrayFilter[i]);
      }
      arrayFilter = arr;
    }

    if (filterElements?.date_until) {
      let arr = [];
      for (let i = 0; i < arrayFilter?.length; i++) {
        let filterEl = await arrayFilter[i].races?.filter(
          (q) => new Date(q.start_date).getTime() <= filterElements.date_until && !q.deleted_at
        );
        filterEl.length && arr.push(arrayFilter[i]);
      }
      arrayFilter = arr;
    }
    // цена
    if (filterElements?.price_from) {
      arrayFilter = await arrayFilter?.filter((el) => +el?.summa >= +filterElements?.price_from);
    }
    if (filterElements?.price_until) {
      arrayFilter = await arrayFilter?.filter((el) => +el?.summa <= +filterElements?.price_until);
    }
    if (filterElements?.suitable_arr?.length) {
      let arr = [];
      let arrSplit = filterElements?.suitable_arr?.filter((el) => el.cnt !== 0);
      console.log(arrayFilter);
      for (let i = 0; i < arrSplit?.length; i++) {
        let filterEl = await arrayFilter?.filter(
          (el) =>
            !!el?.suitables?.find(
              (q) => q?.suitable_id === +arrSplit[i]?.suitable_id && q.cnt >= +arrSplit[i]?.cnt
            )
        );
        filterEl && arr.push(...filterEl);
      }
      arrayFilter = arrSplit?.length ? arr : arrayFilter;
    }

    dispatch(setFilterFirstPage(arrayFilter));
  }, [filterElements, products, urlSearch?.suitable_arr]);

  return (
    <IonMenu
      type="overlay"
      swipeGesture={false}
      menuId="menu-left-filter"
      contentId="main-content"
      className="main-left-menu-filter"
    >
      <div className="menu-filter-cont">
        <div className="header-filter">
          <div className="title">Фильтры</div>
          <IonMenuToggle className="close-left-menu" menu="menu-left-filter">
            <Button typebtn="ghost" className="small cube">
              <Icons icon="x" />
            </Button>
          </IonMenuToggle>
        </div>
        <div className="body-filter">
          <div className="line ">
            <div className="title-line pad">Откуда</div>
            <Select
              value={filterCity || ""}
              array={arrayCity}
              typesel="medium"
              optionLabel="name"
              className="medium"
              onChange={(e) => {
                setFilterCity(e.value);
              }}
              placeholder="Откуда..."
            />
          </div>
          <div className="line">
            <div className="title-line">Тип автопутешествия</div>
            {formatArray?.map((el, i) => {
              return (
                <Checkbox
                  key={i}
                  checked={!!filterElements?.type?.find((q) => q === el.id)}
                  // id={"checkbox"}
                  className="checkbox"
                  position="top"
                  onChange={(e) => {
                    let filter = filterElements?.type;
                    let find = filterElements?.type?.find((q) => q === el.id);
                    if (e.target.checked) {
                      !find && filter.push(el.id);
                    } else {
                      let f = filter.filter((number) => number !== el.id);
                      filter = f;
                    }
                    setFilterElements({ ...filterElements, type: filter });
                  }}
                >
                  <div className="text-checkbox">
                    {el.name} <img src={Vopros} />
                  </div>
                </Checkbox>
              );
            })}
          </div>
          <div className="line-date">
            <div className="date">
              <div className="title-date">Дата от</div>
              <Input
                value={
                  (filterElements?.date_from &&
                    new Date(+filterElements?.date_from).toLocaleDateString()) ||
                  ""
                }
                className="input-date medium"
                placeholder="дд.мм.гггг"
                type="date"
                noImage
                onChange={(e) => {
                  setFilterElements({ ...filterElements, date_from: new Date(e).getTime() });

                  // if (e) {
                  //   setDateOt(e);
                  //   let data = urlSearch;
                  //   data.date_from = new Date(e).getTime();
                  //   const params = new URLSearchParams(data);
                  //   history.push(`?${params.toString()}`);
                  //   // let fil = array?.filter(
                  //   //   (el) => new Date(el?.created_at).getTime() > new Date(e).getTime()
                  //   // );
                  //   // setArray(fil);
                  // } else {
                  //   setDateOt(e);
                  //   let data = urlSearch;
                  //   delete data.date_from;
                  //   const params = new URLSearchParams(data);
                  //   history.push(`?${params.toString()}`);
                  // }
                }}
              />
            </div>
            <div className="date">
              <div className="title-date">Дата до</div>
              <Input
                value={
                  (filterElements?.date_until &&
                    new Date(+filterElements?.date_until).toLocaleDateString()) ||
                  ""
                }
                className="input-date medium"
                placeholder="дд.мм.гггг"
                type="date"
                noImage
                onChange={(e) => {
                  setFilterElements({ ...filterElements, date_until: new Date(e).getTime() });

                  // if (e) {
                  //   setDateOt(e);
                  //   let data = urlSearch;
                  //   data.date_from = new Date(e).getTime();
                  //   const params = new URLSearchParams(data);
                  //   history.push(`?${params.toString()}`);
                  //   // let fil = array?.filter(
                  //   //   (el) => new Date(el?.created_at).getTime() > new Date(e).getTime()
                  //   // );
                  //   // setArray(fil);
                  // } else {
                  //   setDateOt(e);
                  //   let data = urlSearch;
                  //   delete data.date_from;
                  //   const params = new URLSearchParams(data);
                  //   history.push(`?${params.toString()}`);
                  // }
                }}
              />
            </div>
          </div>
          <div className="line">
            <div className="title-line pad">Бюджет</div>
            <div className="inputs">
              <Input
                value={filterElements?.price_from}
                className="input-date medium"
                placeholder="от"
                noImage
                onChange={(e) => {
                  setFilterElements({ ...filterElements, price_from: +e });
                  setSliderValue([e ? +e : DEFAULT_RANGE[0], sliderValue[1]]);
                }}
              />
              <Input
                value={filterElements?.price_until}
                className="input-date medium"
                placeholder="до"
                noImage
                onChange={(e) => {
                  setFilterElements({ ...filterElements, price_until: +e });

                  setSliderValue([sliderValue[0], e ? +e : DEFAULT_RANGE[1]]);
                }}
              />
            </div>
            <div className="cont-slider">
              <Slider
                className="slider-filter"
                value={sliderValue}
                min={0}
                step={1000}
                max={100000}
                onChange={(e) => {
                  setSliderValue(e.value);
                  setFilterElements({
                    ...filterElements,
                    price_from: e.value[0],
                    price_until: e.value[1] !== DEFAULT_RANGE[1] ? e.value[1] : "",
                  });
                }}
                range
              />
            </div>
          </div>
          <div className="line">
            <div className="title-line">Кому подходит</div>
            <div className="dop-mem">
              {suitable?.map((el, i) => {
                return (
                  <Checkbox
                    key={i}
                    checked={
                      filterElements?.suitable_arr?.find((s) => s.suitable_id === el?.id)?.cnt === 1
                        ? true
                        : false
                    }
                    // id={"checkbox"}
                    className="checkbox"
                    position="top"
                    onChange={(e) => {
                      let find = filterElements?.suitable_arr?.find((z) => z.suitable_id === el.id);
                      if (find) {
                        let filter = filterElements?.suitable_arr?.filter(
                          (s) => s.suitable_id !== el.id
                        );
                        filter.push({ suitable_id: el.id, cnt: e.target.checked ? 1 : 0 });
                        setFilterElements({ ...filterElements, suitable_arr: filter });
                      }
                    }}
                  >
                    <div className="text-checkbox">{el.name}</div>
                  </Checkbox>
                );
              })}
            </div>
          </div>
          <div className="line">
            <div className="title-line">Класс обслуживания</div>
            <div className="dop-mem">
              {classArray?.map((el, i) => {
                return (
                  <Checkbox
                    key={i}
                    checked={!!filterElements?.class?.find((q) => q === el.id)}
                    // id={"checkbox"}
                    className="checkbox"
                    position="top"
                    onChange={(e) => {
                      let filter = filterElements?.class;
                      let find = filterElements?.class?.find((q) => q === el.id);
                      if (e.target.checked) {
                        !find && filter.push(el.id);
                      } else {
                        let f = filter.filter((number) => number !== el.id);
                        filter = f;
                      }
                      setFilterElements({ ...filterElements, class: filter });
                    }}
                  >
                    <div className="text-checkbox">{el?.name}</div>
                  </Checkbox>
                );
              })}
            </div>
          </div>
          <div className="line">
            <div className="title-line">Жанр</div>
            <div className="dop-mem">
              {genreArray?.map((el, i) => {
                return (
                  <Checkbox
                    key={i}
                    checked={!!filterElements?.genres?.find((q) => q === el.id)}
                    // id={"checkbox"}
                    className="checkbox"
                    position="top"
                    onChange={(e) => {
                      let filter = filterElements?.genres;
                      let find = filterElements?.genres?.find((q) => q === el.id);
                      if (e.target.checked) {
                        !find && filter.push(el.id);
                      } else {
                        let f = filter.filter((number) => number !== el.id);
                        filter = f;
                      }
                      setFilterElements({ ...filterElements, genres: filter });
                    }}
                  >
                    <div className="text-checkbox">{el?.name}</div>
                  </Checkbox>
                );
              })}
            </div>
          </div>
        </div>
        <div className="footer-menu">
          {/* <IonMenuToggle menu="menu-left-filter"> */}
          <Button
            typebtn="ghost"
            className="small width"
            onClick={async () => {
              setSliderValue(DEFAULT_RANGE);
              let data = urlSearch;
              let newd = {};
              if (data?.id) newd.id = data?.id;
              let value = DEFAULT;
              let arr = [];
              for (let i = 0; i < suitable?.length; i++) {
                arr.push({ suitable_id: suitable[i]?.id, cnt: 0 });
              }
              value.suitable_arr = arr;
              await setFilterElements({ ...value });
              const params = new URLSearchParams(newd);
              history.push(`?${params.toString()}`);
            }}
          >
            Сбросить
          </Button>
          {/* </IonMenuToggle> */}
          <IonMenuToggle menu="menu-left-filter">
            <Button
              disabled={!filterFirstPage.length}
              typebtn="bright"
              className="small width"
              onClick={() => onApply()}
            >
              {filterFirstPage.length ? `Применить` : `Нет подходящих`}
            </Button>
          </IonMenuToggle>
        </div>
      </div>
    </IonMenu>
  );
};

export default LeftMenuFilter;
