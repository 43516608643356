import { IonContent, IonPopover } from "@ionic/react";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import { useHistory } from "react-router";
import image from "../../image/temporary/1.png";

import AlertBox from "../../ui/AlertBox";
import Rating from "../../ui/Rating";
import { useDispatch, useSelector } from "react-redux";
import SalesSchedule from "./SalesSchedule";
import { STATUSES_TOURS } from "../../utils/array";
import Status from "../../ui/Status";
import { useEffect, useState } from "react";
import ModalSubmitForModeration from "./Modals/ModalSubmitForModeration";
import ModalOperNoModeration from "./Modals/ModalOperNoModeration";
import qs from "qs";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { deleteTour, getTourRaces, getToursByID, getToursOperator } from "../../utils/api";
import { setArrayTourRaces, setProducts, setTourFocus } from "../../store/actions";
import Badge from "../../ui/Badge";
import { nameForm, formatDate, checkTourStatus } from "../../utils/utils";
import ModalStartSelling from "./Modals/ModalStartSelling";
import ModalMakeDraft from "./Modals/ModalMakeDraft";
import ModalDelete from "./Modals/ModalDelete";
import { menuController } from "@ionic/core/components";
import SelectButton from "../../ui/SelectButton";
import ChartLine from "../../ui/ChartLine";
import Toast from "../../ui/Toast";
import { URL_ORIGIN } from "../../utils/config";
import ModalDeleteTour from "./Modals/ModalDeleteTour";

const TourInformation = () => {
  const token = useSelector((state) => state.token);
  const tour = useSelector((state) => state.tourFocus);
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  const tourReviews = tour?.clients_reviews?.filter((el) => el.type === 0 && !el.parent_id);
  const arrayTourRaces = useSelector((state) => state.arrayTourRaces);
  const operator = useSelector((state) => state.operator);
  const orders = tour?.orders;
  const [openAlert, setOpenAlert] = useState(true);

  const [orderGraph, setOrderGraph] = useState();
  const [orderGraphYear, setOrderGraphYear] = useState();

  const [load, setLoad] = useState();
  const [loadYear, setLoadYear] = useState();
  const [tourProgress, setTourProgress] = useState();
  const [forGraphOrder, setForGraphOrder] = useState([]);
  const [valForGraphOrder, setValForGraphOrder] = useState(0);
  const [forGraphLoad, setForGraphLoad] = useState([]);
  const [valForGraphLoad, setValForGraphLoad] = useState(0);
  const [valForGraphBusy, setValForGraphBusy] = useState(0);
  const [procent, setProcent] = useState(0);

  useAsyncEffect(async () => {
    tour && setTourProgress(checkTourStatus(tour, tour?.details));
  }, [tour]);

  const months = [
    { name: "январь", month: 1 },
    { name: "февраль", month: 2 },
    { name: "март", month: 3 },
    { name: "апрель", month: 4 },
    { name: "май", month: 5 },
    { name: "июнь", month: 6 },
    { name: "июль", month: 7 },
    { name: "август", month: 8 },
    { name: "сентябрь", month: 9 },
    { name: "октябрь", month: 10 },
    { name: "ноябрь", month: 11 },
    { name: "декабря", month: 12 },
  ];
  function createArrayYers() {
    const start = 2023;
    const end = +new Date().toLocaleDateString("ru", { year: "numeric" });
    const arr = [];

    for (let i = start; i <= end; ++i) {
      arr.push({ name: i });
    }
    return arr.reverse();
  }
  useEffect(() => {
    let serc = months.find(
      (el) => el.month === +new Date().toLocaleDateString("ru", { month: "numeric" })
    );
    setLoad(serc);
    setOrderGraph(serc);
    setOrderGraphYear({ name: +new Date().toLocaleDateString("ru", { year: "numeric" }) });
    setLoadYear({ name: +new Date().toLocaleDateString("ru", { year: "numeric" }) });
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();

  const urlSearch = qs.parse(history.location.search.substr(1));

  useAsyncEffect(async () => {
    await getToursByID(token, urlSearch.id).then((e) => {
      dispatch(setTourFocus(e.data.data));
    });
    await getTourRaces(token, urlSearch.id).then((e) => {
      if (e.data.data) {
        dispatch(setArrayTourRaces(e.data.data));
      }
    });
    await menuController.isEnabled(`right-add`);
  }, []);

  useAsyncEffect(() => {
    let arr = arrayTourRaces;
    let filter = arr?.filter(
      (el) =>
        `${+new Date(el?.created_at).toLocaleDateString("ru", {
          month: "numeric",
        })}.${+new Date(el?.created_at).toLocaleDateString("ru", {
          year: "numeric",
        })}` === `${load?.month}.${loadYear?.name}`
    );
    let orderCalc =
      loadYear &&
      load &&
      Array(new Date(loadYear?.name, load?.month, 0).getDate())
        .fill()
        .map((e, i) => {
          let calc = 0;
          let busy = 0;
          for (let j = 0; j < filter?.length; j++) {
            if (
              i + 1 ===
              +new Date(filter[j]?.created_at).toLocaleDateString("ru", { day: "numeric" })
            ) {
              calc += +filter[j]?.number_travelers;
            }
          }
          return calc;
        });
    let busyCalc =
      loadYear &&
      load &&
      Array(new Date(loadYear?.name, load?.month, 0).getDate())
        .fill()
        .map((e, i) => {
          let busy = 0;
          for (let j = 0; j < filter?.length; j++) {
            if (
              i + 1 ===
              +new Date(filter[j]?.created_at).toLocaleDateString("ru", { day: "numeric" })
            ) {
              busy += +filter[j]?.participants_count;
            }
          }
          return busy;
        });

    let valRef = 0;
    let valBus = 0;
    orderCalc?.map((item) => (valRef += item));
    busyCalc?.map((item) => (valBus += item));
    setValForGraphBusy(valBus);
    setValForGraphLoad(valRef);
    let proc = valBus !== 0 ? (valBus / valRef) * 100 : 0;
    setProcent(proc);
    setForGraphLoad(orderCalc);
  }, [load, loadYear, arrayTourRaces]);

  useAsyncEffect(() => {
    let arr = orders;
    let filter = arr?.filter(
      (el) =>
        `${+new Date(el?.created_at).toLocaleDateString("ru", {
          month: "numeric",
        })}.${+new Date(el?.created_at).toLocaleDateString("ru", {
          year: "numeric",
        })}` === `${orderGraph?.month}.${orderGraphYear?.name}`
    );
    let orderCalc =
      orderGraphYear &&
      orderGraph &&
      Array(new Date(orderGraphYear?.name, orderGraph?.month, 0).getDate())
        .fill()
        .map((e, i) => {
          let calc = 0;
          for (let j = 0; j < filter?.length; j++) {
            if (
              i + 1 ===
              +new Date(filter[j]?.created_at).toLocaleDateString("ru", { day: "numeric" })
            ) {
              calc += +filter[j]?.summa;
            }
          }
          return calc;
        });
    let valRef = 0;
    orderCalc?.map((item) => (valRef += item));
    setValForGraphOrder(valRef?.toFixed(0));
    setForGraphOrder(orderCalc);
  }, [orderGraph, orderGraphYear, orders]);

  const statuses = STATUSES_TOURS;
  const [modalForModeration, setModalForModeration] = useState(false);
  const [modalOperNoModeration, setModalOperNoModeration] = useState(false);
  const [modalStartSelling, setModalStartSelling] = useState(false);
  const [modalMakeDraft, setModalmakeDraft] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [modalDeleteTour, setModalDeleteTour] = useState(false);

  const status = statuses.find((el) => el.status === tour.status_id);

  return (
    <IonContent className="page">
      <div className="tour-info-page">
        <div className="upper-line">
          <div className="tbr">
            <div className="cont-l">
              <Button
                typebtn="outline"
                className="btn-back small btn-back"
                onClick={() => {
                  history.push("/partner/showcase/tours?page=1");
                  dispatch(setTourFocus(false));
                }}
              >
                <Icons icon="design-personal-chevron-left" className="icon-outline" />
              </Button>
              <div className="title">{tour.name}</div>
            </div>
            <div className="btns">
              <Button
                typebtn="outline"
                className="small"
                // disabled={
                //   tour.status_id === 2 ||
                //   tour.status_id === 4 ||
                //   tour.status_id === 5 ||
                //   tour.status_id === 6
                // }
                onClick={() => {
                  history.push(`/partner/showcase/tours/create?id=${urlSearch.id}`);
                  dispatch(setTourFocus(false));
                }}
              >
                Параметры тура
              </Button>
              {(tour.status_id === 1 || tour.status_id === 3) &&
                tourProgress?.one &&
                tourProgress?.two &&
                tourProgress?.three && (
                  <Button
                    typebtn="bright"
                    className="small"
                    onClick={() => {
                      if (operator.status === 2) {
                        setModalForModeration(true);
                      } else if (operator.status === 0 || operator.status === 1) {
                        setModalOperNoModeration(true);
                      }
                    }}
                  >
                    На модерацию
                  </Button>
                )}
              {(tour.status_id === 4 || tour.status_id === 6) && (
                <Button
                  typebtn="bright"
                  className="small"
                  onClick={() => {
                    if (arrayTourRaces?.length) {
                      setModalStartSelling(true);
                    } else {
                      setOpenToast(true);
                      setMessageToast({
                        text: "Чтобы начать продажи, нужно добавить даты в расписание",
                        type: "warning",
                      });
                    }
                  }}
                >
                  Начать продажи
                </Button>
              )}
              {tour.status_id === 5 && (
                <Button
                  typebtn="muted"
                  className="small"
                  onClick={() => {
                    setModalmakeDraft(true);
                  }}
                >
                  Сделать черновиком
                </Button>
              )}
              <Button
                disabled={tour.status_id === 2}
                typebtn="outline"
                className="small cube"
                id="bottom-start-d"
                onClick={() => setOpenPopover(true)}
              >
                <Icons icon="dots-vertical" />
              </Button>
            </div>
          </div>

          <div className="status-cont">
            <Status color={status?.color} className="status-h">
              {status?.name}
            </Status>
          </div>
          <div className="last-change">{`Последнее изменение ${formatDate(tour?.updated_at)}, ${
            tour.last_edit_user?.first_name
          } ${tour.last_edit_user?.last_name[0] || ""}.`}</div>
        </div>
        {openAlert && !tour?.races?.length && (
          <AlertBox
            className="alert-tour-info"
            type="warning"
            text="Чтобы начать продажи, нужно добавить даты в расписание"
            title="Добавьте даты"
            onClick={() => setOpenAlert(false)}
          />
        )}
        <div className="cont-information">
          <div className="block-1">
            {tour.photo ? (
              <div className="item-image">
                <div className="val-photo">+5</div>
                <img src={`${URL_ORIGIN}${tour.photo}`} />
              </div>
            ) : (
              <div className="item-image no-image">
                <Icons icon="no-image" className=" img-no" />
              </div>
            )}
            <div className="item-rating">
              <Rating rate={tour?.rating?.[0]?.avg} className="rat" />
              <div className="text-rat">
                {tour?.rating?.[0]?.avg ? tour?.rating?.[0]?.avg : "0"}
              </div>
            </div>
          </div>
          <div className="block-2">
            <div className="upper">
              <div className="item">
                <Icons icon="info-square-rounded" className="icon-muted-info info-icon" />
                <div className="title-cont">
                  <div className="title">Заказы</div>
                  <SelectButton
                    value={orderGraph}
                    array={months}
                    className="ghost select-finance"
                    onChange={(e) => {
                      setOrderGraph(e.value);
                    }}
                  />
                  <SelectButton
                    value={orderGraphYear}
                    array={createArrayYers()}
                    className="ghost select-finance-year"
                    onChange={(e) => {
                      setOrderGraphYear(e.value);
                    }}
                  />
                </div>
                <div className="char">
                  <div className="char-text">
                    <div className="val">{`${String(valForGraphOrder).replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      " "
                    )} ₽`}</div>
                    <div className="prc">{`${0} %`}</div>
                  </div>
                  <ChartLine
                    color="green"
                    data={forGraphOrder}
                    labels={new Date(orderGraphYear?.name, orderGraph?.month, 0).getDate()}
                  />
                </div>

                <Button typebtn="ghost" className="small btn-watch">
                  Смотреть
                  <Icons icon="arrow-narrow-right" />
                </Button>
              </div>
              <div className="item">
                <Icons icon="info-square-rounded" className="icon-muted-info info-icon" />
                <div className="title-cont">
                  <div className="title">Загрузка</div>
                  <SelectButton
                    value={load}
                    array={months}
                    className="ghost select-finance"
                    onChange={(e) => {
                      setLoad(e.value);
                    }}
                  />
                  <SelectButton
                    value={loadYear}
                    array={createArrayYers()}
                    className="ghost select-finance-year"
                    onChange={(e) => {
                      setLoadYear(e.value);
                    }}
                  />
                </div>
                <div className="cont-load">
                  <div className="seat">
                    <div className="val-text">{valForGraphBusy}</div>
                    <div className="val-subtext">{`/ ${valForGraphLoad} мест`}</div>
                  </div>
                  <div className="line-graph">
                    <div className="val-graph" style={{ width: `${procent}%` }}></div>
                  </div>
                </div>
                <Button typebtn="ghost" className="small btn-watch">
                  Смотреть
                  <Icons icon="arrow-narrow-right" />
                </Button>
              </div>
              <div className="item">
                <div className="body-item">
                  <div className="genre">
                    {tour?.genres?.map((el, i) => {
                      return <Badge key={i}>{el.name}</Badge>;
                    })}
                  </div>
                  <div>
                    <div className="create">
                      <div className="subtext">Дата создания</div>
                      <div className="text">{formatDate(tour?.created_at)}</div>
                    </div>
                    <div className="responsible">
                      <div className="subtext">Ответственный</div>
                      <div className="text">{`${tour?.user?.first_name} (${tour?.user?.operator_user?.operator_role})`}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lower">
              <div className="small-item">
                <Icons icon="info-square-rounded" className="icon-muted-info info-icon" />
                <div className="title">Предложений</div>
                <div className="value">{arrayTourRaces?.length}</div>
              </div>
              <div className="small-item">
                <Icons icon="info-square-rounded" className="icon-muted-info info-icon" />
                <div className="title">Акций</div>
                <div className="value">{tour?.promotions?.length || "0"}</div>
              </div>
              <div className="small-item">
                <Icons icon="info-square-rounded" className="icon-muted-info info-icon" />
                <div className="title">Отзывов</div>
                <div className="value">{tourReviews?.length}</div>
              </div>
              <div className="small-item">
                <Icons icon="info-square-rounded" className="icon-muted-info info-icon" />
                <div className="title">В избранном</div>
                <div className="value">{tour?.favorites}</div>
              </div>
              <div className="small-item">
                <Icons icon="info-square-rounded" className="icon-muted-info info-icon" />
                <div className="title">Штрафы</div>
                <div className="cont-val">
                  <div className="value">0 ₽</div>
                  <Icons icon="succes" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <SalesSchedule />
      </div>
      <ModalSubmitForModeration
        open={modalForModeration}
        onClose={setModalForModeration}
        element={tour}
        fromInfo={true}
      />
      <ModalOperNoModeration open={modalOperNoModeration} onClose={setModalOperNoModeration} />
      <ModalStartSelling
        open={modalStartSelling}
        onClose={setModalStartSelling}
        element={tour}
        fromInfo={true}
      />
      <ModalMakeDraft
        open={modalMakeDraft}
        onClose={setModalmakeDraft}
        element={tour}
        fromInfo={true}
      />

      <ModalDeleteTour open={modalDeleteTour} onClose={setModalDeleteTour} focusElement={tour} />
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
      <IonPopover
        class="popover-menu-el-list"
        isOpen={openPopover}
        onWillDismiss={() => setOpenPopover(false)}
        trigger={openPopover !== false ? `bottom-start-d` : undefined}
        side="bottom"
        alignment="start"
      >
        <div className="body-popover">
          <div
            className="cont-el-pop"
            onClick={() => {
              setOpenPopover(false);
              setModalDeleteTour(true);
            }}
          >
            <Icons icon="design-personal-trash" />
            <div className="text">Удалить</div>
          </div>
        </div>
      </IonPopover>
    </IonContent>
  );
};

export default TourInformation;
