import { IonMenu, IonMenuToggle, IonPopover } from "@ionic/react";
import { useEffect, useMemo, useState } from "react";
import Calendar from "../../ui/Calendar";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../ui/Input";
import { useHistory } from "react-router";
import { setArrayTourRaces, setFormCreateRaces } from "../../store/actions";
import Button from "../../ui/Button";
import qs from "qs";
import Icon from "ol/style/Icon";
import Icons from "../../ui/Icons";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { AutoComplete } from "primereact/autocomplete";
import AutoCompleteSelect from "../../ui/AutoCompleteSelect";
import Checkbox from "../../ui/Checkbox";
import noImage from "../../image/no-image-s.svg";
import { getTourRaces, getTourRacesByID, postTourRaces, putTourRaces } from "../../utils/api";
import ModalCreateOptions from "./Modals/ModalCreateOptions";
import { URL_ORIGIN } from "../../utils/config";

const RightMenuRacesChange = (props) => {
  const token = useSelector((state) => state.token);
  const history = useHistory();
  const dispatch = useDispatch();
  const options = useSelector((state) => state.options);
  const urlSearch = qs.parse(history.location.search.substr(1));
  const [openPopover, setOpenPopover] = useState();
  const [arrOptions, setArrOptions] = useState([]);
  const [openModalCreateOption, setOpenModalCreateOption] = useState(false);
  const formCreateRaces = useSelector((state) => state.formCreateRaces);
  const [page, setPage] = useState();

  // function getDatesInRange(startDate, endDate) {
  //   const date = new Date(startDate.getTime());

  //   date.setDate(date.getDate());

  //   const dates = [];

  //   while (date <= endDate) {
  //     dates.push(new Date(date).toLocaleDateString("ru"));
  //     date.setDate(date.getDate() + 1);
  //   }

  //   return dates;
  // }

  useAsyncEffect(() => {
    setArrOptions(formCreateRaces?.operator_options);
  }, [formCreateRaces]);

  // useAsyncEffect(() => {
  //   if (formCreateRaces.period_start && formCreateRaces.period_end) {
  //     const d1 = new Date(formCreateRaces.period_start);
  //     const d2 = new Date(formCreateRaces.period_end);
  //     dispatch(setFormCreateRaces({ ...formCreateRaces, start_date: getDatesInRange(d1, d2) }));
  //   } else {
  //     dispatch(setFormCreateRaces({ ...formCreateRaces, start_date: [] }));
  //   }
  // }, [formCreateRaces.period_start, formCreateRaces.period_end]);

  // useAsyncEffect(() => {
  //   dispatch(setFormCreateRaces({ ...formCreateRaces, start_date: null }));
  // }, [activeTabs]);

  const onSave = () => {
    let arrEl = formCreateRaces;
    let arrOpt = arrOptions;
    let arrOptionsID = [];
    for (let i = 0; i < arrOpt.length; i++) {
      arrOptionsID.push(arrOpt[i]?.id);
    }

    let forRequest = {
      number_travelers: formCreateRaces?.number_travelers,
      summa: formCreateRaces?.summa,
      currency_id: "RUB",
      start_date: formCreateRaces?.start_date,
      end_date: formCreateRaces?.end_date,
      operator_options: arrOptionsID,
    };
    putTourRaces(token, formCreateRaces?.product_id, forRequest, formCreateRaces?.id).then((e) => {
      if (!e.data.errors) {
        getTourRaces(token, formCreateRaces?.product_id).then((e) => {
          if (e.data.data) {
            dispatch(setArrayTourRaces(e.data.data));
          }
        });
      }
    });
  };

  return (
    <>
      <IonMenu
        // ref={refMenu}
        side="end"
        type="overlay"
        swipeGesture={false}
        menuId="menu-right-add-races-change"
        contentId="right-add"
        className="menu-right-add-races-change"
        onIonDidClose={async (e) => {
          dispatch(setFormCreateRaces([]));
          setArrOptions([]);
          setPage(1);
        }}
      >
        <div className="cont-menu-right-add-races">
          <div className="cont-title">
            <div className="title">Добавление опций</div>
            <div className="subtext">
              Выберите опции из справочника или создайте новые. После добавления опций, можно
              поменять значения стоимости и количества мест, либо заранее измените элемент
              справочника.
            </div>
          </div>
          <div className="l" />
          <div className="sel-options">
            <Input
              array={options}
              type="select-checkbox"
              placeholder="Выберите опцию..."
              className="small"
              image="chevron-down"
              onSelect={setArrOptions}
              select={arrOptions}
            />
            <Button
              typebtn="muted"
              className="small"
              onClick={() => setOpenModalCreateOption(true)}
            >
              <Icons icon="plus" />
              Создать
            </Button>
          </div>

          <div className="options">
            {arrOptions?.map((el, i) => {
              return (
                <div key={i} className="cont-opt">
                  <div className="cont-image">
                    <img
                      src={el.pictures.length ? `${URL_ORIGIN}${el.pictures?.[0]?.photo}` : noImage}
                    />
                  </div>
                  <div className="cont-inf">
                    <div className="text">{el.name}</div>
                    <div className="subtext">{`Цена: ${String(+el.basic_cost)?.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      " "
                    )} ₽`}</div>
                    <div className="subtext">{`Мест: ${+el.basic_volume}`}</div>
                  </div>
                  <Button
                    id={`bottom-start-${i}`}
                    typebtn="ghost"
                    className="small cube btn-dot"
                    onClick={() => {
                      setOpenPopover({
                        index: i,
                      });
                    }}
                  >
                    <Icons icon="dots-vertical" />
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
        <div id={`null`} />
        <div className="btns">
          <IonMenuToggle menu="menu-right-add-races-change">
            <Button
              typebtn="outline"
              className="small"
              onClick={() => {
                let data = urlSearch;
                delete data.product_race;
                const params = new URLSearchParams(data);
                history.push(`?${params.toString()}`);
              }}
            >
              Отмена
            </Button>
          </IonMenuToggle>

          <IonMenuToggle menu="menu-right-add-races-change">
            <Button
              typebtn="bright"
              className="small"
              onClick={() => {
                onSave();
              }}
            >
              Сохранить
            </Button>
          </IonMenuToggle>
        </div>
      </IonMenu>
      <ModalCreateOptions onClose={setOpenModalCreateOption} open={openModalCreateOption} />
      <IonPopover
        class="popover-menu-el-list"
        isOpen={openPopover?.index || (openPopover?.index === 0 && true)}
        onWillDismiss={() => setOpenPopover(false)}
        trigger={openPopover !== false ? `bottom-start-${openPopover?.index}` : undefined}
        side="bottom"
        alignment="start"
      >
        <div className="body-popover">
          <div
            className="cont-el-pop"
            onClick={() => {
              setOpenPopover(false);
              let arr = arrOptions;
              let el = arrOptions[openPopover?.index];
              arr.splice(openPopover.index, 1);
              setArrOptions(arr);
            }}
          >
            <Icons icon="design-personal-trash" />
            <div className="text">Удалить</div>
          </div>
        </div>
      </IonPopover>
    </>
  );
};

export default RightMenuRacesChange;
