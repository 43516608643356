import { IonContent } from "@ionic/react";
import "../../less/css/settings.css";
import AlertBox from "../../ui/AlertBox";
import Input from "../../ui/Input";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../ui/Button";
import Status from "../../ui/Status";
import { useState } from "react";
import { getOperator, putOperator } from "../../utils/api";
import { setOperator } from "../../store/actions";
import Toast from "../../ui/Toast";

const CompanySettingsContent = () => {
  const userPermissions = useSelector((state) => state.userPermissions.settings?.company);

  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const operator = useSelector((state) => state.operator);

  const token = useSelector((state) => state.token);
  const timezone = new Date(operator?.updated_at).getTimezoneOffset() / 60;
  const lastChangeDate = `${new Date(operator?.updated_at).toLocaleString().slice(0, 17)} (UTC${
    +timezone < 0 ? `+${+timezone * -1 > 9 ? "" : "0"}${+timezone * -1}:00` : `-${+timezone * -1}`
  })`;
  const [openAlert, setOpenAlert] = useState(true);
  return (
    <>
      <IonContent className="page">
        <div className="company-settings-page">
          <div className="upper-cont">
            <div className="tbr">
              <div className="title">Данные компании</div>
              <Button
                loading={loading}
                form="company-inf"
                typebtn="bright"
                className="btn-save small"
                disabled={operator.status === 1 || loading}
              >
                Сохранить
              </Button>
            </div>
            <div className="status-cont">
              {operator.status === 1 ? (
                <Status
                  color={"orange"}
                >{`${operator?.status_name?.[0]?.toUpperCase()}${operator?.status_name?.slice(
                  1
                )}`}</Status>
              ) : operator.status === 2 ? (
                <Status
                  color={"green"}
                >{`${operator?.status_name?.[0]?.toUpperCase()}${operator?.status_name?.slice(
                  1
                )}`}</Status>
              ) : operator.status === 3 ? (
                <Status
                  color={"red"}
                >{`${operator?.status_name?.[0]?.toUpperCase()}${operator?.status_name?.slice(
                  1
                )}`}</Status>
              ) : operator.status === 4 ? (
                <Status
                  color={"gray"}
                >{`${operator?.status_name?.[0]?.toUpperCase()}${operator?.status_name?.slice(
                  1
                )}`}</Status>
              ) : undefined}
            </div>
            {operator.status !== 0 && (
              <div className="last-change">{`Последнее изменение ${lastChangeDate}, ${
                operator?.updated_user?.first_name
              } ${operator?.updated_user?.last_name ? operator?.updated_user?.last_name?.[0] : ""}.
            `}</div>
            )}
          </div>
          {openAlert && operator?.status === 0 && (
            <AlertBox
              className="alert-register-partner"
              type="warning"
              text="После сохранения, данные будут отправлены на модерацию. Вносить изменения во время модерации нельзя."
              onClick={() => setOpenAlert(false)}
            />
          )}
          {/* <div className="inf-cont"> */}
          <form
            id="company-inf"
            className="inf-cont"
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              let operatorArr = {};
              let inputs = e.target.querySelectorAll("input");
              inputs.forEach((input) => (operatorArr[input.name] = input.value));
              operatorArr.status = 1;
              if (userPermissions[1]) {
                putOperator(token, operator.id, operatorArr).then(async (e) => {
                  if (!e.data.errors) {
                    await getOperator(token).then((e) => {
                      dispatch(setOperator(e.data.data[0]));
                    });
                  }
                });
              } else {
                setOpenToast(true);
                setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
              }
              setLoading(false);
            }}
          >
            <div className="contl">
              <div className="line-input">
                <div className="text">Полное наименование</div>
                <Input
                  disabled={operator.status === 1}
                  className={`input-company large ${operator.status === 1 ? "disabled" : ""}`}
                  value={operator?.name}
                  name="name"
                  placeholder="не указано..."
                  noImage={true}
                />
              </div>
              <div className="line-input line-input-with-descr">
                <div className="text">Краткое наименование</div>
                <div className="input-descr-cont">
                  <Input
                    value={operator?.name_legal}
                    name="name_legal"
                    disabled={operator.status === 1}
                    className={`input-company large ${operator.status === 1 ? "disabled" : ""}`}
                    placeholder="не указано..."
                    noImage={true}
                  />
                  <div className="descr">Отображается на страницах туров</div>
                </div>
              </div>
              <div className="line-input">
                <div className="text">Юр. адрес</div>
                <Input
                  value={operator?.address_legal}
                  name="address_legal"
                  disabled={operator.status === 1}
                  className={`input-company large ${operator.status === 1 ? "disabled" : ""}`}
                  placeholder="не указано..."
                  noImage={true}
                />
              </div>
              <div className="line-input">
                <div className="text">ИНН</div>
                <Input
                  value={operator?.inn}
                  name="inn"
                  disabled={operator.status === 1}
                  className={`input-company large ${operator.status === 1 ? "disabled" : ""}`}
                  placeholder="не указано..."
                  noImage={true}
                />
              </div>
              <div className="line-input">
                <div className="text">КПП</div>
                <Input
                  value={operator?.kpp}
                  name="kpp"
                  disabled={operator.status === 1}
                  className={`input-company large ${operator.status === 1 ? "disabled" : ""}`}
                  placeholder="не указано..."
                  noImage={true}
                />
              </div>
              <div className="line-input">
                <div className="text">ОГРН</div>
                <Input
                  value={operator?.ogrn}
                  name="ogrn"
                  disabled={operator.status === 1}
                  className={`input-company large ${operator.status === 1 ? "disabled" : ""}`}
                  placeholder="не указано..."
                  noImage={true}
                />
              </div>
              <div className="line-input">
                <div className="text">Р. / счёт</div>
                <Input
                  value={operator?.settlement_account}
                  name="settlement_account"
                  disabled={operator.status === 1}
                  className={`input-company large ${operator.status === 1 ? "disabled" : ""}`}
                  placeholder="не указано..."
                  noImage={true}
                />
              </div>
              <div className="line-input">
                <div className="text">БИК</div>
                <Input
                  value={operator?.bic}
                  name="bic"
                  disabled={operator.status === 1}
                  className={`input-company large ${operator.status === 1 ? "disabled" : ""}`}
                  placeholder="не указано..."
                  noImage={true}
                />
              </div>
              <div className="line-input">
                <div className="text">Банк</div>
                <Input
                  value={operator?.bank_name}
                  name="bank_name"
                  disabled={operator.status === 1}
                  className={`input-company large ${operator.status === 1 ? "disabled" : ""}`}
                  placeholder="не указано..."
                  noImage={true}
                />
              </div>
              <div className="line-input">
                <div className="text">ФИО ген. директора</div>
                <Input
                  value={operator?.manager_name}
                  name="manager_name"
                  disabled={operator.status === 1}
                  className={`input-company large ${operator.status === 1 ? "disabled" : ""}`}
                  placeholder="не указано..."
                  noImage={true}
                />
              </div>
            </div>
            <div className="contr">
              <div className="conteiner">
                <div className="title">Контактная информация</div>

                <div className="line-r">
                  <div className="text">Факт. адрес</div>
                  <Input
                    value={operator?.address}
                    name="address"
                    disabled={operator.status === 1}
                    className={`input-company large ${operator.status === 1 ? "disabled" : ""}`}
                    placeholder="не указано..."
                    noImage={true}
                  />
                  <div className="helper-area">{operator?.address}</div>
                </div>
                <div className="line-r">
                  <div className="text">Телефон</div>
                  <Input
                    value={operator?.phone}
                    name="phone"
                    type="tel"
                    disabled={operator.status === 1}
                    className={`input-company large ${operator.status === 1 ? "disabled" : ""}`}
                    placeholder="не указано..."
                    noImage={true}
                  />
                </div>
                <div className="line-r">
                  <div className="text">E-mail</div>
                  <Input
                    value={operator?.email}
                    name="email"
                    type="email"
                    disabled={operator.status === 1}
                    className={`input-company large ${operator.status === 1 ? "disabled" : ""}`}
                    placeholder="не указано..."
                    noImage={true}
                  />
                </div>
              </div>
            </div>
          </form>
          {/* </div> */}
        </div>
      </IonContent>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </>
  );
};

export default CompanySettingsContent;
