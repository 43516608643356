import { IonModal } from "@ionic/react";
import "../../less/css/firstpage.css";
import { useDispatch, useSelector } from "react-redux";
import { setModalGreetings } from "../../store/actions";
import Close from "../../ui/Close";
import background from "../../image/modal-background-decor-greetings.svg";
import img1 from "../../image/greetings1.png";
import img2 from "../../image/greetings2.png";
import img3 from "../../image/greetings3.png";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";

const ModalGreetings = () => {
  const modalGreetings = useSelector((state) => state.modalGreetings);
  const dispatch = useDispatch();
  return (
    <IonModal
      showBackdrop={true}
      isOpen={modalGreetings}
      onWillDismiss={() => {
        dispatch(setModalGreetings(false));
        localStorage.setItem("greetings", false);
      }}
      className="modal-greetings"
    >
      <Close
        onClick={() => {
          dispatch(setModalGreetings(false));
          localStorage.setItem("greetings", false);
        }}
      />
      <img className="background-image" src={background} />
      <div className="modal-greetings-cont">
        <div className="title-cont">
          <div className="title">
            #смотри<div className="red">родину</div>
          </div>
          <div className="lower-title">автопутешествия по России</div>
        </div>
        <Button
          className="btn-greetings large"
          typebtn="bright"
          onClick={() => {
            dispatch(setModalGreetings(false));
          }}
        >
          искать туры
          <Icons icon="arrow-narrow-right" className="icon-bright" />
        </Button>
        <div className="items">
          <div className="item">
            <img className="img" src={img1} />
            <div className="text-cont">
              <div className="bold">Выбирай маршруты</div>
              <div className="low">Готовые 1-3 дневные туры любых жанров с размещением.</div>
            </div>
          </div>
          <div className="item">
            <img className="img" src={img2} />
            <div className="text-cont">
              <div className="bold">Призжай на машине</div>
              <div className="low">С друзьями или семьёй — туры сразу на несколько участников.</div>
            </div>
          </div>
          <div className="item">
            <img className="img" src={img3} />
            <div className="text-cont">
              <div className="bold">Отдыхай по-новому</div>
              <div className="low">Уникальные места, которые заставят влюбиться в Россию.</div>
            </div>
          </div>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalGreetings;
