import { IonModal } from "@ionic/react";
import { useState } from "react";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import {
  getProductsClientByID,
  getProductsGuestByID,
  postImageReviews,
  postReviews,
} from "../../utils/api";
import qs from "qs";
import { useHistory } from "react-router";
import noImage from "../../image/no-image-s.svg";
import star from "../../image/icons/stars.png";
import AlertBox from "../../ui/AlertBox";
import { useDispatch, useSelector } from "react-redux";
import { setArrayPointsTour, setClientTour, setOpenModalCreateReport } from "../../store/actions";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import Close from "../../ui/Close";
import Toast from "../../ui/Toast";
import { URL_ORIGIN } from "../../utils/config";
import Icons from "../../ui/Icons";
import ModalDelete from "../showcase/Modals/ModalDelete";

const ClientModalCreateReport = (props) => {
  const modalCreateReport = useSelector((state) => state.openModalCreateReport);

  const element = useSelector((state) => state.clientTour);
  const token = useSelector((state) => state.token);
  const [modalDeleteOperUser, setModalDeleteOperUser] = useState(false);
  const [typePhoto, setTypePhoto] = useState();

  const history = useHistory();
  const dispatch = useDispatch();
  const urlSearch = qs.parse(history.location.search.substr(1));
  const reviews = element?.clients_reviews?.filter((el) => el.type === 0 && !el.parent_id);
  const [report, setReport] = useState();
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  const [photoRev, setPhotoRev] = useState();
  const [loading, setLoading] = useState();

  const onAsk = async () => {
    setLoading(true);

    let forSend = {
      type: 3,
      product_id: element?.id,
      review_text: report,
      status_id: 1,
    };
    await postReviews(token, forSend)
      .then(async (e) => {
        if (!e.data.errors) {
          const formDataRevImage = new FormData();

          photoRev &&
            Object.entries(photoRev).forEach(([key, value]) => {
              formDataRevImage.append(`review_pictures[]`, value);
            });
          photoRev &&
            (await postImageReviews(token, formDataRevImage, e.data.order.id).then((e) => {
              if (!e.data.errors) {
                setLoading(false);
              } else {
                setLoading(false);
              }
            }));
          setLoading(false);
          setOpenToast(true);
          setMessageToast({ text: "Жалоба отправлена", type: "success" });
          dispatch(setOpenModalCreateReport(false));
          if (token) {
            getProductsClientByID(token, element?.id).then((e) => {
              if (!e.data.errors) {
                dispatch(setClientTour(e.data.data));
                dispatch(setArrayPointsTour(e.data.data.details));
              }
            });
          } else {
            getProductsGuestByID(element?.id).then((e) => {
              if (!e.data.errors) {
                dispatch(setClientTour(e.data.data));
                dispatch(setArrayPointsTour(e.data.data.details));
              }
            });
          }
        } else {
          setOpenToast(true);
          setMessageToast({
            text: e.data?.error?.[0] || "Ошибка создания жалобы",
            type: "error",
          });
        }
      })
      .catch((e) => {
        setOpenToast(true);
        setMessageToast({
          text: e.response.data?.error?.[0] || "Ошибка создания жалобы",
          type: "error",
        });
      });
  };
  return (
    <>
      <IonModal
        className="modal-create-question"
        isOpen={modalCreateReport}
        onWillDismiss={() => dispatch(setOpenModalCreateReport(false))}
      >
        <Close onClick={() => dispatch(setOpenModalCreateReport(false))} />
        <div className="cont-create-quest">
          <div className="title">Пожаловаться на тур</div>
          <div className="info-tour">
            <div className="image">
              <img src={element?.photo ? `${URL_ORIGIN}${element?.photo}` : noImage} />
            </div>
            <div className="inf-cont">
              <div className="rate-cont">
                <img src={star} />
                <div className="val">
                  {element?.rating?.[0]?.avg ? element?.rating?.[0]?.avg : 0}
                </div>
                <div className="reviews">{`${reviews?.length} отзывов`}</div>
              </div>
              <div className="text">{element?.name}</div>
            </div>
          </div>
          <Input
            placeholder="Опишите проблему..."
            noImage
            className="large area area-quest"
            type="textarea"
            onChange={(e) => {
              setReport(e);
            }}
          />
          <div className="add-image-cont">
            {photoRev?.map((z, indx) => {
              return (
                <div key={indx} className="cont-image-option">
                  <Button
                    typebtn="elevated"
                    className="small cube btn-del-img"
                    onClick={() => {
                      setTypePhoto({ type: 1, id: indx });
                      setModalDeleteOperUser(true);
                    }}
                  >
                    <Icons icon="x" />
                  </Button>
                  <img className="main-img" src={URL.createObjectURL(z)}></img>
                </div>
              );
            })}
            <div className="add">
              <div>
                <input
                  type="file"
                  name="file"
                  id="file-rev-photo"
                  className="inputfile"
                  accept="image/jpeg, image/png"
                  multiple
                  onChange={(e) => {
                    let arr = [];
                    for (var i = 0; i < e.target.files.length; i++) {
                      arr.push(e.target.files[i]);
                    }
                    if (photoRev)
                      for (var i = 0; i < photoRev.length; i++) {
                        arr.push(photoRev[i]);
                      }
                    setPhotoRev(arr);
                  }}
                />
                <label className="image add-img" htmlFor="file-rev-photo">
                  <Icons icon="photo-plus" />
                </label>
              </div>
              <div className="description-add-img">
                <div className="title">Прикрепить фото</div>
                <div className="cont-subtext">
                  <div className="subtext">Формат: JPG, PNG.</div>
                  <div className="subtext">Макс. 5МБ.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="btns">
            <Button
              typebtn="outline"
              className="small"
              onClick={() => dispatch(setOpenModalCreateReport(false))}
            >
              Отмена
            </Button>
            <Button
              disabled={loading}
              loading={loading}
              typebtn="bright"
              className="small"
              onClick={() => {
                onAsk();
              }}
            >
              Отправить
            </Button>
          </div>
        </div>
      </IonModal>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />

      <ModalDelete
        open={modalDeleteOperUser}
        onClose={setModalDeleteOperUser}
        title="Удаление фото"
        text="Вы действительно хотите удалить фото?"
        btn_delete="Да, удалить"
        onClick={() => {
          if (typePhoto.type === 1) {
            let arr = photoRev;
            arr.splice(typePhoto?.id, 1);
            // setPhotoRev(arr);
          } else if (typePhoto.type === 2) {
            let arr = photoRevPoints;
            let arrEl = photoRevPoints.find((aq) => aq.id === typePhoto?.id);
            let arrPhoto = arrEl.photo;
            arrPhoto?.splice(typePhoto?.id_photo, 1);
            // let push = { id: typePhoto?.id, photo: arrPhoto };
            // arr.filter((fil) => fil.id !== typePhoto?.id);
            // arr.push(push);
            // setPhotoRevPoints(arr);
          }
          setModalDeleteOperUser(false);
        }}
      />
    </>
  );
};

export default ClientModalCreateReport;
