import { useEffect, useMemo, useRef, useState } from "react";
import Input from "../ui/Input";
import noImage from "../image/no-image-s.svg";
import qs from "qs";
import backEmpty from "../image/background-empty-table.svg";

import {
  IonChip,
  IonIcon,
  IonLabel,
  IonMenuToggle,
  IonPopover,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import ModalChangeTable from "./ModalsFilter/ModalChangeTable";
import ModalFilterTable from "./ModalsFilter/ModalFilterTableEmployees";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import Icons from "../ui/Icons";
import Rating from "../ui/Rating";
import "../less/css/table-races.css";
import Button from "./Button";
import SelectButton from "./SelectButton";

import PopoverTable from "./PopoverTable";
import { useHistory, useLocation } from "react-router";
import { createDefaultMaskGenerator, mask } from "react-hook-mask";
import { useDispatch, useSelector } from "react-redux";
import Badge from "./Badge";
import { getOptions, getTourRaces, putOptions, putTourRaces } from "../utils/api";
import { setArrayTourRaces, setFormCreateRaces, setOptions } from "../store/actions";
import { URL_ORIGIN } from "../utils/config";
import MultiSelectChip from "./MultiSelectChip";
import AutoCompleteSelect from "./AutoCompleteSelect";
import ExcelJS from "exceljs";

const TableProductRaces = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const urlSearch = qs.parse(history.location.search.substr(1));
  const options = useSelector((state) => state.options);
  const genreArray = useSelector((state) => state.genre);
  const token = useSelector((state) => state.token);
  const [dateOt, setDateOt] = useState();
  const [dateDo, setDateDo] = useState();
  const [searchProm, setSearchProm] = useState();

  const [activeIndex, setActiveIndex] = useState();
  const defaultArray = useMemo(() => props.array, [props.array]);
  const [array, setArray] = useState(defaultArray);

  const [filterArray, setFilterArray] = useState();

  // const arrays = useMemo(() => setArray(props.array), [props.array]);
  // const [openMenuElem, setOpenMenuElem] = useState(false);
  const [pagePaginator, setPagePaginator] = useState(false);
  const [valuePagePaginator, setValuePagePaginator] = useState({ page: 5 });

  const [openModalChangeTable, setOpenModalChangeTable] = useState(false);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const [arrayCuts, setArrayCuts] = useState();
  const [sort, setSort] = useState({ el: null, sort: false, type: null });

  const header = props.header?.filter((el) => el.default);

  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("User Data");
    const headerRow = worksheet.addRow([]);
    const headerCells = Object.values(props.arrayForExcel);
    for (let i = 0; i < headerCells?.length; i++) {
      const column = Object.keys(headerCells[i]);
      for (let j = 0; j < column?.length; j++) {
        headerRow.getCell(j + 1).value = column[j];
      }
    }
    const rows = Object.values(props.arrayForExcel);
    for (let i = 0; i < rows?.length; i++) {
      const cells = Object.values(rows[i]);
      const rowData = [];
      for (let j = 0; j < cells.length; j++) {
        rowData.push(cells[j]);
      }
      worksheet.addRow(rowData);
      worksheet.columns.forEach(function (column, i) {
        let maxLength = 0;
        column["eachCell"]({ includeEmpty: true }, function (cell) {
          var columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
      });
    }
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
      });
      var link = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = link;
      a.download = `${props.nameFile}.xlsx`;
      a.click();
      // window.location = link;
      // saveAs(blob, `output.xlsx`);
    });
  };

  useAsyncEffect(async () => {
    await setArray(defaultArray);
  }, [defaultArray]);

  useAsyncEffect(() => {
    if (filterArray?.length) {
      setArray(filterArray);
    } else if (urlSearch?.options || urlSearch?.options_from || urlSearch?.options_until) {
      setArray(filterArray);
    } else {
      setArray(defaultArray);
    }
  }, [filterArray]);

  const weekdayFormat = (value) => {
    let weekday = new Date(value).toLocaleDateString("ru", { weekday: "short" });
    let returnWeekday = `${weekday[0].toUpperCase()}${weekday[1]}`;
    return returnWeekday;
  };

  useAsyncEffect(async () => {
    await props.setOpenMenuElem(false);
  }, [location]);

  useEffect(() => {
    if (location.pathname === "/partner/showcase/tours/details") {
      if (
        !urlSearch.page ||
        urlSearch?.page > arrayCuts?.length ||
        urlSearch?.page < 1 ||
        Object.keys(urlSearch).length > 1
      ) {
        history.push(`?id=${urlSearch?.id}&page=1`);
        setPage(1);
      }
    }
  }, []);

  let arrayCut = [];
  array?.forEach((el, i) => {
    el.num = i + 1;
  });

  useAsyncEffect(() => {
    if (!array?.length) {
      setArrayCuts([]);
    } else {
      for (
        let i = 0;
        i <
        Math.ceil(
          array?.length /
            (valuePagePaginator.page !== "Все" ? valuePagePaginator.page : array?.length)
        );
        i++
      ) {
        if (valuePagePaginator.page !== "Все") {
          arrayCut[i] = array?.slice(
            i * valuePagePaginator.page,
            i * valuePagePaginator.page + valuePagePaginator.page
          );
          setArrayCuts(arrayCut);
        } else {
          arrayCut[0] = array;
          setArrayCuts(arrayCut);
        }
      }
    }
  }, [valuePagePaginator, array, sort]);

  const paginatorPage = [{ page: 5 }, { page: 25 }, { page: 50 }, { page: 100 }, { page: "Все" }];

  const onChangeOpt = () => {
    let arr = props.openChangeElem;
    let arrForSend;
    if (arr.field === "summa") {
      arrForSend = {
        basic_cost: arr.value,
        basic_volume: arr.element.basic_volume,
        description: arr.element.description,
        name: arr.element.name,
        status: arr.element.status,
      };
    } else if (arr.field === "number_travelers") {
      arrForSend = {
        basic_cost: arr.element.basic_cost,
        basic_volume: arr.value,
        description: arr.element.description,
        name: arr.element.name,
        status: arr.element.status,
      };
    }
    putOptions(token, arr.element.id, arrForSend).then(async (e) => {
      if (!e.data.errors) {
        getTourRaces(token, urlSearch.id).then((e) => {
          if (e.data.data) {
            dispatch(setArrayTourRaces(e.data.data));
          }
        });
        props.setOpenChangeElem(false);
      }
    });
  };
  const onChangeEl = () => {
    let arr = props.openChangeElem;
    let arrForSend;
    if (arr.field === "summa") {
      arrForSend = {
        number_travelers: arr.element.number_travelers,
        summa: arr.value,
        currency_id: "RUB",
        start_date: arr.element.start_date,
        end_date: arr.element.end_date,
      };
    } else if (arr.field === "number_travelers") {
      arrForSend = {
        number_travelers: arr.value,
        summa: arr.element.summa,
        currency_id: "RUB",
        start_date: arr.element.start_date,
        end_date: arr.element.end_date,
      };
    }
    putTourRaces(token, urlSearch.id, arrForSend, arr.element.id).then((e) => {
      if (!e.data.errors) {
        getTourRaces(token, urlSearch.id).then((e) => {
          if (e.data.data) {
            dispatch(setArrayTourRaces(e.data.data));
          }
        });
        props.setOpenChangeElem(false);
      }
    });
  };

  useAsyncEffect(async () => {
    let arrayFilter = defaultArray;
    // название
    if (urlSearch?.options) {
      arrayFilter = arrayFilter?.filter((el) =>
        el?.operator_options?.find((q) => q?.id === +urlSearch?.options)
      );
    }
    // дата
    if (urlSearch?.date_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => new Date(el.start_date).getTime() >= +urlSearch?.date_from
      );
    }
    if (urlSearch?.date_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => new Date(el.end_date).getTime() <= +urlSearch?.date_until + 86400000
      );
    }
    // опции
    if (urlSearch?.options_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.operator_options?.length >= +urlSearch?.options_from
      );
    }
    if (urlSearch?.options_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.operator_options?.length <= +urlSearch?.options_until
      );
    }

    // цена
    if (urlSearch?.price_from) {
      arrayFilter = await arrayFilter?.filter((el) => +el?.summa >= +urlSearch?.price_from);
    }
    if (urlSearch?.price_until) {
      arrayFilter = await arrayFilter?.filter((el) => +el?.summa <= +urlSearch?.price_until);
    }

    // цена cо скидкой
    // if (urlSearch?.discount_from) {
    //   arrayFilter = await arrayFilter?.filter((el) => +el?.summa >= +urlSearch?.discount_from);
    // }
    // if (urlSearch?.discount_until) {
    //   arrayFilter = await arrayFilter?.filter((el) => +el?.summa <= +urlSearch?.discount_until);
    // }

    // мест
    if (urlSearch?.seats_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.number_travelers >= +urlSearch?.seats_from
      );
    }
    if (urlSearch?.seats_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.number_travelers <= +urlSearch?.seats_until
      );
    }

    // занято
    if (urlSearch?.occupied_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.paid_travelers_count >= +urlSearch?.occupied_from
      );
    }
    if (urlSearch?.occupied_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.paid_travelers_count <= +urlSearch?.occupied_until
      );
    }

    // осталось
    if (urlSearch?.left_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.number_travelers - +el?.paid_travelers_count >= +urlSearch?.left_from
      );
    }
    if (urlSearch?.left_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.number_travelers - +el?.paid_travelers_count <= +urlSearch?.left_until
      );
    }

    setFilterArray(arrayFilter);
  }, [
    page,
    urlSearch?.date_until,
    urlSearch?.date_from,
    urlSearch?.options,
    urlSearch?.options_from,
    urlSearch?.options_until,
    urlSearch?.price_from,
    urlSearch?.price_until,
    urlSearch?.seats_from,
    urlSearch?.seats_until,
    urlSearch?.occupied_from,
    urlSearch?.occupied_until,
    urlSearch?.left_from,
    urlSearch?.left_until,
  ]);
  return (
    <>
      <div className="tour-list-races">
        <div className="cont-tablet-races">
          <div className="filter-races">
            <div className="filter-search-cont-races">
              {/* <MultiSelectChip
                disabled={!props.array.length}
                value={searchProm || ""}
                array={options}
                display="comma"
                val={searchProm}
                typesel="small"
                className={`input-search small ${!props.array.length ? "disabled" : ""}`}
                onChange={(e) => {
                  if (e.value) setSearchProm(e.value);
                  let arr = [];
                  for (var i = 0; i < e.value.length; i++) {
                    arr.push(e.value[i].id);
                  }
                  let data = urlSearch;
                  data.options = arr;
                  data.page = 1;
                  const params = new URLSearchParams(data);
                  history.push(`?${params.toString()}`);
                }}
                placeholder={props.searchPlaceholder}
              /> */}
              <AutoCompleteSelect
                disabled={!props.array.length}
                name="name"
                value={searchProm || ""}
                typesel="small"
                className={`input-search small ${!props.array.length ? "disabled" : ""}`}
                placeholder={props.searchPlaceholder}
                array={options}
                image="search"
                onChange={(e) => {
                  setSearchProm(e.value);
                  if (typeof e.value === "string" && !e.value.length) {
                    let data = urlSearch;
                    delete data.options;
                    data.page = 1;
                    const params = new URLSearchParams(data);
                    history.push(`?${params.toString()}`);
                  }
                  if (typeof e.value !== "string") {
                    let data = urlSearch;
                    data.options = e.value?.id;
                    data.page = 1;
                    const params = new URLSearchParams(data);
                    history.push(`?${params.toString()}`);
                  }
                }}
              />
              <div className="search-date">
                <Input
                  disabled={!props.array.length}
                  value={new Date(dateOt).toLocaleDateString() || ""}
                  className={`input-date small ${!props.array.length ? "disabled" : ""}`}
                  placeholder="дата от"
                  type="date"
                  noImage
                  onChange={(e) => {
                    if (e) {
                      setDateOt(e);
                      let data = urlSearch;
                      data.date_from = new Date(e).getTime();
                      const params = new URLSearchParams(data);
                      history.push(`?${params.toString()}`);
                      // let fil = array?.filter(
                      //   (el) => new Date(el?.created_at).getTime() > new Date(e).getTime()
                      // );
                      // setArray(fil);
                    } else {
                      setDateOt(e);
                      let data = urlSearch;
                      delete data.date_from;
                      const params = new URLSearchParams(data);
                      history.push(`?${params.toString()}`);
                    }
                  }}
                />

                <Input
                  disabled={!props.array.length}
                  value={new Date(dateDo).toLocaleDateString() || ""}
                  className={`input-date small ${!props.array.length ? "disabled" : ""}`}
                  placeholder="дата до"
                  type="date"
                  noImage
                  onChange={(e) => {
                    if (e) {
                      setDateDo(e);
                      let data = urlSearch;
                      data.date_until = new Date(e).getTime();
                      const params = new URLSearchParams(data);
                      history.push(`?${params.toString()}`);
                    } else {
                      setDateDo(e);
                      let data = urlSearch;
                      delete data.date_until;
                      const params = new URLSearchParams(data);
                      history.push(`?${params.toString()}`);
                    }
                  }}
                />
              </div>
            </div>
            <div className="btn-elem-filter-races">
              <Button
                disabled={!props.array.length}
                typebtn="ghost"
                className="small btn-elem-f"
                onClick={() => {
                  props.openFilterModal(true);
                  // setOpenModalFilterTable(true);
                }}
              >
                <Icons icon="filter" />
                <div className="text">Фильтры</div>
              </Button>
              <Button
                typebtn="ghost"
                className="small btn-elem-e"
                disabled={!props.array.length || !props.arrayForExcel?.length}
                onClick={() => exportToExcel()}
              >
                <Icons icon="download" />
                <div className="text">Экспорт</div>
              </Button>
              <IonMenuToggle menu="menu-right-add-races">
                <Button className="small" typebtn="outline">
                  <Icons icon="plus" />
                  <div className="text">Добавить</div>
                </Button>
              </IonMenuToggle>
            </div>
          </div>
          <div className="chip-cont-races">
            {/* <IonChip className="chip">
              <div className="text">Стоимость: 15000-55000</div>
              <div className="img">
                <Icons icon="design-personal-x" />
              </div>
            </IonChip> */}
          </div>
          {defaultArray.length ? (
            <div className="tablet-races">
              <table>
                <tbody>
                  <tr className="header-tablet-races">
                    {/* <th className="el-tableth photo">фото</th> */}
                    {header?.map((el, i) => {
                      return (
                        <th
                          key={i}
                          className={`el-tableth-races ${el.field || ""}`}
                          onClick={async () => {
                            // arrayCuts[page].sort(function (a, b) {
                            //   return a[el.field] - b[el.field];
                            // });
                            var product = props.arrayProd.find((q) => q.id === el.product_id);

                            let ar = array;
                            if (!el.noImage) {
                              if (
                                el.type === "summa" ||
                                el.type === "options" ||
                                el.type === "number_travelers" ||
                                el.type === "left" ||
                                el.type === "rate"
                              ) {
                                if (sort) {
                                  if (sort.el === el.field) {
                                    if (sort.sort === "down") {
                                      await ar.sort(function (a, b) {
                                        return a.id && b.id ? a.id - b.id : a.user_id - b.user_id;
                                      });
                                      setArray(ar);
                                      setSort({ el: null, sort: false, type: null });
                                    } else if (sort.sort === false) {
                                      await ar.sort(function (a, b) {
                                        return el.type === "options"
                                          ? a?.[el.field]?.length - b?.[el.field]?.length
                                          : el.type === "left"
                                          ? a?.number_travelers -
                                            a?.paid_travelers_count -
                                            (b?.number_travelers - b?.paid_travelers_count)
                                          : el.type === "rate"
                                          ? (+a?.[el.field]?.rating?.[0]?.avg
                                              ? +a?.[el.field]?.rating?.[0]?.avg
                                              : 0) -
                                            (+b?.[el.field]?.rating?.[0]?.avg
                                              ? +b?.[el.field]?.rating?.[0]?.avg
                                              : 0)
                                          : a?.[el.field] - b?.[el.field];
                                      });
                                      setSort({ el: el.field, sort: "up", type: el.type });
                                      setArray(ar);
                                    } else if (sort.sort === "up") {
                                      await ar.sort(function (a, b) {
                                        return el.type === "options"
                                          ? b?.[el.field]?.length - a?.[el.field]?.length
                                          : el.type === "left"
                                          ? b?.number_travelers -
                                            b?.paid_travelers_count -
                                            (a?.number_travelers - a?.paid_travelers_count)
                                          : el.type === "rate"
                                          ? (+b?.[el.field]?.rating?.[0]?.avg
                                              ? +b?.[el.field]?.rating?.[0]?.avg
                                              : 0) -
                                            (+a?.[el.field]?.rating?.[0]?.avg
                                              ? +a?.[el.field]?.rating?.[0]?.avg
                                              : 0)
                                          : b?.[el.field] - a?.[el.field];
                                      });
                                      setSort({ el: el.field, sort: "down", type: el.type });
                                      setArray(ar);
                                    }
                                  } else {
                                    ar.sort(function (a, b) {
                                      return el.type === "options"
                                        ? a?.[el.field]?.length - b?.[el.field]?.length
                                        : el.type === "left"
                                        ? a?.number_travelers -
                                          a?.paid_travelers_count -
                                          (b?.number_travelers - b?.paid_travelers_count)
                                        : el.type === "rate"
                                        ? (+a?.[el.field]?.rating?.[0]?.avg
                                            ? +a?.[el.field]?.rating?.[0]?.avg
                                            : 0) -
                                          (+b?.[el.field]?.rating?.[0]?.avg
                                            ? +b?.[el.field]?.rating?.[0]?.avg
                                            : 0)
                                        : a?.[el.field] - b?.[el.field];
                                    });
                                    setSort({ el: el.field, sort: "up", type: el.type });
                                    setArray(ar);
                                  }
                                }
                                setActiveIndex(null);
                              } else {
                                if (sort) {
                                  if (sort.el === el.field) {
                                    if (sort.sort === "down") {
                                      ar.sort(function (a, b) {
                                        return a.id && b.id ? a.id - b.id : a.user_id - b.user_id;
                                      });
                                      setArray(ar);
                                      setSort({ el: null, sort: false, type: null });
                                    } else if (sort.sort === false) {
                                      ar.sort(function (a, b) {
                                        var x =
                                          el.type === "range"
                                            ? a["start_date"]?.toLowerCase()
                                            : el.type === "duration"
                                            ? a["product"]?.duration.name.toLowerCase()
                                            : a[el.field]?.toLowerCase();
                                        var y =
                                          el.type === "range"
                                            ? b["start_date"]?.toLowerCase()
                                            : el.type === "duration"
                                            ? b["product"]?.duration.name.toLowerCase()
                                            : b[el.field]?.toLowerCase();
                                        if (x < y) {
                                          return -1;
                                        }
                                        if (x > y) {
                                          return 1;
                                        }
                                        return 0;
                                      });
                                      setSort({ el: el.field, sort: "up", type: el.type });
                                      setArray(ar);
                                    } else {
                                      ar.sort(function (a, b) {
                                        var x =
                                          el.type === "range"
                                            ? a["start_date"]?.toLowerCase()
                                            : el.type === "duration"
                                            ? a["product"]?.duration.name.toLowerCase()
                                            : a[el.field]?.toLowerCase();
                                        var y =
                                          el.type === "range"
                                            ? b["start_date"]?.toLowerCase()
                                            : el.type === "duration"
                                            ? b["product"]?.duration.name.toLowerCase()
                                            : b[el.field]?.toLowerCase();
                                        if (x > y) {
                                          return -1;
                                        }
                                        if (x < y) {
                                          return 1;
                                        }
                                        return 0;
                                      });
                                      setSort({ el: el.field, sort: "down", type: el.type });
                                      setArray(ar);
                                    }
                                  } else {
                                    ar.sort(function (a, b) {
                                      var x =
                                        el.type === "range"
                                          ? a["start_date"]?.toLowerCase()
                                          : el.type === "duration"
                                          ? a["product"]?.duration.name.toLowerCase()
                                          : a[el.field]?.toLowerCase();
                                      var y =
                                        el.type === "range"
                                          ? b["start_date"]?.toLowerCase()
                                          : el.type === "duration"
                                          ? b["product"]?.duration.name.toLowerCase()
                                          : b[el.field]?.toLowerCase();
                                      if (x < y) {
                                        return -1;
                                      }
                                      if (x > y) {
                                        return 1;
                                      }
                                      return 0;
                                    });
                                    setSort({ el: el.field, sort: "up", type: el.type });
                                    setArray(ar);
                                  }
                                }
                                setActiveIndex(null);
                              }
                            }
                          }}
                        >
                          <div className="cont-text-table">
                            <div className="text">{el.name}</div>
                            {!el.noImage && (
                              <Icons
                                icon="design-personal-caret-down-filled"
                                className={
                                  sort.sort === "up" &&
                                  (sort.el === el.field || sort.el[0] === el.field[0]) &&
                                  sort.type === el.type
                                    ? "up"
                                    : sort.sort === "down" &&
                                      (sort.el === el.field || sort.el[0] === el.field[0]) &&
                                      sort.type === el.type
                                    ? "down"
                                    : ""
                                }
                              />
                            )}
                          </div>
                        </th>
                      );
                    })}
                    <th className="el-tableth-races settings head">
                      <div className="cont-set-btn">
                        <div className="open-opt" onClick={() => {}}>
                          <Icons icon="chevrons-down" className="icon-outline" />
                        </div>
                        <div className="setting-opt" onClick={() => setOpenModalChangeTable(true)}>
                          <Icons icon="design-personal-settings" className="icon-outline" />
                        </div>
                      </div>
                    </th>
                  </tr>

                  {arrayCuts?.[page - 1]?.map((el, i) => {
                    var product = props.arrayProd.find((q) => q.id === el.product_id);

                    // console.log("el", el);

                    return (
                      <>
                        <tr key={`i-${el?.id}`} className="line-tablet">
                          {header?.map((td, indx) => {
                            let value;
                            if (td.field === "left") {
                              // ПОМЕНЯТЬ ЗНАЧЕНИЕ КАК ПОЯВИТСЯ В ЗАПРОСЕ
                              value = +el["number_travelers"] - el["paid_travelers_count"];
                            } else if (td.field === "summa_discount") {
                              value = +el["summa"]; // - 1000
                            } else if (td.type === "range") {
                              let arr = [];
                              for (let i = 0; i < td.field.length; i++) {
                                arr.push(el[td.field[i]]);
                              }
                              value = arr;
                            } else {
                              value = el[td.field];
                            }
                            return (
                              <td
                                id={`bottom-start-change-${i}-${indx}-races`}
                                key={`indx-${indx}`}
                                className={`el-tablet-races ${td.field} ${
                                  td.type === "left"
                                    ? value > (el["number_travelers"] / 100) * 40
                                      ? "green"
                                      : "red"
                                    : ""
                                } `}
                                onClick={() => {
                                  if (td.field === "summa") {
                                    props.setOpenChangeElem({
                                      type: "races",
                                      index: i,
                                      indexEl: indx,
                                      element: el,
                                      value: el[td.field],
                                      field: td.field,
                                    });
                                  }
                                  if (td.field === "number_travelers") {
                                    props.setOpenChangeElem({
                                      type: "races",
                                      index: i,
                                      indexEl: indx,
                                      element: el,
                                      value: el[td.field],
                                      field: td.field,
                                    });
                                  }
                                }}
                              >
                                {td.type === "name" ? (
                                  <div className="text-name">{value}</div>
                                ) : td.type === "text" ? (
                                  <div className="text">{value}</div>
                                ) : td.type === "range" ? (
                                  <>
                                    <div className="text">{`${new Date(value[0]).toLocaleDateString(
                                      "ru",
                                      {
                                        day: "numeric",
                                        month: "long",
                                      }
                                    )} - ${new Date(value[1]).toLocaleDateString("ru", {
                                      day: "numeric",
                                      month: "long",
                                    })} `}</div>
                                    <div className="subtext cap">
                                      {`${new Date(value[0]).toLocaleDateString("ru", {
                                        weekday: "long",
                                      })} - ${new Date(value[1]).toLocaleDateString("ru", {
                                        weekday: "long",
                                      })} `}
                                    </div>
                                  </>
                                ) : td.type === "options" ? (
                                  <div className="text-opt">{value?.length}</div>
                                ) : td.type === "duration" ? (
                                  <div className="text">{product?.duration.name}</div>
                                ) : td.type === "number_travelers" ? (
                                  <>
                                    <div className="text">{value}</div>
                                    <div
                                      className={`subtext ${
                                        value === +product?.available_places ? "" : "hide"
                                      } `}
                                    >
                                      по умолч.
                                    </div>
                                  </>
                                ) : td.type === "reviews" ? (
                                  <div className="text">{value ? value.length : "0"}</div>
                                ) : td.type === "left" ? (
                                  <div className="text">{value}</div>
                                ) : td.type === "busy" ? (
                                  <div className="text">0</div>
                                ) : td.type === "type" ? (
                                  <div className="text">{typeEl.type_name}</div>
                                ) : td.type === "rate" ? (
                                  <Rating
                                    rate={product?.rating?.[0]?.avg ? product?.rating?.[0]?.avg : 0}
                                  />
                                ) : td.type === "badge" ? (
                                  <div className="badge-table">
                                    {value?.map((e, i) => {
                                      return (
                                        <Badge array={props.array} key={i}>
                                          {e.name}
                                        </Badge>
                                      );
                                    })}
                                  </div>
                                ) : td.type === "summa" ? (
                                  <>
                                    <div className="text">
                                      {String(+value).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                                    </div>
                                    <div
                                      className={`subtext ${
                                        +value === +product?.summa ? "" : "hide"
                                      } `}
                                    >
                                      по умолч.
                                    </div>
                                  </>
                                ) : td.type === "description" ? (
                                  <div className="text-description">{value}</div>
                                ) : (
                                  td.type === "image" && (
                                    <div className="cont-img">
                                      <img
                                        src={
                                          value && value.length
                                            ? `${URL_ORIGIN}${
                                                typeof value === "string" ? value : value[0]?.photo
                                              }`
                                            : noImage
                                        }
                                      />
                                    </div>
                                  )
                                )}
                              </td>
                            );
                          })}
                          <td className="el-tablet-races settings">
                            <div className="cont-set-btn">
                              <div
                                className="open-opt"
                                onClick={() => {
                                  activeIndex && activeIndex.index === i
                                    ? setActiveIndex(null)
                                    : setActiveIndex({ index: i });
                                }}
                              >
                                <Icons icon="chevron-down" className="icon-outline" />
                              </div>
                              <div
                                id={`bottom-start-${i}`}
                                className="setting-opt"
                                onClick={() => {
                                  props.setOpenMenuElem({
                                    index: i,
                                  });
                                  props.focus(el);
                                }}
                              >
                                <Icons icon="dots-vertical" className="icon-outline" />
                              </div>
                              <div id={`null`} />
                            </div>
                          </td>
                        </tr>
                        {el?.operator_options?.map((opt, index) => {
                          return (
                            <tr
                              key={index}
                              className={`line-tablet options-line ${
                                activeIndex && activeIndex.index === i ? "" : "hide"
                              }`}
                            >
                              {header?.map((td, indx) => {
                                let value;
                                if (td.field === "left") {
                                  // ПОМЕНЯТЬ ЗНАЧЕНИЕ КАК ПОЯВИТСЯ В ЗАПРОСЕ
                                  value = +opt?.basic_volume;
                                } else if (td.field === "summa_discount") {
                                  value = opt?.basic_cost;
                                } else if (td.type === "range") {
                                  value = {
                                    name: opt?.name,
                                    pictures: opt?.pictures?.[0]?.photo,
                                  };
                                } else {
                                  value = el[td.field];
                                }
                                // td.type === "range" && console.log("qweqweq", value);
                                return (
                                  <td
                                    id={`bottom-start-change-${index}-${indx}-option`}
                                    key={indx}
                                    className={`el-tablet-races ${
                                      td.field[0] === "start_date" ? "range" : td.field
                                    } ${
                                      td.type === "left"
                                        ? value > (el["number_travelers"] / 100) * 40
                                          ? "green"
                                          : "red"
                                        : ""
                                    } `}
                                    onClick={() => {
                                      if (td.field === "summa") {
                                        props.setOpenChangeElem({
                                          type: "option",
                                          index: index,
                                          indexEl: indx,
                                          element: opt,
                                          value: opt?.basic_cost,
                                          field: td.field,
                                        });
                                      }
                                      if (td.field === "number_travelers") {
                                        props.setOpenChangeElem({
                                          type: "option",
                                          index: index,
                                          indexEl: indx,
                                          element: opt,
                                          value: opt?.basic_volume,
                                          field: td.field,
                                        });
                                      }
                                    }}
                                  >
                                    {td.type === "name" ? (
                                      <div className="text-name">{value}</div>
                                    ) : td.type === "text" ? (
                                      <div className="text">{value}</div>
                                    ) : td.type === "range" ? (
                                      <div className="cont-range">
                                        <div className="cont-img-opt">
                                          <img
                                            src={
                                              value.pictures
                                                ? `${URL_ORIGIN}${value.pictures}`
                                                : noImage
                                            }
                                          />
                                        </div>
                                        <div className="cont-name-opt">
                                          <div
                                            className={`text-name-opt ${
                                              opt?.status === 0 ? "disable" : ""
                                            }`}
                                          >
                                            {opt?.option?.name}
                                          </div>
                                          {opt?.status === 0 && (
                                            <div className="text-stat-option red">
                                              Опция не активна
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    ) : td.type === "options" ? (
                                      <div className="text-opt"></div>
                                    ) : td.type === "duration" ? (
                                      <div className="text"></div>
                                    ) : td.type === "number_travelers" ? (
                                      <>
                                        <div className="text">{+opt?.basic_volume}</div>
                                        <div
                                          className={`subtext ${
                                            value === +product?.available_places ? "" : "hide"
                                          } `}
                                        >
                                          по умолч.
                                        </div>
                                      </>
                                    ) : td.type === "reviews" ? (
                                      <div className="text">{value ? value.length : "0"}</div>
                                    ) : td.type === "left" ? (
                                      <div className="text">{value}</div>
                                    ) : td.type === "busy" ? (
                                      <div className="text">0</div>
                                    ) : td.type === "type" ? (
                                      <div className="text">{typeEl.type_name}</div>
                                    ) : td.type === "rate" ? (
                                      // <Rating rate={product?.rating ? product?.rating : 0} />
                                      <></>
                                    ) : td.type === "badge" ? (
                                      <div className="badge-table">
                                        {value?.map((e, i) => {
                                          return (
                                            <Badge array={props.array} key={i}>
                                              {e.name}
                                            </Badge>
                                          );
                                        })}
                                      </div>
                                    ) : td.type === "summa" ? (
                                      <>
                                        <div className="text">
                                          {opt.basic_cost?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                                        </div>
                                        <div
                                          className={`subtext ${
                                            +value === +product?.summa ? "" : "hide"
                                          } `}
                                        >
                                          по умолч.
                                        </div>
                                      </>
                                    ) : td.type === "description" ? (
                                      <div className="text-description">{value}</div>
                                    ) : (
                                      td.type === "image" && (
                                        <div className="cont-img">
                                          <img
                                            src={
                                              value && value.length
                                                ? `${URL_ORIGIN}${
                                                    typeof value === "string"
                                                      ? value
                                                      : value[0]?.photo
                                                  }`
                                                : noImage
                                            }
                                          />
                                        </div>
                                      )
                                    )}
                                  </td>
                                );
                              })}

                              <td className="el-tablet-races settings">
                                <div className="cont-set-btn s">
                                  <div
                                    id={`bottom-start-${i}`}
                                    className={`setting-opt ${opt?.status === 0 ? "disabled" : ""}`}
                                    onClick={() => {
                                      if (opt?.status === 0) return;
                                      props.setOpenMenuElem({
                                        index: i,
                                      });
                                      props.focus(el);
                                    }}
                                  >
                                    <Icons icon="dots-vertical" className="icon-outline" />
                                  </div>
                                  <div id={`null`} />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                        <tr
                          className={`line-tablet options-line ${
                            activeIndex && activeIndex.index === i ? "" : "hide"
                          }`}
                        >
                          <td className="el-tablet-races btn-add">
                            <IonMenuToggle menu="menu-right-add-races-change">
                              <Button
                                typebtn="ghost"
                                className="small"
                                onClick={() => {
                                  dispatch(setFormCreateRaces({ ...el }));
                                }}
                              >
                                <Icons icon="plus" />
                                Добавить опцию
                              </Button>
                            </IonMenuToggle>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="table-sales">
              <img src={backEmpty} className="back-img" />
              <div className="content">
                <div className="cont-text">
                  <Icons icon="table-plus" />
                  <div className="text">Пока нет ни одного предложения</div>
                  <div className="subtext">
                    <div>Чтобы начать продажи, нужно добавить даты в расписание.</div>
                    <div>
                      Для каждого предложения можно указать свою стоимость, количество мест и опции.
                    </div>
                  </div>
                </div>
                <IonMenuToggle menu="menu-right-add-races">
                  <Button typebtn="bright" className="small">
                    <Icons icon="plus" />
                    Добавить даты
                  </Button>
                </IonMenuToggle>
              </div>
            </div>
          )}
          {defaultArray.length ? (
            <div className="paginator-races">
              <div className="cont-select-el-list">
                <div className="text">На стр.:</div>
                <SelectButton
                  value={valuePagePaginator}
                  array={paginatorPage}
                  className="ghost btn-paginator"
                  optionLabel="page"
                  onChange={(e) => {
                    setValuePagePaginator(e.value);
                    setPage(1);
                  }}
                />
              </div>
              <div className="col">{`${
                arrayCuts?.[page - 1]?.[0].num ? arrayCuts?.[page - 1]?.[0].num : 0
              }-${
                arrayCuts?.[page - 1]?.[arrayCuts?.[page - 1]?.length - 1].num
                  ? arrayCuts?.[page - 1]?.[arrayCuts?.[page - 1]?.length - 1].num
                  : 0
              } из ${array?.length}`}</div>
              <div className="btn-next">
                <Button
                  typebtn="ghost"
                  className="btn-page small"
                  onClick={() => {
                    setPage(page - 1);
                    setActiveIndex(null);
                    let data = urlSearch;
                    data.page = page - 1;
                    const params = new URLSearchParams(data);
                    history.push(`?${params.toString()}`);
                  }}
                  disabled={page == 1}
                >
                  <Icons icon="design-personal-chevron-left" className="icon-unfilled arrow" />
                </Button>
                <div className="text">{`${page}/${arrayCuts?.length ? arrayCuts?.length : 1}`}</div>
                <Button
                  typebtn="ghost"
                  className="btn-page small"
                  onClick={() => {
                    setPage(page + 1);
                    setActiveIndex(null);
                    let data = urlSearch;
                    data.page = page + 1;
                    const params = new URLSearchParams(data);
                    history.push(`?${params.toString()}`);
                  }}
                  disabled={!arrayCuts?.length || page == arrayCuts?.length}
                >
                  <Icons icon="design-personal-chevron-right" className="icon-unfilled arrow" />
                </Button>
              </div>
            </div>
          ) : undefined}
        </div>
      </div>

      <IonPopover
        trigger={
          props.openMenuElem !== false ? `bottom-start-${props.openMenuElem.index}` : undefined
        }
        class="popover-menu-el-list"
        isOpen={props.openMenuElem.index || (props.openMenuElem.index === 0 && true)}
        onWillDismiss={() => {
          props.setOpenMenuElem(false);
        }}
        side="bottom"
        alignment="start"
      >
        <div className="body-popover">
          {props.menu.map((el, i) => {
            return (
              <div key={i} className="cont-el-pop" onClick={el.click}>
                <Icons icon={el.icon} />
                <div className="text">{el.name}</div>
              </div>
            );
          })}
        </div>
      </IonPopover>
      <IonPopover
        class="popover-paginator-el-list"
        isOpen={pagePaginator}
        onWillDismiss={() => setPagePaginator(false)}
        side="bottom"
        alignment="center"
        trigger="page-paginator"
        size="cover"
      >
        <div className="body-popover">
          {paginatorPage?.map((val, i) => {
            return (
              <div
                key={i}
                className="cont-el-pop"
                onClick={() => {
                  setPage(0);
                  setValuePagePaginator(val.page);
                  setPagePaginator(false);
                }}
              >
                <div className="text">{val.page}</div>
              </div>
            );
          })}
        </div>
      </IonPopover>

      <IonPopover
        trigger={
          props.openChangeElem !== false
            ? `bottom-start-change-${props.openChangeElem.index}-${props.openChangeElem.indexEl}-${props.openChangeElem.type}`
            : undefined
        }
        class="popover-change-element"
        isOpen={props.openChangeElem.index || (props.openChangeElem.index === 0 && true)}
        onWillDismiss={() => {
          props.setOpenChangeElem(false);
        }}
        side="bottom"
        alignment="start"
      >
        {props.openChangeElem.type === "races" ? (
          <div className="body-popover-change">
            <Input
              value={props.openChangeElem.value}
              noImage
              className="small input-change"
              onChange={(e) => {
                props.setOpenChangeElem({ ...props.openChangeElem, value: e });
              }}
            ></Input>
            <Button typebtn="muted" className="small cube" onClick={() => onChangeEl()}>
              <Icons icon="check" />
            </Button>
          </div>
        ) : (
          props.openChangeElem.type === "option" && (
            <div className="body-popover-change">
              <Input
                value={props.openChangeElem.value}
                noImage
                className="small input-change"
                onChange={(e) => {
                  props.setOpenChangeElem({ ...props.openChangeElem, value: e });
                }}
              ></Input>
              <Button typebtn="muted" className="small cube" onClick={() => onChangeOpt()}>
                <Icons icon="check" />
              </Button>
            </div>
          )
        )}
      </IonPopover>

      <ModalChangeTable
        open={openModalChangeTable}
        onClose={setOpenModalChangeTable}
        numberColumns={props.numberColumns}
        column={props.header}
        className={props.classNameTableSetting}
      />
    </>
  );
};

export default TableProductRaces;
