import { IonModal } from "@ionic/react";
import Close from "../Close";
import Select from "../Select";
import Input from "../Input";
import Button from "../Button";
import Toggle from "../Toggle";
import { useMemo, useState } from "react";
import MultiSelectChip from "../MultiSelectChip";
import { useSelector } from "react-redux";
import Checkbox from "../Checkbox";
import qs from "qs";
import { useHistory } from "react-router";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";

const ModalFilterPromotions = (props) => {
  const products = useSelector((state) => state.products);
  const DEFAULT = {
    past: "",
  };
  const history = useHistory();
  const urlSearch = qs.parse(history.location.search.substr(1));
  const [filterElements, setFilterElements] = useState(DEFAULT);
  const [checked, setChecked] = useState(urlSearch?.past || true);

  const [arrSelProducts, setArrSelProducts] = useState();
  const [filterType, setFilterType] = useState();
  const [filterDesing, setFilterDesing] = useState();

  const promotions = useMemo(() => props.promotions, [props.promotions]);
  const arrayForTimeline = useMemo(() => props.arrayForTimeline, [props.arrayForTimeline]);

  const istochnik = [
    { name: "Все", id: 1 },
    { name: "Акции Смородины", id: 2 },
    { name: "Мои акции", id: 3 },
  ];
  const tip = [
    { name: "Все", id: 1 },
    { name: "Скидка на тур", id: 2 },
    { name: "Доп. опции", id: 3 },
    { name: "Пониженная комиссия", id: 4 },
  ];

  const removeEmpty = (obj) =>
    Object.entries(obj)
      .filter(([, v]) => v != null && v !== "")
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

  const func = (obj1, obj2) => {
    let result = {};
    for (let prop in obj1) {
      if (
        obj2.hasOwnProperty(prop)
        // ||
        // prop === "page" ||
        // prop === "date_from" ||
        // prop === "date_until" ||
        // prop === "name_email"
      )
        result[prop] = obj1[prop];
    }
    return result;
  };
  useAsyncEffect(() => {
    if (!urlSearch?.products_prom) {
      setArrSelProducts(null);
    } else {
      let arrF = [];
      let urlF = urlSearch?.products_prom?.split(",");
      for (let i = 0; i < urlF?.length; i++) {
        let find = products?.find((el) => el?.id === +urlF[i]);
        arrF.push(find);
      }
      setArrSelProducts(arrF);
    }

    if (!urlSearch?.source) {
      setFilterDesing(null);
    } else {
      let arrF = [];
      let urlF = urlSearch?.source?.split(",");
      for (let i = 0; i < urlF?.length; i++) {
        let find = istochnik?.find((el) => el?.id === +urlF[i]);
        arrF.push(find);
      }
      setFilterDesing(arrF);
    }

    if (!urlSearch?.type) {
      setFilterType(null);
    } else {
      let arrF = [];
      let urlF = urlSearch?.type?.split(",");
      for (let i = 0; i < urlF?.length; i++) {
        let find = tip?.find((el) => el?.id === +urlF[i]);
        arrF.push(find);
      }
      setFilterType(arrF);
    }
  }, [props.open]);

  const onApply = () => {
    let data = urlSearch;
    let array = filterElements;
    let filter = removeEmpty(array);
    data = func(data, filter);
    let merge = Object.assign(data, filter);
    if (arrSelProducts) {
      let f = [];
      for (let i = 0; i < arrSelProducts?.length; i++) {
        f.push(arrSelProducts[i]?.id);
      }
      if (f.length) {
        merge.products_prom = f;
      } else {
        delete merge?.products_prom;
      }
    }

    if (filterDesing) {
      let f = [];
      // for (let i = 0; i < filterDesing?.length; i++) {
      f.push(filterDesing?.id);
      // }
      if (f.length) {
        merge.source = f;
      } else {
        delete merge?.source;
      }
    }

    if (filterType) {
      let f = [];
      // for (let i = 0; i < filterType?.length; i++) {
      f.push(filterType?.id);
      // }

      if (f.length) {
        merge.type = f;
      } else {
        delete merge?.type;
      }
    }
    const params = new URLSearchParams(merge);
    history.push(`?${params.toString()}`);
    props.onClose(false);
  };
  const itemTemplate = (item) => {
    return (
      <div className="cont-item">
        <Checkbox />
        <div className="country-item">
          <div className="text">{item.name}</div>
          <div className="subtext">{item.duration_name}</div>
        </div>
      </div>
    );
  };

  return (
    <IonModal
      className="filter-table-promotions"
      isOpen={props.open}
      onWillDismiss={() => props.onClose(false)}
    >
      <div className="body-modal">
        <Close onClick={() => props.onClose(false)} />
        <div className="title">Фильтры</div>
        <div className="body">
          <Toggle
            text="Показывать прошедшие"
            checked={checked}
            onChange={(e) => {
              if (!e.target.checked) {
                setChecked(false);
                setFilterElements({ ...filterElements, past: false });
              } else {
                setChecked(true);
                setFilterElements({ ...filterElements, past: "" });
              }
            }}
          />
          <MultiSelectChip
            multiple
            value={arrSelProducts || ""}
            array={products}
            typesel="small"
            val={arrSelProducts}
            className="small"
            onChange={(e) => setArrSelProducts(e.value)}
            placeholder="тур: все"
            itemTemplate={itemTemplate}
          />

          <Select
            value={filterDesing || ""}
            array={istochnik}
            typesel="small"
            optionLabel="name"
            className="small"
            onChange={(e) => {
              setFilterDesing(e.value);
            }}
            placeholder="источник: все"
          />
          <Select
            value={filterType || ""}
            array={tip}
            typesel="small"
            optionLabel="name"
            className="small"
            onChange={(e) => {
              setFilterType(e.value);
            }}
            placeholder="тип: все"
          />
        </div>
        <div className="buttons">
          <Button
            className="small"
            typebtn="outline"
            onClick={async () => {
              // props.onClose(false)
              setArrSelProducts(null);
              setChecked(true);
              setFilterElements(DEFAULT);
              let data = urlSearch;
              let newd = {};
              const params = new URLSearchParams(newd);
              history.push(`?${params.toString()}`);
            }}
          >
            Сбросить
          </Button>
          <Button
            className="small"
            typebtn="bright"
            onClick={() => {
              props.onClose(false);
              onApply();
            }}
          >
            Применить
          </Button>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalFilterPromotions;
