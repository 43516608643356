import { IonModal } from "@ionic/react";
import AlertBox from "../../ui/AlertBox";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import Close from "../../ui/Close";
import { useMemo } from "react";
import { useHistory } from "react-router";

const ModalTourConfirmedOperator = (props) => {
  const result = useMemo(() => props.result, [props.result]);
  const history = useHistory();
  let participants = "";
  for (let i = 0; i < result?.participants?.length; i++) {
    participants += `${
      result?.participants[i]?.ismain === 1
        ? ` ${result?.participants[i]?.first_name} ${
            result?.participants[i]?.last_name
          } (основной)${result?.participants?.length === i + 1 ? "" : ","}`
        : ` ${result?.participants[i]?.first_name} ${result?.participants[i]?.last_name}${
            result?.participants?.length === i + 1 ? "" : ","
          }`
    }`;
  }

  return (
    <IonModal
      className="modal-tour-confirmed"
      isOpen={props.open}
      onWillDismiss={() => props.onClose(false)}
    >
      <div className="body-modal-tour-confrmed">
        <Close onClick={() => props.onClose(false)} />
        <div className="title">Тур подтверждён</div>
        <AlertBox
          noClose
          className="alert-modal-tour-confrmed"
          type="success"
          text="Аванс по заказу перечислен на баланс вашей компании."
        />
        <div className="cont-info">
          <div className="line">
            <div className="subtext">Название тура</div>
            <div className="text">{result?.product?.name}</div>
          </div>
          <div className="line">
            <div className="subtext">Сумма аванса</div>
            <div className="text">{`${String(
              ((+result?.summa / 100) * (100 - +result?.commission_value))?.toFixed(2)
            ).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}</div>
          </div>
          <div className="line">
            <div className="subtext">Даты тура</div>
            <div className="text">{`${new Date(
              result?.race?.start_date
            ).toLocaleDateString()} — ${new Date(
              result?.race?.end_date
            ).toLocaleDateString()}`}</div>
          </div>
          <div className="line">
            <div className="subtext">Участники</div>
            <div className="text">{participants}</div>
          </div>
        </div>
        <div className="btns">
          <Button
            typebtn="outline"
            className="small"
            onClick={() => {
              props.setResultOrder(false);
              props.onClose(false);
            }}
          >
            Закрыть
          </Button>
          <Button
            typebtn="muted"
            className="small"
            onClick={() => {
              history.push(`/partner/finance/orders/details?id=${result?.id}`);
              props.onClose(false);
            }}
          >
            детали заказа
            <Icons icon="arrow-narrow-right" />
          </Button>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalTourConfirmedOperator;
