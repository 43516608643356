import { useDispatch, useSelector } from "react-redux";
import DraftTours from "./DraftTours";
import FeedBackSummary from "./FeedBackSummary";
import FinanceElement from "./FinanceElement";
import GetAdvance from "./GetAdvance";
import OfferElement from "./OfferElement";
import QuestionSummary from "./QuestionSummary";
import Rating from "./Rating";

const HomeMain = () => {
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();

  return (
    <div className="home">
      {/* <ShowcaseElement /> */}
      <GetAdvance />
      <FinanceElement />
      <OfferElement />
      <Rating />
      <DraftTours />
      <FeedBackSummary />
      <QuestionSummary />
      {/* <Schedule /> */}
    </div>
  );
};

export default HomeMain;
