import { IonContent } from "@ionic/react";
import "../../less/css/showcase.css";
import TableTimeline from "../../ui/TableTimeline";
import ModalFilterPromotions from "../../ui/ModalsFilter/ModalFilterPromotions";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../ui/Button";
import qs from "qs";
import Icons from "../../ui/Icons";
import { useHistory } from "react-router";
import { setFormForCreatePromotion, setTimeline } from "../../store/actions";
import ModalQuickShowPromotion from "./Modals/ModalQuickShowPromotion";
import Toast from "../../ui/Toast";
import { DEFAULT_PROMOTIONS } from "../../utils/array";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";

const PromotionsContent = () => {
  const history = useHistory();
  const urlSearch = qs.parse(history.location.search.substr(1));
  const dispatch = useDispatch();
  const [openQuickShow, setOpenQuickShow] = useState(false);
  const [focusElement, setFocusElement] = useState(false);
  const promotions = useSelector((state) => state.promotions);
  const [arrayForTimeline, setArrayForTimeline] = useState();
  const userPermissions = useSelector((state) => state.userPermissions.showcase?.promotions);

  var arrayForExcel = [];

  for (let i = 0; i < promotions?.length; i++) {
    let type =
      promotions[i]?.type === 0 ? "Скидка на стоимость" : promotions[i]?.type === 1 && "Доп. опции";
    let calc_method =
      promotions[i]?.calc_method === 0 ? "Процентов" : promotions[i]?.calc_method === 1 && "Рублей";
    let options = ``;
    for (let j = 0; j < promotions[i].operator_options.length; j++) {
      options += `${promotions[i].operator_options[j]?.name}, `;
    }
    let products = ``;
    for (let j = 0; j < promotions[i].products.length; j++) {
      options += `${promotions[i].products[j]?.name}, `;
    }

    let new_array = {
      Номер: promotions[i]?.id,
      Название: promotions[i]?.promotion?.name,
      "Дата старта": promotions[i]?.start_date,
      "Дата завершения": promotions[i]?.end_date,
      Описание: promotions[i]?.description,
      Цвет: promotions[i]?.color,
      Тип: type,
      Скидка: +promotions[i]?.value,
      Измерение: calc_method,
      Опции: options,
      Туры: products,
    };
    arrayForExcel.push(new_array);
  }

  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const refCont = useRef();

  const operator = useSelector((state) => state.operator);
  const timelineRX = useSelector((state) => state.timeline);

  useEffect(() => {
    dispatch(setFormForCreatePromotion(DEFAULT_PROMOTIONS));
  }, []);

  useAsyncEffect(async () => {
    let arrayFilter = promotions;
    if (urlSearch?.past) {
      arrayFilter = await arrayFilter?.filter(
        (el) => new Date(el?.end_date).getTime() >= new Date().getTime() + 86400000
      );
    }

    // if (urlSearch?.source) {
    //   arrayFilter = await arrayFilter?.filter(
    //     (el) => new Date(el?.end_date).getTime() >= new Date().getTime() + 86400000
    //   );
    // }

    // if (urlSearch?.type) {
    //   arrayFilter = await arrayFilter?.filter(
    //     (el) => new Date(el?.end_date).getTime() >= new Date().getTime() + 86400000
    //   );
    // }
    setArrayForTimeline(arrayFilter);
  }, [urlSearch?.past, promotions]);

  return (
    <>
      <IonContent className="page">
        <div className="promotions-page">
          <div className="upper-line">
            <div className="title">Акции и скидки</div>
            <Button
              typebtn="bright"
              className="small"
              onClick={() => {
                if (userPermissions[1]) {
                  history.push("/partner/showcase/promotions/create");
                  refCont.current?.destroy();
                } else {
                  setOpenToast(true);
                  setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
                }
              }}
            >
              <Icons icon="plus" />
              Создать
            </Button>
          </div>
          <TableTimeline
            refCont={refCont}
            setOpenQuickShow={setOpenQuickShow}
            setFocusElement={setFocusElement}
            searchPlaceholder="Поиск по названию..."
            array={arrayForTimeline}
            arrayForExcel={arrayForExcel}
            operator={operator}
            openFilterModal={setOpenFilterModal}
          />
        </div>
      </IonContent>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
      <ModalFilterPromotions
        setArrayForTimeline={setArrayForTimeline}
        onClose={setOpenFilterModal}
        open={openFilterModal}
        promotions={promotions}
        arrayForTimeline={arrayForTimeline}
      />
      <ModalQuickShowPromotion
        setFocusElement={setFocusElement}
        open={openQuickShow}
        onClose={setOpenQuickShow}
        element={focusElement}
      />
    </>
  );
};

export default PromotionsContent;
