import axios from "axios";

const url_client = "https://travel.ficom-it.info/api/client";
const url_admin = "https://travel.ficom-it.info/api/admin";

export async function postLoginClient(data) {
  let response = await axios({
    url: `${url_client}/login`,
    method: "POST",
    data: {
      ...data,
    },
  });
  return response;
}

export async function postLoginAdmin(data) {
  let response = await axios({
    url: `${url_admin}/login`,
    method: "POST",
    data: {
      ...data,
    },
  });
  return response;
}

export async function postLogout(token) {
  let response = await axios({
    url: `${url_client}/logout`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}
export async function postAdvancePayment(token, order_id, data) {
  let response = await axios({
    url: `${url_admin}/advance-payment/${order_id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postForgotPassword(data) {
  let response = await axios({
    url: `${url_client}/forgot-password`,
    method: "POST",
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postSMSCodeAdmin(data) {
  let response = await axios({
    url: `${url_admin}/confirm-phone`,
    method: "POST",
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postSMSCodeClient(data) {
  let response = await axios({
    url: `${url_client}/confirm-phone`,
    method: "POST",
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postResetPassword(data) {
  let response = await axios({
    url: `${url_client}/reset-password`,
    method: "POST",
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getInitialDataClient(token) {
  let response = await axios({
    url: `${url_client}/get-initial-data`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },

    Accept: "application / json",
  });
  return response;
}
export async function getInitialDataAdmin(token) {
  let response = await axios({
    url: `${url_admin}/get-initial-data`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },

    Accept: "application / json",
  });
  return response;
}

export async function postRegistrationPartner(data) {
  let response = await axios({
    url: `${url_admin}/register`,
    method: "POST",
    Accept: "application / json",
    data: {
      ...data,
    },
  });
  return response;
}

export async function postRegistrationClient(data) {
  let response = await axios({
    url: `${url_client}/register`,
    method: "POST",
    Accept: "application / json",
    data: {
      ...data,
    },
  });
  return response;
}

export async function getPartnerUsers(token) {
  let response = await axios({
    url: `${url_admin}/operator_users`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function deletePartnerUsers(token, id) {
  let response = await axios({
    url: `${url_admin}/operator_users/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getPartnerUsersByID(token, id) {
  let response = await axios({
    url: `${url_admin}/operator_users/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getToursOperator(token) {
  let response = await axios({
    url: `${url_admin}/products`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getSuitable(token) {
  let response = await axios({
    url: `${url_admin}/suitables`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postCreateSuitableTour(token, data, product_id) {
  let response = await axios({
    url: `${url_admin}/products/${product_id}/suitables`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
    data: {
      ...data,
    },
  });
  return response;
}

export async function putCreateSuitableTour(token, data, product_id, suitable_id) {
  let response = await axios({
    url: `${url_admin}/products/${product_id}/suitables/${suitable_id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
    data: {
      ...data,
    },
  });
  return response;
}

export async function getToursByID(token, id) {
  let response = await axios({
    url: `${url_admin}/products/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function deleteTour(token, id) {
  let response = await axios({
    url: `${url_admin}/products/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postCreateTour(token, data) {
  let response = await axios({
    url: `${url_admin}/products`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getGenreList(token) {
  let response = await axios({
    url: `${url_admin}/genres`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getGenreListClient() {
  let response = await axios({
    url: `${url_client}/genres`,
    method: "GET",

    Accept: "application / json",
  });
  return response;
}

export async function getClassList(token) {
  let response = await axios({
    url: `${url_admin}/product_service_classes`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getClassListClient() {
  let response = await axios({
    url: `${url_client}/product_service_classes`,
    method: "GET",

    Accept: "application / json",
  });
  return response;
}

export async function getFormatList(token) {
  let response = await axios({
    url: `${url_admin}/product_durations`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getFormatListClient() {
  let response = await axios({
    url: `${url_client}/product_durations`,
    method: "GET",

    Accept: "application / json",
  });
  return response;
}

export async function getSuitableListClient() {
  let response = await axios({
    url: `${url_client}/suitables`,
    method: "GET",
    Accept: "application / json",
  });
  return response;
}

export async function getPointTypes(token) {
  let response = await axios({
    url: `${url_admin}/product_detail_point_types`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function putCreateTour(token, data, id) {
  let response = await axios({
    url: `${url_admin}/products/${id}`,
    method: "PUT",
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: data,

    Accept: "application / json",
  });
  return response;
}

export async function getPointTour(token, id) {
  let response = await axios({
    url: `${url_admin}/products/${id}/product_details`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postPointTour(token, id, data) {
  let response = await axios({
    url: `${url_admin}/products/${id}/product_details`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ...data },

    Accept: "application / json",
  });
  return response;
}

export async function putPointTour(token, id, data, id_point) {
  let response = await axios({
    url: `${url_admin}/products/${id}/product_details/${id_point}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ...data },

    Accept: "application / json",
  });
  return response;
}

export async function deletePointTour(token, id, id_point) {
  let response = await axios({
    url: `${url_admin}/products/${id}/product_details/${id_point}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },

    Accept: "application / json",
  });
  return response;
}

export async function postAddImagesTour(token, data, id) {
  let response = await axios({
    url: `${url_admin}/products/${id}/product_pictures`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
    Accept: "application / json",
  });
  return response;
}

export async function postAddImagePointTour(token, data, id) {
  let response = await axios({
    url: `${url_admin}/products/${id}/detail_pictures`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
    Accept: "application / json",
  });
  return response;
}

export async function postAddMainImagesTour(token, data, id) {
  let response = await axios({
    url: `${url_admin}/product-add-picture/${id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
    Accept: "application / json",
  });
  return response;
}

export async function deleteMainPhotoTour(token, id) {
  let response = await axios({
    url: `${url_admin}/product-drop-picture/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function deleteOtherPhotoTour(token, id, picture_id) {
  let response = await axios({
    url: `${url_admin}/products/${id}/product_pictures/${picture_id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function deletePointPhotoTour(token, id, picture_id) {
  let response = await axios({
    url: `${url_admin}/products/${id}/detail_pictures/${picture_id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function putPartnerUsers(token, id, data) {
  let response = await axios({
    url: `${url_admin}/operator_users/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ...data },
    Accept: "application / json",
  });
  return response;
}

export async function postPartnerUsers(token, data) {
  let response = await axios({
    url: `${url_admin}/operator_users`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ...data },
    Accept: "application / json",
  });
  return response;
}

export async function postChangePassword(token, data) {
  let response = await axios({
    url: `${url_admin}/changepassword`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ...data },
    Accept: "application / json",
  });
  return response;
}

export async function getTourRaces(token, id) {
  let response = await axios({
    url: `${url_admin}/products/${id}/product_races`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getTourRacesByID(token, id, race_id) {
  let response = await axios({
    url: `${url_admin}/products/${id}/product_races/${race_id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postTourRaces(token, id, data) {
  let response = await axios({
    url: `${url_admin}/products/${id}/product_races`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ...data },

    Accept: "application / json",
  });
  return response;
}

export async function putTourRaces(token, id, data, race_id) {
  let response = await axios({
    url: `${url_admin}/products/${id}/product_races/${race_id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ...data },

    Accept: "application / json",
  });
  return response;
}

export async function deleteTourRaces(token, id, race_id) {
  let response = await axios({
    url: `${url_admin}/products/${id}/product_races/${race_id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getOperator(token) {
  let response = await axios({
    url: `${url_admin}/operators`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function putOperator(token, id, data) {
  let response = await axios({
    url: `${url_admin}/operators/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ...data },
    Accept: "application / json",
  });
  return response;
}

export async function getOptionsDirectory(token) {
  let response = await axios({
    url: `${url_admin}/options`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getPromotionsDirectory(token) {
  let response = await axios({
    url: `${url_admin}/promotions`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postOptionsDirectory(token, data) {
  let response = await axios({
    url: `${url_admin}/options`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ...data },
    Accept: "application / json",
  });
  return response;
}

export async function postPromotionsDirectory(token, data) {
  let response = await axios({
    url: `${url_admin}/promotions`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ...data },
    Accept: "application / json",
  });
  return response;
}

export async function getOptions(token) {
  let response = await axios({
    url: `${url_admin}/operator-options`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function putOptions(token, id, data) {
  let response = await axios({
    url: `${url_admin}/operator-options/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ...data },
    Accept: "application / json",
  });
  return response;
}
export async function postOptions(token, data) {
  let response = await axios({
    url: `${url_admin}/operator-options`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ...data },
    Accept: "application / json",
  });
  return response;
}

export async function deleteOptions(token, id) {
  let response = await axios({
    url: `${url_admin}/operator-options/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postImageOptions(token, data, id) {
  let response = await axios({
    url: `${url_admin}/operator-options/${id}/operator_option_pictures`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
    Accept: "application / json",
  });
  return response;
}

export async function deleteImageOptions(token, id, image_id) {
  let response = await axios({
    url: `${url_admin}/operator-options/${id}/operator_option_pictures/${image_id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getPromotions(token) {
  let response = await axios({
    url: `${url_admin}/operator-promotions`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getPromotionsByID(token, id) {
  let response = await axios({
    url: `${url_admin}/operator-promotions/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postPromotions(token, data) {
  let response = await axios({
    url: `${url_admin}/operator-promotions`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function putPromotions(token, data, id) {
  let response = await axios({
    url: `${url_admin}/operator-promotions/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function deletePromotions(token, id) {
  let response = await axios({
    url: `${url_admin}/operator-promotions/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getOrders(token) {
  let response = await axios({
    url: `${url_admin}/orders`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getCancelOrder(token, id, data) {
  let response = await axios({
    url: `${url_client}/order-cancel-paid/${id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getCancelOrderAdmin(token, id, data) {
  let response = await axios({
    url: `${url_admin}/order-cancel-paid/${id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postOrderResuls(token, id, data) {
  let response = await axios({
    url: `${url_client}/order-result/${id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postOrderStatus(token, id, data) {
  let response = await axios({
    url: `${url_client}/order-status/${id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function putDelOrder(token, id, data) {
  let response = await axios({
    url: `${url_client}/orders/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getOrdersClient(token) {
  let response = await axios({
    url: `${url_client}/orders`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postOrdersClient(token, data) {
  let response = await axios({
    url: `${url_client}/orders`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getOrdersByID(token, id) {
  let response = await axios({
    url: `${url_admin}/orders/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getOrdersClientByID(token, id) {
  let response = await axios({
    url: `${url_client}/orders/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getRulesOperatorUsers(token, user_id) {
  let response = await axios({
    url: `${url_admin}/operator_users/${user_id}/rules`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postRulesOperatorUsers(token, user_id, data) {
  let response = await axios({
    url: `${url_admin}/operator_users/${user_id}/rules`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function putRulesOperatorUsers(token, user_id, data, rule_id) {
  let response = await axios({
    url: `${url_admin}/operator_users/${user_id}/rules/${rule_id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getOperations(token) {
  let response = await axios({
    url: `${url_admin}/operations`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getOperationsByID(token, id) {
  let response = await axios({
    url: `${url_admin}/operations/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postImageReviews(token, data, id) {
  let response = await axios({
    url: `${url_client}/clients-reviews/${id}/pictures`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
    Accept: "application / json",
  });
  return response;
}

export async function getReviews(token) {
  let response = await axios({
    url: `${url_admin}/clients-reviews`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}
export async function getReviewsByID(token, id) {
  let response = await axios({
    url: `${url_admin}/clients-reviews/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function putReviews(token, id, data) {
  let response = await axios({
    url: `${url_admin}/clients-reviews/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postReviews(token, data) {
  let response = await axios({
    url: `${url_client}/clients-reviews`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getProductsGuest() {
  let response = await axios({
    url: `${url_client}/products`,
    method: "GET",
    Accept: "application / json",
  });
  return response;
}

export async function getProductsClient(token) {
  let response = await axios({
    url: `${url_client}/products`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getProductsGuestByID(id) {
  let response = await axios({
    url: `${url_client}/products/${id}`,
    method: "GET",
    Accept: "application / json",
  });
  return response;
}

export async function getProductsClientByID(token, id) {
  let response = await axios({
    url: `${url_client}/products/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function changeStatusProducts(token, product_id, status_to) {
  let response = await axios({
    url: `${url_admin}/product-change-status/${product_id}/${status_to}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getFAQ(token) {
  let response = await axios({
    url: `${url_admin}/faq`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getFAQbyID(token, id) {
  let response = await axios({
    url: `${url_admin}/faq/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postFAQ(token, data) {
  let response = await axios({
    url: `${url_admin}/faq`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function putFAQ(token, id, data) {
  let response = await axios({
    url: `${url_admin}/faq/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function deleteFAQ(token, id) {
  let response = await axios({
    url: `${url_admin}/faq/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}

export async function postUpdateClient(token, data) {
  let response = await axios({
    url: `${url_client}/update-profile`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}

export async function getClient(token) {
  let response = await axios({
    url: `${url_client}/user`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },

    Accept: "application / json",
  });
  return response;
}

export async function postOrderPay(token, id, data) {
  let response = await axios({
    url: `${url_client}/order-pay/${id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": `application/json;`,
    },
    data: {
      ...data,
    },
    Accept: "application / json",
  });
  return response;
}
export async function postFavorite(token, id, data) {
  let response = await axios({
    url: `${url_client}/products/${id}/favorite?operation=${data}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    Accept: "application / json",
  });
  return response;
}
