import { IonModal } from "@ionic/react";
import Close from "../../ui/Close";
import Rating from "../../ui/Rating";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import Input from "../../ui/Input";
import { useState } from "react";

const ModalReviewsReport = (props) => {
  return (
    <IonModal
      className="modal-reviews-report"
      isOpen={props.open}
      onWillDismiss={() => {
        props.onClose(false);
      }}
    >
      <Close
        onClick={() => {
          props.onClose(false);
        }}
      />
      <div className="body-modal-reviews-report">
        <div className="title">Пожаловаться</div>
        <div className="cont-text-information">
          <div className="text-reviews">
            Пожалуйста, опишите причину вашей жалобы. Мы сообщим вам о принятых мерах по окончании
            проверки.
          </div>
          <Input
            placeholder="Ваше сообщение..."
            className="large area area-reviews"
            type="textarea"
          />
        </div>
        <div className="btns">
          <Button
            typebtn="outline"
            className="small"
            onClick={() => {
              props.onClose(false);
            }}
          >
            Отмена
          </Button>
          <Button
            typebtn="bright"
            className="small"
            onClick={() => {
              props.onClose(false);
            }}
          >
            Отправить жалобу
          </Button>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalReviewsReport;
