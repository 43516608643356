import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import img1 from "../../image/temporary/1.png";
import img2 from "../../image/temporary/2.png";
import img3 from "../../image/temporary/3.jpeg";
import PreviewTour from "../../ui/PreviewTour";
import Slider from "../../ui/Slider";
import Icons from "../../ui/Icons";
import Button from "../../ui/Button";
import { useSelector } from "react-redux";
import { useCallback, useRef } from "react";
const DraftTours = () => {
  const products = useSelector((state) => state.products);
  const arrayProducts = products.filter((el) => el.status_id === 1);

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div className="draft-tours-cont">
      <div className="title-cont">
        <div className="title">Черновики туров</div>
        <div className="btn">
          <Button typebtn="outline" className="small cube" onClick={handlePrev}>
            <Icons icon="design-personal-chevron-left" />
          </Button>
          <Button typebtn="outline" className="small cube" onClick={handleNext}>
            <Icons icon="design-personal-chevron-right" />
          </Button>
        </div>
      </div>
      <Slider sliderRef={sliderRef} array={arrayProducts} type="draft" />
    </div>
  );
};

export default DraftTours;
