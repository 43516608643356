import { IonContent } from "@ionic/react";
import "../../less/css/settings.css";
import Table from "../../ui/Table";
import { useDispatch, useSelector } from "react-redux";
import Icons from "../../ui/Icons";
import Button from "../../ui/Button";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import ModalFilterTableEmployees from "../../ui/ModalsFilter/ModalFilterTableEmployees";
import { FIELD_FOR_OPERATOR_USERS, STATUSES_OPERATOR_USERS } from "../../utils/array";
import ModalDelete from "../showcase/Modals/ModalDelete";
import { deletePartnerUsers, getPartnerUsers } from "../../utils/api";
import { setOperatorUsers } from "../../store/actions";
import Toast from "../../ui/Toast";

const EmployeesSettingsContent = () => {
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const status = STATUSES_OPERATOR_USERS;
  // const fieldArray = FIELD_FOR_OPERATOR_USERS;
  const operator_users = useSelector((state) => state.operator_users);
  const [focusElement, setFocusElement] = useState();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [modalDeleteOperUser, setModalDeleteOperUser] = useState(false);
  const history = useHistory();
  const [openMenuElem, setOpenMenuElem] = useState(false);
  const userPermissions = useSelector((state) => state.userPermissions.settings?.operator_users);
  const columnsTable = useSelector((state) => state.columnsTable);

  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });

  const numberColumns = 2;
  const [fieldArray, setFieldArray] = useState();
  useEffect(() => {
    setFieldArray(JSON.parse(localStorage.getItem("column"))[numberColumns]);
  }, [columnsTable]);

  var arrayForExcel = [];

  for (let i = 0; i < operator_users?.length; i++) {
    let new_array = {
      Номер: operator_users[i]?.user_id,
      "Дата создания": operator_users[i]?.created_at,
      "ФИО сотрудника": `${operator_users[i]?.user?.last_name ?? ""} ${
        operator_users[i]?.user?.first_name ?? ""
      } ${operator_users[i]?.user?.middle_name ?? ""}`,
      Email: operator_users[i]?.user?.email,
      "Роль в компании": operator_users[i]?.operator_role,
      "Последний вход": operator_users[i]?.last_login,
      Статус: operator_users[i]?.status_name,
    };
    arrayForExcel.push(new_array);
  }

  const menu = [
    {
      name: "Редактировать",
      icon: "design-personal-edit",
      click: (e) => {
        if (userPermissions[2]) {
          history.push(`/partner/settings/employees/users?id=${focusElement.user_id}`);
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
      },
      status: [1, 2],
    },
    {
      name: "Удалить",
      icon: "design-personal-trash",
      click: () => {
        if (userPermissions[3]) {
          setModalDeleteOperUser(true);
          setOpenMenuElem(false);
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
      },
      status: [1, 2],
    },
  ];

  return (
    <>
      <IonContent className="page">
        <div className="employees-settings-page">
          <div className="upper-line">
            <div className="title">Сотрудники</div>
            <Button
              className="btn-create small"
              typebtn="bright"
              onClick={() => {
                if (userPermissions[1]) {
                  history.push("/partner/settings/employees/users");
                } else {
                  setOpenToast(true);
                  setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
                }
              }}
            >
              <Icons className="icon-bright" icon="plus" />
              Создать
            </Button>
          </div>

          <Table
            onClick={(el) => {
              history.push(`/partner/settings/employees/users?id=${el.user_id}`);
            }}
            openMenuElem={openMenuElem}
            setOpenMenuElem={setOpenMenuElem}
            openFilterModal={setOpenFilterModal}
            focus={setFocusElement}
            status={status}
            array={operator_users}
            header={fieldArray}
            numberColumns={numberColumns}
            menu={menu}
            permission={userPermissions}
            classNameTableSetting="employee"
            arrayForExcel={arrayForExcel}
            nameFile={`Таблица сотрудников ${new Date().toLocaleDateString("ru")}`}
            searchPlaceholder="Поиск по имени, email, примечанию..."
          />
        </div>
      </IonContent>
      <ModalFilterTableEmployees onClose={setOpenFilterModal} open={openFilterModal} />
      <ModalDelete
        open={modalDeleteOperUser}
        onClose={setModalDeleteOperUser}
        onClick={() => {
          deletePartnerUsers(token, focusElement.user_id).then(async (e) => {
            if (!e.data.errors) {
              await getPartnerUsers(token).then((e) => {
                dispatch(setOperatorUsers(e.data.data));
              });
              setModalDeleteOperUser(false);
            }
          });
        }}
      />
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </>
  );
};

export default EmployeesSettingsContent;
