import { useEffect, useState } from "react";
import { FIELD_FOR_FAQ, STATUSES_FAQ } from "../../utils/array";
import { useDispatch, useSelector } from "react-redux";
import { IonContent } from "@ionic/react";
import Table from "../../ui/Table";
import ModalFilterFAQ from "../../ui/ModalsFilter/ModalFilterFAQ";
import ModalFAQDetails from "./ModalFAQDetails";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import ModalDelete from "../showcase/Modals/ModalDelete";
import { deleteFAQ, getFAQ } from "../../utils/api";
import { setFAQ } from "../../store/actions";
import Toast from "../../ui/Toast";

const FAQContent = () => {
  const faq = useSelector((state) => state.faq);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const [focusElement, setFocusElement] = useState();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openMenuElem, setOpenMenuElem] = useState(false);
  const [openFAQDetail, setOpenFAQDetail] = useState(false);
  const [modalDeleteFAQ, setModalDeleteFAQ] = useState(false);
  const userPermissions = useSelector((state) => state.userPermissions.feedback?.questions);
  const columnsTable = useSelector((state) => state.columnsTable);

  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  const status = STATUSES_FAQ;
  // const fieldArray = FIELD_FOR_FAQ;

  const numberColumns = 8;
  const [fieldArray, setFieldArray] = useState();
  useEffect(() => {
    setFieldArray(JSON.parse(localStorage.getItem("column"))[numberColumns]);
  }, [columnsTable]);

  var arrayForExcel = [];

  for (let i = 0; i < faq?.length; i++) {
    let status =
      faq[i]?.status_id === 1 ? "Не опубликован" : faq[i]?.status_id === 2 && "Опубликован";
    let products = ``;
    for (let j = 0; j < faq[i].products.length; j++) {
      products += `${faq[i].products[j]?.name}, `;
    }
    let new_array = {
      Номер: faq[i]?.id,
      "Дата создания": faq[i]?.created_at,
      "В турах": faq[i]?.products.length,
      "Название туров": products,
      "Текст вопроса": faq[i]?.review_text,
      "Текст ответа": faq[i]?.response_text,
      Статус: status,
    };
    arrayForExcel.push(new_array);
  }

  const menu = [
    {
      name: "Редактировать",
      icon: "design-personal-edit",
      click: (e) => {
        if (userPermissions[1]) {
          setOpenFAQDetail(true);
          setOpenMenuElem(false);
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
      },
      status: [1, 2],
    },
    {
      name: "Удалить",
      icon: "design-personal-trash",
      click: () => {
        if (userPermissions[1]) {
          setModalDeleteFAQ(true);
          setOpenMenuElem(false);
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
      },
      status: [1, 2],
    },
  ];
  return (
    <>
      <IonContent className="page">
        <div className="faq-feedback-page">
          <div className="upper-line">
            <div className="title">Частые вопросы</div>
            <Button
              className="btn-create small"
              typebtn="bright"
              onClick={() => {
                setOpenFAQDetail(true);
              }}
            >
              <Icons className="icon-bright" icon="plus" />
              Создать
            </Button>
          </div>
          <Table
            onClick={() => {
              if (userPermissions[0]) {
                setOpenFAQDetail(true);
              } else {
                setOpenToast(true);
                setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
              }
            }}
            openMenuElem={openMenuElem}
            setOpenMenuElem={setOpenMenuElem}
            openFilterModal={setOpenFilterModal}
            focus={setFocusElement}
            status={status}
            array={faq}
            header={fieldArray}
            numberColumns={numberColumns}
            menu={menu}
            permission={userPermissions}
            classNameTableSetting="faq"
            arrayForExcel={arrayForExcel}
            nameFile={`Таблица частых вопросов ${new Date().toLocaleDateString("ru")}`}
            searchPlaceholder="Поиск по названию вопроса..."
          />
        </div>
      </IonContent>
      <ModalFilterFAQ open={openFilterModal} onClose={setOpenFilterModal} />
      <ModalFAQDetails
        open={openFAQDetail}
        onClose={setOpenFAQDetail}
        element={focusElement}
        setFocusElement={setFocusElement}
      />
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
      <ModalDelete
        open={modalDeleteFAQ}
        onClose={setModalDeleteFAQ}
        onClick={() => {
          deleteFAQ(token, focusElement.id).then(async (e) => {
            if (!e.data.errors) {
              await getFAQ(token).then((g) => {
                dispatch(setFAQ(g.data.data));
              });
              setModalDeleteFAQ(false);
            }
          });
        }}
      />
    </>
  );
};

export default FAQContent;
