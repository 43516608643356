import { Redirect, Route, useLocation } from "react-router-dom";
import { IonApp, IonContent, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import FirstPage from "./pages/FirstPage";
import Profile from "./pages/Profile";
import "./less/css/custom.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import LoginModal from "./components/firstpage/LoginModal";
import LoginModalPartner from "./components/firstpage/LoginModalPartner";
import AccessRecoveryModal from "./components/firstpage/AccessRecoveryModal";
import LeftMenu from "./components/firstpage/LeftMenu";
import PartnerPage from "./pages/PartnerPage";
import InformationPage from "./pages/InformationPage";
import ClientPage from "./pages/ClientPage";
import LeftMenuFilter from "./components/firstpage/LeftMenuFilter";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getClient } from "./utils/api";
import { setUser } from "./store/actions";
// import { SmsAero, SmsAeroError, SmsAeroHTTPError } from "smsaero";

setupIonicReact();
// const clientAero = new SmsAero("info@smorodina.ru", "2-2rA5iKcZ4BZCvcbEd118517duXBG8p");

const App = () => {
  function setViewportProperty() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  // Реагируем на изменение размеров экрана
  window.addEventListener("resize", setViewportProperty);

  // Выполняем инициализацию
  setViewportProperty();
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      getClient(token).then(async (e) => {
        if (!e.data.errors) {
          dispatch(setUser(e.data.user));
        }
      });
    }
  }, [token]);
  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet id="main-content">
          <Route exact path="/">
            <FirstPage />
          </Route>
          <InformationPage />
          <Route path="/client">
            <ClientPage />
          </Route>
          <Route path="/partner">
            <PartnerPage />
          </Route>
        </IonRouterOutlet>
        <LoginModal />
        <LoginModalPartner />
        <AccessRecoveryModal />
        <LeftMenu />
        <LeftMenuFilter />
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
