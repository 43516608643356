import { useEffect, useState } from "react";

const Badge = (props) => {
  const [randm, setRandm] = useState();
  var colors_badge = [
    "red",
    "blue",
    "sulu",
    "lgreen",
    "mint",
    "blizzard",
    "purple",
    "lavender",
    "pink",
  ];
  useEffect(() => {
    let rand = Math.floor(Math.random() * colors_badge.length);
    setRandm(rand);
  }, []);

  return (
    <div className={`cont-badge ${props.className} ${colors_badge[randm]}`}>
      <div className="text">{props.children}</div>
    </div>
  );
};

export default Badge;
