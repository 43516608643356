import { IonContent } from "@ionic/react";
import Footer from "../../ui/Footer";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import iconMapX from "../../image/icons/map-pin-x.svg";
import image from "../../image/image-client-no-tours.png";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Rating from "../../ui/Rating";
import Status from "../../ui/Status";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useState } from "react";
import { getCancelOrder, getOrdersClient, putDelOrder } from "../../utils/api";
import { setOpenModalCancelOrders, setOrders } from "../../store/actions";
import ClientModalCancelOrders from "./ClientModalCancelOrders";
import ModalDelete from "../showcase/Modals/ModalDelete";
import { URL_ORIGIN } from "../../utils/config";
import Toast from "../../ui/Toast";

const ClientToursContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.token);
  // const orders = useSelector((state) => state.orders);
  const orders = useSelector((state) => state.orders);
  const [modalDeleteOrder, setModalDeleteOrder] = useState(false);
  const [delEl, setDelEl] = useState();
  const [loadbtndel, setLoadbtndel] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  const paid = orders
    ?.filter((el) => el.status_id === 1 || el.status_id === 2 || el.status_id === 3)
    .reverse();
  const complete = orders?.filter((el) => el.status_id === 6).reverse();
  const cancels = orders?.filter((el) => el.status_id === 5).reverse();
  useEffect(() => {
    setLoading(true);
    getOrdersClient(token).then((e) => {
      dispatch(setOrders(e.data.data));
      setLoading(false);
    });
  }, []);
  return (
    <IonContent className="page-guest">
      <div className="client-tours-page">
        {!loading ? (
          <div className="content">
            <div className="title">Предстоящие путешествия</div>
            <div className="tours">
              {paid?.length ? (
                <div className="orders-cont">
                  {paid?.map((el, i) => {
                    let reviews = el?.product?.clients_reviews.filter(
                      (el) => el.type === 0 && !el.parent_id
                    );
                    return (
                      <div key={i} className="tour">
                        <div className="left">
                          <div className="image-cont">
                            <img src={`${URL_ORIGIN}${el?.product.photo}`} />
                          </div>
                        </div>
                        <div className="right">
                          <div className="info">
                            <div className="rating">
                              <Rating
                                rate={
                                  el?.product?.rating?.[0]?.avg ? el?.product?.rating?.[0]?.avg : 0
                                }
                              />
                              <div className="val-rate">
                                {el?.product?.rating?.[0]?.avg ? el?.product?.rating?.[0]?.avg : 0}
                              </div>
                              <div className="reviews">{`${reviews?.length} отзывов`}</div>
                            </div>
                            <div className="title">{el?.product?.name}</div>
                            <div className="number">{`№ ${el?.id}`}</div>
                            <Status
                              color={
                                el?.status?.id === 4
                                  ? "orange"
                                  : el?.status?.id === 5
                                  ? "red"
                                  : el?.status?.id === 6
                                  ? "green"
                                  : `gray`
                              }
                              // className="status-h"
                            >
                              {el?.status?.description}
                            </Status>
                            <div className="summa-cont">
                              <div className="summa">{`${String(+el?.summa).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                " "
                              )} ₽`}</div>
                              <div className="duration">{`/ ${el?.product?.duration?.name} `}</div>
                            </div>
                          </div>
                          <div className="btns">
                            {(el?.status_id === 2 || el?.status_id === 1) && (
                              <Button
                                typebtn="outline"
                                className="medium"
                                onClick={() => {
                                  if (el?.status_id === 2) {
                                    dispatch(setOpenModalCancelOrders({ open: true, el: el }));
                                  } else if (el?.status_id === 1) {
                                    setDelEl(el);
                                    setModalDeleteOrder(true);
                                  }
                                }}
                              >
                                Отменить тур
                              </Button>
                            )}
                            <Button
                              typebtn="muted"
                              className="medium"
                              onClick={() => {
                                history.push(`/tour?id=${el?.product?.id}&order=${el.id}`);
                              }}
                            >
                              Смотреть
                              <Icons icon="arrow-narrow-right" />
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="cont-null">
                  <div className="left">
                    <img className="icon-map-x" src={iconMapX} />
                    <div className="cont-text">
                      <div className="text">Пока ничего не запланировано</div>
                      <div className="text-muted">Пришло время для новых приключений!</div>
                    </div>
                    <Button className="medium" typebtn="bright" onClick={() => history.push("/")}>
                      Начать поиск
                      <Icons icon="arrow-narrow-right" />
                    </Button>
                  </div>
                  <div className="right">
                    <img src={image} />
                  </div>
                </div>
              )}
              {complete?.length || cancels?.length ? <div className="l" /> : undefined}
              {complete?.length ? (
                <div className="visited-cont">
                  <div className="title">Где вы побывали</div>
                  {complete?.map((el, i) => {
                    return (
                      <div key={i} className="tour">
                        <div className="left">
                          <div className="cont-image">
                            <img src={`${URL_ORIGIN}${el?.product.photo}`} />
                          </div>
                        </div>
                        <div className="right">
                          <div className="info">
                            <div className="rating">
                              <Rating
                                rate={
                                  el?.product?.rating?.[0]?.avg ? el?.product?.rating?.[0]?.avg : 0
                                }
                              />
                              <div className="val-rate">
                                {el?.product?.rating?.[0]?.avg ? el?.product?.rating?.[0]?.avg : 0}
                              </div>
                              <div className="reviews">{`0 отзывов`}</div>
                            </div>
                            <div className="title">{el?.product?.name}</div>
                            <div className="number">{`№ ${el?.id}`}</div>
                            <Status
                              color={
                                el?.status?.id === 4
                                  ? "orange"
                                  : el?.status?.id === 5
                                  ? "red"
                                  : el?.status?.id === 6
                                  ? "green"
                                  : `gray`
                              }
                              // className="status-h"
                            >
                              {el?.status?.description}
                            </Status>
                          </div>
                          <div className="btn">
                            <Button
                              typebtn="bright"
                              className="small"
                              onClick={() => {
                                history.push(
                                  `/tour/review?id=${el?.product?.id}&order_id=${el?.id}`
                                );
                              }}
                            >
                              Оставить отзыв
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : undefined}
              {cancels?.length ? (
                <div className="visited-cont">
                  <div className="title">Отменённые поездки</div>
                  {cancels?.map((el, i) => {
                    return (
                      <div key={i} className="tour">
                        <div className="left">
                          <div className="cont-image">
                            <img src={`${URL_ORIGIN}${el?.product.photo}`} />
                          </div>
                        </div>
                        <div className="right">
                          <div className="info">
                            <div className="rating">
                              <Rating
                                rate={
                                  el?.product?.rating?.[0]?.avg ? el?.product?.rating?.[0]?.avg : 0
                                }
                              />
                              <div className="val-rate">
                                {el?.product?.rating?.[0]?.avg ? el?.product?.rating?.[0]?.avg : 0}
                              </div>
                              <div className="reviews">{`0 отзывов`}</div>
                            </div>
                            <div className="title">{el?.product?.name}</div>
                            <div className="number">{`№ ${el?.id}`}</div>
                            <Status
                              color={
                                el?.status?.id === 4
                                  ? "orange"
                                  : el?.status?.id === 5
                                  ? "red"
                                  : el?.status?.id === 6
                                  ? "green"
                                  : `gray`
                              }
                              // className="status-h"
                            >
                              {el?.status?.description}
                            </Status>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : undefined}
            </div>
          </div>
        ) : (
          <div className="content-scroll">
            <ProgressSpinner style={{ width: "48px", height: "48px" }} stroke="#696775" />
          </div>
        )}
        <Footer />
      </div>
      <ClientModalCancelOrders />
      <ModalDelete
        title="Отмена тура"
        text="Действительно хотите отменить тур?"
        btn_delete="Да, отменить"
        open={modalDeleteOrder}
        onClose={setModalDeleteOrder}
        loading={loadbtndel}
        disabled={loadbtndel}
        onClick={() => {
          setLoadbtndel(true);
          let forSend = {
            reason_cancel: "Отмена заказа клиентом",
          };
          getCancelOrder(token, delEl?.id, forSend)
            .then(async (e) => {
              if (!e.data.errors) {
                getOrdersClient(token).then((q) => {
                  dispatch(setOrders(q.data.data));
                  setModalDeleteOrder(false);
                  setDelEl(null);
                  setLoadbtndel(false);
                });
              } else {
                setLoadbtndel(false);
                setOpenToast(true);
                setMessageToast({
                  text: typeof e.data.errors === "string" ? e.data.errors : "Ошибка отмены тура",
                  type: "error",
                });
              }
            })
            .catch((e) => {
              setLoadbtndel(false);
              setOpenToast(true);
              setMessageToast({
                text:
                  typeof e.response.data.errors === "string"
                    ? e.response.data.errors
                    : "Ошибка отмены тура",
                type: "error",
              });
            });
        }}
      />
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </IonContent>
  );
};

export default ClientToursContent;
