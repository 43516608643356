import Button from "./Button";
import Icons from "./Icons";
import "../less/css/custom.css";
import star from "../image/icons/stars.png";
import noImage from "../image/no-image.svg";
import { useMemo } from "react";
import { setModalLogin, setOpenQuickView, setProductsClients } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getProductsClient, getProductsClientByID, postFavorite } from "../utils/api";
import { countProm } from "../utils/utils";
import { URL_ORIGIN } from "../utils/config";
import qs from "qs";

const PreviewTour = (props) => {
  const element = useMemo(() => props.el, [props.el]);
  const history = useHistory();
  const urlSearch = qs.parse(history.location.search.substr(1));

  const token = useSelector((state) => state.token);
  const reviews = element?.clients_reviews.filter((el) => el.type === 0 && !el.parent_id);
  const dispatch = useDispatch();

  let today = new Date().getTime();

  let arrayProm = props.el?.operator_promotions?.filter((e) => e.type == 0);
  arrayProm = arrayProm?.filter(
    (e) => today >= new Date(e.start_date).getTime() && today <= new Date(e.endDate).getTime()
  );

  // console.log('arrayProm', arrayProm);

  return props.type === "draft" ? (
    <div
      className={`tour-el ${props.className ? props.className : ""}`}
      onClick={() => {
        history.push(`/partner/showcase/tours/details?id=${props.el?.id}`);
      }}
    >
      <div className="images-tour">
        {props.el?.photo ? (
          <img src={`${URL_ORIGIN}${props.el?.photo}`} />
        ) : (
          <div>
            <img src={noImage} />
          </div>
        )}
        <div className="tag">черновик</div>
        {/* <div className="value-images">{`+${props.el?.images}`}</div> */}
      </div>
      <div className="short-inf">
        <div className="name-tour">{props.el?.name}</div>
        <div className="price">
          {arrayProm?.length > 0 ? (
            <div className="last-price">{`${String(+props.el?.summa).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              " "
            )}`}</div>
          ) : undefined}
          <div className="cont-price">
            <div className={`price-value ${arrayProm?.length > 0 ? "promotion" : ""}`}>
              {arrayProm?.length > 0
                ? `${String(countProm(arrayProm, props.el.summa)).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    " "
                  )} ₽`
                : `${String(+props.el?.summa).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}
            </div>
            <div className="duration">/</div>
            <div className="duration width">{`${props.el?.duration.name}`}</div>
          </div>
        </div>
      </div>
    </div>
  ) : props.type === "tours" ? (
    <div
      className={`tour-el ${props.className ? props.className : ""}`}
      onClick={(e) => {
        e.stopPropagation();
        props.maps && props.maps?.remove();
        props.setMaps && props.setMaps(null);
        props.closeMobile && props.closeMobile(false);
        dispatch(setOpenQuickView({ open: true, el: props.el }));
        if (history?.location?.pathname === "/partners") {
          // history.push(`/tour?id=${props.el?.id}`);
          let data = urlSearch;
          data.id = props.el?.id;
          const params = new URLSearchParams(data);
          history.push(`/tour?${params.toString()}`);
        } else {
          // history.push(`?id=${props.el?.id}`);
          let data = urlSearch;
          data.id = props.el?.id;
          const params = new URLSearchParams(data);
          history.push(`?${params.toString()}`);
        }
      }}
    >
      <div className="images-tour">
        {props.el?.photo ? <img src={`${URL_ORIGIN}${props.el?.photo}`} /> : <div></div>}
        <div className="value-images">
          {element?.pictures?.length ? `+${element?.pictures?.length}` : "0"}
        </div>
      </div>
      <div className="short-inf">
        <div className="rating">
          {/* <Icons icon="ratingstar" /> */}
          <img src={star} />
          <div className="value-rat">{props.el?.rating[0]?.avg || "0"}</div>
          <div className="value-review">{`${reviews?.length || "0"} отзывов`}</div>
        </div>
        <div className="name-tour">{props.el?.name}</div>
        <div className="price">
          {arrayProm?.length > 0 ? (
            <div className="last-price">{`${String(+props.el?.summa).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              " "
            )}`}</div>
          ) : undefined}
          <div className="cont-price">
            <div className={`price-value ${arrayProm?.length > 0 ? "promotion" : ""}`}>
              {arrayProm?.length > 0
                ? `${String(countProm(arrayProm, props.el.summa)).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    " "
                  )} ₽`
                : `${String(+props.el?.summa).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}
            </div>
            <div className="duration">{`/`}</div>
            <div className="duration width">{`${props.el?.duration.name || ""}`}</div>
          </div>
        </div>
        <Button
          className={`favourite small cube ${element?.favorite?.[0]?.favorite ? "btn-fav" : ""}`}
          typebtn="ghost"
          onClick={(e) => {
            e.stopPropagation();
            if (token) {
              if (element?.favorite?.[0]?.favorite) {
                postFavorite(token, element?.id, "delete").then(async (e) => {
                  if (!e.data.errors) {
                    await getProductsClientByID(token, element?.id).then((e) => {
                      if (!e.data.errors) {
                        dispatch(setOpenQuickView({ open: false, el: e.data.data }));
                      }
                    });

                    await getProductsClient(token).then((e) => {
                      dispatch(setProductsClients(e.data.data));
                    });
                  }
                });
              } else {
                postFavorite(token, element?.id, "add").then(async (e) => {
                  if (!e.data.errors) {
                    await getProductsClientByID(token, element?.id).then((e) => {
                      if (!e.data.errors) {
                        dispatch(setOpenQuickView({ open: false, el: e.data.data }));
                      }
                    });
                    await getProductsClient(token).then((e) => {
                      dispatch(setProductsClients(e.data.data));
                    });
                  }
                });
              }
            } else {
              dispatch(setModalLogin({ open: true }));
            }
          }}
        >
          <Icons icon={element?.favorite?.[0]?.favorite ? "heart-filled" : "heart"} />
        </Button>
      </div>
    </div>
  ) : undefined;
};

export default PreviewTour;
