import { IonModal } from "@ionic/react";
import Button from "../../../ui/Button";
import Close from "../../../ui/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  changeStatusProducts,
  getToursByID,
  getToursOperator,
  putCreateTour,
} from "../../../utils/api";
import { setProducts, setTourFocus } from "../../../store/actions";

const ModalSubmitForModeration = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);

  return (
    <IonModal
      className="modal-sumbit-for-moderation"
      isOpen={props.open}
      onWillDismiss={() => props.onClose(false)}
    >
      <Close onClick={() => props.onClose(false)} />
      <div className="body-modal-sumbit-for-moderation">
        <div className="title">Отправка тура на модерацию</div>
        <div className="cont-text">
          <div className="up-t">
            <div className="text">
              Пожалуйста, убедитесь, что данные тура заполнены корректно. Во время модерации и после
              успешной проверки
            </div>
            <div className="boldtext">нельзя вносить изменения в тур (кроме расписания).</div>
          </div>
          <div className="text">
            Когда тур пройдёт проверку, вы сможете его опубликовать. Если мы обнаружим ошибки, то
            сообщим об этом на email ответственного сотрудника (указывается в параметрах тура) и
            попросим внести изменения перед повторной модерацией.
          </div>
        </div>
        <div className="btns">
          <Button typebtn="outline" className="small" onClick={() => props.onClose(false)}>
            Отмена
          </Button>
          <Button
            typebtn="bright"
            className="small"
            onClick={async () => {
              await changeStatusProducts(token, props.element.id, 2).then(async (e) => {
                if (e.data.errors) {
                } else {
                  await getToursOperator(token).then((e) => {
                    if (e.data.data) {
                      dispatch(setProducts(e.data.data));
                    }
                  });
                  props.fromInfo &&
                    getToursByID(token, props.element.id).then((e) => {
                      dispatch(setTourFocus(e.data.data));
                    });
                }
              });
              // TODO ВРЕМЕННОЕ
              // await changeStatusProducts(
              //   "fbprl5qbam9lEFyoTzzH9ZxfdjixIE8zIf4yOsIf",
              //   props.element.id,
              //   4
              // ).then(async (e) => {
              //   if (e.data.errors) {
              //   } else {
              //     await getToursOperator(token).then((e) => {
              //       if (e.data.data) {
              //         dispatch(setProducts(e.data.data));
              //       }
              //     });
              //     props.fromInfo &&
              //       getToursByID(token, props.element.id).then((e) => {
              //         dispatch(setTourFocus(e.data.data));
              //       });
              //   }
              // });
              //
              props.onClose(false);
            }}
          >
            Подтвердить
          </Button>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalSubmitForModeration;
