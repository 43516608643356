import { IonModal } from "@ionic/react";
import Button from "../../../ui/Button";
import Close from "../../../ui/Close";

const ModalInsufficientDeposit = (props) => {
  return (
    <IonModal
      className="modal-archive-tour"
      isOpen={props.open}
      onWillDismiss={() => props.onClose(false)}
    >
      <Close onClick={() => props.onClose(false)} />
      <div className="body-modal-archive-tour">
        <div className="title">Это пока нельзя сделать.</div>
        <div className="cont-text">
          <div className="text">
            {
              "Ваш текущий депозит (0 ₽) ниже минимального значения (50 000 ₽). Пополните депозит, чтобы иметь возможность опубликовать тур."
            }
          </div>
        </div>
        <div className="btns">
          <Button typebtn="outline" className="small" onClick={() => props.onClose(false)}>
            Закрыть
          </Button>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalInsufficientDeposit;
