import { useDispatch, useSelector } from "react-redux";
import lock from "../../image/icons/lock-question.svg";
import mail from "../../image/icons/mail-check.svg";
import password from "../../image/icons/password-user.svg";
import { setModalLogin, setModalLoginPartner, setModalRecovery } from "../../store/actions";
import { useEffect, useState } from "react";
import { IonModal } from "@ionic/react";
import Close from "../../ui/Close";
import Button from "../../ui/Button";
import Input from "../../ui/Input";
import background from "../../image/modal-background-decor.svg";
import { postForgotPassword, postResetPassword } from "../../utils/api";
import Toast from "../../ui/Toast";
import { useHistory } from "react-router";
import qs from "qs";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";

const AccessRecoveryModal = () => {
  const modalRecovery = useSelector((state) => state.modalRecovery);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const urlSearch = qs.parse(history.location.search.substr(1));

  const [alertMessage, setAlertMessage] = useState(false);
  const [recovery, setRecovery] = useState(false);
  const [email, setEmail] = useState();
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  const dispatch = useDispatch();
  useAsyncEffect(async () => {
    if (urlSearch?.token_recovery) {
      await setRecovery(true);
    }
  }, []);
  return (
    <IonModal
      className={`modal-login-recovery ${alertMessage ? "alert-message" : ""} ${
        recovery ? "recovery" : ""
      }`}
      isOpen={modalRecovery.open}
      onWillDismiss={() => {
        if (urlSearch?.token_recovery) {
          history.push("/");
        }
        dispatch(setModalRecovery({ open: false }));
        setRecovery(false);
        setAlertMessage(false);
      }}
    >
      {!alertMessage && !recovery && (
        <div className="body-modal-login">
          <img className="image-back-login" src={background} />
          <Close
            onClick={() => {
              if (urlSearch?.token_recovery) {
                history.push("/");
              }
              setRecovery(false);
              setAlertMessage(false);
              dispatch(setModalRecovery({ open: false }));
            }}
          />
          <div className="title">
            <img src={lock} />
            Восстановление доступа
          </div>
          <form
            className="form-login"
            onSubmit={(e) => {
              e.preventDefault();
              let info = {};
              let inputs = e.target.querySelectorAll("input");
              inputs.forEach((input) => (info[input.name] = input.value));
            }}
          >
            <>
              <Input
                name="email"
                noImage
                type="email"
                className="login medium"
                placeholder="email"
                onChange={(e) => setEmail(e)}
              />
              <Button
                loading={loading}
                disabled={loading}
                className={`btn-login medium btn-cont-recovery`}
                type="submit"
                typebtn="bright"
                onClick={() => {
                  // setRegister(false);
                  if (email) {
                    setLoading(true);
                    let forSend = {
                      login: email,
                    };
                    postForgotPassword(forSend).then((e) => {
                      if (!e.data.errors) {
                        // console.log("send");
                        setLoading(false);
                        setAlertMessage(true);
                      } else {
                        setLoading(false);
                        // console.log("no send");
                      }
                    });
                  } else {
                    setLoading(false);
                    setOpenToast(true);
                    setMessageToast({ text: "Заполните почту", type: "warning" });
                  }
                }}
              >
                продолжить
              </Button>
            </>
          </form>
          <div className="lower-btn">
            <div
              className="lower-btn-text"
              onClick={() => {
                modalRecovery.client && dispatch(setModalRecovery({ open: false }));
                modalRecovery.client && dispatch(setModalLogin({ open: true }));
                modalRecovery.partner && dispatch(setModalRecovery({ open: false }));
                modalRecovery.partner && dispatch(setModalLoginPartner({ open: true }));
              }}
            >
              Я помню пароль
            </div>
          </div>
        </div>
      )}
      {alertMessage && (
        <div className="body-modal-login">
          <img className="image-back-login" src={background} />
          <Close
            onClick={() => {
              setRecovery(false);
              setAlertMessage(false);
              dispatch(setModalRecovery({ open: false }));
            }}
          />
          <div className="title">
            <div className="cont">
              <img src={mail} />
              Хорошо
            </div>
            <div className="title-lower">
              Если такой аккаунт есть, пришлём ссылку для смены пароля. Если не видите письма —
              проверьте папку «Спам»
            </div>
          </div>
          <Button
            className={`close-alert-message medium `}
            typebtn="outline"
            onClick={() => {
              setAlertMessage(false);
              dispatch(setModalRecovery({ open: false }));
            }}
          >
            закрыть
          </Button>
        </div>
      )}
      {recovery && (
        <div className="body-modal-login">
          <img className="image-back-login" src={background} />
          <Close
            onClick={() => {
              setRecovery(false);
              setAlertMessage(false);
              dispatch(setModalRecovery({ open: false }));
            }}
          />
          <div className="title">
            <div className="cont">
              <img src={password} />
              Восстановление доступа
            </div>
            <div className="title-lower">Придумайте новый пароль для своей учётной записи</div>
          </div>
          <form
            className="form-login"
            onSubmit={async (e) => {
              e.preventDefault();
              let info = {};
              let inputs = e.target.querySelectorAll("input");
              inputs.forEach((input) => (info[input.name] = input.value));

              if (
                urlSearch?.token_recovery &&
                urlSearch?.login &&
                info.password === info.password_confirmation
              ) {
                setLoading(true);
                let forSend = {
                  token: urlSearch?.token_recovery,
                  login: urlSearch?.login,
                  // email: urlSearch?.login,
                  password: info.password,
                  password_confirmation: info.password_confirmation,
                };
                await postResetPassword(forSend)
                  .then((e) => {
                    if (!e.data.errors) {
                      setRecovery(false);
                      setAlertMessage(false);
                      dispatch(setModalRecovery({ open: false }));
                      setOpenToast(true);
                      setMessageToast({ text: "Пароль успешно изменён", type: "success" });
                      history.push("/");
                    } else {
                      setOpenToast(true);
                      setMessageToast({
                        text:
                          typeof e.data?.errors === "string"
                            ? e.data?.errors
                            : "Введите новый пароль",
                        type: "warning",
                      });
                    }
                    setLoading(false);
                  })
                  .catch((e) => {
                    setLoading(false);
                    setOpenToast(true);
                    setMessageToast({ text: "Ошибка восстановления", type: "error" });
                  });
              }
            }}
          >
            <>
              <Input
                name="password"
                className="password medium"
                placeholder="новый пароль"
                type="password"
                classNameIcon="icon-outline cursor"
                toggle={false}
              />
              <Input
                name="password_confirmation"
                noImage
                className="password medium"
                placeholder="пароль ещё раз"
                type="password"
              />
              <Button
                loading={loading}
                disabled={loading}
                className={`btn-login ${recovery ? "recovery-btn" : ""} medium`}
                type="submit"
                typebtn="bright"
                onClick={async () => {
                  // setRecovery(false);
                  // setAlertMessage(false);
                  // dispatch(setModalRecovery({ open: false }));
                }}
              >
                сохранить
              </Button>
            </>
          </form>
        </div>
      )}
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </IonModal>
  );
};

export default AccessRecoveryModal;
