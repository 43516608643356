import "../../less/css/firstpage.css";
import Button from "../../ui/Button";
import { setModalLogin } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { IonMenuToggle } from "@ionic/react";
import logo from "../../image/logo-no-cut.svg";
import { useHistory } from "react-router";
import Icons from "../../ui/Icons";
import { getInitialDataAdmin } from "../../utils/api";

const HeaderPagesGuest = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);

  return (
    <div className="header-pages-guest">
      <div className="cont-header-btn">
        <div className="left">
          <IonMenuToggle className="btn-menu-left">
            <Button typebtn="ghost" className="small cube">
              <Icons icon="menu" />
            </Button>
          </IonMenuToggle>
          <img
            src={logo}
            onClick={() => {
              history.push("/");
              // window.location.reload();
            }}
          ></img>
        </div>
        <div className="right">
          <Button
            className="favourite medium"
            typebtn="outline"
            onClick={() => history.push("/favorite")}
          >
            <Icons icon="heart" className="icon-elevated" />
          </Button>
          {token ? (
            <Button
              className="favourite medium cube"
              typebtn="outline"
              onClick={() => {
                getInitialDataAdmin(token)
                  .then((e) => {
                    if (!e.data.errors) {
                      if (e.data.user.operator_user) {
                        history.push("/partner/summary");
                      }
                    }
                  })
                  .catch((e) => {
                    if (token) {
                      history.push("/client");
                    }
                  });
              }}
            >
              <Icons icon="user" />
            </Button>
          ) : (
            <Button
              className="btn-login btn-login-header medium"
              typebtn="outline"
              onClick={() => dispatch(setModalLogin({ open: true }))}
            >
              <div className="text">войти</div>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderPagesGuest;
