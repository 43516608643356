import { IonModal, IonReorder, IonReorderGroup } from "@ionic/react";
import Close from "../Close";
import "../../less/css/custom.css";
import Toggle from "../Toggle";
import Button from "../Button";
import Icons from "../Icons";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setColumnTable } from "../../store/actions";

const ModalChangeTable = (props) => {
  const dispatch = useDispatch();
  const columnsTable = useSelector((state) => state.columnsTable);
  const [items, setItems] = useState();

  useEffect(() => {
    setItems(JSON.parse(localStorage.getItem("column"))[props.numberColumns]);
  }, [props.open]);

  const location = useLocation();

  const onChangeColumn = async () => {
    let arrLocal = Object.values(columnsTable);
    arrLocal?.splice(props.numberColumns, 1, items);
    await dispatch(setColumnTable(arrLocal));
    props.onClose(false);
  };

  return (
    <IonModal
      className={`modal-change-table ${props.className ? props.className : ""}`}
      isOpen={props.open}
      onWillDismiss={() => {
        props.onClose(false);
        setItems(JSON.parse(localStorage.getItem("column"))[props.numberColumns]);
      }}
    >
      <div className="body-modal">
        <Close
          onClick={() => {
            props.onClose(false);
            setItems(JSON.parse(localStorage.getItem("column"))[props.numberColumns]);
          }}
        />
        <div className="title">Настроить колонки</div>
        <div className="menu">
          <IonReorderGroup
            disabled={false}
            onIonItemReorder={(event) => setItems(event.detail.complete(items))}
          >
            {items?.map((el, i) => {
              return (
                <div key={i} className="line">
                  <Toggle
                    disabled={el.disabled}
                    checked={el.default}
                    text={el.name}
                    description={el.description}
                    onChange={(e) => {
                      let element = el;
                      element.default = e.target.checked;
                      let arr = items;
                      arr.splice(i, 1, element);
                      setItems(arr);
                    }}
                  />
                  <IonReorder className="reorder-sett" slot="end">
                    <div className="cont-arrow">
                      <Icons icon="design-personal-arrows-down-up" />
                    </div>
                  </IonReorder>
                </div>
              );
            })}
          </IonReorderGroup>
        </div>
        <div className="cont-btn">
          <Button
            typebtn="outline"
            className="small btn-cancel"
            onClick={() => {
              setItems(JSON.parse(localStorage.getItem("column"))[props.numberColumns]);
              props.onClose(false);
            }}
          >
            Отмена
          </Button>
          <Button
            typebtn="bright"
            className="small btn-save"
            onClick={() => {
              onChangeColumn();
            }}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalChangeTable;
