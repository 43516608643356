import { Route } from "react-router";
import InformationContent from "../components/InformationContent";
import { IonPage, IonRouterOutlet } from "@ionic/react";
import AboutUsContent from "../components/AboutUsContent";
import ForPartnersContent from "../components/ForPartnersContent";
import HeaderPagesGuest from "../components/firstpage/HeaderPagesGuest";
import { getProductsClient, getProductsGuest, getSuitableListClient } from "../utils/api";
import { setProductsClients, setSuitable } from "../store/actions";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import { useDispatch, useSelector } from "react-redux";
import HeaderPageClient from "../components/firstpage/HeaderPageClient";
import ShowTourClient from "../components/firstpage/ShowTourClient";
import ClientCheckout from "../components/client/ClientCheckout";
import ClientReview from "../components/client/ClientReview";
import ClientFavorite from "../components/client/ClientFavorite";

const InformationPage = () => {
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  useAsyncEffect(async () => {
    if (token) {
      getProductsClient(token).then((e) => {
        dispatch(setProductsClients(e.data.data));
      });
    } else {
      getProductsGuest().then((e) => {
        dispatch(setProductsClients(e.data.data));
      });
    }
    getSuitableListClient().then((e) => {
      dispatch(setSuitable(e.data.data));
    });
  }, []);
  return (
    <IonPage id="main-content">
      {token ? <HeaderPageClient /> : <HeaderPagesGuest />}
      <IonRouterOutlet>
        <Route path="/about" component={AboutUsContent} />
        <Route path="/partners" component={ForPartnersContent} />
        <Route path="/information" component={InformationContent} />
        <Route exact path="/tour" component={ShowTourClient} />
        <Route path="/tour/review" component={ClientReview} />
        <Route path="/checkout" component={ClientCheckout} />
        <Route path="/favorite" component={ClientFavorite} />
      </IonRouterOutlet>
    </IonPage>

    // {createOpen.open ? <ToursCreateContent /> : <ToursListContent />} // create
  );
};

export default InformationPage;
