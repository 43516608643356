import { useState } from "react";
import noImage from "../../image/no-image.svg";
import noImageS from "../../image/no-image-s.svg";
import Icons from "../../ui/Icons";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { URL_ORIGIN } from "../../utils/config";

const Rating = () => {
  const [expand, setExpand] = useState(true);
  const history = useHistory();
  const products = useSelector((state) => state.products);
  products?.forEach((el) => {
    el.orders_value = el.orders.filter((q) => q.status_id !== 1).length;
  });
  products?.sort(function (a, b) {
    return b.orders_value - a.orders_value;
  });
  const top = products?.slice(0, 10);

  const array = [
    { image: "img", name: "Тур в Дагестан", orders: 125 },
    { image: "img", name: "Дальний Восток на 3 дня", orders: 121 },
    { image: "img", name: "Тур по Екатеринбургу", orders: 108 },
    { image: "img", name: "Золотое Кольцо России", orders: 72 },
    { image: "img", name: "Серебряное Кольцо России", orders: 68 },
    { image: "img", name: "Тур в Дагестан", orders: 125 },
    { image: "img", name: "Дальний Восток на 3 дня", orders: 121 },
    { image: "img", name: "Тур по Екатеринбургу", orders: 108 },
    { image: "img", name: "Золотое Кольцо России", orders: 72 },
    { image: "img", name: "Серебряное Кольцо России", orders: 68 },
  ];
  return (
    <div className="rating">
      <div className="title">Топ-10 туров</div>
      <div className={`top-tablet ${expand ? "hide" : ""}`}>
        {top?.map((el, i) => {
          return (
            <div
              className="row-tablet"
              key={i}
              onClick={() => {
                history.push(`/partner/showcase/tours/details?id=${el.id}`);
              }}
            >
              <div className="cont">
                <div className="el-photo-img">
                  <img src={el?.photo ? `${URL_ORIGIN}${el?.photo}` : noImageS} />
                </div>
                <div className="el-name-text">{el?.name}</div>
              </div>
              <div className="el-order-text">{`${el?.orders_value} заказов`}</div>
            </div>
          );
        })}
        {expand && <div className="shadow"></div>}
      </div>
      {expand ? (
        <div
          className="expand"
          onClick={() => {
            setExpand(false);
          }}
        >
          <div className="btn-expand">
            <div className="text">развернуть</div>
            <Icons icon="chevron-down" className="icon-outline btn-expand-icon" />
          </div>
        </div>
      ) : (
        <div
          className="expand"
          onClick={() => {
            setExpand(true);
          }}
        >
          <div className="btn-expand">
            <div className="text">свернуть</div>
            <Icons icon="chevron-down" className="icon-outline btn-expand-icon reverse" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Rating;
