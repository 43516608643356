import { IonToast } from "@ionic/react";
import Icons from "./Icons";
// import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import starGray from "../image/icon-toast/star-gray.svg";
import starRed from "../image/icon-toast/star-red.svg";
import starGreen from "../image/icon-toast/star-green.svg";
import infoBlue from "../image/icon-toast/info-blue.svg";
import success from "../image/icon-toast/success.svg";
import warning from "../image/icon-toast/warning.svg";
import error from "../image/icon-toast/error.svg";

const Toast = (props) => {
  return (
    <IonToast
      position="top"
      className={`toast ${props.className} ${props.type}`}
      isOpen={props.open}
      message={props.message}
      onDidDismiss={() => props.onClose(false)}
      duration={3000}
      icon={
        props.type === "neutral"
          ? starGray
          : props.type === "primary"
          ? starRed
          : props.type === "secondary"
          ? starGreen
          : props.type === "info"
          ? infoBlue
          : props.type === "success"
          ? success
          : props.type === "warning"
          ? warning
          : props.type === "error"
          ? error
          : starGray
      }
    />
  );
};

export default Toast;
