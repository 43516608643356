import { useState } from "react";
import { setFormCreateOrderClient } from "../../store/actions";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import Toggle from "../../ui/Toggle";
import qs from "qs";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import mapcheck from "../../image/map-check.svg";
import errors from "../../image/icons/exclamation-circle.svg";
import { IonContent, IonPopover } from "@ionic/react";
import Footer from "../../ui/Footer";
import { Skeleton } from "primereact/skeleton";
import { postOrderResuls, postOrderStatus } from "../../utils/api";
import Toast from "../../ui/Toast";
import { URL_ORIGIN } from "../../utils/config";
import { ProgressSpinner } from "primereact/progressspinner";

const ClientCheckoutResult = () => {
  const history = useHistory();
  const token = useSelector((state) => state.token);
  const urlSearch = qs.parse(history.location.search.substr(1));
  const [qr, setQr] = useState(false);
  const [item, setItem] = useState();
  const [openPopover, setOpenPopover] = useState(false);
  const [element, setElement] = useState();
  const [race, setRace] = useState();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);
  const [result, setResult] = useState();
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  const menu = [
    {
      name: "Скопировать ссылку",
      id: 1,
      icon: "copy",
    },
    {
      name: "Вконтакте",
      id: 2,
      icon: "brand-vk",
      href: `https://vk.com/share.php?url=${URL_ORIGIN}tour?id=${urlSearch?.id}"`,
    },
    {
      name: "Одноклассники",
      id: 3,
      icon: "brand-ok-ru",
      href: `https://connect.ok.ru/offer?url=${URL_ORIGIN}tour?id=${urlSearch?.id}&description=тур: "${element?.name}"`,
    },
    {
      name: "Telegram",
      id: 4,
      icon: "brand-telegram",
      href: `https://t.me/share/url?url=${URL_ORIGIN}tour?id=${urlSearch?.id}&text=Сервис автопутешествий Смородина, тур: "${element?.name}"`,
    },
    {
      name: "X (Twitter)",
      id: 5,
      icon: "brand-x",
      href: `https://twitter.com/share?url=${URL_ORIGIN}tour?id=${urlSearch?.id}&text=тур: "${element?.name}"`,
    },
  ];

  useAsyncEffect(() => {
    postOrderResuls(token, urlSearch?.order_id, {
      debugmode: 0,
      pay_reference: urlSearch?.pay_reference,
    });
    let timerId = setInterval(() => {
      postOrderStatus(token, urlSearch?.order_id, {
        debugmode: 0,
        pay_reference: urlSearch?.pay_reference,
      }).then((e) => {
        setResult(e.data.result);
        if (
          e.data.result.paymentStatus === "COMPLETE" ||
          e.data.result.paymentStatus === "PAYMENT_AUTHORIZED"
        ) {
          clearInterval(timerId);
        }
      });
    }, 5000);
  }, []);

  useAsyncEffect(() => {
    let a = orders?.find((el) => el.id === +urlSearch?.order_id);
    if (a) {
      setRace(a?.race);
      setElement(a?.product);
    }
  });

  const filtDet = element?.details;
  const details = filtDet?.sort(function (a, b) {
    return a.order - b.order;
  });

  let nmapi = navitel.createWebMapApi("059ed2d4fdc4bd5a0ffc56eb88d3154ec8cc52a9", {
    locale: navitel.LocaleCode.ru_RU,
  });
  let coor = details?.[0]?.info && JSON.parse(details?.[0]?.info);
  useAsyncEffect(async () => {
    let ite = await nmapi.search.findNearestObject({
      coordinates: {
        latitude: coor?.points[0].lat,
        longitude: coor?.points[0].lon,
      },
      locale: navitel.LocaleCode.ru_RU, // optional
    });
    setItem(ite);
  }, [details]);
  return (
    <IonContent className="page-guest">
      <div className="res-body">
        {result?.paymentStatus === "WAITING_PAYMENT" ||
        result?.paymentStatus === "IN_PROGRESS" ||
        !result ? (
          <div className="result-const load">
            <div className="title">
              <div className="text-title">Обработка транзакции</div>
            </div>
            <ProgressSpinner className="loading-btn" style={{ width: "48px", height: "48px" }} />
          </div>
        ) : result?.paymentStatus === "COMPLETE" ||
          result?.paymentStatus === "PAYMENT_AUTHORIZED" ? (
          <div className="result-const">
            <div className="title">
              <img src={mapcheck} />
              <div className="text-title">Тур успешно оплачен</div>
            </div>
            <div className="info">
              <div className="cont-text-info">
                <div className="text-info">Мы отправим СМС с кодом за час до старта.</div>
                <div className="text-info">
                  По прибытии на точку сбора, назовите код нашему сотруднику.
                </div>
              </div>
            </div>
            <div className="box-cont">
              {element ? (
                <div className="time">{`${new Date(race?.start_date).toLocaleDateString("ru", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}${details?.[0]?.collect_time ? `, ${details?.[0]?.collect_time}` : ""}`}</div>
              ) : (
                <Skeleton width="300px" height="20px" className="mb-2"></Skeleton>
              )}
              {element ? (
                <div className="place">{`Место сбора: ${
                  item?.address?.region !== item?.address?.city ? item?.address?.region : ""
                } ${item?.address?.city} `}</div>
              ) : (
                <Skeleton width="300px" height="20px" className="mb-2"></Skeleton>
              )}
              <div className="btns">
                <Button
                  typebtn="outline"
                  className="medium"
                  id="bottom-start-s"
                  onClick={() => setOpenPopover(true)}
                >
                  <Icons icon="share" />
                  Поделиться
                </Button>
                <Button typebtn="bright" className="medium">
                  Скачать PDF
                </Button>
              </div>
            </div>
            <div className="btn-return">
              <Button
                typebtn="ghost"
                className="medium"
                onClick={() => {
                  dispatch(setFormCreateOrderClient({}));
                  history.push("/");
                }}
              >
                <Icons icon="arrow-back-up" /> Вернуться к покупкам
              </Button>
            </div>
          </div>
        ) : (
          <div className="result-const">
            <div className="title">
              <img src={errors} />
              <div className="text-title err">Ошибка при оформлении</div>
            </div>
            <div className="info-errors">
              <div className="text-info">Пожалуйста, попробуйте оформить тур ещё раз.</div>
              <Button
                className="medium"
                typebtn="muted"
                onClick={() => history.push(`/?id=${urlSearch?.id}`)}
              >
                <Icons icon="arrow-narrow-left" />
                На страницу тура
              </Button>
              <span className="text-lower">
                Если ошибка сохранится, напишите нам на{" "}
                <span className="link">support@smorodina.ru</span>, мы обязательно разберёмся в этой
                ситуации.
              </span>
            </div>
          </div>
        )}
        <Footer />
        <IonPopover
          class="popover-menu-el-list"
          isOpen={openPopover}
          onWillDismiss={() => setOpenPopover(false)}
          trigger={openPopover !== false ? `bottom-start-s` : undefined}
          side="bottom"
          alignment="start"
        >
          <div className="body-popover">
            {menu.map((el, i) => {
              return (
                <div
                  key={i}
                  className="cont-el-pop"
                  href={el.href}
                  onClick={() => {
                    if (el.href) {
                      window.open(el.href);
                      setOpenPopover(false);
                    } else {
                      navigator.clipboard
                        .writeText(`${URL_ORIGIN}tour?id=${urlSearch.id}`)
                        .then(function () {
                          setOpenToast(true);
                          setMessageToast({
                            text: "Ссылка успешно скопирована в буфер обмена",
                            type: "success",
                          });
                          setOpenPopover(false);
                        });
                    }
                  }}
                >
                  <Icons icon={el?.icon} />
                  <div className="text">{el?.name}</div>
                </div>
              );
            })}
          </div>
        </IonPopover>
        <Toast
          open={openToast}
          onClose={setOpenToast}
          message={messageToast.text}
          type={messageToast.type}
        />
      </div>
    </IonContent>
  );
};

export default ClientCheckoutResult;
