import { IonModal } from "@ionic/react";
import Select from "../Select";
import Input from "../Input";
import Button from "../Button";
import Close from "../Close";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import qs from "qs";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";

const ModalFilterFAQ = (props) => {
  const history = useHistory();
  const urlSearch = qs.parse(history.location.search.substr(1));

  // const orders = useSelector((state) => state.orders);
  // orders?.forEach((el) => {
  //   el.name = `${el?.client?.user?.first_name} ${el?.client?.user?.last_name}`;
  // });

  // const res = orders?.reduce((o, i) => {
  //   if (!o.find((v) => v?.user_id == i?.user_id)) {
  //     o.push(i);
  //   }
  //   return o;
  // }, []);

  const DEFAULT = {
    tours_from: "",
    tours_until: "",
  };
  const [filterElements, setFilterElements] = useState(DEFAULT);

  const removeEmpty = (obj) =>
    Object.entries(obj)
      .filter(([, v]) => v != null && v !== "")
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

  const func = (obj1, obj2) => {
    let result = {};
    for (let prop in obj1) {
      if (
        obj2.hasOwnProperty(prop) ||
        prop === "page" ||
        prop === "date_from" ||
        prop === "date_until" ||
        prop === "question"
      )
        result[prop] = obj1[prop];
    }
    return result;
  };

  const onApply = () => {
    let data = urlSearch;
    let array = filterElements;
    let filter = removeEmpty(array);
    data = func(data, filter);
    let merge = Object.assign(data, filter);
    merge.page = 1;

    const params = new URLSearchParams(merge);
    history.push(`?${params.toString()}`);
    props.onClose(false);
  };
  useEffect(() => {
    setFilterElements(urlSearch);
  }, [props.open]);

  return (
    <IonModal
      className="filter-table-reviews"
      isOpen={props.open}
      onWillDismiss={() => {
        props.onClose(false);
        setFilterElements(DEFAULT);
      }}
    >
      <div className="body-modal">
        <Close
          onClick={() => {
            props.onClose(false);
            setFilterElements(DEFAULT);
          }}
        />
        <div className="title">Фильтры</div>
        <div className="body">
          <div className="line">
            <Input
              value={urlSearch?.tours_from ? filterElements?.tours_from : ""}
              name="tours_from"
              className="small input-short"
              placeholder="в турах от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, tours_from: e });
              }}
            />
            <Input
              value={urlSearch?.tours_until ? filterElements?.tours_until : ""}
              name="tours_until"
              className="small input-short"
              placeholder="в турах до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, tours_until: e });
              }}
            />
          </div>
        </div>
        <div className="buttons">
          <Button
            className="small"
            typebtn="outline"
            onClick={async () => {
              // props.onClose(false)
              setFilterElements(DEFAULT);
              let data = urlSearch;
              let newd = {};
              newd.page = 1;
              if (data?.question) newd.question = data?.question;
              if (data?.date_from) newd.date_from = data?.date_from;
              if (data?.date_until) newd.date_until = data?.date_until;
              const params = new URLSearchParams(newd);
              history.push(`?${params.toString()}`);
            }}
          >
            Сбросить
          </Button>
          <Button
            className="small"
            typebtn="bright"
            onClick={() => {
              onApply();
            }}
          >
            Применить
          </Button>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalFilterFAQ;
