import Button from "../../ui/Button";
import "../../less/css/profile.css";
import ProfileInf from "./ProfileInf";
import NotificationSettings from "./NotificationSettings";
import { IonContent } from "@ionic/react";
import { postLogout } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { setToken } from "../../store/actions";
import { useState } from "react";

const ProfileContent = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  return (
    <IonContent className="page">
      <div className="profile">
        <div className="upper-line">
          <div className="title">Мой профиль</div>
        </div>
        <div className="lower-line">
          <ProfileInf setLoading={setLoading} loading={loading} />
          <NotificationSettings />
        </div>
      </div>
    </IonContent>
  );
};

export default ProfileContent;
