import {
  ARRAY_CITY,
  DEFAULT_FAQ,
  DEFAULT_OPERATOR_USERS,
  DEFAULT_OPTIONS,
  DEFAULT_POINT_TOURS,
  DEFAULT_PROMOTIONS,
  DEFAULT_TOURS,
  FIELD_FOR_FAQ,
  FIELD_FOR_OPERATIONS_HISTORY,
  FIELD_FOR_OPERATOR_USERS,
  FIELD_FOR_OPTIONS,
  FIELD_FOR_ORDERS,
  FIELD_FOR_QUESTIONS,
  FIELD_FOR_REVIEWS,
  FIELD_FOR_TOURS,
  FIELD_FOR_TOURS_RACES,
} from "../utils/array";

function parseLCS(name) {
  return JSON.parse(localStorage.getItem(name));
}

const initialState = {
  token: localStorage.getItem("token") ?? "",
  userInfo: {
    clientInfo: parseLCS("userInfo")?.documentInfo ?? "",
  },
  ElMenu: [],
  profile: [],
  operator: [],
  options: [],
  optionsDirectory: [],
  promotions: [],
  promotionsDirectory: [],
  orders: [],
  operations: [],
  reviews: [],
  faq: [],
  modalLogin: { open: false },
  modalLoginPartner: { open: false },
  modalRecovery: { open: false },
  modalGreetings: false,
  createTour: { stage: 1 },
  products: [],
  productsClients: [],
  user: [],
  operator_users: [],
  genre: [],
  classes: [],
  format: [],
  suitable: [],
  typePoint: [],
  formForCreateTour: DEFAULT_TOURS,
  formForCreateOption: DEFAULT_OPTIONS,
  tourOptions: false,
  tourFocus: false,
  formPointTour: DEFAULT_POINT_TOURS,
  formForCreatePromotion: DEFAULT_PROMOTIONS,
  timeline: null,
  arrayMapCity: ARRAY_CITY,
  valCity: ARRAY_CITY[0],
  arrayPointsTour: [],
  arrayTourRaces: [],
  formCreateRaces: {},
  formReviews: {},
  formQuestion: {},
  formFAQ: DEFAULT_FAQ,
  formOperatorUsers: DEFAULT_OPERATOR_USERS,
  formLogin: {},
  formRegister: {},
  myCoor: null,
  userPermissions: {},
  openQuickView: { open: false, el: null },
  formCreateOrderClient: {},
  openModalCreateQuestion: false,
  openModalCreateReport: false,

  openModalCancelOrders: { open: false, el: null },
  filterFirstPage: [],
  columnsTable: [
    FIELD_FOR_TOURS_RACES,
    FIELD_FOR_TOURS,
    FIELD_FOR_OPERATOR_USERS,
    FIELD_FOR_OPTIONS,
    FIELD_FOR_ORDERS,
    FIELD_FOR_OPERATIONS_HISTORY,
    FIELD_FOR_REVIEWS,
    FIELD_FOR_QUESTIONS,
    FIELD_FOR_FAQ,
  ],
  openModalWithdraw: false,
  clientTour: {},
};

export default initialState;
