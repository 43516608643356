import Icons from "./Icons";

const Close = (props) => {
  return (
    <div className="cont-close-btn" onClick={props.onClick}>
      <Icons icon="close20px" className="icon-outline" />
    </div>
  );
};

export default Close;
