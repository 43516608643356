import backgroundTop from "../image/marketing-page-background-road.svg";
import img1 from "../image/about1.png";
import img2 from "../image/about2.png";
import img3 from "../image/about3.png";

import eye from "../image/icons/eye-check.svg";
import graph from "../image/icons/graph.svg";
import heart from "../image/icons/heart-handshake.svg";
import Footer from "../ui/Footer";
import { IonContent } from "@ionic/react";

const AboutUsContent = () => {
  return (
    <IonContent className="page-guest">
      <div className="about-content">
        <img src={backgroundTop} className="back-image" />
        <div className="top-hat">
          <div className="content">
            <div className="center-content">
              <div className="text">
                <div className="cont-text">
                  <span className="text-up">Смородина</span>
                  <div className="large-text">
                    #смотри
                    <span className="large-text">
                      <span className="colortext">родину</span>
                    </span>
                  </div>
                </div>
                <div className="small-text">
                  Наша страна прекрасна, поэтому мы стремимся показать её природные и архитектурные
                  памятники, которые заставят вас влюбиться в Россию. Для этого мы стремимся
                  развивать внутренний туризм, чтобы сделать автопутешествия по России комфортными и
                  увлекательными.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="items">
          <div className="item">
            <img src={img1} />
            <div className="cont-text">
              <div className="bold">Сильный маркетинг</div>
              <div className="low">
                40% бюджета проекта составляют маркетинговые активности, нацеленные на увелчение
                продаж наших партнёров.
              </div>
            </div>
          </div>
          <div className="item">
            <img src={img2} />
            <div className="cont-text">
              <div className="bold">Своя IT-платформа</div>
              <div className="low">
                Развиваем собственную платформу с учётом интересов парнёров-туроператоров.
              </div>
            </div>
          </div>
          <div className="item">
            <img src={img3} />
            <div className="cont-text">
              <div className="bold">Сервис и поддержка</div>
              <div className="low">Оказываем техническую поддержку собственными силами.</div>
            </div>
          </div>
        </div>
        <div className="values">
          <div className="title">Ценности</div>
          <div className="items-values">
            <div className="item-values">
              <div className="up">
                <img src={graph} />
                <div className="text-up">Развитие</div>
              </div>
              <div className="info">
                Изучаем потребности наших партнёров и их клиентов, чтобы превосходить их ожидания.
                Постоянно заглядываем в будущее и тестируем новые продукты ещё до того, как они
                станут востребованы. Всё это возможно благодаря нашей команде высококлассных
                специалистов и постоянному интересу к передовым технологиям.
              </div>
            </div>
            <div className="item-values">
              <div className="up">
                <img src={heart} />
                <div className="text-up">Поддержка</div>
              </div>
              <div className="info">
                Помогаем малому и среднему бизнесу в сфере туризма, предоставляя платформу для
                роста. Помогаем найти уникальные туры. Укрепляем отношения между турагентствами и
                путешественниками, что стимулирует развитие российской экономики и открывает новые
                возможности для внутреннего туризма.
              </div>
            </div>
            <div className="item-values">
              <div className="up">
                <img src={eye} />
                <div className="text-up">Удобство</div>
              </div>
              <div className="info">
                Стремимся обеспечить удобство онлайн-бронирования и широкий выбор автотуров на всей
                территории России, чтобы люди узнали, как она прекрасна. Нашим туроператорам мы
                предоставляем доступ к современным сервисам и выстраиваем долгосрочные партнёрские
                отношения.
              </div>
            </div>
          </div>
        </div>
        <div className="mission">
          <div className="gray">
            <div className="info">
              <div className="up">Наша миссия</div>
              <div className="middle">
                Стремимся открыть новые возможности для внутреннего туризма и показать как прекрасна
                наша страна, её природные и архитектурные памятники.
              </div>
              <div className="bottom">
                Для этого мы развиваем собственную ИТ-платформу автопутешествий и партнёрскую сеть
                туроператоров, которые обеспечивают рост малого и среднего бизнеса в сфере туризма и
                стимулируют развитие российской экономики.
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </IonContent>
  );
};

export default AboutUsContent;
