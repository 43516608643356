const Icons = (props) => {
  return props.icon === "alert-circle-warn" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 6.66667V10M10 13.3333H10.0083M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10Z"
        stroke="#FE8034"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "alert-circle" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 6.66667V10M10 13.3333H10.0083M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10Z"
        stroke="#FE8034"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "arrow-narrow-right" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.1665 10.0003H15.8332M15.8332 10.0003L12.4998 13.3337M15.8332 10.0003L12.4998 6.66699"
        stroke="#606B6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "arrow-right" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.1665 10H15.8332M15.8332 10L10.8332 15M15.8332 10L10.8332 5"
        stroke="#A8B2B1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "bell" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49967 14.1667V15C7.49967 15.663 7.76307 16.2989 8.23191 16.7678C8.70075 17.2366 9.33663 17.5 9.99967 17.5C10.6627 17.5 11.2986 17.2366 11.7674 16.7678C12.2363 16.2989 12.4997 15.663 12.4997 15V14.1667M8.33301 4.16667C8.33301 3.72464 8.5086 3.30072 8.82116 2.98816C9.13372 2.67559 9.55765 2.5 9.99967 2.5C10.4417 2.5 10.8656 2.67559 11.1782 2.98816C11.4907 3.30072 11.6663 3.72464 11.6663 4.16667C12.6233 4.61919 13.4392 5.32361 14.0264 6.20442C14.6136 7.08523 14.95 8.10923 14.9997 9.16667V11.6667C15.0624 12.1848 15.2459 12.6809 15.5354 13.1151C15.8248 13.5493 16.2122 13.9095 16.6663 14.1667H3.33301C3.78712 13.9095 4.17452 13.5493 4.464 13.1151C4.75348 12.6809 4.93696 12.1848 4.99967 11.6667V9.16667C5.04931 8.10923 5.38576 7.08523 5.97297 6.20442C6.56017 5.32361 7.376 4.61919 8.33301 4.16667Z"
        stroke="#606B6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "building-skyscraper" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 17.5H17.5M4.16667 17.5V5.83333L10.8333 2.5V17.5M15.8333 17.5V9.16667L10.8333 5.83333M7.5 7.5V7.50833M7.5 10V10.0083M7.5 12.5V12.5083M7.5 15V15.0083"
        stroke="#A8B2B1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "calendar" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="calendar">
        <path
          id="Vector"
          d="M13.3333 2.5V5.83333M6.66659 2.5V5.83333M3.33325 9.16667H16.6666M9.16659 12.5H9.99992V15M3.33325 5.83333C3.33325 5.39131 3.50885 4.96738 3.82141 4.65482C4.13397 4.34226 4.55789 4.16667 4.99992 4.16667H14.9999C15.4419 4.16667 15.8659 4.34226 16.1784 4.65482C16.491 4.96738 16.6666 5.39131 16.6666 5.83333V15.8333C16.6666 16.2754 16.491 16.6993 16.1784 17.0118C15.8659 17.3244 15.4419 17.5 14.9999 17.5H4.99992C4.55789 17.5 4.13397 17.3244 3.82141 17.0118C3.50885 16.6993 3.33325 16.2754 3.33325 15.8333V5.83333Z"
          stroke="#606B6C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ) : props.icon === "chevron-down" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 7.5L10 12.5L15 7.5"
        stroke="#606B6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "circle-check" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 10L9.16667 11.6667L12.5 8.33333M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10Z"
        stroke="#2FBC2B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "circle-x" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33333 8.33333L11.6667 11.6667M11.6667 8.33333L8.33333 11.6667M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10Z"
        stroke="#FF6F64"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "close" ? (
    <svg
      className={props.className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="x">
        <path
          id="Vector"
          d="M12 4L4 12M4 4L12 12"
          stroke="#606B6C
"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ) : props.icon === "close20px" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 5L5 15M5 5L15 15"
        stroke="#606B6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "desgn-public-arrow-narrow-right" ? (
    <svg
      className={props.className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrow-narrow-right">
        <path
          id="Vector"
          d="M5 12H19M19 12L15 16M19 12L15 8"
          stroke="#222222"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ) : props.icon === "design-personal-archive" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="archive">
        <path
          id="Vector"
          d="M15.8333 6.66634C16.2754 6.66634 16.6993 6.49075 17.0118 6.17819C17.3244 5.86563 17.5 5.4417 17.5 4.99967C17.5 4.55765 17.3244 4.13372 17.0118 3.82116C16.6993 3.5086 16.2754 3.33301 15.8333 3.33301H4.16667C3.72464 3.33301 3.30072 3.5086 2.98816 3.82116C2.67559 4.13372 2.5 4.55765 2.5 4.99967C2.5 5.4417 2.67559 5.86563 2.98816 6.17819C3.30072 6.49075 3.72464 6.66634 4.16667 6.66634M15.8333 6.66634H4.16667M15.8333 6.66634V14.9997C15.8333 15.4417 15.6577 15.8656 15.3452 16.1782C15.0326 16.4907 14.6087 16.6663 14.1667 16.6663H5.83333C5.39131 16.6663 4.96738 16.4907 4.65482 16.1782C4.34226 15.8656 4.16667 15.4417 4.16667 14.9997V6.66634M8.33333 9.99967H11.6667"
          stroke="#A8B2B1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ) : props.icon === "design-personal-arrow-forward-up-double" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrow-forward-up-double">
        <path
          id="Vector"
          d="M9.16683 11.6667L12.5002 8.33333M12.5002 8.33333L9.16683 5M12.5002 8.33333H6.66683C5.78277 8.33333 4.93493 8.68452 4.30981 9.30964C3.68469 9.93477 3.3335 10.7826 3.3335 11.6667C3.3335 12.5507 3.68469 13.3986 4.30981 14.0237C4.93493 14.6488 5.78277 15 6.66683 15H7.50016M13.3335 11.6667L16.6668 8.33333L13.3335 5"
          stroke="#A8B2B1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ) : props.icon === "design-personal-arrows-down-up" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrows-down-up">
        <path
          id="Vector"
          d="M14.1668 2.5V17.5M14.1668 2.5L16.6668 5M14.1668 2.5L11.6668 5M8.3335 15L5.8335 17.5M5.8335 17.5L3.3335 15M5.8335 17.5V2.5"
          stroke="#606B6C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ) : props.icon === "design-personal-caret-down-filled" ? (
    <svg
      className={props.className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="caret-down-filled">
        <path
          id="Vector"
          d="M8.99998 4.5C9.42598 4.5 9.64848 4.993 9.39148 5.3115L9.35348 5.3535L6.35348 8.3535C6.26738 8.43959 6.15283 8.4913 6.03132 8.49894C5.9098 8.50658 5.78968 8.46963 5.69348 8.395L5.64648 8.3535L2.64648 5.3535L2.60498 5.3065L2.57798 5.268L2.55098 5.22L2.54248 5.202L2.52898 5.1685L2.51298 5.1145L2.50798 5.088L2.50298 5.058L2.50098 5.0295V4.9705L2.50348 4.9415L2.50798 4.9115L2.51298 4.8855L2.52898 4.8315L2.54248 4.798L2.57748 4.732L2.60998 4.687L2.64648 4.6465L2.69348 4.605L2.73198 4.578L2.77998 4.551L2.79798 4.5425L2.83148 4.529L2.88548 4.513L2.91198 4.508L2.94198 4.503L2.97048 4.501L8.99998 4.5Z"
          fill="#606B6C"
        />
      </g>
    </svg>
  ) : props.icon === "design-personal-chevron-left" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="chevron-left">
        <path
          id="Vector"
          d="M12.5 5L7.5 10L12.5 15"
          stroke="#606B6C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ) : props.icon === "design-personal-chevron-right" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="chevron-right">
        <path
          id="Vector"
          d="M7.5 5L12.5 10L7.5 15"
          stroke="#606B6C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ) : props.icon === "design-personal-edit" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="edit">
        <path
          id="Vector"
          d="M5.8335 5.83343H5.00016C4.55814 5.83343 4.13421 6.00903 3.82165 6.32159C3.50909 6.63415 3.3335 7.05807 3.3335 7.5001V15.0001C3.3335 15.4421 3.50909 15.8661 3.82165 16.1786C4.13421 16.4912 4.55814 16.6668 5.00016 16.6668H12.5002C12.9422 16.6668 13.3661 16.4912 13.6787 16.1786C13.9912 15.8661 14.1668 15.4421 14.1668 15.0001V14.1668M13.3335 4.16677L15.8335 6.66677M16.9877 5.48759C17.3159 5.15938 17.5003 4.71424 17.5003 4.25009C17.5003 3.78594 17.3159 3.34079 16.9877 3.01259C16.6595 2.68438 16.2143 2.5 15.7502 2.5C15.286 2.5 14.8409 2.68438 14.5127 3.01259L7.50016 10.0001V12.5001H10.0002L16.9877 5.48759Z"
          stroke="#A8B2B1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ) : props.icon === "design-personal-eye" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="eye">
        <g id="Vector">
          <path
            d="M8.33333 10C8.33333 10.442 8.50893 10.866 8.82149 11.1785C9.13405 11.4911 9.55797 11.6667 10 11.6667C10.442 11.6667 10.866 11.4911 11.1785 11.1785C11.4911 10.866 11.6667 10.442 11.6667 10C11.6667 9.55797 11.4911 9.13405 11.1785 8.82149C10.866 8.50893 10.442 8.33333 10 8.33333C9.55797 8.33333 9.13405 8.50893 8.82149 8.82149C8.50893 9.13405 8.33333 9.55797 8.33333 10Z"
            stroke="#A8B2B1"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.5 10C15.5 13.3333 13 15 10 15C7 15 4.5 13.3333 2.5 10C4.5 6.66667 7 5 10 5C13 5 15.5 6.66667 17.5 10Z"
            stroke="#A8B2B1"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  ) : props.icon === "design-personal-flag-up" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="flag-up">
        <path
          id="Vector"
          d="M11.7815 12.7008C11.1101 12.5114 10.4973 12.1557 9.99984 11.6667C9.22097 10.9032 8.17381 10.4756 7.08317 10.4756C5.99253 10.4756 4.94537 10.9032 4.1665 11.6667M4.1665 11.6667V4.16666C4.94537 3.40321 5.99253 2.97559 7.08317 2.97559C8.17381 2.97559 9.22097 3.40321 9.99984 4.16666C10.7787 4.9301 11.8259 5.35773 12.9165 5.35773C14.0071 5.35773 15.0543 4.9301 15.8332 4.16666V9.99999M4.1665 11.6667V17.5M15.8332 18.3334V13.3334M15.8332 13.3334L18.3332 15.8334M15.8332 13.3334L13.3332 15.8334"
          stroke="#A8B2B1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ) : props.icon === "design-personal-note" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="note">
        <path
          id="Vector"
          d="M10.8335 16.6663L16.6668 10.833M10.8335 16.6663V11.6663C10.8335 11.4453 10.9213 11.2334 11.0776 11.0771C11.2339 10.9208 11.4458 10.833 11.6668 10.833H16.6668M10.8335 16.6663H5.00016C4.55814 16.6663 4.13421 16.4907 3.82165 16.1782C3.50909 15.8656 3.3335 15.4417 3.3335 14.9997V4.99967C3.3335 4.55765 3.50909 4.13372 3.82165 3.82116C4.13421 3.5086 4.55814 3.33301 5.00016 3.33301H15.0002C15.4422 3.33301 15.8661 3.5086 16.1787 3.82116C16.4912 4.13372 16.6668 4.55765 16.6668 4.99967V10.833"
          stroke="#A8B2B1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ) : props.icon === "design-personal-settings" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="settings">
        <g id="Vector">
          <path
            d="M8.60417 3.5975C8.95917 2.13417 11.0408 2.13417 11.3958 3.5975C11.4491 3.81733 11.5535 4.02148 11.7006 4.19333C11.8477 4.36518 12.0332 4.49988 12.2422 4.58645C12.4512 4.67303 12.6776 4.70904 12.9032 4.69156C13.1287 4.67407 13.3469 4.60359 13.54 4.48583C14.8258 3.7025 16.2983 5.17417 15.515 6.46083C15.3974 6.65388 15.327 6.87195 15.3096 7.09731C15.2922 7.32267 15.3281 7.54897 15.4146 7.75782C15.5011 7.96666 15.6356 8.15215 15.8073 8.29921C15.9789 8.44627 16.1829 8.55075 16.4025 8.60417C17.8658 8.95917 17.8658 11.0408 16.4025 11.3958C16.1827 11.4491 15.9785 11.5535 15.8067 11.7006C15.6348 11.8477 15.5001 12.0332 15.4135 12.2422C15.327 12.4512 15.291 12.6776 15.3084 12.9032C15.3259 13.1287 15.3964 13.3469 15.5142 13.54C16.2975 14.8258 14.8258 16.2983 13.5392 15.515C13.3461 15.3974 13.1281 15.327 12.9027 15.3096C12.6773 15.2922 12.451 15.3281 12.2422 15.4146C12.0333 15.5011 11.8479 15.6356 11.7008 15.8073C11.5537 15.9789 11.4492 16.1829 11.3958 16.4025C11.0408 17.8658 8.95917 17.8658 8.60417 16.4025C8.5509 16.1827 8.44648 15.9785 8.29941 15.8067C8.15233 15.6348 7.96676 15.5001 7.75779 15.4135C7.54882 15.327 7.32236 15.291 7.09685 15.3084C6.87133 15.3259 6.65313 15.3964 6.46 15.5142C5.17417 16.2975 3.70167 14.8258 4.485 13.5392C4.60258 13.3461 4.67296 13.1281 4.6904 12.9027C4.70785 12.6773 4.67187 12.451 4.58539 12.2422C4.49892 12.0333 4.36438 11.8479 4.19273 11.7008C4.02107 11.5537 3.81714 11.4492 3.5975 11.3958C2.13417 11.0408 2.13417 8.95917 3.5975 8.60417C3.81733 8.5509 4.02148 8.44648 4.19333 8.29941C4.36518 8.15233 4.49988 7.96676 4.58645 7.75779C4.67303 7.54882 4.70904 7.32236 4.69156 7.09685C4.67407 6.87133 4.60359 6.65313 4.48583 6.46C3.7025 5.17417 5.17417 3.70167 6.46083 4.485C7.29417 4.99167 8.37417 4.54333 8.60417 3.5975Z"
            stroke="#606B6C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10Z"
            stroke="#606B6C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  ) : props.icon === "design-personal-trash" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="trash">
        <path
          id="Vector"
          d="M3.3335 5.83333H16.6668M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M4.16683 5.83333L5.00016 15.8333C5.00016 16.2754 5.17576 16.6993 5.48832 17.0118C5.80088 17.3244 6.2248 17.5 6.66683 17.5H13.3335C13.7755 17.5 14.1994 17.3244 14.512 17.0118C14.8246 16.6993 15.0002 16.2754 15.0002 15.8333L15.8335 5.83333M7.50016 5.83333V3.33333C7.50016 3.11232 7.58796 2.90036 7.74424 2.74408C7.90052 2.5878 8.11248 2.5 8.3335 2.5H11.6668C11.8878 2.5 12.0998 2.5878 12.2561 2.74408C12.4124 2.90036 12.5002 3.11232 12.5002 3.33333V5.83333"
          stroke="#A8B2B1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ) : props.icon === "design-personal-x" ? (
    <svg
      className={props.className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="x">
        <path
          id="Vector"
          d="M12 4L4 12M4 4L12 12"
          stroke="#EF4966"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ) : props.icon === "discount-check" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50041 10.0004L9.16707 11.667L12.5004 8.33372M4.16705 6.00038C4.16705 5.51415 4.36021 5.04784 4.70402 4.70402C5.04784 4.36021 5.51415 4.16705 6.00038 4.16705H6.83372C7.3178 4.16677 7.78213 3.97505 8.12538 3.63372L8.70872 3.05038C8.87909 2.87905 9.08165 2.74309 9.30475 2.65031C9.52785 2.55753 9.76709 2.50977 10.0087 2.50977C10.2503 2.50977 10.4896 2.55753 10.7127 2.65031C10.9358 2.74309 11.1383 2.87905 11.3087 3.05038L11.8921 3.63372C12.2354 3.97538 12.7004 4.16705 13.1837 4.16705H14.0171C14.5033 4.16705 14.9696 4.36021 15.3134 4.70402C15.6572 5.04784 15.8504 5.51415 15.8504 6.00038V6.83372C15.8504 7.31705 16.0421 7.78205 16.3837 8.12538L16.9671 8.70872C17.1384 8.87909 17.2743 9.08165 17.3671 9.30475C17.4599 9.52785 17.5077 9.76709 17.5077 10.0087C17.5077 10.2503 17.4599 10.4896 17.3671 10.7127C17.2743 10.9358 17.1384 11.1383 16.9671 11.3087L16.3837 11.8921C16.0424 12.2353 15.8507 12.6996 15.8504 13.1837V14.0171C15.8504 14.5033 15.6572 14.9696 15.3134 15.3134C14.9696 15.6572 14.5033 15.8504 14.0171 15.8504H13.1837C12.6996 15.8507 12.2353 16.0424 11.8921 16.3837L11.3087 16.9671C11.1383 17.1384 10.9358 17.2743 10.7127 17.3671C10.4896 17.4599 10.2503 17.5077 10.0087 17.5077C9.76709 17.5077 9.52785 17.4599 9.30475 17.3671C9.08165 17.2743 8.87909 17.1384 8.70872 16.9671L8.12538 16.3837C7.78213 16.0424 7.3178 15.8507 6.83372 15.8504H6.00038C5.51415 15.8504 5.04784 15.6572 4.70402 15.3134C4.36021 14.9696 4.16705 14.5033 4.16705 14.0171V13.1837C4.16677 12.6996 3.97505 12.2353 3.63372 11.8921L3.05038 11.3087C2.87905 11.1383 2.74309 10.9358 2.65031 10.7127C2.55753 10.4896 2.50977 10.2503 2.50977 10.0087C2.50977 9.76709 2.55753 9.52785 2.65031 9.30475C2.74309 9.08165 2.87905 8.87909 3.05038 8.70872L3.63372 8.12538C3.97505 7.78213 4.16677 7.3178 4.16705 6.83372V6.00038Z"
        stroke="#00860F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "dots-vertical" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.1665 9.99967C9.1665 10.2207 9.2543 10.4326 9.41058 10.5889C9.56686 10.7452 9.77882 10.833 9.99984 10.833C10.2209 10.833 10.4328 10.7452 10.5891 10.5889C10.7454 10.4326 10.8332 10.2207 10.8332 9.99967C10.8332 9.77866 10.7454 9.5667 10.5891 9.41042C10.4328 9.25414 10.2209 9.16634 9.99984 9.16634C9.77882 9.16634 9.56686 9.25414 9.41058 9.41042C9.2543 9.5667 9.1665 9.77866 9.1665 9.99967Z"
        stroke="#606B6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.1665 15.833C9.1665 16.054 9.2543 16.266 9.41058 16.4223C9.56686 16.5785 9.77882 16.6663 9.99984 16.6663C10.2209 16.6663 10.4328 16.5785 10.5891 16.4223C10.7454 16.266 10.8332 16.054 10.8332 15.833C10.8332 15.612 10.7454 15.4 10.5891 15.2438C10.4328 15.0875 10.2209 14.9997 9.99984 14.9997C9.77882 14.9997 9.56686 15.0875 9.41058 15.2438C9.2543 15.4 9.1665 15.612 9.1665 15.833Z"
        stroke="#606B6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.1665 4.16634C9.1665 4.38735 9.2543 4.59932 9.41058 4.7556C9.56686 4.91188 9.77882 4.99967 9.99984 4.99967C10.2209 4.99967 10.4328 4.91188 10.5891 4.7556C10.7454 4.59932 10.8332 4.38735 10.8332 4.16634C10.8332 3.94533 10.7454 3.73337 10.5891 3.57709C10.4328 3.42081 10.2209 3.33301 9.99984 3.33301C9.77882 3.33301 9.56686 3.42081 9.41058 3.57709C9.2543 3.73337 9.1665 3.94533 9.1665 4.16634Z"
        stroke="#606B6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "download" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="download">
        <path
          id="Vector"
          d="M3.3335 14.1663V15.833C3.3335 16.275 3.50909 16.699 3.82165 17.0115C4.13421 17.3241 4.55814 17.4997 5.00016 17.4997H15.0002C15.4422 17.4997 15.8661 17.3241 16.1787 17.0115C16.4912 16.699 16.6668 16.275 16.6668 15.833V14.1663M5.8335 9.16634L10.0002 13.333M10.0002 13.333L14.1668 9.16634M10.0002 13.333V3.33301"
          stroke="#606B6C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ) : props.icon === "filter" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="filter">
        <path
          id="Vector"
          d="M3.3335 3.33301H16.6668V5.14301C16.6667 5.585 16.4911 6.00885 16.1785 6.32134L12.5002 9.99967V15.833L7.50016 17.4997V10.4163L3.76683 6.30967C3.48804 6.00295 3.33354 5.60334 3.3335 5.18884V3.33301Z"
          stroke="#606B6C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ) : props.icon === "heart" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2491 10.4771L9.99911 16.6671L3.74911 10.4771C3.33687 10.0759 3.01215 9.59374 2.7954 9.06092C2.57866 8.52811 2.47458 7.95618 2.48973 7.38117C2.50487 6.80615 2.63891 6.2405 2.88341 5.71984C3.1279 5.19917 3.47756 4.73477 3.91035 4.35587C4.34314 3.97698 4.8497 3.6918 5.39812 3.51829C5.94654 3.34479 6.52495 3.28671 7.09692 3.34773C7.66889 3.40874 8.22203 3.58752 8.72151 3.87281C9.22099 4.1581 9.65599 4.54373 9.99911 5.00539C10.3437 4.54708 10.7792 4.16483 11.2784 3.88256C11.7775 3.6003 12.3295 3.4241 12.8999 3.36499C13.4703 3.30588 14.0467 3.36514 14.5931 3.53905C15.1395 3.71296 15.6441 3.99779 16.0754 4.37569C16.5067 4.7536 16.8553 5.21646 17.0995 5.7353C17.3436 6.25414 17.4781 6.81779 17.4944 7.39098C17.5107 7.96417 17.4085 8.53455 17.1942 9.06643C16.98 9.59831 16.6582 10.0802 16.2491 10.4821"
        stroke="#303639"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "help" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 14.1667V14.175M10 11.2499C9.98466 10.9794 10.0576 10.7112 10.2079 10.4858C10.3582 10.2603 10.5777 10.0898 10.8333 9.99993C11.1466 9.88015 11.4277 9.6893 11.6546 9.4424C11.8816 9.19549 12.0481 8.89929 12.1411 8.57709C12.2341 8.2549 12.2511 7.91551 12.1906 7.58566C12.1302 7.2558 11.994 6.94448 11.7928 6.6762C11.5916 6.40792 11.3308 6.19 11.0311 6.03962C10.7313 5.88923 10.4008 5.81047 10.0654 5.80954C9.73009 5.80861 9.3991 5.88553 9.09854 6.03426C8.79797 6.18298 8.53603 6.39944 8.33333 6.6666M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10Z"
        stroke="#606B6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "info-circle" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 7.5H10.0083M9.16667 10H10V13.3333H10.8333M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10Z"
        stroke="#879FFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "info-square-rounded" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 7.5H10.0083M9.16667 10H10V13.3333H10.8333M10 2.5C16 2.5 17.5 4 17.5 10C17.5 16 16 17.5 10 17.5C4 17.5 2.5 16 2.5 10C2.5 4 4 2.5 10 2.5Z"
        stroke="#A8B2B1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "login" ? (
    <svg
      className={props.className}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 6.66634V4.99967C8 4.55765 8.17559 4.13372 8.48816 3.82116C8.80072 3.5086 9.22464 3.33301 9.66667 3.33301H15.5C15.942 3.33301 16.366 3.5086 16.6785 3.82116C16.9911 4.13372 17.1667 4.55765 17.1667 4.99967V14.9997C17.1667 15.4417 16.9911 15.8656 16.6785 16.1782C16.366 16.4907 15.942 16.6663 15.5 16.6663H9.66667C9.22464 16.6663 8.80072 16.4907 8.48816 16.1782C8.17559 15.8656 8 15.4417 8 14.9997V13.333M3 9.99967H13.8333M13.8333 9.99967L11.3333 7.49967M13.8333 9.99967L11.3333 12.4997"
        stroke="#FFCED0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "map-pin-search" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4308 9.75573C12.5533 9.25058 12.5153 8.71981 12.3221 8.23726C12.1289 7.75472 11.7901 7.3444 11.3528 7.06339C10.9155 6.78238 10.4015 6.64467 9.88231 6.6694C9.36311 6.69413 8.86453 6.88008 8.45593 7.20138C8.04734 7.52268 7.74905 7.96334 7.60258 8.46207C7.4561 8.9608 7.46872 9.49278 7.63868 9.984C7.80863 10.4752 8.12748 10.9012 8.55085 11.2028C8.97422 11.5044 9.48106 11.6665 10.0008 11.6666M9.99328 17.9041C9.55418 17.9025 9.13355 17.7273 8.82328 17.4165L5.28661 13.8807C4.50375 13.0979 3.92819 12.1324 3.61187 11.0714C3.29556 10.0104 3.24845 8.88736 3.47481 7.80362C3.70117 6.71988 4.19387 5.70958 4.90842 4.86392C5.62298 4.01826 6.53689 3.36387 7.56765 2.95982C8.59842 2.55577 9.71358 2.4148 10.8125 2.54961C11.9114 2.68443 12.9594 3.0908 13.8619 3.73203C14.7644 4.37326 15.493 5.22917 15.982 6.22248C16.4709 7.21579 16.7048 8.31523 16.6624 9.42155M16.8341 16.8332L18.3341 18.3332M12.5009 14.9999C12.5009 15.6629 12.7642 16.2988 13.2331 16.7677C13.7019 17.2365 14.3378 17.4999 15.0009 17.4999C15.6639 17.4999 16.2998 17.2365 16.7686 16.7677C17.2375 16.2988 17.5009 15.6629 17.5009 14.9999C17.5009 14.3369 17.2375 13.701 16.7686 13.2321C16.2998 12.7633 15.6639 12.4999 15.0009 12.4999C14.3378 12.4999 13.7019 12.7633 13.2331 13.2321C12.7642 13.701 12.5009 14.3369 12.5009 14.9999Z"
        stroke="#A8B2B1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "menu" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33398 5H16.6673M3.33398 10H16.6673M3.33398 15H16.6673"
        stroke="#606B6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "phone" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16667 3.33301H7.5L9.16667 7.49967L7.08333 8.74967C7.9758 10.5593 9.44039 12.0239 11.25 12.9163L12.5 10.833L16.6667 12.4997V15.833C16.6667 16.275 16.4911 16.699 16.1785 17.0115C15.866 17.3241 15.442 17.4997 15 17.4997C11.7494 17.3021 8.68346 15.9218 6.38069 13.619C4.07792 11.3162 2.69754 8.25029 2.5 4.99967C2.5 4.55765 2.67559 4.13372 2.98816 3.82116C3.30072 3.5086 3.72464 3.33301 4.16667 3.33301Z"
        stroke="#A8B2B1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "plus" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99984 4.16699V15.8337M4.1665 10.0003H15.8332"
        stroke="#FFCED0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "pointer-question" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5523 10.4213L12.3157 10.1847L14.9098 8.44301C15.0894 8.34628 15.2351 8.19693 15.3274 8.015C15.4197 7.83307 15.4542 7.62727 15.4262 7.42521C15.3981 7.22314 15.309 7.03448 15.1707 6.88454C15.0323 6.7346 14.8515 6.63054 14.6523 6.58634L3.33398 3.33301L6.58732 14.6355C6.63164 14.8346 6.73578 15.0154 6.88577 15.1536C7.03577 15.2918 7.22444 15.3809 7.42649 15.4088C7.62854 15.4367 7.8343 15.4022 8.01617 15.3099C8.19803 15.2175 8.34731 15.0718 8.44398 14.8922L10.1857 12.3147L11.2507 13.3797M15.834 18.333V18.3413M15.834 15.833C16.2076 15.8318 16.5699 15.7053 16.8631 15.4738C17.1563 15.2423 17.3634 14.9191 17.4511 14.556C17.5389 14.1928 17.5023 13.8108 17.3472 13.4709C17.1921 13.131 16.9275 12.853 16.5957 12.6813C16.2641 12.5115 15.8849 12.4589 15.5197 12.5319C15.1544 12.605 14.8246 12.7995 14.584 13.0838"
        stroke="#A8B2B1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "ratingstar" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.86824 6.1166L1.55157 6.88744L1.45741 6.9066C1.31486 6.94445 1.1849 7.01944 1.08082 7.12394C0.97673 7.22843 0.902242 7.35868 0.864958 7.50137C0.827673 7.64407 0.828929 7.79411 0.868595 7.93616C0.908262 8.07822 0.98492 8.2072 1.09074 8.30994L4.94241 12.0591L4.03407 17.3549L4.02324 17.4466C4.01451 17.594 4.04513 17.7411 4.11195 17.8729C4.17877 18.0046 4.27939 18.1162 4.40352 18.1962C4.52765 18.2762 4.67081 18.3218 4.81836 18.3284C4.96591 18.3349 5.11254 18.3021 5.24324 18.2333L9.99824 15.7333L14.7424 18.2333L14.8257 18.2716C14.9633 18.3258 15.1128 18.3424 15.2589 18.3197C15.405 18.2971 15.5424 18.236 15.6571 18.1427C15.7717 18.0494 15.8595 17.9272 15.9114 17.7888C15.9633 17.6504 15.9775 17.5006 15.9524 17.3549L15.0432 12.0591L18.8966 8.3091L18.9616 8.23827C19.0544 8.12391 19.1153 7.98698 19.138 7.84143C19.1607 7.69588 19.1444 7.54692 19.0908 7.40971C19.0372 7.2725 18.9482 7.15196 18.8328 7.06036C18.7174 6.96876 18.5799 6.90938 18.4341 6.88827L13.1174 6.1166L10.7407 1.29994C10.672 1.16038 10.5655 1.04286 10.4334 0.960689C10.3013 0.878514 10.1488 0.834961 9.99324 0.834961C9.83766 0.834961 9.68519 0.878514 9.55308 0.960689C9.42098 1.04286 9.31451 1.16038 9.24574 1.29994L6.86824 6.1166Z"
        fill="#C9D0CE"
      />
      <mask
        id="mask0_2813_10894"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <path
          d="M6.86824 6.1166L1.55157 6.88744L1.45741 6.9066C1.31486 6.94445 1.1849 7.01944 1.08082 7.12394C0.97673 7.22843 0.902242 7.35868 0.864958 7.50137C0.827673 7.64407 0.828929 7.79411 0.868595 7.93616C0.908262 8.07822 0.98492 8.2072 1.09074 8.30994L4.94241 12.0591L4.03407 17.3549L4.02324 17.4466C4.01451 17.594 4.04513 17.7411 4.11195 17.8729C4.17877 18.0046 4.27939 18.1162 4.40352 18.1962C4.52765 18.2762 4.67081 18.3218 4.81836 18.3284C4.96591 18.3349 5.11254 18.3021 5.24324 18.2333L9.99824 15.7333L14.7424 18.2333L14.8257 18.2716C14.9633 18.3258 15.1128 18.3424 15.2589 18.3197C15.405 18.2971 15.5424 18.236 15.6571 18.1427C15.7717 18.0494 15.8595 17.9272 15.9114 17.7888C15.9633 17.6504 15.9775 17.5006 15.9524 17.3549L15.0432 12.0591L18.8966 8.3091L18.9616 8.23827C19.0544 8.12391 19.1153 7.98698 19.138 7.84143C19.1607 7.69588 19.1444 7.54692 19.0908 7.40971C19.0372 7.2725 18.9482 7.15196 18.8328 7.06036C18.7174 6.96876 18.5799 6.90938 18.4341 6.88827L13.1174 6.1166L10.7407 1.29994C10.672 1.16038 10.5655 1.04286 10.4334 0.960689C10.3013 0.878514 10.1488 0.834961 9.99324 0.834961C9.83766 0.834961 9.68519 0.878514 9.55308 0.960689C9.42098 1.04286 9.31451 1.16038 9.24574 1.29994L6.86824 6.1166Z"
          fill="#FDB022"
        />
      </mask>
      <g mask="url(#mask0_2813_10894)">
        <rect width="20" height="20" fill="#FE8034" />
      </g>
    </svg>
  ) : props.icon === "refresh" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6693 9.16658C16.4655 7.7001 15.7852 6.3413 14.7331 5.2995C13.6811 4.25769 12.3157 3.59068 10.8473 3.40121C9.37893 3.21174 7.88896 3.51032 6.60694 4.25096C5.32492 4.99159 4.32198 6.1332 3.7526 7.49992M3.33594 4.16658V7.49992H6.66927M3.33594 10.8333C3.53974 12.2998 4.22005 13.6586 5.27207 14.7004C6.3241 15.7422 7.68947 16.4092 9.15788 16.5987C10.6263 16.7881 12.1163 16.4896 13.3983 15.7489C14.6803 15.0083 15.6832 13.8667 16.2526 12.5M16.6693 15.8333V12.5H13.3359"
        stroke="#606B6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "search" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="search">
        <path
          id="Vector"
          d="M17.5 17.5L12.5 12.5M2.5 8.33333C2.5 9.09938 2.65088 9.85792 2.94404 10.5657C3.23719 11.2734 3.66687 11.9164 4.20854 12.4581C4.75022 12.9998 5.39328 13.4295 6.10101 13.7226C6.80875 14.0158 7.56729 14.1667 8.33333 14.1667C9.09938 14.1667 9.85792 14.0158 10.5657 13.7226C11.2734 13.4295 11.9164 12.9998 12.4581 12.4581C12.9998 11.9164 13.4295 11.2734 13.7226 10.5657C14.0158 9.85792 14.1667 9.09938 14.1667 8.33333C14.1667 7.56729 14.0158 6.80875 13.7226 6.10101C13.4295 5.39328 12.9998 4.75022 12.4581 4.20854C11.9164 3.66687 11.2734 3.23719 10.5657 2.94404C9.85792 2.65088 9.09938 2.5 8.33333 2.5C7.56729 2.5 6.80875 2.65088 6.10101 2.94404C5.39328 3.23719 4.75022 3.66687 4.20854 4.20854C3.66687 4.75022 3.23719 5.39328 2.94404 6.10101C2.65088 6.80875 2.5 7.56729 2.5 8.33333Z"
          stroke="#606B6C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ) : props.icon === "star-neutral" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99937 14.7916L4.85604 17.4958L5.83854 11.7683L1.67188 7.71247L7.42187 6.87914L9.99354 1.6683L12.5652 6.87914L18.3152 7.71247L14.1485 11.7683L15.131 17.4958L9.99937 14.7916Z"
        stroke="#838F8E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "star-primary" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99937 14.7916L4.85604 17.4958L5.83854 11.7683L1.67188 7.71244L7.42187 6.87911L9.99354 1.66827L12.5652 6.87911L18.3152 7.71244L14.1485 11.7683L15.131 17.4958L9.99937 14.7916Z"
        stroke="#FF7A8B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "star-secondary" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99937 14.7916L4.85604 17.4958L5.83854 11.7683L1.67188 7.71244L7.42187 6.87911L9.99354 1.66827L12.5652 6.87911L18.3152 7.71244L14.1485 11.7683L15.131 17.4958L9.99937 14.7916Z"
        stroke="#CB85FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "succes" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M14.5674 2.78357C15.8243 3.50928 16.8698 4.55067 17.6006 5.80465C18.3313 7.05863 18.7219 8.48171 18.7336 9.93302C18.7453 11.3843 18.3778 12.8135 17.6674 14.0792C16.957 15.3448 15.9284 16.4029 14.6834 17.1488C13.4384 17.8948 12.0202 18.3026 10.5691 18.332C9.11808 18.3613 7.68451 18.0112 6.41035 17.3163C5.13618 16.6213 4.06561 15.6057 3.30461 14.3698C2.5436 13.134 2.11856 11.7208 2.07155 10.2702L2.06738 10.0002L2.07155 9.73024C2.11822 8.29106 2.53701 6.88854 3.28708 5.6594C4.03716 4.43026 5.09293 3.41645 6.35146 2.7168C7.60999 2.01716 9.02834 1.65557 10.4682 1.66727C11.9081 1.67897 13.3204 2.06357 14.5674 2.78357ZM13.4899 7.7444C13.3464 7.60092 13.1555 7.51473 12.953 7.502C12.7504 7.48926 12.5502 7.55086 12.3899 7.67524L12.3116 7.7444L9.56738 10.4877L8.48988 9.41107L8.41155 9.3419C8.2512 9.21762 8.05103 9.15609 7.84855 9.16886C7.64608 9.18164 7.45522 9.26783 7.31177 9.41129C7.16831 9.55474 7.08212 9.7456 7.06934 9.94807C7.05657 10.1505 7.1181 10.3507 7.24238 10.5111L7.31155 10.5894L8.97822 12.2561L9.05655 12.3252C9.20269 12.4386 9.38241 12.5002 9.56738 12.5002C9.75236 12.5002 9.93207 12.4386 10.0782 12.3252L10.1565 12.2561L13.4899 8.92274L13.559 8.8444C13.6834 8.68407 13.745 8.48386 13.7323 8.28134C13.7196 8.07881 13.6334 7.8879 13.4899 7.7444Z"
        fill="#2FBC2B"
      />
    </svg>
  ) : props.icon === "user" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 17.5V15.8333C5 14.9493 5.35119 14.1014 5.97631 13.4763C6.60143 12.8512 7.44928 12.5 8.33333 12.5H11.6667C12.5507 12.5 13.3986 12.8512 14.0237 13.4763C14.6488 14.1014 15 14.9493 15 15.8333V17.5M6.66667 5.83333C6.66667 6.71739 7.01786 7.56523 7.64298 8.19036C8.2681 8.81548 9.11594 9.16667 10 9.16667C10.8841 9.16667 11.7319 8.81548 12.357 8.19036C12.9821 7.56523 13.3333 6.71739 13.3333 5.83333C13.3333 4.94928 12.9821 4.10143 12.357 3.47631C11.7319 2.85119 10.8841 2.5 10 2.5C9.11594 2.5 8.2681 2.85119 7.64298 3.47631C7.01786 4.10143 6.66667 4.94928 6.66667 5.83333Z"
        stroke="#606B6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "users" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 17.5V15.8333C2.5 14.9493 2.85119 14.1014 3.47631 13.4763C4.10143 12.8512 4.94928 12.5 5.83333 12.5H9.16667C10.0507 12.5 10.8986 12.8512 11.5237 13.4763C12.1488 14.1014 12.5 14.9493 12.5 15.8333V17.5M13.3333 2.60824C14.0503 2.79182 14.6859 3.20882 15.1397 3.79349C15.5935 4.37817 15.8399 5.09726 15.8399 5.8374C15.8399 6.57754 15.5935 7.29664 15.1397 7.88131C14.6859 8.46598 14.0503 8.88298 13.3333 9.06657M17.5 17.4999V15.8333C17.4958 15.0976 17.2483 14.3839 16.7961 13.8036C16.3439 13.2233 15.7124 12.8088 15 12.6249M4.16667 5.83333C4.16667 6.71739 4.51786 7.56523 5.14298 8.19036C5.7681 8.81548 6.61594 9.16667 7.5 9.16667C8.38405 9.16667 9.2319 8.81548 9.85702 8.19036C10.4821 7.56523 10.8333 6.71739 10.8333 5.83333C10.8333 4.94928 10.4821 4.10143 9.85702 3.47631C9.2319 2.85119 8.38405 2.5 7.5 2.5C6.61594 2.5 5.7681 2.85119 5.14298 3.47631C4.51786 4.10143 4.16667 4.94928 4.16667 5.83333Z"
        stroke="#A8B2B1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "x" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 5L5 15M5 5L15 15"
        stroke="#606B6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "wallet-off" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M25.5 12V7.5C25.5 7.10218 25.342 6.72064 25.0607 6.43934C24.7794 6.15804 24.3979 6 24 6H12M6.88054 6.876C6.46017 7.29531 6.17368 7.82992 6.05734 8.41215C5.94099 8.99438 6.00003 9.59804 6.22698 10.1467C6.45393 10.6954 6.83857 11.1643 7.33222 11.4942C7.82586 11.8242 8.4063 12.0002 9.00004 12H12M18 12H27C27.3979 12 27.7794 12.158 28.0607 12.4393C28.342 12.7206 28.5 13.1022 28.5 13.5V18M28.5 28.5C28.5 28.8978 28.342 29.2794 28.0607 29.5607C27.7794 29.842 27.3978 30 27 30H9C8.20435 30 7.44129 29.6839 6.87868 29.1213C6.31607 28.5587 6 27.7956 6 27V9M24 18H30V24M24 24C23.2044 24 22.4413 23.6839 21.8787 23.1213C21.3161 22.5587 21 21.7956 21 21M4.5 4.5L31.5 31.5"
        stroke="#FF7A8B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "checkbox" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M13.5 16.5L18 21L30 9M30 18V27C30 27.7956 29.6839 28.5587 29.1213 29.1213C28.5587 29.6839 27.7956 30 27 30H9C8.20435 30 7.44129 29.6839 6.87868 29.1213C6.31607 28.5587 6 27.7956 6 27V9C6 8.20435 6.31607 7.44129 6.87868 6.87868C7.44129 6.31607 8.20435 6 9 6H22.5"
        stroke="#CB85FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "shield-chevron" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M6.00049 21L18.0005 16.5L30.0005 21M18.0002 4.5C21.504 7.59986 26.0769 9.21385 30.7502 9C31.4306 11.3145 31.6388 13.7422 31.3624 16.1388C31.086 18.5353 30.3307 20.8519 29.1413 22.9508C27.952 25.0497 26.3528 26.8881 24.4389 28.3567C22.525 29.8254 20.3354 30.8943 18.0002 31.5C15.6651 30.8943 13.4755 29.8254 11.5616 28.3567C9.64765 26.8881 8.0485 25.0497 6.85913 22.9508C5.66976 20.8519 4.91444 18.5353 4.63805 16.1388C4.36167 13.7422 4.56985 11.3145 5.25024 9C9.92353 9.21385 14.4965 7.59986 18.0002 4.5Z"
        stroke="#879FFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "receipt" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M13.5 10.5H22.5M13.5 16.5H22.5M19.5 22.5H22.5M7.5 31.5V7.5C7.5 6.70435 7.81607 5.94129 8.37868 5.37868C8.94129 4.81607 9.70435 4.5 10.5 4.5H25.5C26.2956 4.5 27.0587 4.81607 27.6213 5.37868C28.1839 5.94129 28.5 6.70435 28.5 7.5V31.5L24 28.5L21 31.5L18 28.5L15 31.5L12 28.5L7.5 31.5Z"
        stroke="#2FBC2B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "rocket" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
    >
      <path
        d="M17.5007 35C14.8988 36.4689 12.7948 38.6815 11.4586 41.3539C10.1224 44.0264 9.6147 47.0371 10.0007 50C12.9636 50.386 15.9743 49.8784 18.6468 48.5421C21.3193 47.2059 23.5318 45.1019 25.0007 42.5M10.0007 32.5C14.4585 33.0298 18.6084 35.0438 21.7826 38.2181C24.9569 41.3924 26.971 45.5423 27.5007 50C29.7105 48.726 31.559 46.9096 32.8713 44.7223C34.1836 42.5351 34.9165 40.0493 35.0007 37.5C39.1985 36.0233 42.8641 33.335 45.5341 29.775C48.2041 26.2151 49.7585 21.9433 50.0007 17.5C50.0007 15.5109 49.2106 13.6032 47.804 12.1967C46.3975 10.7902 44.4899 10 42.5007 10C38.0574 10.2423 33.7857 11.7967 30.2257 14.4666C26.6657 17.1366 23.9774 20.8022 22.5007 25C19.9514 25.0842 17.4656 25.8171 15.2784 27.1294C13.0912 28.4418 11.2747 30.2902 10.0007 32.5ZM35.0007 22.5C35.0007 23.163 35.2641 23.7989 35.733 24.2678C36.2018 24.7366 36.8377 25 37.5007 25C38.1638 25 38.7997 24.7366 39.2685 24.2678C39.7373 23.7989 40.0007 23.163 40.0007 22.5C40.0007 21.837 39.7373 21.2011 39.2685 20.7322C38.7997 20.2634 38.1638 20 37.5007 20C36.8377 20 36.2018 20.2634 35.733 20.7322C35.2641 21.2011 35.0007 21.837 35.0007 22.5Z"
        stroke="#DE1F4C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "double-arrow" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M6.16406 5.8335L10.3307 10.0002L6.16406 14.1668M11.1641 5.8335L15.3307 10.0002L11.1641 14.1668"
        stroke="#A8B2B1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "eye" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 12C10 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 12C18.6 16 15.6 18 12 18C8.4 18 5.4 16 3 12C5.4 8 8.4 6 12 6C15.6 6 18.6 8 21 12Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "eye-off" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5851 10.5869C10.21 10.9621 9.99942 11.4708 9.99951 12.0013C9.99961 12.5317 10.2104 13.0404 10.5856 13.4154C10.9607 13.7904 11.4695 14.0011 11.9999 14.001C12.5304 14.0009 13.039 13.7901 13.4141 13.4149M16.681 16.673C15.2782 17.5507 13.6547 18.0109 12 18C8.4 18 5.4 16 3 12C4.272 9.88003 5.712 8.32203 7.32 7.32603M10.18 6.18003C10.779 6.05876 11.3888 5.99845 12 6.00003C15.6 6.00003 18.6 8.00003 21 12C20.334 13.11 19.621 14.067 18.862 14.87M3 3L21 21"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "route" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11 19H16.5C17.4283 19 18.3185 18.6313 18.9749 17.9749C19.6313 17.3185 20 16.4283 20 15.5C20 14.5717 19.6313 13.6815 18.9749 13.0251C18.3185 12.3687 17.4283 12 16.5 12H8.5C7.57174 12 6.6815 11.6313 6.02513 10.9749C5.36875 10.3185 5 9.42826 5 8.5C5 7.57174 5.36875 6.6815 6.02513 6.02513C6.6815 5.36875 7.57174 5 8.5 5H13M3 19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21C5.53043 21 6.03914 20.7893 6.41421 20.4142C6.78929 20.0391 7 19.5304 7 19C7 18.4696 6.78929 17.9609 6.41421 17.5858C6.03914 17.2107 5.53043 17 5 17C4.46957 17 3.96086 17.2107 3.58579 17.5858C3.21071 17.9609 3 18.4696 3 19ZM19 7C19.5304 7 20.0391 6.78929 20.4142 6.41421C20.7893 6.03914 21 5.53043 21 5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3C18.4696 3 17.9609 3.21071 17.5858 3.58579C17.2107 3.96086 17 4.46957 17 5C17 5.53043 17.2107 6.03914 17.5858 6.41421C17.9609 6.78929 18.4696 7 19 7Z"
        stroke="#696775"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "minus" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.16669 10H15.8334"
        stroke="#696775"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "photo-plus" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M25 13.3333H25.0167M20.8333 35H10C8.67392 35 7.40215 34.4732 6.46447 33.5355C5.52678 32.5979 5 31.3261 5 30V10C5 8.67392 5.52678 7.40215 6.46447 6.46447C7.40215 5.52678 8.67392 5 10 5H30C31.3261 5 32.5979 5.52678 33.5355 6.46447C34.4732 7.40215 35 8.67392 35 10V20.8333M5 26.6666L13.3333 18.3333C14.88 16.8449 16.7867 16.8449 18.3333 18.3333L25 24.9999M23.3333 23.3333L25 21.6666C26.1167 20.5933 27.4167 20.2933 28.6367 20.7666M26.6667 31.6667H36.6667M31.6667 26.6667V36.6667"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "insert-textarea" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.3335 10.0002H16.6668M6.66683 6.66683L10.0002 3.3335L13.3335 6.66683M13.3335 13.3335L10.0002 16.6668L6.66683 13.3335"
        stroke="#696775"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "list-numbers" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.16683 5.00016H16.6668M9.16683 10.0002H16.6668M10.0002 15.0002H16.6668M3.3335 13.3335C3.3335 12.8915 3.50909 12.4675 3.82165 12.155C4.13421 11.8424 4.55814 11.6668 5.00016 11.6668C5.44219 11.6668 5.86611 11.8424 6.17867 12.155C6.49123 12.4675 6.66683 12.8915 6.66683 13.3335C6.66683 13.826 6.25016 14.1668 5.8335 14.5835L3.3335 16.6668H6.66683M5.00016 8.3335V3.3335L3.3335 5.00016"
        stroke="#696775"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "list" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.49984 5H16.6665M7.49984 10H16.6665M7.49984 15H16.6665M4.1665 5V5.00833M4.1665 10V10.0083M4.1665 15V15.0083"
        stroke="#696775"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "bold" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.8333 9.99984C11.6068 9.99984 12.3487 9.69255 12.8956 9.14557C13.4426 8.59858 13.7499 7.85672 13.7499 7.08317C13.7499 6.30962 13.4426 5.56776 12.8956 5.02078C12.3487 4.47379 11.6068 4.1665 10.8333 4.1665H5.83325V9.99984M10.8333 9.99984H5.83325M10.8333 9.99984H11.6666C12.4401 9.99984 13.182 10.3071 13.729 10.8541C14.276 11.4011 14.5833 12.143 14.5833 12.9165C14.5833 13.6901 14.276 14.4319 13.729 14.9789C13.182 15.5259 12.4401 15.8332 11.6666 15.8332H5.83325V9.99984"
        stroke="#696775"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "array-down-tablet" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5 7.5L10 12.5L15 7.5"
        stroke="#606B6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "table-plus" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M18.75 31.5H7.5C6.70435 31.5 5.94129 31.1839 5.37868 30.6213C4.81607 30.0587 4.5 29.2956 4.5 28.5V7.5C4.5 6.70435 4.81607 5.94129 5.37868 5.37868C5.94129 4.81607 6.70435 4.5 7.5 4.5H28.5C29.2956 4.5 30.0587 4.81607 30.6213 5.37868C31.1839 5.94129 31.5 6.70435 31.5 7.5V18.75M4.5 15H31.5M15 4.5V31.5M24 28.5H33M28.5 24V33"
        stroke="#696775"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "clock" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 5.83333V10L12.5 12.5M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10Z"
        stroke="#696775"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "arrow-narrow-left" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.16699 10H15.8337M4.16699 10L7.50033 13.3334M4.16699 10L7.50033 6.66669"
        stroke="#696775"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "file-type-pdf" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M11.666 2.5V5.83333C11.666 6.05435 11.7538 6.26631 11.9101 6.42259C12.0664 6.57887 12.2783 6.66667 12.4993 6.66667H15.8327M11.666 2.5H5.83268C5.39065 2.5 4.96673 2.67559 4.65417 2.98816C4.34161 3.30072 4.16602 3.72464 4.16602 4.16667V10M11.666 2.5L15.8327 6.66667M15.8327 6.66667V10M4.16602 15H5.41602C5.74754 15 6.06548 14.8683 6.2999 14.6339C6.53432 14.3995 6.66602 14.0815 6.66602 13.75C6.66602 13.4185 6.53432 13.1005 6.2999 12.8661C6.06548 12.6317 5.74754 12.5 5.41602 12.5H4.16602V17.5M14.166 15H15.8327M16.666 12.5H14.166V17.5M9.16602 12.5V17.5H9.99935C10.4414 17.5 10.8653 17.3244 11.1779 17.0118C11.4904 16.6993 11.666 16.2754 11.666 15.8333V14.1667C11.666 13.7246 11.4904 13.3007 11.1779 12.9882C10.8653 12.6756 10.4414 12.5 9.99935 12.5H9.16602Z"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "flag" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5 14.0001C5.93464 13.084 7.19124 12.5708 8.5 12.5708C9.80876 12.5708 11.0654 13.084 12 14.0001C12.9346 14.9162 14.1912 15.4294 15.5 15.4294C16.8088 15.4294 18.0654 14.9162 19 14.0001V5.00009C18.0654 5.91622 16.8088 6.42937 15.5 6.42937C14.1912 6.42937 12.9346 5.91622 12 5.00009C11.0654 4.08395 9.80876 3.5708 8.5 3.5708C7.19124 3.5708 5.93464 4.08395 5 5.00009V14.0001ZM5 14.0001V21.0001"
        stroke="#696775"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "circle-check-filled" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17 3.33989C18.5083 4.21075 19.7629 5.46042 20.6398 6.96519C21.5167 8.46997 21.9854 10.1777 21.9994 11.9192C22.0135 13.6608 21.5725 15.3758 20.72 16.8946C19.8676 18.4133 18.6332 19.6831 17.1392 20.5782C15.6452 21.4733 13.9434 21.9627 12.2021 21.998C10.4608 22.0332 8.74055 21.6131 7.21155 20.7791C5.68256 19.9452 4.39787 18.7264 3.48467 17.2434C2.57146 15.7604 2.06141 14.0646 2.005 12.3239L2 11.9999L2.005 11.6759C2.061 9.94888 2.56355 8.26585 3.46364 6.79089C4.36373 5.31592 5.63065 4.09934 7.14089 3.25977C8.65113 2.42021 10.3531 1.98629 12.081 2.00033C13.8089 2.01437 15.5036 2.47589 17 3.33989ZM15.707 9.29289C15.5348 9.12072 15.3057 9.01729 15.0627 9.002C14.8197 8.98672 14.5794 9.06064 14.387 9.20989L14.293 9.29289L11 12.5849L9.707 11.2929L9.613 11.2099C9.42058 11.0607 9.18037 10.9869 8.9374 11.0022C8.69444 11.0176 8.46541 11.121 8.29326 11.2932C8.12112 11.4653 8.01768 11.6943 8.00235 11.9373C7.98702 12.1803 8.06086 12.4205 8.21 12.6129L8.293 12.7069L10.293 14.7069L10.387 14.7899C10.5624 14.926 10.778 14.9998 11 14.9998C11.222 14.9998 11.4376 14.926 11.613 14.7899L11.707 14.7069L15.707 10.7069L15.79 10.6129C15.9393 10.4205 16.0132 10.1802 15.9979 9.93721C15.9826 9.69419 15.8792 9.46509 15.707 9.29289Z"
        fill="#029C3F"
      />
    </svg>
  ) : props.icon === "circle-x-filled" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17 3.34001C18.5083 4.21087 19.7629 5.46054 20.6398 6.96531C21.5167 8.47009 21.9854 10.1778 21.9994 11.9194C22.0135 13.6609 21.5725 15.376 20.72 16.8947C19.8676 18.4135 18.6332 19.6832 17.1392 20.5783C15.6452 21.4734 13.9434 21.9628 12.2021 21.9981C10.4608 22.0333 8.74055 21.6132 7.21155 20.7793C5.68256 19.9453 4.39787 18.7265 3.48467 17.2435C2.57146 15.7605 2.06141 14.0647 2.005 12.324L2 12L2.005 11.676C2.061 9.94901 2.56355 8.26598 3.46364 6.79101C4.36373 5.31604 5.63065 4.09947 7.14089 3.2599C8.65113 2.42033 10.3531 1.98641 12.081 2.00045C13.8089 2.01449 15.5036 2.47601 17 3.34001ZM10.511 9.14002C10.3015 9.01529 10.0536 8.97142 9.81401 9.01664C9.57441 9.06187 9.35959 9.19308 9.20995 9.3856C9.06031 9.57811 8.98617 9.81867 9.00146 10.062C9.01675 10.3054 9.12043 10.5347 9.293 10.707L10.585 12L9.293 13.293L9.21 13.387C9.05459 13.588 8.98151 13.8406 9.0056 14.0935C9.02969 14.3465 9.14916 14.5807 9.33972 14.7488C9.53029 14.9168 9.77767 15.006 10.0316 14.9982C10.2856 14.9905 10.527 14.8864 10.707 14.707L12 13.415L13.293 14.707L13.387 14.79C13.588 14.9454 13.8406 15.0185 14.0935 14.9944C14.3464 14.9703 14.5807 14.8509 14.7488 14.6603C14.9168 14.4697 15.006 14.2223 14.9982 13.9684C14.9905 13.7144 14.8863 13.473 14.707 13.293L13.415 12L14.707 10.707L14.79 10.613C14.9454 10.412 15.0185 10.1594 14.9944 9.90649C14.9703 9.65357 14.8508 9.4193 14.6603 9.25126C14.4697 9.08323 14.2223 8.99403 13.9684 9.00179C13.7144 9.00954 13.473 9.11367 13.293 9.29302L12 10.585L10.707 9.29302L10.613 9.21001L10.511 9.14002Z"
        fill="#F74055"
      />
    </svg>
  ) : props.icon === "player-pause-filled" ? (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9 4H7C6.46957 4 5.96086 4.21071 5.58579 4.58579C5.21071 4.96086 5 5.46957 5 6V18C5 18.5304 5.21071 19.0391 5.58579 19.4142C5.96086 19.7893 6.46957 20 7 20H9C9.53043 20 10.0391 19.7893 10.4142 19.4142C10.7893 19.0391 11 18.5304 11 18V6C11 5.46957 10.7893 4.96086 10.4142 4.58579C10.0391 4.21071 9.53043 4 9 4Z"
        fill="#F74055"
      />
      <path
        d="M17 4H15C14.4696 4 13.9609 4.21071 13.5858 4.58579C13.2107 4.96086 13 5.46957 13 6V18C13 18.5304 13.2107 19.0391 13.5858 19.4142C13.9609 19.7893 14.4696 20 15 20H17C17.5304 20 18.0391 19.7893 18.4142 19.4142C18.7893 19.0391 19 18.5304 19 18V6C19 5.46957 18.7893 4.96086 18.4142 4.58579C18.0391 4.21071 17.5304 4 17 4Z"
        fill="#F74055"
      />
    </svg>
  ) : props.icon === "mood-sad" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 8.33333H7.50833M12.5 8.33333H12.5083M7.91667 12.7083C8.18823 12.4311 8.51237 12.211 8.87011 12.0606C9.22784 11.9103 9.61197 11.8329 10 11.8329C10.388 11.8329 10.7722 11.9103 11.1299 12.0606C11.4876 12.211 11.8118 12.4311 12.0833 12.7083M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10Z"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "no-image" ? (
    <svg
      className={props.className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 8H15.01M3 16L8 11C8.928 10.107 10.072 10.107 11 11L16 16M14 14L15 13C15.928 12.107 17.072 12.107 18 13L21 16M3 6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H18C18.7956 3 19.5587 3.31607 20.1213 3.87868C20.6839 4.44129 21 5.20435 21 6V18C21 18.7956 20.6839 19.5587 20.1213 20.1213C19.5587 20.6839 18.7956 21 18 21H6C5.20435 21 4.44129 20.6839 3.87868 20.1213C3.31607 19.5587 3 18.7956 3 18V6Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "arrow-up" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4.1665V15.8332M10 4.1665L15 9.1665M10 4.1665L5 9.1665"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "arrow-down" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4.1665V15.8332M10 15.8332L15 10.8332M10 15.8332L5 10.8332"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "notes" ? (
    <svg
      className={props.className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 7H15M9 11H15M9 15H13M5 5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5Z"
        stroke="#696775"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "circle-check-filled-b" ? (
    <svg
      className={props.className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.334 2.22676C12.3395 2.80733 13.1759 3.64044 13.7605 4.64362C14.3451 5.64681 14.6576 6.78527 14.6669 7.94632C14.6763 9.10736 14.3823 10.2507 13.814 11.2632C13.2457 12.2757 12.4228 13.1222 11.4268 13.719C10.4308 14.3157 9.29623 14.642 8.13538 14.6655C6.97454 14.689 5.82768 14.4089 4.80835 13.8529C3.78902 13.297 2.93257 12.4844 2.32376 11.4958C1.71496 10.5071 1.37492 9.37656 1.33732 8.21609L1.33398 8.00009L1.33732 7.78409C1.37465 6.63275 1.70968 5.51073 2.30974 4.52742C2.90981 3.54411 3.75442 2.73306 4.76125 2.17335C5.76807 1.61363 6.90275 1.32436 8.05466 1.33372C9.20656 1.34308 10.3364 1.65076 11.334 2.22676ZM10.472 6.19542C10.3572 6.08064 10.2045 6.01169 10.0424 6.0015C9.88042 5.99131 9.72025 6.04059 9.59198 6.14009L9.52932 6.19542L7.33398 8.39009L6.47198 7.52876L6.40932 7.47342C6.28104 7.37399 6.1209 7.32477 5.95892 7.33499C5.79694 7.34521 5.64426 7.41417 5.52949 7.52893C5.41473 7.6437 5.34577 7.79638 5.33555 7.95836C5.32533 8.12034 5.37455 8.28048 5.47398 8.40876L5.52932 8.47142L6.86265 9.80476L6.92532 9.86009C7.04223 9.9508 7.18601 10 7.33398 10C7.48196 10 7.62574 9.9508 7.74265 9.86009L7.80532 9.80476L10.472 7.13809L10.5273 7.07542C10.6268 6.94715 10.6761 6.78699 10.6659 6.62497C10.6557 6.46295 10.5868 6.31022 10.472 6.19542Z"
        fill="#353343"
      />
    </svg>
  ) : props.icon === "chevrons-down" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83301 5.83325L9.99967 9.99992L14.1663 5.83325M5.83301 10.8333L9.99967 14.9999L14.1663 10.8333"
        stroke="#696775"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "check" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16699 9.99992L8.33366 14.1666L16.667 5.83325"
        stroke="#F74439"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "list-details" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8333 4.16683H17.5M10.8333 7.50016H15M10.8333 12.5002H17.5M10.8333 15.8335H15M2.5 4.16683C2.5 3.94582 2.5878 3.73385 2.74408 3.57757C2.90036 3.42129 3.11232 3.3335 3.33333 3.3335H6.66667C6.88768 3.3335 7.09964 3.42129 7.25592 3.57757C7.4122 3.73385 7.5 3.94582 7.5 4.16683V7.50016C7.5 7.72118 7.4122 7.93314 7.25592 8.08942C7.09964 8.2457 6.88768 8.3335 6.66667 8.3335H3.33333C3.11232 8.3335 2.90036 8.2457 2.74408 8.08942C2.5878 7.93314 2.5 7.72118 2.5 7.50016V4.16683ZM2.5 12.5002C2.5 12.2791 2.5878 12.0672 2.74408 11.9109C2.90036 11.7546 3.11232 11.6668 3.33333 11.6668H6.66667C6.88768 11.6668 7.09964 11.7546 7.25592 11.9109C7.4122 12.0672 7.5 12.2791 7.5 12.5002V15.8335C7.5 16.0545 7.4122 16.2665 7.25592 16.4228C7.09964 16.579 6.88768 16.6668 6.66667 16.6668H3.33333C3.11232 16.6668 2.90036 16.579 2.74408 16.4228C2.5878 16.2665 2.5 16.0545 2.5 15.8335V12.5002Z"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "heart-plus" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99911 16.6668L3.74911 10.4768C3.33687 10.0757 3.01215 9.59349 2.7954 9.06068C2.57866 8.52786 2.47458 7.95594 2.48973 7.38093C2.50487 6.80591 2.63891 6.24026 2.88341 5.71959C3.1279 5.19893 3.47756 4.73453 3.91035 4.35563C4.34314 3.97674 4.8497 3.69155 5.39812 3.51805C5.94654 3.34454 6.52495 3.28647 7.09692 3.34748C7.66889 3.4085 8.22203 3.58728 8.72151 3.87257C9.22099 4.15786 9.65599 4.54348 9.99911 5.00515C10.668 4.12551 11.6589 3.54762 12.7539 3.39861C13.8489 3.2496 14.9582 3.54167 15.8379 4.21056C16.7175 4.87946 17.2954 5.8704 17.4444 6.96538C17.5934 8.06035 17.3013 9.16968 16.6324 10.0493M13.3324 15.8335H18.3324M15.8324 13.3335V18.3335"
        stroke="#696775"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "external-link" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0007 4.99992H5.00065C4.55862 4.99992 4.1347 5.17551 3.82214 5.48807C3.50958 5.80063 3.33398 6.22456 3.33398 6.66659V14.9999C3.33398 15.4419 3.50958 15.8659 3.82214 16.1784C4.1347 16.491 4.55862 16.6666 5.00065 16.6666H13.334C13.776 16.6666 14.1999 16.491 14.5125 16.1784C14.8251 15.8659 15.0007 15.4419 15.0007 14.9999V9.99992M9.16732 10.8333L16.6673 3.33325M16.6673 3.33325H12.5007M16.6673 3.33325V7.49992"
        stroke="#696775"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "share" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25001 8.91671L12.75 6.08337M7.25001 11.0834L12.75 13.9167M2.5 10C2.5 10.663 2.76339 11.2989 3.23223 11.7678C3.70107 12.2366 4.33696 12.5 5 12.5C5.66304 12.5 6.29893 12.2366 6.76777 11.7678C7.23661 11.2989 7.5 10.663 7.5 10C7.5 9.33696 7.23661 8.70107 6.76777 8.23223C6.29893 7.76339 5.66304 7.5 5 7.5C4.33696 7.5 3.70107 7.76339 3.23223 8.23223C2.76339 8.70107 2.5 9.33696 2.5 10ZM12.5 5C12.5 5.66304 12.7634 6.29893 13.2322 6.76777C13.7011 7.23661 14.337 7.5 15 7.5C15.663 7.5 16.2989 7.23661 16.7678 6.76777C17.2366 6.29893 17.5 5.66304 17.5 5C17.5 4.33696 17.2366 3.70107 16.7678 3.23223C16.2989 2.76339 15.663 2.5 15 2.5C14.337 2.5 13.7011 2.76339 13.2322 3.23223C12.7634 3.70107 12.5 4.33696 12.5 5ZM12.5 15C12.5 15.663 12.7634 16.2989 13.2322 16.7678C13.7011 17.2366 14.337 17.5 15 17.5C15.663 17.5 16.2989 17.2366 16.7678 16.7678C17.2366 16.2989 17.5 15.663 17.5 15C17.5 14.337 17.2366 13.7011 16.7678 13.2322C16.2989 12.7634 15.663 12.5 15 12.5C14.337 12.5 13.7011 12.7634 13.2322 13.2322C12.7634 13.7011 12.5 14.337 12.5 15Z"
        stroke="#696775"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "old" ? (
    <svg
      className={props.className}
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6667 35L17 28.3333L13.6667 23.3333V13.3333M13.6667 13.3333L7 18.3333L8.66667 23.3333M13.6667 13.3333L18.6667 16.6667L23.6667 17.5M12 28.3333L8.66667 35M27 35V20.8333C27 20.1703 27.2634 19.5344 27.7322 19.0656C28.2011 18.5967 28.837 18.3333 29.5 18.3333C30.163 18.3333 30.7989 18.5967 31.2678 19.0656C31.7366 19.5344 32 20.1703 32 20.8333V21.6667M12 6.66667C12 7.10869 12.1756 7.53262 12.4882 7.84518C12.8007 8.15774 13.2246 8.33333 13.6667 8.33333C14.1087 8.33333 14.5326 8.15774 14.8452 7.84518C15.1577 7.53262 15.3333 7.10869 15.3333 6.66667C15.3333 6.22464 15.1577 5.80072 14.8452 5.48816C14.5326 5.17559 14.1087 5 13.6667 5C13.2246 5 12.8007 5.17559 12.4882 5.48816C12.1756 5.80072 12 6.22464 12 6.66667Z"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "wheelchair" ? (
    <svg
      className={props.className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.6667 28.3333C32.5507 28.3333 33.3986 28.6845 34.0237 29.3096C34.6488 29.9348 35 30.7826 35 31.6667C35 32.5507 34.6488 33.3986 34.0237 34.0237C33.3986 34.6488 32.5507 35 31.6667 35C30.7826 35 29.9348 34.6488 29.3096 34.0237C28.6845 33.3986 28.3333 32.5507 28.3333 31.6667C28.3333 30.7826 28.6845 29.9348 29.3096 29.3096C29.9348 28.6845 30.7826 28.3333 31.6667 28.3333ZM31.6667 28.3333C31.6667 27.0073 31.14 25.7355 30.2024 24.7978C29.2647 23.8601 27.9929 23.3333 26.6668 23.3333H21.0002M5 5H6.66667C7.55072 5 8.39857 5.35119 9.02369 5.97631C9.64881 6.60143 10 7.44928 10 8.33333V18.3333M10 13.3333H28.3333M25 13.3333V23.3333M5 26.6667C5 28.8768 5.87797 30.9964 7.44078 32.5592C9.00358 34.122 11.1232 35 13.3333 35C15.5435 35 17.6631 34.122 19.2259 32.5592C20.7887 30.9964 21.6667 28.8768 21.6667 26.6667C21.6667 24.4565 20.7887 22.3369 19.2259 20.7741C17.6631 19.2113 15.5435 18.3333 13.3333 18.3333C11.1232 18.3333 9.00358 19.2113 7.44078 20.7741C5.87797 22.3369 5 24.4565 5 26.6667Z"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "baby-carriage" ? (
    <svg
      className={props.className}
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 8.33333H8.16667L10.8867 16.495C11.5503 18.4865 12.8239 20.2186 14.5269 21.4459C16.2298 22.6733 18.2758 23.3336 20.375 23.3333H24.8333C27.2645 23.3333 29.5961 22.3676 31.3151 20.6485C33.0342 18.9294 34 16.5978 34 14.1667C34 11.7355 33.0342 9.40394 31.3151 7.68485C29.5961 5.96577 27.2645 5 24.8333 5H24V15M10.6667 15H34M15.6667 28.3333L17.3333 23.3333M27.3333 23.3333L29 28.3333M10.6667 31.6667C10.6667 32.5507 11.0179 33.3986 11.643 34.0237C12.2681 34.6488 13.1159 35 14 35C14.8841 35 15.7319 34.6488 16.357 34.0237C16.9821 33.3986 17.3333 32.5507 17.3333 31.6667C17.3333 30.7826 16.9821 29.9348 16.357 29.3096C15.7319 28.6845 14.8841 28.3333 14 28.3333C13.1159 28.3333 12.2681 28.6845 11.643 29.3096C11.0179 29.9348 10.6667 30.7826 10.6667 31.6667ZM27.3333 31.6667C27.3333 32.5507 27.6845 33.3986 28.3096 34.0237C28.9348 34.6488 29.7826 35 30.6667 35C31.5507 35 32.3986 34.6488 33.0237 34.0237C33.6488 33.3986 34 32.5507 34 31.6667C34 30.7826 33.6488 29.9348 33.0237 29.3096C32.3986 28.6845 31.5507 28.3333 30.6667 28.3333C29.7826 28.3333 28.9348 28.6845 28.3096 29.3096C27.6845 29.9348 27.3333 30.7826 27.3333 31.6667Z"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "horse-toy" ? (
    <svg
      className={props.className}
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.16732 29.1667C15.6123 36.945 25.0557 36.945 34.5007 29.1667M32.0007 30.8333L28.6673 16.6667L30.334 13.3333L33.6673 15L36.1673 12.5L32.0007 5C23.5807 5.36333 22.0173 10.2217 20.334 15H10.334C9.0079 15 7.73613 15.5268 6.79845 16.4645C5.86077 17.4021 5.33398 18.6739 5.33398 20M8.66732 30.8333L12.0007 15M13.6673 33.3333L17.0007 25H23.6673L27.0007 33.3333"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "man" ? (
    <svg
      className={props.className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6673 26.6663V34.9997M16.6673 26.6663H23.334M16.6673 26.6663L15.0007 14.9997M23.334 26.6663V34.9997M23.334 26.6663L25.0007 14.9997M15.0007 14.9997H25.0007M15.0007 14.9997C12.779 14.9997 10.5557 16.1113 8.33398 18.333M25.0007 14.9997C27.2223 14.9997 29.4457 16.1113 31.6673 18.333M16.6673 6.66634C16.6673 7.5504 17.0185 8.39824 17.6436 9.02336C18.2688 9.64849 19.1166 9.99967 20.0007 9.99967C20.8847 9.99967 21.7326 9.64849 22.3577 9.02336C22.9828 8.39824 23.334 7.5504 23.334 6.66634C23.334 5.78229 22.9828 4.93444 22.3577 4.30932C21.7326 3.6842 20.8847 3.33301 20.0007 3.33301C19.1166 3.33301 18.2688 3.6842 17.6436 4.30932C17.0185 4.93444 16.6673 5.78229 16.6673 6.66634Z"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "paw" ? (
    <svg
      className={props.className}
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.1657 22.5003C23.3324 19.167 22.764 18.3337 20.6657 18.3337C18.5674 18.3337 17.7724 19.592 15.939 22.912C14.369 25.7503 11.1957 25.987 10.404 28.397C10.2424 28.8387 10.1624 29.5253 10.1657 30.0003C10.1657 31.9603 11.4774 33.3337 13.1657 33.3337C15.264 33.3337 18.1657 31.667 20.6657 31.667C23.1657 31.667 26.0674 33.3337 28.1657 33.3337C29.854 33.3337 31.1657 31.962 31.1657 30.0003C31.1657 29.5253 31.084 28.8387 30.9224 28.397C30.1307 25.9787 26.7357 25.3387 25.1657 22.5003Z"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.3124 13.4703C34.0983 13.3798 33.8681 13.3333 33.6357 13.3337H33.6107C32.3857 13.3537 31.0107 14.5837 30.289 16.4437C29.424 18.6687 29.8224 20.9437 31.1857 21.5303C31.4007 21.622 31.6307 21.667 31.8624 21.667C33.094 21.667 34.4874 20.4303 35.214 18.557C36.074 16.332 35.6674 14.057 34.3124 13.4703Z"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4557 15.0003C16.5474 15.0003 16.6374 15.0003 16.7274 14.982C18.3007 14.7687 19.2824 12.7387 18.9274 10.4453C18.589 8.28366 17.1824 6.66699 15.7074 6.66699C15.6157 6.66699 15.5257 6.66699 15.4357 6.68533C13.8624 6.89866 12.8807 8.92866 13.2357 11.222C13.5757 13.377 14.9824 15.0003 16.4574 15.0003H16.4557Z"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.0924 11.222C28.449 8.92866 27.4674 6.89866 25.8924 6.68533C25.8029 6.67294 25.7127 6.66681 25.6224 6.667C24.1474 6.667 22.7424 8.28366 22.4057 10.4453C22.049 12.7387 23.0307 14.7687 24.6057 14.982C24.6957 14.9937 24.7857 15.0003 24.8757 15.0003C26.3507 15.0003 27.759 13.377 28.0924 11.222Z"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1491 21.5303C11.5091 20.9437 11.9057 18.6653 11.0424 16.4437C10.3157 14.5703 8.92405 13.3337 7.69405 13.3337C7.46072 13.3337 7.23239 13.3787 7.01572 13.4703C5.65572 14.057 5.25905 16.3353 6.12239 18.557C6.84905 20.4303 8.24072 21.667 9.47072 21.667C9.70406 21.667 9.93239 21.622 10.1491 21.5303Z"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "grid-dots" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33398 4.16634C3.33398 4.38735 3.42178 4.59932 3.57806 4.7556C3.73434 4.91188 3.9463 4.99967 4.16732 4.99967C4.38833 4.99967 4.60029 4.91188 4.75657 4.7556C4.91285 4.59932 5.00065 4.38735 5.00065 4.16634C5.00065 3.94533 4.91285 3.73337 4.75657 3.57709C4.60029 3.42081 4.38833 3.33301 4.16732 3.33301C3.9463 3.33301 3.73434 3.42081 3.57806 3.57709C3.42178 3.73337 3.33398 3.94533 3.33398 4.16634Z"
        stroke="#222030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16732 4.16634C9.16732 4.38735 9.25512 4.59932 9.4114 4.7556C9.56768 4.91188 9.77964 4.99967 10.0007 4.99967C10.2217 4.99967 10.4336 4.91188 10.5899 4.7556C10.7462 4.59932 10.834 4.38735 10.834 4.16634C10.834 3.94533 10.7462 3.73337 10.5899 3.57709C10.4336 3.42081 10.2217 3.33301 10.0007 3.33301C9.77964 3.33301 9.56768 3.42081 9.4114 3.57709C9.25512 3.73337 9.16732 3.94533 9.16732 4.16634Z"
        stroke="#222030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0007 4.16634C15.0007 4.38735 15.0884 4.59932 15.2447 4.7556C15.401 4.91188 15.613 4.99967 15.834 4.99967C16.055 4.99967 16.267 4.91188 16.4232 4.7556C16.5795 4.59932 16.6673 4.38735 16.6673 4.16634C16.6673 3.94533 16.5795 3.73337 16.4232 3.57709C16.267 3.42081 16.055 3.33301 15.834 3.33301C15.613 3.33301 15.401 3.42081 15.2447 3.57709C15.0884 3.73337 15.0007 3.94533 15.0007 4.16634Z"
        stroke="#222030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33398 9.99967C3.33398 10.2207 3.42178 10.4326 3.57806 10.5889C3.73434 10.7452 3.9463 10.833 4.16732 10.833C4.38833 10.833 4.60029 10.7452 4.75657 10.5889C4.91285 10.4326 5.00065 10.2207 5.00065 9.99967C5.00065 9.77866 4.91285 9.5667 4.75657 9.41042C4.60029 9.25414 4.38833 9.16634 4.16732 9.16634C3.9463 9.16634 3.73434 9.25414 3.57806 9.41042C3.42178 9.5667 3.33398 9.77866 3.33398 9.99967Z"
        stroke="#222030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16732 9.99967C9.16732 10.2207 9.25512 10.4326 9.4114 10.5889C9.56768 10.7452 9.77964 10.833 10.0007 10.833C10.2217 10.833 10.4336 10.7452 10.5899 10.5889C10.7462 10.4326 10.834 10.2207 10.834 9.99967C10.834 9.77866 10.7462 9.5667 10.5899 9.41042C10.4336 9.25414 10.2217 9.16634 10.0007 9.16634C9.77964 9.16634 9.56768 9.25414 9.4114 9.41042C9.25512 9.5667 9.16732 9.77866 9.16732 9.99967Z"
        stroke="#222030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0007 9.99967C15.0007 10.2207 15.0884 10.4326 15.2447 10.5889C15.401 10.7452 15.613 10.833 15.834 10.833C16.055 10.833 16.267 10.7452 16.4232 10.5889C16.5795 10.4326 16.6673 10.2207 16.6673 9.99967C16.6673 9.77866 16.5795 9.5667 16.4232 9.41042C16.267 9.25414 16.055 9.16634 15.834 9.16634C15.613 9.16634 15.401 9.25414 15.2447 9.41042C15.0884 9.5667 15.0007 9.77866 15.0007 9.99967Z"
        stroke="#222030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33398 15.833C3.33398 16.054 3.42178 16.266 3.57806 16.4223C3.73434 16.5785 3.9463 16.6663 4.16732 16.6663C4.38833 16.6663 4.60029 16.5785 4.75657 16.4223C4.91285 16.266 5.00065 16.054 5.00065 15.833C5.00065 15.612 4.91285 15.4 4.75657 15.2438C4.60029 15.0875 4.38833 14.9997 4.16732 14.9997C3.9463 14.9997 3.73434 15.0875 3.57806 15.2438C3.42178 15.4 3.33398 15.612 3.33398 15.833Z"
        stroke="#222030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16732 15.833C9.16732 16.054 9.25512 16.266 9.4114 16.4223C9.56768 16.5785 9.77964 16.6663 10.0007 16.6663C10.2217 16.6663 10.4336 16.5785 10.5899 16.4223C10.7462 16.266 10.834 16.054 10.834 15.833C10.834 15.612 10.7462 15.4 10.5899 15.2438C10.4336 15.0875 10.2217 14.9997 10.0007 14.9997C9.77964 14.9997 9.56768 15.0875 9.4114 15.2438C9.25512 15.4 9.16732 15.612 9.16732 15.833Z"
        stroke="#222030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0007 15.833C15.0007 16.054 15.0884 16.266 15.2447 16.4223C15.401 16.5785 15.613 16.6663 15.834 16.6663C16.055 16.6663 16.267 16.5785 16.4232 16.4223C16.5795 16.266 16.6673 16.054 16.6673 15.833C16.6673 15.612 16.5795 15.4 16.4232 15.2438C16.267 15.0875 16.055 14.9997 15.834 14.9997C15.613 14.9997 15.401 15.0875 15.2447 15.2438C15.0884 15.4 15.0007 15.612 15.0007 15.833Z"
        stroke="#222030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "brand-x" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33398 16.6663L8.97398 11.0263M11.024 8.97634L16.6673 3.33301M3.33398 3.33301L13.1115 16.6663H16.6673L6.88982 3.33301H3.33398Z"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "brand-telegram" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 8.33301L9.16667 11.6663L14.1667 16.6663L17.5 3.33301L2.5 9.16634L5.83333 10.833L7.5 15.833L10 12.4997"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "brand-ok-ru" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.91732 10.833C9.02815 11.3888 10.9732 11.3888 12.084 10.833M7.91732 14.1663L10.0007 11.6663M10.0007 11.6663L12.084 14.1663M10.0007 11.6663V11.2497M8.33398 7.49967C8.33398 7.9417 8.50958 8.36562 8.82214 8.67819C9.1347 8.99075 9.55862 9.16634 10.0007 9.16634C10.4427 9.16634 10.8666 8.99075 11.1792 8.67819C11.4917 8.36562 11.6673 7.9417 11.6673 7.49967C11.6673 7.05765 11.4917 6.63372 11.1792 6.32116C10.8666 6.0086 10.4427 5.83301 10.0007 5.83301C9.55862 5.83301 9.1347 6.0086 8.82214 6.32116C8.50958 6.63372 8.33398 7.05765 8.33398 7.49967ZM16.6673 9.99967C16.6673 16.6663 16.6673 16.6663 10.0007 16.6663C3.33398 16.6663 3.33398 16.6663 3.33398 9.99967C3.33398 3.33301 3.33398 3.33301 10.0007 3.33301C16.6673 3.33301 16.6673 3.33301 16.6673 9.99967Z"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "brand-vk" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.666 15.8333H8.33268C6.56457 15.8333 4.86888 15.131 3.61864 13.8807C2.36839 12.6305 1.66602 10.9348 1.66602 9.16667V5H4.99935V9.16667C4.99935 10.0507 5.35054 10.8986 5.97566 11.5237C6.60078 12.1488 7.44863 12.5 8.33268 12.5V5H11.666V8.75H11.691C12.6044 8.63604 13.4446 8.19238 14.0539 7.50243C14.6631 6.81248 14.9993 5.92376 14.9993 5.00333H18.3327L18.0477 6.42917C17.877 7.28257 17.5136 8.08572 16.9853 8.77735C16.4571 9.46897 15.7778 10.0308 14.9993 10.42C15.7563 10.6722 16.4315 11.1232 16.9543 11.726C17.4771 12.3287 17.8283 13.0608 17.971 13.8458L18.3327 15.8367H14.9993C14.9993 14.9162 14.6631 14.0275 14.0539 13.3376C13.4446 12.6476 12.6044 12.204 11.691 12.09V15.84L11.666 15.8333Z"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "copy" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.34333 13.9475C3.08779 13.8018 2.87523 13.5912 2.72715 13.3371C2.57906 13.0829 2.50071 12.7942 2.5 12.5V4.16667C2.5 3.25 3.25 2.5 4.16667 2.5H12.5C13.125 2.5 13.465 2.82083 13.75 3.33333M5.83333 8.05583C5.83333 7.46639 6.06749 6.90109 6.48429 6.48429C6.90109 6.06749 7.46639 5.83333 8.05583 5.83333H15.2775C15.5694 5.83333 15.8584 5.89082 16.128 6.00251C16.3977 6.1142 16.6427 6.27791 16.849 6.48429C17.0554 6.69067 17.2191 6.93567 17.3308 7.20532C17.4425 7.47497 17.5 7.76397 17.5 8.05583V15.2775C17.5 15.5694 17.4425 15.8584 17.3308 16.128C17.2191 16.3977 17.0554 16.6427 16.849 16.849C16.6427 17.0554 16.3977 17.2191 16.128 17.3308C15.8584 17.4425 15.5694 17.5 15.2775 17.5H8.05583C7.76397 17.5 7.47497 17.4425 7.20532 17.3308C6.93567 17.2191 6.69067 17.0554 6.48429 16.849C6.27791 16.6427 6.1142 16.3977 6.00251 16.128C5.89082 15.8584 5.83333 15.5694 5.83333 15.2775V8.05583Z"
        stroke="#B0AFB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "arrow-back-up" ? (
    <svg
      className={props.className}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99984 11.6667L4.6665 8.33333M4.6665 8.33333L7.99984 5M4.6665 8.33333H13.8332C14.7172 8.33333 15.5651 8.68452 16.1902 9.30964C16.8153 9.93477 17.1665 10.7826 17.1665 11.6667C17.1665 12.5507 16.8153 13.3986 16.1902 14.0237C15.5651 14.6488 14.7172 15 13.8332 15H12.9998"
        stroke="#696775"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : props.icon === "map" ? (
    <svg
      className={props.className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 3.33301L2.5 5.83301V16.6663L7.5 14.1663M7.5 3.33301L12.5 5.83301M7.5 3.33301V14.1663M12.5 5.83301L17.5 3.33301V14.1663L12.5 16.6663M12.5 5.83301V16.6663M12.5 16.6663L7.5 14.1663"
        stroke="#696775"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    props.icon === "heart-filled" && (
      <svg
        className={props.className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.8161 2.56127C6.55365 2.4356 7.31001 2.47706 8.02941 2.68258C8.74881 2.8881 9.41291 3.25245 9.97277 3.74877L10.0036 3.77627L10.0319 3.75127C10.5663 3.28235 11.1945 2.93277 11.8746 2.72584C12.5547 2.51891 13.2712 2.4594 13.9761 2.55127L14.1811 2.58127C15.0697 2.73469 15.9002 3.12557 16.5848 3.71249C17.2694 4.29942 17.7825 5.06056 18.0698 5.9153C18.3571 6.77004 18.4079 7.68657 18.2169 8.56783C18.0258 9.44909 17.5999 10.2623 16.9844 10.9213L16.8344 11.0754L16.7944 11.1096L10.5861 17.2588C10.4428 17.4006 10.253 17.4856 10.0518 17.4982C9.85065 17.5108 9.65173 17.45 9.49194 17.3271L9.4136 17.2588L3.16944 11.0738C2.50795 10.4302 2.03752 9.616 1.81023 8.72149C1.58295 7.82698 1.60769 6.88702 1.8817 6.0057C2.15572 5.12438 2.66832 4.3361 3.36274 3.72816C4.05716 3.12022 4.90629 2.71635 5.8161 2.56127Z"
          fill="#F74439"
        />
      </svg>
    )
  );
};

export default Icons;
