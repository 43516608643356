import { useState, useMemo, useCallback } from "react";
import "../less/css/calendar.css";
import Button from "./Button";
import Icon from "ol/style/Icon";
import Icons from "./Icons";
import { useDispatch, useSelector } from "react-redux";
import { setFormCreateRaces } from "../store/actions";

// UTILITIES
// get all dates of that month
const getMonthDetail = ({ year, month }) => {
  const lastDate = new Date(year, month, 0).getDate();
  return Array(lastDate)
    .fill()
    .map((_, key) => key + 1);
};

const getDateDay = (d) => {
  return d.getDay();
};

const getTodayData = () => {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  return { year, month, date };
};

const getPrevYearMonth = ({ year, month }) => {
  const data = { year, month };
  if (month === 1) {
    data.year -= 1;
    data.month = 12;
  } else data.month -= 1;
  return data;
};

const getNextYearMonth = ({ year, month }) => {
  const data = { year, month };
  if (month === 12) {
    data.year += 1;
    data.month = 1;
  } else data.month += 1;
  return data;
};

// const week = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
const monthName = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

// COMPONENTS
const ActiveMonth = ({ year, month, todayStr, array, setActive, active }) => {
  const formCreateRaces = useSelector((state) => state.formCreateRaces);
  const dispatch = useDispatch();
  const currMonth = getMonthDetail({ year, month });
  const prevMonth = getMonthDetail(getPrevYearMonth({ year, month }));
  const nextMonth = getMonthDetail(getNextYearMonth({ year, month }));
  const currMonthLastDate = currMonth[currMonth.length - 1];
  const startDay = getDateDay(new Date(year, month - 1, 1));
  const endDay = getDateDay(new Date(year, month - 1, currMonthLastDate));
  const prevMonthAddOn = prevMonth.slice(prevMonth.length - startDay + 1);
  const nextMonthAddOn = nextMonth.slice(0, 7 - endDay);
  const visibleMonth = prevMonthAddOn
    .map((date) => ({ date, disabled: true }))
    .concat(currMonth.map((date) => ({ date, disabled: false })))
    .concat(nextMonthAddOn.map((date) => ({ date, disabled: true })));
  return (
    <div className="dates-wrapper" key={`${year}${month}`}>
      {visibleMonth.map((el, i) => {
        let count = 0;
        for (let i = 0; i < array.length; i++) {
          if (
            new Date(array[i].start_date).toLocaleDateString() ===
            new Date(year, +month - 1, el.date).toLocaleDateString()
          ) {
            count += 1;
          }
        }
        return (
          <div
            key={i}
            className={[
              "date",
              count >= 3 ? " unactive" : "",
              active?.find((q) => q === new Date(year, +month - 1, el.date).toLocaleDateString())
                ? " selected"
                : "",
              el.disabled ? " disabled" : "",
              todayStr === `${year}${month}${el.date}` ? " today" : "",
            ].join(" ")}
            onClick={() => {
              if (
                active?.find((q) => q === new Date(year, +month - 1, el.date).toLocaleDateString())
              ) {
                let arr = active;
                let a = arr.indexOf(new Date(year, +month - 1, el.date).toLocaleDateString());
                arr.splice(a, 1);
                setActive([...arr]);
                dispatch(
                  setFormCreateRaces({
                    ...formCreateRaces,
                    start_date: [...arr],
                  })
                );
              } else {
                setActive([...active, new Date(year, +month - 1, el.date).toLocaleDateString()]);
                dispatch(
                  setFormCreateRaces({
                    ...formCreateRaces,
                    start_date: [
                      ...active,
                      new Date(year, +month - 1, el.date).toLocaleDateString(),
                    ],
                  })
                );
              }
            }}
          >
            <div className={`text`}>{el.date}</div>
            <div className="dotter">
              {(count === 0 || count === 1 || count === 2) && (
                <div
                  className={`dot ${
                    count === 0 ? "green" : count === 1 ? "orange" : count === 2 && "red"
                  }`}
                />
              )}
              {(count === 0 || count === 1) && (
                <div className={`dot ${count === 0 ? "green" : count === 1 && "orange"}`} />
              )}
              {count === 0 && <div className={`dot ${count === 0 && "green"}`} />}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const MonthWrapper = ({
  goPrev,
  goNext,
  goToday,
  year,
  month,
  todayStr,
  array,
  setActive,
  active,
  formCreateRaces,
}) => {
  const dispatch = useDispatch();

  return (
    <div className="body-calendar">
      <div className="header-calendar">
        <Button
          typebtn="ghost"
          className="cube small "
          onClick={() => {
            goPrev();
          }}
        >
          <Icons icon="design-personal-chevron-left" />
        </Button>
        <span className="current" onClick={goToday}>
          {monthName[month - 1]}, {year}
        </span>
        <Button
          typebtn="ghost"
          className="cube small"
          onClick={() => {
            goNext();
          }}
        >
          <Icons icon="design-personal-chevron-right" />
        </Button>
      </div>
      <ActiveMonth
        year={year}
        month={month}
        todayStr={todayStr}
        array={array}
        setActive={setActive}
        active={active}
      />
    </div>
  );
};

const Calendar = (props) => {
  const [today, setToday] = useState(getTodayData());
  const [year, setYear] = useState(today.year);
  const [month, setMonth] = useState(today.month);
  const formCreateRaces = useSelector((state) => state.formCreateRaces);
  // const [active, setActive] = useState(formCreateRaces.start_date || []);

  const todayStr = useMemo(() => {
    return Object.values(today).join("");
  }, [today]);

  const setYearMonth = (data) => {
    setYear(data.year);
    setMonth(data.month);
  };
  const goPrev = () => {
    setYearMonth(getPrevYearMonth({ year, month }));
  };
  const goNext = () => {
    setYearMonth(getNextYearMonth({ year, month }));
  };
  const goToday = () => {
    setYearMonth(today);
  };

  return (
    <>
      <MonthWrapper
        goPrev={goPrev}
        goNext={goNext}
        goToday={goToday}
        year={year}
        month={month}
        todayStr={todayStr}
        array={props.array}
        setActive={props.setActive}
        active={props.active}
        formCreateRaces={formCreateRaces}
      />
    </>
  );
};

export default Calendar;
