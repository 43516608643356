import { useDispatch, useSelector } from "react-redux";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import {
  setArrayPointsTour,
  setCreateTour,
  setFAQ,
  setFormForCreateTour,
  setFormPointTour,
  setProducts,
  setTourFocus,
} from "../../store/actions";
import StageOneCreateTour from "./create-tours-stages/StageOneCreateTour";
import { IonContent } from "@ionic/react";
import { useHistory } from "react-router";
import StageTwoCreateTour from "./create-tours-stages/StageTwoCreateTour";
import qs from "qs";
import {
  deleteMainPhotoTour,
  deleteOtherPhotoTour,
  getClassList,
  getFAQ,
  getFormatList,
  getGenreList,
  getPartnerUsers,
  getPointTour,
  getTourRaces,
  getToursByID,
  getToursOperator,
  postAddImagesTour,
  postAddMainImagesTour,
  postCreateSuitableTour,
  postCreateTour,
  putCreateSuitableTour,
  putCreateTour,
  putFAQ,
} from "../../utils/api";
import { useEffect, useState } from "react";
import StageThreeCreateTour from "./create-tours-stages/StageThreeCreateTour";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import Status from "../../ui/Status";
import { DEFAULT_TOURS, STATUSES_TOURS } from "../../utils/array";
import { nameForm, formatDate, checkTourStatus } from "../../utils/utils";
import { menuController } from "@ionic/core/components";

const ToursCreateContent = (props) => {
  const suitable = useSelector((state) => state.suitable);
  const [loading, setLoading] = useState(false);
  const tour = useSelector((state) => state.tourFocus);
  const token = useSelector((state) => state.token);
  const createTour = useSelector((state) => state.createTour);
  const products = useSelector((state) => state.products);
  const productsForFAQ = products.filter((el) => !el.deleted_at === true);
  const [tourProgress, setTourProgress] = useState();
  const [mainPhoto, setMainPhoto] = useState();
  const [otherPhoto, setOtherPhoto] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const urlSearch = qs.parse(history.location.search.substr(1));
  useAsyncEffect(async () => {
    setTourProgress(null);
    if (urlSearch.id === undefined) return;
    getToursByID(token, urlSearch.id).then((e) => {
      dispatch(setTourFocus(e.data.data));
      setTourProgress(checkTourStatus(e.data.data));
      dispatch(setFormForCreateTour(e.data.data));
    });
    getPointTour(token, urlSearch.id).then((e) => {
      if (e.data.data) {
        dispatch(setArrayPointsTour(e.data.data));
      }
    });
    setMainPhoto(null);
    setOtherPhoto(null);
    await menuController.enable(`right-add`);
  }, []);
  const formForCreateTour = useSelector((state) => state.formForCreateTour);
  useAsyncEffect(async () => {
    let value = formForCreateTour;
    let arr = [];
    for (let i = 0; i < suitable?.length; i++) {
      let find = formForCreateTour?.suitables?.find((qe) => qe?.suitable_id === suitable[i]?.id);
      if (find) {
        arr.push({ suitable_id: suitable[i]?.id, cnt: find?.cnt });
      } else {
        arr.push({ suitable_id: suitable[i]?.id, cnt: 0 });
      }
    }
    value.suitable_arr = arr;
    await dispatch(setFormForCreateTour({ ...value }));
  }, [suitable, formForCreateTour?.id]);

  const arrayPointsTour = useSelector((state) => state.arrayPointsTour);
  const arrayTourRaces = useSelector((state) => state.arrayTourRaces);

  useAsyncEffect(() => {
    setTourProgress(checkTourStatus(formForCreateTour, arrayPointsTour));
  }, [formForCreateTour, arrayPointsTour]);

  const operator = useSelector((state) => state.userInfo.clientInfo.operator);
  const statuses = STATUSES_TOURS;
  const status = statuses.find((el) => el.status === tour.status_id);

  const [valueTextArea1, setValueTextArea1] = useState();
  const [valueTextArea2, setValueTextArea2] = useState();

  const [bodyCreate, setBodyCreate] = useState({});
  const faq = useSelector((state) => state.faq);
  // const faq = faqArray?.filter((el) => el.status_id === 1);
  const [faqForTour, setFaqForTour] = useState([]);

  useEffect(() => {
    for (let index = 0; index < faq.length; index++) {
      if (faq[index].products?.find((el) => el.id === formForCreateTour?.id)) {
        faqForTour.push(faq[index]?.id);
      }
    }
  }, [faq]);

  useAsyncEffect(async () => {
    dispatch(setFormForCreateTour(DEFAULT_TOURS));
    await dispatch(setCreateTour({ stage: 1 }));
  }, []);

  const clickSaveDraft = async () => {
    var arrayDop = {
      status_id: 1,
      currency_id: "RUB",
      operator_id: operator.id,
    };
    var array = { ...arrayDop, ...formForCreateTour };
    console.log(formForCreateTour);
    await postCreateTour(token, array)
      .then(async (e) => {
        if (e.data.errors) {
        } else {
          let id = e.data.product.id;
          let filter = formForCreateTour?.suitable_arr?.filter((qe) => qe?.cnt !== 0);
          for (let i = 0; i < filter?.length; i++) {
            let sendSuit = {
              suitable_id: filter[i].suitable_id,
              cnt: filter[i]?.cnt,
            };
            await postCreateSuitableTour(token, sendSuit, id)
              .then((x) => {})
              .catch((e) => {
                alert(e.response?.data.errors);
              });
          }
          await getToursByID(token, id).then((e) => {
            if (e.data.data) {
              dispatch(setFormForCreateTour(e.data.data));
              dispatch(setTourFocus(e.data.data));
            }
          });
          await getToursOperator(token).then((e) => {
            if (e.data.data) {
              dispatch(setProducts(e.data.data));
            }
          });
          history.push(`/partner/showcase/tours/create?id=${id}`);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const clickSave = async () => {
    let array = {
      ...formForCreateTour,
      time_sales_stop: formForCreateTour?.time_sales_stop,
    };
    delete array.btns_add;
    delete array.info;
    delete array.photo;
    delete array.pictures;
    delete array.operator_promotions;
    if (array.genres[0]?.name) {
      let arr = [];
      for (var i = 0; i < array.genres.length; i++) {
        arr.push(array.genres[i].id);
      }

      array.genres = arr;
    }
    const formDataOtherImage = new FormData();
    const formDataMainImage = new FormData();

    mainPhoto && formDataMainImage.append("product_picture", mainPhoto);

    otherPhoto &&
      Object.entries(otherPhoto).forEach(([key, value]) => {
        formDataOtherImage.append(`product_pictures[]`, value);
      });

    otherPhoto &&
      (await postAddImagesTour(token, formDataOtherImage, array.id)
        .then((e) => {
          if (!e.data.errors) {
            setOtherPhoto(null);
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        }));
    mainPhoto &&
      (await postAddMainImagesTour(token, formDataMainImage, array.id)
        .then((e) => {
          if (!e.data.errors) {
            setMainPhoto(null);
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        }));
    if (formForCreateTour?.arrdel?.length) {
      for (let i = 0; i < formForCreateTour?.arrdel?.length; i++) {
        await deleteOtherPhotoTour(
          token,
          formForCreateTour.id,
          formForCreateTour?.arrdel[i]?.id
        ).then(async (e) => {});
      }
    }

    if (formForCreateTour?.photodel) {
      await deleteMainPhotoTour(token, formForCreateTour.id).then(async (e) => {});
    }

    await putCreateTour(token, array, array.id)
      .then(async (e) => {
        if (e.data.errors) {
          setLoading(false);
        } else {
          let id = e.data.product.id;

          let filter = formForCreateTour?.suitable_arr?.filter((qe) => qe?.cnt !== 0);
          for (let i = 0; i < filter?.length; i++) {
            let sendSuit = {
              cnt: filter[i]?.cnt,
            };
            let sendCreateSuit = {
              suitable_id: filter[i].suitable_id,
              cnt: filter[i]?.cnt,
            };
            if (
              formForCreateTour?.suitables?.find((qw) => qw.suitable_id === filter[i]?.suitable_id)
            ) {
              await putCreateSuitableTour(token, sendSuit, id, filter[i]?.suitable_id)
                .then((x) => {})
                .catch((e) => {
                  alert(e.response?.data.errors);
                });
            } else {
              await postCreateSuitableTour(token, sendCreateSuit, id)
                .then((x) => {})
                .catch((e) => {
                  alert(e.response?.data.errors);
                });
            }
          }

          if (faqForTour.length) {
            for (let i = 0; i < faq?.length; i++) {
              let arrProd = [];
              for (let s = 0; s < faq[i]?.products?.length; s++) {
                i === 0 && console.log("faqArray[i]?.products", faq[i]?.products);
                faq[i]?.products[s].id
                  ? arrProd.push(faq[i]?.products[s].id)
                  : arrProd.push(faq[i]?.products[s]);
              }

              // for (let j = 0; j < faqForTour?.length; j++) {
              if (faqForTour.find((z) => z === faq[i].id)) {
                if (!faq[i].products?.find((x) => x.id === +urlSearch?.id)) {
                  arrProd.push(+urlSearch?.id);
                  let arr = {
                    response_text: faq[i]?.response_text,
                    review_text: faq[i]?.review_text,
                    status_id: arrProd?.length ? 1 : 2,
                    products: arrProd,
                    product_id: arrProd.length ? arrProd[0] : productsForFAQ[0]?.id,
                    type: 2,
                  };
                  await putFAQ(token, faq[i].id, arr).then(async (e) => {
                    if (!e.data.errors) {
                      await getFAQ(token).then((e) => {
                        dispatch(setFAQ(e.data.data));
                      });
                    }
                  });
                }
              } else {
                if (faq[i].products?.find((x) => x.id === +urlSearch?.id)) {
                  arrProd = arrProd?.filter((f) => f !== +urlSearch?.id);
                  let arr = {
                    response_text: faq[i]?.response_text,
                    review_text: faq[i]?.review_text,
                    status_id: arrProd?.length ? 1 : 2,
                    products: arrProd,
                    product_id: arrProd[0] ? arrProd[0] : productsForFAQ[0]?.id,
                    type: 2,
                  };
                  await putFAQ(token, faq[i].id, arr).then(async (e) => {
                    if (!e.data.errors) {
                      await getFAQ(token).then((e) => {
                        dispatch(setFAQ(e.data.data));
                      });
                    }
                  });
                }
              }
            }
          } else {
            console.log("НАЧАЛО АЛЬТЕР");
            for (let i = 0; i < faq?.length; i++) {
              let arrProd = [];
              for (let s = 0; s < faq[i]?.products?.length; s++) {
                i === 0 && console.log("faqArray[i]?.products", faq[i]?.products);
                faq[i]?.products[s].id
                  ? arrProd.push(faq[i]?.products[s].id)
                  : arrProd.push(faq[i]?.products[s]);
              }

              if (faq[i].products?.find((x) => x.id === +urlSearch?.id)) {
                arrProd = arrProd?.filter((f) => f !== +urlSearch?.id);

                let arr = {
                  response_text: faq[i]?.response_text,
                  review_text: faq[i]?.review_text,
                  status_id: arrProd?.length ? 1 : 2,
                  products: arrProd,
                  product_id: arrProd[0] ? arrProd[0] : productsForFAQ[0]?.id,
                  type: 2,
                };
                await putFAQ(token, faq[i].id, arr);
                // .then(async (e) => {
                //   if (!e.data.errors) {
                //     await getFAQ(token).then((e) => {
                //       dispatch(setFAQ(e.data.data));
                //     });
                //   }
                // });
              }
            }
          }

          await getToursByID(token, id).then((e) => {
            if (e.data.data) {
              dispatch(
                setFormForCreateTour({
                  ...formForCreateTour,
                  ...e.data.data,
                  arrdel: [],
                  photodel: null,
                })
              );
              dispatch(setTourFocus(e.data.data));
            }
          });

          await getToursOperator(token).then((e) => {
            if (e.data.data) {
              dispatch(setProducts(e.data.data));
            }
          });
          await getFAQ(token).then((e) => {
            dispatch(setFAQ(e.data.data));
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  return (
    <IonContent className="page">
      <div className="tourlist-page-create">
        {!!urlSearch.id ? (
          <div className="upper-line-put">
            <div className="tbr">
              <div className="cont-l">
                <Button
                  typebtn="outline"
                  className="btn-back small btn-back"
                  onClick={() => {
                    history.push("/partner/showcase/tours?page=1");
                    dispatch(setTourFocus(false));
                    dispatch(setFormForCreateTour(DEFAULT_TOURS));
                    dispatch(setArrayPointsTour([]));
                  }}
                >
                  <Icons icon="design-personal-chevron-left" className="icon-outline" />
                </Button>
                <div className="title">{tour.name}</div>
              </div>
              <div className="btns">
                {(formForCreateTour.status_id === 1 || formForCreateTour.status_id === 3) && (
                  <Button
                    disabled={loading}
                    className={`btn-save small ${
                      formForCreateTour.status_id === 2 || loading ? "disabled" : ""
                    }`}
                    loading={loading}
                    typebtn="bright"
                    onClick={() => {
                      setLoading(true);
                      clickSave();
                    }}
                  >
                    Сохранить
                  </Button>
                )}
              </div>
            </div>

            <div className="status-cont">
              <Status color={status?.color} className="status-h">
                {status?.name}
              </Status>
            </div>
            <div className="last-change">{`Последнее изменение ${formatDate(tour?.updated_at)}, ${
              tour.last_edit_user?.first_name
            } ${tour.last_edit_user?.last_name[0] || ""}.`}</div>
          </div>
        ) : (
          <div className="upper-line">
            <div className="cont-l">
              <Button
                typebtn="outline"
                className="btn-back small"
                onClick={() => {
                  history.push("/partner/showcase/tours?page=1");
                  dispatch(setTourFocus(false));
                  dispatch(setFormForCreateTour(DEFAULT_TOURS));
                  dispatch(setArrayPointsTour([]));
                }}
              >
                <Icons icon="design-personal-chevron-left" className="icon-outline" />
              </Button>
              <div className="title">Новый тур</div>
            </div>

            {createTour.stage === 1 && (
              <Button className="btn-save-draft small" typebtn="bright" onClick={clickSaveDraft}>
                Сохранить черновик
              </Button>
            )}
          </div>
        )}
        <div className="stage">
          <div
            className={`item ${createTour.stage === 1 ? "active" : ""}`}
            onClick={() => createTour.stage !== 1 && dispatch(setCreateTour({ stage: 1 }))}
          >
            <Icons
              icon={`${tourProgress?.one ? `circle-check-filled` : `design-personal-settings`}`}
              className={`${
                tourProgress?.one ? "" : createTour.stage === 1 ? "icon-outline" : "icon-unfilled"
              } icon-stage`}
            />
            <div className="info-item">
              <div className="text">Параметры</div>
              {tourProgress?.one ? (
                <div className="muted green">Заполнено</div>
              ) : (
                <div className="muted">Не заполнено</div>
              )}
            </div>
          </div>
          <div
            className={`item ${createTour.stage === 2 ? "active" : ""}`}
            onClick={() =>
              createTour.stage !== 2 && urlSearch.id && dispatch(setCreateTour({ stage: 2 }))
            }
          >
            <Icons
              icon={`${tourProgress?.two ? `circle-check-filled` : `notes`}`}
              className={`${
                tourProgress?.two ? "" : createTour.stage === 2 ? "icon-outline" : "icon-unfilled"
              } icon-stage`}
            />
            <div className="info-item">
              <div className="text">Описание</div>
              {tourProgress?.two ? (
                <div className="muted green">Заполнено</div>
              ) : (
                <div className="muted">Не заполнено</div>
              )}
            </div>
          </div>
          <div
            className={`item ${createTour.stage === 3 ? "active" : ""}`}
            onClick={() =>
              createTour.stage !== 3 && urlSearch.id && dispatch(setCreateTour({ stage: 3 }))
            }
          >
            <Icons
              icon={`${tourProgress?.three ? `circle-check-filled` : `route`}`}
              className={`${
                tourProgress?.three ? "" : createTour.stage === 3 ? "icon-outline" : "icon-unfilled"
              } icon-stage`}
            />
            <div className="info-item">
              <div className="text">Маршрут</div>
              {tourProgress?.three ? (
                <div className="muted green">Заполнено</div>
              ) : (
                <div className="muted">Не заполнено</div>
              )}
            </div>
          </div>
        </div>
        {createTour.stage === 1 && <StageOneCreateTour arrForCreate={setBodyCreate} />}
        {createTour.stage === 2 && (
          <StageTwoCreateTour
            faq={faq}
            faqForTour={faqForTour}
            setFaqForTour={setFaqForTour}
            valueTextArea1={valueTextArea1}
            valueTextArea2={valueTextArea2}
            setValueTextArea1={setValueTextArea1}
            setValueTextArea2={setValueTextArea2}
            mainPhoto={mainPhoto}
            otherPhoto={otherPhoto}
            setOtherPhoto={setOtherPhoto}
            setMainPhoto={setMainPhoto}
          />
        )}
        {createTour.stage === 3 && <StageThreeCreateTour />}
      </div>
    </IonContent>
  );
};

export default ToursCreateContent;
