import { IonContent } from "@ionic/react";
import "../../less/css/finance.css";
import AlertBox from "../../ui/AlertBox";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import ban from "../../image/finance-reports-item/ban.svg";
import checklist from "../../image/finance-reports-item/checklist.svg";
import thumbUp from "../../image/finance-reports-item/thumbUp.svg";
import wallet from "../../image/finance-reports-item/wallet.svg";
import { useState } from "react";
import { useSelector } from "react-redux";
import ExcelJS from "exceljs";

const ReportsContent = () => {
  const arrReviewsQuest = useSelector((state) => state.reviews);
  const ordersArray = useSelector((state) => state.orders);
  ordersArray.forEach((el) => {
    el.name = el?.product?.name;
  });
  const orders = ordersArray?.filter((el) => el.status_id !== 7);
  const operations = useSelector((state) => state.operations);
  const operationsPenalty = operations.filter((el) => el.operation_type_id === 4);
  const reviews = arrReviewsQuest?.filter((el) => el.type === 0 && !el.parent_id);

  const exportToExcel = (type) => {
    let array = [];
    let filename = `Таблица`;
    if (type === 1) {
      filename = `Таблица торговых предложений ${new Date().toLocaleDateString("ru")}`;
      for (let i = 0; i < orders?.length; i++) {
        let operator_options = "";
        for (let j = 0; j < orders[i].operator_options.length; j++) {
          operator_options += `${orders[i].operator_options[j]?.option?.name}, `;
        }
        let forPay = (+orders[i]?.summa / 100) * (100 - +orders[i]?.commission_value);
        let new_array = {
          Номер: orders[i]?.id,
          "Дата создания заказа": orders[i]?.created_at,
          Клиент: `${orders[i]?.client?.user?.last_name || ""} ${
            orders[i]?.client?.user?.first_name || ""
          } ${orders[i]?.client?.user?.middle_name || ""}`,
          "Название тура": orders[i]?.product?.name,
          "Начало заезда": orders[i]?.race.start_date,
          "Завершение заезда": orders[i]?.race.end_date,
          Оплачено: +orders[i]?.summa,
          Комиссия: +orders[i].commission_value,
          Штраф: +orders[i]?.penalty[0]?.penalty || 0,
          "К выплате": forPay <= 0 ? 0 : forPay,
          "Количество участников": orders[i]?.participants.length,
          "Стоимость тура": +orders[i]?.summa,
          Опции: operator_options,
          "Кто отменил": `${orders?.cancel_user?.last_name || ""} ${
            orders?.cancel_user?.first_name || ""
          } ${orders?.cancel_user?.middle_name || ""}`,
          Статус: orders[i]?.status.description,
        };
        array.push(new_array);
      }
    } else if (type === 2) {
      filename = `Таблица штрафов ${new Date().toLocaleDateString("ru")}`;

      for (let i = 0; i < operationsPenalty?.length; i++) {
        let new_array = {
          Номер: operationsPenalty[i]?.id,
          "Дата создания операции": operationsPenalty[i]?.created_at,
          "Тип операции": operationsPenalty[i]?.type.name,
          Сумма: +operationsPenalty[i]?.summa,
          Статус: operationsPenalty[i]?.status.description,
        };
        array.push(new_array);
      }
    } else if (type === 3) {
      filename = `Таблица реализации предложений
      ${new Date().toLocaleDateString("ru")}`;

      for (let i = 0; i < operations?.length; i++) {
        let new_array = {
          Номер: operations[i]?.id,
          "Дата создания операции": operations[i]?.created_at,
          "Тип операции": operations[i]?.type.name,
          Сумма: +operations[i]?.summa,
          Статус: operations[i]?.status.description,
        };
        array.push(new_array);
      }
    } else if (type === 4) {
      filename = `Таблица контроля качества
      ${new Date().toLocaleDateString("ru")}`;
      for (let i = 0; i < reviews?.length; i++) {
        let status =
          reviews[i]?.status_id === 1 ? "Без ответа" : reviews[i]?.status_id === 2 && "Обработан";
        let new_array = {
          Номер: reviews[i]?.id,
          "Дата создания": reviews[i]?.created_at,
          "Оценка тура": +reviews[i]?.tour_assessment,
          "Название тура": reviews[i]?.product.name,
          Автор: `${reviews[i]?.user?.last_name} ${reviews[i]?.user.first_name} ${
            reviews[i]?.user.middle_name ?? ""
          }`,
          "Телефон автора": reviews[i]?.user?.phone,
          "Количество фото": reviews[i]?.pictures.length,
          "Текст отзыва": reviews[i]?.review_text,
          "Дата ответа": reviews[i]?.response_date,
          "Текст ответа": reviews[i]?.response_text,
          Статус: status,
        };
        array.push(new_array);
      }
    }
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("User Data");
    const headerRow = worksheet.addRow([]);
    const headerCells = Object.values(array);
    for (let i = 0; i < headerCells?.length; i++) {
      const column = Object.keys(headerCells[i]);
      for (let j = 0; j < column?.length; j++) {
        headerRow.getCell(j + 1).value = column[j];
      }
    }
    const rows = Object.values(array);
    for (let i = 0; i < rows?.length; i++) {
      const cells = Object.values(rows[i]);
      const rowData = [];
      for (let j = 0; j < cells.length; j++) {
        rowData.push(cells[j]);
      }
      worksheet.addRow(rowData);
      worksheet.columns.forEach(function (column, i) {
        let maxLength = 0;
        column["eachCell"]({ includeEmpty: true }, function (cell) {
          var columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
      });
    }
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
      });
      var link = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = link;
      a.download = `${filename}.xlsx`;
      a.click();
      // window.location = link;
      // saveAs(blob, `output.xlsx`);
    });
  };
  return (
    <IonContent className="page">
      <div className="reports-finance-page">
        <div className="title">Отчёты</div>
        {/* {openAlert && (
          <AlertBox
            type="info"
            text="Отчёты формируются несколько минут и будут отправлены на email"
            onClick={() => setOpenAlert(false)}
          />
        )} */}
        <div className="cont-items">
          <div className="item">
            <div className="text-cont">
              <img src={checklist} className="image-text" />
              <div className="title">Выкуп торговых предложений</div>
              <div className="text">Описание отчёта в табличном виде по информации о заказах.</div>
            </div>
            <Button
              typebtn="outline"
              className="small btn-req"
              onClick={() => {
                exportToExcel(1);
              }}
            >
              Запросить
              <Icons icon="arrow-narrow-right" />
            </Button>
          </div>
          <div className="item">
            <div className="text-cont">
              <img src={ban} className="image-text" />
              <div className="title">Штрафы</div>
              <div className="text">Описание отчёта в табличном виде по информации о заказах.</div>
            </div>
            <Button
              typebtn="outline"
              className="small btn-req"
              onClick={() => {
                exportToExcel(2);
              }}
            >
              Запросить
              <Icons icon="arrow-narrow-right" />
            </Button>
          </div>
          <div className="item">
            <div className="text-cont">
              <img src={wallet} className="image-text" />
              <div className="title">Реализация предложений</div>
              <div className="text">Описание отчёта в табличном виде по информации о заказах.</div>
            </div>
            <Button
              typebtn="outline"
              className="small btn-req"
              onClick={() => {
                exportToExcel(3);
              }}
            >
              Запросить
              <Icons icon="arrow-narrow-right" />
            </Button>
          </div>
          <div className="item">
            <div className="text-cont">
              <img src={thumbUp} className="image-text" />
              <div className="title">Контроль качества</div>
              <div className="text">Описание отчёта в табличном виде по информации о заказах.</div>
            </div>
            <Button
              typebtn="outline"
              className="small btn-req"
              onClick={() => {
                exportToExcel(4);
              }}
            >
              Запросить
              <Icons icon="arrow-narrow-right" />
            </Button>
          </div>
        </div>
      </div>
    </IonContent>
  );
};

export default ReportsContent;
