import { IonMenu, IonMenuToggle, IonPopover } from "@ionic/react";
import { useEffect, useMemo, useState } from "react";
import Calendar from "../../ui/Calendar";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../ui/Input";
import { useHistory } from "react-router";
import { setArrayTourRaces, setFormCreateRaces, setTourFocus } from "../../store/actions";
import Button from "../../ui/Button";
import qs from "qs";
import Icon from "ol/style/Icon";
import Icons from "../../ui/Icons";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { AutoComplete } from "primereact/autocomplete";
import AutoCompleteSelect from "../../ui/AutoCompleteSelect";
import Checkbox from "../../ui/Checkbox";
import noImage from "../../image/no-image-s.svg";
import { getTourRaces, getToursByID, postTourRaces } from "../../utils/api";
import ModalCreateOptions from "./Modals/ModalCreateOptions";
import { URL_ORIGIN } from "../../utils/config";

const RightMenuRaces = (props) => {
  const token = useSelector((state) => state.token);
  const history = useHistory();
  const dispatch = useDispatch();
  const arrayTourRaces = useSelector((state) => state.arrayTourRaces);
  const options = useSelector((state) => state.options);
  const urlSearch = qs.parse(history.location.search.substr(1));
  const [openPopover, setOpenPopover] = useState();
  const [arrOptions, setArrOptions] = useState([]);
  const [openModalCreateOption, setOpenModalCreateOption] = useState(false);
  const formCreateRaces = useSelector((state) => state.formCreateRaces);
  const [active, setActive] = useState(formCreateRaces.start_date || []);
  const tour = useSelector((state) => state.tourFocus);
  const [page, setPage] = useState(1);
  const [activeTabs, setActiveTabs] = useState(1);

  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());

    date.setDate(date.getDate());

    const dates = [];

    while (date <= endDate) {
      dates.push(new Date(date).toLocaleDateString("ru"));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  useAsyncEffect(() => {
    if (formCreateRaces.period_start && formCreateRaces.period_end) {
      const d1 = new Date(formCreateRaces.period_start);
      const d2 = new Date(formCreateRaces.period_end);
      dispatch(setFormCreateRaces({ ...formCreateRaces, start_date: getDatesInRange(d1, d2) }));
    } else {
      dispatch(setFormCreateRaces({ ...formCreateRaces, start_date: [] }));
    }
  }, [formCreateRaces.period_start, formCreateRaces.period_end]);

  useAsyncEffect(() => {
    dispatch(setFormCreateRaces({ ...formCreateRaces, start_date: null }));
  }, [activeTabs]);

  const onSave = () => {
    let arrEl = formCreateRaces;
    let arrOpt = arrOptions;
    let forRequest;
    let arrOptionsID = [];
    for (let i = 0; i < arrOpt.length; i++) {
      arrOptionsID.push(arrOpt[i]?.id);
    }
    for (let i = 0; i < arrEl.start_date.length; i++) {
      let date = arrEl.start_date[i]?.split(".");
      // let new_date = `${date[1]}.${date[0]}.${date[2]}`;
      let end =
        new Date(+date[2], +date[1] - 1, +date[0]).getTime() +
        tour.duration.number_nights * 24 * 60 * 60 * 1000;
      forRequest = {
        number_travelers: formCreateRaces.available_places || tour?.available_places,
        summa: formCreateRaces.summa || tour?.summa,
        currency_id: "RUB",
        start_date: new Date(+date[2], +date[1] - 1, +date[0]).toLocaleDateString("ru"),
        end_date: new Date(end).toLocaleDateString("ru"),
        operator_options: arrOptionsID,
      };
      postTourRaces(token, tour.id, forRequest).then((e) => {
        if (!e.data.errors) {
          getTourRaces(token, tour.id).then((e) => {
            if (e.data.data) {
              dispatch(setArrayTourRaces(e.data.data));
            }
          });
          getToursByID(token, tour.id).then((e) => {
            dispatch(setTourFocus(e.data.data));
          });
        }
      });
    }
  };

  return (
    <>
      <IonMenu
        // ref={refMenu}
        side="end"
        type="overlay"
        swipeGesture={false}
        menuId="menu-right-add-races"
        contentId="right-add"
        className="menu-right-add-races"
        onIonDidClose={async (e) => {
          dispatch(setFormCreateRaces([]));
          setActiveTabs(1);
          setArrOptions([]);
          setPage(1);
          setActive([]);
        }}
      >
        {page === 1 && (
          <>
            <div className="cont-menu-right-add-races">
              <div className="cont-title">
                <div className="title">Добавить даты</div>
                <div className="subtext">
                  Выберите даты начала тура для продажи. Даты завершения подставятся автоматически
                  из формата тура.
                </div>
              </div>
              <div className="l" />
              <div className="stages">
                <div
                  className={`stage ${activeTabs === 1 ? "active" : ""}`}
                  onClick={() => {
                    setActiveTabs(1), setActive([]);
                  }}
                >
                  <div className="text-stage">Вручную</div>
                </div>
                <div
                  className={`stage ${activeTabs === 2 ? "active" : ""}`}
                  onClick={() => {
                    setActiveTabs(2), setActive([]);
                  }}
                >
                  <div className="text-stage">Период</div>
                </div>
              </div>
              {/* <Calendar className="calendar " value={date} onChange={(e) => setDate(e.value)} inline /> */}

              {activeTabs === 1 ? (
                <Calendar setActive={setActive} active={active} array={arrayTourRaces} />
              ) : (
                activeTabs === 2 && (
                  <div className="date-cont">
                    <Input
                      type="date"
                      value={new Date(formCreateRaces.period_start).toLocaleDateString()}
                      placeholder="от"
                      className="medium"
                      noImage
                      onChange={(e) => {
                        dispatch(setFormCreateRaces({ ...formCreateRaces, period_start: e }));
                      }}
                    />
                    <Input
                      type="date"
                      value={new Date(formCreateRaces.period_end).toLocaleDateString()}
                      placeholder="до"
                      className="medium"
                      noImage
                      onChange={(e) => {
                        dispatch(setFormCreateRaces({ ...formCreateRaces, period_end: e }));
                      }}
                    />
                  </div>
                )
              )}
              <div className="num-dates">{`ВЫБРАНО ДНЕЙ: ${
                formCreateRaces.start_date ? formCreateRaces.start_date.length : "0"
              }`}</div>
              <div className="l" />
              <div className="subtext">
                По умолчанию использутся базовые значения из настроек тура. Можно переопределить
                значения для выбранных дней — тогда они больше не будут зависеть от базовых
                значений.
              </div>
              <div className="cont-inputs">
                <div className="cont">
                  <div className="title">Цена</div>
                  <Input
                    value={formCreateRaces.summa}
                    placeholder={`${tour?.summa?.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      " "
                    )} (по умолч.)`}
                    className="medium"
                    noImage
                    onChange={(e) => {
                      dispatch(setFormCreateRaces({ ...formCreateRaces, summa: e }));
                    }}
                  />
                  <div className="subtext">Укажите цену на одно место для выбранных дат.</div>
                </div>
                <div className="cont">
                  <div className="title">Мест</div>
                  <Input
                    value={formCreateRaces.available_places}
                    placeholder={`${tour?.available_places} (по умолч.)`}
                    className="medium"
                    noImage
                    onChange={(e) => {
                      dispatch(setFormCreateRaces({ ...formCreateRaces, available_places: e }));
                    }}
                  />
                  <div className="subtext">
                    Место соответствует заказу на конкретную дату. Одно место может включать
                    несколько человек, максимум определяется на странице Параметры.
                  </div>
                </div>
              </div>
            </div>
            <div className="btns">
              <IonMenuToggle menu="menu-right-add-races">
                <Button typebtn="outline" className="small">
                  Отмена
                </Button>
              </IonMenuToggle>
              <Button
                disabled={
                  activeTabs === 1
                    ? formCreateRaces.start_date
                      ? !formCreateRaces.start_date.length
                      : !formCreateRaces.start_date
                    : !formCreateRaces.period_end || !formCreateRaces.period_start
                }
                typebtn="muted"
                className={`small ${!formCreateRaces.start_date ? "disabled" : ""} `}
                onClick={() => setPage(2)}
              >
                Далее
                <Icons icon="arrow-narrow-right" />
              </Button>
            </div>
          </>
        )}
        {page === 2 && (
          <>
            <div className="cont-menu-right-add-races">
              <div className="cont-title">
                <div className="title">Добавление опций</div>
                <div className="subtext">
                  Выберите опции из справочника или создайте новые. После добавления опций, можно
                  поменять значения стоимости и количества мест, либо заранее измените элемент
                  справочника.
                </div>
              </div>
              <div className="l" />
              <div className="sel-options">
                <Input
                  array={options}
                  type="select-checkbox"
                  placeholder="Выберите опцию..."
                  className="small"
                  image="chevron-down"
                  onSelect={setArrOptions}
                  select={arrOptions}
                />
                <Button
                  typebtn="muted"
                  className="small"
                  onClick={() => setOpenModalCreateOption(true)}
                >
                  <Icons icon="plus" />
                  Создать
                </Button>
              </div>

              <div className="options">
                {arrOptions?.map((el, i) => {
                  return (
                    <div key={i} className="cont-opt">
                      <div className="cont-image">
                        <img
                          src={
                            el.pictures.length ? `${URL_ORIGIN}${el.pictures?.[0]?.photo}` : noImage
                          }
                        />
                      </div>
                      <div className="cont-inf">
                        <div className="text">{el.name}</div>
                        <div className="subtext">{`Цена: ${String(+el.basic_cost)?.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          " "
                        )} ₽`}</div>
                        <div className="subtext">{`Мест: ${+el.basic_volume}`}</div>
                      </div>
                      <Button
                        id={`bottom-start-${i}`}
                        typebtn="ghost"
                        className="small cube btn-dot"
                        onClick={() => {
                          setOpenPopover({
                            index: i,
                          });
                        }}
                      >
                        <Icons icon="dots-vertical" />
                      </Button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div id={`null`} />
            <div className="btns">
              <Button typebtn="outline" className="small" onClick={() => setPage(1)}>
                <Icons icon="arrow-narrow-left" />
                Назад
              </Button>
              <IonMenuToggle menu="menu-right-add-races">
                <Button
                  typebtn="bright"
                  className="small"
                  onClick={() => {
                    onSave();
                  }}
                >
                  Сохранить
                </Button>
              </IonMenuToggle>
            </div>
          </>
        )}
      </IonMenu>
      <ModalCreateOptions onClose={setOpenModalCreateOption} open={openModalCreateOption} />
      <IonPopover
        class="popover-menu-el-list"
        isOpen={openPopover?.index || (openPopover?.index === 0 && true)}
        onWillDismiss={() => setOpenPopover(false)}
        trigger={openPopover !== false ? `bottom-start-${openPopover?.index}` : undefined}
        side="bottom"
        alignment="start"
      >
        <div className="body-popover">
          <div
            className="cont-el-pop"
            onClick={() => {
              setOpenPopover(false);
              let arr = arrOptions;
              let el = arrOptions[openPopover?.index];
              arr.splice(openPopover.index, 1);
              setArrOptions(arr);
            }}
          >
            <Icons icon="design-personal-trash" />
            <div className="text">Удалить</div>
          </div>
        </div>
      </IonPopover>
    </>
  );
};

export default RightMenuRaces;
