import { useEffect, useMemo, useState } from "react";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import Rating from "../../ui/Rating";
import Badge from "../../ui/Badge";
import Clock from "../../image/image-quick-view/clock.svg";
import Coffee from "../../image/image-quick-view/coffee.svg";
import Wallet from "../../image/image-quick-view/wallet.svg";
import Vopros from "../../image/image-quick-view/vopros.svg";
import flag from "../../image/icon-timeline/flag-filled.svg";
import bed from "../../image/icon-timeline/bed-filled.svg";
import star from "../../image/icon-timeline/star-filled.svg";
import SelectButton from "../../ui/SelectButton";
import avatar from "../../image/avatar.svg";
import Select from "../../ui/Select";
import AlertBox from "../../ui/AlertBox";
import { Timeline } from "primereact/timeline";
import Status from "../../ui/Status";
import { useDispatch, useSelector } from "react-redux";
import {
  setArrayPointsTour,
  setModalLogin,
  setOpenQuickView,
  setProductsClients,
} from "../../store/actions";
import { useHistory, useLocation } from "react-router";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import {
  getProductsClient,
  getProductsClientByID,
  getProductsGuest,
  getProductsGuestByID,
  postFavorite,
} from "../../utils/api";
import { IonPopover } from "@ionic/react";
import qs from "qs";
import Toggle from "../../ui/Toggle";
import cornerLeftUp from "../../image/icons/corner-left-up.svg";
import { countProm, toms } from "../../utils/utils";
import { URL_ORIGIN } from "../../utils/config";
import Toast from "../../ui/Toast";

const QuickViewTour = (props) => {
  const dispatch = useDispatch();
  const [openPopover, setOpenPopover] = useState(false);
  const [arrOptions, setArrOptions] = useState([]);
  const [arrOptionsCost, setArrOptionsCost] = useState(0);
  const history = useHistory();

  const urlSearch = qs.parse(history.location.search.substr(1));
  const token = useSelector((state) => state.token);
  const [activeTabs, setActiveTabs] = useState(0);
  const [stage, setStage] = useState(0);
  const [dateRange, setDateRange] = useState();
  const element = useSelector((state) => state.openQuickView.el);

  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });

  // const element = useMemo(() => props.element.el, [props.element]);
  const reviews = element?.clients_reviews.filter((el, index) => el.type === 0 && !el.parent_id);
  const details = element?.details?.sort(function (a, b) {
    return a.order - b.order;
  });
  const pointStart = details?.find((el) => el?.point_type_id === 1);
  const filtDet = details?.filter((el, index) => el?.point_type_id !== 1);
  let races = [];

  useEffect(() => {
    setStage(0);
    setDateRange(0);
    setActiveTabs(0);
  }, [element]);

  useAsyncEffect(() => {
    if (token) {
      getProductsClientByID(token, urlSearch?.id)
        .then((e) => {
          if (!e.data.errors) {
            dispatch(setArrayPointsTour(e.data.data.details));
          }
        })
        .catch((e) => {
          console.log("quick CARCH client", e.response);
        });
    } else {
      getProductsGuestByID(urlSearch?.id).then((e) => {
        if (!e.data.errors) {
          dispatch(setArrayPointsTour(e.data.data.details));
        }
      });
    }
  }, [element]);

  element.races.forEach((e) => {
    let filter = element.details?.filter((el) => el?.point_type_id === 1);
    let sort = filter?.sort(function (a, b) {
      return a.order - b.order;
    })[0];
    let slice;
    if (sort?.collect_time) {
      slice = sort?.collect_time?.slice(0, 5);
    } else {
      slice = "23:59";
    }

    let range = `${new Date(e.start_date).toLocaleDateString("ru", { day: "numeric" })} ${new Date(
      e.start_date
    ).toLocaleDateString("ru", { month: "short" })} — ${new Date(e.end_date).toLocaleDateString(
      "ru",
      { day: "numeric" }
    )} ${new Date(e.end_date).toLocaleDateString("ru", { month: "short" })}`;
    if (
      e.number_travelers > e.paid_travelers_count &&
      new Date(e.start_date).getTime() + toms(slice) > new Date().getTime() &&
      !e.deleted_at
    ) {
      races.push({ ...e, range: range });
    }
  });

  let today = new Date().getTime();

  races = races?.filter((e) => today <= new Date(e.end_date).getTime());
  console.log(races);

  details.filter((e, i) => i === 0);

  const [sortRev, setSortRev] = useState({ name: "Сначала новые" });
  const [adults, setAdulds] = useState(1);
  const [children, setChildren] = useState(0);
  const [babies, setBabies] = useState(0);

  const convertStringToHTML = (htmlString) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, "text/html").body.childNodes;
    return html;
  };

  useEffect(() => {
    const description = convertStringToHTML(element?.description);
    for (let node of description) {
      element?.description && document.getElementById("description")?.appendChild(node);
    }
  }, [element?.description, stage]);

  const arrStages = [
    { name: "Инфо" },
    { name: "Маршрут", val: details?.length },
    { name: "Отзывы", val: reviews?.length },
    { name: "Купить" },
  ];

  const menu = [
    {
      name: "Скопировать ссылку",
      id: 1,
      icon: "copy",
    },
    {
      name: "Вконтакте",
      id: 2,
      icon: "brand-vk",
      href: `https://vk.com/share.php?url=${URL_ORIGIN}tour?id=${urlSearch?.id}"`,
    },
    {
      name: "Одноклассники",
      id: 3,
      icon: "brand-ok-ru",
      href: `https://connect.ok.ru/offer?url=${URL_ORIGIN}tour?id=${urlSearch?.id}&description=тур: "${element?.name}"`,
    },
    {
      name: "Telegram",
      id: 4,
      icon: "brand-telegram",
      href: `https://t.me/share/url?url=${URL_ORIGIN}tour?id=${urlSearch?.id}&text=Сервис автопутешествий Смородина, тур: "${element?.name}"`,
    },
    {
      name: "X (Twitter)",
      id: 5,
      icon: "brand-x",
      href: `https://twitter.com/share?url=${URL_ORIGIN}tour?id=${urlSearch?.id}&text=тур: "${element?.name}"`,
    },
  ];

  const calcDate = (el, response_date) => {
    let response = new Date(response_date).toLocaleDateString("ru");
    let date;
    if (el == 0) {
      date = "Сегодня";
    } else if (el == 1) {
      date = "Вчера";
    } else if (el > 1 && el < 7) {
      date = `${el} ${el > 1 && el < 5 ? "дня" : "дней"} назад`;
    } else if (el >= 7 && el < 14) {
      date = "Неделю назад";
    } else if (el >= 14 && el < 30) {
      date = "2 недели назад";
    } else if (el >= 30 && el < 60) {
      date = "Месяц назад";
    } else if (el >= 60 && el < 90) {
      date = "2 месяца назад";
    } else if (el > 90) {
      date = response;
    }
    return date;
  };
  const [active, setActive] = useState([]);

  const arrSelectRev = [{ name: "Сначала новые" }, { name: "Обработанные" }];
  const customizedContent = (item, index) => {
    const image = document?.getElementsByClassName("icon-info-details");
    const info = document?.getElementsByClassName("cont-reviews-details");
    return (
      <div className="cont-info-details">
        <Icons className="icon-info-details icon-outline" icon="chevron-down" />
        <div className="info-cont">
          <div
            className="cont-title"
            onClick={() => {
              if (image[index].classList.contains("active")) {
                image[index].classList.remove("active");
              } else {
                image[index].classList.add("active");
              }
              if (info[index].classList.contains("hide")) {
                info[index].classList.remove("hide");
              } else {
                info[index].classList.add("hide");
              }
            }}
          >
            <div className="title">{item.name}</div>
            {item.collect_time && <div className="collect-time">{item.collect_time}</div>}
          </div>
          {/* {active  && <Status color="gray">{`День ${index + 1}`}</Status>} */}
        </div>
        <div className="cont-reviews-details hide">
          <div className="text-reviews-details">{item?.description}</div>
          <div className="cont-images">
            {item?.pictures?.map((q, i) => {
              return (
                <div key={i} className="image">
                  <img src={`${URL_ORIGIN}${q.photo}`} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const customizedMarker = (item) => {
    return (
      <span className="span">
        {item.point_type_id === 1 ? (
          <img src={flag} />
        ) : item.point_type_id === 2 ? (
          <img src={bed} />
        ) : (
          item.point_type_id === 3 && <img src={star} />
        )}
      </span>
    );
  };

  let arrayProm = element?.operator_promotions?.filter((e) => e.type == 0);
  arrayProm = arrayProm?.filter(
    (e) => today >= new Date(e.start_date).getTime() && today <= new Date(e.endDate).getTime()
  );

  let arrayPromDataRange = element?.operator_promotions?.filter((e) => e.type == 0);
  arrayPromDataRange = arrayPromDataRange?.filter(
    (e) =>
      new Date(dateRange?.start_date).getTime() >= new Date(e.start_date).getTime() &&
      new Date(dateRange?.start_date).getTime() <= new Date(e.end_date).getTime()
  );

  // console.log("element?.operator_promotions", element?.operator_promotions);

  // console.log("arrOptionsCost", arrOptionsCost);

  return (
    <div className="cont-quick-show">
      <div className="header-quick">
        <div className="name">
          <span className="title-header-quick">{element?.name}</span>
        </div>
        <div className="stages">
          {arrStages?.map((el, i) => {
            return (
              <div
                key={i}
                className={`el-tabs ${activeTabs === i ? `active-tabs` : ""}`}
                onClick={() => {
                  setStage(i);
                  setActiveTabs(i);
                }}
              >
                <div className="text-tabs">{el.name}</div>
                {(el.val || el.val === 0) && (
                  <div className="value-tabs">{el.val ? el.val : "0"}</div>
                )}
              </div>
            );
          })}
        </div>

        <Button
          typebtn="ghost"
          className="close small cube"
          onClick={async () => {
            await props.mapsC.remove();
            await props.setMapsC(null);
            let data = urlSearch;
            if (data?.id) delete data.id;
            const params = new URLSearchParams(data);
            history.push(`?${params.toString()}`);
            // history.push("/");
            dispatch(setOpenQuickView({ open: false, el: null }));
          }}
        >
          <Icons icon="x" />
        </Button>
      </div>
      {stage === 0 ? (
        <div className="info-cont">
          <div className="scroll-quick">
            <div className="btns">
              {/* {console.log("ELEMENT QUICK", element)} */}
              {element?.favorite?.[0]?.favorite ? (
                <Button
                  typebtn="ghost"
                  className="small btn-quick-show favorite"
                  onClick={() => {
                    if (token) {
                      postFavorite(token, urlSearch?.id, "delete").then(async (e) => {
                        if (!e.data.errors) {
                          await getProductsClientByID(token, urlSearch?.id).then((e) => {
                            if (!e.data.errors) {
                              dispatch(setOpenQuickView({ open: true, el: e.data.data }));
                            }
                          });
                          await getProductsClient(token).then((e) => {
                            dispatch(setProductsClients(e.data.data));
                          });
                        }
                      });
                    } else {
                      dispatch(setModalLogin({ open: true }));
                    }
                  }}
                >
                  <Icons icon="heart-filled" />В избранном
                </Button>
              ) : (
                <Button
                  typebtn="ghost"
                  className="small btn-quick-show"
                  onClick={() => {
                    if (token) {
                      postFavorite(token, urlSearch?.id, "add").then(async (e) => {
                        if (!e.data.errors) {
                          await getProductsClientByID(token, urlSearch?.id).then((e) => {
                            if (!e.data.errors) {
                              dispatch(setOpenQuickView({ open: true, el: e.data.data }));
                            }
                          });

                          await getProductsClient(token).then((e) => {
                            dispatch(setProductsClients(e.data.data));
                          });
                        }
                      });
                    } else {
                      dispatch(setModalLogin({ open: true }));
                    }
                  }}
                >
                  <Icons icon="heart-plus" />В избранное
                </Button>
              )}
              <Button
                typebtn="ghost"
                className="small btn-quick-show new-window"
                onClick={async () => {
                  // await props.mapsC.remove();
                  // await props.setMapsC(null);
                  // history.push(`/tour?id=${element?.id}`);
                  window.open(`${window.location.origin}/tour?id=${element?.id}`);
                }}
              >
                <Icons icon="external-link" />В новом окне
              </Button>
              <Button
                typebtn="ghost"
                className="small btn-quick-show"
                id="bottom-start-d"
                onClick={() => setOpenPopover(true)}
              >
                <Icons icon="share" />
                Поделиться
              </Button>
            </div>
            <div className="l"></div>
            <div className="title-cont">
              <div className="rate">
                <Rating rate={element?.rating[0]?.avg ? element?.rating[0]?.avg : "0"} />
                <div className="val-rate">
                  {element?.rating[0]?.avg ? element?.rating[0]?.avg : "0"}
                </div>
                <div className="reviews">{`${reviews?.length} отзывов`}</div>
              </div>
              <div className="title">{element?.name}</div>
              <div className="genres-cont">
                {element?.genres.map((el, i) => {
                  return <Badge key={i}>{el.name}</Badge>;
                })}
              </div>
            </div>
            <div className="block-cont">
              <div className="block">
                <img src={Coffee} />
                <div className="cont-text">
                  <div className="subtext">Класс обслуживания</div>
                  <div className="text">{element?.class?.name}</div>
                </div>
              </div>
              <div className="block">
                <img src={Clock} />
                <div className="cont-text">
                  <div className="subtext">Продолжительность</div>
                  <div className="text">{element?.duration?.name}</div>
                </div>
              </div>
              <div className="block">
                <img src={Wallet} />
                <div className="cont-text">
                  <div className="subtext">Стоимость</div>
                  <div className="row-price">
                    {arrayProm?.length > 0 ? (
                      <div className="last-price">{`${String(+element?.summa).replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        " "
                      )}`}</div>
                    ) : undefined}
                    <div className={`text ${arrayProm?.length > 0 ? "promotion" : ""}`}>
                      {arrayProm?.length > 0
                        ? `${String(countProm(arrayProm, element.summa)).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            " "
                          )} ₽`
                        : `${String(+element?.summa).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cont-images">
              <div className="main-img">
                <img src={`${URL_ORIGIN}${element?.photo}`} />
              </div>
              <div className="other-image">
                {element?.pictures?.map((el, i) => {
                  return (
                    <div key={i} className="image">
                      <img src={`${URL_ORIGIN}${el?.photo}`} />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="cont-description" id="description"></div>
            <div className="fade" />
          </div>
        </div>
      ) : stage === 1 ? (
        <div className="info-cont">
          <div className="scroll-quick">
            <div className="title">Что вас ждёт</div>
            {pointStart ? (
              <div className="point-start">
                <div className="title-point-start-cont">
                  <div className="title-point-start">
                    <div className="text">Точка сбора</div>
                    <img src={Vopros} />
                  </div>
                  <div className="coor-name">{`${
                    pointStart && JSON.parse(pointStart?.info)?.points?.[0]?.lat
                  }°, ${pointStart && JSON.parse(pointStart?.info)?.points?.[0]?.lon}°, ${
                    pointStart?.name
                  }`}</div>
                  {pointStart?.collect_time && (
                    <div className="timezone">{`${pointStart?.collect_time}`}</div>
                  )}
                </div>
                <div className="description">{`${pointStart?.description}`}</div>
                <div className="cont-images">
                  {pointStart?.pictures.map((q, i) => {
                    return (
                      <div key={i} className="image">
                        <img src={`${URL_ORIGIN}${q.photo}`} />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : undefined}

            <Timeline
              value={filtDet}
              // align="right"
              className="customized-timeline"
              marker={customizedMarker}
              content={customizedContent}
            />
          </div>
        </div>
      ) : stage === 2 ? (
        <div className="info-cont">
          <div className="scroll-quick">
            <div className="title">Отзывы путешественников</div>
            <div className="rating-cont">
              <div className="rate">
                <Rating
                  className="star-rev-quick-show"
                  rate={element?.rating[0]?.avg ? element?.rating[0]?.avg : "0"}
                />
                <div className="value">
                  {element?.rating[0]?.avg ? element?.rating[0]?.avg : "0"}
                </div>
              </div>
              <Button
                typebtn="muted"
                className="small"
                onClick={() => {
                  token
                    ? history.push(`/tour/review?id=${urlSearch?.id}`)
                    : dispatch(setModalLogin({ open: true }));
                }}
              >
                Оставить отзыв
              </Button>
            </div>
            {reviews?.length ? (
              <div className="select-sort">
                <SelectButton
                  value={sortRev}
                  array={arrSelectRev}
                  className="ghost"
                  optionLabel="name"
                  onChange={(e) => {
                    setSortRev(e.value);
                  }}
                />
              </div>
            ) : undefined}
            <div className="cont-reviews">
              {reviews?.map((el, i) => {
                let currentDate = Date.parse(new Date());
                let days = (currentDate - Date.parse(el?.created_at)) / 86400000; //86400000 - ms в дне
                let days_response = (currentDate - Date.parse(el?.response_date)) / 86400000; //86400000 - ms в дне
                let when = Math.round(days);
                let when_response = Math.round(days_response);

                return (
                  <div key={i} className="items">
                    <div className="item">
                      <div className="user-info">
                        <div className="circle">
                          <img src={avatar} />
                        </div>
                        <div className="name">{el?.client?.user?.first_name}</div>
                      </div>
                      <div className="user-rate">
                        <Rating rate={el?.tour_assessment ? el?.tour_assessment : 0} />
                        <div className="when">{calcDate(when, el?.created_at)}</div>
                      </div>
                      <div className="user-review">{el.review_text}</div>
                      <div className="cont-images">
                        {el?.pictures.map((im, i) => {
                          return (
                            <div className="image">
                              <img src={`${URL_ORIGIN}${im?.photo}`} />
                            </div>
                          );
                        })}
                      </div>
                      {el?.response_text && (
                        <div className="cont-response">
                          <img src={cornerLeftUp} />
                          <div className="response">
                            <div className="upper">
                              <div className="circle-response">
                                <img src={avatar} />
                              </div>
                              <div className="name-response">{el?.response_user?.first_name}</div>
                              <div className="date-response">
                                {calcDate(when_response, el?.response_date)}
                              </div>
                            </div>
                            <div className="text-response">{el?.response_text}</div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="l" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        stage === 3 && (
          <div className="info-cont">
            <div className="scroll-quick">
              <div className="title-opt">Настройте ваш тур</div>
              {dateRange?.operator_options?.length ? (
                <>
                  <div className="options-cont">
                    {dateRange?.operator_options?.map((el, i) => {
                      if (el?.status !== 1) return;
                      return (
                        <div key={i} className="block">
                          <div className="left-block">
                            <Toggle
                              checked={!!arrOptions?.find((q) => q.id === el.id)}
                              text={el?.option?.name}
                              description={el?.description}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setArrOptions([...arrOptions, el]);
                                  setArrOptionsCost(arrOptionsCost + +el?.basic_cost);
                                } else {
                                  let arr = arrOptions;
                                  let a = arr.indexOf(el);
                                  arr.splice(a, 1);
                                  setArrOptions(arr);
                                  setArrOptionsCost(arrOptionsCost - +el?.basic_cost);
                                }
                              }}
                            />
                          </div>
                          <div className="right-block">
                            {el?.basic_cost ? (
                              <div className="text">
                                {`+${String(+el?.basic_cost).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  " "
                                )} ₽`}
                              </div>
                            ) : (
                              <div className="text-free">Бесплатно</div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="title-races">Выберите даты</div>
                </>
              ) : undefined}

              <div className="races-cont">
                <div className="races-rate">
                  <Rating rate={element?.rating[0]?.avg ? element?.rating[0]?.avg : 0} />
                  <div className="value">
                    {element?.rating[0]?.avg ? element?.rating[0]?.avg : 0}
                  </div>
                  <div className="reviews">{`${reviews?.length} отзывов`}</div>
                </div>
                <div className="cont-select">
                  <div className="title-select">Выберите даты</div>
                  <Select
                    value={dateRange || ""}
                    array={races}
                    typesel="medium"
                    optionLabel="range"
                    className="medium input-create-oper-user"
                    onChange={(e) => setDateRange(e.target.value)}
                    placeholder="Выберите даты..."
                  />
                </div>
                <div className="ld" />
                <AlertBox
                  type="info"
                  text={`В стоимость входит до ${element?.number_travelers} участников`}
                  noClose
                />
                <div className="info-cost">
                  <div className="cost">
                    <div className="title-cost">Стоимость:</div>
                    <div className="row-price">
                      {arrayPromDataRange?.length > 0 && dateRange ? (
                        <div className="last-price">{`${String(+dateRange?.summa).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          " "
                        )}`}</div>
                      ) : undefined}
                      <div
                        className={`val-cost ${
                          arrayPromDataRange?.length > 0 && dateRange ? "promotion" : ""
                        }`}
                      >
                        {dateRange
                          ? `${String(countProm(arrayPromDataRange, dateRange?.summa)).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              " "
                            )} ₽`
                          : `${String(+0).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}
                      </div>
                    </div>
                    {/* <div className="val-cost">{`${String(+dateRange?.summa || 0)?.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      " "
                    )} ₽`}</div> */}
                  </div>
                  <div className="cost">
                    <div className="title-cost">Доп. опции:</div>
                    <div className="val-cost">{`${String(+arrOptionsCost).replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      " "
                    )} ₽`}</div>
                  </div>
                  <div className="cost">
                    <div className="total">Всего:</div>
                    {/* <div className="total">{`${
                      dateRange?.summa?.replace(/\B(?=(\d{3})+(?!\d))/g, " ") || 0
                    } ₽`}</div> */}
                    <div className="total">
                      {dateRange
                        ? `${String(
                            (
                              countProm(arrayPromDataRange, dateRange?.summa) + +arrOptionsCost
                            )?.toFixed(2)
                          ).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`
                        : `${String(+0).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}
                    </div>
                  </div>
                </div>
                <Button
                  disabled={!dateRange || element?.number_travelers < 1}
                  typebtn="bright"
                  className="large btn-continue"
                  onClick={() => {
                    let option = "";
                    if (arrOptions?.length) {
                      for (let i = 0; i < arrOptions?.length; i++) {
                        option += `&option=${arrOptions[i].id}`;
                      }
                    }
                    history.push(
                      `/checkout?id=${element?.id}&race=${dateRange?.id}${
                        arrOptions?.length ? `${option}` : ""
                      }`
                    );
                  }}
                >
                  продолжить
                </Button>
              </div>
            </div>
          </div>
        )
      )}
      <IonPopover
        class="popover-menu-el-list"
        isOpen={openPopover}
        onWillDismiss={() => setOpenPopover(false)}
        trigger={openPopover !== false ? `bottom-start-d` : undefined}
        side="bottom"
        alignment="start"
      >
        <div className="body-popover">
          {menu.map((el, i) => {
            return (
              <div
                key={i}
                className="cont-el-pop"
                href={el.href}
                onClick={() => {
                  if (el.href) {
                    window.open(el.href);
                    setOpenPopover(false);
                  } else {
                    navigator.clipboard
                      .writeText(`${URL_ORIGIN}tour?id=${urlSearch?.id}`)
                      .then(function () {
                        setOpenToast(true);
                        setMessageToast({
                          text: "Ссылка успешно скопирована в буфер обмена",
                          type: "success",
                        });
                        setOpenPopover(false);
                      });
                  }
                }}
              >
                <Icons icon={el?.icon} />
                <div className="text">{el?.name}</div>
              </div>
            );
          })}
        </div>
      </IonPopover>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </div>
  );
};

export default QuickViewTour;
