import defAvatar from "../image/default-image-prev-feedback.svg";
import Rating from "./Rating";

const PreviewFeedback = (props) => {
  const date = props?.el?.created_at;
  let currentDate = Date.parse(new Date());
  let days = (currentDate - Date.parse(date)) / 86400000; //86400000 - ms в дне
  let when = Math.round(days);

  const calcDate = (el, response_date) => {
    let response = new Date(response_date).toLocaleDateString("ru");
    let date;
    if (el == 0) {
      date = "Сегодня";
    } else if (el == 1) {
      date = "Вчера";
    } else if (el > 1 && el < 7) {
      date = `${el} ${el > 1 && el < 5 ? "дня" : "дней"} назад`;
    } else if (el >= 7 && el < 14) {
      date = "Неделю назад";
    } else if (el >= 14 && el < 30) {
      date = "2 недели назад";
    } else if (el >= 30 && el < 60) {
      date = "Месяц назад";
    } else if (el >= 60 && el < 90) {
      date = "2 месяца назад";
    } else if (el > 90) {
      date = response;
    }
    return date;
  };

  return props.type === "feedback" ? (
    <div className="prev-feedback-cont">
      <div className="cont-up">
        <div className="cont-avatar">
          <img className="avatar-info" src={props.el.image ? props.el.image : defAvatar} />
        </div>
        <div className="cont-info-user">
          <div className="text">{props.el?.user?.first_name}</div>
          <div className="text-muted">{props.el?.user?.phone}</div>
        </div>
      </div>
      <div className="rate">
        <Rating rate={props.el?.tour_assessment} />
        <div className="text-muted">{calcDate(when, date)}</div>
      </div>
      <div className="name-tours">{props.el?.product?.name}</div>
      <div className="textarea">{props.el?.review_text}</div>
    </div>
  ) : props.type === "question" ? (
    <div className="prev-feedback-cont">
      <div className="cont-up">
        <div className="cont-avatar">
          <img className="avatar-info" src={props.el.image ? props.el.image : defAvatar} />
        </div>
        <div className="cont-info-user">
          <div className="text">{props.el?.user?.first_name}</div>
          <div className="text-muted">{props.el?.user?.phone}</div>
        </div>
      </div>
      <div className="rate">
        <div className="text-muted">{calcDate(when, date)}</div>
      </div>
      <div className="name-tours">{props.el?.product?.name}</div>
      <div className="textarea">{props.el?.review_text}</div>
    </div>
  ) : undefined;
};

export default PreviewFeedback;
