import backgroundTop from "../image/marketing-page-background-road.svg";
import Button from "../ui/Button";

import ArrowDown from "../image/icons/chevron-down.svg";

import img1 from "../image/temporary/1.png";
import img2 from "../image/temporary/2.png";
import img3 from "../image/temporary/3.jpeg";

import Icons from "../ui/Icons";

import prev1 from "../image/partner-offer/image.svg";
import prev2 from "../image/partner-offer/image-1.svg";
import prev3 from "../image/partner-offer/image-2.svg";
import prev4 from "../image/partner-offer/image-3.svg";
import prev5 from "../image/partner-offer/image-4.svg";
import prev6 from "../image/partner-offer/image-5.svg";

import number1 from "../image/partner-offer/1.svg";
import number2 from "../image/partner-offer/2.svg";
import number3 from "../image/partner-offer/3.svg";
import number4 from "../image/partner-offer/4.svg";
import number5 from "../image/partner-offer/5.svg";
import number6 from "../image/partner-offer/6.svg";

import workwith1 from "../image/partner-offer/workwith1.svg";
import workwith2 from "../image/partner-offer/workwith2.svg";
import workwith3 from "../image/partner-offer/workwith3.svg";
import { IonAccordion, IonAccordionGroup, IonContent, IonItem } from "@ionic/react";
import Footer from "../ui/Footer";
import { setModalLogin, setModalLoginPartner } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Slider from "../ui/Slider";

const ForPartnersContent = () => {
  const dispatch = useDispatch();
  const productsArray = useSelector((state) => state.productsClients);
  const products = productsArray?.filter((el) => el?.races?.length !== 0);
  return (
    <IonContent className="page-guest">
      <div className="for-partners-content">
        <img src={backgroundTop} className="back-image" />
        <div className="top-hat">
          <div className="content">
            <div className="center-content">
              <div className="text">
                <div className="cont-text">
                  <span className="large-text">Автопутешествия в</span>
                  <span className="large-text">
                    <span className="colortext"> хорошей</span> компании!
                  </span>
                </div>
                <div className="small-text">
                  Смородина — это сервис по поиску и бронированию автотуров по России. Мы
                  сотрудничаем с туроператорами и помогаем организовать незабываемые путешествия по
                  уникальным маршрутам нашей страны.
                </div>
              </div>
              <div className="cont-btn">
                <Button
                  typebtn="muted"
                  className="btn-login large"
                  onClick={() => dispatch(setModalLoginPartner({ open: true }))}
                >
                  войти
                </Button>
                <Button
                  typebtn="bright"
                  className="btn-partner large"
                  onClick={() => dispatch(setModalLoginPartner({ open: true, register: true }))}
                >
                  стать партнёром
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Slider array={products} type="tours" className="slider-partner" />

        {/* <div className="cont-slide">
        <div className="shadow1"></div>
        <div className="shadow2"></div>

        <Swiper slidesPerView={6} spaceBetween={20} grabCursor={true} className="mySwiper">
          {arrayTours.map((el, i) => {
            return (
              <SwiperSlide key={i}>
                <PreviewTour el={el} className="slider" type="tours" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div> */}
        <div className="info-cont">
          <div className="cont-offer">
            <div className="title">Мы предлагаем</div>
            <div className="blocks">
              <div className="block primary">
                <Icons icon="wallet-off" />
                <div className="text">Бесплатное размещение туров</div>
              </div>
              <div className="block secondary">
                <Icons icon="checkbox" className="icon-block" />
                <div className="text">Готовую платформу для продаж</div>
              </div>
              <div className="block info">
                <Icons icon="shield-chevron" />
                <div className="text">Безопасные сделки и техподдержку</div>
              </div>
              <div className="block success">
                <Icons icon="receipt" />
                <div className="text">Сервисный сбор всего 2% с продажи</div>
              </div>
            </div>
          </div>
          <div className="offer-stage">
            <div className="stage">
              <img src={prev1} />
              <div className="text">
                <img className="number-1" src={number1} />
                <div className="bold">Используйте каталог для поиска подходящих туров</div>
                <ul className="lower">
                  <li>Фильтрация</li>
                  <li>Построение</li>
                  <li>Отзывы и рейтинг</li>
                  <li>Избранное</li>
                  <li>Похожие туры</li>
                </ul>
              </div>
            </div>
            <div className="stage stage2">
              <div className="text">
                <img className="number-2" src={number2} />
                <div className="bold">Добавляйте своих сотрудников и настраивайте права</div>
                <ul className="lower">
                  <li>Статистика продаж и бронирований</li>
                  <li>Настройка прав доступа сотрудникам</li>
                  <li>Отзывы и рейтинг</li>
                  <li>Избранное</li>
                  <li>Онлайн-оплата</li>
                  <li>Мои заказы</li>
                </ul>
              </div>
              <img src={prev2} />
            </div>
            <div className="stage">
              <img src={prev3} />
              <div className="text">
                <img className="number-3" src={number3} />
                <div className="bold">Управляйте заказами и оплатами</div>
                <ul className="lower">
                  <li>Моментальная онлайн-оплата</li>
                  <li>Детальная карточка заказа</li>
                  <li>Экспорт данных по заказу</li>
                  <li>Автоматический вывод средств</li>
                </ul>
              </div>
            </div>
            <div className="stage stage2">
              <div className="text">
                <img className="number-4" src={number4} />
                <div className="bold">Создавайте туры, управляйте расписанием и остатками</div>
                <ul className="lower">
                  <li>Настройка параметров и описания</li>
                  <li>Конструктор маршрута на карте</li>
                  <li>Настройка расписания тура</li>
                  <li>Избранное</li>
                  <li>Справочник дополнительных опций</li>
                </ul>
              </div>
              <img src={prev4} />
            </div>
            <div className="stage">
              <img src={prev5} />
              <div className="text">
                <img className="number-5" src={number5} />
                <div className="bold">Получайте выплаты по оказанным услугам</div>
                <ul className="lower">
                  <li>Управление финансами</li>
                  <li>Настройка реквизитов компании</li>
                  <li>Автоматический вывод средств</li>
                  <li>Страховой депозит компании</li>
                  <li>Моментальная онлайн-оплата</li>
                </ul>
              </div>
            </div>
            <div className="stage stage2">
              <div className="text">
                <img className="number-6" src={number6} />
                <div className="bold">Создавайте скидки и участвуйте в совместных акциях</div>
                <ul className="lower">
                  <li>Создание акций разных типов</li>
                  <li>Управление графиком акций</li>
                  <li>Участие в акциях платформы</li>
                  <li>Управление скидками на туры и опции</li>
                </ul>
              </div>
              <img src={prev6} />
            </div>
          </div>
          <div className="tryyourself">
            <div className="cont">
              <div className="title">
                <Icons icon="rocket" />
                <div className="bold">Попробуйте сами</div>
                <div className="lower">
                  Зарегистрируйте свою компанию и оцените личный кабинет партнёра
                </div>
              </div>
              <div className="cont-btn">
                <Button
                  typebtn="muted"
                  className="btn-login large"
                  onClick={() => dispatch(setModalLoginPartner({ open: true }))}
                >
                  войти
                </Button>
                <Button
                  typebtn="bright"
                  className="btn-partner large"
                  onClick={() => dispatch(setModalLoginPartner({ open: true, register: true }))}
                >
                  стать партнёром
                </Button>
              </div>
              <div className="line"></div>
              <div className="steps">
                <div className="cont-text ">
                  <div className="bold">Создайте компанию</div>
                  <div className="lower">
                    Вы станете администратором и сможете добавить своих сотрудников
                  </div>
                </div>
                <div className="center-steps">
                  <Icons icon="double-arrow" className="double-arrow" />
                  <div className="cont-text ">
                    <div className="bold">Разместите туры</div>
                    <div className="lower">
                      Бесплатно размещайте туры через личный кабинет. Туры можно опубликовать после
                      прохождения модерации.
                    </div>
                  </div>
                  <Icons icon="double-arrow" className="double-arrow" />
                </div>
                <div className="cont-text ">
                  <div className="bold">Получайте прибыль</div>
                  <div className="lower">
                    Укажите реквизиты счёта в профиле, чтобы получать средства с продажи туров.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="weworkwith">
            <div className="title">С кем работаем</div>
            <div className="blocks">
              <div className="block">
                <img src={workwith1} />
                <div className="cont-text">
                  <div className="bold">Туристические агентства</div>
                  <div className="lower">
                    Компании, предприниматели и самозанятые, продающие и организующие туры
                  </div>
                </div>
              </div>
              <div className="block">
                <img src={workwith2} />
                <div className="cont-text">
                  <div className="bold">Гиды авторских туров</div>
                  <div className="lower">Самостоятельные организаторы с опытом</div>
                </div>
              </div>
              <div className="block">
                <img src={workwith3} />
                <div className="cont-text">
                  <div className="bold">Туроператоры и турфирмы</div>
                  <div className="lower">
                    Поставщики туристических услуг и организаторы путешествий
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="faq">
            <div className="cont-faq">
              <div className="title">Частые вопросы</div>
              <IonAccordionGroup className="item-accord-group">
                <IonAccordion className="item-accord" value="1" toggleIcon={ArrowDown}>
                  <IonItem slot="header">
                    <div>Сколько это стоит?</div>
                  </IonItem>
                  <div className="ion-padding" slot="content">
                    Только сервисный сбор — 2% с каждой продажи тура.
                  </div>
                </IonAccordion>
                <IonAccordion className="item-accord" value="2" toggleIcon={ArrowDown}>
                  <IonItem slot="header">
                    <div>Кто может стать партнёром?</div>
                  </IonItem>
                  <div className="ion-padding" slot="content"></div>
                </IonAccordion>
                <IonAccordion className="item-accord" value="3" toggleIcon={ArrowDown}>
                  <IonItem slot="header">
                    <div>Как вы проверяете партнёра?</div>
                  </IonItem>
                  <div className="ion-padding" slot="content"></div>
                </IonAccordion>
                <IonAccordion className="item-accord" value="4" toggleIcon={ArrowDown}>
                  <IonItem slot="header">
                    <div>Можно ли добавить к стоимости тура сервисный сбор?</div>
                  </IonItem>
                  <div className="ion-padding" slot="content"></div>
                </IonAccordion>
                <IonAccordion className="item-accord" value="5" toggleIcon={ArrowDown}>
                  <IonItem slot="header">
                    <div>Сколько будет идти проверка?</div>
                  </IonItem>
                  <div className="ion-padding" slot="content"></div>
                </IonAccordion>
                <IonAccordion className="item-accord" value="6" toggleIcon={ArrowDown}>
                  <IonItem slot="header">
                    <div>Как вернуть деньги, если отказались от тура?</div>
                  </IonItem>
                  <div className="ion-padding" slot="content"></div>
                </IonAccordion>
              </IonAccordionGroup>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </IonContent>
  );
};

export default ForPartnersContent;
