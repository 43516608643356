import { useEffect, useMemo, useState } from "react";
import Input from "../ui/Input";
import noImage from "../image/no-image-s.svg";
import { IonChip, IonIcon, IonLabel, IonPopover, IonSelect, IonSelectOption } from "@ionic/react";
import ModalChangeTable from "./ModalsFilter/ModalChangeTable";
import ModalFilterTable from "./ModalsFilter/ModalFilterTableEmployees";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import Icons from "../ui/Icons";
import Rating from "../ui/Rating";
import qs from "qs";
import "../less/css/table.css";
import { Tooltip } from "primereact/tooltip";

import Button from "./Button";
import PopoverTable from "./PopoverTable";
import { useHistory, useLocation } from "react-router";
import Status from "./Status";
import { createDefaultMaskGenerator, mask } from "react-hook-mask";
import { useSelector } from "react-redux";
import Badge from "./Badge";
import Toast from "./Toast";
import SelectButton from "./SelectButton";
import AutoCompleteSelect from "./AutoCompleteSelect";
import filter from "array-filter";
import ChipsTable from "./ChipsTable";
import { URL_ORIGIN } from "../utils/config";
import ExcelJS from "exceljs";

const Table = (props) => {
  const genreArray = useSelector((state) => state.genre);
  const userInfo = useSelector((state) => state.userInfo);

  const history = useHistory();
  const urlSearch = qs.parse(history.location.search.substr(1));
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  const [dateOt, setDateOt] = useState();
  const [dateDo, setDateDo] = useState();
  const [searchProm, setSearchProm] = useState();

  const defaultArray = useMemo(() => props.array, [props.array]);
  const [sort, setSort] = useState({ el: null, sort: false, type: null, noImage: false });
  const [array, setArray] = useState(defaultArray);
  const [filterArray, setFilterArray] = useState();
  const [stageArray, setStageArray] = useState();
  const [arrayCuts, setArrayCuts] = useState();
  const header = props.header?.filter((el) => el.default === true);

  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("User Data");
    const headerRow = worksheet.addRow([]);
    const headerCells = Object.values(props.arrayForExcel);
    for (let i = 0; i < headerCells?.length; i++) {
      const column = Object.keys(headerCells[i]);
      for (let j = 0; j < column?.length; j++) {
        headerRow.getCell(j + 1).value = column[j];
      }
    }
    const rows = Object.values(props.arrayForExcel);
    for (let i = 0; i < rows?.length; i++) {
      const cells = Object.values(rows[i]);
      const rowData = [];
      for (let j = 0; j < cells.length; j++) {
        rowData.push(cells[j]);
      }
      worksheet.addRow(rowData);
      worksheet.columns.forEach(function (column, i) {
        let maxLength = 0;
        column["eachCell"]({ includeEmpty: true }, function (cell) {
          var columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
      });
    }
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
      });
      var link = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = link;
      a.download = `${props.nameFile}.xlsx`;
      a.click();
      // window.location = link;
      // saveAs(blob, `output.xlsx`);
    });
  };

  // console.log(
  //   "ARRAYS",
  //   "defaultArray: ",
  //   defaultArray,
  //   "array: ",
  //   array,
  //   "filterArray: ",
  //   filterArray,
  //   "arrayCuts: ",
  //   arrayCuts
  // );

  const [activeTabs, setActiveTabs] = useState("all");
  const [pagePaginator, setPagePaginator] = useState(false);
  const [valuePagePaginator, setValuePagePaginator] = useState({ page: 5 });
  const [openModalChangeTable, setOpenModalChangeTable] = useState(false);
  const [page, setPage] = useState(+urlSearch?.page);
  const location = useLocation();
  const maskGenerator = createDefaultMaskGenerator("+9 (999) 999-99-99");
  let arrayCut = [];

  // const onSort = async (type, field, noImage) => {
  useAsyncEffect(async () => {
    // })
    // arrayCuts[page].sort(function (a, b) {
    //   return a[el.field] - b[el.field];
    // });
    let type = sort.type;
    let field = sort.el;
    let ar = filterArray;
    if (!sort.noImage) {
      if (
        type === "price" ||
        type === "rate" ||
        type === "places" ||
        type === "reviews" ||
        type === "participants" ||
        type === "options" ||
        type === "status" ||
        type === "photo_val" ||
        type === "price_race" ||
        type === "id" ||
        type === "type_operations" ||
        type === "name_link" ||
        type === "tooltip" ||
        type === "price_topay" ||
        type === "comission" ||
        type === "penalty"
      ) {
        if (sort) {
          if (sort.el === field) {
            if (sort.sort === false) {
              await ar.sort(function (a, b) {
                return a.id && b.id ? a.id - b.id : a.user_id - b.user_id;
              });
              setFilterArray(ar);
              // setSort({ el: null, sort: false, type: null });
            } else if (sort.sort === "up") {
              await ar.sort(function (a, b) {
                if (type === "reviews") {
                  let fila = a?.[field].filter((z) => z.type === 0);
                  let filb = b?.[field].filter((z) => z.type === 0);
                  return fila?.length - filb?.length;
                } else {
                  return type === "participants" || type === "options" || type === "photo_val"
                    ? a?.[field]?.length - b?.[field]?.length
                    : type === "type_operations" || type === "status" || type === "name_link"
                    ? (a?.[field]?.id ? a?.[field]?.id : a?.[field]) -
                      (b?.[field]?.id ? b?.[field]?.id : b?.[field])
                    : type === "price_race"
                    ? +a?.[field]?.summa - +b?.[field]?.summa
                    : type === "penalty"
                    ? (+a?.[field]?.length ? +a?.[field]?.[0]?.penalty : 0) -
                      (+b?.[field]?.length ? +b?.[field]?.[0]?.penalty : 0)
                    : type === "rate"
                    ? (+a?.[field]?.length ? +a?.[field]?.[0]?.avg : 0) -
                      (+b?.[field]?.length ? +b?.[field]?.[0]?.avg : 0)
                    : type === "tooltip"
                    ? a?.[field]?.length - b?.[field]?.length
                    : a?.[field] - b?.[field];
                }
              });
              // setSort({ el: field, sort: "up", type: type });
              setFilterArray(ar);
            } else if (sort.sort === "down") {
              await ar.sort(function (a, b) {
                if (type === "reviews") {
                  let fila = a?.[field].filter((z) => z.type === 0);
                  let filb = b?.[field].filter((z) => z.type === 0);
                  return filb?.length - fila?.length;
                } else {
                  return type === "participants" || type === "options" || type === "photo_val"
                    ? b?.[field]?.length - a?.[field]?.length
                    : type === "type_operations" || type === "status" || type === "name_link"
                    ? (b?.[field]?.id ? b?.[field]?.id : b?.[field]) -
                      (a?.[field]?.id ? a?.[field]?.id : a?.[field])
                    : type === "price_race"
                    ? +b?.[field]?.summa - +a?.[field]?.summa
                    : type === "penalty"
                    ? (+b?.[field]?.length ? +b?.[field]?.[0]?.penalty : 0) -
                      (+a?.[field]?.length ? +a?.[field]?.[0]?.penalty : 0)
                    : type === "rate"
                    ? (+b?.[field]?.length ? +b?.[field]?.[0]?.avg : 0) -
                      (+a?.[field]?.length ? +a?.[field]?.[0]?.avg : 0)
                    : type === "tooltip"
                    ? b?.[field]?.length - a?.[field]?.length
                    : b?.[field] - a?.[field];
                }
              });
              // setSort({ el: field, sort: "down", type: type });
              setFilterArray(ar);
            }
          } else {
            ar.sort(function (a, b) {
              if (type === "reviews") {
                let fila = a?.[field].filter((z) => z.type === 0);
                let filb = b?.[field].filter((z) => z.type === 0);
                return fila?.length - filb?.length;
              } else {
                return type === "participants" || type === "options" || type === "photo_val"
                  ? a?.[field]?.length - b?.[field]?.length
                  : type === "type_operations" || type === "status" || type === "name_link"
                  ? (a?.[field]?.id ? a?.[field]?.id : a?.[field]) -
                    (b?.[field]?.id ? b?.[field]?.id : b?.[field])
                  : type === "price_race"
                  ? +a?.[field]?.summa - +b?.[field]?.summa
                  : type === "penalty"
                  ? (+a?.[field]?.length ? +a?.[field]?.[0]?.penalty : 0) -
                    (+b?.[field]?.length ? +b?.[field]?.[0]?.penalty : 0)
                  : type === "rate"
                  ? (a?.[field]?.length ? +a?.[field]?.[0]?.avg : 0) -
                    (+b?.[field]?.length ? +b?.[field]?.[0]?.avg : 0)
                  : type === "tooltip"
                  ? a?.[field]?.length - b?.[field]?.length
                  : a?.[field] - b?.[field];
              }
            });
            // setSort({ el: field, sort: "up", type: type });
            setFilterArray(ar);
          }
        }
      } else {
        if (sort) {
          if (sort.el === field) {
            if (sort.sort === false) {
              ar?.sort(function (a, b) {
                return a.id && b.id ? a.id - b.id : a.user_id - b.user_id;
              });
              setFilterArray(ar);
              // setSort({ el: null, sort: false, type: null });
            } else if (sort.sort === "up") {
              ar.sort(function (a, b) {
                var x =
                  type === "child"
                    ? a[field]?.name?.toLowerCase()
                    : type === "client_orders"
                    ? a[field]?.user?.first_name?.toLowerCase()
                    : type === "weekday_start"
                    ? a[field]?.start_date?.toLowerCase()
                    : type === "weekday_end"
                    ? a[field]?.end_date?.toLowerCase()
                    : type === "user_name" || type === "client_reviews"
                    ? a[field]?.first_name?.toLowerCase()
                    : type === "user_email"
                    ? a[field]?.email?.toLowerCase()
                    : a?.[field]?.toLowerCase();
                var y =
                  type === "child"
                    ? b[field]?.name?.toLowerCase()
                    : type === "client_orders"
                    ? b[field]?.user?.first_name?.toLowerCase()
                    : type === "weekday_start"
                    ? b[field]?.start_date?.toLowerCase()
                    : type === "weekday_end"
                    ? b[field]?.end_date?.toLowerCase()
                    : type === "user_name" || type === "client_reviews"
                    ? b[field]?.first_name?.toLowerCase()
                    : type === "user_email"
                    ? b[field]?.email?.toLowerCase()
                    : b[field]?.toLowerCase();
                if (x < y) {
                  return -1;
                }
                if (x > y) {
                  return 1;
                }
                return 0;
              });
              // setSort({ el: field, sort: "up", type: type });
              setFilterArray(ar);
            } else {
              ar.sort(function (a, b) {
                var x =
                  type === "child"
                    ? a[field]?.name?.toLowerCase()
                    : type === "client_orders"
                    ? a[field]?.user?.first_name?.toLowerCase()
                    : type === "weekday_start"
                    ? a[field]?.start_date?.toLowerCase()
                    : type === "weekday_end"
                    ? a[field]?.end_date?.toLowerCase()
                    : type === "user_name" || type === "client_reviews"
                    ? a[field]?.first_name?.toLowerCase()
                    : type === "user_email"
                    ? a[field]?.email?.toLowerCase()
                    : type === "number"
                    ? a[field]
                    : a[field]?.toLowerCase() || "-";
                var y =
                  type === "child"
                    ? b[field]?.name?.toLowerCase()
                    : type === "client_orders"
                    ? b[field]?.user?.first_name?.toLowerCase()
                    : type === "weekday_start"
                    ? b[field]?.start_date?.toLowerCase()
                    : type === "weekday_end"
                    ? b[field]?.end_date?.toLowerCase()
                    : type === "user_name" || type === "client_reviews"
                    ? b[field]?.first_name?.toLowerCase()
                    : type === "user_email"
                    ? b[field]?.email?.toLowerCase()
                    : type === "number"
                    ? b[field]
                    : b[field]?.toLowerCase() || "-";
                if (x > y) {
                  return -1;
                }
                if (x < y) {
                  return 1;
                }
                return 0;
              });
              // setSort({ el: field, sort: "down", type: type });
              setFilterArray(ar);
            }
          } else {
            ar.sort(function (a, b) {
              var x =
                type === "child"
                  ? a[field]?.name?.toLowerCase()
                  : type === "client_orders"
                  ? a[field]?.user?.first_name?.toLowerCase()
                  : type === "weekday_start"
                  ? a[field]?.start_date?.toLowerCase()
                  : type === "weekday_end"
                  ? a[field]?.end_date?.toLowerCase()
                  : type === "user_name" || type === "client_reviews"
                  ? a[field]?.first_name?.toLowerCase()
                  : type === "user_email"
                  ? a[field]?.email?.toLowerCase()
                  : type === "number"
                  ? a[field]
                  : a[field]?.toLowerCase() || "-";
              var y =
                type === "child"
                  ? b[field]?.name?.toLowerCase()
                  : type === "client_orders"
                  ? b[field]?.user?.first_name?.toLowerCase()
                  : type === "weekday_start"
                  ? b[field]?.start_date?.toLowerCase()
                  : type === "weekday_end"
                  ? b[field]?.end_date?.toLowerCase()
                  : type === "user_name" || type === "client_reviews"
                  ? b[field]?.first_name?.toLowerCase()
                  : type === "user_email"
                  ? b[field]?.email?.toLowerCase()
                  : type === "number"
                  ? b[field]
                  : b[field]?.toLowerCase() || "-";
              if (x < y) {
                return -1;
              }
              if (x > y) {
                return 1;
              }
              return 0;
            });
            // setSort({ el: field, sort: "up", type: type });
            setFilterArray(ar);
          }
        }
      }
    }
  }, [sort, page, filterArray]);

  useEffect(() => {
    if (
      location.pathname === "/partner/showcase/tours" ||
      location.pathname === "/partner/showcase/options" ||
      location.pathname === "/partner/finance/orders" ||
      location.pathname === "/partner/finance/operations" ||
      location.pathname === "/partner/feedback/reviews" ||
      location.pathname === "/partner/feedback/questions" ||
      location.pathname === "/partner/feedback/faq" ||
      location.pathname === "/partner/settings/employees"
    ) {
      if (
        !urlSearch.page ||
        urlSearch?.page > arrayCuts?.length ||
        urlSearch?.page < 1 ||
        Object.keys(urlSearch).length > 1
      ) {
        history.push("?page=1");
        setPage(1);
      }
    }
  }, []);

  useAsyncEffect(() => {
    filterArray ? setArray(filterArray) : setArray(defaultArray);
  }, [defaultArray, filterArray]);

  const dateFormat = (value) => {
    var timezone = new Date(value).getTimezoneOffset() / 60;

    let createDate = new Date(value).toTimeString().split(" ");
    let showDate = `${createDate[0].slice(0, 5)} (UTC${
      +timezone < 0 ? `+${+timezone * -1 > 9 ? "" : "0"}${+timezone * -1}:00` : `-${+timezone * -1}`
    })`;
    return showDate;
  };

  const weekdayFormat = (value) => {
    let weekday = new Date(value).toLocaleDateString("ru", { weekday: "short" });
    let returnWeekday = `${weekday[0].toUpperCase()}${weekday[1]}`;
    return returnWeekday;
  };

  useAsyncEffect(async () => {
    await props.setOpenMenuElem(false);
  }, [location]);

  array?.forEach((el, i) => {
    el.num = i + 1;
  });

  useAsyncEffect(async () => {
    if (!array.length) {
      setArrayCuts([]);
    } else {
      for (
        let i = 0;
        i <
        Math.ceil(
          array?.length /
            (valuePagePaginator.page !== "Все" ? valuePagePaginator.page : array?.length)
        );
        i++
      ) {
        if (valuePagePaginator.page !== "Все") {
          arrayCut[i] = array?.slice(
            i * valuePagePaginator.page,
            i * valuePagePaginator.page + valuePagePaginator.page
          );
          setArrayCuts(arrayCut);
        } else {
          arrayCut[0] = array;
          setArrayCuts(arrayCut);
        }
      }
    }
  }, [valuePagePaginator, array, sort]);

  const paginatorPage = [{ page: 5 }, { page: 25 }, { page: 50 }, { page: 100 }, { page: "Все" }];

  useAsyncEffect(async () => {
    if (activeTabs === "all") {
      setFilterArray(defaultArray);
      setStageArray(defaultArray);
    } else {
      if (props.type) {
        let search;
        if (props.type[0].status === 0) {
          search = props.type.find((q) => q.type === activeTabs);
        } else {
          search = props.type.find((q) => q.type === activeTabs + 1);
        }
        let valstat = await defaultArray.filter((arr) => arr.type?.id === search.type);
        setFilterArray(valstat);
        setStageArray(valstat);
      } else if (props.status) {
        let search;
        if (props.status[0].status === 0 || props.status[0].status_id === 0) {
          search = props.status.find((q) => q.status === activeTabs || q.status_id === activeTabs);
        } else {
          search = props.status.find(
            (q) => q.status === activeTabs + 1 || q.status_id === activeTabs + 1
          );
        }
        let valstat = await defaultArray.filter(
          (arr) => arr.status_id === search.status || arr.status === search.status
        );
        setFilterArray(valstat);
        setStageArray(valstat);
      }
    }
  }, [defaultArray]);

  useAsyncEffect(async () => {
    let arrayFilter = activeTabs === "all" ? defaultArray : stageArray;
    // название
    if (urlSearch?.name) {
      let pat = urlSearch?.name?.split(" ");
      let search;
      for (let i = 0; i < pat?.length; i++) {
        search = await arrayFilter?.filter((q) =>
          q?.name?.toLowerCase()?.includes(pat[i]?.toLowerCase())
        );
      }
      arrayFilter = search;
    }
    // название вопроса
    if (urlSearch?.question) {
      let pat = urlSearch?.question?.split(" ");
      let search;
      for (let i = 0; i < pat?.length; i++) {
        search = await arrayFilter?.filter((q) =>
          q?.review_text?.toLowerCase()?.includes(pat[i]?.toLowerCase())
        );
      }
      arrayFilter = search;
    }
    // название / client
    if (urlSearch?.name_client) {
      let pat = urlSearch?.name_client?.split(" ");
      let search;
      for (let i = 0; i < pat?.length; i++) {
        search = await arrayFilter?.filter(
          (q) =>
            q?.product?.name?.toLowerCase()?.includes(pat[i]?.toLowerCase()) ||
            q?.client?.user?.first_name?.toLowerCase()?.includes(pat[i]?.toLowerCase()) ||
            q?.client?.user?.last_name?.toLowerCase()?.includes(pat[i]?.toLowerCase()) ||
            q?.user?.first_name?.toLowerCase()?.includes(pat[i]?.toLowerCase()) ||
            q?.user?.last_name?.toLowerCase()?.includes(pat[i]?.toLowerCase()) ||
            q?.id == pat[i]
        );
      }
      arrayFilter = search;
    }
    // name / email
    if (urlSearch?.name_email) {
      let pat = urlSearch?.name_email?.split(" ");
      let search;
      for (let i = 0; i < pat?.length; i++) {
        search = await arrayFilter?.filter(
          (q) =>
            q?.user?.first_name?.toLowerCase()?.includes(pat[i]?.toLowerCase()) ||
            q?.user?.last_name?.toLowerCase()?.includes(pat[i]?.toLowerCase()) ||
            q?.user?.email?.toLowerCase()?.includes(pat[i]?.toLowerCase()) ||
            q?.comment?.toLowerCase()?.includes(pat[i]?.toLowerCase())
        );
      }
      arrayFilter = search;
    }

    // название / summa
    if (urlSearch?.price) {
      let pat = urlSearch?.price?.split(" ");
      let search;
      for (let i = 0; i < pat?.length; i++) {
        search = await arrayFilter?.filter((q) => q?.summa?.includes(pat[i]));
      }
      arrayFilter = search;
    }
    if (urlSearch?.name_price) {
      let pat = urlSearch?.name_price?.split(" ");
      let search;
      for (let i = 0; i < pat?.length; i++) {
        search = await arrayFilter?.filter((q) => q?.summa?.includes(pat[i]) || q?.id == pat[i]);
      }
      arrayFilter = search;
    }
    // дата
    if (urlSearch?.date_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => new Date(el.created_at).getTime() >= +urlSearch?.date_from
      );
    }
    if (urlSearch?.date_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => new Date(el.created_at).getTime() <= +urlSearch?.date_until + 86400000
      );
    }

    // дата последнего входа
    if (urlSearch?.last_login_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => new Date(el.last_login).getTime() >= +urlSearch?.last_login_from
      );
    }
    if (urlSearch?.last_login_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => new Date(el.last_login).getTime() <= +urlSearch?.last_login_until + 86400000
      );
    }

    // дата старта
    if (urlSearch?.start_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => new Date(el?.race?.start_date).getTime() >= +urlSearch?.start_from
      );
    }
    if (urlSearch?.start_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => new Date(el?.race?.start_date).getTime() <= +urlSearch?.start_until + 86400000
      );
    }

    // дата заврешения
    if (urlSearch?.end_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => new Date(el?.race?.end_date).getTime() >= +urlSearch?.end_from
      );
    }
    if (urlSearch?.end_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => new Date(el?.race?.end_date).getTime() <= +urlSearch?.end_until + 86400000
      );
    }
    // дата ответа
    if (urlSearch?.response_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => new Date(el?.response_date).getTime() >= +urlSearch?.response_from
      );
    }
    if (urlSearch?.response_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => new Date(el?.response_date).getTime() <= +urlSearch?.response_until + 86400000
      );
    }

    // формат
    if (urlSearch?.format) {
      let arr = [];
      let arrSplit = urlSearch?.format?.split(",");
      for (let i = 0; i < arrSplit?.length; i++) {
        let filterEl = await arrayFilter?.filter((el) => el?.duration?.id === +arrSplit[i]);
        filterEl && arr.push(...filterEl);
      }
      arrayFilter = arr;
    }
    // цена
    if (urlSearch?.price_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.summa >= +urlSearch?.price_from || +el?.basic_cost >= +urlSearch?.price_from
      );
    }
    if (urlSearch?.price_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.summa <= +urlSearch?.price_until || +el?.basic_cost <= +urlSearch?.price_until
      );
    }
    // стоимость тура
    if (urlSearch?.price_from_order) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.race?.summa >= +urlSearch?.price_from_order
      );
    }
    if (urlSearch?.price_until_order) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.race?.summa <= +urlSearch?.price_until_order
      );
    }
    // мест
    if (urlSearch?.seats_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) =>
          +el?.available_places >= +urlSearch?.seats_from ||
          +el?.basic_volume >= +urlSearch?.seats_from
      );
    }
    if (urlSearch?.seats_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) =>
          +el?.available_places <= +urlSearch?.seats_until ||
          +el?.basic_volume <= +urlSearch?.seats_until
      );
    }
    // жанры
    if (urlSearch?.genres) {
      let arr = [];
      let arrSplit = urlSearch?.genres?.split(",");
      for (let i = 0; i < arrSplit?.length; i++) {
        let filterEl = await arrayFilter?.filter((el) =>
          el?.genres?.find((a) => a?.id === +arrSplit[i])
        );
        filterEl && arr.push(...filterEl);
      }
      arrayFilter = arr;
    }
    // статус
    if (urlSearch?.status) {
      arrayFilter = await arrayFilter?.filter((el) => +el?.status?.id === +urlSearch?.status);
    }
    // клиенты
    if (urlSearch?.client) {
      let arr = [];
      let arrSplit = urlSearch?.client?.split(",");
      for (let i = 0; i < arrSplit?.length; i++) {
        let filterEl = await arrayFilter?.filter((el) => el?.user_id === +arrSplit[i]);
        filterEl && arr.push(...filterEl);
      }
      arrayFilter = arr;
    }
    // опубликован
    if (urlSearch?.public_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => new Date(el.publication_date).getTime() >= +urlSearch?.public_from
      );
    }
    if (urlSearch?.public_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => new Date(el.publication_date).getTime() <= +urlSearch?.public_until + 86400000
      );
    }
    // рейтинг
    if (urlSearch?.rate_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) =>
          +el?.rating?.[0]?.avg >= +urlSearch?.rate_from ||
          +el?.tour_assessment >= +urlSearch?.rate_from
      );
    }
    if (urlSearch?.rate_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) =>
          +el?.rating?.[0]?.avg <= +urlSearch?.rate_until ||
          +el?.tour_assessment <= +urlSearch?.rate_until
      );
    }
    // оплачено
    if (urlSearch?.paid_from) {
      arrayFilter = await arrayFilter?.filter((el) => +el?.summa >= +urlSearch?.paid_from);
    }
    if (urlSearch?.paid_until) {
      arrayFilter = await arrayFilter?.filter((el) => +el?.summa <= +urlSearch?.paid_until);
    }
    // в турах
    if (urlSearch?.tours_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.products?.length >= +urlSearch?.tours_from
      );
    }
    if (urlSearch?.tours_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.products?.length <= +urlSearch?.tours_until
      );
    }

    // в турах
    if (urlSearch?.role) {
      arrayFilter = await arrayFilter?.filter((el) => el?.operator_role === urlSearch?.role);
    }

    // фото
    if (urlSearch?.photo_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.pictures?.length >= +urlSearch?.photo_from
      );
    }
    if (urlSearch?.photo_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.pictures?.length <= +urlSearch?.photo_until
      );
    }

    // комиссия
    if (urlSearch?.comission_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.commission_value >= +urlSearch?.comission_from
      );
    }
    if (urlSearch?.comission_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.commission_value <= +urlSearch?.comission_until
      );
    }

    // штраф
    if (urlSearch?.fine_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => (el?.penalty.length ? +el.penalty[0]?.penalty : 0) >= +urlSearch?.fine_from
      );
    }
    if (urlSearch?.fine_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => (el?.penalty.length ? +el.penalty[0]?.penalty : 0) <= +urlSearch?.fine_until
      );
    }

    // к выплате
    if (urlSearch?.to_pay_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => (+el?.summa / 100) * (100 - +el?.commission_value) >= +urlSearch?.to_pay_from
      );
    }
    if (urlSearch?.to_pay_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => (+el?.summa / 100) * (100 - +el?.commission_value) <= +urlSearch?.to_pay_until
      );
    }
    // участников
    if (urlSearch?.participants_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.participants?.length >= +urlSearch?.participants_from
      );
    }
    if (urlSearch?.participants_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.participants?.length <= +urlSearch?.participants_until
      );
    }
    // опции
    if (urlSearch?.options_from) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.operator_options?.length >= +urlSearch?.options_from
      );
    }
    if (urlSearch?.options_until) {
      arrayFilter = await arrayFilter?.filter(
        (el) => +el?.operator_options?.length <= +urlSearch?.options_until
      );
    }
    // отзывы
    if (urlSearch?.reviews_from) {
      let arrRev = arrayFilter;
      await arrRev?.forEach((q) => {
        let fil = q.clients_reviews?.filter((a) => a?.type === 0);
        q.reviews_length = fil?.length;
      });
      arrayFilter = await arrayFilter?.filter(
        (el) => el?.reviews_length >= +urlSearch?.reviews_from
      );
    }
    if (urlSearch?.reviews_until) {
      let arrRev = arrayFilter;
      await arrRev?.forEach((q) => {
        let fil = q.clients_reviews?.filter((a) => a?.type === 0);
        q.reviews_length = fil?.length;
      });
      arrayFilter = await arrayFilter?.filter(
        (el) => el?.reviews_length <= +urlSearch?.reviews_until
      );
    }
    // избранное
    if (urlSearch?.favorite_from) {
      arrayFilter = await arrayFilter?.filter((el) => +el?.favorites >= +urlSearch?.favorite_from);
    }
    if (urlSearch?.favorite_until) {
      arrayFilter = await arrayFilter?.filter((el) => +el?.favorites <= +urlSearch?.favorite_until);
    }

    // console.log("befor FILTER", arrayFilter);
    setFilterArray(arrayFilter);
  }, [
    urlSearch?.name,
    urlSearch?.name_client,
    urlSearch?.name_price,
    urlSearch?.name_email,
    urlSearch?.client,
    urlSearch?.status,
    urlSearch?.date_from,
    urlSearch?.date_until,
    urlSearch?.end_from,
    urlSearch?.end_until,
    urlSearch?.start_from,
    urlSearch?.last_login_from,
    urlSearch?.last_login_until,
    urlSearch?.role,
    urlSearch?.start_until,
    urlSearch?.price_from,
    urlSearch?.price_until,
    urlSearch?.price_from_order,
    urlSearch?.price_until_order,
    urlSearch?.paid_from,
    urlSearch?.paid_until,
    urlSearch?.seats_from,
    urlSearch?.seats_until,
    urlSearch?.genres,
    urlSearch?.format,
    urlSearch?.question,
    urlSearch?.price,
    urlSearch?.tours_from,
    urlSearch?.tours_until,
    urlSearch?.comission_from,
    urlSearch?.comission_until,
    urlSearch?.fine_from,
    urlSearch?.fine_until,
    urlSearch?.to_pay_from,
    urlSearch?.to_pay_until,
    urlSearch?.participants_from,
    urlSearch?.participants_until,
    urlSearch?.options_from,
    urlSearch?.options_until,
    page,
    stageArray,
  ]);

  useEffect(() => {
    setPage(+urlSearch?.page);
  }, [urlSearch?.page]);

  return (
    <>
      <div className="tour-list">
        {location.pathname !== "/partner/showcase/options" && (
          <div className="tabs">
            <div
              className={`el-tabs ${activeTabs === "all" ? `active-tabs` : ""}`}
              onClick={() => {
                setPage(1);
                setActiveTabs("all");
                setArray(defaultArray);
                setStageArray(defaultArray);
                setFilterArray(null);
                let data = urlSearch;
                data.page = 1;
                const params = new URLSearchParams(data);
                history.push(`?${params.toString()}`);
              }}
            >
              <div className="text-tabs">Все</div>
              <div className="value-tabs">{props.array?.length}</div>
            </div>
            {props.type
              ? props.type?.map((el, i) => {
                  let valstat = defaultArray?.filter((arr) => arr.type.id === el.type);
                  let valstatDef = defaultArray?.filter((arr) => arr.type.id === el.type);
                  return (
                    <div
                      key={i}
                      className={`el-tabs ${activeTabs === i ? `active-tabs` : ""}`}
                      onClick={() => {
                        setPage(1);
                        setActiveTabs(i);
                        setArray(valstat);
                        setFilterArray(valstat);
                        setStageArray(valstat);
                        let data = urlSearch;
                        data.page = 1;
                        const params = new URLSearchParams(data);
                        history.push(`?${params.toString()}`);
                        // setSearchProm(null);
                      }}
                    >
                      <div className="text-tabs">{el.type_name_full || el.type_name}</div>
                      <div className="value-tabs">{valstatDef.length}</div>
                    </div>
                  );
                })
              : props.status?.map((el, i) => {
                  let valstat = defaultArray?.filter(
                    (arr) => arr.status_id === el.status || arr.status == el.status
                  );
                  let valstatDef = defaultArray?.filter(
                    (arr) => arr.status_id === el.status || arr.status == el.status
                  );
                  return (
                    <div
                      key={i}
                      className={`el-tabs ${activeTabs === i ? `active-tabs` : ""}`}
                      onClick={() => {
                        setPage(1);
                        setActiveTabs(i);
                        setArray(valstat);
                        setStageArray(valstat);
                        setFilterArray(valstat);
                        let data = urlSearch;
                        data.page = 1;
                        const params = new URLSearchParams(data);
                        history.push(`?${params.toString()}`);
                        // setSearchProm(null);
                      }}
                    >
                      <div className="text-tabs">{el.name_full || el.name}</div>
                      <div className="value-tabs">{valstatDef.length}</div>
                    </div>
                  );
                })}
          </div>
        )}
        <div className="cont-tablet">
          <div className="filter">
            <div className="filter-search-cont">
              <Input
                value={searchProm}
                className="input-search small"
                placeholder={props.searchPlaceholder}
                image="search"
                onChange={(e) => {
                  if (!e) {
                    let data = urlSearch;
                    if (
                      location?.pathname === "/partner/finance/orders" ||
                      location?.pathname === "/partner/feedback/reviews" ||
                      location?.pathname === "/partner/feedback/questions"
                    ) {
                      delete data.name_client;
                    } else if (location?.pathname === "/partner/finance/operations") {
                      delete data.name_price;
                    } else if (location?.pathname === "/partner/feedback/faq") {
                      delete data.question;
                    } else if (location?.pathname === "/partner/settings/employees") {
                      delete data.name_email;
                    } else {
                      delete data.name;
                    }
                    data.page = 1;
                    const params = new URLSearchParams(data);
                    history.push(`?${params.toString()}`);
                  } else {
                    setSearchProm(e);
                    let data = urlSearch;
                    if (
                      location?.pathname === "/partner/finance/orders" ||
                      location?.pathname === "/partner/feedback/reviews" ||
                      location?.pathname === "/partner/feedback/questions"
                    ) {
                      data.name_client = e;
                    } else if (location?.pathname === "/partner/finance/operations") {
                      data.name_price = e;
                    } else if (location?.pathname === "/partner/feedback/faq") {
                      data.question = e;
                    } else if (location?.pathname === "/partner/settings/employees") {
                      data.name_email = e;
                    } else {
                      data.name = e;
                    }
                    data.page = 1;
                    const params = new URLSearchParams(data);
                    history.push(`?${params.toString()}`);
                  }
                }}
              />
              <div className="search-date">
                <Input
                  value={dateOt ? new Date(dateOt).toLocaleDateString() : ""}
                  className="input-date small"
                  placeholder="создан от"
                  type="date"
                  noImage
                  onChange={(e) => {
                    if (e) {
                      setDateOt(e);
                      let data = urlSearch;
                      data.date_from = new Date(e).getTime();
                      const params = new URLSearchParams(data);
                      history.push(`?${params.toString()}`);
                      // let fil = array?.filter(
                      //   (el) => new Date(el?.created_at).getTime() > new Date(e).getTime()
                      // );
                      // setArray(fil);
                    } else {
                      setDateOt(e);
                      let data = urlSearch;
                      delete data.date_from;
                      const params = new URLSearchParams(data);
                      history.push(`?${params.toString()}`);
                    }
                  }}
                />
                <Input
                  value={dateDo ? new Date(dateDo).toLocaleDateString() : ""}
                  className="input-date small"
                  placeholder="создан до"
                  type="date"
                  noImage
                  onChange={(e) => {
                    if (e) {
                      setDateDo(e);
                      let data = urlSearch;
                      data.date_until = new Date(e).getTime();
                      const params = new URLSearchParams(data);
                      history.push(`?${params.toString()}`);
                    } else {
                      setDateDo(e);
                      let data = urlSearch;
                      delete data.date_until;
                      const params = new URLSearchParams(data);
                      history.push(`?${params.toString()}`);
                    }
                  }}
                />
              </div>
            </div>
            <div className="btn-elem-filter">
              <Button
                typebtn="ghost"
                className="small btn-elem-f"
                onClick={() => {
                  props.openFilterModal(true);
                  // setOpenModalFilterTable(true);
                }}
              >
                <Icons icon="filter" />
                <div className="text">Фильтры</div>
              </Button>
              <Button
                disabled={!props.arrayForExcel?.length}
                typebtn="ghost"
                className="small btn-elem-e"
                onClick={() => {
                  if (props.permission?.[0]) {
                    exportToExcel();
                  } else {
                    setOpenToast(true);
                    setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
                  }
                }}
              >
                <Icons icon="download" />
                <div className="text">Экспорт</div>
              </Button>
            </div>
          </div>
          <div className="chip-cont">
            <ChipsTable urlSearch={urlSearch} setDateOt={setDateOt} setDateDo={setDateDo} />
          </div>
          <div className="tablet">
            <table>
              <tbody>
                <tr className="header-tablet">
                  {/* <th className="el-tableth photo">фото</th> */}
                  {header?.map((el, i) => {
                    return (
                      <th
                        key={i}
                        className={`el-tableth ${el.field || ""}`}
                        onClick={async () => {
                          if (sort.el !== el.field) {
                            setSort({
                              el: el.field,
                              sort: "up",
                              type: el.type,
                              noImage: el.noImage,
                            });
                          } else {
                            setSort({
                              el: el.field,
                              sort:
                                sort.sort === "up"
                                  ? "down"
                                  : sort.sort === "down"
                                  ? false
                                  : sort.sort === false && "up",

                              type: el.type,
                              noImage: el.noImage,
                            });
                          }
                        }}
                      >
                        <div className="cont-text-table">
                          <div className="text">{el.name}</div>
                          {!el.noImage && (
                            <Icons
                              icon="design-personal-caret-down-filled"
                              className={
                                sort.sort === "up" && sort.el === el.field && sort.type === el.type
                                  ? "up"
                                  : sort.sort === "down" &&
                                    sort.el === el.field &&
                                    sort.type === el.type
                                  ? "down"
                                  : ""
                              }
                            />
                          )}
                        </div>
                      </th>
                    );
                  })}
                  <th className="el-tableth settings head">
                    <div className="setting" onClick={() => setOpenModalChangeTable(true)}>
                      <Icons icon="design-personal-settings" className="icon-outline" />
                    </div>
                  </th>
                </tr>
                {arrayCuts?.[page - 1]?.map((el, i) => {
                  let statusEl = el.status_id
                    ? props.status?.find((fin) => fin.status === el.status_id)
                    : props.status?.find((fin) => fin.status == el.status);
                  let typeEl = props.type?.find((t) => t.type === el.type);
                  let penalty = el.penalty?.length ? +el.penalty?.[0]?.penalty : 0;
                  let comission = el?.commission_value ?? 0;
                  return (
                    <tr key={i} className="line-tablet">
                      {header?.map((td, index) => {
                        let value = el[td.field];
                        if (td.type === "reviews") {
                          value = value?.filter((fil) => fil?.type === 0);
                        }
                        return (
                          <td
                            key={index}
                            className={`el-tablet ${td.field}`}
                            onClick={() => {
                              props.focus(el);
                              props.onClick(el);
                            }}
                          >
                            {td.type === "name" || td.type === "id" ? (
                              <div className="text-name">{value}</div>
                            ) : td.type === "text" ? (
                              <div className="text">{value}</div>
                            ) : td.type === "number" ? (
                              <div className="text">{value}</div>
                            ) : td.type === "places" ? (
                              <div className="text">{+value}</div>
                            ) : td.type === "comment" ? (
                              <div className="text">{value ? value : "-"}</div>
                            ) : td.type === "date" ? (
                              <>
                                {value ? (
                                  <>
                                    <div className="up-text-create">
                                      {new Date(value).toLocaleDateString()}
                                    </div>
                                    <div className="down-text-create">{dateFormat(value)}</div>
                                  </>
                                ) : (
                                  <div>-</div>
                                )}
                              </>
                            ) : td.type === "status" ? (
                              <Status color={statusEl?.color}>{statusEl?.name}</Status>
                            ) : td.type === "child" ? (
                              <div className="text">{value?.name}</div>
                            ) : td.type === "user_email" ? (
                              <div className="text">{value?.email}</div>
                            ) : td.type === "user_name" ? (
                              <div className="text-name">{`${value?.last_name} ${
                                value?.first_name
                              } ${value?.middle_name ? value?.middle_name : ""}`}</div>
                            ) : td.type === "reviews" ? (
                              <div className="text">{value ? value?.length : "0"}</div>
                            ) : td.type === "participants" ? (
                              <div className="text">{value ? value.length : "0"}</div>
                            ) : td.type === "price_race" ? (
                              <div className="text">
                                {value?.summa
                                  ? String((+value?.summa)?.toFixed(2))?.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      " "
                                    )
                                  : "0"}
                              </div>
                            ) : td.type === "options" ? (
                              <div className="text">{value ? value.length : "0"}</div>
                            ) : td.type === "photo_val" ? (
                              <div className="text">{value ? value.length : "0"}</div>
                            ) : td.type === "tooltip" ? (
                              <div className="text">
                                {value.length ? (
                                  <Tooltip target={`#id_${i}`} className="tool">
                                    {value.map((qw, indx) => {
                                      return (
                                        <div className="tooltext" key={indx}>
                                          {qw.name}
                                        </div>
                                      );
                                    })}
                                  </Tooltip>
                                ) : undefined}
                                <div className="tooltip-text" id={`id_${i}`}>
                                  {value ? value.length : "0"}
                                </div>
                              </div>
                            ) : td.type === "type" ? (
                              <div className="text">{typeEl.type_name}</div>
                            ) : td.type === "type_operations" ? (
                              <div className="text">{value.name}</div>
                            ) : td.type === "name_link" ? (
                              <div className="text-name">{value.name}</div>
                            ) : td.type === "rate" ? (
                              <Rating rate={+value[0]?.avg} />
                            ) : td.type === "rate-rev" ? (
                              <Rating rate={+value} />
                            ) : td.type === "badge" ? (
                              <div className="badge-table">
                                {value?.map((e, i) => {
                                  return (
                                    <Badge array={props.array} key={i}>
                                      {e.name}
                                    </Badge>
                                  );
                                })}
                              </div>
                            ) : td.type === "price" ? (
                              <div className="text">
                                {value
                                  ? String(+value)?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                  : "0"}
                              </div>
                            ) : td.type === "comission" ? (
                              <div className="text">{`${+value}%`}</div>
                            ) : td.type === "penalty" ? (
                              <div className="text">
                                {value
                                  ? String(value.length ? +value[0].penalty : 0)?.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      " "
                                    )
                                  : "0"}
                              </div>
                            ) : td.type === "price_topay" ? (
                              <div className="text">
                                {value
                                  ? String(
                                      ((+value / 100) * (100 - comission))?.toFixed(2)
                                    )?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                  : "0"}
                              </div>
                            ) : td.type === "description" ? (
                              value ? (
                                <div className="text-description">{value}</div>
                              ) : (
                                <div className="text-description">-</div>
                              )
                            ) : td.type === "image" ? (
                              <div className="cont-img">
                                <img
                                  src={
                                    value && value.length
                                      ? `${URL_ORIGIN}${
                                          typeof value === "string" ? value : value[0]?.photo
                                        }`
                                      : noImage
                                  }
                                />
                              </div>
                            ) : td.type === "client" ? (
                              <>
                                <div className="up-text-create">{`${value} ${el?.last_name?.[0]}.`}</div>
                                <div className="down-text-create">
                                  {el?.phone && mask(el?.phone, maskGenerator)}
                                </div>
                              </>
                            ) : td.type === "client_reviews" ? (
                              <>
                                <div className="up-text-create">{`${value.first_name} ${value?.last_name?.[0]}.`}</div>
                                <div className="down-text-create">
                                  {value?.phone && mask(value?.phone, maskGenerator)}
                                </div>
                              </>
                            ) : td.type === "client_orders" ? (
                              <>
                                <div className="up-text-create">{`${value?.user?.first_name} ${
                                  value?.user?.last_name?.[0]
                                    ? `${value?.user?.last_name?.[0]}.`
                                    : ""
                                }`}</div>
                                <div className="down-text-create">
                                  {value?.user?.phone && mask(value?.user?.phone, maskGenerator)}
                                </div>
                              </>
                            ) : td.type === "weekday_start" ? (
                              value ? (
                                <>
                                  <div className="up-text-create">
                                    {new Date(value?.start_date).toLocaleDateString()}
                                  </div>
                                  <div className="down-text-create">
                                    {weekdayFormat(value?.start_date)}
                                  </div>
                                </>
                              ) : (
                                <div className="text">-</div>
                              )
                            ) : td.type === "weekday_end" ? (
                              value ? (
                                <>
                                  <div className="up-text-create">
                                    {new Date(value?.end_date).toLocaleDateString()}
                                  </div>
                                  <div className="down-text-create">
                                    {weekdayFormat(value?.end_date)}
                                  </div>
                                </>
                              ) : (
                                <div className="text">-</div>
                              )
                            ) : (
                              td.type === "weekday" && (
                                <>
                                  <div className="up-text-create">
                                    {new Date(value).toLocaleDateString()}
                                  </div>
                                  <div className="down-text-create">{weekdayFormat(value)}</div>
                                </>
                              )
                            )}
                          </td>
                        );
                      })}
                      <td className="el-tablet settings">
                        <div
                          id={`bottom-start-${i}`}
                          className={`setting`}
                          onClick={() => {
                            if (el.deleted_at) return;
                            props.setOpenMenuElem({
                              index: i,
                              elstat: el.status_id ? el.status_id : el.status,
                            });
                            props.focus(el);
                          }}
                        >
                          <Icons
                            icon="dots-vertical"
                            className={`icon-outline ${el.deleted_at ? "disabled" : ""}`}
                          />
                        </div>
                        <div id={`null`} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="paginator">
            <div className="cont-select-el-list">
              <div className="text">На стр.:</div>
              <SelectButton
                value={valuePagePaginator}
                array={paginatorPage}
                className="ghost btn-paginator"
                optionLabel="page"
                onChange={(e) => {
                  setValuePagePaginator(e.value);
                  setPage(1);
                }}
              />
            </div>
            <div className="col">{`${
              arrayCuts?.[page - 1]?.[0].num ? arrayCuts?.[page - 1]?.[0].num : 0
            }-${
              arrayCuts?.[page - 1]?.[arrayCuts?.[page - 1]?.length - 1].num
                ? arrayCuts?.[page - 1]?.[arrayCuts?.[page - 1]?.length - 1].num
                : 0
            } из ${array?.length}`}</div>
            <div className="btn-next">
              <Button
                typebtn="ghost"
                className="btn-page small"
                onClick={() => {
                  setPage(page - 1);
                  let data = urlSearch;
                  data.page = page - 1;
                  const params = new URLSearchParams(data);
                  history.push(`?${params.toString()}`);
                }}
                disabled={page == 1}
              >
                <Icons icon="design-personal-chevron-left" className="icon-unfilled arrow" />
              </Button>
              <div className="text">{`${page}/${arrayCuts?.length ? arrayCuts?.length : 1}`}</div>
              <Button
                typebtn="ghost"
                className="btn-page small"
                onClick={() => {
                  setPage(page + 1);
                  let data = urlSearch;
                  data.page = page + 1;
                  const params = new URLSearchParams(data);
                  history.push(`?${params.toString()}`);
                }}
                disabled={!arrayCuts?.length || page == arrayCuts?.length}
              >
                <Icons icon="design-personal-chevron-right" className="icon-unfilled arrow" />
              </Button>
            </div>
          </div>
        </div>
      </div>

      <PopoverTable
        status={props.openMenuElem.elstat}
        array={props.array}
        menu={props.menu}
        class="popover-menu-el-list"
        isOpen={props.openMenuElem.index || (props.openMenuElem.index === 0 && true)}
        onWillDismiss={() => {
          props.setOpenMenuElem(false);
          // props.focus(null);
        }}
        trigger={
          props.openMenuElem !== false ? `bottom-start-${props.openMenuElem.index}` : undefined
        }
        side="bottom"
        alignment="start"
      />
      <IonPopover
        class="popover-paginator-el-list"
        isOpen={pagePaginator}
        onWillDismiss={() => setPagePaginator(false)}
        side="bottom"
        alignment="center"
        trigger="page-paginator"
        size="cover"
      >
        <div className="body-popover">
          {paginatorPage?.map((val, i) => {
            return (
              <div
                key={i}
                className="cont-el-pop"
                onClick={() => {
                  setPage(0);
                  setValuePagePaginator(val.page);
                  setPagePaginator(false);
                }}
              >
                <div className="text">{val.page}</div>
              </div>
            );
          })}
        </div>
      </IonPopover>
      <ModalChangeTable
        open={openModalChangeTable}
        numberColumns={props.numberColumns}
        onClose={setOpenModalChangeTable}
        column={props.header}
        className={props.classNameTableSetting}
      />
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </>
  );
};

export default Table;
