import { useIonViewDidEnter, useIonViewWillLeave } from "@ionic/react";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { useSelector } from "react-redux";
import flagiconmap from "../../image/icons/flag-icon-map.png";
import bediconmap from "../../image/icons/bed-icon-map.png";
import { useState } from "react";

const MiniMapCreateTour = (props) => {
  const formForCreateTour = useSelector((state) => state.formForCreateTour);
  const arrayPointsTour = useSelector((state) => state.arrayPointsTour);
  const formPointTour = useSelector((state) => state.formPointTour);

  var nmapi, map, navitelTile;

  var LeafIcon = L.Icon.extend({
    options: {
      iconSize: [32, 32],
    },
  });
  var flagIcon = new LeafIcon({ iconUrl: flagiconmap });
  var bedIcon = new LeafIcon({ iconUrl: bediconmap });

  useAsyncEffect(async () => {
    if (props.maps) {
      props.maps.remove();
    }
    nmapi = await navitel.createWebMapApi("059ed2d4fdc4bd5a0ffc56eb88d3154ec8cc52a9", {
      locale: navitel.LocaleCode.ru_RU,
    });
    map = L.map("map-create", {
      center: [55.75, 37.7],
      zoom: 8,
    });
    navitelTile = nmapi.extension.leaflet.createTileLayer({
      locale: navitel.LocaleCode.ru_RU,
    });

    navitelTile.addTo(map);
    arrayPointsTour?.forEach((el, i) => {
      L.marker([el?.lat1, el?.lon1], {
        icon:
          el.point_type_id === 1
            ? flagIcon
            : (el.point_type_id === 2 || el.point_type_id === 3) && bedIcon,
      }).addTo(map);
    });

    let arr = [];
    for (let i = 0; i < arrayPointsTour.length; i++) {
      arr.push([+arrayPointsTour[i].lat1, +arrayPointsTour[i].lon1]);
    }
    if (arrayPointsTour.length >= 2) {
      const routeId = await nmapi.route.routeId({
        objectType: nmapi.route.ObjectType.CAR,
        waypointsList: arr,
      });
      const geom = await nmapi.route.routeGeometry({
        routeId,
      });
      const meta = await nmapi.route.routeMetadata({ routeId });

      let arrForCreateLines = [];
      for (let i = 0; i < geom[0].pointsList.length; i++) {
        arrForCreateLines.push([geom[0].pointsList[i].latitude, geom[0].pointsList[i].longitude]);
      }
      var path = L.polyline(arrForCreateLines, {
        color: "#DD304F",
        weight: 4,
        opacity: 1,
      }).addTo(map);
      // 5.
      map.addLayer(path);
      map.fitBounds(path.getBounds());
    }

    map.invalidateSize();

    props.setMaps(map);
  }, [arrayPointsTour]);

  return (
    <div className="cont-map-c">
      <div className="map" id="map-create"></div>
    </div>
  );
};
export default MiniMapCreateTour;
