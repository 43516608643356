import { useHistory, useLocation } from "react-router";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import { IonContent } from "@ionic/react";
import "../../less/css/showcase.css";
import noImage from "../../image/no-image.svg";
import Table from "../../ui/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { DEFAULT_TOURS, FIELD_FOR_TOURS, STATUSES_TOURS } from "../../utils/array";
import ModalSubmitForModeration from "./Modals/ModalSubmitForModeration";
import ModalOperNoModeration from "./Modals/ModalOperNoModeration";
import ModalStartSelling from "./Modals/ModalStartSelling";
import qs from "qs";

import ModalMakeDraft from "./Modals/ModalMakeDraft";
import ModalArchiveTour from "./Modals/ModalArchiveTour";
import ModalInsufficientDeposit from "./Modals/ModalInsufficientDeposit";
import ModalFilterTableTours from "../../ui/ModalsFilter/ModalFilterTableTours";
import ModalDelete from "./Modals/ModalDelete";
import {
  setArrayPointsTour,
  setArrayTourRaces,
  setFormForCreateTour,
  setProducts,
  setTourFocus,
} from "../../store/actions";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { checkTourStatus } from "../../utils/utils";
import { deleteTour, getToursOperator, putCreateTour } from "../../utils/api";
import Toast from "../../ui/Toast";
import ModalDeleteTour from "./Modals/ModalDeleteTour";

const ToursListContent = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const operator = useSelector((state) => state.operator);
  const token = useSelector((state) => state.token);
  const products = useSelector((state) => state.products);
  const arrayProducts = products.filter((el) => !el.deleted_at === true);
  const userPermissions = useSelector((state) => state.userPermissions.showcase?.tours);

  var arrayForExcel = [];

  for (let i = 0; i < products?.length; i++) {
    let review = products[i].clients_reviews.filter((el) => el.type === 0);
    let genres = ``;
    for (let j = 0; j < products[i].genres.length; j++) {
      genres += `${products[i].genres[j]?.name}, `;
    }
    let new_array = {
      Номер: products[i]?.id,
      Название: products[i]?.name,
      "Формат тура": products[i]?.duration?.name,
      Цена: products[i]?.summa,
      Мест: products[i]?.available_places,
      "Дата создания тура": products[i]?.created_at,
      Жанры: genres,
      "Дата публикации тура": products[i]?.updated_at,
      Рейтинг: +products[i]?.rating?.[0]?.avg || 0,
      "Количество отзывов": review.length,
      "В избранном": products[i]?.favorites,
      Статус: products[i]?.status.description,
    };
    arrayForExcel.push(new_array);
  }

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [focusElement, setFocusElement] = useState();
  const [modalForModeration, setModalForModeration] = useState(false);
  const [modalOperNoModeration, setModalOperNoModeration] = useState(false);
  const [modalDeleteTour, setModalDeleteTour] = useState(false);
  const [modalStartSelling, setModalStartSelling] = useState(false);
  const [modalMakeDraft, setModalmakeDraft] = useState(false);
  const [modalArchiveTour, setModalArchiveTour] = useState(false);
  const [modalInsufficientDeposit, setModalInsufficientDeposit] = useState(false);
  const [tourProgress, setTourProgress] = useState();
  const [openMenuElem, setOpenMenuElem] = useState(false);
  const urlSearch = qs.parse(history.location.search.substr(1));
  const columnsTable = useSelector((state) => state.columnsTable);

  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });

  const status = STATUSES_TOURS;
  // const fieldArray = FIELD_FOR_TOURS;
  const numberColumns = 1;
  const [fieldArray, setFieldArray] = useState();
  useEffect(() => {
    setFieldArray(JSON.parse(localStorage.getItem("column"))[numberColumns]);
  }, [columnsTable]);

  useAsyncEffect(() => {
    dispatch(setTourFocus(false));
    dispatch(setFormForCreateTour(DEFAULT_TOURS));
    dispatch(setArrayPointsTour([]));
    dispatch(setArrayTourRaces([]));
  }, [location]);

  const arrayFocusElemt = useMemo((e) => focusElement, [focusElement]);
  useAsyncEffect(async () => {
    focusElement && setTourProgress(checkTourStatus(focusElement, focusElement?.details));
  }, [focusElement]);

  const menu = [
    {
      name: "Предпросмотр",
      icon: "design-personal-eye",
      click: () => {
        if (userPermissions[0]) {
          history.push(`/partner/showcase/tours/details?id=${focusElement.id}`);
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
        setOpenMenuElem(false);
      },
      // status: [2, 3, 4, 5, 6],
      status: [3, 4, 5, 6],
    },
    {
      name: "Параметры",
      icon: "design-personal-edit",
      click: () => {
        if (userPermissions[0]) {
          history.push(`/partner/showcase/tours/create?id=${focusElement.id}`);
          setOpenMenuElem(false);
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
        setOpenMenuElem(false);
        // dispatch(setTourFocus(focusElement));
      },
      status: [1, 2, 3, 4, 5, 6],
    },
    {
      name: "На модерацию",
      icon: "design-personal-arrow-forward-up-double",
      click: () => {
        if (userPermissions[4]) {
          if (operator.status === 2) {
            setModalForModeration(true);
          } else if (operator.status === 0 || operator.status === 1) {
            setModalOperNoModeration(true);
          }
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
        setOpenMenuElem(false);
        console.log("tourProgress",tourProgress)
      },
      status: tourProgress?.one && tourProgress?.two && tourProgress?.three ? [1, 3] : [2],
    },
    {
      name: "Начать продажи",
      icon: "design-personal-flag-up",
      click: () => {
        if (userPermissions[4]) {
          // if (arrayTourRaces?.length) {
          setModalStartSelling(true);
          setOpenMenuElem(false);
          // } else {
          //   setOpenToast(true);
          //   setMessageToast({
          //     text: "Чтобы начать продажи, нужно добавить даты в расписание",
          //     type: "warning",
          //   });
          // }
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
        // setModalInsufficientDeposit(true);
      },
      status: [4, 6],
    },
    {
      name: "Сделать черновиком",
      icon: "design-personal-note",
      click: () => {
        if (userPermissions[4]) {
          setModalmakeDraft(true);
          setOpenMenuElem(false);
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
      },
      status: [4, 5, 6],
    },
    {
      name: "Архивировать",
      icon: "design-personal-archive",
      click: () => {
        if (userPermissions[4]) {
          setModalArchiveTour(true);
          setOpenMenuElem(false);
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
      },
      status: [4, 5],
    },
    {
      name: "Удалить",
      icon: "design-personal-trash",
      click: () => {
        if (userPermissions[3]) {
          setModalDeleteTour(true);
          setOpenMenuElem(false);
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
      },
      status: [1, 3, 4, 5, 6],
    },
  ];

  return (
    <>
      <IonContent className="page">
        <div className="tourlist-page">
          <div className="upper-line">
            <div className="title">Список туров</div>
            <Button
              className="btn-create small"
              typebtn="bright"
              onClick={() => {
                if (userPermissions[1]) {
                  history.push("/partner/showcase/tours/create");
                } else {
                  setOpenToast(true);
                  setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
                }
              }}
            >
              <Icons className="icon-bright" icon="plus" />
              Создать
            </Button>
          </div>
          <Table
            onClick={(el) => {
              if (userPermissions[0]) {
                history.push(`/partner/showcase/tours/details?id=${el.id}`);
              } else {
                setOpenToast(true);
                setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
              }
            }}
            openMenuElem={openMenuElem}
            setOpenMenuElem={setOpenMenuElem}
            openFilterModal={setOpenFilterModal}
            focus={setFocusElement}
            array={arrayProducts}
            header={fieldArray}
            numberColumns={numberColumns}
            status={status}
            menu={menu}
            permission={userPermissions}
            classNameTableSetting="tours"
            arrayForExcel={arrayForExcel}
            nameFile={`Таблица туров ${new Date().toLocaleDateString("ru")}`}
            searchPlaceholder="Поиск по названию..."
          />
        </div>
      </IonContent>
      <ModalFilterTableTours open={openFilterModal} onClose={setOpenFilterModal} />
      <ModalSubmitForModeration
        element={arrayFocusElemt}
        open={modalForModeration}
        onClose={setModalForModeration}
      />
      <ModalOperNoModeration open={modalOperNoModeration} onClose={setModalOperNoModeration} />

      <ModalDeleteTour
        open={modalDeleteTour}
        onClose={setModalDeleteTour}
        focusElement={focusElement}
      />
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
      <ModalStartSelling
        open={modalStartSelling}
        onClose={setModalStartSelling}
        element={arrayFocusElemt}
      />
      <ModalMakeDraft open={modalMakeDraft} onClose={setModalmakeDraft} element={arrayFocusElemt} />
      <ModalArchiveTour
        open={modalArchiveTour}
        onClose={setModalArchiveTour}
        element={arrayFocusElemt}
      />
      <ModalInsufficientDeposit
        open={modalInsufficientDeposit}
        onClose={setModalInsufficientDeposit}
      />
    </>
  );
};

export default ToursListContent;
