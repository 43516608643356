import { useHistory } from "react-router";
import Button from "../../ui/Button";
import Toggle from "../../ui/Toggle";
import { postLogout } from "../../utils/api";
import { setToken } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import ModalChangePassword from "./ModalChangePassword";
import { useState } from "react";

const NotificationSettings = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const token = useSelector((state) => state.token);
  return (
    <div className="notif-div">
      <div className="notification-profile">
        <div className="title">Уведомления</div>
        <div className="toggles">
          <Toggle
            checked={false}
            text="Новая продажа"
            description="Покупатель успешно оплатил тур"
          />
          <Toggle checked={false} text="Новый отзыв" description="Опубликован новый отзыв к туру" />
          <Toggle
            checked={false}
            text="Новая акция от платформы"
            description="Новое предложение акции платформы"
          />
        </div>
      </div>
      <div className="btns">
        <Button className="medium" typebtn="outline" onClick={() => setOpenChangePassword(true)}>
          Изменить пароль
        </Button>
        <Button
          className="btn-logout medium"
          typebtn="outline"
          onClick={() => {
            postLogout(token)
              .then((e) => {
                history.push("/");
                dispatch(setToken(null));
                localStorage.removeItem("token");
                localStorage.removeItem("userInfo");
                localStorage.removeItem("column");

                window.location.reload();
              })
              .catch((e) => {
                console.log(e);
              });
          }}
        >
          Выйти из аккаунта
        </Button>
      </div>
      <ModalChangePassword
        open={openChangePassword}
        onClose={() => {
          setOpenChangePassword(false);
        }}
      />
    </div>
  );
};

export default NotificationSettings;
