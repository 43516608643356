import "../less/css/header.css";
import logo from "../image/logo.svg";
import { useHistory, useLocation } from "react-router";
import HeaderPopover from "../ui/HeaderPopover";
import { useState } from "react";
import Icons from "../ui/Icons";
import { useDispatch, useSelector } from "react-redux";
import Button from "../ui/Button";
import { Tooltip } from "primereact/tooltip";
import { setOpenModalWithdraw } from "../store/actions";

const Header = (props) => {
  const dispatch = useDispatch();
  const client = useSelector((state) => state.userInfo.clientInfo);
  const operator = useSelector((state) => state.operator);
  const location = useLocation();
  const history = useHistory();
  const [popoverHeader, setPopoverHeader] = useState({ isOpen: false, elem: null });
  const [openBalance, setOpenBalance] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);

  return (
    <>
      <div className="header">
        <div className="upper-line-header">
          <div className="left-segment">
            <div className="logo">Смородина</div>
            {Object.keys(operator)?.length > 0 && (
              <div className="_company">
                {operator?.status === 2 ? (
                  <div className="status">
                    <Icons icon="discount-check" className="icon-com" />
                    <div className="overlay">
                      <div className="text">Ваша компания подтверждена.</div>
                    </div>
                  </div>
                ) : (
                  <div className="status">
                    <Icons icon="alert-circle" className="icon-warning icon-com" />
                    <div className="overlay">
                      <div className="text">
                        Ваша компания не подтверждена. Заполните данные компании и дождитесь
                        модерации.
                      </div>
                      <Button
                        typebtn="muted"
                        className="small"
                        onClick={() => history.push("/partner/settings/company")}
                      >
                        Настройки
                        <Icons icon="arrow-narrow-right" />
                      </Button>
                    </div>
                  </div>
                )}
                <span className={operator?.status === 2 ? `ver` : `nover`}>
                  {operator?.name_legal}
                </span>
                <span className="text">/</span>
                <span className="text">
                  {!!client.user?.sys_user
                    ? "Администратор"
                    : !!client.user?.operator_user && client?.user?.operator_user?.operator_role}
                </span>
              </div>
            )}
          </div>
          <div className="right-segment">
            <div
              className="select-value"
              onClick={() => {
                setOpenBalance(true);
              }}
              onMouseLeave={() => {
                setOpenBalance(false);
              }}
            >
              <div className="value">{`${String(operator?.balans ? +operator?.balans : 0).replace(
                /\B(?=(\d{3})+(?!\d))/g,
                " "
              )} ₽`}</div>{" "}
              {/* Заглушка */}
              <Icons icon="chevron-down" />
              <div className={`overlay ${openBalance ? "focus" : ""}`}>
                <div className="cont-val">
                  <div className="value">{`Депозит: ${String(
                    operator?.balans ? +operator?.balans : 0
                  ).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}</div>
                </div>
                <Button
                  typebtn="muted"
                  className="small"
                  onClick={() => dispatch(setOpenModalWithdraw(true))}
                >
                  Вывести
                  <Icons icon="arrow-narrow-right" />
                </Button>
                {/* <div className="alert-req">{`Запрос от ${"11.11.11"} на вывод ${0} ₽ в работе`}</div> */}
              </div>
            </div>
            <div className="cont-icons">
              <div
                className="cont-icon"
                onClick={() => setOpenNotification(true)}
                onMouseLeave={() => {
                  setOpenNotification(false);
                }}
              >
                {/* <div className="action-alert" /> */} {/* Новое уведомление */}
                {/* <Icons icon="bell" className="icon-outline" /> */}
                <Button
                  typebtn="ghost"
                  className="small cube"
                  // onClick={() => setOpenNotification(true)}
                  // onMouseLeave={() => {
                  //   setOpenNotification(false);
                  // }}
                >
                  <Icons icon="bell" />
                </Button>
                <div className={`overlay ${openNotification ? "focus" : ""}`}>
                  <div className="scroll">Нет уведомлений</div>
                </div>
              </div>
              {/* <div className="cont-icon">
                <Button typebtn="ghost" className="small cube">
                  <Icons icon="help" />
                </Button>
              </div> */}
              <div className="cont-icon" id="bottom-start-user">
                <Button
                  typebtn="ghost"
                  className="small cube"
                  onClick={() => {
                    setPopoverHeader({ isOpen: true, elem: "user" });
                  }}
                >
                  <Icons icon="user" />
                </Button>
              </div>
              <div id={`bottom-start-null`} />
            </div>
          </div>
        </div>
        <div className="down-line-header">
          {/* select-el - выбранный элемент */}
          <div
            className={`el-menu ${
              location.pathname.includes("/partner/summary") ? "select-el" : ""
            }`}
            onClick={() => {
              history.push("/partner/summary");
            }}
          >
            Сводка
          </div>
          <div
            className={`el-menu ${
              location.pathname.includes("/partner/showcase") ? "select-el" : ""
            }`}
            onClick={() => {
              setPopoverHeader({ isOpen: true, elem: "showcase" });
            }}
            id="bottom-start-showcase"
          >
            Витрина
          </div>
          <div
            className={`el-menu ${
              location.pathname.includes("/partner/finance") ? "select-el" : ""
            }`}
            onClick={() => {
              setPopoverHeader({ isOpen: true, elem: "finance" });
            }}
            id="bottom-start-finance"
          >
            Финансы
          </div>
          <div
            className={`el-menu ${
              location.pathname.includes("/partner/feedback") ? "select-el" : ""
            }`}
            onClick={() => {
              setPopoverHeader({ isOpen: true, elem: "feedback" });
            }}
            id="bottom-start-feedback"
          >
            Обратная связь
          </div>
          <div
            className={`el-menu ${
              location.pathname.includes("/partner/settings") ? "select-el" : ""
            }`}
            onClick={() => {
              setPopoverHeader({ isOpen: true, elem: "settings" });
            }}
            id="bottom-start-settings"
          >
            Настройки
          </div>
        </div>
      </div>
      <HeaderPopover
        class={`popover-header-el-list ${popoverHeader.elem}`}
        isOpen={popoverHeader.isOpen}
        onWillDismiss={() => setPopoverHeader({ isOpen: false, elem: null })}
        trigger={popoverHeader !== false ? `bottom-start-${popoverHeader.elem}` : undefined}
        side="bottom"
        alignment="start"
        element={popoverHeader.elem}
      />
    </>
  );
};

export default Header;
