import { IonContent } from "@ionic/react";
import "../../less/css/finance.css";
import Table from "../../ui/Table";
import { FIELD_FOR_ORDERS, STATUSES_ORDERS } from "../../utils/array";
import { useEffect, useState } from "react";
import ModalFilterOrders from "../../ui/ModalsFilter/ModalFilterOrders";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import Toast from "../../ui/Toast";

const OrdersContent = () => {
  const ordersArray = useSelector((state) => state.orders);
  ordersArray.forEach((el) => {
    el.name = el?.product?.name;
  });
  const orders = ordersArray?.filter((el) => el.status_id !== 7);
  const [focusElement, setFocusElement] = useState();
  const [openMenuElem, setOpenMenuElem] = useState(false);

  const userPermissions = useSelector((state) => state.userPermissions.finance?.orders);
  const columnsTable = useSelector((state) => state.columnsTable);

  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const history = useHistory();
  const status = STATUSES_ORDERS;
  // const fieldArray = FIELD_FOR_ORDERS;

  const numberColumns = 4;
  const [fieldArray, setFieldArray] = useState();
  useEffect(() => {
    setFieldArray(JSON.parse(localStorage.getItem("column"))[numberColumns]);
  }, [columnsTable]);

  var arrayForExcel = [];

  for (let i = 0; i < orders?.length; i++) {
    let operator_options = "";
    for (let j = 0; j < orders[i].operator_options.length; j++) {
      operator_options += `${orders[i].operator_options[j]?.option?.name}, `;
    }
    let forPay = (+orders[i]?.summa / 100) * (100 - +orders[i]?.commission_value);
    let new_array = {
      Номер: orders[i]?.id,
      "Дата создания заказа": orders[i]?.created_at,
      Клиент: `${orders[i]?.client?.user?.last_name || ""} ${
        orders[i]?.client?.user?.first_name || ""
      } ${orders[i]?.client?.user?.middle_name || ""}`,
      "Название тура": orders[i]?.product?.name,
      "Начало заезда": orders[i]?.race?.start_date || "",
      "Завершение заезда": orders[i]?.race?.end_date || "",
      Оплачено: +orders[i]?.summa,
      Комиссия: +orders[i].commission_value,
      Штраф: +orders[i]?.penalty[0]?.penalty || 0,
      "К выплате": forPay <= 0 ? 0 : forPay,
      "Количество участников": orders[i]?.participants.length,
      "Стоимость тура": +orders[i]?.summa,
      Опции: operator_options,
      "Кто отменил": `${orders?.cancel_user?.last_name || ""} ${
        orders?.cancel_user?.first_name || ""
      } ${orders?.cancel_user?.middle_name || ""}`,
      Статус: orders[i]?.status.description,
    };
    arrayForExcel.push(new_array);
  }

  const menu = [
    {
      name: "Смотреть",
      icon: "design-personal-eye",
      click: (e) => {
        if (userPermissions[0]) {
          history.push(`/partner/finance/orders/details?id=${focusElement.id}`);
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
      },
      status: [1, 2, 3, 4, 5, 6],
    },
    {
      name: "Детали тура",
      icon: "file-type-pdf",
      click: () => {
        console.log("ДЕТ");
      },
      status: [1, 2, 3, 4, 5, 6],
    },
  ];

  return (
    <>
      <IonContent className="page">
        <div className="orders-finance-page">
          <div className="title">Заказы</div>
          <Table
            onClick={(el) => {
              if (userPermissions[0]) {
                history.push(`/partner/finance/orders/details?id=${el.id}`);
              } else {
                setOpenToast(true);
                setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
              }
            }}
            openMenuElem={openMenuElem}
            setOpenMenuElem={setOpenMenuElem}
            openFilterModal={setOpenFilterModal}
            focus={setFocusElement}
            status={status}
            array={orders}
            numberColumns={numberColumns}
            header={fieldArray}
            menu={menu}
            permission={userPermissions}
            classNameTableSetting="orders"
            arrayForExcel={arrayForExcel}
            nameFile={`Таблица заказов ${new Date().toLocaleDateString("ru")}`}
            searchPlaceholder="Поиск по туру, покупателю..."
          />
        </div>
      </IonContent>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
      <ModalFilterOrders onClose={setOpenFilterModal} open={openFilterModal} />
    </>
  );
};

export default OrdersContent;
