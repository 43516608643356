import { IonPopover } from "@ionic/react";
import "../less/css/custom.css";
import Icons from "./Icons";
import { useLocation } from "react-router";

const PopoverTable = (props) => {
  return (
    <IonPopover
      class={props.class}
      isOpen={props.isOpen}
      onWillDismiss={props.onWillDismiss}
      trigger={props.trigger}
      side={props.side}
      alignment={props.alignment}
    >
      <div className="body-popover">
        {props.menu.map((el, i) => {
          let search;
          el.status.forEach((q) => {
            if (q === props.status) search = q;
          });
          if (!search && search !== 0) return;
          return (
            <div key={i} className="cont-el-pop" onClick={el.click}>
              <Icons icon={el.icon} />
              <div className="text">{el.name}</div>
            </div>
          );
        })}
      </div>
    </IonPopover>
  );
};

export default PopoverTable;
