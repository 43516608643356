import { IonMenu, IonMenuToggle, useIonViewDidEnter } from "@ionic/react";
import { useAsyncEffect } from "../../../hooks/useAsyncEffect";
import Input from "../../../ui/Input";
import Select from "../../../ui/Select";
import Button from "../../../ui/Button";
import Icons from "../../../ui/Icons";
import { useEffect, useMemo, useRef, useState } from "react";
import L from "leaflet";
import { useDispatch, useSelector } from "react-redux";
import { setArrayPointsTour, setFormPointTour, setProducts } from "../../../store/actions";
import flagiconmap from "../../../image/icons/flag-icon-map.png";
import bediconmap from "../../../image/icons/bed-icon-map.png";
import ModalDelete from "../Modals/ModalDelete";
import "../../../less/css/custom.css";
import {
  deletePointPhotoTour,
  getPointTour,
  getToursOperator,
  postAddImagePointTour,
  postPointTour,
  putPointTour,
} from "../../../utils/api";
import { DEFAULT_POINT_TOURS } from "../../../utils/array";
import AutoCompleteSelect from "../../../ui/AutoCompleteSelect";
import { Marker } from "react-leaflet";
import { URL_ORIGIN } from "../../../utils/config";

const RightMenuCoor = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const searchEl = useRef();
  const formPointTour = useSelector((state) => state.formPointTour);
  const arrayPointsTour = useSelector((state) => state.arrayPointsTour);

  const sortArray = arrayPointsTour.sort((a, b) => (a.order > b.order ? 1 : -1));
  const [nmapis, setNmapis] = useState();
  const [newMarkers, setNewMarkers] = useState();
  const typePoint = useSelector((state) => state.typePoint);
  const [coorPoint, setCoorPoint] = useState();
  const [arrPhotoPoint, setArrPhotoPoint] = useState();
  const [modalDeleteOperUser, setModalDeleteOperUser] = useState(false);
  const [typePhoto, setTypePhoto] = useState();
  const formForCreateTour = useSelector((state) => state.formForCreateTour);
  const [searchPattern, setSearchPattern] = useState();
  const [textSearch, setTextSearch] = useState();

  const [list, setList] = useState();
  const [stageAddCoor, setStageAddCoor] = useState(1);
  const [menuOpen, setMenuOpen] = useState(false);
  const refMapEl = useRef();
  const refMenu = useRef();

  const [time, setTime] = useState();
  const [timezone, setTimezone] = useState();

  // const [maps, setMaps] = useState();
  const maps = useRef();
  const marker = useRef();
  var LeafIcon = L.Icon.extend({
    options: {
      iconSize: [32, 32],
    },
  });
  var flagIcon = new LeafIcon({ iconUrl: flagiconmap });
  var bedIcon = new LeafIcon({ iconUrl: bediconmap });

  const timeZone = [
    { name: "UTC+02:00 (Калининград)" },
    { name: "UTC+03:00 (Москва)" },
    { name: "UTC+04:00 (Самара)" },
    { name: "UTC+05:00 (Екатеринбург)" },
    { name: "UTC+06:00 (Омск)" },
    { name: "UTC+07:00 (Новосибирск)" },
    { name: "UTC+08:00 (Иркутск)" },
    { name: "UTC+09:00 (Якутск)" },
    { name: "UTC+10:00 (Владивосток)" },
    { name: "UTC+11:00 (Магадан)" },
    { name: "UTC+12:00 (Камчатка)" },
  ];

  var nmapi, map, navitelTile;
  var newMarker;

  const InitMap = async () => {
    if (stageAddCoor === 1) {
      map = L.map(refMapEl.current, {
        center: [55.75, 37.7],
        zoom: 8,
      });

      nmapi = await navitel.createWebMapApi("059ed2d4fdc4bd5a0ffc56eb88d3154ec8cc52a9", {
        locale: navitel.LocaleCode.ru_RU,
      });
      setNmapis(nmapi);
      navitelTile = nmapi.extension.leaflet.createTileLayer({
        locale: navitel.LocaleCode.ru_RU,
      });
      navitelTile.addTo(map);
      map?.on("click", onMapClick);
      if (coorPoint) {
        newMarker = new L.marker([coorPoint.lat, coorPoint.lng], {
          icon:
            formPointTour.point_type_id === 1
              ? flagIcon
              : formPointTour.point_type_id === 2 || formPointTour.point_type_id === 3
              ? bedIcon
              : flagIcon,
        });
        newMarker.addTo(map);
        marker.current = newMarker;
        // setNewMarkers(newMarker);
      }
      if (formPointTour.lat1 && formPointTour.lon1 && !coorPoint) {
        newMarker = new L.marker([formPointTour.lat1, formPointTour.lon1], {
          icon:
            formPointTour.point_type_id === 1
              ? flagIcon
              : formPointTour.point_type_id === 2 || formPointTour.point_type_id === 3
              ? bedIcon
              : flagIcon,
        });
        newMarker.addTo(map);
        map.panTo(new L.LatLng(formPointTour.lat1, formPointTour.lon1));
        // setNewMarkers(newMarker);
        marker.current = newMarker;
      }
      maps.current = map;
      // setMaps(map);
    }
  };
  useAsyncEffect(async () => {
    if ((await refMenu.current.isOpen()) === true) {
      InitMap();
    }
  }, [stageAddCoor]);

  function onMapClick(e) {
    setCoorPoint(e.latlng);

    if (marker.current && maps.current.hasLayer(marker.current)) {
      maps.current.removeLayer(marker.current);
      marker.current = null;
    }
    let newMarker = new L.marker([e.latlng.lat, e.latlng.lng], {
      icon:
        formPointTour.point_type_id === 1
          ? flagIcon
          : formPointTour.point_type_id === 2 || formPointTour.point_type_id === 3
          ? bedIcon
          : flagIcon,
    });
    newMarker.addTo(maps.current);
    marker.current = newMarker;
  }

  function onMapSearch(e) {
    setCoorPoint(e.latlng);
    if (marker.current && maps.current.hasLayer(marker.current)) {
      maps.current.removeLayer(marker.current);
      marker.current = null;
    }
    newMarker = new L.marker([e.latlng.lat, e.latlng.lng], {
      icon:
        formPointTour.point_type_id === 1
          ? flagIcon
          : formPointTour.point_type_id === 2 || formPointTour.point_type_id === 3
          ? bedIcon
          : flagIcon,
    });
    newMarker.addTo(maps.current);
    marker.current = newMarker;

    // setNewMarkers(newMarker);
    maps.current.panTo(new L.LatLng(e.latlng.lat, e.latlng.lng));
  }

  const spliteTime = formPointTour?.collect_time?.split(" ");
  const [typePointSel, setTypePoitSel] = useState();
  const [timeZoneSel, setTimeZoneSel] = useState();
  const formPointTourNamePointType = typePoint.find((el) => el.id === formPointTour.point_type_id);
  const formPointTourTimeZone = timeZone.find(
    (el) => el.name === `${spliteTime?.[1]} ${spliteTime?.[2]}`
  );

  const itemTemplate = (item) => {
    return (
      <div className="p-d-flex p-ai-center country-item">
        <div className="text">
          {item.address.street
            ? `${item.address.street} ${item.address.building}`
            : `${item.label}`}
        </div>
        <div className="subtext">{`${
          item.address.city ? item.address.city : item.address.region
        } `}</div>
      </div>
    );
  };

  useAsyncEffect(async () => {
    if (searchPattern?.length > 2) {
      await nmapis?.search.textSearch({ searchPattern }).then((e) => {
        setList(e);
      });
    }
  }, [searchPattern]);

  // useAsyncEffect(() => {
  //   if (!searchPattern.length) {
  //     setList(null);
  //   }
  // }, [searchPattern]);

  return (
    <IonMenu
      ref={refMenu}
      side="end"
      type="overlay"
      swipeGesture={false}
      menuId="menu-right-add-coor"
      contentId="right-add"
      className="menu-right-add-coor"
      onIonDidOpen={async (e) => {
        // setMenuOpen(e.target._isOpen);
        InitMap();
      }}
      onIonDidClose={async (e) => {
        // setMenuOpen(e.target._isOpen);
        await setStageAddCoor(1);
        setCoorPoint(null);
        setArrPhotoPoint(null);
        setTypePoitSel(null);
        setTimeZoneSel(null);
        setSearchPattern(null);
        setList(null);
        dispatch(setFormPointTour(DEFAULT_POINT_TOURS));
        maps?.current.remove();
      }}
    >
      {stageAddCoor === 1 ? (
        <div className={`menu`}>
          <div className="top">
            <div className="coordinates">
              {coorPoint
                ? `${coorPoint?.lat}°, ${coorPoint?.lng}° `
                : formPointTour.lat1 && formPointTour.lon1
                ? `${+formPointTour?.lat1}°, ${+formPointTour?.lon1}°`
                : ""}
            </div>
            <div className="cont-map-add-coor">
              <div className="map" id="map-add-coor" ref={refMapEl}></div>
            </div>
            <div className="inf-cont">
              <AutoCompleteSelect
                itemTemplate={itemTemplate}
                image="search"
                sleep={3}
                filter
                field="fullname"
                value={searchPattern}
                typesel="medium"
                className="search-input medium"
                placeholder="Поиск на карте..."
                array={list}
                // onFocus={() => {
                //   setSearchPattern(searchPattern);
                //   setTextSearch(!textSearch);
                // }}
                // onBlur={() => setSearchProm(null)}
                onChange={async (e) => {
                  if (typeof e.value === "string") {
                    setSearchPattern(e.value);
                  }
                  if (typeof e.value !== "string") {
                    onMapSearch({
                      latlng: {
                        lat: e.value.coordinates.latitude,
                        lng: e.value.coordinates.longitude,
                      },
                    });
                    // setSearchPattern(e.value);
                    // maps?.on("click", onMapClick());
                  }
                }}
                // onKeyPress={(e) => {
                //   console.log(e);
                //   if (e.code == "Enter") {
                //     setSearchPattern(e.target.value);
                //     setTextSearch(!textSearch);
                //   }
                // }}
              />
              <Input
                value={formPointTour.name || ""}
                className="medium inp-add-coor"
                noImage
                placeholder="Название точки маршрута"
                onChange={(e) => {
                  dispatch(setFormPointTour({ ...formPointTour, name: e }));
                }}
              />
              <Select
                value={typePointSel || formPointTourNamePointType || ""}
                val={formPointTour.point_type_id}
                typesel="medium"
                array={typePoint}
                className="medium select-line"
                onChange={(e) => {
                  setTypePoitSel(e.value);
                  dispatch(setFormPointTour({ ...formPointTour, point_type_id: e.value.id }));
                }}
                placeholder="Выберите из списка..."
              />
              {formPointTour.point_type_id === 1 && (
                <div className="time-cont">
                  <Input
                    value={spliteTime?.[0] || ""}
                    type="time"
                    className="medium time"
                    noImage
                    placeholder="12:00"
                    onChange={(e) => setTime(e)}
                  />
                  <Select
                    value={timeZoneSel || formPointTourTimeZone || ""}
                    typesel="medium"
                    array={timeZone}
                    val={spliteTime?.[2] || timeZone}
                    className="medium select-line"
                    onChange={(e) => {
                      setTimeZoneSel(e.value);
                      setTimezone(e.value.name);
                    }}
                    placeholder="Выберите из списка..."
                  />
                </div>
              )}
              <Input
                value={formPointTour.description || ""}
                onChange={(e) => {
                  dispatch(setFormPointTour({ ...formPointTour, description: e }));
                }}
                type="textarea"
                className="area textarea-add-coor"
                noImage
                placeholder="Описание точки маршрута"
              />
            </div>
          </div>
          <div className="btns">
            <IonMenuToggle menu="menu-right-add-coor">
              <Button
                typebtn="outline"
                className="small"
                onClick={() => {
                  setStageAddCoor(1);
                }}
              >
                Отмена
              </Button>
            </IonMenuToggle>
            <Button
              typebtn="muted"
              className="small"
              onClick={async () => {
                await maps.current.remove();
                // setMaps(null);
                maps.current = null;
                setStageAddCoor(stageAddCoor + 1);
                dispatch(
                  setFormPointTour({
                    ...formPointTour,
                    collect_time:
                      time && timezone
                        ? `${time} ${timezone}`
                        : formPointTour.collect_time
                        ? formPointTour.collect_time
                        : null,
                    lat1: coorPoint?.lat ? coorPoint?.lat : formPointTour.lat1,
                    lon1: coorPoint?.lng ? coorPoint?.lng : formPointTour.lon1,
                  })
                );
              }}
            >
              Далее
              <Icons icon="arrow-narrow-right" />
            </Button>
          </div>
        </div>
      ) : (
        stageAddCoor === 2 && (
          <div className="menu-images">
            <div className="top">
              <div className="text-cont">
                <div className="title">Добавьте фото</div>
                <div className="text">
                  Изображения будут выводиться вместе с описанием этой точки маршрута. Мы
                  рекомендуем не игнорировать этот шаг — фотографии мест помогают путешественникам
                  заинтересоваться и решиться на покупку тура.
                </div>
              </div>
              <div className="add-image-cont">
                <div className="text-cont">
                  <div className="text">Выберите или перетащите сюда файл(ы)</div>
                  <div className="subtext">Макс.: 5Мб каждый, всего не более 50Мб</div>
                </div>
                <div className="images">
                  {formPointTour.photos?.map((el, i) => {
                    return (
                      <div className="add-img" key={i}>
                        <Button
                          disabled={formForCreateTour.status_id === 2}
                          typebtn="elevated"
                          className="small cube btn-del-img"
                          onClick={() => {
                            setTypePhoto({ type: 2, id: el.id });
                            setModalDeleteOperUser(true);
                          }}
                        >
                          <Icons icon="design-personal-trash" />
                        </Button>
                        <img src={`${URL_ORIGIN}${el.photo}`} />
                      </div>
                    );
                  })}
                  {arrPhotoPoint?.map((el, i) => {
                    return (
                      <div className="add-img" key={i}>
                        <Button
                          typebtn="elevated"
                          className="small cube btn-del-img"
                          onClick={() => {
                            setTypePhoto({ type: 1, id: i });
                            setModalDeleteOperUser(true);
                          }}
                        >
                          <Icons icon="design-personal-trash" />
                        </Button>
                        <img src={URL.createObjectURL(el)} />
                      </div>
                    );
                  })}
                  <input
                    type="file"
                    name="file"
                    id="file-other-photo-point"
                    className="inputfile"
                    accept="image/jpeg, image/png"
                    multiple
                    onChange={(e) => {
                      let arr = [];
                      for (var i = 0; i < e.target.files.length; i++) {
                        arr.push(e.target.files[i]);
                      }
                      if (arrPhotoPoint)
                        for (var i = 0; i < arrPhotoPoint.length; i++) {
                          arr.push(arrPhotoPoint[i]);
                        }
                      setArrPhotoPoint(arr);
                    }}
                  />
                  <label className="add-img" htmlFor="file-other-photo-point">
                    <Icons icon="photo-plus" />
                  </label>
                </div>
              </div>
            </div>
            <div className="btns">
              <Button
                typebtn="outline"
                className="small"
                onClick={() => {
                  setStageAddCoor(stageAddCoor - 1);
                }}
              >
                <Icons icon="arrow-narrow-left" />
                Назад
              </Button>
              <IonMenuToggle menu="menu-right-add-coor">
                <Button
                  disabled={formForCreateTour.status_id === 2}
                  typebtn="bright"
                  className="small"
                  onClick={() => {
                    setStageAddCoor(1);
                    if (formPointTour.id) {
                      let arrc = { ...formPointTour };
                      delete arrc.info;
                      putPointTour(token, formForCreateTour.id, arrc, formPointTour.id).then(
                        async (e) => {
                          if (!e.data.errors) {
                            await getPointTour(token, formForCreateTour.id).then(async (e) => {
                              dispatch(setArrayPointsTour(e.data.data));
                            });
                            const formData = new FormData();
                            arrPhotoPoint &&
                              Object.entries(arrPhotoPoint).forEach(([key, value]) => {
                                formData.append(`detail_pictures[]`, value);
                              });
                            arrPhotoPoint &&
                              postAddImagePointTour(
                                token,
                                formData,
                                arrayPointsTour[arrayPointsTour.length - 1]?.id
                              ).then(async (e) => {
                                if (!e.data.errors) {
                                  await getPointTour(token, formForCreateTour.id).then(
                                    async (e) => {
                                      dispatch(setArrayPointsTour(e.data.data));
                                    }
                                  );
                                }
                              });
                            getToursOperator(token).then((e) => {
                              if (e.data.data) {
                                dispatch(setProducts(e.data.data));
                              }
                            });
                          }
                        }
                      );
                    } else {
                      let arrc = {
                        ...formPointTour,
                        order: sortArray.length ? sortArray[sortArray.length - 1]?.order + 1 : 0,
                      };

                      postPointTour(token, formForCreateTour.id, arrc).then(async (e) => {
                        if (!e.data.errors) {
                          await getPointTour(token, formForCreateTour.id).then(async (e) => {
                            dispatch(setArrayPointsTour(e.data.data));
                          });
                          const formData = new FormData();
                          arrPhotoPoint &&
                            Object.entries(arrPhotoPoint).forEach(([key, value]) => {
                              formData.append(`detail_pictures[]`, value);
                            });
                          arrPhotoPoint &&
                            postAddImagePointTour(token, formData, e.data.data.id).then(
                              async (e) => {
                                if (!e.data.errors) {
                                  await getPointTour(token, formForCreateTour.id).then(
                                    async (e) => {
                                      dispatch(setArrayPointsTour(e.data.data));
                                    }
                                  );
                                }
                              }
                            );
                          getToursOperator(token).then((e) => {
                            if (e.data.data) {
                              dispatch(setProducts(e.data.data));
                            }
                          });
                        }
                      });
                    }
                  }}
                >
                  Сохранить
                </Button>
              </IonMenuToggle>
            </div>
          </div>
        )
      )}
      <ModalDelete
        open={modalDeleteOperUser}
        onClose={setModalDeleteOperUser}
        title="Удаление фото"
        text="Вы действительно хотите удалить фото?"
        btn_delete="Да, удалить"
        onClick={() => {
          if (typePhoto.type === 1) {
            let arr = arrPhotoPoint;
            arr.splice(typePhoto.id, 1);
            setArrPhotoPoint(arr);
          } else if (typePhoto.type === 2) {
            deletePointPhotoTour(token, formPointTour.id, typePhoto.id).then((e) => {
              if (!e.data.error) {
                let arr = formPointTour.photos;
                let newarr = arr.filter((el) => el.id !== typePhoto.id);
                dispatch(
                  setFormPointTour({
                    ...formPointTour,
                    photos: newarr,
                  })
                );
              }
              getPointTour(token, formForCreateTour.id).then((e) => {
                dispatch(setArrayPointsTour(e.data.data));
              });
            });
          }

          setModalDeleteOperUser(false);
        }}
      />
    </IonMenu>
  );
};

export default RightMenuCoor;
