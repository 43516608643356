import { useState } from "react";
import Input from "../../ui/Input";
import AlertBox from "../../ui/AlertBox";
import Button from "../../ui/Button";
import { useDispatch, useSelector } from "react-redux";
import Icons from "../../ui/Icons";
import {
  getPartnerUsers,
  getPartnerUsersByID,
  postChangePassword,
  putPartnerUsers,
} from "../../utils/api";
import { setProfile } from "../../store/actions";

const ProfileInf = (props) => {
  const dispatch = useDispatch();
  const operator = useSelector((state) => state.userInfo.clientInfo);
  const client = useSelector((state) => state.profile);
  const token = useSelector((state) => state.token);
  const [email, setEmail] = useState();
  const [changeEmail, setChangeEmail] = useState(false);
  const [alertEmail, setAlertEmail] = useState(false);
  const [pass, setPass] = useState();
  const [reppass, setReppass] = useState();
  const checkEmail = email
    ?.toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  return (
    <div className="profile-inf">
      <form
        id="profile-inf"
        className="profile-inf"
        onSubmit={async (e) => {
          e.preventDefault();
          props.setLoading(true);
          let documentInfo = {};
          let inputs = e.target.querySelectorAll("input");
          inputs.forEach((input) => (documentInfo[input.name] = input.value ? input.value : null));
          let arr = {
            ...documentInfo,
            phone: documentInfo?.phone.replace(/\D/g, ""),
            login: client?.user?.login,
          };

          await putPartnerUsers(token, operator.user.id, arr)
            .then(async (e) => {
              if (!e.data.errors) {
                changeEmail && setAlertEmail(true);
                await getPartnerUsersByID(token, operator.user.id).then((e) => {
                  dispatch(setProfile(e.data.data));
                });
              }
              props.setLoading(false);
            })
            .catch((e) => {
              props.setLoading(false);
            });
          // if (pass && reppass && pass === reppass) {
          //   let arr = {
          //     password: pass,
          //     password_confirmation: reppass,
          //   };
          //   postChangePassword(token, arr).then((e) => {});
          // }
        }}
      >
        <div className="line-input">
          <div className="text">Имя</div>
          <Input
            value={client?.user?.first_name}
            name="first_name"
            className="input-profile large"
            placeholder="Имя"
            noImage={true}
          />
        </div>
        <div className="line-input">
          <div className="cont-text-descript">
            <div className="text-disc">Отчество</div>
            <div className="dop-text-disc">не обязательно</div>
          </div>

          <Input
            value={client?.user?.middle_name}
            name="middle_name"
            className="input-profile large"
            placeholder="Отчество"
            noImage={true}
          />
        </div>
        <div className="line-input">
          <div className="text">Фамилия</div>
          <Input
            value={client?.user?.last_name}
            name="last_name"
            className="input-profile large"
            placeholder="Фамилия"
            noImage={true}
          />
        </div>
        <div className="line-input">
          <div className="text">Телефон</div>
          <Input
            value={client?.user?.phone}
            name="phone"
            className="input-profile large"
            placeholder="+7 (___) ___-__-__"
            noImage={true}
            type="tel"
          />
        </div>
        <div className="line-input">
          <div className="text">E-mail</div>
          <Input
            value={client?.user?.email}
            name="email"
            type="email"
            className="input-profile large"
            placeholder="company@mail.com"
            noImage={true}
            onChange={(e) => {
              setEmail(e);
              setChangeEmail(true);
            }}
          />
        </div>
        {alertEmail && (
          <div className="alert-place">
            <div className="repeat-cont">
              <AlertBox
                onClick={() => setChangeEmail(false)}
                type="warning"
                className="alert-profile-email"
                text="Email был изменён. Необходимо подтвердить изменения по ссылке, которую мы отправили на новый email"
              />
              <Button
                className="small repeat-message"
                typebtn="outline"
                type="sumbit"
                form="profile-inf"
              >
                <Icons icon="refresh" className="image icon-outline" />
                <div>Выслать повторно</div>
              </Button>
            </div>
          </div>
        )}
      </form>
      <Button
        loading={props.loading}
        disabled={props.loading}
        type="sumbit"
        form="profile-inf"
        className="btn-save medium "
        typebtn="bright"
      >
        Сохранить
      </Button>
      {/* <div className="line-input">
        <div className="text">Новый пароль</div>
        <Input
          // name="password"
          type="password"
          toggle={false}
          className="input-profile large"
          placeholder="введите пароль..."
          onChange={(e) => setPass(e)}
        />
      </div>
      <div className="line-input">
        <div className="text">Новый пароль ещё раз</div>
        <Input
          // name="repeat-password"
          type="password"
          className="input-profile large"
          placeholder="введите пароль..."
          noImage={true}
          onChange={(e) => setReppass(e)}
        />
      </div> */}
    </div>
  );
};

export default ProfileInf;
