import { IonContent, useIonViewWillLeave } from "@ionic/react";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import qs from "qs";
import { useHistory } from "react-router";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { useEffect, useState } from "react";
import {
  setArrayPointsTour,
  setFormCreateOrderClient,
  setModalLogin,
  setProfile,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getClient,
  getProductsClientByID,
  getProductsGuestByID,
  postOrderPay,
  postOrdersClient,
} from "../../utils/api";
import Input from "../../ui/Input";
import Rating from "../../ui/Rating";
import Checkbox from "../../ui/Checkbox";
import Footer from "../../ui/Footer";

import visa from "../../image/social/visa.svg";
import mir from "../../image/social/mir.svg";
import mastercard from "../../image/social/mastercard.svg";
import Toggle from "../../ui/Toggle";
import Toast from "../../ui/Toast";
import { countProm } from "../../utils/utils";
import { URL_ORIGIN } from "../../utils/config";

const ClientCheckout = () => {
  const history = useHistory();
  const token = useSelector((state) => state.token);

  const [oferta, setOferta] = useState(false);
  const [rules, setRules] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  const dispatch = useDispatch();
  const [optionsCost, setOptionsCoist] = useState(0);
  const formCreateOrderClient = useSelector((state) => state.formCreateOrderClient);
  const [phone, setPhone] = useState();
  const profile = useSelector((state) => state.profile);
  const urlSearch = qs.parse(history.location.search.substr(1));
  const [element, setElement] = useState();
  const reviews = element?.clients_reviews.filter((el) => el.type === 0 && !el.parent_id);
  const filtDet = element?.details;
  const details = filtDet?.sort(function (a, b) {
    return a.order - b.order;
  });
  const [loading, setLoading] = useState(false);

  const race = element?.races?.find((el) => el.id === +urlSearch?.race);
  const currentraces = race?.operator_options?.filter((arr) =>
    urlSearch?.option.includes(String(arr?.id))
  );
  const options = typeof urlSearch?.option === "string" ? [+urlSearch?.option] : urlSearch?.option;
  // console.log("OPTIONS", options);
  // console.log("PROFILE", profile);
  // console.log("DETAILS", details);
  // console.log("RACE", race);
  // console.log("ELEMENT", element);

  // console.log("race", race, "element", element, "optionsCost", optionsCost);

  useEffect(() => {
    let cost = 0;
    for (let i = 0; i < race?.operator_options?.length; i++) {
      for (let j = 0; j < options?.length; j++) {
        if (race?.operator_options[i]?.id == options?.[j]) {
          cost += +race?.operator_options[i]?.basic_cost;
        }
      }
    }
    setOptionsCoist(cost);
  }, [race]);

  let today = new Date().getTime();

  let arrayPromDataRange = element?.operator_promotions?.filter((e) => e.type == 0);
  arrayPromDataRange = arrayPromDataRange?.filter(
    (e) =>
      new Date(race?.start_date).getTime() >= new Date(e.start_date).getTime() &&
      new Date(race?.start_date).getTime() <= new Date(e.end_date).getTime()
  );

  // console.log("arrayPromDataRange", arrayPromDataRange);
  console.log("CLIENT", formCreateOrderClient);

  useAsyncEffect(() => {
    if (token) {
      getClient(token).then(async (e) => {
        dispatch(setProfile(e.data.user));
        let arrAdult = formCreateOrderClient?.adult ? formCreateOrderClient?.adult : [];
        for (let i = 0; i < urlSearch?.adults - 1; i++) {
          arrAdult.push({ first_name: null, last_name: null, id: arrAdult.length + 1, ismain: 0 });
        }
        setPhone(e.data.user?.phone);
        await dispatch(
          setFormCreateOrderClient({
            ...formCreateOrderClient,
            main: {
              last_name: e.data.user?.last_name,
              first_name: e.data.user?.first_name,
              middle_name: e.data.user?.middle_name,
              phone: e.data.user?.phone.replace(/\D/g, ""),
              birth_date: e.data.user?.birth_date,
              ismain: 1,
              id: 1,
            },
            adult: arrAdult.length ? arrAdult : [],
          })
        );
      });
    }
  }, [token]);

  useAsyncEffect(async () => {
    if (urlSearch.id === undefined) return;
    if (token) {
      getProductsClientByID(token, urlSearch?.id).then(async (e) => {
        if (!e.data.errors) {
          setElement(e.data.data);
          if (!token) {
            await dispatch(
              setFormCreateOrderClient({
                ...formCreateOrderClient,
                main: {
                  last_name: null,
                  first_name: null,
                  middle_name: null,
                  phone: null,
                  birth_date: null,
                  ismain: 1,
                  id: 1,
                },
              })
            );
            let arr = formCreateOrderClient?.adult ? formCreateOrderClient?.adult : [];
            await dispatch(
              setFormCreateOrderClient({
                ...formCreateOrderClient,
                adult: arr,
              })
            );
          }
          // dispatch(setArrayPointsTour(e.data.data.details));
        }
      });
    } else {
      getProductsGuestByID(urlSearch.id).then(async (e) => {
        if (!e.data.errors) {
          setElement(e.data.data);
          await dispatch(
            setFormCreateOrderClient({
              ...formCreateOrderClient,
              main: {
                last_name: null,
                first_name: null,
                middle_name: null,
                phone: null,
                birth_date: null,
                ismain: 1,
                id: 1,
              },
            })
          );
          let arr = formCreateOrderClient?.adult ? formCreateOrderClient?.adult : [];
          await dispatch(
            setFormCreateOrderClient({
              ...formCreateOrderClient,
              adult: arr,
            })
          );
          // dispatch(setArrayPointsTour(e.data.data.details));
        }
      });
    }
  }, []);

  const onPay = async () => {
    let arrParticipants = [];
    arrParticipants.push(formCreateOrderClient.main);
    for (let i = 0; i < formCreateOrderClient.adult.length; i++) {
      arrParticipants.push(formCreateOrderClient.adult[i]);
    }

    let forSend = {
      product_id: element?.id,
      race_id: urlSearch?.race,
      summa: countProm(arrayPromDataRange, race.summa) + +optionsCost,
      currency_id: "RUB",
      status_id: 1,
      participants: arrParticipants,
    };
    if (urlSearch?.option) {
      forSend.operator_options =
        typeof urlSearch?.option === "string" ? [+urlSearch.option] : urlSearch.option;
    }
    if (
      formCreateOrderClient?.main?.first_name &&
      formCreateOrderClient?.main?.last_name &&
      formCreateOrderClient?.main?.phone &&
      formCreateOrderClient?.main?.birth_date
    ) {
      await postOrdersClient(token, forSend)
        .then(async (e) => {
          if (!e.data.errors) {
            let res = e.data.order;
            await postOrderPay(token, res?.id, { summa: +res?.summa, debugmode: 0 })
              .then((e) => {
                if (e.data.result.status === "SUCCESS") {
                  window.location.href = e.data.result?.paymentResult?.url;
                  setLoading(false);
                }
              })
              .catch((e) => {
                setOpenToast(true);
                setMessageToast({
                  text: e.response.data.errors || "Ошибка оплаты",
                  type: "error",
                });
                setLoading(false);
              });
          } else {
            setOpenToast(true);
            setMessageToast({ text: e.data.errors || "Заполните участников", type: "error" });
            setLoading(false);
          }
        })
        .catch((e) => {
          setOpenToast(true);
          setMessageToast({
            text: e.response.data.errors || "Заполните участников",
            type: "error",
          });
          setLoading(false);
        });
    } else {
      setOpenToast(true);
      setMessageToast({ text: "Заполните участников", type: "error" });
      setLoading(false);
    }
  };
  console.log("ELEMENT", race?.operator_options);
  return (
    <IonContent className="page-guest">
      <div className="checkout">
        <div className="checkout-cont">
          <div className="title-cont">
            <div className="back">
              <Button
                typebtn="outline"
                className="small"
                onClick={() => {
                  dispatch(setFormCreateOrderClient({}));
                  history.push(`/tour?id=${urlSearch.id}`);
                }}
              >
                <Icons icon="arrow-narrow-left" />
                назад
              </Button>
            </div>
            <div className="title">Тур скоро будет ваш</div>
          </div>
          <div className="cont-form">
            <div className="left">
              <div className="title">
                <div className="text">Путешественники</div>
                <div className="subtext">{`${
                  (formCreateOrderClient?.adult ? formCreateOrderClient?.adult?.length : 0) + 1
                } / ${element?.number_travelers}`}</div>
              </div>
              <div className="traveler">
                <div className="title">Основной путешественник</div>
                <div className="line-input">
                  <div className="text">Фамилия</div>
                  <Input
                    value={formCreateOrderClient?.main?.last_name}
                    name="last_name"
                    className="input-profile large"
                    placeholder="Фамилия"
                    noImage={true}
                    onChange={(e) => {
                      dispatch(
                        setFormCreateOrderClient({
                          ...formCreateOrderClient,
                          main: {
                            ...formCreateOrderClient.main,
                            last_name: e,
                          },
                        })
                      );
                    }}
                  />
                </div>
                <div className="line-input">
                  <div className="text">Имя</div>
                  <Input
                    value={formCreateOrderClient?.main?.first_name}
                    name="first_name"
                    className="input-profile large"
                    placeholder="Имя"
                    noImage={true}
                    onChange={(e) => {
                      dispatch(
                        setFormCreateOrderClient({
                          ...formCreateOrderClient,
                          main: {
                            ...formCreateOrderClient.main,
                            first_name: e,
                          },
                        })
                      );
                    }}
                  />
                </div>
                <div className="line-input">
                  <div className="cont-text-descript">
                    <div className="text-disc">Отчество</div>
                    <div className="dop-text-disc">не обязательно</div>
                  </div>

                  <Input
                    value={formCreateOrderClient?.main?.middle_name}
                    name="middle_name"
                    className="input-profile large"
                    placeholder="Отчество"
                    noImage={true}
                    onChange={(e) => {
                      dispatch(
                        setFormCreateOrderClient({
                          ...formCreateOrderClient,
                          main: {
                            ...formCreateOrderClient.main,
                            middle_name: e,
                          },
                        })
                      );
                    }}
                  />
                </div>
                <div className="line-input">
                  <div className="text">Телефон</div>
                  <Input
                    value={phone}
                    name="phone"
                    className="input-profile large"
                    placeholder="+7"
                    noImage={true}
                    type="tel"
                    onChange={(e) => {
                      setPhone(e);
                    }}
                  />
                </div>
                <div className="line-input">
                  <div className="text">Дата рождения</div>
                  <Input
                    value={
                      formCreateOrderClient?.main?.birth_date
                        ? new Date(formCreateOrderClient?.main?.birth_date).toLocaleDateString()
                        : null
                    }
                    name="birth_date"
                    className="input-profile large"
                    placeholder="Дата рождения"
                    noImage={true}
                    type="date"
                    onChange={(e) => {
                      dispatch(
                        setFormCreateOrderClient({
                          ...formCreateOrderClient,
                          main: {
                            ...formCreateOrderClient.main,
                            birth_date: e,
                          },
                        })
                      );
                    }}
                  />
                </div>
              </div>
              {formCreateOrderClient?.adult &&
                formCreateOrderClient?.adult.map((el, i) => {
                  return (
                    <div key={i} className="traveler">
                      <div className="title">{`Путешественник №${i + 1 + 1}`}</div>
                      <div className="line-input">
                        <div className="text">Фамилия</div>
                        <Input
                          value={el?.last_name}
                          name="last_name"
                          className="input-profile large"
                          placeholder="Фамилия"
                          noImage={true}
                          onChange={(e) => {
                            let arr = formCreateOrderClient.adult;
                            arr[i].last_name = e;
                            dispatch(
                              setFormCreateOrderClient({ ...formCreateOrderClient, adult: arr })
                            );
                          }}
                        />
                      </div>
                      <div className="line-input">
                        <div className="text">Имя</div>
                        <Input
                          value={el?.first_name}
                          name="first_name"
                          className="input-profile large"
                          placeholder="Имя"
                          noImage={true}
                          onChange={(e) => {
                            let arr = formCreateOrderClient.adult;
                            arr[i].first_name = e;
                            dispatch(
                              setFormCreateOrderClient({ ...formCreateOrderClient, adult: arr })
                            );
                          }}
                        />
                      </div>

                      <div className="line-input">
                        <div className="text">Дата рождения</div>
                        <Input
                          value={
                            el?.birth_date ? new Date(el?.birth_date).toLocaleDateString() : null
                          }
                          name="birth_date"
                          className="input-profile large"
                          placeholder="Дата рождения"
                          noImage={true}
                          type="date"
                          onChange={(e) => {
                            let arr = formCreateOrderClient.adult;
                            arr[i].birth_date = e;
                            dispatch(
                              setFormCreateOrderClient({ ...formCreateOrderClient, adult: arr })
                            );
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              {((element?.number_travelers > 1 &&
                formCreateOrderClient?.adult?.length + 1 < element?.number_travelers) ||
                !formCreateOrderClient.adult) && (
                <div className="btn-add">
                  <Button
                    className="medium"
                    typebtn="outline"
                    onClick={() => {
                      let arr = formCreateOrderClient?.adult ? formCreateOrderClient?.adult : [];
                      arr.push({
                        first_name: null,
                        last_name: null,

                        ismain: 0,
                      });
                      dispatch(
                        setFormCreateOrderClient({
                          ...formCreateOrderClient,
                          adult: arr,
                        })
                      );
                    }}
                  >
                    <Icons icon="plus" />
                    Добавить
                  </Button>
                </div>
              )}
            </div>
            <div className="right">
              <div className="topay">
                <div className="rating">
                  <Rating rate={element?.rating?.[0]?.avg ? element?.rating?.[0]?.avg : 0} />
                  <div className="val-rate">
                    {element?.rating?.[0]?.avg ? element?.rating?.[0]?.avg : 0}
                  </div>
                  <div className="reviews">{`${reviews?.length} отзывов`}</div>
                </div>
                <div className="cont-info">
                  <div className="title">
                    <div className="cont-image">
                      <img src={`${URL_ORIGIN}${element?.photo}`} />
                    </div>
                    <div className="text">{element?.name}</div>
                  </div>
                  <div className="line">
                    <div className="subtext">Даты</div>
                    <div className="text">{`${new Date(race?.start_date).toLocaleDateString("ru", {
                      day: "numeric",
                    })} ${new Date(race?.start_date).toLocaleDateString("ru", {
                      month: "short",
                    })} — ${new Date(race?.end_date).toLocaleDateString("ru", {
                      day: "numeric",
                    })} ${new Date(race?.end_date).toLocaleDateString("ru", {
                      month: "short",
                    })}`}</div>
                  </div>
                  <div className="line">
                    <div className="subtext">Формат</div>
                    <div className="text">{element?.duration?.name}</div>
                  </div>
                  <div className="line">
                    <div className="subtext">Класс обслуживания</div>
                    <div className="text">{element?.class?.name}</div>
                  </div>
                  <div className="line">
                    <div className="subtext">Доп. опции</div>
                    {currentraces?.length
                      ? currentraces?.map((el, i) => {
                          return (
                            (typeof urlSearch?.option === "string"
                              ? +urlSearch?.option === el?.id
                              : urlSearch?.option?.find((q) => +q === el?.id)) && (
                              <div key={i} className="text">
                                {el?.option?.name}
                              </div>
                            )
                          );
                        })
                      : "-"}
                  </div>
                </div>
                <div className="l" />
                <div className="price-cont">
                  <div className="price">
                    <div className="text">Стоимость тура:</div>
                    <div className="row-price">
                      {arrayPromDataRange?.length > 0 && race ? (
                        <div className="last-price">{`${String(+race.summa).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          " "
                        )}`}</div>
                      ) : undefined}
                      <div
                        className={`text ${
                          arrayPromDataRange?.length > 0 && race ? "promotion" : ""
                        }`}
                      >
                        {race
                          ? `${String(countProm(arrayPromDataRange, race.summa)).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              " "
                            )} ₽`
                          : `${String(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}
                      </div>
                    </div>
                  </div>
                  <div className="price">
                    <div className="text">Доп. опции:</div>
                    <div className="text">{`${String(optionsCost).replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      " "
                    )} ₽`}</div>
                  </div>
                  <div className="price">
                    <div className="text-b">Всего:</div>
                    <div className="text-b">
                      {race
                        ? `${String(
                            countProm(arrayPromDataRange, race?.summa) + optionsCost
                          ).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`
                        : `${String(+0).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}
                    </div>
                  </div>
                </div>
                <div className="l" />
                <div className="checkbox-conter">
                  <Checkbox
                    checked={oferta}
                    id={"checkbox"}
                    className="checkbox"
                    position="top"
                    onChange={(e) => {
                      setOferta(e.target.checked);
                    }}
                  >
                    <div className="cont-text">
                      Принимаю <a className="link">оферту</a>
                    </div>
                  </Checkbox>
                  <Checkbox
                    checked={rules}
                    id={"checkbox"}
                    className="checkbox"
                    position="top"
                    onChange={(e) => {
                      setRules(e.target.checked);
                    }}
                  >
                    <div className="cont-text">
                      Соглашаюсь с <a className="link">правилами сервиса</a>
                    </div>
                  </Checkbox>
                  <Checkbox
                    checked={privacy}
                    id={"checkbox"}
                    className="checkbox"
                    position="top"
                    onChange={(e) => {
                      setPrivacy(e.target.checked);
                    }}
                  >
                    <div className="cont-text">
                      Соглашаюсь с <a className="link">политикой конфиденциальности</a>
                    </div>
                  </Checkbox>
                </div>

                <Button
                  disabled={!oferta || !rules || !privacy || loading}
                  loading={loading}
                  typebtn="bright"
                  className="large"
                  onClick={() => {
                    if (token) {
                      setLoading(true);
                      onPay();
                    } else {
                      dispatch(setModalLogin({ open: true }));
                      setLoading(false);
                    }
                  }}
                >
                  К оплате <Icons icon="arrow-narrow-right" />
                </Button>
              </div>
              <div className="descr">
                <div className="payment">
                  <img src={visa} />
                  <img src={mastercard} />
                  <img src={mir} />
                </div>
                <div className="text">
                  Покупка тура происходит сразу после успешной онлайн-оплаты.
                </div>
                <div className="tetxt">
                  <div className="text">Бесплатная отмена в течение 24 часов.</div>
                  <div className="row">
                    <div className="text">Ознакомьтесь c</div>
                    <div className="link">{` правилами отмены.`}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </IonContent>
  );
};

export default ClientCheckout;
