import { IonModal } from "@ionic/react";

import Close from "../../../ui/Close";
import Input from "../../../ui/Input";
import Button from "../../../ui/Button";
import AlertBox from "../../../ui/AlertBox";
import Checkbox from "../../../ui/Checkbox";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";

import { useHistory } from "react-router";
import { changeStatusProducts, getToursOperator } from "../../../utils/api";
import { setProducts } from "../../../store/actions";
import Toast from "../../../ui/Toast";

const ModalDeleteTour = (props) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const token = useSelector((state) => state.token);
  const history = useHistory();
  const urlSearch = qs.parse(history.location.search.substr(1));
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });

  const onDelete = () => {
    changeStatusProducts(token, props.focusElement?.id, 7)
      .then((e) => {
        if (!e.data.errors) {
          getToursOperator(token).then((e) => {
            if (e.data.data) {
              dispatch(setProducts(e.data.data));
            }
          });
          if (urlSearch?.id) {
            history.push(`/partner/showcase/tours?page=1`);
          }
          setOpenToast(true);
          setMessageToast({
            text: "Тур удален!",
            type: "success",
          });
        }
      })
      .catch((e) => {
        setOpenToast(true);
        setMessageToast({
          text: e.response.data.errors || "Ошибка удаления",
          type: "error",
        });
      });
  };
  return (
    <>
      <IonModal
        className="modal-charge-fine"
        isOpen={props.open}
        onWillDismiss={() => {
          props.onClose(false);
          setChecked(false);
        }}
      >
        <Close onClick={() => props.onClose(false)} />
        <div className="body-modal-cancel-order">
          <div className="title">Удаление тура</div>
          <div className="text">
            Вы точно хотите удалить тур? Существующие заказы не будут удалены.
          </div>

          <AlertBox type="warning" text="Восстановить данные после удаления нельзя." noClose />
          <Checkbox position="center" onChange={(e) => setChecked(e.target.checked)}>
            Я понимаю
          </Checkbox>
          <div className="btns">
            <Button typebtn="outline" className="small" onClick={() => props.onClose(false)}>
              Отмена
            </Button>
            <Button
              disabled={!checked}
              typebtn="bright"
              className="small"
              onClick={async () => {
                await onDelete();
                props.onClose(false);
              }}
            >
              Подтвердить
            </Button>
          </div>
        </div>
      </IonModal>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </>
  );
};

export default ModalDeleteTour;
