import { IonModal } from "@ionic/react";
import Select from "../Select";
import Input from "../Input";
import Button from "../Button";
import Close from "../Close";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import qs from "qs";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";

const ModalFilterTableEmployees = (props) => {
  const history = useHistory();
  const urlSearch = qs.parse(history.location.search.substr(1));
  const operatorUsers = useSelector((state) => state.operator_users);
  const [filterRole, setFilterRole] = useState();

  const arrRole = [{ name: "Администратор" }, { name: "Сотрудник" }];

  const DEFAULT = {
    tours_from: "",
    tours_until: "",
  };
  const [filterElements, setFilterElements] = useState(DEFAULT);

  useAsyncEffect(() => {
    if (!urlSearch?.role) {
      setFilterRole(null);
    } else {
      let urlF = urlSearch?.role;
      let find = arrRole?.find((el) => el.name === urlF);
      setFilterRole(find);
    }
  }, [props.open]);

  const removeEmpty = (obj) =>
    Object.entries(obj)
      .filter(([, v]) => v != null && v !== "")
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

  const func = (obj1, obj2) => {
    let result = {};
    for (let prop in obj1) {
      if (
        obj2.hasOwnProperty(prop) ||
        prop === "page" ||
        prop === "date_from" ||
        prop === "date_until" ||
        prop === "name_email"
      )
        result[prop] = obj1[prop];
    }
    return result;
  };

  const onApply = () => {
    let data = urlSearch;
    let array = filterElements;
    let filter = removeEmpty(array);
    data = func(data, filter);
    let merge = Object.assign(data, filter);
    merge.page = 1;

    if (filterRole) {
      let f = [];
      f.push(filterRole?.name);

      if (f.length) {
        merge.role = f;
      } else {
        delete merge?.role;
      }
    }

    const params = new URLSearchParams(merge);
    history.push(`?${params.toString()}`);
    props.onClose(false);
  };
  useEffect(() => {
    setFilterElements(urlSearch);
  }, [props.open]);

  return (
    <IonModal
      className="filter-table-operator_users"
      isOpen={props.open}
      onWillDismiss={() => {
        props.onClose(false);
        setFilterElements(DEFAULT);
      }}
    >
      <div className="body-modal">
        <Close
          onClick={() => {
            props.onClose(false);
            setFilterElements(DEFAULT);
          }}
        />
        <div className="title">Фильтры</div>
        <div className="body">
          <Select
            value={filterRole || ""}
            array={arrRole}
            typesel="small"
            optionLabel="name"
            className="small"
            onChange={(e) => {
              setFilterRole(e.value);
            }}
            placeholder="роль: все"
          />
          <div className="line ">
            <Input
              value={
                urlSearch?.last_login_from
                  ? filterElements?.last_login_from &&
                    new Date(+filterElements?.last_login_from).toLocaleDateString()
                  : ""
              }
              type="date"
              name="last_login_from"
              className="small input-short"
              placeholder="последний вход от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, last_login_from: new Date(e).getTime() });
              }}
            />

            <Input
              value={
                urlSearch?.last_login_until
                  ? filterElements?.last_login_until &&
                    new Date(+filterElements?.last_login_until).toLocaleDateString()
                  : ""
              }
              type="date"
              name="last_login_until"
              className="small input-short"
              placeholder="последний вход до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, last_login_until: new Date(e).getTime() });
              }}
            />
          </div>
        </div>
        <div className="buttons">
          <Button
            className="small"
            typebtn="outline"
            onClick={async () => {
              // props.onClose(false)
              setFilterRole(null);
              setFilterElements(DEFAULT);
              let data = urlSearch;
              let newd = {};
              newd.page = 1;
              if (data?.name_email) newd.name_email = data?.name_email;
              if (data?.date_from) newd.date_from = data?.date_from;
              if (data?.date_until) newd.date_until = data?.date_until;
              const params = new URLSearchParams(newd);
              history.push(`?${params.toString()}`);
            }}
          >
            Сбросить
          </Button>
          <Button
            className="small"
            typebtn="bright"
            onClick={() => {
              onApply();
            }}
          >
            Применить
          </Button>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalFilterTableEmployees;
