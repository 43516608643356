import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../less/css/custom.css";

const InputCode = (props) => {
  const PLUG_SMS = ["", "", "", ""];
  const [verification, setVerification] = useState(PLUG_SMS);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!!props.successSMS) return;
  //   setVerification(PLUG_SMS);
  // }, [props.successSMS]);

  // const postSMSONServer = async (code) => {
  //   postCodeSMS(timeTokenRX, { code: code })
  //     .then((e) => {
  //       if (e.data.success) {
  //         var hashSMS = CryptoJS.SHA256(code).toString();
  //         dispatch(setHashSMS(hashSMS));
  //         dispatch(setNumberSuccess(true));
  //       }
  //       if (!e.data.success) {
  //         props.setSuccessSMS("error");
  //         return;
  //       }
  //       dispatch(setTokenArr(JSON.stringify(e.data.data)));

  //       props.exit({ token: e.data.data.token });
  //     })
  //     .catch(async (e) => {
  //       await sleep(500);
  //       console.log("el er", e);
  //     });
  // };
  const numberCode = document.querySelector(".number-code");

  function changeInput(i, e) {
    function filterRange(arr, index, el) {
      let array = arr;
      array.splice(index, 1, el);
      // if (el === "" || el === null || el === undefined) {
      //   numberCode.getElementsByTagName("input")?.[i - 1]?.focus();
      // }
      return [...array];
    }

    const numberCode = document.querySelector(".number-code");

    setVerification(filterRange(verification, i, e)); //nn

    if (e && e.length === 1) {
      if (i < 3) {
        numberCode.getElementsByTagName("input")[i + 1].focus();
      }
      if (e && i === 3) {
        let arrayNum = filterRange(verification, i, e);
        setVerification(filterRange(verification, i, e)); //n
        let code = "";
        arrayNum.forEach((el) => (code += el));

        if (e && e.length > 1) setVerification([...e]);
      }
    }
  }

  const arrayInput = useMemo(() => {
    let arrayInput = new Array(4);
    arrayInput.fill(1);
    return arrayInput;
  }, []);

  return (
    <div className={`number-code`}>
      {arrayInput.map((el, indx) => (
        <input
          placeholder="0"
          key={indx}
          name={`${indx}`}
          autoFocus={indx == 0 ? true : false}
          className="input-code"
          type="tel"
          onKeyDown={(e) =>
            // verification[indx] !== 0
            {
              if ([...verification][indx] == "") {
                e.key === "Backspace" &&
                  numberCode.getElementsByTagName("input")[indx - 1]?.focus();
              }
            }
          }
          onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))}
          maxLength="1"
          value={[...verification][indx]}
          onChange={(e) => {
            changeInput(indx, e.target.value);
          }}
        />
      ))}
    </div>
  );
};

export default InputCode;
