import { IonCheckbox, IonModal } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Close from "../../../ui/Close";
import Button from "../../../ui/Button";
import {
    postRegistrationClient,
    postSMSCodeClient,
    postUpdateClient,
} from "../../../utils/api";
import {
    setElMenu,
    setModalLogin,
    setToken,
    setUserInfo,
} from "../../../store/actions";
import background from "../../../image/modal-background-decor.svg";
import pinIcon from "../../../image/icons/password-mobile-phone.svg";
import OtpInput from "react-otp-input";

import { useRef, useState } from "react";
import TimerCode from "../../../ui/TimerCode";
import Toast from "../../../ui/Toast";
import { globalinfo } from "../../client/ClientProfileContent";

const ModalPin = (props) => {
    const ALL_SECOND = 59 * 1000;
    const [time, setTime] = useState(ALL_SECOND);
    const [openToast, setOpenToast] = useState(false);
    const [messageToast, setMessageToast] = useState({ text: null, type: null });

    const [joinPhone, setJoinPhone] = useState(false);
    const [pass, setPass] = useState();
    const [error, setError] = useState(false);
    const [valTime, setValTime] = useState("");
    const [register, setRegister] = useState(false);
    // const [modalPin, setmodalPin] = useState(false);
    const [errorCode, setErrorCode] = useState(false);
    const [otp, setOtp] = useState("");
    const [activeTimer, setActiveTimer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [send, setSend] = useState();
    const history = useHistory();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.token);

    const obj = useRef({});
    const checkPassword = (e) => {
        if (pass !== e && pass && e) {
            setError("repeat");
        } else {
            setError(false);
        }
    };
    const debaunce = (fn, timeoutMs) => {
        return function perform(...args) {
            const previousCall = obj.current.lastCall;
            obj.current.lastCall = Date.now();
            if (previousCall && obj.current.lastCall - previousCall <= timeoutMs) {
                clearTimeout(obj.current.lastCallTimer);
            }
            obj.current.lastCallTimer = setTimeout(() => fn(...args), timeoutMs);
        };
    };

    const debaunceRequest = debaunce(checkPassword, 500);

    const handleInput = (e) => {
        setValTime(e);
        debaunceRequest(e);
    };

    return (
        <>
            <IonModal
                className="change-phone"
                isOpen={props.open}
                onWillDismiss={() => props.onClose(false)}
            >
                {/* {props.modalPin && ( */}
                <div className="body-modal-login">
                    <img className="image-back-login" src={background} />
                    <Close
                        onClick={() => {
                            setRegister(false);
                            // setmodalPin(false);
                            setJoinPhone(false);
                            setOtp(null);
                            setErrorCode(false);
                            props.onClose(false);
                            dispatch(setModalLogin({ open: false }));
                        }}
                    />
                    <div className="title">
                        <div className="cont">
                            <img src={pinIcon} />
                            Код из СМС
                        </div>
                        <div className="title-lower">
                            Введите код, который мы отправили на указанный номер
                        </div>
                    </div>
                    <form
                        className="form-login"
                        onSubmit={(e) => {
                            e.preventDefault();
                            setLoading(true);
                            let forSend = {
                                confirmation_code: otp,
                                login: globalinfo.email,
                            };
                            postSMSCodeClient(forSend)
                                .then((e) => {
                                    if (!e.data.errors) {
                                        setLoading(false);
                                        props.onClose(false);
                                    } else {
                                        setLoading(false);
                                        setOpenToast(true);
                                        setMessageToast({
                                            text:
                                                typeof e.data.response.errors === "string"
                                                    ? e.data.errors
                                                    : "Ошибка сохранения",
                                            type: "error",
                                        });
                                    }
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setOpenToast(true);
                                    setMessageToast({
                                        text:
                                            typeof e.response.data.errors === "string"
                                                ? e.response.data.errors
                                                : "Ошибка сохранения",
                                        type: "error",
                                    });
                                });
                        }}
                    >
                        <>
                            {/* <InputCode name="code" /> */}
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={4}
                                inputStyle={`input-code ${errorCode ? "error" : ""}`}
                                containerStyle="cont-input-code"
                                inputType="tel"
                                placeholder="0000"
                                renderSeparator={<span> </span>}
                                renderInput={(props) => <input {...props} />}
                            />
                            <div className="cont-btn">
                                <TimerCode
                                    setActiveTimer={setActiveTimer}
                                    activeTimer={activeTimer}
                                    time={time}
                                    setTime={setTime}
                                    allSecond={ALL_SECOND}
                                    send={send}
                                    setOpenToast={setOpenToast}
                                    setMessageToast={setMessageToast}
                                    onSendAgain={() => {
                                        // if (send) {
                                        postUpdateClient(token, globalinfo)
                                            .then((e) => {
                                                if (!e.data.errors) {
                                                    setTime(ALL_SECOND);
                                                    setActiveTimer(true);
                                                } else {
                                                    setOpenToast(true);
                                                    setMessageToast({
                                                        text:
                                                            typeof e.data.errors === "string"
                                                                ? e.data.errors
                                                                : "Ошибка повторной отправки 1",
                                                        type: "error",
                                                    });
                                                }
                                            })
                                            .catch((e) => {
                                                let arr = "";
                                                if (e.response?.data?.errors.length >= 2) {
                                                    for (let i = 0; i < e.response?.data.errors.length; i++) {
                                                        arr += ` ${e.response?.data?.errors[i]}`;
                                                    }
                                                } else {
                                                    arr = e.response?.data?.errors;
                                                }
                                                setOpenToast(true);
                                                setMessageToast({
                                                    text: arr ? arr : "Ошибка повторной отправки 2",
                                                    type: "error",
                                                });
                                                setActiveTimer(false);
                                                setOtp(null);
                                            });
                                        // } else {
                                        //     setOpenToast(true);
                                        //     setMessageToast({
                                        //         text: "Ошибка повторной отправки 3",
                                        //         type: "warning",
                                        //     });
                                        // }
                                    }}
                                />
                                <Button
                                    className={`btn-login save medium`}
                                    type="submit"
                                    typebtn="bright"
                                >
                                    сохранить
                                </Button>
                            </div>
                        </>
                    </form>
                </div>
                {/* )} */}
            </IonModal>
            <Toast
                open={openToast}
                onClose={setOpenToast}
                message={messageToast.text}
                type={messageToast.type}
            />
        </>
    );
};

export default ModalPin;
