import initialState from "../state.js";
function rootReducer(state = initialState, action) {
  switch (action.type) {
    case "TOKEN":
      return { ...state, token: action.data };
    case "USER_INFO":
      return { ...state, userInfo: action.data };
    case "COLUMNS_TABLE":
      return { ...state, columnsTable: action.data };
    case "PROFILE":
      return { ...state, profile: action.data };
    case "OPERATOR":
      return { ...state, operator: action.data };
    case "OPTIONS":
      return { ...state, options: action.data };
    case "OPTIONS_DIRECTORY":
      return { ...state, optionsDirectory: action.data };
    case "PROMOTIONS_DIRECTORY":
      return { ...state, promotionsDirectory: action.data };
    case "PROMOTIONS":
      return { ...state, promotions: action.data };
    case "ORDERS":
      return { ...state, orders: action.data };
    case "OPERATIONS":
      return { ...state, operations: action.data };
    case "REVIEWS":
      return { ...state, reviews: action.data };
    case "FAQ":
      return { ...state, faq: action.data };
    case "EL_MENU":
      return { ...state, ElMenu: action.data };
    case "MODAL_LOGIN":
      return { ...state, modalLogin: action.data };
    case "MODAL_LOGIN_PARTNER":
      return { ...state, modalLoginPartner: action.data };
    case "MODAL_RECOVERY":
      return { ...state, modalRecovery: action.data };
    case "MODAL_GREETINGS":
      return { ...state, modalGreetings: action.data };
    case "CREATE_TOUR":
      return { ...state, createTour: action.data };
    case "PRODUCTS":
      return { ...state, products: action.data };
    case "PRODUCTS_CLIENTS":
      return { ...state, productsClients: action.data };
    case "USER":
      return { ...state, user: action.data };
    case "OPERATOR_USERS":
      return { ...state, operator_users: action.data };
    case "GENRE":
      return { ...state, genre: action.data };
    case "CLASSES":
      return { ...state, classes: action.data };
    case "SUITABLE":
      return { ...state, suitable: action.data };
    case "FORMAT":
      return { ...state, format: action.data };
    case "FORM_FOR_CREATE_TOUR":
      return { ...state, formForCreateTour: action.data };
    case "FORM_FOR_CREATE_OPTION":
      return { ...state, formForCreateOption: action.data };
    case "TOUR_OPTIONS":
      return { ...state, tourOptions: action.data };
    case "TOUR_FOCUS":
      return { ...state, tourFocus: action.data };
    case "TYPE_POINT":
      return { ...state, typePoint: action.data };
    case "FORM_POINT_TOUR":
      return { ...state, formPointTour: action.data };
    case "ARRAY_POINTS_TOUR":
      return { ...state, arrayPointsTour: action.data };
    case "ARRAY_TOUR_RACES":
      return { ...state, arrayTourRaces: action.data };
    case "FORM_FOR_CREATE_PROMOTION":
      return { ...state, formForCreatePromotion: action.data };
    case "TIMELINE":
      return { ...state, timeline: action.data };
    case "ARRAY_MAP_CITY":
      return { ...state, arrayMapCity: action.data };
    case "VAL_CITY":
      return { ...state, valCity: action.data };
    case "FORM_CREATE_RACES":
      return { ...state, formCreateRaces: action.data };
    case "FORM_REVIEWS":
      return { ...state, formReviews: action.data };
    case "FORM_QUESTION":
      return { ...state, formQuestion: action.data };
    case "FORM_FAQ":
      return { ...state, formFAQ: action.data };
    case "FORM_OPERATOR_USERS":
      return { ...state, formOperatorUsers: action.data };
    case "FORM_LOGIN":
      return { ...state, formLogin: action.data };
    case "FORM_REGISTER":
      return { ...state, formRegister: action.data };
    case "MY_COOR":
      return { ...state, myCoor: action.data };
    case "USER_PERMISSIONS":
      return { ...state, userPermissions: action.data };
    case "OPEN_QUICK_VIEW":
      return { ...state, openQuickView: action.data };
    case "FORM_CREATE_ORDER_CLIENT":
      return { ...state, formCreateOrderClient: action.data };
    case "OPEN_MODAL_CREATE_QUESTION":
      return { ...state, openModalCreateQuestion: action.data };
    case "OPEN_MODAL_CREATE_REPORT":
      return { ...state, openModalCreateReport: action.data };
    case "OPEN_MODAL_CANCEL_ORDERS":
      return { ...state, openModalCancelOrders: action.data };
    case "CLIENT_TOUR":
      return { ...state, clientTour: action.data };
    case "FILTER_FIRST_PAGE":
      return { ...state, filterFirstPage: action.data };
    case "OPEN_MODAL_WITHDRAW":
      return { ...state, openModalWithdraw: action.data };
    default:
      return state;
  }
}

export default rootReducer;
