import { IonModal } from "@ionic/react";
import Close from "../../../ui/Close";
import Button from "../../../ui/Button";
import Icons from "../../../ui/Icons";
import { elements } from "chart.js";
import { useState } from "react";
import { formatDate } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Toast from "../../../ui/Toast";

const ModalQuickShowPromotion = (props) => {
  const history = useHistory();
  const [activeTabs, setActiveTabs] = useState(1);
  const format = useSelector((state) => state.format);

  const userPermissions = useSelector((state) => state.userPermissions.showcase?.promotions);

  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });

  return (
    <>
      <IonModal
        className="modal-show-promotion"
        isOpen={props.open}
        onWillDismiss={() => {
          props.onClose(false);
          setActiveTabs(1);
        }}
      >
        <Close
          onClick={() => {
            props.onClose(false);
            setActiveTabs(1);
          }}
        />
        <div className="body-modal-show-promotion">
          <div className="title">{props.element?.name}</div>
          <div className="stages">
            <div
              className={`stage ${activeTabs === 1 ? "active" : ""}`}
              onClick={() => setActiveTabs(1)}
            >
              <div className="text-stage">Информация</div>
            </div>
            <div
              className={`stage ${activeTabs === 2 ? "active" : ""}`}
              onClick={() => setActiveTabs(2)}
            >
              <div className="text-stage">Туры</div>
              <div className="val-stage">{props.element?.products?.length}</div>
            </div>
          </div>
          {activeTabs === 1 && (
            <>
              <div className="cont-info">
                <div className="line">
                  <div className="subtext">Старт акции</div>
                  <div className="text">{formatDate(props.element?.start_date)}</div>
                </div>
                <div className="line">
                  <div className="subtext">Завершение акции</div>
                  <div className="text">{formatDate(props.element?.end_date)}</div>
                </div>
                <div className="line">
                  <div className="subtext">Тип</div>
                  <div className="text">
                    {props.element?.type === 0
                      ? "Скидка на стоимость"
                      : props.element?.type === 1 && "Доп. опции"}
                  </div>
                </div>
                {props.element?.type === 0 && (
                  <div className="line">
                    <div className="subtext">Значение</div>
                    <div className="text">{`${String(+props.element?.value).replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      " "
                    )}${
                      props.element?.calc_method === 0
                        ? "%"
                        : props.element?.calc_method === 1 && ""
                    }`}</div>
                  </div>
                )}
              </div>
              <div className="description">{props.element?.description}</div>
            </>
          )}
          {activeTabs === 2 && (
            <div className="cont-info">
              {props.element?.products.map((el, i) => {
                let a = format?.find((fr) => fr.id === el.product_duration_id);
                return (
                  <div className="line-t" key={i}>
                    <div className="name">{el.name}</div>
                    <div className="subtext">{a?.name}</div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="btns">
            <Button
              typebtn="outline"
              className="small"
              onClick={() => {
                props.onClose(false);
                props.setFocusElement(null);
                setActiveTabs(1);
              }}
            >
              Закрыть
            </Button>
            <Button
              typebtn="muted"
              className="small"
              onClick={() => {
                if (userPermissions[2]) {
                  history.push(`/partner/showcase/promotions/create?id=${props.element?.id}`);
                  props.onClose(false);
                  props.setFocusElement(null);
                  setActiveTabs(1);
                } else {
                  setOpenToast(true);
                  setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
                }
              }}
            >
              <Icons icon="design-personal-edit" />
              Редактировать
            </Button>
          </div>
        </div>
      </IonModal>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </>
  );
};

export default ModalQuickShowPromotion;
