import Close from "../../ui/Close";
import { IonModal } from "@ionic/react";
import { useState } from "react";
import Toast from "../../ui/Toast";
import { setOpenModalCancelOrders, setOrders } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import AlertBox from "../../ui/AlertBox";
import Checkbox from "../../ui/Checkbox";
import Button from "../../ui/Button";
import { getCancelOrder, getOrdersClient } from "../../utils/api";
import qs from "qs";
import { useHistory, useLocation } from "react-router";

const ClientModalCancelOrders = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const urlSearch = qs.parse(history.location.search.substr(1));
  const token = useSelector((state) => state.token);
  const modalCancelOrders = useSelector((state) => state.openModalCancelOrders);
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const onCancel = () => {
    let forSend = {
      reason_cancel: "Отмена заказа клиентом",
    };
    getCancelOrder(token, modalCancelOrders?.el?.id, forSend)
      .then((e) => {
        if (!e.data.errors) {
          getOrdersClient(token).then((q) => {
            dispatch(setOrders(q.data.data));
            dispatch(setOpenModalCancelOrders({ ...modalCancelOrders, open: false }));
            setLoading(false);
            if (location.pathname === "/tour") {
              history.push(`?id=${urlSearch?.id}`);
            }
          });
          setOpenToast(true);
          setMessageToast({ text: "Тур отменён", type: "success" });
        } else {
          setOpenToast(true);
          setMessageToast({
            text: typeof e.data.errors === "string" ? e.data.errors : "Ошибка отмены тура",
            type: "error",
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        setOpenToast(true);
        setMessageToast({
          text:
            typeof e.response.data.errors === "string"
              ? e.response.data.errors
              : "Ошибка отмены тура",
          type: "error",
        });
      });
  };
  return (
    <>
      <IonModal
        className="modal-cancel-orders"
        isOpen={modalCancelOrders.open}
        onWillDismiss={() =>
          dispatch(setOpenModalCancelOrders({ ...modalCancelOrders, open: false }))
        }
      >
        <Close
          onClick={() => dispatch(setOpenModalCancelOrders({ ...modalCancelOrders, open: false }))}
        />
        <div className="modal-cancel-body">
          <div className="title">Отмена тура</div>
          <div className="scroll">
            <div className="cont-info">
              <div className="num">{`№ ${modalCancelOrders?.el?.id}`}</div>
              <div className="name">{modalCancelOrders?.el?.product?.name}</div>
            </div>
            <div className="info-cancel-client">
              <div className="first">
                В случае отказа Пользователя от поездки по любым причинам Платформа имеет право
                удержать фактически понесенные расходы Платформы или Продавца (исполнителя), которые
                подтверждаются документально (на основании{" "}
                <a className="link">ст. 32 Закона о защите прав потребителей</a>), которые могут
                составлять:
              </div>
              <ul className="second">
                <li>при отказе более 15 дней до заезда — 0% от стоимости тура</li>
                <li>при отказе от 15 до 7 дней до заезда — 30% от стоимости тура</li>
              </ul>
              <div className="third">
                Денежные средства возвращаются на карту клиента в срок не более 30 дней по закону РФ
                после проведения возврата. Срок зависит от Банка выпустившего карту.
              </div>
            </div>
            <AlertBox
              type="warning"
              text="В случае несогласия с условиями отмены, пожалуйста обратитесь в техподдержку: support@smorodina.com"
              noClose
            />
            <Checkbox
              name="deposit"
              position="up"
              className="text-checkbox"
              onChange={(e) => setChecked(e.target.checked)}
            >
              <div className="text">
                Я ознакомлен с <a className="link">Условиями отмены</a> и{" "}
                <a className="link">Лицензионнным соглашением</a>.
              </div>
            </Checkbox>
          </div>
          <div className="btns">
            <Button
              typebtn="outline"
              className="small"
              onClick={() => {
                dispatch(setOpenModalCancelOrders({ ...modalCancelOrders, open: false }));
              }}
            >
              Отмена
            </Button>
            <Button
              loading={loading}
              disabled={!checked || loading}
              typebtn="bright"
              className="small"
              onClick={() => {
                onCancel();
                setLoading(true);
              }}
            >
              Подтвердить
            </Button>
          </div>
        </div>
      </IonModal>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </>
  );
};

export default ClientModalCancelOrders;
