import { IonModal } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Close from "../../ui/Close";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import { getInitialDataAdmin, postLoginAdmin, postRegistrationPartner } from "../../utils/api";
import {
  setElMenu,
  setFormRegister,
  setModalLoginPartner,
  setModalRecovery,
  setToken,
  setUserInfo,
} from "../../store/actions";
import background from "../../image/modal-background-decor.svg";
import iconTop from "../../image/icons/login-2.svg";
import { useEffect, useRef, useState } from "react";
import iconTopReg from "../../image/icons/user-plus.svg";
import Checkbox from "../../ui/Checkbox";
import AlertBox from "../../ui/AlertBox";
import Toast from "../../ui/Toast";
import OtpInput from "react-otp-input";
import TimerCode from "../../ui/TimerCode";
import pinIcon from "../../image/icons/password-mobile-phone.svg";
import { postSMSCodeAdmin } from "../../utils/api";

const LoginModalPartner = (props) => {
  const modalLoginPartner = useSelector((state) => state.modalLoginPartner);
  const formRegister = useSelector((state) => state.formRegister);
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  const ALL_SECOND = 59 * 1000;
  const [time, setTime] = useState(ALL_SECOND);
  const [errorCode, setErrorCode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [otp, setOtp] = useState("");

  const [valTime, setValTime] = useState("");
  const [register, setRegister] = useState(false);
  const [pin, setPin] = useState(false);
  const [activeTimer, setActiveTimer] = useState(false);
  const [send, setSend] = useState();
  const [pass, setPass] = useState();
  const [error, setError] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (modalLoginPartner.register) {
      setRegister(true);
    }
  }, [modalLoginPartner]);

  const obj = useRef({});
  const checkPassword = (e) => {
    if (pass !== e && pass && e) {
      setError("repeat");
    } else {
      setError(false);
    }
  };

  const debaunce = (fn, timeoutMs) => {
    return function perform(...args) {
      const previousCall = obj.current.lastCall;
      obj.current.lastCall = Date.now();
      if (previousCall && obj.current.lastCall - previousCall <= timeoutMs) {
        clearTimeout(obj.current.lastCallTimer);
      }
      obj.current.lastCallTimer = setTimeout(() => fn(...args), timeoutMs);
    };
  };

  const debaunceRequest = debaunce(checkPassword, 500);

  const handleInput = (e) => {
    setValTime(e);
    debaunceRequest(e);
  };

  return (
    <>
      <IonModal
        className={`modal-login-partner ${register ? "register-partner" : ""}`}
        isOpen={modalLoginPartner.open}
        onWillDismiss={() => {
          dispatch(setModalLoginPartner({ open: false }));
          setRegister(false);
          setError(false);
          setPin(false);
          setOtp(null);
          setErrorCode(false);
        }}
      >
        {register && (
          <div className="body-modal-login">
            <img className="image-back-login" src={background} />
            <Close
              onClick={() => {
                setRegister(false);
                dispatch(setModalLoginPartner({ open: false }));
                setError(false);
                setPin(false);
                setOtp(null);
                setErrorCode(false);
              }}
            />
            <div className="title">
              <div className="cont">
                <img src={iconTop} />
                Регистрация
              </div>
              <div className="title-lower">для партнёров</div>
            </div>
            <AlertBox
              noClose
              className="alert-register-partner"
              type="warning"
              text="Вы станете администратором компании и сможете добавлять своих сотрудников."
            />
            <form
              className="form-login"
              onSubmit={async (e) => {
                e.preventDefault();
                let info = {};
                let inputs = e.target.querySelectorAll("input");
                inputs.forEach((input) => !!input.name.length && (info[input.name] = input.value));
                let data = {
                  name_company: info.name_company,
                  email: info.email,
                  password: info.password,
                  login: info.email,
                  phone: info.phone.replace(/\D/g, ""),
                };
                if (
                  info.phone &&
                  info.name_company &&
                  info.email &&
                  info.password &&
                  info.password === info.repeat_password
                ) {
                  postRegistrationPartner(data)
                    .then((e) => {
                      if (!e.data.errors) {
                        setSend(data);
                        setPin(true);
                        setRegister(false);
                        // if (e.data.token) {
                        //   dispatch(setModalLoginPartner({ open: false }));
                        //   getInitialDataAdmin(e.data.token?.split("|")[1])
                        //     .then((q) => {
                        //       if (!q.data.errors) {
                        //         dispatch(setToken(e.data.token.split("|")[1]));
                        //         dispatch(setUserInfo({ clientInfo: q.data }));
                        //         dispatch(setElMenu(q.data.menu));
                        //         history.push("/partner/summary");
                        //       } else {
                        //         setOpenToast(true);
                        //         setMessageToast({
                        //           text:
                        //             typeof q.data.errors === "string"
                        //               ? q.data.errors
                        //               : "Ошибка входа",
                        //           type: "error",
                        //         });
                        //       }
                        //     })
                        //     .catch((e) => {
                        //       setOpenToast(true);
                        //       setMessageToast({
                        //         text:
                        //           typeof e.data.errors === "string"
                        //             ? e.data.errors
                        //             : "Ошибка входа",
                        //         type: "error",
                        //       });
                        //     });
                        // }
                      } else {
                        setOpenToast(true);
                        setMessageToast({
                          text:
                            typeof e.data.errors === "string"
                              ? e.data.errors
                              : "Ошибка регистрации",
                          type: "error",
                        });
                      }
                    })
                    .catch((e) => {
                      let arr = "";
                      if (e.response?.data?.errors.length >= 2) {
                        for (let i = 0; i < e.response?.data.errors.length; i++) {
                          arr += ` ${e.response?.data?.errors[i]}`;
                        }
                      } else {
                        arr = e.response?.data?.errors;
                      }
                      setLoading(false);
                      setOpenToast(true);
                      setMessageToast({
                        text:
                          typeof e.response.data.errors === "string"
                            ? e.response.data.errors
                            : "Ошибка регистрации",
                        type: "error",
                      });
                      setActiveTimer(false);
                      setRegister(true);
                      setPin(false);
                      setOtp(null);
                      dispatch(setFormRegister({}));
                    });
                  setActiveTimer(true);
                } else {
                  setOpenToast(true);
                  setMessageToast({
                    text: "Заполните поля",
                    type: "warning",
                  });
                }
              }}
            >
              <Input
                name="name_company"
                noImage
                className="login medium"
                placeholder="название компании"
              />
              <Input
                name="phone"
                noImage
                className="login medium"
                placeholder="номер телефона"
                type="tel"
              />
              <Input
                name="email"
                noImage
                className="login medium"
                placeholder="email"
                type="email"
              />
              <Input
                name="password"
                type="password"
                className="password medium"
                placeholder="придумайте пароль"
                classNameIcon="icon-outline cursor"
                toggle={false}
                onChange={(e) => setPass(e)}
              />
              <Input
                name="repeat_password"
                invalid={error === "repeat"}
                noImage
                className="password medium"
                placeholder="пароль ещё раз"
                type="password"
                onChange={(e) => handleInput(e)}
              />
              <Checkbox
                className="checkbox"
                name="privacy_policy"
                onChange={(e) => {
                  dispatch(setFormRegister({ ...formRegister, privacy_policy: e.target.checked }));
                }}
              >
                <div className="cont-text">
                  Соглашаюсь с <a className="link">Политикой обработки персональных данных </a>
                </div>
              </Checkbox>
              <Checkbox
                className="checkbox"
                name="service_policy"
                position="top"
                onChange={(e) => {
                  dispatch(setFormRegister({ ...formRegister, service_policy: e.target.checked }));
                }}
              >
                <div className="cont-text">
                  Принимаю <a className="link">Пользовательское соглашение</a>
                </div>
              </Checkbox>
              <Checkbox
                className="checkbox"
                name="promotional_materials"
                onChange={(e) => {
                  dispatch(
                    setFormRegister({ ...formRegister, promotional_materials: e.target.checked })
                  );
                }}
              >
                <div className="cont-text">Соглашаюсь получать рекламные материалы</div>
              </Checkbox>
              <Button
                disabled={!!error || !formRegister.privacy_policy || !formRegister.service_policy}
                className={`btn-login ${register ? "register" : ""} medium`}
                type="submit"
                typebtn="bright"
                onClick={() => {
                  // setRegister(false);
                  // dispatch(setModalLoginPartner({ open: false }));
                }}
              >
                {/* завершить и войти */}
                продолжить
              </Button>
            </form>
            <div className="lower-btn">
              <div
                className="lower-btn-text"
                onClick={() => {
                  setRegister(false);
                }}
              >
                Уже есть аккаунт?
              </div>
            </div>
          </div>
        )}
        {pin && (
          <div className="body-modal-login">
            <img className="image-back-login" src={background} />
            <Close
              onClick={() => {
                setRegister(false);
                setPin(false);
                setOtp(null);
                dispatch(setModalLoginPartner({ open: false }));
              }}
            />
            <div className="title">
              <div className="cont">
                <img src={pinIcon} />
                Код из СМС
              </div>
              <div className="title-lower">
                Введите код, который мы отправили на указанный номер
              </div>
            </div>
            <form
              className="form-login"
              onSubmit={(e) => {
                e.preventDefault();
                setLoading(true);

                let forSend = {
                  phone: send.phone,
                  confirmation_code: otp,
                  login: send.login,
                  password: send.password,
                };
                postSMSCodeAdmin(forSend)
                  .then((e) => {
                    if (!e.data.errors) {
                      if (e.data.token) {
                        getInitialDataAdmin(e.data.token?.split("|")[1])
                          .then((q) => {
                            if (!q.data.errors) {
                              setRegister(false);
                              setPin(false);
                              setLoading(false);
                              setOtp(null);
                              dispatch(setToken(e.data.token.split("|")[1]));
                              dispatch(setUserInfo({ clientInfo: q.data }));
                              dispatch(setElMenu(q.data.menu));
                              dispatch(setModalLoginPartner({ open: false }));
                              history.push("/partner/summary");
                            } else {
                              setOpenToast(true);
                              setMessageToast({
                                text:
                                  typeof q.data.errors === "string"
                                    ? q.data.errors
                                    : "Ошибка входа",
                                type: "error",
                              });
                            }
                          })
                          .catch((e) => {
                            setOpenToast(true);
                            setLoading(false);

                            setMessageToast({
                              text:
                                typeof e.response.data.errors === "string"
                                  ? e.response.data.errors
                                  : "Ошибка входа",
                              type: "error",
                            });
                          });
                      }
                    } else {
                      setLoading(false);
                      setOpenToast(true);
                      setMessageToast({
                        text: typeof e.data.errors === "string" ? e.data.errors : "Ошибка входа",
                        type: "error",
                      });
                    }
                  })
                  .catch((e) => {
                    setLoading(false);
                    setOpenToast(true);
                    setMessageToast({
                      text:
                        typeof e.response.data.errors === "string"
                          ? e.response.data.errors
                          : "Ошибка входа",
                      type: "error",
                    });
                  });
              }}
            >
              <>
                {/* <InputCode name="code" /> */}
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  inputStyle={`input-code ${errorCode ? "error" : ""}`}
                  containerStyle="cont-input-code"
                  inputType="tel"
                  placeholder="0000"
                  renderSeparator={<span> </span>}
                  renderInput={(props) => <input {...props} />}
                />
                <div className="cont-btn">
                  <TimerCode
                    setActiveTimer={setActiveTimer}
                    activeTimer={activeTimer}
                    time={time}
                    setTime={setTime}
                    allSecond={ALL_SECOND}
                    send={send}
                    setOpenToast={setOpenToast}
                    setMessageToast={setMessageToast}
                    onSendAgain={() => {
                      if (send) {
                        postRegistrationPartner(send)
                          .then((e) => {
                            if (!e.data.errors) {
                              setActiveTimer(true);
                              setTime(ALL_SECOND);
                            } else {
                              setOpenToast(true);
                              setMessageToast({
                                text:
                                  typeof e.data.errors === "string"
                                    ? e.data.errors
                                    : "Ошибка повторной отправки",
                                type: "error",
                              });
                            }
                          })
                          .catch((e) => {
                            let arr = "";
                            if (e.response?.data?.errors.length >= 2) {
                              for (let i = 0; i < e.response?.data.errors.length; i++) {
                                arr += ` ${e.response?.data?.errors[i]}`;
                              }
                            } else {
                              arr = e.response?.data?.errors;
                            }
                            setOpenToast(true);
                            setMessageToast({
                              text: arr ? arr : "Ошибка повторной отправки",
                              type: "error",
                            });
                            setActiveTimer(false);
                            setOtp(null);
                          });
                      } else {
                        setOpenToast(true);
                        setMessageToast({
                          text: "Ошибка повторной отправки",
                          type: "warning",
                        });
                      }
                    }}
                  />
                  <Button
                    disabled={loading}
                    loading={loading}
                    className={`btn-login ${register ? "register" : ""} medium`}
                    type="submit"
                    typebtn="bright"
                  >
                    войти
                  </Button>
                </div>
              </>
            </form>
          </div>
        )}
        {!register && !pin && (
          <div className="body-modal-login">
            <img className="image-back-login" src={background} />
            <Close onClick={() => dispatch(setModalLoginPartner({ open: false }))} />
            <div className="title">
              <div className="cont">
                <img src={iconTop} />
                Авторизация
              </div>
              <div className="title-lower">для партнёров</div>
            </div>

            <form
              className="form-login"
              onSubmit={(e) => {
                e.preventDefault();
                let info = {};
                let inputs = e.target.querySelectorAll("input");
                inputs.forEach((input) => (info[input.name] = input.value));
                setLoading(true);

                postLoginAdmin(info)
                  .then((e) => {
                    if (!e.data.errors) {
                      if (e.data.token) {
                        getInitialDataAdmin(e.data.token?.split("|")[1])
                          .then((q) => {
                            if (!q.data.errors) {
                              dispatch(setToken(e.data.token?.split("|")[1]));
                              dispatch(setUserInfo({ clientInfo: q.data }));
                              dispatch(setElMenu(q.data.menu));
                              dispatch(setModalLoginPartner({ open: false }));

                              history.push("/partner/summary");
                            } else {
                              setOpenToast(true);
                              setMessageToast({
                                text:
                                  typeof q.data.errors === "string"
                                    ? q.data.errors
                                    : "Ошибка авторизации",
                                type: "error",
                              });
                            }
                            setLoading(false);
                          })
                          .catch((e) => {
                            setOpenToast(true);
                            setLoading(false);

                            setMessageToast({
                              text:
                                typeof e.response.data.errors === "string"
                                  ? e.response.data.errors
                                  : "Ошибка авторизации",
                              type: "error",
                            });
                          });
                      }
                    } else {
                      if (e.data.errors.password) {
                        setError("password");
                      }
                      setOpenToast(true);
                      setLoading(false);

                      setMessageToast({
                        text:
                          typeof e.data.errors === "string"
                            ? e.data.errors
                            : e.data.errors.password || "Ошибка авторизации",
                        type: "error",
                      });
                    }
                  })
                  .catch((e) => {
                    setOpenToast(true);
                    setLoading(false);

                    setMessageToast({
                      text:
                        typeof e.response.data.errors === "string"
                          ? e.response.data.errors
                          : "Ошибка авторизации",
                      type: "error",
                    });
                  });
              }}
            >
              <>
                <Input name="login" noImage className="login medium" placeholder="email" />
                <Input
                  invalid={error === "password"}
                  name="password"
                  className="password medium"
                  placeholder="пароль"
                  type="password"
                  classNameIcon="icon-outline cursor"
                  toggle={false}
                />
                <Button
                  className="btn-login medium"
                  type="submit"
                  typebtn="bright"
                  disabled={loading}
                  loading={loading}
                >
                  войти
                </Button>
              </>
            </form>
            <div className="lower-btn">
              <div
                className="lower-btn-text"
                onClick={() => {
                  dispatch(setModalLoginPartner({ open: false }));
                  dispatch(setModalRecovery({ open: true, partner: true }));
                }}
              >
                Не помню пароль
              </div>
              <div className="lower-btn-text" onClick={() => setRegister(true)}>
                Стать партнёром
              </div>
            </div>
          </div>
        )}
      </IonModal>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </>
  );
};

export default LoginModalPartner;
