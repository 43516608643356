export const ALL_TYPES = {
  TOKEN: "TOKEN",
  USER_INFO: "USER_INFO",
  COLUMNS_TABLE: "COLUMNS_TABLE",
  PROFILE: "PROFILE",
  OPERATOR: "OPERATOR",
  OPTIONS: "OPTIONS",
  PROMOTIONS: "PROMOTIONS",
  EL_MENU: "EL_MENU",
  MODAL_LOGIN: "MODAL_LOGIN",
  MODAL_LOGIN_PARTNER: "MODAL_LOGIN_PARTNER",
  MODAL_RECOVERY: "MODAL_RECOVERY",
  MODAL_GREETINGS: "MODAL_GREETINGS",
  CREATE_TOUR: "CREATE_TOUR",
  PRODUCTS: "PRODUCTS",
  OPERATOR_USERS: "OPERATOR_USERS",
  GENRE: "GENRE",
  CLASSES: "CLASSES",
  FORMAT: "FORMAT",
  FORM_FOR_CREATE_TOUR: "FORM_FOR_CREATE_TOUR",
  TOUR_OPTIONS: "TOUR_OPTIONS",
  TOUR_FOCUS: "TOUR_FOCUS",
  TYPE_POINT: "TYPE_POINT",
  FORM_POINT_TOUR: "FORM_POINT_TOUR",
  SUITABLE: "SUITABLE",
  USER: "USER",
  ARRAY_POINTS_TOUR: "ARRAY_POINTS_TOUR",
  ARRAY_TOUR_RACES: "ARRAY_TOUR_RACES",
  FORM_FOR_CREATE_OPTION: "FORM_FOR_CREATE_OPTION",
  FORM_FOR_CREATE_PROMOTION: "FORM_FOR_CREATE_PROMOTION",
  TIMELINE: "TIMELINE",
  ORDERS: "ORDERS",
  OPERATIONS: "OPERATIONS",
  REVIEWS: "REVIEWS",
  FAQ: "FAQ",
  ARRAY_MAP_CITY: "ARRAY_MAP_CITY",
  VAL_CITY: "VAL_CITY",
  FORM_CREATE_RACES: "FORM_CREATE_RACES",
  FORM_REVIEWS: "FORM_REVIEWS",
  FORM_QUESTION: "FORM_QUESTION",
  FORM_FAQ: "FORM_FAQ",
  FORM_OPERATOR_USERS: "FORM_OPERATOR_USERS",
  FORM_LOGIN: "FORM_LOGIN",
  FORM_REGISTER: "FORM_REGISTER",
  MY_COOR: "MY_COOR",
  USER_PERMISSIONS: "USER_PERMISSIONS",
  PRODUCTS_CLIENTS: "PRODUCTS_CLIENTS",
  OPEN_QUICK_VIEW: "OPEN_QUICK_VIEW",
  FORM_CREATE_ORDER_CLIENT: "FORM_CREATE_ORDER_CLIENT",
  OPEN_MODAL_CREATE_QUESTION: "OPEN_MODAL_CREATE_QUESTION",
  OPEN_MODAL_CREATE_REPORT: "OPEN_MODAL_CREATE_REPORT",
  OPEN_MODAL_CANCEL_ORDERS: "OPEN_MODAL_CANCEL_ORDERS",
  CLIENT_TOUR: "CLIENT_TOUR",
  OPTIONS_DIRECTORY: "OPTIONS_DIRECTORY",
  PROMOTIONS_DIRECTORY: "PROMOTIONS_DIRECTORY",
  FILTER_FIRST_PAGE: "FILTER_FIRST_PAGE",
  OPEN_MODAL_WITHDRAW: "OPEN_MODAL_WITHDRAW",
};
