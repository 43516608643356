import { IonMenuToggle, IonPopover } from "@ionic/react";
import Icons from "../../../ui/Icons";
import MiniMapCreateTour from "../../map/MiniMapCreateTour";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../ui/Button";
import { useMemo, useState } from "react";
import { setArrayPointsTour, setFormPointTour, setProducts } from "../../../store/actions";
import { deletePointTour, getPointTour, getToursOperator, putPointTour } from "../../../utils/api";
import ModalDelete from "../Modals/ModalDelete";
import { URL_ORIGIN } from "../../../utils/config";

const StageThreeCreateTour = () => {
  const dispatch = useDispatch();
  const arrayPointsTour = useSelector((state) => state.arrayPointsTour);
  const [modalDeleteOperUser, setModalDeleteOperUser] = useState(false);

  const sortArray = arrayPointsTour.sort((a, b) => (a.order > b.order ? 1 : -1));
  const token = useSelector((state) => state.token);
  const formForCreateTour = useSelector((state) => state.formForCreateTour);
  const formPointTour = useSelector((state) => state.formPointTour);
  const [openPopover, setOpenPopover] = useState(false);
  const [focusElement, setFocusElement] = useState();
  const [maps, setMaps] = useState();
  const disabled =
    formForCreateTour.status_id === 2 ||
    formForCreateTour.status_id === 4 ||
    formForCreateTour.status_id === 5 ||
    formForCreateTour.status_id === 6;
  const menuDisable = [
    {
      name: "Смотреть",
      id: 1,
      icon: "design-personal-eye",
      click: () => {
        setOpenPopover(false);
        dispatch(setFormPointTour(focusElement));
      },
    },
  ];
  const menu = [
    {
      name: "Вверх",
      id: 1,
      icon: "arrow-up",
      click: () => {
        let arrc = { ...focusElement, order: focusElement.order - 1 };
        delete arrc.info;
        let arrPointChange = arrayPointsTour.find((el) => el.order === focusElement.order - 1);
        putPointTour(token, formForCreateTour.id, arrc, focusElement.id).then(async (e) => {
          if (!e.data.errors) {
            await getPointTour(token, formForCreateTour.id).then(async (e) => {
              dispatch(setArrayPointsTour(e.data.data));
            });
          }
        });
        let array = { ...arrPointChange, order: arrPointChange.order + 1 };
        delete array.info;

        putPointTour(token, formForCreateTour.id, array, arrPointChange.id).then(async (e) => {
          if (!e.data.errors) {
            await getPointTour(token, formForCreateTour.id).then(async (e) => {
              dispatch(setArrayPointsTour(e.data.data));
            });
          }
        });
        setOpenPopover(false);
      },
    },
    {
      name: "Вниз",
      id: 2,
      icon: "arrow-down",
      click: () => {
        let arrc = { ...focusElement, order: focusElement.order + 1 };
        delete arrc.info;
        let arrPointChange = arrayPointsTour.find((el) => el.order === focusElement.order + 1);
        putPointTour(token, formForCreateTour.id, arrc, focusElement.id).then(async (e) => {
          if (!e.data.errors) {
            await getPointTour(token, formForCreateTour.id).then(async (e) => {
              dispatch(setArrayPointsTour(e.data.data));
            });
          }
        });
        let array = { ...arrPointChange, order: arrPointChange.order - 1 };
        delete array.info;

        putPointTour(token, formForCreateTour.id, array, arrPointChange.id).then(async (e) => {
          if (!e.data.errors) {
            await getPointTour(token, formForCreateTour.id).then(async (e) => {
              dispatch(setArrayPointsTour(e.data.data));
            });
          }
        });
        setOpenPopover(false);
      },
    },
    {
      name: "Редактировать",
      id: 3,
      icon: "design-personal-edit",
      click: () => {
        setOpenPopover(false);
        dispatch(setFormPointTour(focusElement));
      },
    },
    {
      name: "Удалить",
      id: 4,
      icon: "design-personal-trash",
      click: () => {
        setModalDeleteOperUser(true);
        setOpenPopover(false);
      },
    },
  ];

  return (
    <>
      <div className="cont-stage-three-create-tour">
        <div className="contl">
          {sortArray?.map((el, i) => {
            return (
              <div className="point-cont" key={i}>
                <div className="upper">
                  <div className="text-cont">
                    <div className="surtext">{el.name_point_type}</div>
                    <div className="text">{el.name}</div>
                    <div id={`null`} />
                  </div>
                  <Button
                    disabled={disabled}
                    typebtn="ghost"
                    className="cube btn-set-point"
                    id={`bottom-start-${i}`}
                    onClick={() => {
                      setOpenPopover({
                        index: i,
                      });
                      setFocusElement(el);
                    }}
                  >
                    <Icons icon="dots-vertical" />
                  </Button>
                </div>
                <div className="descr">{el.description}</div>
                <div className="images" key={i}>
                  {el.photos &&
                    el.photos?.map((im, i) => {
                      return (
                        <div className="cont-img" key={i}>
                          <img src={`${URL_ORIGIN}${im.photo}`} />
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
          {!disabled && (
            <IonMenuToggle menu="menu-right-add-coor">
              <div className="add">
                <Icons icon="plus" className="icon-unfilled" />
                <div className="text">Добавить точку</div>
              </div>
            </IonMenuToggle>
          )}
        </div>
        <div className="contr">
          <MiniMapCreateTour setMaps={setMaps} maps={maps} />
        </div>
      </div>
      <IonPopover
        class="popover-menu-el-list"
        isOpen={openPopover.index || (openPopover.index === 0 && true)}
        onWillDismiss={() => setOpenPopover(false)}
        trigger={openPopover !== false ? `bottom-start-${openPopover.index}` : undefined}
        side="bottom"
        alignment="start"
      >
        <div className="body-popover">
          {menu?.map((el, i) => {
            return el.id === 3 ? (
              <IonMenuToggle key={i} menu="menu-right-add-coor">
                <div className="cont-el-pop" onClick={el.click}>
                  <Icons icon={el.icon} />
                  <div className="text">{el.name}</div>
                </div>
              </IonMenuToggle>
            ) : el.id === 1 ? (
              focusElement?.order === 0 ? undefined : (
                <div key={i} className="cont-el-pop" onClick={el.click}>
                  <Icons icon={el.icon} />
                  <div className="text">{el.name}</div>
                </div>
              )
            ) : el.id === 2 ? (
              focusElement?.order === arrayPointsTour.length - 1 ? undefined : (
                <div key={i} className="cont-el-pop" onClick={el.click}>
                  <Icons icon={el.icon} />
                  <div className="text">{el.name}</div>
                </div>
              )
            ) : (
              <div key={i} className="cont-el-pop" onClick={el.click}>
                <Icons icon={el.icon} />
                <div className="text">{el.name}</div>
              </div>
            );
          })}
        </div>
      </IonPopover>
      <ModalDelete
        open={modalDeleteOperUser}
        onClose={setModalDeleteOperUser}
        onClick={() => {
          deletePointTour(token, formForCreateTour.id, focusElement.id).then(async (e) => {
            if (!e.data.errors) {
              await getPointTour(token, formForCreateTour.id).then(async (e) => {
                dispatch(setArrayPointsTour(e.data.data));
              });
              getToursOperator(token).then((e) => {
                if (e.data.data) {
                  dispatch(setProducts(e.data.data));
                }
              });
            }
          });
          setModalDeleteOperUser(false);
        }}
      />
    </>
  );
};

export default StageThreeCreateTour;
