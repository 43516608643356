import { useIonViewDidEnter, useIonViewWillLeave } from "@ionic/react";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { useSelector } from "react-redux";
import flagiconmap from "../../image/icons/flag-icon-map.png";
import bediconmap from "../../image/icons/bed-icon-map.png";
import { useState } from "react";
import { fromLonLat } from "ol/proj";

const MapQuickShowTourClient = (props) => {
  const arrayPointsTour = useSelector((state) => state.arrayPointsTour);

  var nmapi, map, navitelTile;

  var LeafIcon = L.Icon.extend({
    options: {
      iconSize: [32, 32],
    },
  });
  var flagIcon = new LeafIcon({ iconUrl: flagiconmap });
  var bedIcon = new LeafIcon({ iconUrl: bediconmap });
  useAsyncEffect(async () => {
    //проверим, если нет маршрута, то не будем строить карту.
    if (Object.keys(arrayPointsTour).length != 0) {
      if (props.maps != undefined) {
        props.maps.remove();
        props.setMaps(null);
      }
      nmapi = await navitel.createWebMapApi("059ed2d4fdc4bd5a0ffc56eb88d3154ec8cc52a9", {
        locale: navitel.LocaleCode.ru_RU,
      });
      //отцентруем карту по первой точке маршрута
      let startCoord = JSON.parse(arrayPointsTour[0]?.info).points[0];

      map = L.map("map-show", {
        center: startCoord != null ? fromLonLat([startCoord.lon, startCoord.lat]) : [55.75, 37.7],
        zoom: 8,
        maxZoom: 17,
        minZoom: 5,
      });
      navitelTile = nmapi.extension.leaflet.createTileLayer({
        locale: navitel.LocaleCode.ru_RU,
      });

      arrayPointsTour?.forEach((el, i) => {
        let coorG = JSON.parse(el?.info).points[0];
        L.marker([coorG?.lat, coorG?.lon], {
          icon:
            el?.point_type_id === 1
              ? flagIcon
              : (el.point_type_id === 2 || el.point_type_id === 3) && bedIcon,
        }).addTo(map);
      });
      let arr = [];
      for (let i = 0; i < arrayPointsTour.length; i++) {
        let coorG = JSON.parse(arrayPointsTour[i]?.info).points[0];
        arr.push([coorG.lat, coorG.lon]);
      }
      if (arrayPointsTour.length >= 2) {
        const routeId = await nmapi.route.routeId({
          objectType: nmapi.route.ObjectType.CAR,
          waypointsList: arr,
        });
        const geom = await nmapi.route.routeGeometry({
          routeId,
        });

        let arrForCreateLines = [];
        for (let i = 0; i < geom[0].pointsList.length; i++) {
          arrForCreateLines.push([geom[0].pointsList[i].latitude, geom[0].pointsList[i].longitude]);
        }
        var path = L.polyline(arrForCreateLines, {
          color: "#DD304F",
          weight: 4,
          opacity: 1,
        }).addTo(map);
        // 5.
        map.addLayer(path);
        map.fitBounds(path.getBounds());
      }
      navitelTile.addTo(map);
      if (arrayPointsTour.length === 1) {
        map.panTo([arr[0][0], arr[0][1]]);
      }

      map.invalidateSize();
      props.setMaps(map);
    } else {
      if (props.maps !== undefined) {
        props.maps.remove();
        props.setMaps(null);
      }
      nmapi = await navitel.createWebMapApi("059ed2d4fdc4bd5a0ffc56eb88d3154ec8cc52a9", {
        locale: navitel.LocaleCode.ru_RU,
      });

      map = L.map("map-show", {
        center: [55.75, 37.7],
        zoom: 8,
        maxZoom: 17,
        minZoom: 5,
      });
      navitelTile = nmapi.extension.leaflet.createTileLayer({
        locale: navitel.LocaleCode.ru_RU,
      });

      navitelTile.addTo(map);

      props.setMaps(map);
    }
  }, [arrayPointsTour]);

  return (
    <div className="cont-map-q">
      <div className="map" id="map-show"></div>
    </div>
  );
};
export default MapQuickShowTourClient;
