// import star from "../image/icons/stars.png";
import star from "../image/rating-star/star100.svg";

// import star_none from "../image/icons/stars-none.png";
import star_none from "../image/rating-star/star0.svg";

// import star_half from "../image/icons/stars-half.png";
import star_half from "../image/rating-star/star50.svg";

// import star33 from "../image/icons/stars33.png";
import star33 from "../image/rating-star/star33.svg";

// import star66 from "../image/icons/stars66.png";
import star66 from "../image/rating-star/star66.svg";

import "../less/css/custom.css";
import { useMemo } from "react";

const Rating = (props) => {
  const rat = useMemo(() => props.rate, [props.rate]);
  return (
    <div className="rating-star">
      <img
        className={`star ${props.className}`}
        src={
          rat >= 1
            ? star
            : rat > 0.66 && rat < 1
            ? star66
            : rat > 0.33 && rat < 0.66
            ? star_half
            : rat > 0 && rat < 0.33
            ? star33
            : star_none
        }
      />
      <img
        className={`star ${props.className}`}
        src={
          rat >= 2
            ? star
            : rat > 1.66 && rat < 2
            ? star66
            : rat > 1.33 && rat < 1.66
            ? star_half
            : rat > 1 && rat < 1.33
            ? star33
            : star_none
        }
      />
      <img
        className={`star ${props.className}`}
        src={
          rat >= 3
            ? star
            : rat > 2.66 && rat < 3
            ? star66
            : rat > 2.33 && rat < 2.66
            ? star_half
            : rat > 2 && rat < 2.33
            ? star33
            : star_none
        }
      />
      <img
        className={`star ${props.className}`}
        src={
          rat >= 4
            ? star
            : rat > 3.66 && rat < 4
            ? star66
            : rat > 3.33 && rat < 3.66
            ? star_half
            : rat > 3 && rat < 3.33
            ? star33
            : star_none
        }
      />
      <img
        className={`star ${props.className}`}
        src={
          rat >= 5
            ? star
            : rat > 4.66 && rat < 5
            ? star66
            : rat > 4.33 && rat < 4.66
            ? star_half
            : rat > 4 && rat < 4.33
            ? star33
            : star_none
        }
      />
    </div>
  );
};
export default Rating;
