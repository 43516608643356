import { useState, useMemo, useEffect } from "react";
import Icons from "../../../ui/Icons";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "primereact/editor";

import { setFormForCreateTour, setProducts, setTourFocus } from "../../../store/actions";
import { useAsyncEffect } from "../../../hooks/useAsyncEffect";
import Button from "../../../ui/Button";
import ModalDelete from "../Modals/ModalDelete";
import {
  deleteMainPhotoTour,
  deleteOtherPhotoTour,
  getToursByID,
  getToursOperator,
} from "../../../utils/api";
import { checkTourStatus } from "../../../utils/utils";
import { URL_ORIGIN } from "../../../utils/config";
import Toggle from "../../../ui/Toggle";
import ModalFAQDetails from "../../feedback/ModalFAQDetails";

const StageTwoCreateTour = (props) => {
  const dispatch = useDispatch();
  const formForCreateTour = useSelector((state) => state.formForCreateTour);
  const [modalDeleteOperUser, setModalDeleteOperUser] = useState(false);
  const [typePhoto, setTypePhoto] = useState();
  const token = useSelector((state) => state.token);
  const faq = useMemo(() => props.faq, [props.faq]);
  // let faqForTour = [];
  // for (let index = 0; index < faq.length; index++) {
  //   if (faq[index].products?.find((el) => el.id === formForCreateTour?.id)) {
  //     faqForTour.push(faq[index]?.id);
  //   }
  // }
  // console.log(faqForTour);
  const [openFAQDetail, setOpenFAQDetail] = useState(false);

  const [description1, setDescription1] = useState(formForCreateTour.description);
  const [description2, setDescription2] = useState(formForCreateTour.description_inc_price);
  const disabled =
    formForCreateTour.status_id === 2 ||
    formForCreateTour.status_id === 4 ||
    formForCreateTour.status_id === 5 ||
    formForCreateTour.status_id === 6;
  const arrPhoto = useMemo(() => props.otherPhoto, [props.otherPhoto]);
  // useAsyncEffect(() => {
  //   if (mainPhoto) {
  //     var reader = new FileReader();
  //     reader.readAsDataURL(mainPhoto);
  //     reader.onload = function (f) {
  //       dispatch(setFormForCreateTour({ ...formForCreateTour, photo: this.result }));
  //     };
  //   }
  // }, [mainPhoto]);
  // useAsyncEffect(() => {
  //   if (otherPhoto) {
  //     let arr = [];
  //     for (var i = 0; i < otherPhoto.length; i++) {
  //       var reader = new FileReader();
  //       reader.readAsDataURL(otherPhoto[i]);
  //       reader.onload = function (f) {
  //         arr.push(this.result);
  //       };
  //     }
  //     dispatch(setFormForCreateTour({ ...formForCreateTour, photos: arr }));
  //   }
  // }, [otherPhoto]);

  useAsyncEffect(() => {
    dispatch(
      setFormForCreateTour({
        ...formForCreateTour,
        description: description1,
        description_inc_price: description2,
      })
    );
  }, [description1, description2]);

  const CustomButton = () => <Icons icon="insert-textarea" className="insert-icon" />;
  function insertLine() {
    const cursorPosition = this.quill.getSelection().index;
    this.quill.insertEmbed(
      cursorPosition,
      "__________________________________________________________________________________"
    );
    this.quill.setSelection(cursorPosition + 82);
  }
  const modules1 = useMemo(
    () => ({
      toolbar: {
        container: "#desc1",
        handlers: {
          insertLine: insertLine,
        },
      },
    }),
    []
  );

  const modules2 = useMemo(
    () => ({
      toolbar: {
        container: "#desc2",
        handlers: {
          insertLine: insertLine,
        },
      },
    }),
    []
  );

  return (
    <>
      <div className="cont-stage-two-create-tour">
        <div className="contl">
          <div className="description">
            <div className="title">
              <div className="text">Описание тура</div>
              <div className="subtext">
                Опишите, что ждёт путешественников: яркие впечатления, исторические места и т.д.
              </div>
            </div>
            <div className="text-editor">
              <div className="text-editor">
                <Editor
                  readOnly={disabled}
                  placeholder="Добавьте описание тура..."
                  headerTemplate={
                    <div id="desc1" className="cont-header">
                      <button className="ql-bold" />
                      <div className="l" />
                      <button className="ql-list" value="bullet"></button>
                      <button className="ql-list" value="ordered"></button>
                      <div className="l" />
                      <button disabled={disabled} className="ql-insertLine">
                        <CustomButton />
                      </button>
                    </div>
                  }
                  value={formForCreateTour.description}
                  modules={modules1}
                  onTextChange={(e) => {
                    setDescription1(e.htmlValue);
                  }}
                  style={{ height: "304px", opacity: disabled ? "40%" : "100%" }}
                />
              </div>
            </div>
          </div>
          <div className="description">
            <div className="title">
              <div className="text">Что входит в стоимость</div>
              <div className="subtext">Укажите всё, что считаете нужным</div>
            </div>
            <div className="text-editor">
              <Editor
                placeholder="Составьте список того, что входит в стоимость..."
                headerTemplate={
                  <div id="desc2" className="cont-header">
                    <button className="ql-bold" />
                    <div className="l" />
                    <button className="ql-list" value="bullet"></button>
                    <button className="ql-list" value="ordered"></button>
                    <div className="l" />
                    <button disabled={disabled} className="ql-insertLine">
                      <CustomButton />
                    </button>
                  </div>
                }
                value={formForCreateTour.description_inc_price}
                modules={modules2}
                onTextChange={(e) => {
                  setDescription2(e.htmlValue);
                }}
                style={{ height: "304px", opacity: disabled ? "40%" : "100%" }}
              />
            </div>
          </div>
          <div className="faq-cont">
            <div className="title-cont">
              <div className="text-cont">
                <div className="text">Частые вопросы</div>
                <div className="subtext">Выберите из своего справочника или создайте</div>
              </div>
              {!disabled && (
                <Button
                  typebtn="outline"
                  className="small"
                  onClick={() => {
                    setOpenFAQDetail(true);
                  }}
                >
                  <Icons icon="plus" />
                  Создать новый
                </Button>
              )}
            </div>
            <div className="cont-faqs">
              <div className="faq-partner">
                <div className="title">Из вашего справочника:</div>
                {faq?.map((el, i) => {
                  return (
                    <Toggle
                      disabled={disabled}
                      className="toggle-create-tour"
                      key={i}
                      checked={props.faqForTour.find((q) => q === el.id)}
                      text={el.review_text}
                      description={el.response_text}
                      onChange={(e) => {
                        if (e.target.checked) {
                          props.faqForTour.push(el.id);
                        } else {
                          props.setFaqForTour(props.faqForTour.filter((s) => s !== el.id));
                        }
                      }}
                    />
                  );
                })}
              </div>
              {/* <div className="l" /> */}
              {/* <div className="faq-partner">
                <div className="title">От платформы:</div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="contr">
          <div className="main">
            <div className="title">
              <div className="text">Фото на обложку</div>
              <div className="subtext">Рекомендуем не менее 1280px в ширину.</div>
            </div>
            {formForCreateTour.photo ? (
              <div className="cont-image-main">
                {!disabled && (
                  <Button
                    typebtn="elevated"
                    className="small cube btn-del-img"
                    onClick={() => {
                      setTypePhoto({ type: 4 });
                      setModalDeleteOperUser(true);
                    }}
                  >
                    <Icons icon="x" />
                  </Button>
                )}
                <img className="main-img" src={`${URL_ORIGIN}${formForCreateTour.photo}`}></img>
              </div>
            ) : !props.mainPhoto ? (
              <div>
                <input
                  disabled={disabled}
                  type="file"
                  name="file"
                  id="file-main-photo"
                  accept="image/jpeg, image/png"
                  className="inputfile"
                  onChange={(e) => props.setMainPhoto(e.target.files[0])}
                />
                <label className="main-image" htmlFor="file-main-photo">
                  <Icons icon="photo-plus" />
                </label>
              </div>
            ) : (
              <div className="cont-image-main">
                <Button
                  typebtn="elevated"
                  className="small cube btn-del-img"
                  onClick={() => {
                    setTypePhoto({ type: 1 });
                    setModalDeleteOperUser(true);
                  }}
                >
                  <Icons icon="x" />
                </Button>
                <img className="main-img" src={URL.createObjectURL(props.mainPhoto)}></img>
              </div>
            )}
          </div>
          <div className="more">
            <div className="title">
              <div className="text">Ещё фото</div>
              <div className="subtext">Минимум 4 фото, рекомендуем не менее 1280px в ширину.</div>
            </div>
            <div className="images">
              {formForCreateTour?.pictures?.map((el, i) => {
                return (
                  <div className="image-f" key={i}>
                    {!disabled && (
                      <Button
                        disabled={formForCreateTour.status_id === 2}
                        typebtn="elevated"
                        className="small cube btn-del-img"
                        onClick={() => {
                          setTypePhoto({ type: 3, id: el.id });
                          setModalDeleteOperUser(true);
                        }}
                      >
                        <Icons icon="x" />
                      </Button>
                    )}
                    <img className="other-img" src={`${URL_ORIGIN}${el.photo}`}></img>
                  </div>
                );
              })}
              {arrPhoto?.map((el, i) => {
                return (
                  <div className="image-f" key={i}>
                    <Button
                      typebtn="elevated"
                      className="small cube btn-del-img"
                      onClick={() => {
                        setTypePhoto({ type: 2, id: i });
                        setModalDeleteOperUser(true);
                      }}
                    >
                      <Icons icon="x" />
                    </Button>
                    <img className="other-img" src={URL.createObjectURL(el)}></img>
                  </div>
                );
              })}
              {!disabled && (
                <div>
                  <input
                    type="file"
                    name="file"
                    id="file-other-photo"
                    className="inputfile"
                    accept="image/jpeg, image/png"
                    multiple
                    onChange={(e) => {
                      let arr = [];
                      for (var i = 0; i < e.target.files.length; i++) {
                        arr.push(e.target.files[i]);
                      }
                      if (props.otherPhoto)
                        for (var i = 0; i < props.otherPhoto.length; i++) {
                          arr.push(props.otherPhoto[i]);
                        }
                      props.setOtherPhoto(arr);
                    }}
                  />
                  <label className="image" htmlFor="file-other-photo">
                    <Icons icon="photo-plus" />
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalFAQDetails
        open={openFAQDetail}
        onClose={setOpenFAQDetail}
        // element={focusElement}
        // setFocusElement={setFocusElement}
      />
      <ModalDelete
        open={modalDeleteOperUser}
        onClose={setModalDeleteOperUser}
        title="Удаление фото"
        text="Вы действительно хотите удалить фото?"
        btn_delete="Да, удалить"
        onClick={() => {
          if (typePhoto.type === 1) {
            props.setMainPhoto(null);
          } else if (typePhoto.type === 2) {
            let arr = props.otherPhoto;
            arr.splice(typePhoto.id, 1);
            props.setOtherPhoto(arr);
          } else if (typePhoto.type === 3) {
            // deleteOtherPhotoTour(token, formForCreateTour.id, typePhoto.id).then(async (e) => {
            //   if (!e.data.error) {
            //     let arr = formForCreateTour.pictures;
            //     let newarr = arr.filter((el) => el.id !== typePhoto.id);
            //     dispatch(
            //       setFormForCreateTour({
            //         ...formForCreateTour,
            //         photos: newarr,
            //       })
            //     );
            //     await getToursOperator(token).then((e) => {
            //       if (e.data.data) {
            //         dispatch(setProducts(e.data.data));
            //       }
            //     });
            //     await getToursByID(token, formForCreateTour.id).then((e) => {
            //       dispatch(setTourFocus(e.data.data));
            //       dispatch(setFormForCreateTour(e.data.data));
            //     });
            //   }
            // });
            let arr = formForCreateTour.pictures;
            let arrdel = formForCreateTour.arrdel ? formForCreateTour?.arrdel : [];
            let findarr = arr.find((el) => el?.id === typePhoto.id);
            let newarr = arr.filter((el) => el.id !== typePhoto.id);
            arrdel.push(findarr);
            dispatch(
              setFormForCreateTour({
                ...formForCreateTour,
                pictures: newarr,
                arrdel: arrdel,
              })
            );
          } else if (typePhoto.type === 4) {
            // deleteMainPhotoTour(token, formForCreateTour.id).then(async (e) => {
            //   if (!e.data.error) {
            //     dispatch(
            //       setFormForCreateTour({
            //         ...formForCreateTour,
            //         photo: null,
            //       })
            //     );
            //     await getToursOperator(token).then((e) => {
            //       if (e.data.data) {
            //         dispatch(setProducts(e.data.data));
            //       }
            //     });
            //     await getToursByID(token, formForCreateTour.id).then((e) => {
            //       dispatch(setTourFocus(e.data.data));
            //       dispatch(setFormForCreateTour(e.data.data));
            //     });
            //   }
            // });
            dispatch(
              setFormForCreateTour({
                ...formForCreateTour,
                photodel: formForCreateTour?.photo,
                photo: null,
              })
            );
          }
          setModalDeleteOperUser(false);
        }}
      />
    </>
  );
};

export default StageTwoCreateTour;
