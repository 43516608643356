export function nameForm(value) {
  let val = value?.split(" ");
  return `${val?.[0]}${val?.[1] ? ` ${val?.[1]?.[0]}` : ""}.`;
}

export function formatDate(value) {
  var timezone = new Date(value).getTimezoneOffset() / 60;
  var lastChangeDate = `${new Date(value).toLocaleString().slice(0, 17)} (UTC${
    +timezone < 0 ? `+${+timezone * -1 > 9 ? "" : "0"}${+timezone * -1}:00` : `-${+timezone * -1}`
  })`;
  return lastChangeDate;
}

export function checkTourStatus(array, points) {
  let stage = { one: false, two: false, three: false };
  if (
    array.name &&
    array.product_duration_id &&
    array.product_class_id &&
    array.genres &&
    array.time_sales_stop &&
    array.available_places &&
    array.summa &&
    array.user_id
  ) {
    stage = { ...stage, one: true };
  }

  if (
    array.description &&
    array.description_inc_price &&
    array.photo &&
    array.pictures.length >= 4
  ) {
    stage = { ...stage, two: true };
  }
  console.log("POINTS", points);
  if (points?.length) {
    stage = { ...stage, three: true };
  }
  return stage;
}

export function toBinary(num) {
  let str = "";
  while (num >= 1) {
    str += Math.floor(num) % 2;
    num = num / 2;
  }
  if (str.length === 0) {
    str = "00000";
  } else if (str.length === 1) {
    str = String(+str * 10000);
  } else if (str.length === 2) {
    str = String(+str * 1000);
  } else if (str.length === 3) {
    str = String(+str * 100);
  } else if (str.length === 4) {
    str = String(+str * 10);
  } else if (str.length === 5) {
    str = str;
  }
  return str.split("").reverse().join("");
}

export function initTasks() {
  const currentDate = new Date();
  const tasks = [
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
      name: "Some Project",
      id: "ProjectSample",
      // progress: 25,
      type: "project",
      hideChildren: false,
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2, 12, 28),
      name: "Idea",
      id: "Task 0",
      // progress: 45,
      type: "task",
      project: "ProjectSample",
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
      name: "Research",
      id: "Task 1",
      // progress: 25,
      // dependencies: ["Task 0"],
      type: "task",
      project: "ProjectSample",
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 0, 0),
      name: "Discussion with team",
      id: "Task 2",
      // progress: 10,
      // dependencies: ["Task 1"],
      type: "task",
      project: "ProjectSample",
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
      name: "Developing",
      id: "Task 3",
      // progress: 2,
      // dependencies: ["Task 2"],
      type: "task",
      project: "ProjectSample",
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
      name: "Review",
      id: "Task 4",
      type: "task",
      // progress: 70,
      // dependencies: ["Task 2"],
      project: "ProjectSample",
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
      name: "Release",
      id: "Task 6",
      // progress: currentDate.getMonth(),
      type: "task",
      // dependencies: ["Task 4"],
      project: "ProjectSample",
    },
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
      name: "Party Time",
      id: "Task 9",
      // progress: 0,
      // isDisabled: true,
      type: "task",
      project: "ProjectSample",
    },
  ];
  return tasks;
}
export function getStartEndDateForProject(tasks, projectId) {
  const projectTasks = tasks.filter((t) => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;
  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
}

export function countProm(arrayProm, summa) {
  let summaSkidki = 0;
  let summaSoSkidkoy = 0;

  for (let index = 0; index < arrayProm?.length; index++) {
    const elem = arrayProm[index];

    if (elem.calc_method == 0) {
      summaSkidki = summaSkidki + (+summa * +elem.value) / 100;
    } else {
      summaSkidki = summaSkidki + +elem.value;
    }
  }
  summaSoSkidkoy = +summa - summaSkidki;

  return +summaSoSkidkoy?.toFixed(2);
}

export function toms(mmss) {
  if (typeof mmss !== "string") {
    if (mmss.toString) mmss = mmss.toString();
  }

  var parts = mmss.split(":"),
    n = parts.length,
    ms = 0,
    i;

  for (i = 0; i < parts.length; i++) {
    let part = parseInt(parts[n - 1 - i]);
    if (i === 0) {
      ms += part * 1000;
    } else if (i === 1) {
      ms += part * 6e4;
    } else if (i === 2) {
      ms += part * 36e5;
    }
  }

  return ms;
}
