import { useDispatch, useSelector } from "react-redux";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import {
  getClient,
  getInitialDataClient,
  getOrdersClient,
  getSuitableListClient,
} from "../utils/api";
import { setElMenu, setOrders, setProfile, setSuitable, setUserInfo } from "../store/actions";
import { IonPage, IonRouterOutlet } from "@ionic/react";
import { Route, useHistory } from "react-router";
import HeaderPageClient from "../components/firstpage/HeaderPageClient";
import ClientToursContent from "../components/client/ClientToursContent";
import Footer from "../ui/Footer";
import ClientProfileContent from "../components/client/ClientProfileContent";
import ClientCheckout from "../components/client/ClientCheckout";
import ClientCheckoutResult from "../components/client/ClientCheckoutResult";

const ClientPage = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const history = useHistory();

  // const createOpen = useSelector((state) => state.createTour); // create
  useAsyncEffect(async () => {
    if (!token || token !== "null") {
      await getInitialDataClient(token)
        .then(async (e) => {
          dispatch(setUserInfo({ clientInfo: e.data }));
          dispatch(setElMenu(e.data.menu));
        })
        .catch((e) => {
          history.push("/");
        });
    }
  }, [token]);

  useAsyncEffect(async () => {
    getClient(token).then((e) => {
      dispatch(setProfile(e.data.user));
    });

    // getOrdersClient(token).then((e) => {
    //   dispatch(setOrders(e.data.data));
    // });
  }, []);

  return (
    <IonPage id="main-content">
      <HeaderPageClient />
      <IonRouterOutlet id="right-add">
        <Route exact path="/client" component={ClientToursContent} />
        <Route path="/client/profile" component={ClientProfileContent} />
        <Route path="/client/result" component={ClientCheckoutResult} />
      </IonRouterOutlet>
    </IonPage>
  );
};

export default ClientPage;
