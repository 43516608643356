import "../../less/css/home.css";
import image1 from "../../image/note-and-pen-3d-illustration-png.png";
import image2 from "../../image/snowy-pine-tree-8086523-6516730.png";
import Promotion from "../../ui/Promotion";

const OfferElement = () => {
  return (
    <div className="offer">
      <Promotion
        title={"Как сделать тур, который понравится всем"}
        text={
          " Объясним как лучше всего описать тур и что упомянуть, чтобы покупатели не прошли мимо."
        }
        className={"sup"}
        image={image1}
      />
      <Promotion
        title={"Новая акция от платформы!"}
        text={"Участвуйте в акции «Зима близко». Снижаем комиссию на 5% для всех акционных туров."}
        className={"prom"}
        image={image2}
      />
    </div>
  );
};

export default OfferElement;
