import { IonContent, IonPopover } from "@ionic/react";
import qs from "qs";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import Status from "../../ui/Status";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../ui/Input";
import { COLORS_PROMOTIONS, DEFAULT_PROMOTIONS, STATUSES_PROMOTIONS } from "../../utils/array";
import { useEffect, useState } from "react";
import Select from "../../ui/Select";
import AlertBox from "../../ui/AlertBox";
import MultiSelectChip from "../../ui/MultiSelectChip";
import noImage from "../../image/no-image-s.svg";
import { Dropdown } from "primereact/dropdown";
import AutoCompleteSelect from "../../ui/AutoCompleteSelect";
import {
  setFormForCreateOption,
  setFormForCreatePromotion,
  setOptionsDirectory,
  setProducts,
  setPromotions,
  setPromotionsDirectory,
} from "../../store/actions";
import {
  deletePromotions,
  getOptionsDirectory,
  getPromotions,
  getPromotionsByID,
  getPromotionsDirectory,
  postPromotions,
  postPromotionsDirectory,
  putPromotions,
} from "../../utils/api";
import { formatDate, nameForm } from "../../utils/utils";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import ModalCreateOptions from "./Modals/ModalCreateOptions";
import ModalDelete from "./Modals/ModalDelete";
import Toast from "../../ui/Toast";
import { URL_ORIGIN } from "../../utils/config";

const PromotionsCreate = () => {
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const [openModalCreateOption, setOpenModalCreateOption] = useState(false);
  const [modalDeletePromotions, setModalDeletePromotions] = useState(false);
  const userPermissions = useSelector((state) => state.userPermissions.showcase?.promotions);
  const userPermissionsOptions = useSelector((state) => state.userPermissions.showcase?.options);
  const operator = useSelector((state) => state.operator);
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });

  const formForCreatePromotion = useSelector((state) => state.formForCreatePromotion);
  const history = useHistory();
  const dispatch = useDispatch();
  const urlSearch = qs.parse(history.location?.search.substr(1));
  const promotions = useSelector((state) => state.promotions);
  const products = useSelector((state) => state.products);
  // ФИЛЬТР
  let idProds = [];
  for (let i = 0; i < formForCreatePromotion.products?.length; i++) {
    idProds.push(formForCreatePromotion.products[i].id);
  }
  let fill = products?.filter((item) => !idProds.includes(item.id));
  //
  const promotionsDirectory = useSelector((state) => state.promotionsDirectory);
  const options = useSelector((state) => state.options);
  const format = useSelector((state) => state.format);
  const focusPromotions = promotions?.find((el) => el.id === formForCreatePromotion?.id);
  const [focusColor, setFocusColor] = useState();
  const [arrOptions, setArrOptions] = useState();
  const [arrProducts, setArrProducts] = useState();
  const [searchProducts, setSearchProducts] = useState();
  const [openPopover, setOpenPopover] = useState(false);
  const [openPopoverProd, setOpenPopoverProd] = useState(false);
  const [openPopoverDel, setOpenPopoverDel] = useState(false);

  const statuses = STATUSES_PROMOTIONS;
  const status = statuses.find((el) => el.status === formForCreatePromotion?.status);
  const colors = COLORS_PROMOTIONS;
  const type = [
    { name: "Скидка на стоимость", id: 0 },
    { name: "Доп. опции", id: 1 },
  ];
  const cur = [
    { name: "Процентов", id: 0 },
    { name: "Рублей", id: 1 },
  ];
  const [typeVal, setTypeVal] = useState(type[0]);
  const [curVal, setCurVal] = useState(cur[0]);

  const [name, setName] = useState();

  useEffect(() => {
    setArrOptions();
    setArrProducts();
  }, []);

  useAsyncEffect(async () => {
    let a = await promotionsDirectory?.find(
      (el) => el?.id === formForCreatePromotion?.promotion?.id
    );
    setName(a);
  }, [promotionsDirectory, formForCreatePromotion.promotion]);

  useEffect(() => {
    if (!urlSearch?.id) {
      dispatch(setFormForCreatePromotion(DEFAULT_PROMOTIONS));
      setFocusColor(null);
      setArrOptions();
      setArrProducts();
    }
  }, [urlSearch?.id]);

  useAsyncEffect(async () => {
    if (urlSearch.id === undefined) return;
    await getPromotionsByID(token, urlSearch.id)
      .then((e) => {
        if (e.data.data) {
          let prom = e.data.data;
          prom.name = prom?.promotion?.name;
          dispatch(setFormForCreatePromotion(prom));
          let searchCol = colors.find((el) => el.color === e.data.data.color);
          setFocusColor({ color: e.data.data.color, indx: searchCol.id });
          setTypeVal(type[e.data.data?.type]);
          setCurVal(cur[e.data.data?.calc_method]);
          setArrOptions(e.data.data.operator_options);
          setArrProducts(e.data.data.products);
        }
      })
      .catch(() => {
        // history.push("/partner/showcase/promotions");
      });
  }, []);

  const defVal = curVal.id === 0 ? 20 : 1000;

  const onSave = async () => {
    let arr = formForCreatePromotion;
    let arrProd = [];
    if (arrProducts?.length) {
      for (var i = 0; i < arrProducts.length; i++) {
        arrProd.push(arrProducts[i].id);
      }
    }
    let findName = promotionsDirectory?.find((q) => q.name === arr?.name);

    let forSend = {
      ...formForCreatePromotion,
      status: 1,
      type: typeVal.id,
      calc_method: curVal.id,
      products: arrProd.length ? arrProd : null,
      value: typeVal.id === 0 && !formForCreatePromotion.value ? defVal : defVal,
      start_date: new Date(formForCreatePromotion.start_date).getTime() / 1000,
      end_date: new Date(formForCreatePromotion.end_date).getTime() / 1000,
      operator_id: operator?.id,
    };
    if (findName || arr?.for_promotion_id) {
      if (arr?.for_promotion_id) {
        forSend.promotion_id = arr?.for_promotion_id;
      } else {
        forSend.promotion_id = findName?.id;
      }
    } else {
      await postPromotionsDirectory(token, { name: arr?.name }).then((s) => {
        if (!s.data.errors) {
          forSend.promotion_id = s.data?.promotion?.id;
          getPromotionsDirectory(token).then((e) => {
            dispatch(setPromotionsDirectory(e.data.data));
          });
        }
      });
    }
    postPromotions(token, forSend).then(async (e) => {
      if (!e.data.errors) {
        let id = e.data.promotion.id;
        await getPromotionsByID(token, id).then((e) => {
          if (e.data.data) {
            let prom = e.data.data;
            prom.name = prom?.promotion?.name;
            dispatch(setFormForCreatePromotion(prom));
            // dispatch(setFormForCreatePromotion(e.data.data));
          }
        });
        await getPromotions(token).then((e) => {
          const arr = e.data.data;
          arr?.forEach((el) => {
            el.name = el?.promotion?.name;
          });
          dispatch(setPromotions(arr));
        });
        history.push(`/partner/showcase/promotions/create?id=${id}`);
      }
      setLoading(false);
    });
  };

  const onSaveChange = async () => {
    let arr = formForCreatePromotion;
    let arrChangeProducts = [];
    let arrChangeOptions = [];
    if (arrProducts?.length) {
      for (var i = 0; i < arrProducts.length; i++) {
        arrChangeProducts.push(arrProducts[i].id);
      }
    }
    if (arrOptions?.length) {
      for (var i = 0; i < arrOptions.length; i++) {
        arrChangeOptions.push(arrOptions[i].id);
      }
    }

    arr.operator_options = arrChangeOptions;
    arr.products = arrChangeProducts;
    arr.start_date = new Date(formForCreatePromotion.start_date).getTime() / 1000;
    arr.end_date = new Date(formForCreatePromotion.end_date).getTime() / 1000;
    let findName = promotionsDirectory?.find((q) => q.name === arr?.name);
    if (findName || arr?.for_promotion_id) {
      if (arr?.for_promotion_id) {
        arr.promotion_id = arr?.for_promotion_id;
      } else {
        arr.promotion_id = findName?.id;
      }
    } else {
      await postPromotionsDirectory(token, { name: arr?.name }).then((s) => {
        if (!s.data.errors) {
          arr.promotion_id = s.data?.promotion?.id;
          getPromotionsDirectory(token).then((e) => {
            dispatch(setPromotionsDirectory(e.data.data));
          });
        }
      });
    }
    await putPromotions(token, arr, arr.id).then(async (e) => {
      if (!e.data.errors) {
        let id = e.data.promotion.id;
        await getPromotionsByID(token, id).then((e) => {
          if (e.data.data) {
            let prom = e.data.data;
            prom.name = prom?.promotion?.name;
            dispatch(setFormForCreatePromotion(prom));
          }
        });
        await getPromotions(token).then((e) => {
          const arr = e.data.data;
          arr?.forEach((el) => {
            el.name = el?.promotion?.name;
          });
          dispatch(setPromotions(arr));
        });
      }
      setLoading(false);
    });
  };
  const itemTemplate = (item) => {
    return (
      <div className="p-d-flex p-ai-center country-item">
        <div className="text">{item.name}</div>
        <div className="subtext">{item.duration.name}</div>
      </div>
    );
  };

  const onChangeOption = (element) => {
    setOpenModalCreateOption(true);
    dispatch(setFormForCreateOption({ ...element }));
  };

  return (
    <>
      <IonContent className="page">
        <div className="body-create-promotions">
          {!!urlSearch.id ? (
            <div className="upper-line-put">
              <div className="tbr">
                <div className="cont-l">
                  <Button
                    typebtn="outline"
                    className="btn-back small btn-back"
                    onClick={() => {
                      history.push("/partner/showcase/promotions");
                      dispatch(setFormForCreatePromotion(DEFAULT_PROMOTIONS));
                      // window.location.reload();
                    }}
                  >
                    <Icons icon="design-personal-chevron-left" className="icon-outline" />
                  </Button>
                  <div className="title">{focusPromotions?.name}</div>
                </div>
                <div className="btns">
                  <Button
                    disabled={loading}
                    className={`btn-save-draft small  ${loading ? "disabled" : ""}`}
                    loading={loading}
                    typebtn="bright"
                    onClick={() => {
                      if (userPermissions[2]) {
                        onSaveChange();
                        setLoading(true);
                      } else {
                        setOpenToast(true);
                        setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
                      }
                    }}
                  >
                    Сохранить
                  </Button>
                  <Button
                    typebtn="outline"
                    id="bottom-start-d"
                    className="small cube"
                    onClick={() => setOpenPopoverDel(true)}
                  >
                    <Icons icon="dots-vertical" />
                  </Button>
                </div>
              </div>

              <div className="status-cont">
                <Status color={status?.color} className="status-h">
                  {status?.name}
                </Status>
              </div>
              <div className="last-change">{`Последнее изменение ${formatDate(
                focusPromotions?.updated_at
              )} ${focusPromotions?.user.first_name} ${focusPromotions?.user?.last_name.slice(
                0,
                1
              )}.`}</div>
            </div>
          ) : (
            <div className="upper-line">
              <div className="cont-l">
                <Button
                  typebtn="outline"
                  className="btn-back small"
                  onClick={() => {
                    history.push("/partner/showcase/promotions");
                    // window.location.reload();
                  }}
                >
                  <Icons icon="design-personal-chevron-left" className="icon-outline" />
                </Button>
                <div className="title">Новая акция</div>
              </div>

              <Button
                disabled={loading}
                loading={loading}
                className={`btn-save-draft small ${loading ? "disabled" : ""}`}
                typebtn="bright"
                onClick={() => {
                  if (userPermissions[2]) {
                    onSave();
                    setLoading(true);
                  } else {
                    setOpenToast(true);
                    setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
                  }
                }}
              >
                Сохранить
              </Button>
            </div>
          )}
          <div className="body">
            <div className="input-cont">
              <div className="line">
                <div className="title-line">Название</div>
                {/* <Input
                  value={formForCreatePromotion.name || ""}
                  placeholder="Не указано..."
                  className="large input-line"
                  noImage
                  onChange={(e) => {
                    dispatch(setFormForCreatePromotion({ ...formForCreatePromotion, name: e }));
                  }}
                /> */}
                <AutoCompleteSelect
                  noImage
                  sleep={2}
                  name="name"
                  value={name}
                  typesel="large"
                  className="large input-line"
                  placeholder="Название"
                  array={promotionsDirectory}
                  onChange={(e) => {
                    setName(e.value);
                    if (typeof e.value === "string") {
                      dispatch(
                        setFormForCreatePromotion({ ...formForCreatePromotion, name: e.value })
                      );
                    }
                    if (typeof e.value !== "string") {
                      dispatch(
                        setFormForCreatePromotion({
                          ...formForCreatePromotion,
                          for_promotion_id: e.value?.id,
                          name: e.value?.name,
                        })
                      );
                    }
                  }}
                />
              </div>
              <div className="line">
                <div className="title-line">Дата старта</div>
                <Input
                  type="date"
                  value={
                    formForCreatePromotion?.start_date
                      ? new Date(formForCreatePromotion?.start_date).toLocaleDateString()
                      : ""
                  }
                  placeholder="дд.мм.гг"
                  className="large input-line"
                  noImage
                  onChange={(e) => {
                    dispatch(
                      setFormForCreatePromotion({ ...formForCreatePromotion, start_date: e })
                    );
                  }}
                />
              </div>
              <div className="line">
                <div className="title-line">Дата завершения</div>
                <Input
                  type="date"
                  value={
                    formForCreatePromotion?.end_date
                      ? new Date(formForCreatePromotion?.end_date).toLocaleDateString()
                      : ""
                  }
                  placeholder="дд.мм.гг"
                  className="large input-line"
                  noImage
                  onChange={(e) => {
                    dispatch(setFormForCreatePromotion({ ...formForCreatePromotion, end_date: e }));
                  }}
                />
              </div>
              <div className="line">
                <div className="title-line">Описание</div>
                <Input
                  type="textarea"
                  value={formForCreatePromotion.description || ""}
                  placeholder="Не указано..."
                  className="textarea-line textarea area "
                  noImage
                  onChange={(e) => {
                    dispatch(
                      setFormForCreatePromotion({ ...formForCreatePromotion, description: e })
                    );
                  }}
                />
              </div>
              <div className="line">
                <div className="title-line">Цвет акции</div>
                <div className="cont-colors-cube">
                  {colors.map((el, i) => {
                    return (
                      <div
                        key={i}
                        className={`color-cube ${i + 1 === focusColor?.indx ? "focus" : ""}`}
                        style={{ background: `${el.color}` }}
                        onClick={() => {
                          setFocusColor({ color: el.color, indx: el.id });
                          dispatch(
                            setFormForCreatePromotion({
                              ...formForCreatePromotion,
                              color: el.color,
                            })
                          );
                        }}
                      >
                        {i + 1 === focusColor?.indx && (
                          <Icons icon="circle-check-filled-b" className="icon-c" />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="line">
                <div className="title-line">Тип</div>
                <Select
                  value={typeVal || type[0]}
                  array={type}
                  // val={formForCreateTour.duration_name}
                  typesel="large"
                  className="large select-line"
                  onChange={(e) => {
                    setTypeVal(e.value);
                    dispatch(
                      setFormForCreatePromotion({
                        ...formForCreatePromotion,
                        type: e.value.id,
                        value: 1000,
                        calc_method: 1,
                      })
                    );
                  }}
                  placeholder="Выберите из списка..."
                />
              </div>
              {typeVal?.id === 0 && (
                <div className="line">
                  <div className="title-line">Значение</div>
                  <div className="cont-input-line">
                    <div className="left-input-line">
                      {curVal?.id === 0 ? (
                        <Input
                          value={+formForCreatePromotion.value || 20}
                          placeholder="Не указано..."
                          className="large input-line-s"
                          noImage
                          onChange={(e) => {
                            dispatch(
                              setFormForCreatePromotion({
                                ...formForCreatePromotion,
                                value: e,
                              })
                            );
                          }}
                        />
                      ) : (
                        curVal?.id === 1 && (
                          <>
                            <Input
                              value={+formForCreatePromotion.value || 1000}
                              placeholder="Не указано..."
                              className="large input-line-s"
                              noImage
                              onChange={(e) => {
                                dispatch(
                                  setFormForCreatePromotion({
                                    ...formForCreatePromotion,
                                    value: e,
                                  })
                                );
                              }}
                            />
                          </>
                        )
                      )}
                      <div className="subtext">
                        {curVal?.id === 0 ? "от 1% до 90%" : curVal?.id === 1 && "Минимум 100"}
                      </div>
                    </div>
                    <Select
                      value={curVal || cur[0]}
                      array={cur}
                      // val={formForCreateTour.duration_name}
                      typesel="large"
                      className="large select-line-s"
                      onChange={(e) => {
                        setCurVal(e.value);
                        dispatch(
                          setFormForCreatePromotion({
                            ...formForCreatePromotion,
                            calc_method: e.value.id,
                            value: e.value.id ? 1000 : 20,
                          })
                        );
                      }}
                      placeholder="Выберите из списка..."
                    />
                  </div>
                </div>
              )}
              {typeVal?.id === 1 && (
                <>
                  <div className="line line-a">
                    <div className="title-line">Опции</div>
                    <div className="cont-sel-opt">
                      <div className="sel-opt">
                        <MultiSelectChip
                          value={arrOptions || ""}
                          array={options}
                          display="comma"
                          val={arrOptions}
                          typesel="large"
                          className="large select-line-a"
                          onChange={(e) => {
                            setArrOptions(e.value);
                            let arr = [];
                            for (var i = 0; i < e.value.length; i++) {
                              arr.push(e.value[i].id);
                            }
                            dispatch(
                              setFormForCreatePromotion({
                                ...formForCreatePromotion,
                                operator_options: arr.length ? arr : null,
                              })
                            );
                          }}
                          placeholder="Выберите из списка..."
                        />
                        <Button
                          typebtn="muted"
                          className="large btn-create"
                          onClick={() => {
                            if (userPermissionsOptions[1]) {
                              setOpenModalCreateOption(true);
                            } else {
                              setOpenToast(true);
                              setMessageToast({
                                text: "Недостаточно прав доступа",
                                type: "warning",
                              });
                            }
                          }}
                        >
                          <Icons icon="plus" />
                          Создать
                        </Button>
                      </div>
                      <AlertBox
                        type="info"
                        text="Выбранные опции будут бесплатны для клиента"
                        noClose
                      />
                      {arrOptions?.map((el, i) => {
                        return (
                          <div key={i} className="cont-options">
                            <div className="justify">
                              <div className="image">
                                <img
                                  src={
                                    el.pictures?.[0]?.photo
                                      ? `${URL_ORIGIN}${el.pictures[0]?.photo}`
                                      : noImage
                                  }
                                />
                              </div>
                              <div className="cont-info">
                                <div className="title">{el.name}</div>
                                <div className="cont-subtext">
                                  <div className="subtext">{`Цена: ${String(
                                    +el.basic_cost
                                  )?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}</div>
                                  <div
                                    className="link"
                                    onClick={() => {
                                      onChangeOption(el);
                                    }}
                                  >
                                    Изменить
                                  </div>
                                </div>
                                <div className="cont-subtext">
                                  <div className="subtext">{`Мест: ${+el.basic_volume}`}</div>
                                  <div
                                    className="link"
                                    onClick={() => {
                                      onChangeOption(el);
                                    }}
                                  >
                                    Изменить
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div id={`null`} />
                            <Button
                              typebtn="ghost"
                              className="small cube"
                              id={`bottom-start-${i}`}
                              onClick={() => {
                                setOpenPopover({
                                  index: i,
                                });
                              }}
                            >
                              <Icons icon="dots-vertical" className="icon-outline" />
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="tour-sel-cont">
              <div className="title">К каким турам применяется:</div>
              <AutoCompleteSelect
                itemTemplate={itemTemplate}
                showClear
                filter
                onBlur={() => setSearchProducts(null)}
                value={searchProducts}
                array={fill}
                val={arrProducts}
                typesel="small"
                className="small select-line"
                onChange={(e) => {
                  if (typeof e.value === "string") {
                    setSearchProducts(e.value);
                  }
                  if (typeof e.value !== "string") {
                    setSearchProducts(e.value);
                    if (arrProducts) {
                      let arr = arrProducts;
                      arr.push(e.value);
                      setArrProducts(arr);
                    } else {
                      let arr = [];
                      arr.push(e.value);
                      setArrProducts(arr);
                    }
                  }
                }}
                placeholder="Выберите тур..."
              />
              <div className="cont-tours-prev">
                {arrProducts?.map((el, i) => {
                  let a = format?.find((fr) => fr.id === el.product_duration_id);
                  return (
                    <div key={i}>
                      <div className="cont-info">
                        <div className="left">
                          <div className="image">
                            <img src={el.photo ? `${URL_ORIGIN}${el.photo}` : noImage} />
                          </div>
                          <div className="text-cont">
                            <div className="text">{el.name}</div>
                            <div className="subtext">{a?.name}</div>
                          </div>
                          <div id={`null`} />
                        </div>
                        <Button
                          typebtn="ghost"
                          className="small cube"
                          id={`bottom-start-t-${i}`}
                          onClick={() => {
                            setOpenPopoverProd({
                              index: i,
                            });
                          }}
                        >
                          <Icons icon="dots-vertical" className="icon-outline" />
                        </Button>
                      </div>
                      {arrProducts.length > 1 && <div className="l"></div>}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </IonContent>
      <IonPopover
        class="popover-menu-el-list"
        isOpen={openPopoverDel}
        onWillDismiss={() => setOpenPopoverDel(false)}
        trigger={openPopoverDel !== false ? `bottom-start-d` : undefined}
        side="bottom"
        alignment="start"
      >
        <div className="body-popover">
          <div
            className="cont-el-pop"
            onClick={() => {
              if (userPermissions[3]) {
                setOpenPopoverDel(false);
                setModalDeletePromotions(true);
              } else {
                setOpenToast(true);
                setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
              }
            }}
          >
            <Icons icon="design-personal-trash" />
            <div className="text">Удалить</div>
          </div>
        </div>
      </IonPopover>
      <IonPopover
        class="popover-menu-el-list"
        isOpen={openPopover.index || (openPopover.index === 0 && true)}
        onWillDismiss={() => setOpenPopover(false)}
        trigger={openPopover !== false ? `bottom-start-${openPopover.index}` : undefined}
        side="bottom"
        alignment="start"
      >
        <div className="body-popover">
          <div
            className="cont-el-pop"
            onClick={() => {
              setOpenPopover(false);
              let arr = arrOptions;
              let el = arrOptions[openPopover.index];
              arr.splice(openPopover.index, 1);
              setArrOptions(arr);
            }}
          >
            <Icons icon="design-personal-trash" />
            <div className="text">Удалить</div>
          </div>
        </div>
      </IonPopover>
      <IonPopover
        class="popover-menu-el-list"
        isOpen={openPopoverProd.index || (openPopoverProd.index === 0 && true)}
        onWillDismiss={() => setOpenPopoverProd(false)}
        trigger={openPopoverProd !== false ? `bottom-start-t-${openPopoverProd.index}` : undefined}
        side="bottom"
        alignment="start"
      >
        <div className="body-popover">
          <div
            className="cont-el-pop"
            onClick={() => {
              setOpenPopoverProd(false);
              let arr = arrProducts;
              let el = arrProducts[openPopoverProd.index];
              arr.splice(openPopoverProd.index, 1);
              setArrProducts(arr);
            }}
          >
            <Icons icon="design-personal-trash" />
            <div className="text">Удалить</div>
          </div>
        </div>
      </IonPopover>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
      <ModalCreateOptions onClose={setOpenModalCreateOption} open={openModalCreateOption} />
      <ModalDelete
        open={modalDeletePromotions}
        onClose={setModalDeletePromotions}
        onClick={() => {
          deletePromotions(token, formForCreatePromotion.id).then(async (e) => {
            if (!e.data.errors) {
              await getPromotions(token).then((g) => {
                const arr = g.data.data;
                arr?.forEach((el) => {
                  el.name = el?.promotion?.name;
                });
                dispatch(setPromotions(arr));
              });
              history.push("/partner/showcase/promotions");
            }
          });
        }}
      />
    </>
  );
};

export default PromotionsCreate;
