import { IonModal } from "@ionic/react";
import Select from "../Select";
import Input from "../Input";
import Button from "../Button";
import Close from "../Close";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import qs from "qs";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import MultiSelectChip from "../MultiSelectChip";

const ModalFilterOrders = (props) => {
  const history = useHistory();
  const urlSearch = qs.parse(history.location.search.substr(1));
  const [filterClient, setFilterClient] = useState();
  const orders = useSelector((state) => state.orders);
  orders?.forEach((el) => {
    el.name = `${el?.client?.user?.first_name} ${el?.client?.user?.last_name}`;
  });

  const res = orders?.reduce((o, i) => {
    if (!o.find((v) => v?.user_id == i?.user_id)) {
      o.push(i);
    }
    return o;
  }, []);

  const DEFAULT = {
    start_from: "",
    start_until: "",
    end_from: "",
    end_until: "",
    paid_from: "",
    paid_until: "",
    comission_from: "",
    comission_until: "",
    fine_from: "",
    fine_until: "",
    to_pay_from: "",
    to_pay_until: "",
    participants_from: "",
    participants_until: "",
    price_from_order: "",
    price_until_order: "",
    options_from: "",
    options_until: "",
  };
  const [filterElements, setFilterElements] = useState(DEFAULT);

  const removeEmpty = (obj) =>
    Object.entries(obj)
      .filter(([, v]) => v != null && v !== "")
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

  useAsyncEffect(() => {
    if (!urlSearch?.client) {
      setFilterClient(null);
    } else {
      let arrF = [];
      let urlF = urlSearch?.client?.split(",");
      for (let i = 0; i < urlF?.length; i++) {
        let find = orders?.find((el) => el?.user_id === +urlF[i]);
        arrF.push(find);
      }
      setFilterClient(arrF);
    }
  }, [props.open]);

  const func = (obj1, obj2) => {
    let result = {};
    for (let prop in obj1) {
      if (
        obj2.hasOwnProperty(prop) ||
        prop === "page" ||
        prop === "date_from" ||
        prop === "date_until" ||
        prop === "name_client"
      )
        result[prop] = obj1[prop];
    }
    return result;
  };

  const onApply = () => {
    let data = urlSearch;
    let array = filterElements;
    let filter = removeEmpty(array);
    data = func(data, filter);
    let merge = Object.assign(data, filter);
    merge.page = 1;
    if (filterClient) {
      let f = [];
      for (let i = 0; i < filterClient?.length; i++) {
        f.push(filterClient[i]?.user_id);
      }
      if (f.length) {
        merge.client = f;
      } else {
        delete merge?.client;
      }
    }
    const params = new URLSearchParams(merge);
    history.push(`?${params.toString()}`);
    props.onClose(false);
  };
  useEffect(() => {
    setFilterElements(urlSearch);
  }, [props.open]);

  return (
    <IonModal
      className="filter-table-orders"
      isOpen={props.open}
      onWillDismiss={() => {
        props.onClose(false);
        setFilterElements(DEFAULT);
      }}
    >
      <div className="body-modal">
        <Close
          onClick={() => {
            props.onClose(false);
            setFilterElements(DEFAULT);
          }}
        />
        <div className="title">Фильтры</div>
        <div className="body">
          <MultiSelectChip
            value={filterClient || ""}
            array={res}
            display="comma"
            val={filterClient}
            typesel="small"
            className="small"
            onChange={(e) => {
              setFilterClient(e.value);
            }}
            placeholder="клиент: все"
          />
          <div className="line ">
            <Input
              value={
                urlSearch?.start_from
                  ? filterElements?.start_from &&
                    new Date(+filterElements?.start_from).toLocaleDateString()
                  : ""
              }
              type="date"
              name="start_from"
              className="small input-short"
              placeholder="старт от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, start_from: new Date(e).getTime() });
              }}
            />

            <Input
              value={
                urlSearch?.start_until
                  ? filterElements?.start_until &&
                    new Date(+filterElements?.start_until).toLocaleDateString()
                  : ""
              }
              type="date"
              name="start_until"
              className="small input-short"
              placeholder="старт до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, start_until: new Date(e).getTime() });
              }}
            />
          </div>
          <div className="line ">
            <Input
              value={
                urlSearch?.end_from
                  ? filterElements?.end_from &&
                    new Date(+filterElements?.end_from).toLocaleDateString()
                  : ""
              }
              type="date"
              name="end_from"
              className="small input-short"
              placeholder="завершение от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, end_from: new Date(e).getTime() });
              }}
            />

            <Input
              value={
                urlSearch?.end_until
                  ? filterElements?.end_until &&
                    new Date(+filterElements?.end_until).toLocaleDateString()
                  : ""
              }
              type="date"
              name="end_until"
              className="small input-short"
              placeholder="завершение до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, end_until: new Date(e).getTime() });
              }}
            />
          </div>
          <div className="line">
            <Input
              value={urlSearch?.paid_from ? filterElements?.paid_from : ""}
              name="paid_from"
              className="small input-short"
              placeholder="оплачено от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, paid_from: e });
              }}
            />
            <Input
              value={urlSearch?.paid_until ? filterElements?.paid_until : ""}
              name="paid_until"
              className="small input-short"
              placeholder="оплачено до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, paid_until: e });
              }}
            />
          </div>
          <div className="line">
            <Input
              value={urlSearch?.comission_from ? filterElements?.comission_from : ""}
              name="comission_from"
              className="small input-short"
              placeholder="комиссия от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, comission_from: e });
              }}
            />
            <Input
              value={urlSearch?.comission_until ? filterElements?.comission_until : ""}
              name="comission_until"
              className="small input-short"
              placeholder="комиссия до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, comission_until: e });
              }}
            />
          </div>
          <div className="line">
            <Input
              value={urlSearch?.fine_from ? filterElements?.fine_from : ""}
              name="fine_from"
              className="small input-short"
              placeholder="штраф от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, fine_from: e });
              }}
            />
            <Input
              value={urlSearch?.fine_until ? filterElements?.fine_until : ""}
              name="fine_until"
              className="small input-short"
              placeholder="штраф до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, fine_until: e });
              }}
            />
          </div>
          <div className="line">
            <Input
              value={urlSearch?.to_pay_from ? filterElements?.to_pay_from : ""}
              name="to_pay_from"
              className="small input-short"
              placeholder="к выплате от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, to_pay_from: e });
              }}
            />
            <Input
              value={urlSearch?.to_pay_until ? filterElements?.to_pay_until : ""}
              name="to_pay_until"
              className="small input-short"
              placeholder="к выплате до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, to_pay_until: e });
              }}
            />
          </div>
          <div className="line">
            <Input
              value={urlSearch?.participants_from ? filterElements?.participants_from : ""}
              name="participants_from"
              className="small input-short"
              placeholder="участников от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, participants_from: e });
              }}
            />
            <Input
              value={urlSearch?.participants_until ? filterElements?.participants_until : ""}
              name="participants_until"
              className="small input-short"
              placeholder="участников до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, participants_until: e });
              }}
            />
          </div>
          <div className="line">
            <Input
              value={urlSearch?.price_from_order ? filterElements?.price_from_order : ""}
              name="price_from_order"
              className="small input-short"
              placeholder="стоимость тура от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, price_from_order: e });
              }}
            />
            <Input
              value={urlSearch?.price_until_order ? filterElements?.price_until_order : ""}
              name="price_until_order"
              className="small input-short"
              placeholder="стоимость тура до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, price_until_order: e });
              }}
            />
          </div>
          <div className="line">
            <Input
              value={urlSearch?.options_from ? filterElements?.options_from : ""}
              name="options_from"
              className="small input-short"
              placeholder="опций от"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, options_from: e });
              }}
            />
            <Input
              value={urlSearch?.options_until ? filterElements?.options_until : ""}
              name="options_until"
              className="small input-short"
              placeholder="опций до"
              noImage
              onChange={(e) => {
                setFilterElements({ ...filterElements, options_until: e });
              }}
            />
          </div>
        </div>
        <div className="buttons">
          <Button
            className="small"
            typebtn="outline"
            onClick={async () => {
              // props.onClose(false)
              setFilterClient(null);
              setFilterElements(DEFAULT);
              let data = urlSearch;
              let newd = {};
              newd.page = 1;
              if (data?.name_client) newd.name_client = data?.name_client;
              if (data?.date_from) newd.date_from = data?.date_from;
              if (data?.date_until) newd.date_until = data?.date_until;
              const params = new URLSearchParams(newd);
              history.push(`?${params.toString()}`);
            }}
          >
            Сбросить
          </Button>
          <Button
            className="small"
            typebtn="bright"
            onClick={() => {
              onApply();
            }}
          >
            Применить
          </Button>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalFilterOrders;
