import { IonModal } from "@ionic/react";
import Close from "../../ui/Close";
import Rating from "../../ui/Rating";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import Input from "../../ui/Input";
import avatar from "../../image/avatar.svg";
import { useEffect, useState } from "react";
import { createDefaultMaskGenerator, mask } from "react-hook-mask";
import { setFAQ, setFormQuestion, setReviews } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { getFAQ, getReviews, getReviewsByID, postFAQ, putReviews } from "../../utils/api";
import Toast from "../../ui/Toast";

const ModalQuestionsDetails = (props) => {
  const maskGenerator = createDefaultMaskGenerator("+9 (999) 999-99-99");
  const [loading, setLoading] = useState(false);

  const userPermissions = useSelector((state) => state.userPermissions.feedback?.questions);

  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });

  const token = useSelector((state) => state.token);
  const formQuestion = useSelector((state) => state.formQuestion);
  const dispatch = useDispatch();

  useAsyncEffect(async () => {
    props.element &&
      (await getReviewsByID(token, props.element?.id).then((el) => {
        if (!el.data.errors) {
          dispatch(setFormQuestion(el.data.data));
        }
      }));
  }, [props.element]);

  const formatDate = (value) => {
    var timezone = new Date(value).getTimezoneOffset() / 60;
    var lastChangeDate = ` ${new Date(value).toLocaleDateString().slice(0, 2)} ${new Date(
      value
    ).toLocaleDateString("ru", { month: "short" })} ${new Date(value)
      .toLocaleDateString()
      .slice(6, 11)}, ${new Date(value).toLocaleTimeString().slice(0, 5)} (UTC${
      +timezone < 0 ? `+${+timezone * -1 > 9 ? "" : "0"}${+timezone * -1}:00` : `-${+timezone * -1}`
    })`;
    return lastChangeDate;
  };

  const onSave = () => {
    let array = { ...formQuestion, status_id: 2 };
    putReviews(token, formQuestion.id, array).then(async (e) => {
      if (!e.data.errors) {
        await getReviews(token).then((q) => {
          if (!q.data.errors) {
            dispatch(setReviews(q.data.data));
          }
        });
        await getReviewsByID(token, formQuestion?.id).then((z) => {
          if (!z.data.errors) {
            dispatch(setFormQuestion(z.data.data));
          }
        });
      }
      setLoading(false);
    });
  };

  return (
    <>
      <IonModal
        className="modal-questions-details"
        isOpen={props.open}
        onWillDismiss={() => {
          props.onClose(false);
          dispatch(setFormQuestion({}));
          props.setFocusElement(null);
        }}
      >
        <Close
          onClick={() => {
            props.onClose(false);
            dispatch(setFormQuestion({}));
            props.setFocusElement(null);
          }}
        />
        {formQuestion?.status_id === 1 ? (
          <div className="body-modal-questions-details">
            <div className="client-cont">
              <div className="circle">
                <img src={avatar} />
              </div>
              <div className="info-cont">
                <div className="text">{`${formQuestion?.user?.first_name} ${formQuestion?.user?.last_name[0]}.`}</div>
                <div className="subtext">
                  {formQuestion?.user?.phone && mask(formQuestion?.user?.phone, maskGenerator)}
                </div>
              </div>
            </div>
            <div className="cont-text-information">
              <div className="line">
                <div className="name">{formQuestion?.product?.name}</div>
                <div className="text">{formQuestion?.review_text}</div>
                <div className="subtext">{formatDate(formQuestion?.created_at)}</div>
              </div>
              <Button
                typebtn="ghost"
                className="small btn-report"
                onClick={() => {
                  if (userPermissions[2]) {
                    props.onClose(false);
                    props.setOpenModalReport(true);
                  } else {
                    setOpenToast(true);
                    setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
                  }
                }}
              >
                <Icons icon="alert-circle" />
                Пожаловаться
              </Button>
            </div>
            <Input
              placeholder="Ваш ответ..."
              noImage
              className="large area area-reviews"
              type="textarea"
              onChange={(e) => {
                dispatch(
                  setFormQuestion({
                    ...formQuestion,
                    response_text: e,
                    response_date: new Date(),
                  })
                );
              }}
            />
            <div className="btns">
              <Button
                typebtn="outline"
                className="small"
                onClick={() => {
                  props.onClose(false);
                  dispatch(setFormQuestion({}));
                  props.setFocusElement(null);
                }}
              >
                Отмена
              </Button>
              <Button
                loading={loading}
                disabled={loading}
                typebtn="bright"
                className={`small ${loading ? "disabled" : ""} btn-save`}
                onClick={() => {
                  if (userPermissions[1]) {
                    onSave();
                    setLoading(true);
                  } else {
                    setOpenToast(true);
                    setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
                  }
                }}
              >
                Опубликовать ответ
              </Button>
            </div>
          </div>
        ) : (
          <div className="body-modal-questions-details">
            <div className="client-cont">
              <div className="circle">
                <img src={avatar} />
              </div>
              <div className="info-cont">
                <div className="text">{`${formQuestion?.user?.first_name} ${formQuestion?.user?.last_name[0]}.`}</div>
                <div className="subtext">{formQuestion?.user?.phone}</div>
              </div>
            </div>
            <div className="cont-text-information">
              <div className="line">
                <div className="name">{formQuestion?.product?.name}</div>
                <div className="text">{formQuestion?.review_text}</div>
                <div className="subtext">{formatDate(formQuestion?.created_at)}</div>
              </div>
              <Button
                typebtn="ghost"
                className="small btn-report"
                onClick={() => {
                  if (userPermissions[2]) {
                    props.onClose(false);
                    props.setOpenModalReport(true);
                  } else {
                    setOpenToast(true);
                    setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
                  }
                }}
              >
                <Icons icon="alert-circle" />
                Пожаловаться
              </Button>
              <div className="l" />
              <div className="answer-cont">
                <div className="client-cont">
                  <div className="circle">
                    <img src={avatar} />
                  </div>
                  <div className="info-cont">
                    <div className="text">{`${formQuestion?.response_user?.first_name} ${formQuestion?.response_user?.last_name[0]}.`}</div>
                    <div className="subtext">{formQuestion?.response_user?.phone}</div>
                  </div>
                </div>
                <div className="cont-text-information">
                  <div className="line">
                    <div className="name">{formQuestion?.product?.name}</div>
                    <div className="text">{formQuestion?.response_text}</div>
                    <div className="subtext">{formatDate(formQuestion?.response_date)}</div>
                  </div>
                </div>
                <Button
                  typebtn="ghost"
                  className="small btn-ed"
                  onClick={() => {
                    if (userPermissions[1]) {
                      dispatch(setFormQuestion({ ...formQuestion, status_id: 1 }));
                    } else {
                      setOpenToast(true);
                      setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
                    }
                  }}
                >
                  <Icons icon="design-personal-edit" />
                  <div className="text">Редактировать ответ</div>
                </Button>
              </div>
            </div>
            <div className="btns">
              <Button
                typebtn="outline"
                className="small"
                onClick={() => {
                  props.onClose(false);
                  dispatch(setFormQuestion({}));
                  props.setFocusElement(null);
                }}
              >
                Отмена
              </Button>
              <Button
                typebtn="muted"
                className="small"
                onClick={() => {
                  let arr = {
                    response_text: formQuestion?.response_text,
                    review_text: formQuestion?.review_text,
                    status_id: 2,
                    product_id: formQuestion?.product_id,
                    type: 2,
                  };
                  postFAQ(token, arr).then(async (e) => {
                    if (!e.data.errors) {
                      await getFAQ(token).then((e) => {
                        dispatch(setFAQ(e.data.data));
                      });
                      props.onClose(false);
                      dispatch(setFormQuestion({}));
                      props.setFocusElement(null);
                    }
                  });
                }}
              >
                <Icons icon="plus" />
                Добавить в Частые вопросы
              </Button>
            </div>
          </div>
        )}
      </IonModal>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </>
  );
};

export default ModalQuestionsDetails;
