import "../less/css/custom.css";
import bgDecor from "../image/bg-btn-decor-large.svg";
import { IonSpinner } from "@ionic/react";

const Button = (props) => {
  return props.typebtn === "bright" ? (
    <button
      id={props.id}
      disabled={props.disabled}
      form={props.form}
      type={props.type}
      className={`custom-btn bright ${props.className}`}
      onClick={props.onClick}
      onMouseLeave={props.onMouseLeave}
    >
      {props.loading ? (
        <div className="cont-spinner">
          <IonSpinner className="loading-btn" name="circular"></IonSpinner>
        </div>
      ) : (
        <div className="text-btn bright">{props.children}</div>
      )}
    </button>
  ) : props.typebtn === "muted" ? (
    <button
      id={props.id}
      disabled={props.disabled}
      form={props.form}
      type={props.type}
      className={`custom-btn muted ${props.className}`}
      onClick={props.onClick}
    >
      {props.loading ? (
        <IonSpinner className="loading-btn" name="circular"></IonSpinner>
      ) : (
        <div className="text-btn muted">{props.children}</div>
      )}
    </button>
  ) : props.typebtn === "outline" ? (
    <button
      id={props.id}
      disabled={props.disabled}
      form={props.form}
      type={props.type}
      className={`custom-btn outline ${props.className}`}
      onClick={props.onClick}
    >
      {props.loading ? (
        <IonSpinner className="loading-btn" name="circular"></IonSpinner>
      ) : (
        <div className="text-btn outline">{props.children}</div>
      )}
    </button>
  ) : props.typebtn === "ghost" ? (
    <button
      id={props.id}
      disabled={props.disabled}
      form={props.form}
      type={props.type}
      className={`custom-btn ghost ${props.className}`}
      onClick={props.onClick}
      onMouseLeave={props.onMouseLeave}
    >
      {props.loading ? (
        <IonSpinner className="loading-btn" name="circular"></IonSpinner>
      ) : (
        <div className="text-btn ghost">{props.children}</div>
      )}
    </button>
  ) : (
    props.typebtn === "elevated" && (
      <button
        id={props.id}
        disabled={props.disabled}
        form={props.form}
        type={props.type}
        className={`custom-btn elevated ${props.className}`}
        onClick={props.onClick}
      >
        {props.loading ? (
          <IonSpinner className="loading-btn" name="circular"></IonSpinner>
        ) : (
          <div className="text-btn elevated">{props.children}</div>
        )}
      </button>
    )
  );
};

export default Button;
