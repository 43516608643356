import { Chart } from "primereact/chart";
import { useEffect, useState } from "react";

const ChartLine = (props) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  let label =
    props.labels &&
    Array(props.labels)
      .fill()
      .map((e, i) => i + 1);
  const colors = {
    green: {
      color: "rgba(47, 188, 43, 1)",
      back: "rgba(47, 188, 43, 0)",
      grad: "rgba(47, 188, 43, 0.3)",
    },
    orange: {
      color: "rgba(254, 128, 52, 1)",
      back: "rgba(254, 128, 52, 0)",
      grad: "rgba(254, 128, 52, 0.3)",
    },
  };
  useEffect(() => {
    const data = {
      labels: label,
      datasets: [
        {
          label: "Sales",
          data: props.data,

          backgroundColor: ({ chart: { ctx } }) => {
            const bg = ctx.createLinearGradient(0, 50, 0, 0);
            bg.addColorStop(0, colors[props.color]?.back);
            bg.addColorStop(1, colors[props.color]?.grad);
            return bg;
          },
          borderColor: colors[props.color]?.color, //green
          fill: true,
          pointStyle: false,
          borderWidth: 2,
          lineTension: 0.2,
          cubicInterpolationMode: "monotone",
        },
      ],
    };
    const options = {
      scales: {
        y: {
          grid: {
            drawTicks: false,
            display: false,
          },
          border: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [props.data, props.label]);

  return (
    <div className="cont-chart">
      <Chart height="44" type="line" data={chartData} options={chartOptions} />
    </div>
  );
};
export default ChartLine;
