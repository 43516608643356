import { IonModal } from "@ionic/react";
import Close from "../../ui/Close";
import Icons from "../../ui/Icons";
import Button from "../../ui/Button";
import ExcelJS from "exceljs";
import { useHistory } from "react-router";

const OperationsHistoryDetails = (props) => {
  const history = useHistory();
  const formatDate = (value) => {
    var timezone = new Date(value).getTimezoneOffset() / 60;
    var lastChangeDate = ` ${new Date(value).toLocaleDateString().slice(0, 2)} ${new Date(
      value
    ).toLocaleDateString("ru", { month: "short" })} ${new Date(value)
      .toLocaleDateString()
      .slice(6, 11)}, ${new Date(value).toLocaleTimeString().slice(0, 5)} (UTC${
      +timezone < 0 ? `+${+timezone * -1 > 9 ? "" : "0"}${+timezone * -1}:00` : `-${+timezone * -1}`
    })`;
    return lastChangeDate;
  };

  const exportToExcel = () => {
    let array = [];

    let filename = `Операция №${props.element?.id} ${new Date().toLocaleDateString("ru")}`;
    let new_array = {
      Номер: props.element?.id,
      "Дата создания операции": props.element?.created_at,
      "Тип операции": props.element?.type.name,
      Заказ:
        props.element?.operation_type_id === 1 ||
        props.element?.operation_type_id === 2 ||
        props.element?.operation_type_id === 4
          ? props.element?.order?.id
          : "",
      Сумма: +props.element?.summa,
      Инициатор:
        props.element?.operation_type_id === 5 || props.element?.operation_type_id === 6
          ? "Заглушка"
          : "",
      Источник: props.element?.operation_type_id === 6 ? "Вне платформы" : "",
      Статус: props.element?.status.description,
    };

    array.push(new_array);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("User Data");
    const headerRow = worksheet.addRow([]);
    const headerCells = Object.values(array);
    for (let i = 0; i < headerCells?.length; i++) {
      const column = Object.keys(headerCells[i]);
      for (let j = 0; j < column?.length; j++) {
        headerRow.getCell(j + 1).value = column[j];
      }
    }
    const rows = Object.values(array);
    for (let i = 0; i < rows?.length; i++) {
      const cells = Object.values(rows[i]);
      const rowData = [];
      for (let j = 0; j < cells.length; j++) {
        rowData.push(cells[j]);
      }
      worksheet.addRow(rowData);
      worksheet.columns.forEach(function (column, i) {
        let maxLength = 0;
        column["eachCell"]({ includeEmpty: true }, function (cell) {
          var columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
      });
    }
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
      });
      var link = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = link;
      a.download = `${filename}.xlsx`;
      a.click();
      // window.location = link;
      // saveAs(blob, `output.xlsx`);
    });
  };

  return (
    <IonModal
      isOpen={props.open}
      onWillDismiss={() => props.onClose(false)}
      className={`modal-detail-operations-hist `}
    >
      <Close onClick={() => props.onClose(false)} />
      <div className="body-modal-detail-operations-hist">
        <div className="title">{`Операция №${props.element?.id}`}</div>
        <div className="info">
          <div className="history-orders">
            {props.element?.status_id === 2 && (
              <div className="line">
                <Icons icon="circle-check-filled" />
                <div className="cont-text">
                  <div className="text">Успешно</div>
                  <div className="subtext green">{formatDate(props.element?.updated_at)}</div>
                </div>
              </div>
            )}
            {props.element?.status_id === 3 && (
              <div className="line">
                <Icons icon="circle-x-filled" />
                <div className="cont-text">
                  <div className="text">Ошибка</div>
                  <div className="subtext red">{formatDate(props.element?.updated_at)}</div>
                </div>
              </div>
            )}
            <div className="line">
              <Icons icon="design-personal-edit" className="icon-ed" />
              <div className="cont-text">
                <div className="text">Создан</div>
                <div className="subtext gray">{formatDate(props.element?.order.created_at)}</div>
              </div>
            </div>
          </div>
          <div className="lin" />
          <div className="details">
            <div className="title">Детали</div>
            <div className="cont-det">
              {(props.element?.type.id === 5 || props.element?.type.id === 6) && (
                <div className="line-det">
                  <div className="subtext">Инициатор</div>
                  <div className="text">{`Алексей Б. (сотрудник)`}</div>
                </div>
              )}
              <div className="line-det">
                <div className="subtext">Номер документа</div>
                <div className="text">{props.element?.num}</div>
              </div>
              <div className="line-det">
                <div className="subtext">Тип</div>
                <div className="text">
                  {props.type?.find((el) => el.type === props.element?.type.id)?.type_name}
                </div>
              </div>
              <div className="line-det">
                <div className="subtext">Сумма</div>
                <div className="text">
                  {String(+props.element?.summa).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                </div>
              </div>
              {props.element?.type.id !== 5 && props.element?.type.id !== 6 && (
                <div className="line-det">
                  <div className="subtext">Заказ</div>
                  <div
                    className="text name"
                    onClick={() => {
                      history.push(`/partner/finance/orders/details?id=${props.element?.order.id}`);
                    }}
                  >
                    {props.element?.order.id}
                  </div>
                </div>
              )}
              {props.element?.type.id === 6 && (
                <div className="line-det">
                  <div className="subtext">Источник</div>
                  <div className="text">Вне платформы</div>
                </div>
              )}
            </div>
          </div>
          {props.element?.type.id === 5 && <div className="lin" />}
          {props.element?.type.id === 5 && (
            <div className="details">
              <div className="title">Реквизиты получателя</div>
              <div className="cont-det">
                <div className="line-det">
                  <div className="subtext">Полное наименование</div>
                  <div className="text">ООО «CoralTravel»</div>
                </div>
                <div className="line-det">
                  <div className="subtext">ИНН</div>
                  <div className="text">00000000000</div>
                </div>
                <div className="line-det">
                  <div className="subtext">КПП</div>
                  <div className="text">00000000000</div>
                </div>
                <div className="line-det">
                  <div className="subtext">Р./счёт</div>
                  <div className="text">000000000000000000</div>
                </div>
                <div className="line-det">
                  <div className="subtext">Корр. счёт</div>
                  <div className="text">000000000000000000</div>
                </div>
                <div className="line-det">
                  <div className="subtext">БИК</div>
                  <div className="text">000000000000000000</div>
                </div>
                <div className="line-det">
                  <div className="subtext">Банк</div>
                  <div className="text">ЗАО «БАО»</div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="btns">
          <Button typebtn="outline" className="small" onClick={() => props.onClose(false)}>
            Закрыть
          </Button>
          <Button typebtn="muted" className="small" onClick={() => exportToExcel()}>
            <Icons icon="download" />
            Скачать
          </Button>
        </div>
      </div>
    </IonModal>
  );
};

export default OperationsHistoryDetails;
