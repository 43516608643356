import { useState, useMemo } from "react";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";

import "../less/css/custom.css";
import Icons from "./Icons";

const Select = (props) => {
  return (
    <div className="cont-select">
      <Dropdown
        disabled={props.disabled}
        name={props.name}
        filter={props.filter}
        showClear={props.showClear}
        editable={props.editable}
        value={props.value}
        emptyFilterMessage="Не найдено"
        emptyMessage="Не найдено"
        onChange={props.onChange}
        options={props.array}
        optionLabel={props.optionLabel || "name"}
        placeholder={props.placeholder}
        className={`select ${props.className} ${!props.val ? "undef" : ""}`}
        dropdownIcon={<Icons icon="chevron-down" className={`icon-select ${props.typesel} `} />}
        // clearIcon={<Icons icon="x" className={`icon-clear ${props.typesel} `} />}
      />
    </div>
  );
};

export default Select;
