import { IonContent } from "@ionic/react";
import "../../less/css/showcase.css";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import Table from "../../ui/Table";
import noImage from "../../image/no-image.svg";

import { FIELD_FOR_OPTIONS, STATUSES_OPTIONS } from "../../utils/array";
import { useEffect, useState } from "react";
import ModalFilterOptions from "../../ui/ModalsFilter/ModalFilterOptions";
import ModalCreateOptions from "./Modals/ModalCreateOptions";
import ModalDelete from "./Modals/ModalDelete";
import { useDispatch, useSelector } from "react-redux";
import { deleteOptions, getOptions } from "../../utils/api";
import { setFormForCreateOption, setOptions } from "../../store/actions";
import Toast from "../../ui/Toast";

const OptionsContent = () => {
  const dispatch = useDispatch();
  const formForCreateOption = useSelector((state) => state.formForCreateOption);
  const userPermissions = useSelector((state) => state.userPermissions.showcase?.options);

  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  const columnsTable = useSelector((state) => state.columnsTable);

  const token = useSelector((state) => state.token);
  const options = useSelector((state) => state.options);

  const [focusElement, setFocusElement] = useState();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openModalCreateOption, setOpenModalCreateOption] = useState(false);
  const [modalDeleteOption, setModalDeleteOption] = useState(false);
  const [openMenuElem, setOpenMenuElem] = useState(false);
  // const fieldArray = FIELD_FOR_OPTIONS;
  const status = STATUSES_OPTIONS;

  const numberColumns = 3;
  const [fieldArray, setFieldArray] = useState();
  useEffect(() => {
    setFieldArray(JSON.parse(localStorage.getItem("column"))[numberColumns]);
  }, [columnsTable]);

  var arrayForExcel = [];

  for (let i = 0; i < options?.length; i++) {
    let new_array = {
      Номер: options[i]?.id,
      Название: options[i]?.option.name,
      Цена: +options[i]?.basic_cost,
      Мест: +options[i]?.basic_volume,
      "Дата создания опции": options[i]?.created_at,
      Статус: options[i]?.status_name,
    };
    arrayForExcel.push(new_array);
  }

  const menu = [
    {
      name: "Редактировать",
      icon: "design-personal-edit",
      click: (e) => {
        if (userPermissions[0]) {
          setOpenModalCreateOption(true);
          dispatch(setFormForCreateOption({ ...focusElement }));
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
        setOpenMenuElem(false);
      },
      status: [1, 0],
    },
    {
      name: "Удалить",
      icon: "design-personal-trash",
      click: () => {
        if (userPermissions[3]) {
          setModalDeleteOption(true);
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
        setOpenMenuElem(false);
      },
      status: [1, 0],
    },
  ];
  return (
    <>
      <IonContent className="page">
        <div className="showcase-options">
          <div className="upper-line">
            <div className="title">Список опций</div>
            <Button
              className="btn-create small"
              typebtn="bright"
              onClick={() => setOpenModalCreateOption(true)}
            >
              <Icons className="icon-bright" icon="plus" />
              Создать
            </Button>
          </div>

          <Table
            onClick={(el) => {
              if (userPermissions[0]) {
                setOpenModalCreateOption(true);
                dispatch(setFormForCreateOption({ ...el }));
              } else {
                setOpenToast(true);
                setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
              }
            }}
            openMenuElem={openMenuElem}
            setOpenMenuElem={setOpenMenuElem}
            openFilterModal={setOpenFilterModal}
            focus={setFocusElement}
            status={status}
            array={options}
            header={fieldArray}
            numberColumns={numberColumns}
            menu={menu}
            permission={userPermissions}
            classNameTableSetting="options"
            arrayForExcel={arrayForExcel}
            nameFile={`Таблица опций ${new Date().toLocaleDateString("ru")}`}
            searchPlaceholder="Поиск по названию..."
          />
        </div>
      </IonContent>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
      <ModalFilterOptions onClose={setOpenFilterModal} open={openFilterModal} />
      <ModalCreateOptions onClose={setOpenModalCreateOption} open={openModalCreateOption} />
      <ModalDelete
        open={modalDeleteOption}
        onClose={setModalDeleteOption}
        onClick={() => {
          deleteOptions(token, focusElement.id).then(async (e) => {
            if (!e.data.errors) {
              await getOptions(token).then((g) => {
                const arr = g.data.data;
                arr?.forEach((el) => {
                  el.name = el?.option.name;
                });
                dispatch(setOptions(arr));
                let arrFocus = g.data.data.find((el) => el.id === formForCreateOption?.id);
                dispatch(setFormForCreateOption({ ...arrFocus }));
              });
            }
            setModalDeleteOption(false);
          });
        }}
      />
    </>
  );
};

export default OptionsContent;
