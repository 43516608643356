import { IonMenu, IonMenuToggle } from "@ionic/react";
import "../../less/css/firstpage.css";
import Button from "../../ui/Button";
import { setModalLogin, setModalLoginPartner } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import OK from "../../image/social/OK.svg";
import VK from "../../image/social/VK.svg";
import Telegram from "../../image/social/Telegram.svg";
import { useHistory, useLocation } from "react-router";
import Icons from "../../ui/Icons";

const LeftMenu = () => {
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const ArrMenuClient = [
    {
      name: "Мои туры",
      icon: "list-details",
    },
    {
      name: "Мой профиль",
      icon: "user",
    },
  ];
  const ArrMenu = [
    {
      name: "Поиск тура",
      icon: "map-pin-search",
    },
    {
      name: "Как это работает",
      icon: "pointer-question",
    },
    {
      name: "О компании",
      icon: "building-skyscraper",
    },
    {
      name: "Контакты",
      icon: "phone",
    },
    {
      name: "Партнёрам",
      icon: "users",
    },
  ];
  return (
    <>
      <IonMenu
        type="overlay"
        swipeGesture={false}
        menuId="menu-left"
        contentId="main-content"
        className="main-left-menu"
      >
        {token && !user?.operator_user ? (
          <div className="menu">
            <IonMenuToggle className="close-left-menu" menu="menu-left">
              <Icons icon="x" className="icon-outline" />
            </IonMenuToggle>
            <div className="btn-menu">
              {ArrMenuClient.map((el, i) => {
                return (
                  <IonMenuToggle key={i}>
                    <div
                      key={i}
                      className="menu-item"
                      onClick={() => {
                        i === 0 ? history.push("/client") : history.push("/client/profile");
                      }}
                    >
                      <Icons icon={el.icon} />
                      <div className="text-menu-item">{el.name}</div>
                    </div>
                  </IonMenuToggle>
                );
              })}
            </div>
            <div className="l"></div>
            <div className="btn-menu btn-menu-client">
              {ArrMenu.map((el, i) => {
                return (
                  <IonMenuToggle key={i}>
                    <div
                      key={i}
                      className="menu-item"
                      onClick={() => {
                        i === 0
                          ? history.push("/")
                          : i === 2
                          ? history.push("/about")
                          : i === 4 && history.push("/partners");
                      }}
                    >
                      <Icons icon={el.icon} />
                      <div className="text-menu-item">{el.name}</div>
                    </div>
                  </IonMenuToggle>
                );
              })}
            </div>
            <div className="l"></div>
            <div className="other-btns">
              <IonMenuToggle menu="menu-left">
                <div
                  className="other-btn"
                  onClick={() => dispatch(setModalLoginPartner({ open: true }))}
                >
                  Вход для партнёров
                </div>
              </IonMenuToggle>

              <IonMenuToggle menu="menu-left">
                <div className="other-btn" onClick={() => history.push("/information")}>
                  Политика конфиденциальности
                </div>
              </IonMenuToggle>

              <div className="other-btn">Оферта</div>
              <div className="other-btn">Согласие на обработку данных</div>
            </div>
            <div className="contacts">
              <div className="text-contacts">{"8 (800) 900-00-00"}</div>
              <div className="text-contacts">help@smorodina.ru</div>
            </div>
            <div className="address">
              <div className="text-address">ООО “КОПАТ”</div>
              <div className="text-address">ИНН 1234567890, ОГРН 1234567890</div>
            </div>
            <div className="circles">
              <img src={VK} />
              <img src={OK} />
              <img src={Telegram} />
            </div>
          </div>
        ) : (
          <div className="menu">
            <IonMenuToggle className="close-left-menu" menu="menu-left">
              <Icons icon="x" className="icon-outline" />
            </IonMenuToggle>
            <div className="btn-menu">
              {ArrMenu.map((el, i) => {
                return (
                  <IonMenuToggle key={i}>
                    <div
                      key={i}
                      className="menu-item"
                      onClick={() => {
                        i === 0
                          ? history.push("/")
                          : i === 2
                          ? history.push("/about")
                          : i === 4 && history.push("/partners");
                      }}
                    >
                      <Icons icon={el.icon} />
                      <div className="text-menu-item">{el.name}</div>
                    </div>
                  </IonMenuToggle>
                );
              })}
            </div>
            <IonMenuToggle menu="menu-left">
              <Button
                typebtn="bright"
                className="btn-login-left-menu medium"
                onClick={() => {
                  dispatch(setModalLogin({ open: true }));
                }}
              >
                войти
                <Icons icon="login" className="icon-bright" />
              </Button>
            </IonMenuToggle>

            <div className="other-btns">
              <IonMenuToggle menu="menu-left">
                <div
                  className="other-btn"
                  onClick={() => dispatch(setModalLoginPartner({ open: true }))}
                >
                  Вход для партнёров
                </div>
              </IonMenuToggle>

              <IonMenuToggle menu="menu-left">
                <div className="other-btn" onClick={() => history.push("/information")}>
                  Политика конфиденциальности
                </div>
              </IonMenuToggle>

              <div className="other-btn">Оферта</div>
              <div className="other-btn">Согласие на обработку данных</div>
            </div>
            <div className="contacts">
              <div className="text-contacts">{"8 (800) 900-00-00"}</div>
              <div className="text-contacts">help@smorodina.ru</div>
            </div>
            <div className="address">
              <div className="text-address">ООО “КОПАТ”</div>
              <div className="text-address">ИНН 1234567890, ОГРН 1234567890</div>
            </div>
            <div className="circles">
              <img src={VK} />
              <img src={OK} />
              <img src={Telegram} />
            </div>
          </div>
        )}
      </IonMenu>
    </>
  );
};

export default LeftMenu;
