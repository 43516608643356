import { IonChip } from "@ionic/react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import Icons from "./Icons";
import { useHistory } from "react-router";
import { STATUSES_OPERATIONS_HISTORY } from "../utils/array";

const ChipsTable = (props) => {
  const urlSearch = useMemo(() => props.urlSearch, [props.urlSearch]);
  const format = useSelector((state) => state.format);
  const genre = useSelector((state) => state.genre);
  const history = useHistory();
  const orders = useSelector((state) => state.orders);
  orders?.forEach((el) => {
    el.name = `${el?.client?.user?.first_name} ${el?.client?.user?.last_name}`;
  });
  const res = orders?.reduce((o, i) => {
    if (!o.find((v) => v?.user_id == i?.user_id)) {
      o.push(i);
    }
    return o;
  }, []);
  const statuses = STATUSES_OPERATIONS_HISTORY;

  const formFormatString = (val) => {
    let arrSplit = val?.split(",");
    let string = "";
    for (let i = 0; i < arrSplit?.length; i++) {
      let find = format?.find((q) => q?.id === +arrSplit[i]);
      string += i + 1 !== arrSplit?.length ? ` ${find?.name},` : ` ${find?.name}`;
    }
    return string;
  };
  const formGenresString = (val) => {
    let arrSplit = val?.split(",");
    let string = "";
    for (let i = 0; i < arrSplit?.length; i++) {
      let find = genre?.find((q) => q?.id === +arrSplit[i]);
      string += i + 1 !== arrSplit?.length ? ` ${find?.name},` : ` ${find?.name}`;
    }
    return string;
  };

  const formClientString = (val) => {
    let arrSplit = val?.split(",");
    let string = "";
    for (let i = 0; i < arrSplit?.length; i++) {
      let find = res?.find((q) => q?.user_id === +arrSplit[i]);
      string += i + 1 !== arrSplit?.length ? ` ${find?.name},` : ` ${find?.name}`;
    }
    return string;
  };

  const formStatusString = (val) => {
    let arrSplit = val?.split(",");
    let string = "";
    for (let i = 0; i < arrSplit?.length; i++) {
      let find = statuses?.find((q) => q?.status === +arrSplit[i]);
      string += i + 1 !== arrSplit?.length ? ` ${find?.name},` : ` ${find?.name}`;
    }
    return string;
  };
  return (
    <>
      {(urlSearch?.date_from || urlSearch?.date_until) && (
        <IonChip className="chip">
          <div className="text">{`Создан: ${
            urlSearch?.date_from
              ? `${new Date(+urlSearch?.date_from).toLocaleDateString("ru")}`
              : "..."
          }-${
            urlSearch?.date_until
              ? `${new Date(+urlSearch?.date_until).toLocaleDateString("ru")}`
              : "..."
          }`}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.date_from;
              delete data.date_until;
              const params = new URLSearchParams(data);
              props.setDateOt(null);
              props.setDateDo(null);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {urlSearch?.format && (
        <IonChip className="chip">
          <div className="text">{`Формат:${formFormatString(urlSearch?.format)}`}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.format;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {(urlSearch?.price_from || urlSearch?.price_until) && (
        <IonChip className="chip">
          <div className="text">{`Цена: ${urlSearch?.price_from ? urlSearch?.price_from : "..."}-${
            urlSearch?.price_until ? urlSearch?.price_until : "..."
          } `}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.price_from;
              delete data.price_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {(urlSearch?.seats_from || urlSearch?.seats_until) && (
        <IonChip className="chip">
          <div className="text">{`Мест: ${urlSearch?.seats_from ? urlSearch?.seats_from : "..."}-${
            urlSearch?.seats_until ? urlSearch?.seats_until : "..."
          } `}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.seats_from;
              delete data.seats_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {urlSearch?.genres && (
        <IonChip className="chip">
          <div className="text">{`Жанр:${formGenresString(urlSearch?.genres)}`}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.genres;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {(urlSearch?.public_from || urlSearch?.public_until) && (
        <IonChip className="chip">
          <div className="text">{`Опубликован: ${
            urlSearch?.public_from
              ? `${new Date(+urlSearch?.public_from).toLocaleDateString("ru")}`
              : "..."
          }-${
            urlSearch?.public_until
              ? `${new Date(+urlSearch?.public_until).toLocaleDateString("ru")}`
              : "..."
          }`}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.public_from;
              delete data.public_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {(urlSearch?.rate_from || urlSearch?.rate_until) && (
        <IonChip className="chip">
          <div className="text">{`Рейтинг: ${urlSearch?.rate_from ? urlSearch?.rate_from : "..."}-${
            urlSearch?.rate_until ? urlSearch?.rate_until : "..."
          } `}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.rate_from;
              delete data.rate_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {(urlSearch?.reviews_from || urlSearch?.reviews_until) && (
        <IonChip className="chip">
          <div className="text">{`Отзывы: ${
            urlSearch?.reviews_from ? urlSearch?.reviews_from : "..."
          }-${urlSearch?.reviews_until ? urlSearch?.reviews_until : "..."} `}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.reviews_from;
              delete data.reviews_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {(urlSearch?.favorite_from || urlSearch?.favorite_until) && (
        <IonChip className="chip">
          <div className="text">{`Избранное: ${
            urlSearch?.favorite_from ? urlSearch?.favorite_from : "..."
          }-${urlSearch?.favorite_until ? urlSearch?.favorite_until : "..."} `}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.favorite_from;
              delete data.favorite_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {(urlSearch?.start_from || urlSearch?.start_until) && (
        <IonChip className="chip">
          <div className="text">{`Старт: ${
            urlSearch?.start_from
              ? `${new Date(+urlSearch?.start_from).toLocaleDateString("ru")}`
              : "..."
          }-${
            urlSearch?.start_until
              ? `${new Date(+urlSearch?.start_until).toLocaleDateString("ru")}`
              : "..."
          }`}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.start_from;
              delete data.start_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {(urlSearch?.end_from || urlSearch?.end_until) && (
        <IonChip className="chip">
          <div className="text">{`Завершение: ${
            urlSearch?.end_from
              ? `${new Date(+urlSearch?.end_from).toLocaleDateString("ru")}`
              : "..."
          }-${
            urlSearch?.end_until
              ? `${new Date(+urlSearch?.end_until).toLocaleDateString("ru")}`
              : "..."
          }`}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.end_from;
              delete data.end_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}

      {(urlSearch?.last_login_from || urlSearch?.last_login_until) && (
        <IonChip className="chip">
          <div className="text">{`Последний вход: ${
            urlSearch?.last_login_from
              ? `${new Date(+urlSearch?.last_login_from).toLocaleDateString("ru")}`
              : "..."
          }-${
            urlSearch?.last_login_until
              ? `${new Date(+urlSearch?.last_login_until).toLocaleDateString("ru")}`
              : "..."
          }`}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.last_login_from;
              delete data.last_login_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}

      {(urlSearch?.response_from || urlSearch?.response_until) && (
        <IonChip className="chip">
          <div className="text">{`Дата ответа: ${
            urlSearch?.response_from
              ? `${new Date(+urlSearch?.response_from).toLocaleDateString("ru")}`
              : "..."
          }-${
            urlSearch?.response_until
              ? `${new Date(+urlSearch?.response_until).toLocaleDateString("ru")}`
              : "..."
          }`}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.response_from;
              delete data.response_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {(urlSearch?.paid_from || urlSearch?.paid_until) && (
        <IonChip className="chip">
          <div className="text">{`Оплачено: ${
            urlSearch?.paid_from ? urlSearch?.paid_from : "..."
          }-${urlSearch?.paid_until ? urlSearch?.paid_until : "..."} `}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.paid_from;
              delete data.paid_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {(urlSearch?.comission_from || urlSearch?.comission_until) && (
        <IonChip className="chip">
          <div className="text">{`Комиссия: ${
            urlSearch?.comission_from ? urlSearch?.comission_from : "..."
          }-${urlSearch?.comission_until ? urlSearch?.comission_until : "..."} `}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.comission_from;
              delete data.comission_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {(urlSearch?.fine_from || urlSearch?.fine_until) && (
        <IonChip className="chip">
          <div className="text">{`Штраф: ${urlSearch?.fine_from ? urlSearch?.fine_from : "..."}-${
            urlSearch?.fine_until ? urlSearch?.fine_until : "..."
          } `}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.fine_from;
              delete data.fine_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {(urlSearch?.to_pay_from || urlSearch?.to_pay_until) && (
        <IonChip className="chip">
          <div className="text">{`К выплате: ${
            urlSearch?.to_pay_from ? urlSearch?.to_pay_from : "..."
          }-${urlSearch?.to_pay_until ? urlSearch?.to_pay_until : "..."} `}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.to_pay_from;
              delete data.to_pay_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {(urlSearch?.participants_from || urlSearch?.participants_until) && (
        <IonChip className="chip">
          <div className="text">{`Участников: ${
            urlSearch?.participants_from ? urlSearch?.participants_from : "..."
          }-${urlSearch?.participants_until ? urlSearch?.participants_until : "..."} `}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.participants_from;
              delete data.participants_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {(urlSearch?.price_from_order || urlSearch?.price_until_order) && (
        <IonChip className="chip">
          <div className="text">{`Стоимость тура: ${
            urlSearch?.price_from_order ? urlSearch?.price_from_order : "..."
          }-${urlSearch?.price_until_order ? urlSearch?.price_until_order : "..."} `}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.price_from_order;
              delete data.price_until_order;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {(urlSearch?.options_from || urlSearch?.options_until) && (
        <IonChip className="chip">
          <div className="text">{`Опций: ${
            urlSearch?.options_from ? urlSearch?.options_from : "..."
          }-${urlSearch?.options_until ? urlSearch?.options_until : "..."} `}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.options_from;
              delete data.options_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}

      {(urlSearch?.tours_from || urlSearch?.tours_until) && (
        <IonChip className="chip">
          <div className="text">{`В турах: ${
            urlSearch?.tours_from ? urlSearch?.tours_from : "..."
          }-${urlSearch?.tours_until ? urlSearch?.tours_until : "..."} `}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.tours_from;
              delete data.tours_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}

      {(urlSearch?.photo_from || urlSearch?.photo_until) && (
        <IonChip className="chip">
          <div className="text">{`Фото: ${urlSearch?.photo_from ? urlSearch?.photo_from : "..."}-${
            urlSearch?.photo_until ? urlSearch?.photo_until : "..."
          } `}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.photo_from;
              delete data.photo_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}

      {(urlSearch?.video_from || urlSearch?.video_until) && (
        <IonChip className="chip">
          <div className="text">{`Видео: ${urlSearch?.video_from ? urlSearch?.video_from : "..."}-${
            urlSearch?.video_until ? urlSearch?.video_until : "..."
          } `}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.video_from;
              delete data.video_until;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}

      {urlSearch?.client && (
        <IonChip className="chip">
          <div className="text">{`Клиент:${formClientString(urlSearch?.client)}`}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.client;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {urlSearch?.status && (
        <IonChip className="chip">
          <div className="text">{`Статус:${formStatusString(urlSearch?.status)}`}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.status;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
      {urlSearch?.role && (
        <IonChip className="chip">
          <div className="text">{`Роль: ${urlSearch?.role}`}</div>
          <div
            className="img"
            onClick={() => {
              let data = urlSearch;
              delete data.role;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            }}
          >
            <Icons icon="design-personal-x" />
          </div>
        </IonChip>
      )}
    </>
  );
};
export default ChipsTable;
