import { IonContent } from "@ionic/react";
import "../../less/css/settings.css";
import Icons from "../../ui/Icons";
import Button from "../../ui/Button";
import { useHistory } from "react-router";
import Input from "../../ui/Input";
import Select from "../../ui/Select";
import AlertBox from "../../ui/AlertBox";
import Checkbox from "../../ui/Checkbox";
import Status from "../../ui/Status";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import {
  getPartnerUsers,
  getPartnerUsersByID,
  getRulesOperatorUsers,
  postPartnerUsers,
  postRulesOperatorUsers,
  putPartnerUsers,
  putRulesOperatorUsers,
} from "../../utils/api";
import { setFormOperatorUsers, setOperatorUsers, setUserPermissions } from "../../store/actions";
import { useEffect, useState } from "react";
import { DEFAULT_OPERATOR_USERS } from "../../utils/array";

const EmplyeesInformation = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userPermissions = useSelector((state) => state.userPermissions);
  console.log(userPermissions);
  const token = useSelector((state) => state.token);
  const userID = useSelector((state) => state.userInfo.clientInfo?.user?.id);
  const operator = useSelector((state) => state.operator);
  const formOperatorUsers = useSelector((state) => state.formOperatorUsers);
  const urlSearch = qs.parse(history.location?.search.substr(1));
  const [showcase, setShowcase] = useState(0);
  const [finance, setFinance] = useState(0);
  const [feedback, setFeedback] = useState(0);
  const [settings, setSettings] = useState(0);
  const toBinary = (num) => {
    let str = "";
    while (num >= 1) {
      str += Math.floor(num) % 2;
      num = num / 2;
    }
    if (str.length === 0) {
      str = "00000";
    } else if (str.length === 1) {
      str = String(+str * 10000);
    } else if (str.length === 2) {
      str = String(+str * 1000);
    } else if (str.length === 3) {
      str = String(+str * 100);
    } else if (str.length === 4) {
      str = String(+str * 10);
    } else if (str.length === 5) {
      str = str;
    }
    return str.split("").reverse().join("");
  };
  useAsyncEffect(async () => {
    if (urlSearch.id === undefined) return;
    await getPartnerUsersByID(token, urlSearch.id).then(async (e) => {
      if (!e.data.errors) {
        await getRulesOperatorUsers(token, urlSearch.id).then((q) => {
          let arr = [];
          q.data.data.forEach((el) => {
            let binary = toBinary(el.crud);
            arr.push(binary);
          });
          dispatch(
            setFormOperatorUsers({
              ...e.data.data,
              checkbox: {
                showcase: {
                  tours: {
                    0: arr[0][0] === "1" ? true : false,
                    1: arr[0][1] === "1" ? true : false,
                    2: arr[0][2] === "1" ? true : false,
                    3: arr[0][3] === "1" ? true : false,
                    4: arr[0][4] === "1" ? true : false,
                  },
                  options: {
                    0: arr[1][0] === "1" ? true : false,
                    1: arr[1][1] === "1" ? true : false,
                    2: arr[1][2] === "1" ? true : false,
                    3: arr[1][3] === "1" ? true : false,
                    4: arr[1][4] === "1" ? true : false,
                  },
                  promotions: {
                    0: arr[2][0] === "1" ? true : false,
                    1: arr[2][1] === "1" ? true : false,
                    2: arr[2][2] === "1" ? true : false,
                    3: arr[2][3] === "1" ? true : false,
                    4: arr[2][4] === "1" ? true : false,
                  },
                },
                finance: {
                  orders: {
                    0: arr[3][0] === "1" ? true : false,
                    1: arr[3][1] === "1" ? true : false,
                    2: arr[3][2] === "1" ? true : false,
                    3: arr[3][3] === "1" ? true : false,
                    4: arr[3][4] === "1" ? true : false,
                  },
                  operations: {
                    0: arr[4][0] === "1" ? true : false,
                    1: arr[4][1] === "1" ? true : false,
                    2: arr[4][2] === "1" ? true : false,
                    3: arr[4][3] === "1" ? true : false,
                    4: arr[4][4] === "1" ? true : false,
                  },
                },
                feedback: {
                  reviews: {
                    0: arr[5][0] === "1" ? true : false,
                    1: arr[5][1] === "1" ? true : false,
                    2: arr[5][2] === "1" ? true : false,
                    3: arr[5][3] === "1" ? true : false,
                    4: arr[5][4] === "1" ? true : false,
                  },
                  questions: {
                    0: arr[6][0] === "1" ? true : false,
                    1: arr[6][1] === "1" ? true : false,
                    2: arr[6][2] === "1" ? true : false,
                    3: arr[6][3] === "1" ? true : false,
                    4: arr[6][4] === "1" ? true : false,
                  },
                  // support: {
                  //   0: false,
                  // },
                },
                settings: {
                  company: {
                    0: arr[8][0] === "1" ? true : false,
                    1: arr[8][1] === "1" ? true : false,
                    2: arr[8][2] === "1" ? true : false,
                    3: arr[8][3] === "1" ? true : false,
                    4: arr[8][4] === "1" ? true : false,
                  },
                  operator_users: {
                    0: arr[7][0] === "1" ? true : false,
                    1: arr[7][1] === "1" ? true : false,
                    2: arr[7][2] === "1" ? true : false,
                    3: arr[7][3] === "1" ? true : false,
                    4: arr[7][4] === "1" ? true : false,
                  },
                },
              },
            })
          );
        });
      }
    });
  }, []);

  // console.log(formOperatorUsers);
  const user = {
    first_name: "Сергей",
    last_name: "Гончаров",
    middle_name: "Иванович",
    description: "Менеджер-консультант. Занимается продажами",
    email: "usermail@mail.com",
    role: "Администратор",
    lastLogin: "2023-11-14T14:54:10.000000Z",
    created_at: "2023-11-23T14:54:10.000000Z",
    updated_at: "2023-11-23T14:54:10.000000Z",
    status: "1",
    updated_user: {
      first_name: "Сергей",
      last_name: "Гончаров",
    },
  };

  const timezone = new Date(user?.updated_at).getTimezoneOffset() / 60;
  const lastChangeDate = `${new Date(user?.updated_at).toLocaleString().slice(0, 17)} (UTC${
    +timezone < 0 ? `+${+timezone * -1 > 9 ? "" : "0"}${+timezone * -1}:00` : `-${+timezone * -1}`
  })`;

  const onSave = () => {
    let tours = [];
    let options = [];
    let promotions = [];
    let orders = [];
    let operations = [];
    let reviews = [];
    let questions = [];
    let company = [];
    let operator_users = [];
    for (let i = 0; i < Object.keys(formOperatorUsers.checkbox.showcase.tours).length; i++) {
      let vak = formOperatorUsers.checkbox.showcase.tours[i] === true ? "1" : "0";
      tours.push(vak);
    }
    for (let i = 0; i < Object.keys(formOperatorUsers.checkbox.showcase.options).length; i++) {
      let vak = formOperatorUsers.checkbox.showcase.options[i] === true ? "1" : "0";
      options.push(vak);
    }
    for (let i = 0; i < Object.keys(formOperatorUsers.checkbox.showcase.promotions).length; i++) {
      let vak = formOperatorUsers.checkbox.showcase.promotions[i] === true ? "1" : "0";
      promotions.push(vak);
    }
    for (let i = 0; i < Object.keys(formOperatorUsers.checkbox.finance.orders).length; i++) {
      let vak = formOperatorUsers.checkbox.finance.orders[i] === true ? "1" : "0";
      orders.push(vak);
    }
    for (let i = 0; i < Object.keys(formOperatorUsers.checkbox.finance.operations).length; i++) {
      let vak = formOperatorUsers.checkbox.finance.operations[i] === true ? "1" : "0";
      operations.push(vak);
    }
    for (let i = 0; i < Object.keys(formOperatorUsers.checkbox.feedback.reviews).length; i++) {
      let vak = formOperatorUsers.checkbox.feedback.reviews[i] === true ? "1" : "0";
      reviews.push(vak);
    }
    for (let i = 0; i < Object.keys(formOperatorUsers.checkbox.feedback.reviews).length; i++) {
      let vak = formOperatorUsers.checkbox.feedback.questions[i] === true ? "1" : "0";
      questions.push(vak);
    }
    for (let i = 0; i < Object.keys(formOperatorUsers.checkbox.settings.company).length; i++) {
      let vak = formOperatorUsers.checkbox.settings.company[i] === true ? "1" : "0";
      company.push(vak);
    }
    for (
      let i = 0;
      i < Object.keys(formOperatorUsers.checkbox.settings.operator_users).length;
      i++
    ) {
      let vak = formOperatorUsers.checkbox.settings.operator_users[i] === true ? "1" : "0";
      operator_users.push(vak);
    }
    tours = parseInt(tours.join(""), 2);
    options = parseInt(options.join(""), 2);
    promotions = parseInt(promotions.join(""), 2);
    orders = parseInt(orders.join(""), 2);
    operations = parseInt(operations.join(""), 2);
    reviews = parseInt(reviews.join(""), 2);
    questions = parseInt(questions.join(""), 2);
    company = parseInt(company.join(""), 2);
    operator_users = parseInt(operator_users.join(""), 2);
    if (urlSearch.id) {
      let forSend = {
        login: formOperatorUsers.user?.login,
        comment: formOperatorUsers.comment,
        first_name: formOperatorUsers.user?.first_name,
        last_name: formOperatorUsers.user?.last_name,
        // operator_id: operator.id,
        email: formOperatorUsers?.user?.email,
      };
      putPartnerUsers(token, urlSearch.id, forSend).then(async (e) => {
        if (!e.data.errors) {
          var rules = [
            { crud: tours, rule_id: 101 },
            { crud: options, rule_id: 102 },
            { crud: promotions, rule_id: 103 },
            { crud: orders, rule_id: 104 },
            { crud: operations, rule_id: 105 },
            { crud: reviews, rule_id: 106 },
            { crud: questions, rule_id: 107 },
            { crud: company, rule_id: 109 },
            { crud: operator_users, rule_id: 110 },
          ];
          for (let i = 0; i < rules?.length; i++) {
            await putRulesOperatorUsers(
              token,
              urlSearch.id,
              {
                // rule_id: rules[i].rule_id,
                crud: rules[i].crud,
              },
              rules[i].rule_id
            );
          }
          await getPartnerUsers(token).then((e) => {
            dispatch(setOperatorUsers(e.data.data));
          });
          await getRulesOperatorUsers(token, urlSearch.id).then((q) => {
            let arr = [];
            e.data.data?.forEach((el) => {
              let binary = toBinary(el.crud);
              arr.push(binary);
            });
            dispatch(
              setUserPermissions({
                showcase: {
                  tours: {
                    0: arr[0][0] === "1" ? true : false, // Просмотр / Экспорт
                    1: arr[0][1] === "1" ? true : false, // Создание
                    2: arr[0][2] === "1" ? true : false, // Редактирование
                    3: arr[0][3] === "1" ? true : false, // Удаление
                    4: arr[0][4] === "1" ? true : false, // Изменение статуса
                  },
                  options: {
                    0: arr[1][0] === "1" ? true : false, // Просмотр / Экспорт
                    1: arr[1][1] === "1" ? true : false, // Создание
                    2: arr[1][2] === "1" ? true : false, // Редактирование
                    3: arr[1][3] === "1" ? true : false, // Удаление
                    4: arr[1][4] === "1" ? true : false, // Деактивировать
                  },
                  promotions: {
                    0: arr[2][0] === "1" ? true : false, // Просмотр / Экспорт
                    1: arr[2][1] === "1" ? true : false, // Создание
                    2: arr[2][2] === "1" ? true : false, // Редактирование
                    3: arr[2][3] === "1" ? true : false, // Удаление
                    4: arr[2][4] === "1" ? true : false, // Активировать акцию платформы
                  },
                },
                finance: {
                  orders: {
                    0: arr[3][0] === "1" ? true : false, // Просмотр / Экспорт
                    1: arr[3][1] === "1" ? true : false,
                    2: arr[3][2] === "1" ? true : false,
                    3: arr[3][3] === "1" ? true : false,
                    4: arr[3][4] === "1" ? true : false,
                  },
                  operations: {
                    0: arr[4][0] === "1" ? true : false, // Просмотр / Экспорт
                    1: arr[4][1] === "1" ? true : false,
                    2: arr[4][2] === "1" ? true : false,
                    3: arr[4][3] === "1" ? true : false,
                    4: arr[4][4] === "1" ? true : false,
                  },
                },
                feedback: {
                  reviews: {
                    0: arr[5][0] === "1" ? true : false, // Просмотр / Экспорт
                    1: arr[5][1] === "1" ? true : false, // Модерировать
                    2: arr[5][2] === "1" ? true : false, // Пожаловаться
                    3: arr[5][3] === "1" ? true : false,
                    4: arr[5][4] === "1" ? true : false,
                  },
                  questions: {
                    0: arr[6][0] === "1" ? true : false, // Просмотр / Экспор
                    1: arr[6][1] === "1" ? true : false, // Модерировать
                    2: arr[6][2] === "1" ? true : false, // Пожаловаться
                    3: arr[6][3] === "1" ? true : false,
                    4: arr[6][4] === "1" ? true : false,
                  },
                  // support: {
                  //   0: false,
                  // },
                },
                settings: {
                  company: {
                    0: arr[8][0] === "1" ? true : false, // Просмотр
                    1: arr[8][1] === "1" ? true : false, // Редактировать
                    2: arr[8][2] === "1" ? true : false,
                    3: arr[8][3] === "1" ? true : false,
                    4: arr[8][4] === "1" ? true : false,
                  },
                  operator_users: {
                    0: arr[7][0] === "1" ? true : false, // Просмотр
                    1: arr[7][1] === "1" ? true : false, // Создание
                    2: arr[7][2] === "1" ? true : false, // Редатирование
                    3: arr[7][3] === "1" ? true : false, // Удаление
                    4: arr[7][4] === "1" ? true : false,
                  },
                },
              })
            );
          });
          history.push("/partner/settings/employees?page=1");
          dispatch(setFormOperatorUsers(DEFAULT_OPERATOR_USERS));
        }
      });
    } else {
      let forSend = {
        login: formOperatorUsers.email,
        comment: formOperatorUsers.comment,
        password: "1111", // TODO
        first_name: "user",
        last_name: "user",
        operator_id: operator.id,
        email: formOperatorUsers.email,
      };

      postPartnerUsers(token, forSend).then(async (e) => {
        if (!e.data.errors) {
          let rules = [
            { crud: tours, rule_id: 101 },
            { crud: options, rule_id: 102 },
            { crud: promotions, rule_id: 103 },
            { crud: orders, rule_id: 104 },
            { crud: operations, rule_id: 105 },
            { crud: reviews, rule_id: 106 },
            { crud: questions, rule_id: 107 },
            { crud: operator_users, rule_id: 110 },
            { crud: company, rule_id: 109 },
          ];
          for (let i = 0; i < rules?.length; i++) {
            await postRulesOperatorUsers(token, e.data.operator_user.user_id, {
              rule_id: rules[i].rule_id,
              crud: rules[i].crud,
            });
          }
          await getPartnerUsers(token).then((ej) => {
            dispatch(setOperatorUsers(ej.data.data));
          });

          history.push("/partner/settings/employees?page=1");
          dispatch(setFormOperatorUsers(DEFAULT_OPERATOR_USERS));
        }
      });
    }
  };

  useEffect(() => {
    let valueS = 0;
    let valueF = 0;
    let valueR = 0;
    let valueO = 0;

    for (let i = 0; i < Object.keys(formOperatorUsers.checkbox.showcase.tours).length; i++) {
      valueS = formOperatorUsers.checkbox.showcase.tours[i] ? (valueS += 1) : (valueS += 0);
    }
    for (let i = 0; i < Object.keys(formOperatorUsers.checkbox.showcase.options).length; i++) {
      valueS = formOperatorUsers.checkbox.showcase.options[i] ? (valueS += 1) : (valueS += 0);
    }
    for (let i = 0; i < Object.keys(formOperatorUsers.checkbox.showcase.promotions).length; i++) {
      valueS = formOperatorUsers.checkbox.showcase.promotions[i] ? (valueS += 1) : (valueS += 0);
    }
    for (let i = 0; i < Object.keys(formOperatorUsers.checkbox.finance.orders).length; i++) {
      valueF = formOperatorUsers.checkbox.finance.orders[i] ? (valueF += 1) : (valueF += 0);
    }
    for (let i = 0; i < Object.keys(formOperatorUsers.checkbox.finance.operations).length; i++) {
      valueF = formOperatorUsers.checkbox.finance.operations[i] ? (valueF += 1) : (valueF += 0);
    }
    for (let i = 0; i < Object.keys(formOperatorUsers.checkbox.feedback.reviews).length; i++) {
      valueR = formOperatorUsers.checkbox.feedback.reviews[i] ? (valueR += 1) : (valueR += 0);
    }
    for (let i = 0; i < Object.keys(formOperatorUsers.checkbox.feedback.questions).length; i++) {
      valueR = formOperatorUsers.checkbox.feedback.questions[i] ? (valueR += 1) : (valueR += 0);
    }
    // for (let i = 0; i < Object.keys(formOperatorUsers.checkbox.feedback.support).length; i++) {
    //   valueR = formOperatorUsers.checkbox.feedback.support[i] ? (valueR += 1) : (valueR += 0);
    // }
    for (let i = 0; i < Object.keys(formOperatorUsers.checkbox.settings.company).length; i++) {
      valueO = formOperatorUsers.checkbox.settings.company[i] ? (valueO += 1) : (valueO += 0);
    }
    for (
      let i = 0;
      i < Object.keys(formOperatorUsers.checkbox.settings.operator_users).length;
      i++
    ) {
      valueO = formOperatorUsers.checkbox.settings.operator_users[i]
        ? (valueO += 1)
        : (valueO += 0);
    }
    setShowcase(valueS);
    setFinance(valueF);
    setFeedback(valueR);
    setSettings(valueO);
    // showcase
    if (valueS === 15) {
      let showcaseMarker = document.querySelector("#showcase");
      let showcaseMarkers = document.querySelectorAll("#showcase-el");
      showcaseMarker.checked = true;
      showcaseMarkers.forEach((cb) => (cb.checked = true));
    }
    if (valueS < 15) {
      let showcaseMarker = document.querySelector("#showcase");
      showcaseMarker.checked = false;
    }
    if (valueS === 0) {
      let showcaseMarkers = document.querySelectorAll("#showcase-el");
      showcaseMarkers.forEach((cb) => (cb.checked = false));
    }
    // finance
    if (valueF === 2) {
      let financeMarker = document.querySelector("#finance");
      let financeMarkers = document.querySelectorAll("#finance-el");
      financeMarker.checked = true;
      financeMarkers.forEach((cb) => (cb.checked = true));
    }
    if (valueF < 2) {
      let financeMarker = document.querySelector("#finance");
      financeMarker.checked = false;
    }
    if (valueF === 0) {
      let financeMarkers = document.querySelectorAll("#finance-el");
      financeMarkers.forEach((cb) => (cb.checked = false));
    }
    // feedback
    if (valueR === 6) {
      let feedbackMarker = document.querySelector("#feedback");
      let feedbackMarkers = document.querySelectorAll("#feedback-el");
      feedbackMarker.checked = true;
      feedbackMarkers.forEach((cb) => (cb.checked = true));
    }
    if (valueR < 6) {
      let feedbackMarker = document.querySelector("#feedback");
      feedbackMarker.checked = false;
    }
    if (valueR === 0) {
      let feedbackMarkers = document.querySelectorAll("#feedback-el");
      feedbackMarkers.forEach((cb) => (cb.checked = false));
    }
    // settings
    if (valueO === 6) {
      let settingsMarker = document.querySelector("#settings");
      let settingsMarkers = document.querySelectorAll("#settings-el");
      settingsMarker.checked = true;
      settingsMarkers.forEach((cb) => (cb.checked = true));
    }
    if (valueO < 6) {
      let settingsMarker = document.querySelector("#settings");
      settingsMarker.checked = false;
    }
    if (valueO === 0) {
      let settingsMarkers = document.querySelectorAll("#settings-el");
      settingsMarkers.forEach((cb) => (cb.checked = false));
    }
  }, [formOperatorUsers]);
  return (
    <IonContent className="page">
      <div className="employees-info-oper-user-page">
        {urlSearch.id ? (
          <div className="upper-line">
            <div className="tbr">
              <div className="cont-l">
                <Button
                  typebtn="outline"
                  className="btn-back small btn-back"
                  onClick={() => {
                    history.push("/partner/settings/employees?page=1");
                    dispatch(setFormOperatorUsers(DEFAULT_OPERATOR_USERS));
                  }}
                >
                  <Icons icon="design-personal-chevron-left" className="icon-outline" />
                </Button>
                <div className="title">
                  {formOperatorUsers?.user
                    ? `${formOperatorUsers?.user?.first_name} ${formOperatorUsers?.user?.last_name?.[0]}.`
                    : ""}
                </div>
              </div>
              <Button
                disabled={formOperatorUsers?.status === 3}
                type="sumbit"
                form="operator-user-inf"
                className="btn-save small"
                typebtn="bright"
                onClick={() => onSave()}
              >
                Сохранить
              </Button>
            </div>
            <div className="status-cont">
              <Status
                color={`${
                  formOperatorUsers?.status === 1
                    ? "green"
                    : (formOperatorUsers?.status === 2 || formOperatorUsers?.status === 3) && "gray"
                }`}
              >
                {formOperatorUsers?.status_name}
              </Status>
            </div>
            {/* <div className="last-change">{`Последнее изменение ${lastChangeDate}, ${
              user?.updated_user?.first_name
            } ${user?.updated_user?.last_name ? user?.updated_user?.last_name?.[0] : ""}.
          `}</div> */}
          </div>
        ) : (
          <div className="upper-line-n">
            <div className="cont-l">
              <Button
                typebtn="outline"
                className="btn-back small btn-back"
                onClick={() => {
                  history.push("/partner/settings/employees?page=1");
                }}
              >
                <Icons icon="design-personal-chevron-left" className="icon-outline" />
              </Button>
              <div className="title">Новый сотрудник</div>
            </div>
            <Button
              type="sumbit"
              form="operator-user-inf"
              className="btn-save small"
              typebtn="bright"
              onClick={() => onSave()}
            >
              Сохранить
            </Button>
          </div>
        )}

        <div
          className="body-create"
          id="operator-user-inf"
          // onSubmit={(e) => {
          //   e.preventDefault();
          //   let info = {};
          //   let inputs = e.target.querySelectorAll("input");
          //   let textareas = e.target.querySelectorAll("textarea");
          //   console.log(inputs);
          //   inputs.forEach((input) => (info[input.name] = input.value));
          //   textareas.forEach((textareas) => (info[textareas.name] = textareas.value));
          //   console.log(info);
          // }}
        >
          <div className="first-line">
            <div className="left">
              <div className="line-input">
                <div className="text">Email сотрудника</div>
                <Input
                  disabled={urlSearch.id && formOperatorUsers.user?.email}
                  value={formOperatorUsers.user?.email}
                  name="email"
                  className={`input-create-oper-user large ${
                    urlSearch.id && formOperatorUsers.user?.email ? "disabled" : ""
                  }`}
                  type="email"
                  placeholder="email..."
                  noImage={true}
                  onChange={(e) =>
                    dispatch(setFormOperatorUsers({ ...formOperatorUsers, email: e }))
                  }
                />
              </div>
              <div className="line-input-textarea">
                <div className="text">Примечание</div>
                <Input
                  value={formOperatorUsers?.comment}
                  type="textarea"
                  name="comment"
                  className="input-create-oper-user area"
                  placeholder="примечание..."
                  noImage={true}
                  onChange={(e) =>
                    dispatch(setFormOperatorUsers({ ...formOperatorUsers, comment: e }))
                  }
                />
              </div>
              <div className="line-input">
                <div className="text">Права доступа</div>
                <Select
                  typesel="large"
                  className="large input-create-oper-user"
                  onChange={(e) => console.log(e.target.value)}
                  placeholder="выберите шаблон..."
                />
              </div>
            </div>
          </div>
          <div className="cont-1">
            <div className="title">
              <Checkbox
                // checked={showcase === 19}
                id="showcase"
                position="center"
                name="showcase"
                onChange={(e) =>
                  e.target.checked
                    ? dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              tours: {
                                0: true,
                                1: true,
                                2: true,
                                3: true,
                                4: true,
                                // 5: true,
                                // 6: true,
                              },
                              options: {
                                0: true,
                                1: true,
                                2: true,
                                3: true,
                                4: true,
                                // 5: true,
                              },
                              promotions: {
                                0: true,
                                1: true,
                                2: true,
                                3: true,
                                4: true,
                                // 5: true,
                              },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...DEFAULT_OPERATOR_USERS.checkbox.showcase,
                            },
                          },
                        })
                      )
                }
              >
                <div className="cont-text">
                  <div className="text">Витрина</div>
                  <div className="subtext">{`${showcase} / 15`}</div>
                </div>
              </Checkbox>
            </div>
            <div className="cont-items">
              <div className="item">
                <div className="title-item">Туры</div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.showcase.tours[0] === true}
                    id="showcase-el"
                    position="center"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              tours: {
                                ...formOperatorUsers.checkbox.showcase.tours,
                                0: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item" name="view_tour">
                      Просмотр / Экспорт
                    </div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.showcase.tours[1] === true}
                    id="showcase-el"
                    position="center"
                    name="create_tour"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              tours: {
                                ...formOperatorUsers.checkbox.showcase.tours,
                                1: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Создание</div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.showcase.tours[2] === true}
                    id="showcase-el"
                    position="center"
                    name="edit_tour"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              tours: {
                                ...formOperatorUsers.checkbox.showcase.tours,
                                2: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Редактирование</div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.showcase.tours[3] === true}
                    id="showcase-el"
                    position="center"
                    name="delete_tour"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              tours: {
                                ...formOperatorUsers.checkbox.showcase.tours,
                                3: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Удаление</div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.showcase.tours[4] === true}
                    id="showcase-el"
                    position="center"
                    name="submit_tour"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              tours: {
                                ...formOperatorUsers.checkbox.showcase.tours,
                                4: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Изменение статуса</div>
                  </Checkbox>
                </div>
                {/* <div className="line-item">
                  <Checkbox
                    id="showcase-el"
                    position="center"
                    name="remove_tour"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              tours: {
                                ...formOperatorUsers.checkbox.showcase.tours,
                                5: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Снять с публикации</div>
                  </Checkbox>
                </div> */}
                {/* <div className="line-item">
                  <Checkbox
                    id="showcase-el"
                    position="center"
                    name="export_tour"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              tours: {
                                ...formOperatorUsers.checkbox.showcase.tours,
                                6: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Экспорт</div>
                  </Checkbox>
                </div> */}
              </div>
              <div className="item">
                <div className="title-item">Опции</div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.showcase.options[0] === true}
                    id="showcase-el"
                    position="center"
                    name="view_options"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              options: {
                                ...formOperatorUsers.checkbox.showcase.options,
                                0: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Просмотр / Экспорт</div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.showcase.options[1] === true}
                    id="showcase-el"
                    position="center"
                    name="create_options"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              options: {
                                ...formOperatorUsers.checkbox.showcase.options,
                                1: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Создание</div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.showcase.options[2] === true}
                    id="showcase-el"
                    position="center"
                    name="edit_options"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              options: {
                                ...formOperatorUsers.checkbox.showcase.options,
                                2: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Редактирование</div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.showcase.options[3] === true}
                    id="showcase-el"
                    position="center"
                    name="delete_options"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              options: {
                                ...formOperatorUsers.checkbox.showcase.options,
                                3: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Удаление</div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.showcase.options[4] === true}
                    id="showcase-el"
                    position="center"
                    name="deactivate_options"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              options: {
                                ...formOperatorUsers.checkbox.showcase.options,
                                4: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Деактивировать</div>
                  </Checkbox>
                </div>
                {/* <div className="line-item">
                  <Checkbox
                    id="showcase-el"
                    position="center"
                    name="export_options"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              options: {
                                ...formOperatorUsers.checkbox.showcase.options,
                                5: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Экспорт</div>
                  </Checkbox>
                </div> */}
              </div>
              <div className="item">
                <div className="title-item">Акции и скидки</div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.showcase.promotions[0] === true}
                    id="showcase-el"
                    position="center"
                    name="view_event"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              promotions: {
                                ...formOperatorUsers.checkbox.showcase.promotions,
                                0: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Просмотр / Экспорт</div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.showcase.promotions[1] === true}
                    id="showcase-el"
                    position="center"
                    name="create_event"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              promotions: {
                                ...formOperatorUsers.checkbox.showcase.promotions,
                                1: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Создание</div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.showcase.promotions[2] === true}
                    id="showcase-el"
                    position="center"
                    name="edit_event"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              promotions: {
                                ...formOperatorUsers.checkbox.showcase.promotions,
                                2: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Редактирование</div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.showcase.promotions[3] === true}
                    id="showcase-el"
                    position="center"
                    name="delete_event"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              promotions: {
                                ...formOperatorUsers.checkbox.showcase.promotions,
                                3: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Удаление</div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.showcase.promotions[4] === true}
                    id="showcase-el"
                    position="center"
                    name="active_event"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              promotions: {
                                ...formOperatorUsers.checkbox.showcase.promotions,
                                4: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Активировать акцию платформы</div>
                  </Checkbox>
                </div>
                {/* <div className="line-item">
                  <Checkbox
                    id="showcase-el"
                    position="center"
                    name="export_event"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            showcase: {
                              ...formOperatorUsers.checkbox.showcase,
                              promotions: {
                                ...formOperatorUsers.checkbox.showcase.promotions,
                                5: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Экспорт</div>
                  </Checkbox>
                </div> */}
              </div>
            </div>
          </div>
          <div className="cont-1">
            <div className="title">
              <Checkbox
                position="center"
                name="finance"
                id="finance"
                onChange={(e) =>
                  e.target.checked
                    ? dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            finance: {
                              orders: {
                                0: true,
                                1: false,
                                2: false,
                                3: false,
                                4: false,
                              },
                              operations: {
                                0: true,
                                1: false,
                                2: false,
                                3: false,
                                4: false,
                              },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            finance: {
                              ...DEFAULT_OPERATOR_USERS.checkbox.finance,
                            },
                          },
                        })
                      )
                }
              >
                <div className="cont-text">
                  <div className="text">Финансы</div>
                  <div className="subtext">{`${finance} / 2`}</div>
                </div>
              </Checkbox>
            </div>
            <div className="cont-items">
              <div className="item">
                <div className="title-item">Заказы</div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.finance.orders[0] === true}
                    id="finance-el"
                    position="center"
                    name="view_orders"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            finance: {
                              ...formOperatorUsers.checkbox.finance,
                              orders: {
                                ...formOperatorUsers.checkbox.finance.orders,
                                0: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Просмотр / Экспорт</div>
                  </Checkbox>
                </div>
                {/* <div className="line-item">
                  <Checkbox
                    id="finance-el"
                    position="center"
                    name="export_orders"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            finance: {
                              ...formOperatorUsers.checkbox.finance,
                              orders: {
                                ...formOperatorUsers.checkbox.finance.orders,
                                1: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Экспорт</div>
                  </Checkbox>
                </div> */}
              </div>
              <div className="item">
                <div className="title-item">История выплат</div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.finance.operations[0] === true}
                    id="finance-el"
                    position="center"
                    name="view_history"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            finance: {
                              ...formOperatorUsers.checkbox.finance,
                              operations: {
                                ...formOperatorUsers.checkbox.finance.operations,
                                0: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Просмотр / Экспорт</div>
                  </Checkbox>
                </div>
                {/* <div className="line-item">
                  <Checkbox
                    id="finance-el"
                    position="center"
                    name="export_history"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            finance: {
                              ...formOperatorUsers.checkbox.finance,
                              operations: {
                                ...formOperatorUsers.checkbox.finance.operations,
                                1: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Экспорт</div>
                  </Checkbox>
                </div> */}
              </div>
            </div>
          </div>
          <div className="cont-1">
            <div className="title">
              <Checkbox
                position="center"
                name="feedback"
                id="feedback"
                onChange={(e) =>
                  e.target.checked
                    ? dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            feedback: {
                              reviews: {
                                0: true,
                                1: true,
                                2: true,
                                3: false,
                                4: false,
                              },
                              questions: {
                                0: true,
                                1: true,
                                2: true,
                                3: false,
                                4: false,
                              },
                              // support: {
                              //   0: true,
                              // },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            feedback: {
                              ...DEFAULT_OPERATOR_USERS.checkbox.feedback,
                            },
                          },
                        })
                      )
                }
              >
                <div className="cont-text">
                  <div className="text">Обратная связь</div>
                  <div className="subtext">{`${feedback} / 6`}</div>
                </div>
              </Checkbox>
            </div>
            <div className="cont-items">
              <div className="item">
                <div className="title-item">Отзывы</div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.feedback.reviews[0] === true}
                    id="feedback-el"
                    position="center"
                    name="view_reviews"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            feedback: {
                              ...formOperatorUsers.checkbox.feedback,
                              reviews: {
                                ...formOperatorUsers.checkbox.feedback.reviews,
                                0: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Просмотр / Экспорт</div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.feedback.reviews[1] === true}
                    id="feedback-el"
                    position="center"
                    name="moderate_reviews"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            feedback: {
                              ...formOperatorUsers.checkbox.feedback,
                              reviews: {
                                ...formOperatorUsers.checkbox.feedback.reviews,
                                1: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Модерировать</div>
                  </Checkbox>
                </div>
                {/* <div className="line-item">
                  <Checkbox
                    id="feedback-el"
                    position="center"
                    name="answer_reviews"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            feedback: {
                              ...formOperatorUsers.checkbox.feedback,
                              reviews: {
                                ...formOperatorUsers.checkbox.feedback.reviews,
                                2: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Ответить</div>
                  </Checkbox>
                </div> */}
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.feedback.reviews[2] === true}
                    id="feedback-el"
                    position="center"
                    name="complain_reviews"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            feedback: {
                              ...formOperatorUsers.checkbox.feedback,
                              reviews: {
                                ...formOperatorUsers.checkbox.feedback.reviews,
                                2: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Пожаловаться</div>
                  </Checkbox>
                </div>
                {/* <div className="line-item">
                  <Checkbox
                    id="feedback-el"
                    position="center"
                    name="export_reviews"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            feedback: {
                              ...formOperatorUsers.checkbox.feedback,
                              reviews: {
                                ...formOperatorUsers.checkbox.feedback.reviews,
                                4: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Экспорт</div>
                  </Checkbox>
                </div> */}
              </div>
              <div className="item">
                <div className="title-item">Вопросы</div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.feedback.questions[0] === true}
                    id="feedback-el"
                    position="center"
                    name="view_questions"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            feedback: {
                              ...formOperatorUsers.checkbox.feedback,
                              questions: {
                                ...formOperatorUsers.checkbox.feedback.questions,
                                0: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Просмотр / Экспорт</div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.feedback.questions[1] === true}
                    id="feedback-el"
                    position="center"
                    name="moderate_questions"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            feedback: {
                              ...formOperatorUsers.checkbox.feedback,
                              questions: {
                                ...formOperatorUsers.checkbox.feedback.questions,
                                1: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Модерировать</div>
                  </Checkbox>
                </div>
                {/* <div className="line-item">
                  <Checkbox
                    id="feedback-el"
                    position="center"
                    name="answer_questions"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            feedback: {
                              ...formOperatorUsers.checkbox.feedback,
                              questions: {
                                ...formOperatorUsers.checkbox.feedback.questions,
                                2: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Ответить</div>
                  </Checkbox>
                </div> */}
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.feedback.questions[2] === true}
                    id="feedback-el"
                    position="center"
                    name="complain_questions"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            feedback: {
                              ...formOperatorUsers.checkbox.feedback,
                              questions: {
                                ...formOperatorUsers.checkbox.feedback.questions,
                                2: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Пожаловаться</div>
                  </Checkbox>
                </div>
                {/* <div className="line-item">
                  <Checkbox
                    id="feedback-el"
                    position="center"
                    name="export_questions"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            feedback: {
                              ...formOperatorUsers.checkbox.feedback,
                              questions: {
                                ...formOperatorUsers.checkbox.feedback.questions,
                                4: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Экспорт</div>
                  </Checkbox>
                </div> */}
              </div>
              {/* <div className="item">
                <div className="title-item">Поддержка</div>
                <div className="line-item">
                  <Checkbox
                    id="feedback-el"
                    position="center"
                    name="send_request_support"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            feedback: {
                              ...formOperatorUsers.checkbox.feedback,
                              support: {
                                ...formOperatorUsers.checkbox.feedback.support,
                                0: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Отправить запрос</div>
                  </Checkbox>
                </div>
              </div> */}
            </div>
          </div>
          <div className="cont-1">
            <div className="title">
              <Checkbox
                position="center"
                name="settings"
                id="settings"
                onChange={(e) =>
                  e.target.checked
                    ? dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            settings: {
                              company: {
                                0: true,
                                1: true,
                                2: false,
                                3: false,
                                4: false,
                              },
                              operator_users: {
                                0: true,
                                1: true,
                                2: true,
                                3: true,
                                4: false,
                              },
                            },
                          },
                        })
                      )
                    : dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            settings: {
                              ...DEFAULT_OPERATOR_USERS.checkbox.settings,
                            },
                          },
                        })
                      )
                }
              >
                <div className="cont-text">
                  <div className="text">Настройки</div>
                  <div className="subtext">{`${settings} / 6`}</div>
                </div>
              </Checkbox>
            </div>
            <div className="cont-items">
              <div className="item">
                <div className="title-item">Данные компании</div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.settings.company[0] === true}
                    id="settings-el"
                    position="center"
                    name="view_company"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            settings: {
                              ...formOperatorUsers.checkbox.settings,
                              company: {
                                ...formOperatorUsers.checkbox.settings.company,
                                0: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Просмотр</div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.settings.company[1] === true}
                    id="settings-el"
                    position="center"
                    name="edit_company"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            settings: {
                              ...formOperatorUsers.checkbox.settings,
                              company: {
                                ...formOperatorUsers.checkbox.settings.company,
                                1: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item">Редактирование</div>
                  </Checkbox>
                </div>
              </div>
              <div className="item">
                <div className="title-item">Сотрудники</div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.settings.operator_users[0] === true}
                    id="settings-el"
                    position="center"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            settings: {
                              ...formOperatorUsers.checkbox.settings,
                              operator_users: {
                                ...formOperatorUsers.checkbox.settings.operator_users,
                                0: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item" name="view_employee">
                      Просмотр
                    </div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.settings.operator_users[1] === true}
                    id="settings-el"
                    position="center"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            settings: {
                              ...formOperatorUsers.checkbox.settings,
                              operator_users: {
                                ...formOperatorUsers.checkbox.settings.operator_users,
                                1: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item" name="create_employee">
                      Создание
                    </div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.settings.operator_users[2] === true}
                    id="settings-el"
                    position="center"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            settings: {
                              ...formOperatorUsers.checkbox.settings,
                              operator_users: {
                                ...formOperatorUsers.checkbox.settings.operator_users,
                                2: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item" name="edit_employee">
                      Редактирование
                    </div>
                  </Checkbox>
                </div>
                <div className="line-item">
                  <Checkbox
                    checked={formOperatorUsers.checkbox.settings.operator_users[3] === true}
                    id="settings-el"
                    position="center"
                    onChange={(e) =>
                      dispatch(
                        setFormOperatorUsers({
                          ...formOperatorUsers,
                          checkbox: {
                            ...formOperatorUsers.checkbox,
                            settings: {
                              ...formOperatorUsers.checkbox.settings,
                              operator_users: {
                                ...formOperatorUsers.checkbox.settings.operator_users,
                                3: e.target.checked,
                              },
                            },
                          },
                        })
                      )
                    }
                  >
                    <div className="text-item" name="delete_employee">
                      Удаление
                    </div>
                  </Checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </IonContent>
  );
};

export default EmplyeesInformation;
