import { IonCheckbox, IonModal } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Close from "../../ui/Close";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import {
  getInitialDataClient,
  postLoginClient,
  postRegistrationClient,
  postSMSCodeClient,
} from "../../utils/api";
import {
  setElMenu,
  setFormRegister,
  setModalLogin,
  setModalLoginPartner,
  setModalRecovery,
  setToken,
  setUserInfo,
} from "../../store/actions";
import background from "../../image/modal-background-decor.svg";
import iconTop from "../../image/icons/login-2.svg";
import iconTopReg from "../../image/icons/user-plus.svg";
import pinIcon from "../../image/icons/password-mobile-phone.svg";
import OtpInput from "react-otp-input";

import { useRef, useState } from "react";
import Checkbox from "../../ui/Checkbox";
import InputCode from "../../ui/InputCode";
import TimerCode from "../../ui/TimerCode";
import Toast from "../../ui/Toast";

const LoginModal = (props) => {
  const modalLogin = useSelector((state) => state.modalLogin);
  const formRegister = useSelector((state) => state.formRegister);
  const ALL_SECOND = 59 * 1000;
  const [time, setTime] = useState(ALL_SECOND);
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });

  const [joinPhone, setJoinPhone] = useState(false);
  const [pass, setPass] = useState();
  const [error, setError] = useState(false);
  const [valTime, setValTime] = useState("");
  const [register, setRegister] = useState(false);
  const [pin, setPin] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const [otp, setOtp] = useState("");
  const [activeTimer, setActiveTimer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [send, setSend] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  const obj = useRef({});
  const checkPassword = (e) => {
    if (pass !== e && pass && e) {
      setError("repeat");
    } else {
      setError(false);
    }
  };
  const debaunce = (fn, timeoutMs) => {
    return function perform(...args) {
      const previousCall = obj.current.lastCall;
      obj.current.lastCall = Date.now();
      if (previousCall && obj.current.lastCall - previousCall <= timeoutMs) {
        clearTimeout(obj.current.lastCallTimer);
      }
      obj.current.lastCallTimer = setTimeout(() => fn(...args), timeoutMs);
    };
  };

  const debaunceRequest = debaunce(checkPassword, 500);

  const handleInput = (e) => {
    setValTime(e);
    debaunceRequest(e);
  };
  return (
    <>
      <IonModal
        className={`modal-login ${joinPhone ? "phone" : ""} ${register ? "register" : ""} ${
          pin ? "pin" : ""
        }`}
        isOpen={modalLogin.open}
        onWillDismiss={() => {
          setRegister(false);
          setPin(false);
          setLoading(false);
          setJoinPhone(false);
          setOtp(null);
          setErrorCode(false);
          dispatch(setModalLogin({ open: false }));
        }}
      >
        {register && (
          <div className="body-modal-login">
            <img className="image-back-login" src={background} />
            <Close
              onClick={() => {
                setRegister(false);
                setPin(false);
                setJoinPhone(false);
                setOtp(null);
                setLoading(false);
                setErrorCode(false);
                dispatch(setModalLogin({ open: false }));
              }}
            />
            <div className="title">
              <div className="cont">
                <img src={iconTopReg} />
                Регистрация
              </div>
            </div>
            <form
              className="form-login"
              onSubmit={async (e) => {
                e.preventDefault();
                let info = {};
                let inputs = e.target.querySelectorAll("input");
                inputs.forEach((input) => (info[input.name] = input.value));
                let forSend = {
                  email: info.email,
                  login: info.email,
                  phone: info.phone.replace(/\D/g, ""),
                  password: info.password,
                };
                if (
                  info.email &&
                  info.phone &&
                  info.password &&
                  info.password === info.repeat_password
                ) {
                  postRegistrationClient(forSend)
                    .then((e) => {
                      if (!e.data.errors) {
                        setSend(forSend);
                        setRegister(false);
                        setPin(true);
                      } else {
                        setOpenToast(true);
                        setMessageToast({
                          text:
                            typeof e.data.errors === "string"
                              ? e.data.errors
                              : "Ошибка регистрации",
                          type: "error",
                        });
                      }
                    })
                    .catch((e) => {
                      let arr = "";
                      if (e.response?.data?.errors.length >= 2) {
                        for (let i = 0; i < e.response?.data.errors.length; i++) {
                          arr += ` ${e.response?.data?.errors[i]}`;
                        }
                      } else {
                        arr = e.response?.data?.errors;
                      }
                      setLoading(false);
                      setOpenToast(true);
                      setMessageToast({
                        text: arr ? arr : "Ошибка регистрации",
                        type: "error",
                      });
                      setActiveTimer(false);
                      setRegister(true);
                      setPin(false);
                      setOtp(null);
                      dispatch(setFormRegister({}));
                    });

                  setActiveTimer(true);
                } else {
                  setOpenToast(true);
                  setMessageToast({
                    text: "Заполните поля",
                    type: "warning",
                  });
                }
              }}
            >
              <>
                <Input
                  name="phone"
                  noImage
                  className="login medium"
                  placeholder="номер телефона"
                  type="tel"
                />
                <Input
                  name="email"
                  noImage
                  className="login medium"
                  placeholder="email"
                  type="email"
                />
                <Input
                  name="password"
                  type="password"
                  className="password medium"
                  placeholder="придумайте пароль"
                  classNameIcon="icon-outline cursor"
                  toggle={false}
                  onChange={(e) => setPass(e)}
                />
                <Input
                  name="repeat_password"
                  invalid={error === "repeat"}
                  noImage
                  className="password medium"
                  placeholder="пароль ещё раз"
                  type="password"
                  onChange={(e) => handleInput(e)}
                />
                <Checkbox
                  className="checkbox"
                  name="privacy_policy"
                  onChange={(e) => {
                    dispatch(
                      setFormRegister({ ...formRegister, privacy_policy: e.target.checked })
                    );
                  }}
                >
                  <div className="cont-text">
                    Соглашаюсь с <a className="link">Политикой обработки персональных данных </a>
                  </div>
                </Checkbox>
                <Checkbox
                  className="checkbox"
                  name="service_policy"
                  position="top"
                  onChange={(e) => {
                    dispatch(
                      setFormRegister({ ...formRegister, service_policy: e.target.checked })
                    );
                  }}
                >
                  <div className="cont-text">
                    Принимаю <a className="link">Пользовательское соглашение</a>
                  </div>
                </Checkbox>
                <Checkbox
                  className="checkbox"
                  name="promotional_materials"
                  onChange={(e) => {
                    dispatch(
                      setFormRegister({ ...formRegister, promotional_materials: e.target.checked })
                    );
                  }}
                >
                  <div className="cont-text">Соглашаюсь получать рекламные материалы</div>
                </Checkbox>
                <Button
                  disabled={!!error || !formRegister.privacy_policy || !formRegister.service_policy}
                  className={`btn-login ${register ? "register" : ""} medium`}
                  type="submit"
                  typebtn="bright"
                >
                  продолжить
                </Button>
              </>
            </form>
            <div className="lower-btn">
              <div
                className="lower-btn-text"
                onClick={() => {
                  setRegister(false);
                }}
              >
                Уже есть аккаунт?
              </div>
              <div
                className="lower-btn-text"
                onClick={async () => {
                  dispatch(setModalLogin({ open: false }));
                  dispatch(setModalLoginPartner({ open: true, register: true }));
                  setRegister(false);
                  setPin(false);
                }}
              >
                Хочу стать партнёром
              </div>
            </div>
          </div>
        )}
        {pin && (
          <div className="body-modal-login">
            <img className="image-back-login" src={background} />
            <Close
              onClick={() => {
                setRegister(false);
                setPin(false);
                setJoinPhone(false);
                setOtp(null);
                setErrorCode(false);
                dispatch(setModalLogin({ open: false }));
              }}
            />
            <div className="title">
              <div className="cont">
                <img src={pinIcon} />
                Код из СМС
              </div>
              <div className="title-lower">
                Введите код, который мы отправили на указанный номер
              </div>
            </div>
            <form
              className="form-login"
              onSubmit={(e) => {
                e.preventDefault();
                setLoading(true);
                let forSend = {
                  phone: send.phone,
                  confirmation_code: otp,
                  login: send.login,
                  password: send.password,
                };
                postSMSCodeClient(forSend)
                  .then((e) => {
                    if (!e.data.errors) {
                      setLoading(false);
                      if (e.data.token) {
                        getInitialDataClient(e.data.token?.split("|")[1])
                          .then((q) => {
                            if (!q.data.errors) {
                              setLoading(false);
                              setRegister(false);
                              setPin(false);
                              setJoinPhone(false);
                              setOtp(null);
                              dispatch(setToken(e.data.token.split("|")[1]));
                              dispatch(setUserInfo({ clientInfo: q.data }));
                              dispatch(setElMenu(q.data.menu));
                              dispatch(setModalLogin({ open: false }));
                              history.push("/client");
                            } else {
                              setOpenToast(true);
                              setMessageToast({
                                text:
                                  typeof q.data.errors === "string"
                                    ? q.data.errors
                                    : "Ошибка входа",
                                type: "error",
                              });
                            }
                          })
                          .catch((e) => {
                            setOpenToast(true);
                            setMessageToast({
                              text:
                                typeof e.response.data.errors === "string"
                                  ? e.response.data.errors
                                  : "Ошибка входа",
                              type: "error",
                            });
                          });
                      }
                    } else {
                      setLoading(false);
                      setOpenToast(true);
                      setMessageToast({
                        text:
                          typeof e.data.response.errors === "string"
                            ? e.data.errors
                            : "Ошибка входа",
                        type: "error",
                      });
                    }
                  })
                  .catch((e) => {
                    setLoading(false);
                    setOpenToast(true);
                    setMessageToast({
                      text:
                        typeof e.response.data.errors === "string"
                          ? e.response.data.errors
                          : "Ошибка входа",
                      type: "error",
                    });
                  });
              }}
            >
              <>
                {/* <InputCode name="code" /> */}
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  inputStyle={`input-code ${errorCode ? "error" : ""}`}
                  containerStyle="cont-input-code"
                  inputType="tel"
                  placeholder="0000"
                  renderSeparator={<span> </span>}
                  renderInput={(props) => <input {...props} />}
                />
                <div className="cont-btn">
                  <TimerCode
                    setActiveTimer={setActiveTimer}
                    activeTimer={activeTimer}
                    time={time}
                    setTime={setTime}
                    allSecond={ALL_SECOND}
                    send={send}
                    setOpenToast={setOpenToast}
                    setMessageToast={setMessageToast}
                    onSendAgain={() => {
                      if (send) {
                        postRegistrationClient(send)
                          .then((e) => {
                            if (!e.data.errors) {
                              setTime(ALL_SECOND);
                              setActiveTimer(true);
                            } else {
                              setOpenToast(true);
                              setMessageToast({
                                text:
                                  typeof e.data.errors === "string"
                                    ? e.data.errors
                                    : "Ошибка повторной отправки 1",
                                type: "error",
                              });
                            }
                          })
                          .catch((e) => {
                            let arr = "";
                            if (e.response?.data?.errors.length >= 2) {
                              for (let i = 0; i < e.response?.data.errors.length; i++) {
                                arr += ` ${e.response?.data?.errors[i]}`;
                              }
                            } else {
                              arr = e.response?.data?.errors;
                            }
                            setOpenToast(true);
                            setMessageToast({
                              text: arr ? arr : "Ошибка повторной отправки 2",
                              type: "error",
                            });
                            setActiveTimer(false);
                            setOtp(null);
                          });
                      } else {
                        setOpenToast(true);
                        setMessageToast({
                          text: "Ошибка повторной отправки 3",
                          type: "warning",
                        });
                      }
                    }}
                  />
                  <Button
                    className={`btn-login ${register ? "register" : ""} medium`}
                    type="submit"
                    typebtn="bright"
                  >
                    войти
                  </Button>
                </div>
              </>
            </form>
          </div>
        )}
        {!register && !pin && (
          <div className="body-modal-login">
            <img className="image-back-login" src={background} />
            <Close onClick={() => dispatch(setModalLogin({ open: false }))} />
            <div className="title">
              <img src={iconTop} />
              Авторизация
            </div>
            {joinPhone ? (
              <form
                className="form-login"
                onSubmit={(e) => {
                  e.preventDefault();
                  let info = {};
                  let inputs = e.target.querySelectorAll("input");
                  inputs.forEach((input) => (info[input.name] = input.value));
                  let forSend = {
                    phone: info.phone.replace(/\D/g, ""),
                    password: info.password,
                  };

                  if (info.phone && info.password) {
                    setLoading(true);
                    postLoginClient(forSend)
                      .then((e) => {
                        if (!e.data.errors) {
                          if (e.data.token) {
                            getInitialDataClient(e.data.token?.split("|")[1])
                              .then((q) => {
                                if (!q.data.errors) {
                                  setRegister(false);
                                  setPin(false);
                                  setJoinPhone(false);
                                  setLoading(false);

                                  dispatch(setToken(e.data.token.split("|")[1]));
                                  dispatch(setUserInfo({ clientInfo: q.data }));
                                  dispatch(setElMenu(q.data.menu));
                                  dispatch(setModalLogin({ open: false }));
                                  history.push("/client");
                                } else {
                                  setOpenToast(true);
                                  setMessageToast({
                                    text:
                                      typeof q.data.errors === "string"
                                        ? q.data.errors
                                        : "Ошибка входа",
                                    type: "error",
                                  });
                                }
                              })
                              .catch((e) => {
                                setOpenToast(true);
                                setLoading(false);

                                setMessageToast({
                                  text:
                                    typeof e.response.data.errors === "string"
                                      ? e.response.data.errors
                                      : "Ошибка входа",
                                  type: "error",
                                });
                              });
                          }
                        } else {
                          if (e.data.errors.password) {
                            setError("password");
                          }
                          setOpenToast(true);
                          setLoading(false);

                          setMessageToast({
                            text:
                              typeof e.data.errors === "string"
                                ? e.data.errors
                                : e.data.errors.password || "Ошибка авторизации",
                            type: "error",
                          });
                        }
                      })
                      .catch((e) => {
                        setOpenToast(true);
                        setLoading(false);

                        setMessageToast({
                          text:
                            typeof e.response.data.errors === "string"
                              ? e.response.data.errors
                              : "Ошибка авторизации",
                          type: "error",
                        });
                      });
                  }
                }}
              >
                <>
                  <Input
                    name="phone"
                    noImage
                    className="login medium"
                    placeholder="+7"
                    type="tel"
                  />
                  <Input
                    name="password"
                    invalid={error === "password"}
                    classNameIcon="icon-outline cursor"
                    className="password medium"
                    placeholder="пароль"
                    type="password"
                    toggle={false}
                  />
                  <Button
                    className="btn-login medium"
                    type="submit"
                    typebtn="bright"
                    disabled={loading}
                    loading={loading}
                  >
                    войти
                  </Button>
                </>
              </form>
            ) : (
              <form
                className="form-login"
                onSubmit={(e) => {
                  e.preventDefault();
                  let info = {};
                  let inputs = e.target.querySelectorAll("input");
                  inputs.forEach((input) => (info[input.name] = input.value));

                  let forSend = {
                    login: info.login,
                    password: info.password,
                  };

                  if (info.login && info.password) {
                    setLoading(true);
                    postLoginClient(forSend)
                      .then(async (e) => {
                        if (!e.data.errors) {
                          if (e.data.token) {
                            await getInitialDataClient(e.data.token?.split("|")[1])
                              .then((q) => {
                                if (!q.data.errors) {
                                  setRegister(false);
                                  setPin(false);
                                  setJoinPhone(false);
                                  setLoading(false);

                                  dispatch(setToken(e.data.token.split("|")[1]));
                                  dispatch(setUserInfo({ clientInfo: q.data }));
                                  dispatch(setElMenu(q.data.menu));
                                  dispatch(setModalLogin({ open: false }));
                                  history.push("/client");
                                } else {
                                  setOpenToast(true);
                                  setMessageToast({
                                    text:
                                      typeof q.data.errors === "string"
                                        ? q.data.errors
                                        : "Ошибка входа",
                                    type: "error",
                                  });
                                }
                              })
                              .catch((e) => {
                                setOpenToast(true);
                                setLoading(false);

                                setMessageToast({
                                  text:
                                    typeof e.response.data.errors === "string"
                                      ? e.response.data.errors
                                      : "Ошибка входа",
                                  type: "error",
                                });
                              });
                          }
                        } else {
                          if (e.data.errors.password) {
                            setError("password");
                          }
                          setOpenToast(true);
                          setLoading(false);

                          setMessageToast({
                            text:
                              typeof e.data.errors === "string"
                                ? e.data.errors
                                : e.data.errors.password || "Ошибка авторизации",
                            type: "error",
                          });
                        }
                      })
                      .catch((e) => {
                        setOpenToast(true);
                        setLoading(false);

                        setMessageToast({
                          text:
                            typeof e.response.data.errors === "string"
                              ? e.response.data.errors
                              : "Ошибка авторизации",
                          type: "error",
                        });
                      });
                  }
                }}
              >
                <>
                  <Input name="login" noImage className="login medium" placeholder="email" />
                  <Input
                    name="password"
                    invalid={error === "password"}
                    classNameIcon="icon-outline cursor"
                    className="password medium"
                    placeholder="пароль"
                    type="password"
                    toggle={false}
                  />
                  <Button
                    className="btn-login medium"
                    type="submit"
                    typebtn="bright"
                    disabled={loading}
                    loading={loading}
                  >
                    войти
                  </Button>
                </>
              </form>
            )}
            <div className="lower-btn">
              <div
                className="lower-btn-text"
                onClick={() => {
                  dispatch(setModalLogin({ open: false }));
                  dispatch(setModalRecovery({ open: true, client: true }));
                }}
              >
                Не помню пароль
              </div>
              {joinPhone ? (
                <div className="lower-btn-text" onClick={() => setJoinPhone(false)}>
                  Войти через email
                </div>
              ) : (
                <div className="lower-btn-text" onClick={() => setJoinPhone(true)}>
                  Войти через телефон
                </div>
              )}
              <div
                className="lower-btn-text"
                onClick={() => {
                  setRegister(true);
                }}
              >
                Нет аккаунта?
              </div>
            </div>
          </div>
        )}
      </IonModal>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </>
  );
};

export default LoginModal;
