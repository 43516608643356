import { IonModal } from "@ionic/react";
import Close from "../../ui/Close";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import { postChangePassword } from "../../utils/api";
import { useSelector } from "react-redux";
import Toast from "../../ui/Toast";
import { useState } from "react";

const ModalChangePassword = (props) => {
  const token = useSelector((state) => state.token);
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  return (
    <>
      <IonModal
        className="change-password"
        isOpen={props.open}
        onWillDismiss={() => props.onClose()}
      >
        <Close onClick={() => props.onClose()} />
        <form
          className="cont-change-password"
          onSubmit={(e) => {
            e.preventDefault();
            // props.setLoading(true);
            let documentInfo = {};
            let inputs = e.target.querySelectorAll("input");
            inputs.forEach(
              (input) => (documentInfo[input.name] = input.value ? input.value : null)
            );
            console.log(documentInfo);
            if (documentInfo?.password === documentInfo?.password_confirmation) {
              postChangePassword(token, documentInfo).then((e) => {
                if (!e.data.errors) {
                  setOpenToast(true);
                  setMessageToast({
                    text: "Пароль успешно изменён",
                    type: "success",
                  });
                  props.onClose();
                } else {
                  let arr = Object.values(e.data.errors);
                  let errors = "";
                  for (let i = 0; i < arr.length; i++) {
                    errors += ` ${arr[i]}`;
                  }
                  setOpenToast(true);
                  setMessageToast({
                    text: errors?.length ? errors : "Новый пароль не совпадает",
                    type: "warning",
                  });
                }
              });
            } else {
              setOpenToast(true);
              setMessageToast({
                text: "Новый пароль не совпадает",
                type: "warning",
              });
            }
          }}
        >
          <div className="title">Изменить пароль</div>
          <Input
            name="oldpassword"
            type="password"
            toggle={false}
            className="input-profile large"
            placeholder="старый пароль"
            noImage={true}
          />
          <Input
            name="password"
            type="password"
            toggle={false}
            className="input-profile large"
            placeholder="новый пароль"
          />
          <Input
            name="password_confirmation"
            type="password"
            toggle={false}
            className="input-profile large"
            placeholder="новый пароль ещё раз"
            noImage={true}
          />
          <div className="btns">
            <Button
              typebtn="outline"
              className="small"
              type="button"
              onClick={() => props.onClose()}
            >
              Отмена
            </Button>
            <Button typebtn="bright" className="small">
              Отправить
            </Button>
          </div>
        </form>
      </IonModal>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </>
  );
};

export default ModalChangePassword;
