import { ALL_TYPES } from "./types";

export function setToken(data) {
  localStorage.setItem("token", data);
  return {
    type: ALL_TYPES.TOKEN,
    data: data,
  };
}

export function setUserInfo(data) {
  let prevInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
  let nevInfo = { ...prevInfo, ...data };
  localStorage.setItem("userInfo", JSON.stringify(nevInfo));
  return {
    type: ALL_TYPES.USER_INFO,
    data: nevInfo,
  };
}

export function setColumnTable(data) {
  let prevInfo = JSON.parse(localStorage.getItem("column")) || [];
  let nevInfo = { ...prevInfo, ...data };
  localStorage.setItem("column", JSON.stringify(nevInfo));
  return {
    type: ALL_TYPES.COLUMNS_TABLE,
    data: nevInfo,
  };
}

export function setProfile(data) {
  return {
    type: ALL_TYPES.PROFILE,
    data: data,
  };
}

export function setElMenu(data) {
  return {
    type: ALL_TYPES.EL_MENU,
    data: data,
  };
}

export function setModalLogin(data) {
  return {
    type: ALL_TYPES.MODAL_LOGIN,
    data: data,
  };
}

export function setModalLoginPartner(data) {
  return {
    type: ALL_TYPES.MODAL_LOGIN_PARTNER,
    data: data,
  };
}

export function setModalRecovery(data) {
  return {
    type: ALL_TYPES.MODAL_RECOVERY,
    data: data,
  };
}

export function setFormCreateOrderClient(data) {
  return {
    type: ALL_TYPES.FORM_CREATE_ORDER_CLIENT,
    data: data,
  };
}

export function setModalGreetings(data) {
  return {
    type: ALL_TYPES.MODAL_GREETINGS,
    data: data,
  };
}

export function setCreateTour(data) {
  return {
    type: ALL_TYPES.CREATE_TOUR,
    data: data,
  };
}

export function setProducts(data) {
  return {
    type: ALL_TYPES.PRODUCTS,
    data: data,
  };
}

export function setProductsClients(data) {
  return {
    type: ALL_TYPES.PRODUCTS_CLIENTS,
    data: data,
  };
}

export function setFilterFirstPage(data) {
  return {
    type: ALL_TYPES.FILTER_FIRST_PAGE,
    data: data,
  };
}

export function setOperatorUsers(data) {
  return {
    type: ALL_TYPES.OPERATOR_USERS,
    data: data,
  };
}

export function setUser(data) {
  return {
    type: ALL_TYPES.USER,
    data: data,
  };
}

export function setSuitable(data) {
  return {
    type: ALL_TYPES.SUITABLE,
    data: data,
  };
}

export function setGenre(data) {
  return {
    type: ALL_TYPES.GENRE,
    data: data,
  };
}

export function setClasses(data) {
  return {
    type: ALL_TYPES.CLASSES,
    data: data,
  };
}

export function setFormat(data) {
  return {
    type: ALL_TYPES.FORMAT,
    data: data,
  };
}

export function setFormForCreateTour(data) {
  return {
    type: ALL_TYPES.FORM_FOR_CREATE_TOUR,
    data: data,
  };
}

export function setTourOptions(data) {
  return {
    type: ALL_TYPES.TOUR_OPTIONS,
    data: data,
  };
}

export function setOptionsDirectory(data) {
  return {
    type: ALL_TYPES.OPTIONS_DIRECTORY,
    data: data,
  };
}

export function setPromotionsDirectory(data) {
  return {
    type: ALL_TYPES.PROMOTIONS_DIRECTORY,
    data: data,
  };
}

export function setTourFocus(data) {
  return {
    type: ALL_TYPES.TOUR_FOCUS,
    data: data,
  };
}
export function setTypePoint(data) {
  return {
    type: ALL_TYPES.TYPE_POINT,
    data: data,
  };
}

export function setFormPointTour(data) {
  return {
    type: ALL_TYPES.FORM_POINT_TOUR,
    data: data,
  };
}

export function setArrayPointsTour(data) {
  return {
    type: ALL_TYPES.ARRAY_POINTS_TOUR,
    data: data,
  };
}

export function setArrayTourRaces(data) {
  return {
    type: ALL_TYPES.ARRAY_TOUR_RACES,
    data: data,
  };
}

export function setOperator(data) {
  return {
    type: ALL_TYPES.OPERATOR,
    data: data,
  };
}
export function setOptions(data) {
  return {
    type: ALL_TYPES.OPTIONS,
    data: data,
  };
}
export function setPromotions(data) {
  return {
    type: ALL_TYPES.PROMOTIONS,
    data: data,
  };
}

export function setFormForCreateOption(data) {
  return {
    type: ALL_TYPES.FORM_FOR_CREATE_OPTION,
    data: data,
  };
}

export function setFormForCreatePromotion(data) {
  return {
    type: ALL_TYPES.FORM_FOR_CREATE_PROMOTION,
    data: data,
  };
}

export function setTimeline(data) {
  return {
    type: ALL_TYPES.TIMELINE,
    data: data,
  };
}

export function setOrders(data) {
  return {
    type: ALL_TYPES.ORDERS,
    data: data,
  };
}

export function setOperations(data) {
  return {
    type: ALL_TYPES.OPERATIONS,
    data: data,
  };
}

export function setReviews(data) {
  return {
    type: ALL_TYPES.REVIEWS,
    data: data,
  };
}

export function setArrayMapCity(data) {
  return {
    type: ALL_TYPES.ARRAY_MAP_CITY,
    data: data,
  };
}

export function setValCity(data) {
  return {
    type: ALL_TYPES.VAL_CITY,
    data: data,
  };
}
export function setFormCreateRaces(data) {
  return {
    type: ALL_TYPES.FORM_CREATE_RACES,
    data: data,
  };
}

export function setFormReviews(data) {
  return {
    type: ALL_TYPES.FORM_REVIEWS,
    data: data,
  };
}

export function setFormQuestion(data) {
  return {
    type: ALL_TYPES.FORM_QUESTION,
    data: data,
  };
}

export function setFAQ(data) {
  return {
    type: ALL_TYPES.FAQ,
    data: data,
  };
}
export function setFormFAQ(data) {
  return {
    type: ALL_TYPES.FORM_FAQ,
    data: data,
  };
}

export function setFormOperatorUsers(data) {
  return {
    type: ALL_TYPES.FORM_OPERATOR_USERS,
    data: data,
  };
}

export function setFormLogin(data) {
  return {
    type: ALL_TYPES.FORM_LOGIN,
    data: data,
  };
}

export function setFormRegister(data) {
  return {
    type: ALL_TYPES.FORM_REGISTER,
    data: data,
  };
}
export function setMyCoor(data) {
  return {
    type: ALL_TYPES.MY_COOR,
    data: data,
  };
}
export function setUserPermissions(data) {
  return {
    type: ALL_TYPES.USER_PERMISSIONS,
    data: data,
  };
}

export function setOpenQuickView(data) {
  return {
    type: ALL_TYPES.OPEN_QUICK_VIEW,
    data: data,
  };
}

export function setOpenModalCreateQuestion(data) {
  return {
    type: ALL_TYPES.OPEN_MODAL_CREATE_QUESTION,
    data: data,
  };
}

export function setOpenModalCreateReport(data) {
  return {
    type: ALL_TYPES.OPEN_MODAL_CREATE_REPORT,
    data: data,
  };
}

export function setOpenModalCancelOrders(data) {
  return {
    type: ALL_TYPES.OPEN_MODAL_CANCEL_ORDERS,
    data: data,
  };
}

export function setClientTour(data) {
  return {
    type: ALL_TYPES.CLIENT_TOUR,
    data: data,
  };
}

export function setOpenModalWithdraw(data) {
  return {
    type: ALL_TYPES.OPEN_MODAL_WITHDRAW,
    data: data,
  };
}
