import { useEffect, useMemo, useState } from "react";
import Input from "../../../ui/Input";
import Select from "../../../ui/Select";
import AlertBox from "../../../ui/AlertBox";
import Button from "../../../ui/Button";
import Icons from "../../../ui/Icons";
import Toggle from "../../../ui/Toggle";
import { useDispatch, useSelector } from "react-redux";
import { setFormForCreateTour } from "../../../store/actions";
import MultiSelectChip from "../../../ui/MultiSelectChip";
import { useAsyncEffect } from "../../../hooks/useAsyncEffect";

const StageOneCreateTour = (props) => {
  const dispatch = useDispatch();
  const operator = useSelector((state) => state.userInfo.clientInfo);
  const suitable = useSelector((state) => state.suitable);
  suitable?.sort(function (a, b) {
    return a.type_id - b.type_id;
  });
  const tour = useSelector((state) => state.tourFocus);
  const genreArray = useSelector((state) => state.genre);
  const classArray = useSelector((state) => state.classes);
  const formatArray = useSelector((state) => state.format);
  const operatorUsersArray = useSelector((state) => state.operator_users);
  const operatorUsers = operatorUsersArray?.filter((el) => el?.status === 1);
  operatorUsers?.forEach((el) => {
    if (el.user_id === operator.user?.id) {
      el.full_name = `${el?.user?.first_name} (${el?.operator_role})`;
    }
  });

  const [showAlert, setShowAlert] = useState(true);
  const timeOutSales = [
    {
      name: 24,
      time: 86400,
    },
    // {
    //   name: "48",
    //   time: 86400,
    // },
    // {
    //   name: "64",
    //   time: 259200,
    // },
  ];
  const formForCreateTour = useSelector((state) => state.formForCreateTour);
  const [durationName, setDurationName] = useState();
  const [className, setClassName] = useState();
  const [genre, setGenre] = useState();

  const [timeEnd, setTimeEnd] = useState();
  const [operatorUser, setOperatorUser] = useState();
  const formForCreateTourDurationName = formatArray.find(
    (el) => el.id === formForCreateTour.product_duration_id
  );
  const formForCreateTourClassName = classArray.find(
    (el) => el.id === formForCreateTour.product_class_id
  );
  const formForCreateTourTimeEnd = timeOutSales.find(
    (el) => el.time === formForCreateTour.time_sales_stop
  );
  const formForCreateTourOperatorUser = operatorUsers.find(
    (el) => el.user_id === formForCreateTour.user_id
  );

  useAsyncEffect(() => {
    if (formForCreateTour.genres?.length) {
      let array = [];
      for (let i = 0; i < formForCreateTour.genres?.length; i++) {
        let a = genreArray.find((el) => el.id === formForCreateTour.genres[i].id);
        if (a) {
          array.push(a);
        }
      }
      setGenre(array);
    }
  }, [genreArray]);
  const disabled =
    formForCreateTour.status_id === 2 ||
    formForCreateTour.status_id === 4 ||
    formForCreateTour.status_id === 5 ||
    formForCreateTour.status_id === 6;
  return (
    <div className="cont-stage-one-create-tour">
      <div className="input-cont">
        <div className="line">
          <div className="title-line">Название</div>
          <Input
            disabled={disabled}
            value={formForCreateTour.name}
            placeholder="Не указано..."
            className="large input-line"
            noImage
            onChange={(e) => {
              dispatch(setFormForCreateTour({ ...formForCreateTour, name: e }));
            }}
          />
        </div>
        <div className="line">
          <div className="title-line">Тип автопутешествия</div>
          <Select
            disabled={disabled}
            value={durationName || formForCreateTourDurationName || ""}
            array={formatArray}
            val={formForCreateTour.duration_name}
            typesel="large"
            className="large select-line"
            onChange={(e) => {
              setDurationName(e.value);
              dispatch(
                setFormForCreateTour({
                  ...formForCreateTour,
                  product_duration_id: e.value.id,
                  number_nights: e.value.number_nights,
                })
              );
            }}
            placeholder="Выберите из списка..."
          />
        </div>
        {/* <div className="line">
          <div className="title-line">Количество ночёвок</div>
          <Input
            disabled={
              (durationName && !durationName?.editable_nights) ||
              !formForCreateTourDurationName?.editable_nights
            }
            value={formForCreateTour?.number_nights || formForCreateTourDurationName?.number_nights}
            placeholder="Не указано..."
            className={`large input-line ${
              (durationName && !durationName?.editable_nights) ||
              !formForCreateTourDurationName?.editable_nights
                ? "disabled"
                : ""
            }`}
            noImage
            onChange={(e) => {
              dispatch(setFormForCreateTour({ ...formForCreateTour, number_nights: +e }));
            }}
          />
        </div> */}
        <div className="line">
          <div className="title-line">Класс обслуживания</div>
          <Select
            disabled={disabled}
            value={className || formForCreateTourClassName || ""}
            array={classArray}
            val={formForCreateTour.class_name}
            typesel="large"
            className="large select-line"
            onChange={(e) => {
              setClassName(e.value);
              dispatch(
                setFormForCreateTour({ ...formForCreateTour, product_class_id: e.value.id })
              );
            }}
            placeholder="Выберите из списка..."
          />
        </div>
        <div className="line">
          <div className="title-line">Жанр тура</div>
          <MultiSelectChip
            disabled={disabled}
            value={genre || formForCreateTour.genres || ""}
            array={genreArray}
            maxSelectedLabels={3}
            val={formForCreateTour.genres}
            typesel="large"
            className="large select-line"
            onChange={(e) => {
              setGenre(e.value);
              let arr = [];
              for (var i = 0; i < e.value.length; i++) {
                arr.push(e.value[i].id);
              }
              dispatch(
                setFormForCreateTour({ ...formForCreateTour, genres: arr.length ? arr : null })
              );
            }}
            placeholder="Выберите из списка..."
          />
        </div>
        <div className="line">
          <div className="title-line">Время, за которое прекращаются продажи</div>
          <Select
            disabled={disabled}
            typesel="large"
            value={timeEnd || formForCreateTourTimeEnd || ""}
            // val={formForCreateTour.time_sales_stop / 60 / 60}
            array={timeOutSales}
            className="large select-line"
            onChange={(e) => {
              setTimeEnd(e.value);
              dispatch(
                setFormForCreateTour({
                  ...formForCreateTour,
                  time_sales_stop: e.value.time,
                })
              );
            }}
            placeholder="Выберите из списка..."
          />
        </div>
        <div className="line">
          <div className="title-line">Макс. количество участников</div>
          <Input
            // disabled={
            //   (durationName && !durationName?.editable_nights) ||
            //   !formForCreateTourDurationName?.editable_nights
            // }
            disabled={disabled}
            value={
              formForCreateTour?.number_travelers || formForCreateTourDurationName?.number_travelers
            }
            placeholder="Не указано..."
            className={`large input-line `}
            noImage
            onChange={(e) => {
              dispatch(setFormForCreateTour({ ...formForCreateTour, number_travelers: +e }));
            }}
          />
        </div>
        <div className="l" />
        {showAlert && (
          <AlertBox
            className="alert-create-tour"
            type="info"
            text="Значения по умолчанию будут использоваться при заполнении дней в расписании. При необходимости, там же можно будет установить другие значения для любых дней."
            onClick={() => setShowAlert(false)}
          />
        )}
        <div className="line">
          <div className="title-line">Цена по умолч.</div>
          <Input
            disabled={disabled}
            value={+formForCreateTour.summa}
            placeholder="Не указано..."
            className="large input-line"
            noImage
            onChange={(e) => {
              dispatch(setFormForCreateTour({ ...formForCreateTour, summa: e }));
            }}
          />
        </div>
        <div className="line">
          <div className="title-line">Доступно мест на дату по умолч.</div>
          <Input
            disabled={disabled}
            value={formForCreateTour.available_places}
            placeholder="Не указано..."
            className="large input-line"
            noImage
            onChange={(e) => {
              dispatch(setFormForCreateTour({ ...formForCreateTour, available_places: +e }));
            }}
          />
        </div>
        <div className="l" />
        <div className="title-create-tour">Льготный возврат</div>
        <div className="line">
          <div className="title-line up">Дней до старта тура</div>
          <div className="cont-input-subtext">
            <Input
              disabled={disabled}
              value={formForCreateTour.days_until_start}
              placeholder="Не указано..."
              className="large input-line"
              noImage
              onChange={(e) => {
                dispatch(
                  setFormForCreateTour({
                    ...formForCreateTour,
                    days_until_start: +e,
                  })
                );
              }}
            />
            <div className="subtext">
              Минимальное количество дней до старта для льготного возврата
            </div>
          </div>
        </div>
        <div className="line">
          <div className="title-line up">Время после покупки</div>
          <div className="cont-input-subtext">
            <Input
              disabled={disabled}
              value={formForCreateTour.time_after_purchase}
              placeholder="Не указано..."
              className="large input-line"
              noImage
              onChange={(e) => {
                dispatch(setFormForCreateTour({ ...formForCreateTour, time_after_purchase: +e }));
              }}
            />
            <div className="subtext">
              Сколько часов с момента покупки будет действать льготный возврат
            </div>
          </div>
        </div>
        <div className="line">
          <div className="title-line up">Процент возврата</div>
          <div className="cont-input-subtext">
            <Input
              disabled={disabled}
              value={formForCreateTour.return_percent}
              placeholder="Не указано..."
              className="large input-line"
              noImage
              onChange={(e) => {
                dispatch(setFormForCreateTour({ ...formForCreateTour, return_percent: +e }));
              }}
            />
            <div className="subtext">Процент возврата покупателю при отмене тура (0-100)</div>
          </div>
        </div>
      </div>
      <div className="dop-colmn">
        <div className="suitable">
          <div className="title">Кому подойдёт</div>
          {suitable?.map((el, i) => {
            return (
              <Toggle
                key={i}
                disabled={disabled}
                text={el?.name}
                description={el?.description}
                checked={
                  formForCreateTour?.suitable_arr?.find((s) => s.suitable_id === el?.id)?.cnt === 1
                    ? true
                    : false
                }
                onChange={
                  (e) => {
                    let find = formForCreateTour?.suitable_arr?.find(
                      (z) => z.suitable_id === el.id
                    );
                    if (find) {
                      let filter = formForCreateTour?.suitable_arr?.filter(
                        (s) => s.suitable_id !== el.id
                      );
                      filter.push({ suitable_id: el.id, cnt: e.detail.checked ? 1 : 2 });
                      dispatch(
                        setFormForCreateTour({
                          ...formForCreateTour,
                          suitable_arr: filter,
                        })
                      );
                    }
                  }
                  // dispatch(
                  //   setFormForCreateTour({ ...formForCreateTour, possible_invalid: e.detail.checked })
                  // )
                }
              />
            );
          })}
        </div>
        <div className="responsible">
          <div className="title">Ответственный сотрудник</div>
          <div className="cont-sel">
            <Select
              disabled={disabled}
              value={operatorUser || formForCreateTourOperatorUser || ""}
              val={formForCreateTour.first_name}
              optionLabel="full_name"
              onChange={(e) => {
                setOperatorUser(e.value);
                // let selval = operatorUsers.find((el) => el.first_name === e.target.value); // TODO если одинаковые имена
                dispatch(
                  setFormForCreateTour({
                    ...formForCreateTour,
                    user_id: e.value.user_id,
                  })
                );
              }}
              typesel="large"
              className="large"
              array={operatorUsers}
              // defaultValue={operatorUsers?.[0]?.first_name}
              placeholder="Выберите из списка..."
            />
            <div className="muted">
              На email этого пользователя будут приходить системные уведомления, в том числе по
              результатам модерации тура
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StageOneCreateTour;
