import "../../less/css/firstpage.css";
import logo from "../../image/logo.svg";
// temporary
import mapPin from "../../image/icons/map-pin.svg";
import route from "../../image/icons/route.svg";
import clockFilled from "../../image/icons/clock-filled.svg";

import star from "../../image/icons/stars.png";
import qs from "qs";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import { IonMenuButton, IonMenuToggle } from "@ionic/react";
import Icons from "../../ui/Icons";
import PreviewTour from "../../ui/PreviewTour";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { setArrayMapCity, setOpenQuickView, setValCity } from "../../store/actions";
import { useHistory, useLocation } from "react-router";
import { toms } from "../../utils/utils";

const LeftMenuTours = (props) => {
  // const products = useMemo(() => props.filterArray, [props.filterArray]);
  const productsSearch = useSelector((state) => state.productsClients);
  const productsArray = useSelector((state) => state.filterFirstPage);
  productsArray.forEach((e) => {
    let count = 0;
    let filter = e.details?.filter((el) => el?.point_type_id === 1);
    let sort = filter?.sort(function (a, b) {
      return a.order - b.order;
    })[0];
    let slice;
    if (sort?.collect_time) {
      slice = sort?.collect_time?.slice(0, 5);
    } else {
      slice = "23:59";
    }
    e.time_start = slice;
    for (let i = 0; i < e.races.length; i++) {
      if (
        e?.races[i].number_travelers !== 0 &&
        e?.races[i].number_travelers > e?.races[i].paid_travelers_count &&
        new Date(e?.races[i].start_date).getTime() + toms(slice) > new Date().getTime() &&
        !e?.races[i]?.deleted_at
      ) {
        count += 1;
      }
    }
    e.count_races = count;
  });
  const products = productsArray?.filter((el) => el?.races?.length !== 0 && el?.count_races !== 0);
  const history = useHistory();
  const urlSearch = qs.parse(history.location.search.substr(1));
  const location = useLocation();
  const arrayMapCity = useSelector((state) => state.arrayMapCity);
  const [list, setList] = useState();
  const dispatch = useDispatch();
  // const [searchPattern, setSearchPattern] = useState();
  const searchPattern = useMemo(() => props.searchPattern, [props.searchPattern]);
  const [focusSearch, setFocusSearch] = useState(false);
  const [searchTour, setSearchTour] = useState([]);
  const lastSearch = JSON.parse(localStorage.getItem("last_search_product"));
  if (!lastSearch) {
    localStorage.setItem("last_search_product", JSON.stringify([]));
  }
  useAsyncEffect(async () => {
    if (searchPattern?.length) {
      let pat = searchPattern?.split(" ");
      let search;
      for (let i = 0; i < pat?.length; i++) {
        if (!!pat[i]) {
          search = productsSearch?.filter((q) =>
            q?.name?.toLowerCase()?.includes(pat[i]?.toLowerCase())
          );
        }
      }
      // if (searchPattern?.length > 2) {
      //   props.nmapis?.search
      //     .textSearch({
      //       searchPattern,
      //       limit: 5,
      //       pivot: { longitude: 99.92900937795639, latitude: 60.40111541748047 },
      //     })
      //     .then((e) => {
      //       setList(e);
      //     });
      // }
      setSearchTour(search);
    }
  }, [searchPattern]);

  // const lastSearch = localStorage.getItem()
  const maps = useMemo(() => props.maps, [props.maps]);
  return (
    <div className="left-menu-tours">
      <div className="header-left-menu">
        <div className="up-header">
          <IonMenuToggle className="btn-menu-left" menu="menu-left">
            <Button typebtn="ghost" className="small cube btn-header-left">
              <Icons icon="menu" />
            </Button>
          </IonMenuToggle>
          <img className="logo" src={logo}></img>
          <IonMenuToggle className="btn-menu-left" menu="menu-left-filter">
            <Button typebtn="ghost" className="small cube btn-header-left">
              <Icons icon="filter" />
            </Button>
          </IonMenuToggle>
        </div>
        <Input
          value={searchPattern}
          onFocus={() => setFocusSearch(true)}
          onBlur={() => setFocusSearch(false)}
          className="input-search-tour medium"
          placeholder="Поиск на карте..."
          noImage={true}
          onChange={(e) => {
            props.setSearchPattern(e);
            if (!e) {
              if (props.myCoor ? arrayMapCity?.length > 11 : arrayMapCity?.length > 10) {
                let arr = arrayMapCity;
                arr.shift();
                dispatch(setArrayMapCity([...arr]));
                dispatch(
                  setValCity(
                    [
                      {
                        name: arr[0].name,
                        lon: arr[0].lon,
                        lat: arr[0].lat,
                      },
                    ][0]
                  )
                );
              }
              setList(null);
              setSearchTour([]);
            }
          }}
        />
      </div>
      <div className="content-left-menu">
        <div className="content">
          {searchPattern || focusSearch ? (
            <div
              className="scroll search"
              onMouseDown={(e) => {
                e.preventDefault();
              }}
            >
              {/* {list?.map((el, i) => {
                return (
                  <div
                    key={i}
                    className="el-search"
                    onClick={async () => {
                      if (urlSearch.id) {
                        await dispatch(setOpenQuickView({ open: false, el: null }));
                        history.push("/");
                      }
                      let local = JSON.parse(localStorage.getItem("last_search_product"));
                      if (local.length === 10) {
                        local.shift();
                      }
                      if (!local.find((eq) => eq === searchPattern)) {
                        local.push(searchPattern);
                      }

                      localStorage.setItem("last_search_product", JSON.stringify(local));

                      const item = await props.nmapis.search.findNearestObject({
                        coordinates: {
                          latitude: el.coordinates.latitude,
                          longitude: el.coordinates.longitude,
                        },
                        locale: navitel.LocaleCode.ru_RU, // optional
                      });
                      let arr = props.arrayCity;
                      if (
                        props.myCoor ? props.arrayCity?.length > 11 : props.arrayCity?.length > 10
                      ) {
                        arr.shift();
                      }
                      dispatch(
                        setArrayMapCity([
                          {
                            name: item.address.city,
                            lon: el.coordinates.latitude,
                            lat: el.coordinates.longitude,
                          },
                          ...arr,
                        ])
                      );

                      dispatch(
                        setValCity(
                          [
                            {
                              name: item.address.city,
                              lon: el.coordinates.latitude,
                              lat: el.coordinates.longitude,
                            },
                          ][0]
                        )
                      );
                    }}
                  >
                    <div className="cont-img">
                      <img src={mapPin} />
                    </div>
                    <div className={`cont-inf-el-search ${list.length === i + 1 ? "" : "border"}`}>
                      <div className="left">
                        <div className="text">{el.label}</div>
                        <div className="subtext">
                          {el?.address.city ? el?.address.city : "Область"}
                        </div>
                      </div>
                      <div className="right"></div>
                    </div>
                  </div>
                );
              })} */}
              {searchTour?.map((el, i) => {
                return (
                  <div
                    key={i}
                    className="el-search"
                    onClick={async (e) => {
                      e.stopPropagation();
                      maps?.remove();
                      props.setMaps(null);
                      dispatch(setOpenQuickView({ open: true, el: el }));
                      let local = JSON.parse(localStorage.getItem("last_search_product"));
                      if (local.length === 10) {
                        local.shift();
                      }
                      if (!local.find((eq) => eq === searchPattern)) {
                        local.push(searchPattern);
                      }

                      localStorage.setItem("last_search_product", JSON.stringify(local));
                      history.push(`?id=${el?.id}`);
                    }}
                  >
                    <div className="cont-img">
                      <img src={route} />
                    </div>
                    <div
                      className={`cont-inf-el-search ${
                        searchTour.length === i + 1 ? "" : "border"
                      }`}
                    >
                      <div className="left">
                        <div className="text">{el.name}</div>
                        <div className="subtext">Тур</div>
                      </div>
                      <div className="right">
                        <div className="rating">
                          <img src={star} />
                          <div className="val-rate">
                            {el?.rating.length ? (+el?.rating[0]?.avg)?.toFixed(2) : "0.00"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {!searchPattern &&
                lastSearch?.map((el, i) => {
                  return (
                    <div
                      key={i}
                      className="el-search"
                      onClick={async (e) => {
                        e.stopPropagation();
                        props.setSearchPattern(el);
                      }}
                    >
                      <div className="cont-img">
                        <img src={clockFilled} />
                      </div>
                      <div
                        className={`cont-inf-el-search ${
                          searchTour.length === i + 1 ? "" : "border"
                        }`}
                      >
                        <div className="left">
                          <div className="text">{el}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="scroll">
              {products?.map((el, i) => {
                return (
                  <PreviewTour
                    key={i}
                    el={el}
                    type="tours"
                    maps={props.maps}
                    setMaps={props.setMaps}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeftMenuTours;
