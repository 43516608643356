import Input from "../../ui/Input";
import backEmpty from "../../image/background-empty-table.svg";
import Icons from "../../ui/Icons";
import Button from "../../ui/Button";
import RightMenuRaces from "./RightMenuRaces";
import { IonMenuToggle } from "@ionic/react";
import TableProductRaces from "../../ui/TableProductRaces";
import { useEffect, useState } from "react";
import { FIELD_FOR_TOURS_RACES } from "../../utils/array";
import { useDispatch, useSelector } from "react-redux";
import ModalFilterProductRaces from "../../ui/ModalsFilter/ModalFilterProductRaces";
import ModalDelete from "./Modals/ModalDelete";
import { deleteTourRaces, getTourRaces, getToursByID } from "../../utils/api";
import qs from "qs";
import { setArrayTourRaces, setTourFocus } from "../../store/actions";
import { useHistory } from "react-router";
import Toast from "../../ui/Toast";

const SalesSchedule = (props) => {
  const [openMenuElem, setOpenMenuElem] = useState(false);
  const [openChangeElem, setOpenChangeElem] = useState(false);
  const token = useSelector((state) => state.token);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [focusElement, setFocusElement] = useState();
  const arrayTourRaces = useSelector((state) => state.arrayTourRaces);
  const products = useSelector((state) => state.products);
  const columnsTable = useSelector((state) => state.columnsTable);
  const [openModalDelete, setOpenModelDelete] = useState(false);
  const history = useHistory();
  const urlSearch = qs.parse(history.location.search.substr(1));
  const dispatch = useDispatch();
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  // const fieldArray = FIELD_FOR_TOURS_RACES;
  const numberColumns = 0;
  const [fieldArray, setFieldArray] = useState();
  useEffect(() => {
    setFieldArray(JSON.parse(localStorage.getItem("column"))[numberColumns]);
  }, [columnsTable]);
  // const fieldArray = JSON.parse(localStorage.getItem("column"))[0][0];
  var arrayForExcel = [];

  for (let i = 0; i < arrayTourRaces?.length; i++) {
    let new_array = {
      Номер: arrayTourRaces[i]?.id,
      "Дата начала заезда": arrayTourRaces[i]?.start_date,
      "Дата завершения заезда": arrayTourRaces[i]?.end_date,
      "Формат тура": arrayTourRaces[i]?.product.duration.name,
      "Количество опций": arrayTourRaces[i]?.operator_options.length,
      "Дата создания": arrayTourRaces[i]?.created_at,
      Цена: +arrayTourRaces[i]?.summa,
      "Цена со скидкой": "Заглушка",
      Мест: arrayTourRaces[i]?.number_travelers,
      "Занято мест": arrayTourRaces[i]?.paid_travelers_count,
      "Осталось мест":
        arrayTourRaces[i]?.number_travelers - arrayTourRaces[i]?.paid_travelers_count,
    };
    arrayForExcel.push(new_array);
  }

  const menu = [
    // {
    //   name: "Предпросмотр",
    //   icon: "design-personal-eye",
    //   click: () => {},
    // },
    {
      name: "Архивировать",
      icon: "design-personal-archive",
      click: () => {
        setOpenModelDelete(true);
        setOpenMenuElem(false);
      },
    },
  ];
  return (
    <>
      <div className="sales-schedule">
        <div className="title">Календарь продаж</div>
        <TableProductRaces
          openMenuElem={openMenuElem}
          setOpenMenuElem={setOpenMenuElem}
          openChangeElem={openChangeElem}
          setOpenChangeElem={setOpenChangeElem}
          openFilterModal={setOpenFilterModal}
          focus={setFocusElement}
          array={arrayTourRaces}
          arrayProd={products}
          numberColumns={numberColumns}
          header={fieldArray}
          menu={menu}
          classNameTableSetting="tours-races"
          arrayForExcel={arrayForExcel}
          nameFile={`Таблица заездов ${new Date().toLocaleDateString("ru")}`}
          searchPlaceholder="Поиск по опциям..."
        />
      </div>
      <ModalFilterProductRaces onClose={setOpenFilterModal} open={openFilterModal} />
      <ModalDelete
        title="Архивировать"
        text="Действительно хотите архивировать тур?"
        btn_delete="Да"
        open={openModalDelete}
        onClose={setOpenModelDelete}
        onClick={() => {
          deleteTourRaces(token, focusElement?.product_id, focusElement?.id)
            .then(async (e) => {
              if (!e.data.errors) {
                await getToursByID(token, urlSearch.id).then((e) => {
                  dispatch(setTourFocus(e.data.data));
                });
                await getTourRaces(token, urlSearch.id).then((e) => {
                  if (e.data.data) {
                    dispatch(setArrayTourRaces(e.data.data));
                  }
                });
                setOpenToast(true);
                setMessageToast({
                  text: "Тур архивирован",
                  type: "success",
                });
              } else {
                setOpenToast(true);
                setMessageToast({
                  text: "Ошибка архивирования",
                  type: "error",
                });
              }
            })
            .catch((e) => {
              setOpenToast(true);
              setMessageToast({
                text: e.response.data.errors || "Ошибка архивирования",
                type: "error",
              });
            });
          setOpenModelDelete(false);
        }}
      />

      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </>
  );
};

export default SalesSchedule;
