import { IonModal } from "@ionic/react";
import Close from "../../ui/Close";
import Rating from "../../ui/Rating";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import Input from "../../ui/Input";
import avatar from "../../image/avatar.svg";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { getReviews, getReviewsByID, putReviews } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { setFormReviews, setReviews } from "../../store/actions";
import { useState } from "react";
import { Timeline } from "primereact/timeline";
import flag from "../../image/icon-timeline/flag-filled.svg";
import bed from "../../image/icon-timeline/bed-filled.svg";
import star from "../../image/icon-timeline/star-filled.svg";
import Badge from "../../ui/Badge";
import Status from "../../ui/Status";
import Toast from "../../ui/Toast";
import { URL_ORIGIN } from "../../utils/config";
import { formatDate } from "../../utils/utils";

const ModalReviewsDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);

  const userPermissions = useSelector((state) => state.userPermissions.feedback?.reviews);

  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });

  const token = useSelector((state) => state.token);
  const formReviews = useSelector((state) => state.formReviews);
  var productDetails = formReviews?.product?.details;
  productDetails?.sort(function (a, b) {
    return a.order - b.order;
  });

  const dispatch = useDispatch();

  useAsyncEffect(async () => {
    props.element &&
      (await getReviewsByID(token, props.element?.id).then((el) => {
        if (!el.data.errors) {
          dispatch(setFormReviews(el.data.data));
        }
      }));
  }, [props.element]);

  // const formatDate = (value) => {
  //   var timezone = new Date(value).getTimezoneOffset() / 60;
  //   var lastChangeDate = ` ${new Date(value).toLocaleDateString().slice(0, 2)} ${new Date(
  //     value
  //   ).toLocaleDateString("ru", { month: "short" })} ${new Date(value)
  //     .toLocaleDateString()
  //     .slice(6, 11)}, ${new Date(value).toLocaleTimeString().slice(0, 5)} (UTC${
  //     +timezone < 0 ? `+${+timezone * -1 > 9 ? "" : "0"}${+timezone * -1}:00` : `-${+timezone * -1}`
  //   })`;
  //   return lastChangeDate;
  // };

  const onSave = () => {
    let array = { ...formReviews, status_id: 2 };
    putReviews(token, formReviews.id, array).then(async (e) => {
      if (!e.data.errors) {
        await getReviews(token).then((q) => {
          if (!q.data.errors) {
            dispatch(setReviews(q.data.data));
          }
        });
        await getReviewsByID(token, formReviews?.id).then((z) => {
          if (!z.data.errors) {
            dispatch(setFormReviews(z.data.data));
          }
        });
      }
      setLoading(false);
    });
  };
  const customizedContent = (item) => {
    let rev = formReviews?.childs?.find((el) => el?.detail_id === item?.id);
    return (
      <div className="cont-info-details">
        <Icons
          className="icon-info-details icon-outline"
          icon={active === item.id ? `design-personal-chevron-left` : "chevron-down"}
        />
        <div className="info-cont">
          <div className="cont-title">
            <div
              className="title"
              onClick={() => (active === item.id ? setActive(false) : setActive(item.id))}
            >
              {item.name}
            </div>
            {active === item.id && <div className="collect-time">{item.collect_time}</div>}
          </div>
          {/* {active === item.id && <Status color="gray">{`День ${item.order + 1}`}</Status>} */}
        </div>
        {active === item.id && (
          <>
            <div className="cont-rate">
              <Rating rate={rev?.tour_assessment ? rev?.tour_assessment : 0} />
              <div className="val">
                {rev?.tour_assessment
                  ? +rev?.tour_assessment === 1
                    ? "Плохо"
                    : +rev?.tour_assessment === 2
                    ? "Неудовлетворительно"
                    : +rev?.tour_assessment === 3
                    ? "Удовлетворительно"
                    : +rev?.tour_assessment === 4
                    ? "Хорошо"
                    : +item?.tour_assessment === 5 && "Отлично"
                  : "Не указано"}
              </div>
            </div>
            <div className="text-reviews-details">{rev?.review_text}</div>
            <div className="cont-images">
              {rev?.pictures?.map((e, i) => {
                return (
                  <div key={i} className="image">
                    <img src={`${URL_ORIGIN}${e?.photo}`} />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  };
  const customizedMarker = (item) => {
    return (
      <span className="span">
        {item.point_type_id === 1 ? (
          <img src={flag} />
        ) : item.point_type_id === 2 ? (
          <img src={bed} />
        ) : (
          item.point_type_id === 3 && <img src={star} />
        )}
      </span>
    );
  };

  return (
    <>
      <IonModal
        className="modal-reviews-details"
        isOpen={props.open}
        onWillDismiss={() => {
          props.onClose(false);
          dispatch(setFormReviews({}));
          props.setFocusElement(null);
        }}
      >
        <Close
          onClick={() => {
            props.onClose(false);
            dispatch(setFormReviews({}));
            props.setFocusElement(null);
          }}
        />
        <div className="body-modal-reviews-details">
          <div className="client-cont">
            <div className="circle">
              <img src={avatar} />
            </div>
            <div className="info-cont">
              <div className="text">{`${formReviews?.user?.first_name} ${formReviews?.user?.last_name[0]}.`}</div>
              <div className="subtext">{formReviews?.user?.phone}</div>
            </div>
          </div>
          <div className="rate-cont">
            <Rating rate={formReviews?.tour_assessment} />
            <div className="val">
              {formReviews?.tour_assessment
                ? +formReviews?.tour_assessment === 1
                  ? "Плохо"
                  : +formReviews?.tour_assessment === 2
                  ? "Неудовлетворительно"
                  : +formReviews?.tour_assessment === 3
                  ? "Удовлетворительно"
                  : +formReviews?.tour_assessment === 4
                  ? "Хорошо"
                  : +formReviews?.tour_assessment === 5 && "Отлично"
                : "Не указано"}
            </div>
          </div>
          <div className="cont-text-information">
            <div className="text-reviews">{formReviews?.review_text}</div>
            {formReviews?.pictures?.length ? (
              <div className="cont-images">
                {formReviews?.pictures?.length &&
                  formReviews?.pictures.map((el, i) => {
                    return (
                      <div key={i} className="item-img">
                        <img src={`${URL_ORIGIN}${el.photo}`} />
                      </div>
                    );
                  })}
              </div>
            ) : undefined}
            <div className="info-reviews">
              <div className="line">
                <div className="subtext">Тур</div>
                <div className="text-name">{formReviews?.product?.name}</div>
              </div>
              <div className="line">
                <div className="subtext">Создан</div>
                <div className="text">{formatDate(formReviews?.created_at)}</div>
              </div>
            </div>
            {/* <Timeline
              value={productDetails}
              // align="right"
              className="customized-timeline"
              marker={customizedMarker}
              content={customizedContent}
            /> */}
            <Button
              typebtn="outline"
              className="small btn-report"
              onClick={() => {
                if (userPermissions[2]) {
                  props.onClose(false);
                  props.setOpenModalReport(true);
                } else {
                  setOpenToast(true);
                  setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
                }
              }}
            >
              <Icons icon="alert-circle" />
              Пожаловаться
            </Button>
            {formReviews?.status_id === 1 ? (
              <Input
                value={formReviews.response_text || ""}
                placeholder="Ваш ответ..."
                className="large area area-reviews"
                type="textarea"
                noImage
                onChange={(e) =>
                  dispatch(
                    setFormReviews({
                      ...formReviews,
                      response_text: e,
                      response_date: new Date(),
                    })
                  )
                }
              />
            ) : (
              formReviews?.status_id === 2 && (
                <>
                  <div className="l" />
                  <div className="client-cont">
                    <div className="circle">
                      <img src={avatar} />
                    </div>
                    <div className="info-cont">
                      <div className="text">{`${formReviews?.response_user?.first_name} ${formReviews?.response_user?.last_name[0]}.`}</div>
                      <div className="subtext">{formReviews?.user?.phone}</div>
                    </div>
                  </div>
                  <div className="cont-text-information">
                    <div className="text-reviews">{formReviews?.response_text}</div>
                  </div>
                  <div className="info-reviews">
                    <div className="line">
                      <div className="subtext">Тур</div>
                      <div className="text-name">{formReviews?.product?.name}</div>
                    </div>
                    <div className="line">
                      <div className="subtext">Создан</div>
                      <div className="text">{formatDate(formReviews?.updated_at)}</div>
                    </div>
                  </div>
                  <Button
                    typebtn="outline"
                    className="small btn-report-answer-edit"
                    onClick={() => {
                      if (userPermissions[1]) {
                        dispatch(setFormReviews({ ...formReviews, status_id: 1 }));
                      } else {
                        setOpenToast(true);
                        setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
                      }
                    }}
                  >
                    <Icons icon="design-personal-edit" />
                    Редактировать ответ
                  </Button>
                </>
              )
            )}
          </div>
          <div className="btns">
            <Button
              typebtn="outline"
              className="small"
              onClick={() => {
                props.onClose(false);
                props.setFocusElement(null);
                dispatch(setFormReviews({}));
              }}
            >
              Отмена
            </Button>
            {formReviews?.status_id === 1 && (
              <Button
                disabled={loading}
                loading={loading}
                typebtn="bright"
                className={`small ${loading ? "disabled" : ""} btn-save`}
                onClick={() => {
                  if (userPermissions[1]) {
                    onSave();
                    setLoading(true);
                  } else {
                    setOpenToast(true);
                    setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
                  }
                }}
              >
                Опубликовать ответ
              </Button>
            )}
          </div>
        </div>
      </IonModal>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </>
  );
};

export default ModalReviewsDetails;
