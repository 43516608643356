import { IonModal } from "@ionic/react";
import Close from "../../ui/Close";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import { useState } from "react";
import { getCancelOrderAdmin, getOrders, getOrdersByID } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { setOrders } from "../../store/actions";
import Toast from "../../ui/Toast";

const ModalCancelOrder = (props) => {
  const [reason, setReason] = useState();
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  const send = () => {
    let forSend = {
      reason_cancel: reason,
    };
    getCancelOrderAdmin(token, props.orderInformation.id, forSend)
      .then(async (e) => {
        if (!e.data.errors) {
          props.setOrderInformation(e.data.order);
          await getOrders(token).then((e) => {
            dispatch(setOrders(e.data.data));
          });
          props.onClose(false);
        }
      })
      .catch((e) => {
        setOpenToast(true);
        setMessageToast({
          text: e.response.data.errors || "Недостаточно прав доступа",
          type: "warning",
        });
      });
  };
  return (
    <IonModal
      className="modal-cancel-order"
      isOpen={props.open}
      onWillDismiss={() => props.onClose(false)}
    >
      <Close onClick={() => props.onClose(false)} />
      <div className="body-modal-cancel-order">
        <div className="title">Отмена заказа</div>
        <div className="text">
          Укажите причину отмены заказа. После подтверждения, заказ будет переведён в статус
          «Отменён». Средства будут возвращены покупателю.
        </div>
        <Input
          placeholder="Причина отмены..."
          //   className-="large area area-cancel-order"
          type="textarea"
          noImage
          className="large area area-cancel-order"
          onChange={(e) => {
            setReason(e);
          }}
        />
        <div className="btns">
          <Button typebtn="outline" className="small" onClick={() => props.onClose(false)}>
            Отмена
          </Button>
          <Button
            typebtn="bright"
            className="small"
            onClick={() => {
              send();
            }}
          >
            Подтвердить
          </Button>
        </div>
      </div>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </IonModal>
  );
};

export default ModalCancelOrder;
