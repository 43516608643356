import {
  IonContent,
  IonHeader,
  IonMenu,
  IonPage,
  IonRouterOutlet,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import "../less/css/showcase.css";
import Home from "./Home";
import { Route, useHistory } from "react-router";
import ToursListContent from "../components/showcase/ToursListContent";
import Header from "../components/Header";
import OptionsContent from "../components/showcase/OptionsContent";
import PromotionsContent from "../components/showcase/PromotionsContent";
import OrdersContent from "../components/finance/OrdersContent";
import ReportsContent from "../components/finance/ReportsContent";
import OperationsHistoryContent from "../components/finance/OperationsHistoryContent";
import QuestionsContent from "../components/feedback/QuestionsContent";
import ReviewsContent from "../components/feedback/ReviewsContent";
import EmployeesSettingsContent from "../components/settings/EmployeesSettingsContent";
import CompanySettingsContent from "../components/settings/CompanySettingsContent";
import ProfileContent from "../components/profile/ProfileContent";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getClassList,
  getFAQ,
  getFormatList,
  getGenreList,
  getInitialDataAdmin,
  getOperations,
  getOperator,
  getOptions,
  getOptionsDirectory,
  getOrders,
  getPartnerUsers,
  getPartnerUsersByID,
  getPointTypes,
  getPromotions,
  getPromotionsDirectory,
  getReviews,
  getRulesOperatorUsers,
  getSuitable,
  getToursOperator,
} from "../utils/api";
import {
  setClasses,
  setElMenu,
  setFAQ,
  setFormat,
  setGenre,
  setOperations,
  setOperator,
  setOperatorUsers,
  setOptions,
  setOptionsDirectory,
  setOrders,
  setProducts,
  setProfile,
  setPromotions,
  setPromotionsDirectory,
  setReviews,
  setSuitable,
  setTypePoint,
  setUserInfo,
  setUserPermissions,
} from "../store/actions";
import ToursCreateContent from "../components/showcase/ToursCreateContent";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import EmplyeesInformation from "../components/settings/EmplyeesInformation";
import TourInformation from "../components/showcase/TourInformation";
import RightMenuCoor from "../components/showcase/create-tours-stages/RightMenuCoor";
import OrderDetails from "../components/finance/OrderDetails";
import PromotionsCreate from "../components/showcase/PromotionsCreate";
import RightMenuRaces from "../components/showcase/RightMenuRaces";
import { menuController } from "@ionic/core/components";
import FAQContent from "../components/feedback/FAQContent";
import { toBinary } from "../utils/utils";
import RightMenuRacesChange from "../components/showcase/RightMenuRacesChange";
import {
  FIELD_FOR_TOURS_RACES,
  FIELD_FOR_TOURS,
  FIELD_FOR_OPERATOR_USERS,
  FIELD_FOR_OPTIONS,
  FIELD_FOR_ORDERS,
  FIELD_FOR_OPERATIONS_HISTORY,
  FIELD_FOR_REVIEWS,
  FIELD_FOR_QUESTIONS,
  FIELD_FOR_FAQ,
} from "../utils/array";
import ModalWithdraw from "../components/finance/ModalWithdraw";

const PartnerPage = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const history = useHistory();
  const profile = useSelector((state) => state.profile);
  const userPermissions = useSelector((state) => state.userPermissions);

  // const createOpen = useSelector((state) => state.createTour); // create
  useAsyncEffect(() => {
    if (!localStorage.getItem("column")) {
      let arrDefField = [
        FIELD_FOR_TOURS_RACES,
        FIELD_FOR_TOURS,
        FIELD_FOR_OPERATOR_USERS,
        FIELD_FOR_OPTIONS,
        FIELD_FOR_ORDERS,
        FIELD_FOR_OPERATIONS_HISTORY,
        FIELD_FOR_REVIEWS,
        FIELD_FOR_QUESTIONS,
        FIELD_FOR_FAQ,
      ];
      localStorage.setItem("column", JSON.stringify(arrDefField));
    }
  }, []);
  useAsyncEffect(async () => {
    if (!token || token !== "null") {
      await getInitialDataAdmin(token)
        .then(async (e) => {
          dispatch(setUserInfo({ clientInfo: e.data }));
          dispatch(setElMenu(e.data.menu));
          await getPartnerUsersByID(token, e.data.user.id).then((e) => {
            dispatch(setProfile(e.data.data));
          });
          await getRulesOperatorUsers(token, e.data.user.id).then((e) => {
            let arr = [];
            e.data.data.forEach((el) => {
              let binary = toBinary(el.crud);
              arr.push(binary);
            });
            dispatch(
              setUserPermissions({
                showcase: {
                  tours: {
                    0: arr[0][0] === "1" ? true : false, // Просмотр / Экспорт
                    1: arr[0][1] === "1" ? true : false, // Создание
                    2: arr[0][2] === "1" ? true : false, // Редактирование
                    3: arr[0][3] === "1" ? true : false, // Удаление
                    4: arr[0][4] === "1" ? true : false, // Изменение статуса
                  },
                  options: {
                    0: arr[1][0] === "1" ? true : false, // Просмотр / Экспорт
                    1: arr[1][1] === "1" ? true : false, // Создание
                    2: arr[1][2] === "1" ? true : false, // Редактирование
                    3: arr[1][3] === "1" ? true : false, // Удаление
                    4: arr[1][4] === "1" ? true : false, // Деактивировать
                  },
                  promotions: {
                    0: arr[2][0] === "1" ? true : false, // Просмотр / Экспорт
                    1: arr[2][1] === "1" ? true : false, // Создание
                    2: arr[2][2] === "1" ? true : false, // Редактирование
                    3: arr[2][3] === "1" ? true : false, // Удаление
                    4: arr[2][4] === "1" ? true : false, // Активировать акцию платформы
                  },
                },
                finance: {
                  orders: {
                    0: arr[3][0] === "1" ? true : false, // Просмотр / Экспорт
                    1: arr[3][1] === "1" ? true : false,
                    2: arr[3][2] === "1" ? true : false,
                    3: arr[3][3] === "1" ? true : false,
                    4: arr[3][4] === "1" ? true : false,
                  },
                  operations: {
                    0: arr[4][0] === "1" ? true : false, // Просмотр / Экспорт
                    1: arr[4][1] === "1" ? true : false,
                    2: arr[4][2] === "1" ? true : false,
                    3: arr[4][3] === "1" ? true : false,
                    4: arr[4][4] === "1" ? true : false,
                  },
                },
                feedback: {
                  reviews: {
                    0: arr[5][0] === "1" ? true : false, // Просмотр / Экспорт
                    1: arr[5][1] === "1" ? true : false, // Модерировать
                    2: arr[5][2] === "1" ? true : false, // Пожаловаться
                    3: arr[5][3] === "1" ? true : false,
                    4: arr[5][4] === "1" ? true : false,
                  },
                  questions: {
                    0: arr[6][0] === "1" ? true : false, // Просмотр / Экспор
                    1: arr[6][1] === "1" ? true : false, // Модерировать
                    2: arr[6][2] === "1" ? true : false, // Пожаловаться
                    3: arr[6][3] === "1" ? true : false,
                    4: arr[6][4] === "1" ? true : false,
                  },
                  // support: {
                  //   0: false,
                  // },
                },
                settings: {
                  company: {
                    0: arr[8][0] === "1" ? true : false, // Просмотр
                    1: arr[8][1] === "1" ? true : false, // Редактировать
                    2: arr[8][2] === "1" ? true : false,
                    3: arr[8][3] === "1" ? true : false,
                    4: arr[8][4] === "1" ? true : false,
                  },
                  operator_users: {
                    0: arr[7][0] === "1" ? true : false, // Просмотр
                    1: arr[7][1] === "1" ? true : false, // Создание
                    2: arr[7][2] === "1" ? true : false, // Редатирование
                    3: arr[7][3] === "1" ? true : false, // Удаление
                    4: arr[7][4] === "1" ? true : false,
                  },
                },
              })
            );
          });
        })
        .catch((e) => {
          history.push("/");
        });
    }
  }, [token]);

  useAsyncEffect(async () => {
    getToursOperator(token).then((e) => {
      if (e.data.data) {
        dispatch(setProducts(e.data.data));
      }
    });
    await getPartnerUsers(token).then((e) => {
      if (e.data.data) {
        dispatch(setOperatorUsers(e.data.data));
      }
    });
    getGenreList(token).then((e) => {
      dispatch(setGenre(e.data.data));
    });
    getPointTypes(token).then((e) => {
      dispatch(setTypePoint(e.data.data));
    });
    getFormatList(token).then((e) => {
      dispatch(setFormat(e.data.data));
    });
    getSuitable(token).then((e) => {
      dispatch(setSuitable(e.data.data));
    });
    getClassList(token).then((e) => {
      dispatch(setClasses(e.data.data));
    });
    getOperator(token).then((e) => {
      dispatch(setOperator(e.data.data[0]));
    });
    getOptions(token).then(async (e) => {
      const arr = e.data.data;
      arr?.forEach((el) => {
        el.name = el?.option?.name;
      });
      dispatch(setOptions(arr));
    });
    getOptionsDirectory(token).then((e) => {
      dispatch(setOptionsDirectory(e.data.data));
    });
    getPromotionsDirectory(token).then((e) => {
      dispatch(setPromotionsDirectory(e.data.data));
    });
    getPromotions(token).then((e) => {
      const arr = e.data.data;
      arr?.forEach((el) => {
        el.name = el?.promotion?.name;
      });
      dispatch(setPromotions(arr));
    });
    getOrders(token).then((e) => {
      dispatch(setOrders(e.data.data));
    });
    getOperations(token).then((e) => {
      dispatch(setOperations(e.data.data));
    });
    getReviews(token).then((e) => {
      dispatch(setReviews(e.data.data));
    });
    getFAQ(token).then((e) => {
      dispatch(setFAQ(e.data.data));
    });
  }, []);
  const [pageCreateRaces, setPageCreateRaces] = useState();

  return (
    <IonPage id="main-content">
      <Header />

      <RightMenuCoor />
      <RightMenuRaces />
      <RightMenuRacesChange />
      <ModalWithdraw />

      <IonRouterOutlet id="right-add">
        <Route exact path="/partner/summary" component={Home} />

        <Route exact path="/partner/showcase/tours" component={ToursListContent} />
        <Route
          id="right-add-races"
          path="/partner/showcase/tours/create"
          component={ToursCreateContent}
        />
        <Route path="/partner/showcase/tours/details" component={TourInformation} />

        <Route path="/partner/showcase/options" component={OptionsContent} />
        <Route exact path="/partner/showcase/promotions" component={PromotionsContent} />
        <Route path="/partner/showcase/promotions/create" component={PromotionsCreate} />

        <Route exact path="/partner/finance/orders" component={OrdersContent} />
        <Route path="/partner/finance/orders/details" component={OrderDetails} />
        <Route path="/partner/finance/reports" component={ReportsContent} />
        <Route path="/partner/finance/operations" component={OperationsHistoryContent} />

        <Route path="/partner/feedback/reviews" component={ReviewsContent} />
        <Route path="/partner/feedback/questions" component={QuestionsContent} />
        <Route path="/partner/feedback/faq" component={FAQContent} />

        <Route path="/partner/settings/company" component={CompanySettingsContent} />
        <Route exact path="/partner/settings/employees" component={EmployeesSettingsContent} />
        <Route path="/partner/settings/employees/users" component={EmplyeesInformation} />

        <Route path="/partner/profile" component={ProfileContent} />
      </IonRouterOutlet>
    </IonPage>
    // {createOpen.open ? <ToursCreateContent /> : <ToursListContent />} // create
  );
};

export default PartnerPage;
