import Input from "../ui/Input";
import Icons from "../ui/Icons";
import "../less/css/table-timeline.css";
import Button from "./Button";
import { useEffect, useMemo, useRef, useState } from "react";
// import { Timeline } from "vis-timeline/peer";
// // import { Network } from "vis-network/peer/esm/vis-network";
// import { DataSet } from "vis-data/peer/esm/vis-data";
import qs from "qs";

import moment from "moment";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import { useDispatch, useSelector } from "react-redux";
import { setTimeline } from "../store/actions";
import SelectButton from "./SelectButton";
import AutoCompleteSelect from "./AutoCompleteSelect";
import Toast from "./Toast";

import Timeline from "react-visjs-timeline";
import { useHistory } from "react-router";
import ExcelJS from "exceljs";

const TableTimeline = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const propmotions = useSelector((state) => state.promotions);
  const userPermissions = useSelector((state) => state.userPermissions.showcase?.promotions);
  const [item, setItem] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  const urlSearch = qs.parse(history.location.search.substr(1));

  const timelineRX = useSelector((state) => state.timeline);
  var nowMonth = new Date().toLocaleDateString("ru", { month: "long" });
  var nowNumericMonth = new Date().toLocaleDateString("ru", { month: "numeric" });
  var nowYear = new Date().toLocaleDateString("ru", { year: "numeric" });
  const [datePaginator, setDayPaginator] = useState(
    `${nowMonth[0].toUpperCase() + nowMonth.slice(1)}, ${nowYear}`
  );
  const [dateNumericPaginator, setDayNumericPaginator] = useState([
    +nowYear,
    nowNumericMonth - 1,
    1,
  ]);

  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("User Data");
    const headerRow = worksheet.addRow([]);
    const headerCells = Object.values(props.arrayForExcel);
    for (let i = 0; i < headerCells?.length; i++) {
      const column = Object.keys(headerCells[i]);
      for (let j = 0; j < column?.length; j++) {
        headerRow.getCell(j + 1).value = column[j];
      }
    }
    const rows = Object.values(props.arrayForExcel);
    for (let i = 0; i < rows?.length; i++) {
      const cells = Object.values(rows[i]);
      const rowData = [];
      for (let j = 0; j < cells.length; j++) {
        rowData.push(cells[j]);
      }
      worksheet.addRow(rowData);
      worksheet.columns.forEach(function (column, i) {
        let maxLength = 0;
        column["eachCell"]({ includeEmpty: true }, function (cell) {
          var columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
      });
    }
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
      });
      var link = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = link;
      a.download = `Таблица акций ${new Date().toLocaleDateString("ru")}.xlsx`;
      a.click();
      // window.location = link;
      // saveAs(blob, `output.xlsx`);
    });
  };

  const arrSort = [
    { name: "По дате старта", id: 1, val: "start_date" },
    { name: "По дате завершения", id: 2, val: "end_date" },
    { name: "По дате создания", id: 3, val: "created_at" },
  ];

  const arrScale = [
    { name: "100%", id: 1 },
    { name: "75%", id: 2 },
    { name: "50%", id: 3 },
    { name: "25%", id: 4 },
  ];
  const [valSort, setValSort] = useState(arrSort[0]);
  const [valScale, setValScale] = useState(arrScale[0]);
  const [searchProm, setSearchProm] = useState();

  // const itemsFocus = [
  //   {
  //     start: moment().startOf("day"),
  //     end: moment().startOf("day").add(1, "days"),
  //     type: "background",
  //     style: `background-color: #F9F9FB !important; position: absolute; z-index: 0`,
  //   },
  // ];

  var options = {
    stack: true,
    start: new Date(),
    end: new Date(1000 * 60 * 60 * 24 + new Date().valueOf()),
    editable: {
      updateTime: true, // drag items horizontally
    },
    onMove: (e) => {},
    zoomMin: 1000000000,
    zoomMax: 1000000000,
    order: function (a, b) {
      return b.start - a.start;
    },
    margin: {
      axis: 32,
      item: {
        vertical: 4,
      },
    },
    orientation: {
      axis: "top",
      item: "top",
    },
    showMajorLabels: true,
    showCurrentTime: true,
    type: "range",
    format: {
      minorLabels: {
        minute: "h:mm",
        hour: "HH:mm",
        day: "D",
        // week: "w",
        weekday: "D ddd",
      },
    },
  };

  useEffect(() => {
    var itemArray = [];
    for (let i = 0; i < props.array?.length; i++) {
      itemArray.push({
        editable: {
          updateTime: true, // drag items horizontally
        },
        create: new Date(props.array[i].created_at).toLocaleDateString("sv-SE"),
        className: "item-timeline",
        align: "center",
        content: `<div><span>${props.array[i]?.name}</span>
    <div>${
      props.array[i].operator ? props.array[i].operator.name : props.operator.name
    }</div></div>`,
        style: `background-color: ${props.array[i].color} !important`,
        id: props.array[i].id,
        start: new Date(props.array[i].start_date).toLocaleDateString("sv-SE"),
        end: new Date(props.array[i].end_date).toLocaleDateString("sv-SE"),
      });
    }
    setItem(itemArray);
  }, [props.array]);

  const refCont = useRef();
  const container = useMemo(() => refCont.current, [refCont.current]);

  // useAsyncEffect(async () => {
  //   if (item.length) {
  //     timeline = new Timeline(container.current, item, options);
  //     await timeline.moveTo(new Date());
  //     dispatch(setTimeline(timeline));
  //   }
  // }, [item, props.array]);

  container?.$el?.on("select", (e) => {
    // if (userPermissions[0]) {
    let searchEl = props.array?.find((el) => el.id === e.items[0]);
    props.setFocusElement(searchEl);
    searchEl && props.setOpenQuickShow(true);
    // } else {
    //   setOpenToast(true);
    //   setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
    // }
  });

  const moveHanlderLeft = () => {
    let range = container?.$el.getWindow();
    let interval = (range.end - range.start) * 0.5;
    container?.$el.moveTo(new Date(new Date(range.start).getTime() + interval - 2629800000));
    // history.push(
    //   `?date=${new Date(new Date(range.start).getTime() + interval - 2629800000).getTime()}`
    // );
  };
  const moveHanlderRight = () => {
    let range = container?.$el.getWindow();
    let interval = (range.end - range.start) * 0.5;
    container?.$el.moveTo(new Date(new Date(range.start).getTime() + interval + 2629800000));
    // history.push(
    //   `?date=${new Date(new Date(range.start).getTime() + interval + 2629800000).getTime()}`
    // );
  };

  // useAsyncEffect(() => {
  //   history.push(`?date=${new Date().getTime()}`);
  // }, []);

  // useAsyncEffect(() => {
  //   console.log(container?.$el.getWindow());
  // }, [urlSearch.date]);
  return (
    <>
      <div className="list-timeline">
        <div className="cont-tablet">
          <div className="filter">
            <div className="filter-search-cont">
              <AutoCompleteSelect
                image="search"
                filter
                value={searchProm}
                typesel="small"
                className="input-search small"
                placeholder={props.searchPlaceholder}
                array={propmotions}
                // onBlur={() => setSearchProm(null)}
                onChange={(e) => {
                  if (typeof e.value === "string") {
                    setSearchProm(e.value);
                  }
                  if (typeof e.value !== "string") {
                    // setSearchProm(e.value);
                    let interval =
                      (new Date(e.value.end_date).getTime() -
                        new Date(e.value.start_date).getTime()) *
                      0.5;
                    container?.$el.moveTo(
                      new Date(new Date(e.value.start_date).getTime() + interval)
                    );
                  }
                }}
              />
              <div className="paginator-date">
                <div className="btns-paginator">
                  <Button
                    typebtn="ghost"
                    className="small cube"
                    onClick={async () => {
                      // var d = new Date(
                      //   dateNumericPaginator[0],
                      //   dateNumericPaginator[1],
                      //   dateNumericPaginator[2]
                      // );
                      // var from = d.setMonth(d.getMonth() - 1);
                      // let newMonth = new Date(d).toLocaleDateString("ru", { month: "long" });
                      // let newYear = new Date(d).toLocaleDateString("ru", { year: "numeric" });
                      // let newNumericMonth = new Date(d).toLocaleDateString("ru", {
                      //   month: "numeric",
                      // });
                      // setDayPaginator(
                      //   `${newMonth[0].toUpperCase() + newMonth.slice(1)}, ${newYear}`
                      // );
                      // setDayNumericPaginator([+newYear, +newNumericMonth - 1, 1]);
                      // container?.current?.$el?.moveTo(new Date(+newYear, +newNumericMonth - 1, 1));
                      // history.push(`?date=${+urlSearch?.date - 2629800000}`);

                      moveHanlderLeft();
                    }}
                  >
                    <Icons icon="design-personal-chevron-left" />
                  </Button>
                  <Button
                    typebtn="ghost"
                    className="small cube"
                    onClick={() => {
                      // var d = new Date(
                      //   dateNumericPaginator[0],
                      //   dateNumericPaginator[1],
                      //   dateNumericPaginator[2]
                      // );
                      // var from = d.setMonth(d.getMonth() + 1);
                      // let newMonth = new Date(d).toLocaleDateString("ru", { month: "long" });
                      // let newYear = new Date(d).toLocaleDateString("ru", { year: "numeric" });
                      // let newNumericMonth = new Date(d).toLocaleDateString("ru", {
                      //   month: "numeric",
                      // });
                      // setDayPaginator(
                      //   `${newMonth[0].toUpperCase() + newMonth.slice(1)}, ${newYear}`
                      // );
                      // setDayNumericPaginator([+newYear, +newNumericMonth - 1, 1]);
                      // container?.current?.$el?.moveTo(new Date(+newYear, +newNumericMonth - 1, 1));
                      moveHanlderRight();
                    }}
                  >
                    <Icons icon="design-personal-chevron-right" />
                  </Button>
                </div>
                <div className="date">{""}</div>
              </div>
              <Button
                typebtn="outline"
                className="small"
                onClick={async () => {
                  // let newMonth = new Date().toLocaleDateString("ru", { month: "long" });
                  // let newYear = new Date().toLocaleDateString("ru", { year: "numeric" });
                  // let newNumericMonth = new Date().toLocaleDateString("ru", {
                  //   month: "numeric",
                  // });
                  // setDayPaginator(`${newMonth[0].toUpperCase() + newMonth.slice(1)}, ${newYear}`);
                  // setDayNumericPaginator([+newYear, +newNumericMonth - 1, 1]);
                  await container?.$el.moveTo(new Date());
                }}
              >
                Сегодня
              </Button>
            </div>
            <div className="btn-elem-filter">
              <SelectButton
                value={valSort}
                array={arrSort}
                className="ghost btn-elem-d"
                onChange={(e) => {
                  // setValSort(e.value);
                  if (e.value.id === 1) {
                    container?.$el.setOptions({
                      order: function (a, b) {
                        return b.start - a.start;
                      },
                    });
                  } else if (e.value.id === 2) {
                    container?.$el.setOptions({
                      order: function (a, b) {
                        return b.end - a.end;
                      },
                    });
                  } else if (e.value.id === 3) {
                    container?.$el.setOptions({
                      order: function (a, b) {
                        return b.create - a.create;
                      },
                    });
                  }
                  container?.$el.moveTo(new Date());
                }}
              />
              <SelectButton
                value={valScale}
                array={arrScale}
                className="ghost btn-elem-d"
                onChange={async (e) => {
                  // setValScale(e.value);
                  if (e.value.id === 4) {
                    container?.$el.setOptions({
                      zoomMax: 4000000000,
                      zoomMin: 4000000000,
                    });
                  }
                  if (e.value.id === 3) {
                    container?.$el.setOptions({
                      zoomMax: 3000000000,
                      zoomMin: 3000000000,
                    });
                  }
                  if (e.value.id === 2) {
                    container?.$el.setOptions({
                      zoomMax: 2000000000,
                      zoomMin: 2000000000,
                    });
                  }
                  if (e.value.id === 1) {
                    container?.$el.setOptions({
                      zoomMax: 1000000000,
                      zoomMin: 1000000000,
                    });
                  }
                  container?.$el.moveTo(new Date());
                }}
              />
              <Button
                typebtn="ghost"
                className="small btn-elem-f"
                onClick={() => {
                  props.openFilterModal(true);
                  // setOpenModalFilterTable(true);
                }}
              >
                <Icons icon="filter" />
                <div className="text">Фильтры</div>
              </Button>
              <Button typebtn="ghost" className="small btn-elem-e" onClick={() => exportToExcel()}>
                <Icons icon="download" />
                <div className="text">Скачать</div>
              </Button>
            </div>
          </div>
          <div className="chip-cont">
            {/* <IonChip className="chip">
              <div className="text">Стоимость: 15000-55000</div>
              <div className="img">
                <Icons icon="design-personal-x" />
              </div>
            </IonChip> */}
          </div>
          {/* <div ref={container} id="visualization"></div> */}
          <Timeline options={options} items={item} ref={refCont} />

          {/* <Timeline options={options} items={items} /> */}
        </div>
      </div>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </>
    // <Gantt tasks={tasks} isChecked={false} listCellWidth={""} onDateChange={handleTaskChange} />
  );
};

export default TableTimeline;
