import { useMemo, useState } from "react";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { useDispatch, useSelector } from "react-redux";
import { setArrayMapCity, setOpenQuickView, setValCity } from "../../store/actions";
import route from "../../image/icons/route.svg";
import clockFilled from "../../image/icons/clock-filled.svg";
import star from "../../image/icons/stars.png";
import { useHistory } from "react-router";
import Icons from "../../ui/Icons";
import { IonMenuToggle } from "@ionic/react";

const SearchTourMobile = (props) => {
  const dispatch = useDispatch();
  const [focusSearch, setFocusSearch] = useState(false);
  const [searchTour, setSearchTour] = useState([]);
  const history = useHistory();
  const searchPattern = useMemo(() => props.searchPattern, [props.searchPattern]);

  const products = useSelector((state) => state.filterFirstPage);
  const arrayMapCity = useSelector((state) => state.arrayMapCity);
  const [list, setList] = useState();
  const maps = useMemo(() => props.maps, [props.maps]);
  const lastSearch = JSON.parse(localStorage.getItem("last_search_product"));
  if (!lastSearch) {
    localStorage.setItem("last_search_product", JSON.stringify([]));
  }
  useAsyncEffect(async () => {
    if (searchPattern?.length) {
      let pat = searchPattern?.split(" ");
      let search;
      for (let i = 0; i < pat?.length; i++) {
        if (!!pat[i]) {
          search = products?.filter((q) => q?.name?.toLowerCase()?.includes(pat[i]?.toLowerCase()));
        }
      }
      // if (searchPattern?.length > 2) {
      //   props.nmapis?.search
      //     .textSearch({
      //       searchPattern,
      //       limit: 5,
      //       pivot: { longitude: 99.92900937795639, latitude: 60.40111541748047 },
      //     })
      //     .then((e) => {
      //       setList(e);
      //     });
      // }
      setSearchTour(search);
    }
  }, [searchPattern]);

  return (
    <div className="body-search">
      <div className="header-body-search">
        <Input
          autoFocus
          value={searchPattern}
          onFocus={() => setFocusSearch(true)}
          onBlur={() => setFocusSearch(false)}
          className="input-search-tour medium"
          placeholder="Поиск на карте..."
          noImage={true}
          onChange={(e) => {
            props.setSearchPattern(e);
            if (!e) {
              if (props.myCoor ? arrayMapCity?.length > 11 : arrayMapCity?.length > 10) {
                let arr = arrayMapCity;
                arr.shift();
                dispatch(setArrayMapCity([...arr]));
                dispatch(
                  setValCity(
                    [
                      {
                        name: arr[0].name,
                        lon: arr[0].lon,
                        lat: arr[0].lat,
                      },
                    ][0]
                  )
                );
              }
              setList(null);
              setSearchTour([]);
            }
          }}
        />
        <Button className="small" typebtn="ghost" onClick={() => props.setOpenMobileSearch(false)}>
          Отмена
        </Button>
      </div>
      <div className="search-list-cont">
        {searchTour?.map((el, i) => {
          return (
            <div
              key={i}
              className="el-search"
              onClick={async (e) => {
                e.stopPropagation();
                maps?.remove();
                props.setMaps(null);
                dispatch(setOpenQuickView({ open: true, el: el }));
                let local = JSON.parse(localStorage.getItem("last_search_product"));
                if (local.length === 10) {
                  local.shift();
                }
                if (!local.find((eq) => eq === searchPattern)) {
                  local.push(searchPattern);
                }

                localStorage.setItem("last_search_product", JSON.stringify(local));
                props.setOpenMobileSearch(false);
                props.setSearchPattern(null);

                history.push(`?id=${el?.id}`);
              }}
            >
              <div className="cont-img">
                <img src={route} />
              </div>
              <div className={`cont-inf-el-search ${searchTour.length === i + 1 ? "" : "border"}`}>
                <div className="left">
                  <div className="text">{el.name}</div>
                  <div className="subtext">Тур</div>
                </div>
                <div className="right">
                  <div className="rating">
                    <img src={star} />
                    <div className="val-rate">
                      {el?.rating.length ? (+el?.rating[0]?.avg)?.toFixed(2) : "0.00"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {!searchPattern &&
          lastSearch?.map((el, i) => {
            return (
              <div
                key={i}
                className="el-search"
                onClick={async (e) => {
                  e.stopPropagation();
                  props.setSearchPattern(el);
                }}
              >
                <div className="cont-img">
                  <img src={clockFilled} />
                </div>
                <div className={`cont-inf-el-search `}>
                  <div className="left">
                    <div className="text">{el}</div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SearchTourMobile;
