import { IonContent, IonPage } from "@ionic/react";
import "../less/css/home.css";
import HomeMain from "../components/home/HomeMain";

const Home = () => {
  return (
    // <IonPage>
    <IonContent className="page">
      <HomeMain />
    </IonContent>
    // </IonPage>
  );
};

export default Home;
