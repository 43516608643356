const Checkbox = (props) => {
  return (
    <div className={`checkbox-cont ${props.position ? props.position : ""}`}>
      {props.type === "radio" ? (
        <input
          type="radio"
          defaultChecked={props.checked}
          name={props.name || undefined}
          onChange={props.onChange}
        />
      ) : (
        <input
          type="checkbox"
          id={props.id}
          name={props.name || undefined}
          checked={props.checked}
          // defaultChecked={props.checked}
          onChange={props.onChange}
        />
      )}
      <label htmlFor={props.htmlFor} className={props.className}>
        {props.children}
      </label>
    </div>
  );
};

export default Checkbox;
