import { IonContent } from "@ionic/react";
import "../../less/css/finance.css";
import Table from "../../ui/Table";
import { useEffect, useState } from "react";
import { FIELD_FOR_OPERATIONS_HISTORY, STATUSES_OPERATIONS_HISTORY } from "../../utils/array";
import ModalFilterOpearationsHistory from "../../ui/ModalsFilter/ModalFilterOpearationsHistory";
import { useSelector } from "react-redux";
import OperationsHistoryDetails from "./OperationsHistoryDetails";
import ModalWithdraw from "./ModalWithdraw";
import Toast from "../../ui/Toast";

const OperationsHistoryContent = () => {
  const operator = useSelector((state) => state.operator);
  const operations = useSelector((state) => state.operations);
  const [openMenuElem, setOpenMenuElem] = useState(false);

  const userPermissions = useSelector((state) => state.userPermissions.finance?.operations);

  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  const columnsTable = useSelector((state) => state.columnsTable);

  const [openOperationsHistoryDetails, setOpenOperationsHistoryDetails] = useState(false);
  const [focusElement, setFocusElement] = useState();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const status = STATUSES_OPERATIONS_HISTORY;
  // const fieldArray = FIELD_FOR_OPERATIONS_HISTORY;

  const numberColumns = 5;
  const [fieldArray, setFieldArray] = useState();
  useEffect(() => {
    setFieldArray(JSON.parse(localStorage.getItem("column"))[numberColumns]);
  }, [columnsTable]);

  var arrayForExcel = [];

  for (let i = 0; i < operations?.length; i++) {
    let new_array = {
      Номер: operations[i]?.id,
      "Дата создания операции": operations[i]?.created_at,
      "Тип операции": operations[i]?.type.name,
      Заказ:
        operations[i]?.operation_type_id === 1 ||
        operations[i]?.operation_type_id === 2 ||
        operations[i]?.operation_type_id === 4
          ? operations[i]?.order?.id
          : "",
      Сумма: +operations[i]?.summa,
      Инициатор:
        operations[i]?.operation_type_id === 5 || operations[i]?.operation_type_id === 6
          ? "Заглушка"
          : "",
      Источник: operations[i]?.operation_type_id === 6 ? "Вне платформы" : "",
      Статус: operations[i]?.status.description,
    };
    if (
      operations[i]?.operation_type_id === 1 ||
      operations[i]?.operation_type_id === 2 ||
      operations[i]?.operation_type_id === 4
    ) {
      new_array = { ...new_array, Заказ: operations[i]?.order?.id };
    }
    arrayForExcel.push(new_array);
  }

  const menu = [
    {
      name: "Смотреть",
      icon: "design-personal-eye",
      click: (e) => {
        if (userPermissions[0]) {
          setOpenOperationsHistoryDetails(true);
          setOpenMenuElem(false);
        } else {
          setOpenToast(true);
          setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
        }
      },
      status: [2, 3],
    },
  ];

  const type = [
    {
      type: 1,
      type_name: "Аванс",
      type_name_full: "Авансы",
    },
    // {
    //   type: 2,
    //   type_name: "Получение остатка",
    // },
    {
      type: 3,
      type_name: "Возврат",
      type_name_full: "Возвраты",
    },
    {
      type: 4,
      type_name: "Штраф",
      type_name_full: "Штрафы",
    },
    {
      type: 5,
      type_name: "Выплата",
      type_name_full: "Выплаты",
    },
    {
      type: 6,
      type_name: "Пополнение депозита",
    },
  ];

  return (
    <>
      <IonContent className="page">
        <div className="operations-finance-page">
          <div className="cont-title">
            <div className="title">История операций</div>
            <div className="subtext">{`${operator?.name_legal}, ${
              operator?.settlement_account ?? ""
            }`}</div>
          </div>
          <Table
            onClick={() => {
              if (userPermissions[0]) {
                setOpenOperationsHistoryDetails(true);
              } else {
                setOpenToast(true);
                setMessageToast({ text: "Недостаточно прав доступа", type: "warning" });
              }
            }}
            openMenuElem={openMenuElem}
            setOpenMenuElem={setOpenMenuElem}
            type={type}
            openFilterModal={setOpenFilterModal}
            focus={setFocusElement}
            status={status}
            array={operations}
            header={fieldArray}
            numberColumns={numberColumns}
            menu={menu}
            permission={userPermissions}
            classNameTableSetting="operations-history"
            arrayForExcel={arrayForExcel}
            nameFile={`Таблица истории транзакций ${new Date().toLocaleDateString("ru")}`}
            searchPlaceholder="Поиск по сумме..."
          />
        </div>
      </IonContent>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
      <ModalFilterOpearationsHistory open={openFilterModal} onClose={setOpenFilterModal} />
      <OperationsHistoryDetails
        status={status}
        type={type}
        open={openOperationsHistoryDetails}
        onClose={setOpenOperationsHistoryDetails}
        element={focusElement}
      />
    </>
  );
};

export default OperationsHistoryContent;
