import { IonContent } from "@ionic/react";
import "../less/css/firstpage.css";
import Footer from "../ui/Footer";

const InformationContent = () => {
  return (
    <IonContent className="page-guest">
      <div className="inf-content">
        <div className="cont-inf">
          <div className="cont-1">
            <div className="title">Политика обработки Персональных данных</div>
            <div className="lower">Редакция от 27 февраля 2023 года</div>
          </div>
          <div className="cont">
            <div className="title">Общие положения</div>
            <div className="low">
              1.1. Политика ООО «Смородина» (далее – "Общество") в отношении обработки персональных
              данных (далее – "Политика") разработана в соответствии с Федеральным законом №152– ФЗ
              «О персональных данных» от 27.07.2006 г. и иными актами РФ. <br />
              1.2. Настоящая Политика определяет порядок обработки персональных данных пользователей
              сайта/ов https://mosdigitals.ru/ (далее – "Сайты"), условия и принципы обработки
              персональных данных, права Пользователей и обязанности Общества, сведения о
              реализуемых мерах по защите обрабатываемых персональных данных. <br />
              1.3. Настоящая Политика действует в отношении всех персональных данных, которые
              Общество получает от пользователей Сайта (далее – "Пользователи", "Пользователь").{" "}
              <br />
              1.4. В случае несогласия с условиями Политики Пользователь должен немедленно
              прекратить любое использование Сайтов.
            </div>
          </div>
          <div className="cont">
            <div className="title">Термины и определения</div>
            <div className="low">
              В Политике используются следующие термины: <br />
              Сайт – интернет-сайт, расположенный по адресу https://smorodina.com <br />
              Сайт Платформы – интернет-сайт, расположенный по адресу https://smorodina.com <br />
              Сайты - Сайт и Сайт Платформы. <br />
              Персональные данные (далее - "ПДн") – любая информация, относящаяся к прямо или
              косвенно определенному или определяемому физическому лицу (субъекту ПДн). <br />
              Блокирование ПДн – временное прекращение обработки ПДн (за исключением случаев, если
              обработка необходима для уточнения ПДн). <br />
              Обработка ПДн – любое действие (операция) или совокупность действий (операций),
              совершаемых с использованием средств автоматизации или без использования таких средств
              с персональными данными, включая сбор, запись, систематизацию, накопление, хранение,
              уточнение (обновление, изменение), извлечение, использование, блокирование, удаление,
              уничтожение ПДн. <br />
              Оператор – Общество с ограниченной ответственностью «Смородина» (ООО «Смородина»),
              юридический адрес: 123242, г. Москва, Ленина, 1. 4. Общество самостоятельно организует
              и осуществляет обработку ПДн, а также определяет цели обработки ПДн, состав ПДн,
              подлежащих обработке, действия (операции), совершаемые с ПДн. <br />
              Личный кабинет – совокупность защищенных страниц Сайта Платформы, созданных в
              результате регистрации Пользователя и доступных при вводе его аутентификационных
              данных (адреса электронной почты и пароля) в предусмотренные для этого поля на Сайте
              Платформы.
              <br />
              Предоставление ПДн – действия, направленные на раскрытие ПДн определенному лицу или
              определенному кругу лиц. <br />
              Распространение ПДн – действия, направленные на раскрытие ПДн неопределенному кругу
              лиц (передача ПДн) или на ознакомление с персональными данными неограниченного круга
              лиц, в том числе обнародование ПДн в средствах массовой информации, размещение в
              информационно–телекоммуникационных сетях или предоставление доступа к персональным
              данным каким–либо иным способом. <br />
              Пользователь – посетитель Сайтов, субъект ПДн, а также пользователь мобильных
              прилождений. <br />
              Уничтожение ПДн – действия, в результате которых невозможно восстановить содержание
              ПДн в ИСПДн, и (или) в результате которых уничтожаются материальные носители ПДн.{" "}
              <br />
              Файлы cookie (куки) - данные, которые автоматически передаются Обществом в процессе
              использования Сайтов с помощью установленного на устройстве Пользователя программного
              обеспечения, в том числе IP-адрес, географическое местоположение, информация о
              браузере и виде операционной системы устройства Пользователя, технические
              характеристики оборудования и программного обеспечения, используемых Пользователем,
              дата и время доступа к Сайтам.
            </div>
          </div>
          <div className="cont">
            <div className="title">Правовые основания и цели обработки персональных данных</div>
            <div className="low">
              3.1. Правовыми основаниями обработки ПДн Обществом являются: <br />
              Гражданский Кодекс РФ. <br />
              Федеральный закон от 29 декабря 2012 г. № 273-ФЗ «Об образовании в Российской
              Федерации»; <br />
              Согласие Пользователя на обработку персональных данных. <br />
              3.2. Общество обрабатывает ПДн Пользователя исключительно в следующих целях: <br />
              3.2.1. Оказание Пользователю услуг Оператором: регистрация в Личном кабинете,
              предоставление Платформы, консультации и техническая поддержка, участие в партнерских
              программах; <br />
              3.2.2. Размещение на официальном сайте Общества в целях информирования потенциальных и
              текущих клиентов ООО «Смородина», посетителей Сайта; <br />
              3.2.3. Определение предпочтений Пользователя, совершенствование работы Сайта. <br />
              3.3. Конкретный объем ПДн, обрабатываемых в указанных выше целях, определен в разделе
              5 Политики.
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </IonContent>
  );
};

export default InformationContent;
