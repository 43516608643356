import { IonModal } from "@ionic/react";
import Button from "../../../ui/Button";
import Close from "../../../ui/Close";
const ModalOperNoModeration = (props) => {
  return (
    <IonModal
      className="modal-oper-no-moderation"
      isOpen={props.open}
      onWillDismiss={() => props.onClose(false)}
    >
      <Close onClick={() => props.onClose(false)} />
      <div className="body-modal-oper-no-moderation">
        <div className="title">Это пока нельзя сделать.</div>
        <div className="cont-text">
          <div className="text">
            Ваша компания ещё не прошла модерацию. Вы получите уведомление, когда мы завершим
            процедуру проверки. Если прошло более 5 рабочих дней, напишите нам на
          </div>
          <a className="link">help@smorodina.com.</a>
        </div>
        <div className="btns">
          <Button typebtn="outline" className="small" onClick={() => props.onClose(false)}>
            Закрыть
          </Button>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalOperNoModeration;
