import { IonPopover } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { setToken } from "../store/actions";
import { postLogout } from "../utils/api";

const HeaderPopover = (props) => {
  const history = useHistory();
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  return (
    <IonPopover
      class={props.class}
      isOpen={props.isOpen}
      onWillDismiss={props.onWillDismiss}
      trigger={props.trigger}
      side={props.side}
      alignment={props.alignment}
    >
      <div className="body-popover">
        {props.element === "user" && (
          <>
            <div
              className="cont-el-pop"
              onClick={() => {
                history.push("/partner/profile");
                props.onWillDismiss();
              }}
            >
              <div className="text">Редактировать</div>
            </div>
            <div
              className="cont-el-pop"
              onClick={() => {
                postLogout(token)
                  .then((e) => {
                    history.push("/");
                    dispatch(setToken(null));
                    localStorage.removeItem("token");
                    localStorage.removeItem("userInfo");
                    localStorage.removeItem("column");

                    props.onWillDismiss();
                    window.location.reload();
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }}
            >
              <div className="text">Выйти</div>
            </div>
          </>
        )}
        {props.element === "user-client" && (
          <>
            <div
              className="cont-el-pop"
              onClick={() => {
                history.push("/client/profile");
                props.onWillDismiss();
              }}
            >
              <div className="text">Редактировать</div>
            </div>
            <div
              className="cont-el-pop"
              onClick={() => {
                postLogout(token)
                  .then((e) => {
                    history.push("/");
                    dispatch(setToken(null));
                    localStorage.removeItem("token");
                    localStorage.removeItem("userInfo");
                    localStorage.removeItem("column");

                    props.onWillDismiss();
                    window.location.reload();
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }}
            >
              <div className="text">Выйти</div>
            </div>
          </>
        )}
        {props.element === "showcase" && (
          <>
            <div
              className="cont-el-pop"
              onClick={() => {
                history.push("/partner/showcase/tours?page=1");
                props.onWillDismiss();
              }}
            >
              <div className="text">Туры</div>
            </div>
            <div
              className="cont-el-pop"
              onClick={() => {
                history.push("/partner/showcase/options?page=1");
                props.onWillDismiss();
              }}
            >
              <div className="text">Опции</div>
            </div>
            <div
              className="cont-el-pop"
              onClick={() => {
                history.push("/partner/showcase/promotions");
                props.onWillDismiss();
              }}
            >
              <div className="text">Акции и скидки</div>
            </div>
          </>
        )}
        {props.element === "finance" && (
          <>
            <div
              className="cont-el-pop"
              onClick={() => {
                history.push("/partner/finance/orders?page=1");
                props.onWillDismiss();
              }}
            >
              <div className="text">Заказы</div>
            </div>
            <div
              className="cont-el-pop"
              onClick={() => {
                history.push("/partner/finance/reports");
                props.onWillDismiss();
              }}
            >
              <div className="text">Отчёты</div>
            </div>
            <div
              className="cont-el-pop"
              onClick={() => {
                history.push("/partner/finance/operations?page=1");
                props.onWillDismiss();
              }}
            >
              <div className="text">История</div>
            </div>
          </>
        )}
        {props.element === "feedback" && (
          <>
            <div
              className="cont-el-pop"
              onClick={() => {
                history.push("/partner/feedback/reviews?page=1");
                props.onWillDismiss();
              }}
            >
              <div className="text">Отзывы</div>
            </div>
            <div
              className="cont-el-pop"
              onClick={() => {
                history.push("/partner/feedback/questions?page=1");
                props.onWillDismiss();
              }}
            >
              <div className="text">Вопросы</div>
            </div>
            <div
              className="cont-el-pop"
              onClick={() => {
                history.push("/partner/feedback/faq?page=1");
                props.onWillDismiss();
              }}
            >
              <div className="text">Частые вопросы</div>
            </div>
          </>
        )}
        {props.element === "settings" && (
          <>
            <div
              className="cont-el-pop"
              onClick={() => {
                history.push("/partner/settings/company");
                props.onWillDismiss();
              }}
            >
              <div className="text">Компания</div>
            </div>
            <div
              className="cont-el-pop"
              onClick={() => {
                history.push("/partner/settings/employees?page=1");
                props.onWillDismiss();
              }}
            >
              <div className="text">Сотрудники</div>
            </div>
          </>
        )}
      </div>
    </IonPopover>
  );
};

export default HeaderPopover;
