import { IonContent } from "@ionic/react";
import { setModalLogin, setOpenQuickView, setProductsClients } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Rating from "../../ui/Rating";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import noImage from "../../image/no-image.svg";
import Badge from "../../ui/Badge";
import { getProductsClient, getProductsClientByID, postFavorite } from "../../utils/api";
import Footer from "../../ui/Footer";
import { useHistory } from "react-router";
import { countProm } from "../../utils/utils";
import { URL_ORIGIN } from "../../utils/config";
import star from "../../image/icons/stars.png";

const ClientFavorite = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.productsClients);

  products?.forEach((el) => {
    let revs = el?.clients_reviews?.filter((q) => q.type === 0 && !q.parent_id);
    el.length_reviews = revs?.length;
  });

  const popular = products?.slice(0, 6).sort(function (a, b) {
    return b.length_reviews - a.length_reviews;
  });
  const favorite = products?.filter((el) => el?.favorite?.[0]?.favorite === 1);
  const token = useSelector((state) => state.token);

  let today = new Date().getTime();

  return (
    <IonContent className="page-guest">
      <div className="favorite-cont">
        <div className="favorite-body">
          {favorite?.length ? (
            <>
              <div className="title-fav">Избранное</div>
              <div className="favotite">
                {favorite?.map((el, i) => {
                  let today = new Date().getTime();

                  let arrayProm = el?.operator_promotions?.filter((e) => e.type == 0);
                  arrayProm = arrayProm?.filter(
                    (e) =>
                      today >= new Date(e.start_date).getTime() &&
                      today <= new Date(e.endDate).getTime()
                  );

                  return (
                    <div key={i} className="cont-fav-tour">
                      <div className="image">
                        <img src={el?.photo ? `${URL_ORIGIN}${el?.photo}` : noImage} />
                        <div className="cont-val-images">
                          {el?.pictures?.length ? `+${el?.pictures?.length}` : "0"}
                        </div>
                      </div>
                      <div className="info-tour">
                        <div className="cont-inf">
                          <div className="cont-rating">
                            <Rating rate={el?.rating?.[0]?.avg ? el?.rating?.[0]?.avg : 0} />
                            <div className="val-rate">
                              {el?.rating?.[0]?.avg ? el?.rating?.[0]?.avg : 0}
                            </div>
                            <div className="reviews">{`${el?.length_reviews} отзывов`}</div>
                          </div>
                          <div className="title">{el?.name}</div>
                        </div>
                        <div className="cont-genres">
                          {el?.genres?.map((q, i) => {
                            return <Badge key={i}>{q.name}</Badge>;
                          })}
                        </div>
                      </div>
                      <div className="summa-body">
                        <div className="summa-cont">
                          <div className="row-price">
                            {arrayProm?.length > 0 ? (
                              <div className="last-price">{`${String(+el?.summa).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                " "
                              )}`}</div>
                            ) : undefined}
                            <div className={`summa ${arrayProm?.length > 0 ? "promotion" : ""}`}>
                              {arrayProm?.length > 0
                                ? `${String(countProm(arrayProm, el?.summa)).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    " "
                                  )} ₽`
                                : `${String(+el?.summa).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}
                            </div>
                          </div>
                          {/* <div className="summa">{`${String(+el?.summa).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            " "
                          )} ₽`}</div> */}
                          <div className="duration">{`/ ${el?.duration?.name}`}</div>
                        </div>
                        <div className="buttonsdiv">
                          <Button
                            typebtn="muted"
                            className="medium fav-btn"
                            onClick={() => {
                              if (token) {
                                postFavorite(token, el?.id, "delete").then(async (e) => {
                                  if (!e.data.errors) {
                                    await getProductsClientByID(token, el?.id).then((e) => {
                                      if (!e.data.errors) {
                                        dispatch(
                                          setOpenQuickView({ open: false, el: e.data.data })
                                        );
                                      }
                                    });
                                    await getProductsClient(token).then((e) => {
                                      dispatch(setProductsClients(e.data.data));
                                    });
                                  }
                                });
                              }
                            }}
                          >
                            <Icons icon="heart-filled" />В избранном
                          </Button>
                          <Button
                            typebtn="bright"
                            className="medium fav-btn"
                            onClick={() => {
                              history.push(`/tour?id=${el?.id}`);
                            }}
                          >
                            Купить
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="l" />
            </>
          ) : undefined}
          <div className="title-most">
            <div className="text">Популярные туры</div>
            <Button typebtn="outline" className="small" onClick={() => history.push("/")}>
              Все туры
              <Icons icon="arrow-narrow-right" />
            </Button>
          </div>
          <div className="cont-tours-most-popular">
            {popular?.map((el, i) => {
              let arrayProm = el?.operator_promotions?.filter((e) => e.type == 0);
              arrayProm = arrayProm?.filter(
                (e) =>
                  today >= new Date(e.start_date).getTime() &&
                  today <= new Date(e.endDate).getTime()
              );

              return (
                <div
                  key={i}
                  className="tour"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`tour/?id=${el?.id}`);
                  }}
                >
                  <div className="image">
                    <img src={el?.photo ? `${URL_ORIGIN}${el?.photo}` : noImage} />
                    <div className="cont-val-images">
                      {el?.pictures?.length ? `+${el?.pictures?.length}` : "0"}
                    </div>
                  </div>
                  <div className="info">
                    <div className="cont-title">
                      <div className="rating-cont">
                        {/* <Rating rate={el?.rating?.[0]?.avg ? el?.rating?.[0]?.avg : 0} /> */}
                        <img src={star} />
                        <div className="val-rate">
                          {el?.rating?.[0]?.avg ? el?.rating?.[0]?.avg : 0}
                        </div>
                        <div className="reviews">{`${el?.length_reviews} отзывов`}</div>
                      </div>
                      <div className="title">{el?.name}</div>
                    </div>
                    <div className="summa-cont">
                      <div className="summa-disc">
                        {" "}
                        {arrayProm?.length > 0 ? (
                          <div className="last-price">{`${String(+el?.summa).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            " "
                          )}`}</div>
                        ) : undefined}
                      </div>
                      <div className="sum">
                        <div className="left">
                          <div className={`summa ${arrayProm?.length > 0 ? "promotion" : ""}`}>
                            {arrayProm?.length > 0
                              ? `${String(countProm(arrayProm, el?.summa)).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  " "
                                )} ₽`
                              : `${String(+el?.summa).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}
                          </div>
                          {/* <div className="summa">{`${String(+el?.summa).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            " "
                          )} ₽`}</div> */}
                          <div className="duration">{`/ ${el?.duration?.name}`}</div>
                        </div>
                        <Button
                          typebtn="ghost"
                          className={`small cube ${el?.favorite?.[0]?.favorite ? "btn-fav" : ""}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (token) {
                              if (el?.favorite?.[0]?.favorite) {
                                postFavorite(token, el?.id, "delete").then(async (e) => {
                                  if (!e.data.errors) {
                                    await getProductsClientByID(token, el?.id).then((e) => {
                                      if (!e.data.errors) {
                                        dispatch(
                                          setOpenQuickView({ open: false, el: e.data.data })
                                        );
                                      }
                                    });
                                    await getProductsClient(token).then((e) => {
                                      dispatch(setProductsClients(e.data.data));
                                    });
                                  }
                                });
                              } else {
                                postFavorite(token, el?.id, "add").then(async (e) => {
                                  if (!e.data.errors) {
                                    await getProductsClientByID(token, el?.id).then((e) => {
                                      if (!e.data.errors) {
                                        dispatch(
                                          setOpenQuickView({ open: false, el: e.data.data })
                                        );
                                      }
                                    });
                                    await getProductsClient(token).then((e) => {
                                      dispatch(setProductsClients(e.data.data));
                                    });
                                  }
                                });
                              }
                            } else {
                              dispatch(setModalLogin({ open: true }));
                            }
                          }}
                        >
                          <Icons icon={el?.favorite?.[0]?.favorite ? "heart-filled" : "heart"} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Footer />
      </div>
    </IonContent>
  );
};
export default ClientFavorite;
