import "../../less/css/clientpage.css";
import Button from "../../ui/Button";
import { setModalLogin, setUser } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { IonMenuToggle } from "@ionic/react";
import logo from "../../image/logo-no-cut.svg";
import { useHistory } from "react-router";
import Icons from "../../ui/Icons";
import { useEffect, useState } from "react";
import HeaderPopover from "../../ui/HeaderPopover";
import { getClient } from "../../utils/api";

const HeaderPageClient = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [popoverHeader, setPopoverHeader] = useState({ isOpen: false, elem: null });

  return (
    <>
      <div className="header-pages-client">
        <div className="cont-header-btn">
          <div className="left">
            <div className="lef">
              <IonMenuToggle className="btn-menu-left">
                <Icons icon="menu" className="icon-outline" />
              </IonMenuToggle>
              <img
                src={logo}
                onClick={() => {
                  history.push("/");
                  // window.location.reload();
                }}
              ></img>
            </div>
            {!user?.operator_user && (
              <Button
                typebtn="outline"
                className="medium"
                onClick={() => {
                  history.push("/client");
                }}
              >
                мои туры
              </Button>
            )}
          </div>
          <div className="right">
            <Button
              className="favourite medium"
              typebtn="outline"
              onClick={() => history.push("/favorite")}
            >
              <Icons icon="heart" className="icon-elevated" />
            </Button>
            <Button
              className="cube medium"
              typebtn="outline"
              id={user?.operator_user ? `bottom-start-user` : `bottom-start-user-client`}
              onClick={() => {
                setPopoverHeader({
                  isOpen: true,
                  elem: user?.operator_user ? "user" : "user-client",
                });
              }}
            >
              <Icons icon="user" />
            </Button>
            <div id="bottom-start-null"></div>
          </div>
        </div>
      </div>
      <HeaderPopover
        class={`popover-header-el-list ${popoverHeader.elem}`}
        isOpen={popoverHeader.isOpen}
        onWillDismiss={() => setPopoverHeader({ isOpen: false, elem: null })}
        trigger={popoverHeader !== false ? `bottom-start-${popoverHeader.elem}` : undefined}
        side="bottom"
        alignment="start"
        element={popoverHeader.elem}
      />
    </>
  );
};

export default HeaderPageClient;
