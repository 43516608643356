import { IonModal } from "@ionic/react";
import { useState } from "react";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { getProductsClientByID, getProductsGuestByID, postReviews } from "../../utils/api";
import qs from "qs";
import { useHistory } from "react-router";
import noImage from "../../image/no-image-s.svg";
import star from "../../image/icons/stars.png";
import AlertBox from "../../ui/AlertBox";
import { useDispatch, useSelector } from "react-redux";
import { setArrayPointsTour, setClientTour, setOpenModalCreateQuestion } from "../../store/actions";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import Close from "../../ui/Close";
import Toast from "../../ui/Toast";
import { URL_ORIGIN } from "../../utils/config";

const ClientModalCreateQuestion = (props) => {
  const modalCreateQuestion = useSelector((state) => state.openModalCreateQuestion);
  const element = useSelector((state) => state.clientTour);
  const token = useSelector((state) => state.token);
  const history = useHistory();
  const dispatch = useDispatch();
  const urlSearch = qs.parse(history.location.search.substr(1));
  const reviews = element?.clients_reviews?.filter((el) => el.type === 0 && !el.parent_id);
  const [question, setQuestion] = useState();
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });

  const onAsk = async () => {
    let forSend = {
      type: 1,
      product_id: element?.id,
      review_text: question,
      status_id: 1,
    };
    await postReviews(token, forSend)
      .then((e) => {
        if (!e.data.errors) {
          setOpenToast(true);
          setMessageToast({ text: "Вопрос создан", type: "success" });
          dispatch(setOpenModalCreateQuestion(false));
          if (token) {
            getProductsClientByID(token, element?.id).then((e) => {
              if (!e.data.errors) {
                dispatch(setClientTour(e.data.data));
                dispatch(setArrayPointsTour(e.data.data.details));
              }
            });
          } else {
            getProductsGuestByID(element?.id).then((e) => {
              if (!e.data.errors) {
                dispatch(setClientTour(e.data.data));
                dispatch(setArrayPointsTour(e.data.data.details));
              }
            });
          }
        } else {
          setOpenToast(true);
          setMessageToast({
            text: e.data?.error?.[0] || "Ошибка создания вопроса",
            type: "error",
          });
        }
      })
      .catch((e) => {
        setOpenToast(true);
        setMessageToast({
          text: e.response.data?.error?.[0] || "Ошибка создания вопроса",
          type: "error",
        });
      });
  };
  return (
    <>
      <IonModal
        className="modal-create-question"
        isOpen={modalCreateQuestion}
        onWillDismiss={() => dispatch(setOpenModalCreateQuestion(false))}
      >
        <Close onClick={() => dispatch(setOpenModalCreateQuestion(false))} />
        <div className="cont-create-quest">
          <div className="title">Задать вопрос</div>
          <div className="info-tour">
            <div className="image">
              <img src={element?.photo ? `${URL_ORIGIN}${element?.photo}` : noImage} />
            </div>
            <div className="inf-cont">
              <div className="rate-cont">
                <img src={star} />
                <div className="val">
                  {element?.rating?.[0]?.avg ? element?.rating?.[0]?.avg : 0}
                </div>
                <div className="reviews">{`${reviews?.length} отзывов`}</div>
              </div>
              <div className="text">{element?.name}</div>
            </div>
          </div>
          <Input
            placeholder="Ваш вопрос..."
            noImage
            className="large area area-quest"
            type="textarea"
            onChange={(e) => {
              setQuestion(e);
            }}
          />
          <AlertBox type="info" text="Отзыв будет опубликован после модерации." noClose />
          <div className="btns">
            <Button
              typebtn="outline"
              className="small"
              onClick={() => dispatch(setOpenModalCreateQuestion(false))}
            >
              Отмена
            </Button>
            <Button
              typebtn="bright"
              className="small"
              onClick={() => {
                onAsk();
              }}
            >
              Отправить
            </Button>
          </div>
        </div>
      </IonModal>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
    </>
  );
};

export default ClientModalCreateQuestion;
