import { useSelector } from "react-redux";
import PreviewTour from "../../ui/PreviewTour";
import HeaderPagesGuest from "./HeaderPagesGuest";
import Icons from "../../ui/Icons";
import Button from "../../ui/Button";
import { IonMenuToggle } from "@ionic/react";
import { useMemo } from "react";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";

const ListToursMobile = (props) => {
  return (
    <div className="list-tour-mobile">
      <HeaderPagesGuest />
      <div className="scroll">
        {props.products?.map((el, i) => {
          return (
            <PreviewTour
              closeMobile={props.setOpenListMobile}
              key={i}
              el={el}
              type="tours"
              maps={props.maps}
              setMaps={props.setMaps}
            />
          );
        })}
      </div>
      <div className="btns">
        <IonMenuToggle className="btn-menu-left" menu="menu-left-filter">
          <Button className="small" typebtn="outline">
            <Icons icon="filter" />
            Фильтры
          </Button>
        </IonMenuToggle>
        <Button
          className="small"
          typebtn="outline"
          onClick={() => {
            props.setOpenListMobile(false);
          }}
        >
          <Icons icon="map" />
          На карте
        </Button>
      </div>
    </div>
  );
};

export default ListToursMobile;
