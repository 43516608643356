import { IonModal } from "@ionic/react";
import Close from "../../ui/Close";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import Checkbox from "../../ui/Checkbox";
import { useState } from "react";
import AlertBox from "../../ui/AlertBox";
import { useDispatch, useSelector } from "react-redux";
import Icons from "../../ui/Icons";
import { setOpenModalWithdraw } from "../../store/actions";

const ModalWithdraw = (props) => {
  const dispatch = useDispatch();
  const operator = useSelector((state) => state.userInfo.clientInfo.operator);
  const openModalWithdraw = useSelector((state) => state.openModalWithdraw);

  const [page, setPage] = useState(1);
  const [stage, setStage] = useState(1);

  const [checked, setChecked] = useState(false);
  return (
    <IonModal
      className="modal-withdraw"
      isOpen={openModalWithdraw}
      onWillDismiss={() => {
        dispatch(setOpenModalWithdraw(false));
        setPage(1);
        setStage(1);
      }}
    >
      <Close
        onClick={() => {
          dispatch(setOpenModalWithdraw(false));

          setPage(1);
          setStage(1);
        }}
      />
      {stage === 1 ? (
        <div className="body-modal-withdraw">
          <div className="title">Вывод средств</div>
          <div className="infor">
            <div className="header-type">
              <Checkbox
                name="radio"
                checked={page === 1}
                position="center width"
                className="text-checkbox"
                type="radio"
                onChange={() => setPage(1)}
              >
                На расч. счёт
              </Checkbox>
              <Checkbox
                name="radio"
                checked={page === 2}
                position="center width"
                type="radio"
                className="text-checkbox"
                onChange={() => setPage(2)}
              >
                Пополнить депозит
              </Checkbox>
            </div>
            <div className="l"></div>
            {page === 1 && (
              <div className="cont-page-1">
                <Checkbox
                  name="deposit"
                  position="center"
                  className="text-checkbox"
                  onChange={(e) => setChecked(e.target.checked)}
                >
                  Разрешить снятие с депозита
                </Checkbox>
                {checked && (
                  <AlertBox
                    type="warning"
                    text="Если на депозите останется меньше чем 50 000 ₽, то все ваши туры будут переведены в архив."
                    noClose
                  />
                )}
                {/* <AlertBox
                type="warning"
                text="Снятие с депозита невозможно, пока сумма авансовых платежей больше нуля."
                noClose
              /> */}
                <div className="available">{`Доступно: 30000 ₽`}</div>
                <div className="cont">
                  <Input className="medium" npImage placeholder="сумма..." />
                  <div className="subtext">Минимум 2000 ₽</div>
                </div>
              </div>
            )}
            {page === 2 && (
              <div className="cont-page-1">
                <div className="available">{`Доступно: 30000 ₽`}</div>
                <div className="cont">
                  <Input className="medium" npImage placeholder="сумма..." />
                  <div className="subtext">Минимум 2000 ₽</div>
                </div>
              </div>
            )}
          </div>
          <div className="btns">
            <Button
              typebtn="outline"
              className="small"
              onClick={() => {
                dispatch(setOpenModalWithdraw(false));
                setPage(1);
                setStage(1);
              }}
            >
              Отмена
            </Button>
            <Button
              typebtn="muted"
              className="small"
              onClick={() => {
                setStage(2);
              }}
            >
              Продолжить
            </Button>
          </div>
        </div>
      ) : stage === 2 ? (
        <div className="body-modal-withdraw">
          <div className="title">Подтвердите данные</div>
          {page === 1 && (
            <div className="info-confirm">
              <div className="line">
                <div className="subtext">Полное наименование</div>
                <div className="text">{operator?.legal_name ?? "-"}</div>
              </div>
              <div className="line">
                <div className="subtext">Юр. адрес</div>
                <div className="text">{operator?.address_legal ?? "-"}</div>
              </div>
              <div className="line">
                <div className="subtext">ИНН</div>
                <div className="text">{operator?.inn ?? "-"}</div>
              </div>
              <div className="line">
                <div className="subtext">КПП</div>
                <div className="text">{operator?.kpp ?? "-"}</div>
              </div>
              <div className="line">
                <div className="subtext">Р./счёт</div>
                <div className="text">{operator?.settlement_account ?? "-"}</div>
              </div>
              <div className="line">
                <div className="subtext">БИК</div>
                <div className="text">{operator?.bic ?? "-"}</div>
              </div>
              <div className="line">
                <div className="subtext">Банк</div>
                <div className="text">{operator?.bank_name ?? "-"}</div>
              </div>
              <div className="line">
                <div className="subtext">ФИО ген. директора</div>
                <div className="text">{operator?.manager_name ?? "-"}</div>
              </div>
            </div>
          )}
          {page === 1 && <div className="l"></div>}
          {page === 1 && (
            <AlertBox
              type="info"
              text="Деньги будут перечислены по указанным реквизитам в течение 5 рабочих дней. Вы получите уведомление на email по результату."
              noClose
            />
          )}
          {page === 2 && (
            <AlertBox
              type="info"
              text="Деньги будут перечислены на депозит вашего аккаунта моментально."
              noClose
            />
          )}
          <div className="available">{`Сумма: 30000 ₽`}</div>
          <div className="btns">
            <Button
              typebtn="outline"
              className="small"
              onClick={() => {
                setStage(1);
              }}
            >
              <Icons icon="arrow-narrow-left" />
              Отмена
            </Button>
            <Button
              typebtn="bright"
              className="small"
              onClick={() => {
                setStage(3);
              }}
            >
              Подтвердить
            </Button>
          </div>
        </div>
      ) : (
        stage === 3 && (
          <div className="body-modal-withdraw">
            <div className="title">Запрос на выплату отправлен.</div>
            <div className="text-send-req">
              Запросы на выплату обрабатываются вручную в течение 5 рабочих дней. Вы получите
              уведомление на email после подтверждения операции с нашей стороны.
            </div>
            <div className="btns">
              <Button
                typebtn="outline"
                className="small"
                onClick={() => {
                  dispatch(setOpenModalWithdraw(false));

                  setPage(1);
                  setStage(1);
                }}
              >
                Закрыть
              </Button>
            </div>
          </div>
        )
      )}
    </IonModal>
  );
};

export default ModalWithdraw;
