import { useHistory, useLocation } from "react-router";
import "../less/css/firstpage.css";
import HeaderFirstPage from "../components/firstpage/HeaderFirstPage";
import { IonContent, IonMenuToggle, IonPage } from "@ionic/react";
import LeftMenu from "../components/firstpage/LeftMenu";
import LeftMenuTours from "../components/firstpage/LeftMenuTours";
import MapOl from "../components/map/MapOl";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "../components/firstpage/LoginModal";
import AccessRecoveryModal from "../components/firstpage/AccessRecoveryModal";
import LoginModalPartner from "../components/firstpage/LoginModalPartner";
import ModalGreetings from "../components/firstpage/ModalGreetings";
import { useEffect, useRef, useState } from "react";
import {
  setArrayPointsTour,
  setClasses,
  setFilterFirstPage,
  setFormat,
  setGenre,
  setModalGreetings,
  setModalRecovery,
  setMyCoor,
  setOpenQuickView,
  setProductsClients,
  setSuitable,
  setUser,
} from "../store/actions";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import {
  getClassListClient,
  getClient,
  getFormatListClient,
  getGenreListClient,
  getProductsClient,
  getProductsClientByID,
  getProductsGuest,
  getProductsGuestByID,
  getSuitableListClient,
} from "../utils/api";
import { Geolocation } from "@capacitor/geolocation";
import QuickViewTour from "../components/firstpage/QuickViewTour";
import "../less/css/custom.css";
import MapQuickShowTourClient from "../components/firstpage/MapQuickShowTourClient";
import qs from "qs";
import SearchTourMobile from "../components/firstpage/SearchTourMobile";
import Icons from "../ui/Icons";
import Button from "../ui/Button";
import ListToursMobile from "../components/firstpage/ListToursMobile";

const FirstPage = (props) => {
  const location = useLocation();
  const token = useSelector((state) => state.token);
  const [maps, setMaps] = useState();
  const [nmapis, setNmapis] = useState();
  const [circle, setCircle] = useState();
  const history = useHistory();
  const [mapsC, setMapsC] = useState();
  const [openMobileSearch, setOpenMobileSearch] = useState(false);
  const [openListMobile, setOpenListMobile] = useState(false);
  const user = useSelector((state) => state.user);
  const urlSearch = qs.parse(history.location.search.substr(1));
  const products = useSelector((state) => state.productsClients);

  const filterProducts = useSelector((state) => state.filterFirstPage);

  const [filterArray, setFilterArray] = useState();
  const [searchPatternList, setSearchPatternList] = useState();

  const arrayCity = useSelector((state) => state.arrayMapCity);
  const valCity = useSelector((state) => state.valCity);
  const dispatch = useDispatch();
  const openQuickView = useSelector((state) => state.openQuickView);
  const myCoor = useSelector((state) => state.myCoor);
  useAsyncEffect(async () => {
    const coordinates = await Geolocation.getCurrentPosition();
    dispatch(setMyCoor(coordinates));
  }, []);

  useAsyncEffect(() => {
    setFilterArray(products);
    dispatch(setFilterFirstPage(products));
  }, [products]);

  useAsyncEffect(async () => {
    if (token) {
      getProductsClient(token).then((e) => {
        dispatch(setProductsClients(e.data.data));
      });
    } else {
      getProductsGuest().then((e) => {
        dispatch(setProductsClients(e.data.data));
      });
    }
    getFormatListClient().then((e) => {
      dispatch(setFormat(e.data.data));
    });
    getSuitableListClient().then((e) => {
      dispatch(setSuitable(e.data.data));
    });
    getClassListClient().then((e) => {
      dispatch(setClasses(e.data.data));
    });
    getGenreListClient().then((e) => {
      dispatch(setGenre(e.data.data));
    });

    // props.clientAero
    //   .auth()
    //   .then((response) => console.log(response))
    //   .catch((error) => console.error(error));
  }, [token]);

  const arrayDistance = [
    { name: "150 км", val: 150000, zoom: 7.75 },
    { name: "100 км", val: 100000, zoom: 8.5 },
    { name: "50 км", val: 50000, zoom: 9.5 },
  ];

  const [valDistance, setValDistance] = useState(arrayDistance[0]);
  useEffect(() => {
    if (localStorage.getItem("greetings") !== "false") {
      dispatch(setModalGreetings(true));
    }
    if (urlSearch.id) {
      if (token) {
        getProductsClientByID(token, urlSearch?.id).then((e) => {
          if (!e.data.errors) {
            if (e.data.data?.length === 0) {
              dispatch(setOpenQuickView({ open: false, el: null }));
              history.push("/");
            } else {
              // dispatch(setArrayPointsTour(e.data.data.details));
              dispatch(setOpenQuickView({ open: true, el: e.data.data }));
            }
          }
        });
      } else {
        getProductsGuestByID(urlSearch?.id).then((e) => {
          if (!e.data.errors) {
            if (e.data.data?.length === 0) {
              dispatch(setOpenQuickView({ open: false, el: null }));
              history.push("/");
            } else {
              // dispatch(setArrayPointsTour(e.data.data.details));
              dispatch(setOpenQuickView({ open: true, el: e.data.data }));
            }
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    if (urlSearch?.token_recovery) {
      dispatch(setModalRecovery({ open: true, client: true }));
    } else if (urlSearch?.id) {
      history.push(`?id=${+urlSearch?.id}`);
    } else {
      history.push("/");
    }
  }, []);
  return (
    <>
      <IonPage>
        <IonContent id="main-content">
          <div className="first-page">
            <HeaderFirstPage
              valCity={valCity}
              valDistance={valDistance}
              setValDistance={setValDistance}
              arrayDistance={arrayDistance}
              arrayCity={arrayCity}
              openQuickView={openQuickView}
              openMobileSearch={openMobileSearch}
              setOpenMobileSearch={setOpenMobileSearch}
            />
            <LeftMenuTours
              filterArray={filterArray}
              myCoor={myCoor}
              maps={maps}
              setMaps={setMaps}
              nmapis={nmapis}
              arrayCity={arrayCity}
              circle={circle}
              valDistance={valDistance}
              setSearchPattern={setSearchPatternList}
              searchPattern={searchPatternList}
            />
            {openMobileSearch && (
              <SearchTourMobile
                myCoor={myCoor}
                searchPattern={searchPatternList}
                setOpenMobileSearch={setOpenMobileSearch}
                setSearchPattern={setSearchPatternList}
                maps={maps}
                setMaps={setMaps}
              />
            )}
            {openListMobile && (
              <ListToursMobile
                maps={maps}
                products={filterProducts}
                setMaps={setMaps}
                setOpenListMobile={setOpenListMobile}
                setSearchPattern={setSearchPatternList}
                searchPattern={searchPatternList}
              />
            )}
            {openQuickView.open ? (
              <div className="quick-s">
                <QuickViewTour mapsC={mapsC} setMapsC={setMapsC} />
                <MapQuickShowTourClient setMaps={setMapsC} maps={mapsC} />
              </div>
            ) : (
              <MapOl
                searchPatternList={searchPatternList}
                circle={circle}
                setCircle={setCircle}
                setMaps={setMaps}
                maps={maps}
                setNmapis={setNmapis}
                nmapis={nmapis}
                arrayCity={arrayCity}
                valDistance={valDistance}
                myCoor={myCoor}
              />
            )}
            <div className="bottom-menu">
              <IonMenuToggle className="btn-menu-left" menu="menu-left-filter">
                <Button typebtn="elevated" className="medium">
                  <Icons icon="filter" />
                  Фильтры
                </Button>
              </IonMenuToggle>

              <Button
                typebtn="elevated"
                className="list medium"
                onClick={() => {
                  setOpenListMobile(true);
                }}
              >
                <Icons icon="list-details" />
                Списком
              </Button>
            </div>
          </div>
        </IonContent>
      </IonPage>
      <ModalGreetings />
    </>
  );
};

export default FirstPage;
