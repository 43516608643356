import { IonContent } from "@ionic/react";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import { useHistory } from "react-router";
import qs from "qs";
import curStar from "../../image/rating-star/rating-star-cur.svg";
import uncurStar from "../../image/rating-star/rating-star-uncur.svg";
import { Rating } from "primereact/rating";
import { useEffect, useState } from "react";
import Footer from "../../ui/Footer";
import Input from "../../ui/Input";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import {
  getProductsClientByID,
  getProductsGuestByID,
  postImageReviews,
  postReviews,
} from "../../utils/api";
import noImage from "../../image/no-image-s.svg";
import star from "../../image/icons/stars.png";
import AlertBox from "../../ui/AlertBox";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../ui/Toast";
import { setClientTour } from "../../store/actions";
import ModalDelete from "../showcase/Modals/ModalDelete";
import { URL_ORIGIN } from "../../utils/config";

const ClientReview = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const urlSearch = qs.parse(history.location.search.substr(1));
  const [rate, setRate] = useState();
  const [ratePoints, setRatePoints] = useState([]);
  const token = useSelector((state) => state.token);
  const [photoRev, setPhotoRev] = useState();
  const [photoRevPoints, setPhotoRevPoints] = useState([]);
  const [modalDeleteOperUser, setModalDeleteOperUser] = useState(false);
  const [typePhoto, setTypePhoto] = useState();

  const [qwe, setQwe] = useState();
  const [qweP, setQweP] = useState();
  const [textMainReviewm, setTextmainReview] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setQwe(null);
    setQweP(null);
  }, [qwe, qweP]);
  const [element, setElement] = useState();
  const reviews = element?.clients_reviews?.filter((el) => el.type === 0 && !el.parent_id);
  const [points, setPoints] = useState();

  useAsyncEffect(() => {
    if (token) {
      if (urlSearch?.order_id) {
        getOrdersClientByID(token, urlSearch.order).then((e) => {
          if (!e.data.errors) {
            dispatch(setClientTour(e.data.data.product));
            dispatch(setArrayPointsTour(e.data.data.product.details));
          }
        });
      } else {
        getProductsClientByID(token, urlSearch?.id).then((e) => {
          if (!e.data.errors) {
            setElement(e.data.data);
            let details = e.data.data.details?.sort(function (a, b) {
              return a.order - b.order;
            });
            details?.forEach((el, index) => {
              el.num = index + 1;
            });
            setPoints(details);
          }
        });
      }
    }
  }, []);

  const onSend = async () => {
    setLoading(true);
    let forSend = {
      type: 0,
      tour_assessment: rate,
      product_id: element?.id,
      review_text: textMainReviewm,
      status_id: 1,
    };
    await postReviews(token, forSend)
      .then(async (e) => {
        if (!e.data.errors) {
          const formDataRevImage = new FormData();

          photoRev &&
            Object.entries(photoRev).forEach(([key, value]) => {
              formDataRevImage.append(`review_pictures[]`, value);
            });
          photoRev &&
            (await postImageReviews(token, formDataRevImage, e.data.order.id).then((e) => {
              if (!e.data.errors) {
                setLoading(false);
              } else {
                setLoading(false);
              }
            }));
          // console.log("ratePoints", ratePoints);
          // if (ratePoints?.length) {
          //   for (let i = 0; i < ratePoints?.length; i++) {
          //     let fors = {
          //       type: 0,
          //       product_id: element?.id,
          //       status_id: 1,
          //       tour_assessment: ratePoints[i]?.tour_assessment,
          //       review_text: ratePoints[i]?.review_text,
          //       detail_id: ratePoints[i]?.detail_id,
          //       parent_id: e.data.order?.id,
          //     };
          //     await postReviews(token, fors).then(async (q) => {
          //       if (!q.data.errors) {
          //         for (let j = 0; j < photoRevPoints?.length; j++) {
          //           let formDataPointsImage = new FormData();
          //           photoRevPoints[j]?.photo &&
          //             Object.entries(photoRevPoints[j]?.photo).forEach(([key, value]) => {
          //               formDataPointsImage.append(`review_pictures[]`, value);
          //             });
          //           photoRevPoints[j]?.photo &&
          //             (await postImageReviews(token, formDataPointsImage, q.data.order.id).then(
          //               (e) => {
          //                 if (!e.data.errors) {
          //                   setLoading(false);
          //                 } else {
          //                   setLoading(false);
          //                 }
          //               }
          //             ));
          //         }
          //       }
          //     });
          //   }
          // }

          setLoading(false);
          setOpenToast(true);
          setMessageToast({ text: "Отзыв успешно создан", type: "success" });
          getProductsClientByID(token, urlSearch?.id).then((e) => {
            if (!e.data.errors) {
              dispatch(setClientTour(e.data.data));
            }
          });
          history.push(`/tour?id=${element?.id}`);
        } else {
          setLoading(false);
          setOpenToast(true);
          setMessageToast({
            text: typeof e.data.errors === "string" ? e.data.errors : "Ошибка создания отзыва",
            type: "error",
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        setOpenToast(true);
        setMessageToast({
          text:
            typeof e.response.data.errors === "string"
              ? e.response.data.errors
              : "Ошибка создания отзыва",
          type: "error",
        });
      });
  };
  return (
    <IonContent className="page-guest">
      <div className="page-review">
        <div className="body-rev-page">
          <div className="cont-title">
            <div>
              <Button
                typebtn="outline"
                className="small"
                onClick={() => history.push(`/tour?id=${urlSearch?.id}`)}
              >
                <Icons icon="arrow-narrow-left" />
                все туры
              </Button>
            </div>
            <div className="title">Ваши впечатления:</div>
          </div>
          <div className="create-review-cont">
            <div className="left">
              <div className="review-info">
                <div className="rating-cont">
                  <Rating
                    value={rate}
                    onChange={(e) => setRate(e.value)}
                    cancel={false}
                    onIcon={
                      <img src={curStar} alt="custom-image-active" width="36px" height="36px" />
                    }
                    offIcon={<img src={uncurStar} alt="custom-image" width="36px" height="36px" />}
                  />
                  <div className="val">
                    {rate
                      ? rate === 1
                        ? "Плохо"
                        : rate === 2
                        ? "Неудовлетворительно"
                        : rate === 3
                        ? "Удовлетворительно"
                        : rate === 4
                        ? "Хорошо"
                        : rate === 5 && "Отлично"
                      : "Не указано"}
                  </div>
                </div>
                <Input
                  placeholder="Опишите вашу поездку..."
                  noImage
                  className="large area area-reviews"
                  type="textarea"
                  onChange={(e) => {
                    setTextmainReview(e);
                  }}
                />
                <div className="add-image-cont">
                  {photoRev?.map((z, indx) => {
                    return (
                      <div key={indx} className="cont-image-option">
                        <Button
                          typebtn="elevated"
                          className="small cube btn-del-img"
                          onClick={() => {
                            setTypePhoto({ type: 1, id: indx });
                            setModalDeleteOperUser(true);
                          }}
                        >
                          <Icons icon="x" />
                        </Button>
                        <img className="main-img" src={URL.createObjectURL(z)}></img>
                      </div>
                    );
                  })}
                  <div className="add">
                    <div>
                      <input
                        type="file"
                        name="file"
                        id="file-rev-photo"
                        className="inputfile"
                        accept="image/jpeg, image/png"
                        multiple
                        onChange={(e) => {
                          let arr = [];
                          for (var i = 0; i < e.target.files.length; i++) {
                            arr.push(e.target.files[i]);
                          }
                          if (photoRev)
                            for (var i = 0; i < photoRev.length; i++) {
                              arr.push(photoRev[i]);
                            }
                          setPhotoRev(arr);
                        }}
                      />
                      <label className="image add-img" htmlFor="file-rev-photo">
                        <Icons icon="photo-plus" />
                      </label>
                    </div>
                    <div className="description-add-img">
                      <div className="title">Прикрепить фото</div>
                      <div className="cont-subtext">
                        <div className="subtext">Формат: JPG, PNG.</div>
                        <div className="subtext">Макс. 5МБ.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="create-rev-points">
                <div className="title">Оцените точки маршрута:</div>
                {points?.map((el, i) => {
                  let rating = ratePoints?.find((q) => q.id === i);
                  let photo = photoRevPoints?.find((z) => z?.id === i);
                  return (
                    <div className="point-rev" key={i}>
                      <div className="body-rev">
                        <div className="image">
                          <img
                            src={
                              el?.pictures?.[0]?.photo
                                ? `${URL_ORIGIN}${el?.pictures?.[0]?.photo}`
                                : noImage
                            }
                          />
                        </div>
                        <div className="right-rev">
                          <div className="cont-title">
                            <div className="subtext">{`День ${el?.num}`}</div>
                            <div className="text">{el?.name}</div>
                          </div>
                          <div className="cont-rating">
                            <Rating
                              value={rating?.tour_assessment}
                              onChange={async (e) => {
                                let a = ratePoints;
                                if (a?.find((el) => el?.id === i)) {
                                  a[i] = {
                                    id: i,
                                    detail_id: el?.id,
                                    tour_assessment: e.value,
                                    review_text: "",
                                  };
                                } else {
                                  let ratepoint = {
                                    id: i,
                                    detail_id: el?.id,
                                    tour_assessment: e.value,
                                    review_text: "",
                                  };
                                  a?.push(ratepoint);
                                }
                                setRatePoints(a);
                                setQwe(e.value);
                              }}
                              cancel={false}
                              onIcon={
                                <img
                                  src={curStar}
                                  alt="custom-image-active"
                                  width="36px"
                                  height="36px"
                                />
                              }
                              offIcon={
                                <img
                                  src={uncurStar}
                                  alt="custom-image"
                                  width="36px"
                                  height="36px"
                                />
                              }
                            />
                            <div className="val">
                              {rating?.tour_assessment
                                ? rating?.tour_assessment === 1
                                  ? "Плохо"
                                  : rating?.tour_assessment === 2
                                  ? "Неудовлетворительно"
                                  : rating?.tour_assessment === 3
                                  ? "Удовлетворительно"
                                  : rating?.tour_assessment === 4
                                  ? "Хорошо"
                                  : rating?.tour_assessment === 5 && "Отлично"
                                : "Не указано"}
                            </div>
                          </div>
                          {rating && (
                            <>
                              <div className="input-rev-point">
                                <Input
                                  placeholder="Опишите вашу поездку..."
                                  noImage
                                  className="large area area-reviews-point"
                                  type="textarea"
                                  onChange={(e) => {
                                    let a = ratePoints;
                                    if (a?.find((el) => el?.id === i)) {
                                      a[i] = { ...a[i], review_text: e };
                                    }
                                    setRatePoints(a);
                                  }}
                                />
                              </div>

                              <div className="add-image-cont">
                                {photo?.photo?.map((x, index) => {
                                  return (
                                    <div key={index} className="cont-image-option">
                                      <Button
                                        typebtn="elevated"
                                        className="small cube btn-del-img"
                                        onClick={() => {
                                          setTypePhoto({ type: 2, id_photo: index, id: i });
                                          setModalDeleteOperUser(true);
                                        }}
                                      >
                                        <Icons icon="x" />
                                      </Button>
                                      <img className="main-img" src={URL.createObjectURL(x)}></img>
                                    </div>
                                  );
                                })}
                                <div className="add">
                                  <div>
                                    <input
                                      type="file"
                                      name="file"
                                      id={`file-rev-photo-${i}`}
                                      className="inputfile"
                                      accept="image/jpeg, image/png"
                                      multiple
                                      onChange={(c) => {
                                        let find = photoRevPoints?.find((al) => al?.id === i);
                                        if (find) {
                                          console.log("FIND", find);
                                          let array = photoRevPoints.filter((qw) => qw.id !== i);
                                          console.log(array);
                                          let arr = [];
                                          for (var j = 0; j < c.target.files.length; j++) {
                                            arr.push(c.target.files[j]);
                                          }
                                          arr?.forEach((b) => {
                                            find.photo.push(b);
                                          });
                                          array.push(find);
                                          console.log(array);
                                          setPhotoRevPoints(array);
                                        } else {
                                          let array = photoRevPoints;
                                          let arr = [];
                                          for (var j = 0; j < c.target.files.length; j++) {
                                            arr.push(c.target.files[j]);
                                          }
                                          let a = [];
                                          a.push({ id: i, photo: arr, el: el?.id });
                                          let con = array.concat(a);
                                          setPhotoRevPoints(con);
                                        }
                                        // setQweP(c.target.files[0]);
                                      }}
                                    />
                                    <label
                                      className="image add-img"
                                      htmlFor={`file-rev-photo-${i}`}
                                    >
                                      <Icons icon="photo-plus" />
                                    </label>
                                  </div>
                                  <div className="description-add-img">
                                    <div className="title">Прикрепить фото</div>
                                    <div className="cont-subtext">
                                      <div className="subtext">Формат: JPG, PNG.</div>
                                      <div className="subtext">Макс. 5МБ.</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {i + 1 !== points?.length ? <div className="l" /> : undefined}
                    </div>
                  );
                })}
              </div> */}
            </div>
            <div className="right">
              <div className="cont-send">
                <div className="info-tour">
                  <div className="image">
                    <img src={element?.photo ? `${URL_ORIGIN}${element?.photo}` : noImage} />
                  </div>
                  <div className="inf-cont">
                    <div className="rate-cont">
                      <img src={star} />
                      <div className="val">
                        {element?.rating?.[0]?.avg ? element?.rating?.[0]?.avg : 0}
                      </div>
                      <div className="reviews">{`${reviews?.length} отзывов`}</div>
                    </div>
                    <div className="text">{element?.name}</div>
                  </div>
                </div>
                <AlertBox type="info" text="Отзыв будет опубликован после модерации." noClose />
                <Button
                  disabled={loading || !rate || !textMainReviewm}
                  loading={loading}
                  typebtn="bright"
                  className="large btn-send"
                  onClick={() => {
                    onSend();
                  }}
                >
                  отправить
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
      <ModalDelete
        open={modalDeleteOperUser}
        onClose={setModalDeleteOperUser}
        title="Удаление фото"
        text="Вы действительно хотите удалить фото?"
        btn_delete="Да, удалить"
        onClick={() => {
          if (typePhoto.type === 1) {
            let arr = photoRev;
            arr.splice(typePhoto?.id, 1);
            // setPhotoRev(arr);
          } else if (typePhoto.type === 2) {
            let arr = photoRevPoints;
            let arrEl = photoRevPoints.find((aq) => aq.id === typePhoto?.id);
            let arrPhoto = arrEl.photo;
            arrPhoto?.splice(typePhoto?.id_photo, 1);
            // let push = { id: typePhoto?.id, photo: arrPhoto };
            // arr.filter((fil) => fil.id !== typePhoto?.id);
            // arr.push(push);
            // setPhotoRevPoints(arr);
          }
          setModalDeleteOperUser(false);
        }}
      />
    </IonContent>
  );
};

export default ClientReview;
