import Input from "../../ui/Input";
import Button from "../../ui/Button";
import AlertBox from "../../ui/AlertBox";
import { useState } from "react";
import ModalTourConfirmedOperator from "./ModalTourConfirmedOperator";
import { useDispatch, useSelector } from "react-redux";
import { getOrders, postAdvancePayment } from "../../utils/api";
import { setOrders } from "../../store/actions";

const GetAdvance = () => {
  const [alertReq, setAlertReq] = useState(false);
  const [modalConfirmed, setModalConfirmed] = useState(false);
  const [resultOrder, setResultOrder] = useState();
  const [errorsAvans, setErrorsAvans] = useState();
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  return (
    <>
      {/* {alertReq && (
        <AlertBox
          noClose
          className="alert-summary"
          type="info"
          title="Запрос принят"
          text="Запрос от **.**.** на вывод ** *** ₽ в работе. Вы получите email по результату."
        />
      )} */}
      <div className="get-advance-cont">
        <form
          className="cont-input-get-advance"
          onSubmit={async (e) => {
            e.preventDefault();
            // setLoading(true);
            let data = {};
            let inputs = e.target.querySelectorAll("input");
            inputs.forEach((input) => (data[input.name] = input.value ? input.value : null));
            let forSend = {
              payment_code: data.payment_code,
            };
            if (data.payment_code && data.order_id) {
              postAdvancePayment(token, data.order_id, forSend)
                .then((e) => {
                  setModalConfirmed(true);
                  setResultOrder(e.data.data);
                  getOrders(token).then((e) => {
                    dispatch(setOrders(e.data.data));
                  });
                })
                .catch((e) => {
                  setErrorsAvans(e.response.data.errors);
                });
            } else {
              setErrorsAvans("Поля не заполнены!");
            }
          }}
        >
          <Input
            placeholder="номер заказа"
            className="input-get-advance-number medium"
            noImage
            name="order_id"
          />
          <Input
            placeholder="код"
            className="input-get-advance-code medium"
            noImage
            name="payment_code"
          />
          <Button type="sumbit" typebtn="bright" className="btn-get-advance medium">
            получить аванс
          </Button>
        </form>
        {errorsAvans && <div className="alert">{errorsAvans}</div>}
      </div>
      <ModalTourConfirmedOperator
        onClose={setModalConfirmed}
        open={modalConfirmed}
        result={resultOrder}
        setResultOrder={setResultOrder}
      />
    </>
  );
};

export default GetAdvance;
