import { IonContent } from "@ionic/react";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import Status from "../../ui/Status";
import { useHistory } from "react-router";
import AlertBox from "../../ui/AlertBox";
import { useEffect, useState } from "react";
import ModalCancelOrder from "./ModalCancelOrder";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { useSelector } from "react-redux";
import { getOrdersByID } from "../../utils/api";
import qs from "qs";
import { createDefaultMaskGenerator, mask } from "react-hook-mask";
import ExcelJS from "exceljs";

const OrderDetails = () => {
  const history = useHistory();
  const [openModalCancelOrder, setOpenModalCancelOrder] = useState(false);
  const [orderInformation, setOrderInformation] = useState();
  const [openAlert, setOpenAlert] = useState(true);
  const token = useSelector((state) => state.token);
  const urlSearch = qs.parse(history.location?.search.substr(1));
  const maskGenerator = createDefaultMaskGenerator("+9 (999) 999-99-99");
  const operator = useSelector((state) => state.operator);

  useAsyncEffect(async () => {
    await getOrdersByID(token, urlSearch.id).then((e) => {
      if (!e.data.errors) {
        setOrderInformation(e.data.data);
      }
    });
  }, []);

  const exportToExcel = () => {
    let array = [];

    let filename = `Заказ №${orderInformation?.id} ${new Date().toLocaleDateString("ru")}`;
    let operator_options = "";
    for (let j = 0; j < orderInformation?.operator_options.length; j++) {
      operator_options += `${orderInformation?.operator_options[j]?.option?.name}, `;
    }
    let members = "";
    for (let j = 0; j < orderInformation?.participants.length; j++) {
      members += `${orderInformation?.participants[j]?.last_name ?? ""} ${
        orderInformation?.participants[j]?.first_name ?? ""
      }, `;
    }
    let forPay = (
      (+orderInformation?.summa / 100) *
      (100 - +orderInformation?.commission_value)
    )?.toFixed(2);
    let new_array = {
      Номер: orderInformation?.id,
      "Дата создания заказа": orderInformation?.created_at,
      Клиент: `${orderInformation?.client?.user?.last_name || ""} ${
        orderInformation?.client?.user?.first_name || ""
      } ${orderInformation?.client?.user?.middle_name || ""}`,
      "Название тура": orderInformation?.product?.name,
      "Начало заезда": orderInformation?.race?.start_date || "",
      "Завершение заезда": orderInformation?.race?.end_date || "",
      Оплачено: +orderInformation?.summa,
      Комиссия: +orderInformation?.commission_value,
      Штраф: +orderInformation?.penalty[0]?.penalty || 0,
      "К выплате": forPay <= 0 ? 0 : forPay,
      "Количество участников": orderInformation?.participants.length,
      Участники: members,
      "Стоимость тура": +orderInformation?.summa,
      Опции: operator_options,
      "Кто отменил": `${orderInformation?.cancel_user?.last_name || ""} ${
        orderInformation?.cancel_user?.first_name || ""
      } ${orderInformation?.cancel_user?.middle_name || ""}`,
      Статус: orderInformation?.status.description,
    };
    array.push(new_array);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("User Data");
    const headerRow = worksheet.addRow([]);
    const headerCells = Object.values(array);
    for (let i = 0; i < headerCells?.length; i++) {
      const column = Object.keys(headerCells[i]);
      for (let j = 0; j < column?.length; j++) {
        headerRow.getCell(j + 1).value = column[j];
      }
    }
    const rows = Object.values(array);
    for (let i = 0; i < rows?.length; i++) {
      const cells = Object.values(rows[i]);
      const rowData = [];
      for (let j = 0; j < cells.length; j++) {
        rowData.push(cells[j]);
      }
      worksheet.addRow(rowData);
      worksheet.columns.forEach(function (column, i) {
        let maxLength = 0;
        column["eachCell"]({ includeEmpty: true }, function (cell) {
          var columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
      });
    }
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
      });
      var link = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = link;
      a.download = `${filename}.xlsx`;
      a.click();
      // window.location = link;
      // saveAs(blob, `output.xlsx`);
    });
  };

  const formatDate = (value) => {
    var timezone = new Date(value).getTimezoneOffset() / 60;
    var lastChangeDate = `${new Date(value).toLocaleString().slice(0, 17)} (UTC${
      +timezone < 0 ? `+${+timezone * -1 > 9 ? "" : "0"}${+timezone * -1}:00` : `-${+timezone * -1}`
    })`;
    return lastChangeDate;
  };
  const formatDateMonthShort = (value) => {
    var timezone = new Date(value).getTimezoneOffset() / 60;
    var lastChangeDate = ` ${new Date(value).toLocaleDateString().slice(0, 2)} ${new Date(
      value
    ).toLocaleDateString("ru", { month: "short" })} ${new Date(value)
      .toLocaleDateString()
      .slice(6, 11)}, ${new Date(value).toLocaleTimeString().slice(0, 5)} (UTC${
      +timezone < 0 ? `+${+timezone * -1 > 9 ? "" : "0"}${+timezone * -1}:00` : `-${+timezone * -1}`
    })`;
    return lastChangeDate;
  };

  const headerComp = [
    {
      field: "product",
      name: "детали заказа",
      type: "text",
    },
    {
      field: "summa",
      name: "до скидки",
      type: "price",
    },
    {
      field: "promotion",
      name: "скидка",
      type: "discount",
    },
    {
      field: "summa",
      name: "со скидкой",
      type: "price",
    },
  ];

  const headerMember = [
    {
      field: "first_name",
      name: "имя",
      type: "text-modif",
    },
    {
      field: "last_name",
      name: "фамилия",
      type: "text",
    },
    {
      field: "email",
      name: "email",
      type: "text",
    },
    {
      field: "phone",
      name: "телефон",
      type: "phone",
    },
    {
      field: "birth_date",
      name: "Дата рожд.",
      type: "date",
    },
    // {
    //   field: "sex",
    //   name: "пол",
    //   type: "text",
    // },
  ];

  // console.log('orderInformation', orderInformation);

  return (
    <>
      <IonContent className="page">
        <div className="page-detail-order">
          <div className="upper-line">
            <div className="tbr">
              <div className="cont-l">
                <Button
                  typebtn="outline"
                  className="btn-back small cube"
                  onClick={() => {
                    history.push("/partner/finance/orders?page=1");
                  }}
                >
                  <Icons icon="design-personal-chevron-left" className="icon-outline" />
                </Button>
                <div className="title">{`Заказ №${orderInformation?.id}`}</div>
              </div>
              <div className="btns">
                {orderInformation?.status_id === 2 && (
                  <Button
                    typebtn="ghost"
                    className="small"
                    onClick={() => setOpenModalCancelOrder(true)}
                  >
                    <Icons icon="x" />
                    Отменить заказ
                  </Button>
                )}
                <Button typebtn="outline" className="small" onClick={() => exportToExcel()}>
                  <Icons icon="download" />
                  Скачать
                </Button>
                <Button
                  typebtn="muted"
                  className="small"
                  onClick={() => {
                    // setModalForModeration(true);
                    // setModalOperNoModeration(true);
                  }}
                >
                  <Icons icon="file-type-pdf" />
                  Детали тура
                </Button>
              </div>
            </div>

            <div className="status-cont">
              <Status
                color={
                  orderInformation?.status_id === 5
                    ? "red"
                    : orderInformation?.status_id === 6
                    ? "green"
                    : `gray`
                }
                className="status-h"
              >
                {orderInformation?.status?.description}
              </Status>
            </div>
          </div>
          <div className="body-content">
            <div className="left">
              {orderInformation?.status_id === 5 && (
                <AlertBox
                  type="danger"
                  title="Причина отмены"
                  text={orderInformation?.cancel_reason}
                  noClose
                />
              )}
              <div className="item">
                <div className="title">Состав</div>
                <div className="tablet">
                  <table>
                    <tbody>
                      <tr className="header-tablet">
                        {/* <th className="el-tableth photo">фото</th> */}
                        {headerComp?.map((el, i) => {
                          return (
                            <th key={i} className={`el-tableth ${el.field || ""}`}>
                              <div className="cont-text-table">
                                <div className="text">{el.name}</div>
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                      {console.log(orderInformation)}
                      {orderInformation?.race && (
                        <tr className="line-tablet">
                          {headerComp?.map((td, i) => {
                            let value;
                            if (td.field === "product") {
                              value = orderInformation?.product.name;
                            } else {
                              value = orderInformation?.race[td.field];
                            }
                            return (
                              <td key={i} className={`el-tablet ${td.field}`}>
                                {td.type === "text" ? (
                                  <div className="text">{value}</div>
                                ) : td.type === "price" ? (
                                  <div className="text">
                                    {String(+value).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                                  </div>
                                ) : (
                                  td.type === "discount" && (
                                    <>
                                      <div className="up-text-create">{value ? value : "-"}</div>
                                      <div className="down-text-create green">
                                        {/* {orderInformation?.race.name_discount} */}
                                      </div>
                                    </>
                                  )
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      )}
                      {!!orderInformation?.operator_options?.length &&
                        orderInformation?.operator_options?.map((tr, index) => {
                          return (
                            <tr key={index} className="line-tablet">
                              {headerComp?.map((td, i) => {
                                let value;
                                if (td.field === "product") {
                                  value = tr.pivot.default_name;
                                } else if (td.field === "summa") {
                                  value = +tr.pivot.default_basic_cost;
                                }
                                return (
                                  <td key={i} className={`el-tablet ${td.field}`}>
                                    {td.type === "text" ? (
                                      <div className="text">{value}</div>
                                    ) : td.type === "price" ? (
                                      <div className="text">
                                        {String(+value).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                                      </div>
                                    ) : (
                                      td.type === "discount" && (
                                        <>
                                          <div className="up-text-create">
                                            {value ? value : "-"}
                                          </div>
                                          <div className="down-text-create green">
                                            {/* {orderInformation?.race.name_discount} */}
                                          </div>
                                        </>
                                      )
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="item">
                <div className="title">{`Участники (${orderInformation?.participants.length})`}</div>
                <div className="tablet">
                  <table>
                    <tbody>
                      <tr className="header-tablet">
                        {/* <th className="el-tableth photo">фото</th> */}
                        {headerMember?.map((el, i) => {
                          return (
                            <th key={i} className={`el-tableth ${el.field || ""}`}>
                              <div className="cont-text-table">
                                <div className="text">{el.name}</div>
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                      {/* {orderInformation?.client && (
                        <tr className="line-tablet">
                          {headerMember?.map((td, i) => {
                            let value = orderInformation?.client?.user[td.field];
                            if (td.field === "sex") {
                              value === "1" ? (value = "Муж.") : (value = "Жен.");
                            }
                            return (
                              <td key={i} className={`el-tablet ${td.field}`}>
                                {td.type === "text" ? (
                                  <div className="text">{value || "-"}</div>
                                ) : td.type === "date" ? (
                                  <div className="text">{new Date(value).toLocaleDateString()}</div>
                                ) : (
                                  td.type === "text-modif" && (
                                    <>
                                      <div className="up-text-create">{value}</div>
                                      <div className="down-text-create gray">
                                        Основной покупатель
                                      </div>
                                    </>
                                  )
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      )} */}

                      {orderInformation?.participants?.map((el, i) => {
                        return (
                          <tr key={i} className="line-tablet">
                            {headerMember?.map((td, i) => {
                              let value = el[td.field];
                              if (td.field === "sex") {
                                value === "1" ? (value = "Муж.") : (value = "Жен.");
                              }
                              return (
                                <td key={i} className={`el-tablet ${td.field}`}>
                                  {td.type === "text" ? (
                                    <div className="text">{value || "-"}</div>
                                  ) : td.type === "date" ? (
                                    <div className="text">
                                      {new Date(value).toLocaleDateString()}
                                    </div>
                                  ) : td.type === "phone" ? (
                                    <div className="text">
                                      {value && mask(value, maskGenerator)}
                                    </div>
                                  ) : (
                                    td.type === "text-modif" && (
                                      <>
                                        <div className="up-text-create">{value}</div>
                                        {el.ismain ? (
                                          <div className="down-text-create gray">
                                            Основной покупатель
                                          </div>
                                        ) : undefined}
                                      </>
                                    )
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="right">
              {(orderInformation?.status_id === 2 || orderInformation?.status_id === 3) && (
                <AlertBox
                  type="warning"
                  text={`Отмена заказа возможна до ${formatDate(new Date())}`}
                  noClose
                />
              )}
              {openAlert && (
                <AlertBox
                  type="info"
                  text="В заказе зафиксированы цены и скидки на момент его создания. Вся информация по туру сохраняется в pdf-файл, доступный по ссылке выше."
                  onClick={() => setOpenAlert(false)}
                />
              )}
              <div className="options">
                <div className="title">Параметры</div>
                <div className="cont-info">
                  <div className="line">
                    <div className="subtext">Даты тура</div>
                    <div className="text">{`${new Date(
                      orderInformation?.race?.start_date
                    ).toLocaleDateString()} — ${new Date(
                      orderInformation?.race?.end_date
                    ).toLocaleDateString()}`}</div>
                  </div>
                  <div className="line">
                    <div className="subtext">Итого стоимость</div>
                    <div className="text">{`${String(+orderInformation?.summa).replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      " "
                    )} ₽`}</div>
                  </div>
                  <div className="line">
                    <div className="subtext">Комиссия</div>
                    <div className="text">{`${+orderInformation?.commission_value}%`}</div>
                  </div>
                  <div className="line-btn">
                    <div className="cont-text">
                      <div className="subtext">Штраф</div>
                      <div className="text">{`${String(
                        orderInformation?.penalty?.length
                          ? +orderInformation?.penalty[0]?.penalty
                          : 0
                      ).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}</div>
                    </div>
                    {orderInformation?.status_id === 5 && (
                      <Button typebtn="ghost" className="cube small">
                        <Icons icon="arrow-right" />
                      </Button>
                    )}
                  </div>
                  <div className="line">
                    <div className="subtext">К выплате</div>
                    <div className="text">{`${String(
                      (
                        (+orderInformation?.summa / 100) *
                        (100 - +orderInformation?.commission_value)
                      )?.toFixed(2)
                    ).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}</div>
                  </div>

                  {orderInformation?.status_id === 5 && orderInformation?.cancel_user && (
                    <div className="line">
                      <div className="subtext">Кто отменил</div>
                      <div className="text">{`${orderInformation?.cancel_user?.first_name || ""} ${
                        orderInformation?.cancel_user?.last_name[0]?.toUpperCase() || ""
                      }`}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="history-orders">
                <div className="title">История заказа</div>
                <div className="cont-history">
                  {orderInformation?.status_history.map((hist, i) => {
                    return (
                      <div key={i}>
                        {hist?.status_id === 1 && (
                          <div className="line">
                            <Icons icon="flag" />
                            <div className="cont-text">
                              <div className="text">Заказ создан</div>
                              <div className="subtext gray">
                                {formatDateMonthShort(new Date(hist?.created_at))}
                              </div>
                            </div>
                          </div>
                        )}
                        {hist?.status_id === 2 && (
                          <div className="line">
                            <Icons icon="circle-check-filled" />
                            <div className="cont-text">
                              <div className="text">{`Оплачено клиентом: ${String(
                                +orderInformation?.summa
                              ).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}</div>
                              <div className="subtext green">
                                {formatDateMonthShort(new Date(hist?.created_at))}
                              </div>
                            </div>
                          </div>
                        )}
                        {hist?.status_id === 3 && (
                          <div className="line">
                            <Icons icon="circle-check-filled" />
                            <div className="cont-text">
                              <div className="text">{`Получен аванс: ${String(
                                (
                                  (+orderInformation?.summa / 100) *
                                  (100 - +orderInformation?.commission_value)
                                )?.toFixed(2)
                              ).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}</div>
                              <div className="subtext green">
                                {formatDateMonthShort(new Date(hist?.created_at))}
                              </div>
                            </div>
                          </div>
                        )}
                        {hist?.status_id === 4 && (
                          <div className="line">
                            <Icons icon="player-pause-filled" />
                            <div className="cont-text">
                              <div className="text">Заморожено</div>
                              <div className="subtext red">{formatDateMonthShort(new Date())}</div>
                            </div>
                          </div>
                        )}
                        {hist?.status_id === 5 && (
                          <div className="line">
                            <Icons icon="circle-x-filled" />
                            <div className="cont-text">
                              <div className="text">Заказ отменён</div>
                              <div className="subtext red">
                                {formatDateMonthShort(new Date(hist?.created_at))}
                              </div>
                            </div>
                          </div>
                        )}

                        {hist?.status_id === 6 && (
                          <div className="line">
                            <Icons icon="circle-check-filled" />
                            <div className="cont-text">
                              <div className="text">{`Заказ завершён`} </div>
                              <div className="subtext green">
                                {formatDateMonthShort(new Date(hist?.created_at))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {!!orderInformation?.penalty.length && (
                    <div className="line">
                      <Icons icon="circle-x-filled" />
                      <div className="cont-text">
                        <div className="text">
                          {`Штраф: ${String(
                            orderInformation?.penalty?.length
                              ? +orderInformation?.penalty[0]?.penalty
                              : 0
                          ).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}
                        </div>
                        <div className="subtext red">
                          {formatDateMonthShort(
                            orderInformation?.penalty?.length &&
                              new Date(+orderInformation?.penalty[0]?.dat * 1000)
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
      <ModalCancelOrder
        open={openModalCancelOrder}
        onClose={setOpenModalCancelOrder}
        orderInformation={orderInformation}
        setOrderInformation={setOrderInformation}
      />
    </>
  );
};

export default OrderDetails;
