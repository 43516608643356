import "../../less/css/map.css";
import L from "leaflet";
import { useIonViewDidEnter } from "@ionic/react";
import { Geolocation } from "@capacitor/geolocation";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setArrayMapCity, setOpenQuickView, setValCity } from "../../store/actions";
import flagiconmap from "../../image/point.png";
import bediconmap from "../../image/icons/bed-icon-map.png";
import star from "../../image/icons/stars.png";
import { URL_ORIGIN } from "../../utils/config";
import qs from "qs";
import { toms } from "../../utils/utils";

const MapOl = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const openQuickView = useSelector((state) => state.openQuickView);
  const productsArray = useSelector((state) => state.filterFirstPage);
  productsArray.forEach((e) => {
    let count = 0;
    let filter = e.details?.filter((el) => el?.point_type_id === 1);
    let sort = filter?.sort(function (a, b) {
      return a.order - b.order;
    })[0];
    let slice;
    if (sort?.collect_time) {
      slice = sort?.collect_time?.slice(0, 5);
    } else {
      slice = "23:59";
    }
    e.time_start = slice;
    for (let i = 0; i < e.races.length; i++) {
      if (
        e?.races[i].number_travelers !== 0 &&
        e?.races[i].number_travelers > e?.races[i].paid_travelers_count &&
        new Date(e?.races[i].start_date).getTime() + toms(slice) > new Date().getTime() &&
        !e?.races[i]?.deleted_at
      ) {
        count += 1;
      }
    }
    e.count_races = count;
  });
  const products = productsArray?.filter((el) => el?.races?.length !== 0 && el?.count_races !== 0);
  const urlSearch = qs.parse(history.location.search.substr(1));
  const valCity = useSelector((state) => state.valCity);
  var nmapi, map, navitelTile;
  var circ;
  // const [circle, setCircle] = useState();
  const [groupS, setGroupS] = useState();
  const maps = useMemo(() => props.maps, [props.maps]);
  const nmapis = useMemo(() => props.nmapis, [props.nmapis]);
  const circle = useMemo(() => props.circle, [props.circle]);
  const myCoor = useMemo(() => props.myCoor, [props.myCoor]);
  const refUrl = useRef(urlSearch);
  var LeafIcon = L.Icon.extend({
    options: {
      iconSize: [32, 32],
    },
  });
  var flagIcon = new LeafIcon({ iconUrl: flagiconmap });
  var bedIcon = new LeafIcon({ iconUrl: bediconmap });

  useIonViewDidEnter(() => {
    if (maps != undefined) {
      maps?.remove();
      props.setMaps(null);
    }
    nmapi = navitel.createWebMapApi("059ed2d4fdc4bd5a0ffc56eb88d3154ec8cc52a9", {
      locale: navitel.LocaleCode.ru_RU,
    });
    props.setNmapis(nmapi);
    map = L.map("map", {
      center: [valCity.lon, valCity.lat],
      zoom: props.valDistance.zoom,
    });
    navitelTile = nmapi.extension.leaflet.createTileLayer({
      locale: navitel.LocaleCode.ru_RU,
    });
    navitelTile.addTo(map);
    props.setMaps(map);
    circ = L.circle([valCity.lon, valCity.lat], {
      radius: props.valDistance.val,
      color: "#F74439",
      weight: "2", // 1
      fillColor: "#F74439",
      // fillOpacity: "0.04",
      dashArray: "4",
    }).addTo(map);
    props.setCircle(circ);
  }, [valCity, openQuickView]);

  useEffect(() => {
    if (nmapis) {
      if (maps != undefined) {
        maps.remove();
        props.setMaps(null);
      }
      nmapi = navitel.createWebMapApi("059ed2d4fdc4bd5a0ffc56eb88d3154ec8cc52a9", {
        locale: navitel.LocaleCode.ru_RU,
      });
      props.setNmapis(nmapi);
      map = L.map("map", {
        center: [valCity.lon, valCity.lat],
        zoom: props.valDistance.zoom,
      });
      navitelTile = nmapi.extension.leaflet.createTileLayer({
        locale: navitel.LocaleCode.ru_RU,
      });
      navitelTile.addTo(map);
      props.setMaps(map);
      circ = L.circle([valCity.lon, valCity.lat], {
        radius: props.valDistance.val,
        color: "#F74439",
        weight: "2", // 1
        fillColor: "#F74439",
        // fillOpacity: "0.04",
        dashArray: "4",
      }).addTo(map);
      props.setCircle(circ);
    }
  }, [openQuickView]);

  useAsyncEffect(() => {
    circle?.setLatLng([valCity.lon, valCity.lat]);
    maps?.flyTo([valCity.lon, valCity.lat], props.valDistance.zoom);
  }, [valCity]);

  useAsyncEffect(() => {
    circle?.setRadius(props.valDistance.val);
    maps?.flyTo([valCity.lon, valCity.lat], props.valDistance.zoom);
  }, [props.valDistance]);

  useAsyncEffect(() => {
    var array = [];
    groupS?.clearLayers();
    for (var i = 0; i < products?.length; i++) {
      let filtDet = products[i]?.details;
      if (filtDet?.length) {
        let details = filtDet?.sort(function (a, b) {
          return a.order - b.order;
        });
        var item = JSON.parse(details[0]?.info);
        var d = maps?.distance([item.points[0].lat, item.points[0].lon], circle.getLatLng());
        var isInside = d < circle?.getRadius();
        let marker;
        let reviews = products[i]?.clients_reviews.filter((el) => el.type === 0 && !el.parent_id);

        // onClick=${() => { dispatch(setOpenQuickView({ open: true, el: props.el })); history.push(`?id=${props.el?.id}`);}}}

        if (isInside) {
          marker = new L.marker([item.points[0].lat, item.points[0].lon], {
            icon: flagIcon,
          }).bindPopup(
            `<div id=${products[i]?.id} class="body-popup-map">
            <div class="left">
              <div class="image-cont">
                <img src=${URL_ORIGIN}${products[i]?.photo} />
              </div>
            </div>
            <div class="right">
              <div class="rating">
                <img src=${star} />
                <div class="val-rate">${
                  products[i]?.rating?.[0]?.avg ? products[i]?.rating?.[0]?.avg : 0
                }</div>
                <div class="reviews">${reviews?.length} отзывов</div>
              </div>
              <div class="title">${products[i]?.name}</div> 
              <div class="price"><div class="text">${`${String(+products[i]?.summa).replace(
                /\B(?=(\d{3})+(?!\d))/g,
                " "
              )} ₽`}</div><div class="subtext">${`/ `}</div><div class="subtext">${`${products[i]?.duration.name}`}</div></div>
            </div>
          </div>`
          );
          marker.on("click", function (e) {
            var popup = e.target.getPopup()._contentNode.lastChild;
            popup.onclick = () => {
              let product = products?.find((el) => el.id === +popup?.id);
              dispatch(setOpenQuickView({ open: true, el: product }));
              // history.push(`?id=${popup?.id}`);
              let data = refUrl.current;
              data.id = popup?.id;
              const params = new URLSearchParams(data);
              history.push(`?${params.toString()}`);
            };
          });
          array.push(marker);
        }
      }
    }
    if (array.length) {
      var group = L.featureGroup(array).addTo(maps);
      setGroupS(group);
    }
  }, [productsArray, valCity, props.valDistance]);

  useAsyncEffect(async () => {
    if (myCoor && nmapis && !props.searchPatternList) {
      const item = await nmapis.search.findNearestObject({
        coordinates: {
          latitude: myCoor?.coords.latitude,
          longitude: myCoor?.coords.longitude,
        },
        locale: navitel.LocaleCode.ru_RU, // optional
      });
      if (props.arrayCity[0].name !== item.address.city) {
        dispatch(
          setArrayMapCity([
            {
              name: item.address.city,
              lon: item.coordinates.latitude,
              lat: item.coordinates.longitude,
            },
            ...props.arrayCity,
          ])
        );
      }
      dispatch(
        setValCity(
          [
            {
              name: item.address.city,
              lon: item.coordinates.latitude,
              lat: item.coordinates.longitude,
            },
            ...props.arrayCity,
          ][0]
        )
      );
      circle?.setLatLng([myCoor?.coords.latitude, myCoor?.coords.longitude]);
      L.marker([myCoor?.coords.latitude, myCoor?.coords.longitude]).addTo(maps);
      maps.flyTo([myCoor?.coords.latitude, myCoor?.coords.longitude], props.valDistance.zoom);
    }
  }, [myCoor, maps]);

  return (
    <div className="cont-map">
      <div className={`map`} id="map"></div>
    </div>
  );
};

export default MapOl;
