import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonItem,
  IonPopover,
  IonSpinner,
} from "@ionic/react";
import Button from "../../ui/Button";
import Icons from "../../ui/Icons";
import { useHistory, useLocation } from "react-router";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import {
  getCancelOrder,
  getOrdersClient,
  getOrdersClientByID,
  getProductsClient,
  getProductsClientByID,
  getProductsGuest,
  getProductsGuestByID,
  postFavorite,
  postOrderPay,
  putDelOrder,
} from "../../utils/api";
import qs from "qs";
import avatar from "../../image/avatar.svg";
import ArrowDown from "../../image/icons/chevron-down.svg";
import { ProgressSpinner } from "primereact/progressspinner";

import { useEffect, useRef, useState } from "react";
import Badge from "../../ui/Badge";
import Rating from "../../ui/Rating";
import { useDispatch, useSelector } from "react-redux";
import {
  setArrayPointsTour,
  setClientTour,
  setFormCreateOrderClient,
  setModalLogin,
  setOpenModalCancelOrders,
  setOpenModalCreateQuestion,
  setOpenModalCreateReport,
  setOpenQuickView,
  setOrders,
  setProductsClients,
} from "../../store/actions";
import Clock from "../../image/image-quick-view/clock.svg";
import Coffee from "../../image/image-quick-view/coffee.svg";
import Wallet from "../../image/image-quick-view/wallet.svg";
import AlertBox from "../../ui/AlertBox";
import Select from "../../ui/Select";
import MapShowTourClient from "./MapShowTourClient";
import Vopros from "../../image/image-quick-view/vopros.svg";
import { Timeline } from "primereact/timeline";
import flag from "../../image/icon-timeline/flag-filled.svg";
import bed from "../../image/icon-timeline/bed-filled.svg";
import star from "../../image/icon-timeline/star-filled.svg";
import Status from "../../ui/Status";
import cornerLeftUp from "../../image/icons/corner-left-up.svg";
import SelectButton from "../../ui/SelectButton";
import man from "../../image/icons/man.svg";
import backEmpty from "../../image/background-empty-table.svg";
import Footer from "../../ui/Footer";
import { formatDate } from "../../utils/utils";
import Toggle from "../../ui/Toggle";
import ClientModalCreateQuestion from "../client/ClientModalCreateQuestion";
import ClientModalCancelOrders from "../client/ClientModalCancelOrders";
import ModalDelete from "../showcase/Modals/ModalDelete";
import { countProm } from "../../utils/utils";
import { URL_ORIGIN } from "../../utils/config";
import ClientModalCreateReport from "../client/ClientModalCreateReport";
import Toast from "../../ui/Toast";

const ShowTourClient = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const element = useSelector((state) => state.clientTour);
  const [activeTabs, setActiveTabs] = useState(0);
  const [sortRev, setSortRev] = useState({ name: "Сначала новые" });
  const arrSelectRev = [{ name: "Сначала новые" }, { name: "Обработанные" }];
  const [openPopover, setOpenPopover] = useState(false);
  const [dateRange, setDateRange] = useState();
  const [adults, setAdulds] = useState(1);
  const [children, setChildren] = useState(0);
  const [babies, setBabies] = useState(0);
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ text: null, type: null });
  const token = useSelector((state) => state.token);
  const reviews = element?.clients_reviews?.filter((el) => el.type === 0 && !el.parent_id);
  const hideReviews = reviews?.slice(0, 4);
  const [hideRev, setHideRev] = useState(true);
  const question = element?.clients_reviews?.filter((el) => el.type === 1);
  const hideQuestion = question?.slice(0, 4);
  const [hideQuest, setHideQuest] = useState(true);
  const orders = useSelector((state) => state.orders);
  const [modalDeleteOrder, setModalDeleteOrder] = useState(false);
  // const [order, setOrder] = useState();
  const [delEl, setDelEl] = useState();
  const [loadbtndel, setLoadbtndel] = useState(false);
  const faq = element?.clients_reviews?.filter((el) => el.type === 2);
  const det = element?.details;
  const details = det?.sort(function (a, b) {
    return a.order - b.order;
  });
  const pointStart = details?.find((el) => el?.point_type_id === 1);
  const detailsOther = details?.filter((el, index) => el?.point_type_id !== 1);
  // const detailsOther = details?.filter((el, index) => index !== 0);
  const suitable = useSelector((state) => state.suitable);
  suitable?.sort(function (a, b) {
    return a.type_id - b.type_id;
  });
  const picture = element?.pictures?.slice(0, 4);
  const [maps, setMaps] = useState();
  const [loading, setLoading] = useState(false);

  let races = [];
  element?.races?.forEach((e) => {
    let range = `${new Date(e.start_date).toLocaleDateString("ru", { day: "numeric" })} ${new Date(
      e.start_date
    ).toLocaleDateString("ru", { month: "short" })} — ${new Date(e.end_date).toLocaleDateString(
      "ru",
      { day: "numeric" }
    )} ${new Date(e.end_date).toLocaleDateString("ru", { month: "short" })}`;
    if (
      e.number_travelers > e.paid_travelers_count &&
      new Date(e.start_date).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) &&
      !e.deleted_at
    ) {
      races.push({ ...e, range: range });
    }
  });

  let today = new Date().getTime();

  races = races?.filter((e) => today <= new Date(e.end_date).getTime());

  const urlSearch = qs.parse(history.location.search.substr(1));
  const order = orders?.find((el) => el.id === +urlSearch?.order);
  console.log("order", order)
  const [orderOptionsCost, setOrderOptionsCost] = useState(0);
  useEffect(() => {
    let cost = 0;
    for (let i = 0; i < order?.operator_options?.length; i++) {
      cost += +order?.operator_options[i]?.pivot?.default_basic_cost;
    }
    setOrderOptionsCost(cost);
  }, [order]);

  const [arrOptions, setArrOptions] = useState([]);
  const [arrOptionsCost, setArrOptionsCost] = useState(0);

  useAsyncEffect(() => {
    if (orders.length) {
      if (!order) {
        history.push(`/tour?id=${urlSearch.id}`);
      }
    }
  }, [orders]);

  useAsyncEffect(() => {
    if (urlSearch.id === undefined) return;
    dispatch(setOpenQuickView({ open: false, el: null }));
    if (token) {
      if (urlSearch.order) {
        getOrdersClientByID(token, urlSearch.order).then((e) => {
          if (!e.data.errors) {
            dispatch(setClientTour(e.data.data.product));
            dispatch(setArrayPointsTour(JSON.parse(e.data.data.info)?.product_details));
            setOrder(e.data.data);
          }
        });
      } else {
        getProductsClientByID(token, urlSearch.id).then((e) => {
          if (!e.data.errors)
            if (Object.keys(e.data.data).length === 0) {
              history.push("/");
            } else {
              dispatch(setClientTour(e.data.data));
              dispatch(setArrayPointsTour(e.data.data.details));
            }
        });
      }
    } else {
      getProductsGuestByID(urlSearch.id).then((e) => {
        if (!e.data.errors) {
          dispatch(setClientTour(e.data.data));
          dispatch(setArrayPointsTour(e.data.data.details));
        }
      });
    }
  }, [urlSearch?.id, urlSearch.order]);

  const convertStringToHTML = (htmlString) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, "text/html").body.childNodes;
    return html;
  };

  useEffect(() => {
    const description = convertStringToHTML(element?.description);
    for (let node of description) {
      element?.description && document.getElementById("description")?.appendChild(node);
    }
  }, [element?.description]);
  useEffect(() => {
    const descriptionIncPrice = convertStringToHTML(element?.description_inc_price);
    for (let node of descriptionIncPrice) {
      element?.description_inc_price &&
        document.getElementById("description_inc_price")?.appendChild(node);
    }
  }, [element?.description_inc_price]);

  useAsyncEffect(() => {
    dispatch(setFormCreateOrderClient({}));
  }, []);

  useAsyncEffect(async () => {
    if (token) {
      getOrdersClient(token).then((e) => {
        dispatch(setOrders(e.data.data));
      });
    }
  }, []);

  const calcDate = (el, response_date) => {
    let response = new Date(response_date).toLocaleDateString("ru");
    let date;
    if (el == 0) {
      date = "Сегодня";
    } else if (el == 1) {
      date = "Вчера";
    } else if (el > 1 && el < 7) {
      date = `${el} ${el > 1 && el < 5 ? "дня" : "дней"} назад`;
    } else if (el >= 7 && el < 14) {
      date = "Неделю назад";
    } else if (el >= 14 && el < 30) {
      date = "2 недели назад";
    } else if (el >= 30 && el < 60) {
      date = "Месяц назад";
    } else if (el >= 60 && el < 90) {
      date = "2 месяца назад";
    } else if (el > 90) {
      date = response;
    }
    return date;
  };

  // const [active, setActive] = useState([]);

  const customizedContent = (item, index) => {
    const image = document?.getElementsByClassName("icon-info-details");
    const info = document?.getElementsByClassName("cont-reviews-details");
    return (
      <div className="cont-info-details">
        <Icons className="icon-info-details icon-outline" icon="chevron-down" />
        <div className="info-cont">
          <div
            className="cont-title"
            onClick={() => {
              if (image[index].classList.contains("active")) {
                image[index].classList.remove("active");
              } else {
                image[index].classList.add("active");
              }
              if (info[index].classList.contains("hide")) {
                info[index].classList.remove("hide");
              } else {
                info[index].classList.add("hide");
              }
            }}
          >
            <div className="title">{item.name}</div>
            {item.collect_time && <div className="collect-time">{item.collect_time}</div>}
          </div>
          {/* {active  && <Status color="gray">{`День ${index + 1}`}</Status>} */}
        </div>
        <div className="cont-reviews-details hide">
          <div className="text-reviews-details">{item?.description}</div>
          <div className="cont-images">
            {item?.pictures?.map((q, i) => {
              return (
                <div key={i} className="image">
                  <img src={`${URL_ORIGIN}${q.photo}`} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const customizedMarker = (item) => {
    return (
      <span className="span">
        {item.point_type_id === 1 ? (
          <img src={flag} />
        ) : item.point_type_id === 2 ? (
          <img src={bed} />
        ) : (
          item.point_type_id === 3 && <img src={star} />
        )}
      </span>
    );
  };

  const menu = [
    {
      name: "Скопировать ссылку",
      id: 1,
      icon: "copy",
    },
    {
      name: "Вконтакте",
      id: 2,
      icon: "brand-vk",
      href: `https://vk.com/share.php?url=${URL_ORIGIN}tour?id=${urlSearch.id}"`,
    },
    {
      name: "Одноклассники",
      id: 3,
      icon: "brand-ok-ru",
      href: `https://connect.ok.ru/offer?url=${URL_ORIGIN}tour?id=${urlSearch.id}&description=тур: "${element?.name}"`,
    },
    {
      name: "Telegram",
      id: 4,
      icon: "brand-telegram",
      href: `https://t.me/share/url?url=${URL_ORIGIN}tour?id=${urlSearch.id}&text=Сервис автопутешествий Смородина, тур: "${element?.name}"`,
    },
    {
      name: "X (Twitter)",
      id: 5,
      icon: "brand-x",
      href: `https://twitter.com/share?url=${URL_ORIGIN}tour?id=${urlSearch.id}&text=тур: "${element?.name}"`,
    },
  ];

  // console.log("element", element.promotions);

  let arrayProm = element?.operator_promotions?.filter((e) => e.type == 0);
  arrayProm = arrayProm?.filter(
    (e) => today >= new Date(e.start_date).getTime() && today <= new Date(e.endDate).getTime()
  );

  let arrayPromDataRange = element?.operator_promotions?.filter((e) => e.type == 0);
  arrayPromDataRange = arrayPromDataRange?.filter(
    (e) =>
      new Date(dateRange?.start_date).getTime() >= new Date(e.start_date).getTime() &&
      new Date(dateRange?.start_date).getTime() <= new Date(e.end_date).getTime()
  );

  // console.log("arrayPromDataRange", arrayPromDataRange);

  // console.log("element", element);

  // console.log("arrayProm", arrayProm);

  // console.log("dateRange", dateRange);

  // console.log("order", order);

  return (
    <IonContent className="page-guest">
      <div className="body-show">
        <div className="cont-show">
          <div className="title-cont">
            <div className="btn-back">
              <Button
                typebtn="outline"
                className="small"
                onClick={() => {
                  // dispatch(setOpenQuickView({ open: false, el: null }));
                  maps.remove();
                  setMaps(null);
                  if (token && order) {
                    history.push("/client");
                  } else {
                    history.push("/");
                  }
                }}
              >
                <Icons icon="arrow-narrow-left" />
                все туры
              </Button>
            </div>
            <div className="title">{element?.name}</div>
            <div className="genres">
              {element?.genres?.map((el, i) => {
                return <Badge key={i}>{el.name}</Badge>;
              })}
            </div>
            <div className="rating">
              <div className="left">
                <Rating rate={element?.rating?.[0]?.avg ? element?.rating?.[0]?.avg : 0} />
                <div className="val">
                  {element?.rating?.[0]?.avg ? element?.rating?.[0]?.avg : 0}
                </div>
                <div className="reviews">{`${reviews?.length ?? "0"} отзывов`}</div>
              </div>
              <div className="right">
                {element?.favorite?.[0]?.favorite ? (
                  <Button
                    typebtn="ghost"
                    className="small btn-show-tour favorite"
                    onClick={() => {
                      if (token) {
                        postFavorite(token, urlSearch?.id, "delete").then(async (e) => {
                          if (!e.data.errors) {
                            if (urlSearch?.order) {
                              getOrdersClientByID(token, urlSearch.order).then((e) => {
                                if (!e.data.errors) {
                                  dispatch(setClientTour(e.data.data.product));
                                  dispatch(
                                    setArrayPointsTour(
                                      JSON.parse(e.data.data.info)?.product_details
                                    )
                                  );
                                  setOrder(e.data.data);
                                }
                              });
                            } else {
                              await getProductsClientByID(token, urlSearch?.id).then((e) => {
                                if (!e.data.errors) {
                                  dispatch(setClientTour(e.data.data));
                                  dispatch(setArrayPointsTour(e.data.data.details));
                                }
                              });
                            }
                            await getProductsClient(token).then((e) => {
                              dispatch(setProductsClients(e.data.data));
                            });
                          }
                        });
                      } else {
                        dispatch(setModalLogin({ open: true }));
                      }
                    }}
                  >
                    <Icons icon="heart-filled" />В избранном
                  </Button>
                ) : (
                  <Button
                    typebtn="ghost"
                    className="small btn-show-tour"
                    onClick={() => {
                      if (token) {
                        postFavorite(token, urlSearch?.id, "add").then(async (e) => {
                          if (!e.data.errors) {
                            if (urlSearch?.order) {
                              getOrdersClientByID(token, urlSearch.order).then((e) => {
                                if (!e.data.errors) {
                                  dispatch(setClientTour(e.data.data.product));
                                  dispatch(
                                    setArrayPointsTour(
                                      JSON.parse(e.data.data.info)?.product_details
                                    )
                                  );
                                  setOrder(e.data.data);
                                }
                              });
                            } else {
                              await getProductsClientByID(token, urlSearch?.id).then((e) => {
                                if (!e.data.errors) {
                                  dispatch(setClientTour(e.data.data));
                                  dispatch(setArrayPointsTour(e.data.data.details));
                                }
                              });
                            }
                            await getProductsClient(token).then((e) => {
                              dispatch(setProductsClients(e.data.data));
                            });
                          }
                        });
                      } else {
                        dispatch(setModalLogin({ open: true }));
                      }
                    }}
                  >
                    <Icons icon="heart" />В избранное
                  </Button>
                )}
                <Button
                  typebtn="ghost"
                  id="bottom-start-d"
                  onClick={() => setOpenPopover(true)}
                  className="small btn-show-tour"
                >
                  <Icons icon="share" />
                  Поделиться
                </Button>
              </div>
            </div>
          </div>
          <div className="images-cont">
            <div className="left">
              <Button className="small btn-all-photo" typebtn="elevated">
                <Icons icon="grid-dots" />
                {`Все фото (${element?.pictures?.length + 1})`}
              </Button>
              <img src={`${URL_ORIGIN}${element?.photo}`} />
            </div>
            <div className="right">
              {picture?.map((el, i) => {
                return (
                  <div key={i} className="image">
                    <img src={`${URL_ORIGIN}${el?.photo}`} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="cont-information">
            <div className="left">
              <div className="block-cont">
                <div className="block">
                  <img src={Coffee} />
                  <div className="cont-text">
                    <div className="subtext">Класс обслуживания</div>
                    <div className="text">{element?.class?.name}</div>
                  </div>
                </div>
                <div className="block">
                  <img src={Clock} />
                  <div className="cont-text">
                    <div className="subtext">Продолжительность</div>
                    <div className="text">{element?.duration?.name}</div>
                  </div>
                </div>
                <div className="block">
                  <img src={Wallet} />
                  <div className="cont-text">
                    <div className="subtext">Стоимость</div>
                    <div className="row-price">
                      {arrayProm?.length > 0 ? (
                        <div className="last-price">{`${String(+element?.summa).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          " "
                        )}`}</div>
                      ) : undefined}
                      <div className={`text ${arrayProm?.length > 0 ? "promotion" : ""}`}>
                        {arrayProm?.length > 0
                          ? `${String(countProm(arrayProm, element.summa)).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              " "
                            )} ₽`
                          : `${String(+element?.summa).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}
                      </div>
                    </div>
                    {/* <div className="text">{`${String(+element?.summa)?.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      " "
                    )} ₽`}</div> */}
                  </div>
                </div>
              </div>
              <div className="description" id="description"></div>
              <div className="suitable">
                <div className="title">Кому подойдёт</div>
                <div className="blocks-suitable">
                  <div className="line">
                    <div className={`block-suitable ${element?.number_travelers ? "true" : ""}`}>
                      <img
                        src={man}
                        className={`${element?.number_travelers ? "" : "icon-active"}`}
                      />
                      <div className="cont-text">
                        <div className={`subtext ${element?.number_travelers ? "true" : ""}`}>
                          Старше 17 лет
                        </div>
                        <div className={`text ${element?.number_travelers ? "true" : ""}`}>
                          Взрослые
                        </div>
                      </div>
                      <div className="stat">
                        {element?.number_travelers ? (
                          <Icons icon="check" className="green" />
                        ) : (
                          <Icons icon="x" className="gray" />
                        )}
                      </div>
                    </div>
                    {suitable?.map((el, i) => {
                      let find = element?.suitables?.find((q) => q.suitable_id === el?.id)?.cnt;
                      return (
                        <div key={i} className={`block-suitable ${find === 1 ? "true" : ""}`}>
                          {el?.picture && (
                            <img
                              src={`${URL_ORIGIN}/${el?.picture}`}
                              className={`${find === 1 ? "" : "icon-active"} `}
                            />
                          )}
                          <div className="cont-text">
                            <div className={`subtext ${find === 1 ? "true" : ""}`}>
                              {el?.description}
                            </div>
                            <div className={`text ${find === 1 ? "true" : ""}`}>{el?.name}</div>
                          </div>
                          <div className="stat">
                            {find === 1 ? (
                              <Icons icon="check" className="green" />
                            ) : (
                              <Icons icon="x" className="gray" />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="description-inc">
                <div className="title">Входит в стоимость</div>
                <div className="description_inc_price" id="description_inc_price"></div>
              </div>
              {dateRange?.operator_options?.length ? (
                <>
                  <div className="options-cont">
                    <div className="title-opt">Дополнительные опции</div>
                    {dateRange?.operator_options?.map((el, i) => {
                      if (el?.status !== 1) return;
                      return (
                        <div key={i} className="block">
                          <div className="left-block">
                            <Toggle
                              disabled={order}
                              checked={!!arrOptions?.find((q) => q.id === el.id)}
                              text={el?.option?.name}
                              description={el?.description}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setArrOptions([...arrOptions, el]);
                                  setArrOptionsCost(arrOptionsCost + +el?.basic_cost);
                                } else {
                                  let arr = arrOptions;
                                  let a = arr.indexOf(el);
                                  arr.splice(a, 1);
                                  setArrOptions(arr);
                                  setArrOptionsCost(arrOptionsCost - +el?.basic_cost);
                                }
                              }}
                            />
                          </div>
                          <div className="right-block">
                            {el?.basic_cost ? (
                              <div className="text">
                                {`+${String(+el?.basic_cost).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  " "
                                )} ₽`}
                              </div>
                            ) : (
                              <div className="text-free">Бесплатно</div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : undefined}
              <div className="map-cont">
                <div className="title">{`Маршрут (${element?.details?.length})`}</div>
                {/*<div className="title">{`Маршрут (${*/}
                {/*  order*/}
                {/*    ? JSON.parse(order?.info)?.product_details?.length*/}
                {/*    : element?.details?.length*/}
                {/*})`}</div>*/}
                <div className="conter">
                  <MapShowTourClient setMaps={setMaps} maps={maps} />
                </div>
              </div>
              <div className="cont-details">
                {pointStart ? (
                  <div className="point-start">
                    <div className="title-point-start-cont">
                      <div className="title-point-start">
                        <div className="text">Точка сбора</div>
                        <img src={Vopros} />
                      </div>
                      {details && (
                        <div className="coor-name">{`${
                          pointStart && JSON.parse(pointStart?.info)?.points[0]?.lat
                        }°, ${pointStart && JSON.parse(pointStart?.info)?.points[0]?.lon}°, ${
                          pointStart?.name
                        }`}</div>
                      )}

                      {details?.[0].collect_time && (
                        <div className="timezone">{`${pointStart?.collect_time}`}</div>
                      )}
                    </div>
                    <div className="description">{`${pointStart?.description}`}</div>
                    <div className="cont-images">
                      {pointStart?.pictures.map((q, i) => {
                        return (
                          <div key={i} className="image">
                            <img src={`${URL_ORIGIN}${q.photo}`} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : undefined}
                <Timeline
                  value={detailsOther}
                  // align="right"
                  className="customized-timeline"
                  marker={customizedMarker}
                  content={customizedContent}
                />
              </div>
              <div className="cont-reviews">
                <div className="cont-stage">
                  <div
                    className={`stage ${activeTabs === 0 ? "active" : ""}`}
                    onClick={() => {
                      setActiveTabs(0);
                      setHideQuest(true);
                    }}
                  >
                    <div className="text">Отзывы</div>
                    <div className="len">{reviews?.length}</div>
                  </div>
                  <div
                    className={`stage ${activeTabs === 1 ? "active" : ""}`}
                    onClick={() => {
                      setActiveTabs(1);
                      setHideRev(true);
                    }}
                  >
                    <div className="text">Вопросы</div>
                    <div className="len">{question?.length}</div>
                  </div>
                </div>
                <div className="rating-cont">
                  <div className="rate-left">
                    <Rating
                      className="rev-rate"
                      rate={element?.rating?.[0]?.avg ? element?.rating?.[0]?.avg : 0}
                    />
                    <div className="rate-val">
                      {element?.rating?.[0]?.avg ? element?.rating?.[0]?.avg : 0}
                    </div>
                  </div>
                  {activeTabs === 0 ? (
                    <Button
                      className="small"
                      typebtn="muted"
                      onClick={() => {
                        if (token) {
                          history.push(`/tour/review?id=${urlSearch?.id}`);
                        } else {
                          dispatch(setModalLogin({ open: true }));
                        }
                      }}
                    >
                      Оставить отзыв
                    </Button>
                  ) : (
                    activeTabs === 1 && (
                      <Button
                        className="small"
                        typebtn="muted"
                        onClick={() => {
                          token
                            ? dispatch(setOpenModalCreateQuestion(true))
                            : dispatch(setModalLogin({ open: true }));
                        }}
                      >
                        Задать вопрос
                      </Button>
                    )
                  )}
                </div>
                {activeTabs === 0 && reviews?.length ? (
                  <div className="select-sort">
                    <SelectButton
                      value={sortRev}
                      array={arrSelectRev}
                      className="ghost"
                      optionLabel="name"
                      onChange={(e) => {
                        setSortRev(e.value);
                      }}
                    />
                  </div>
                ) : activeTabs === 1 && question?.length ? (
                  <div className="select-sort">
                    <SelectButton
                      value={sortRev}
                      array={arrSelectRev}
                      className="ghost"
                      optionLabel="name"
                      onChange={(e) => {
                        setSortRev(e.value);
                      }}
                    />
                  </div>
                ) : undefined}
                <div className="cont-review">
                  {activeTabs === 0
                    ? (hideRev ? hideReviews : reviews)?.map((el, i) => {
                        let currentDate = Date.parse(new Date());
                        let days = (currentDate - Date.parse(el?.created_at)) / 86400000; //86400000 - ms в дне
                        let days_response =
                          (currentDate - Date.parse(el?.response_date)) / 86400000; //86400000 - ms в дне
                        let when = Math.round(days);
                        let when_response = Math.round(days_response);
                        return (
                          <div key={i} className="items">
                            <div className="item">
                              <div className="user-info">
                                <div className="circle">
                                  <img src={avatar} />
                                </div>
                                <div className="name">{el?.client?.user?.first_name}</div>
                              </div>
                              <div className="user-rate">
                                <Rating rate={el?.tour_assessment ? el?.tour_assessment : 0} />
                                <div className="when">{calcDate(when, el?.created_at)}</div>
                              </div>
                              <div className="user-review">{el.review_text}</div>
                              <div className="cont-images">
                                {el?.pictures.map((im, i) => {
                                  return (
                                    <div key={i} className="image">
                                      <img src={`${URL_ORIGIN}${im?.photo}`} />
                                    </div>
                                  );
                                })}
                              </div>
                              {el?.response_text && (
                                <div className="cont-response">
                                  <img src={cornerLeftUp} />
                                  <div className="response">
                                    <div className="upper">
                                      <div className="circle-response">
                                        <img src={avatar} />
                                      </div>
                                      <div className="name-response">
                                        {el?.response_user?.first_name}
                                      </div>
                                      <div className="date-response">
                                        {calcDate(when_response, el?.response_date)}
                                      </div>
                                    </div>
                                    <div className="text-response">{el?.response_text}</div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div
                              className={`l ${
                                hideRev
                                  ? i === hideReviews.length - 1
                                    ? "hide"
                                    : ""
                                  : i === reviews.length - 1
                                  ? "hide"
                                  : ""
                              }`}
                            />
                          </div>
                        );
                      })
                    : activeTabs === 1
                    ? (hideQuest ? hideQuestion : question)?.map((el, i) => {
                        let currentDate = Date.parse(new Date());
                        let days = (currentDate - Date.parse(el?.created_at)) / 86400000; //86400000 - ms в дне
                        let days_response =
                          (currentDate - Date.parse(el?.response_date)) / 86400000; //86400000 - ms в дне
                        let when = Math.round(days);
                        let when_response = Math.round(days_response);

                        return (
                          <div key={i} className="items">
                            <div className="item">
                              <div className="user-info">
                                <div className="circle">
                                  <img src={avatar} />
                                </div>
                                <div className="name">{el?.client?.user?.first_name}</div>
                              </div>
                              <div className="user-rate">
                                <div className="when">{calcDate(when, el?.created_at)}</div>
                              </div>
                              <div className="user-review">{el.review_text}</div>
                              {el?.response_text && (
                                <div className="cont-response">
                                  <img src={cornerLeftUp} />
                                  <div className="response">
                                    <div className="upper">
                                      <div className="circle-response">
                                        <img src={avatar} />
                                      </div>
                                      <div className="name-response">
                                        {el?.response_user?.first_name}
                                      </div>
                                      <div className="date-response">
                                        {calcDate(when_response, el?.response_date)}
                                      </div>
                                    </div>
                                    <div className="text-response">{el?.response_text}</div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div
                              className={`l ${
                                hideQuest
                                  ? i === hideQuestion.length - 1
                                    ? "hide"
                                    : ""
                                  : i === question.length - 1
                                  ? "hide"
                                  : ""
                              }`}
                            />
                          </div>
                        );
                      })
                    : undefined}
                </div>
                {(activeTabs === 0 && hideRev && reviews?.length > 4) ||
                (activeTabs === 1 && hideQuest && question?.length > 4) ? (
                  <div className="btn-more">
                    <Button
                      className="small"
                      typebtn="ghost"
                      onClick={() => {
                        if (activeTabs === 0) {
                          setHideRev(false);
                        } else if (activeTabs === 1) {
                          setHideQuest(false);
                        }
                      }}
                    >
                      Показать ещё <Icons icon="chevron-down" />
                    </Button>
                  </div>
                ) : undefined}
              </div>
              {faq?.length ? (
                <div className="faq-cont">
                  <div className="title">Частые вопросы</div>
                  <IonAccordionGroup className="item-accord-group">
                    {faq?.map((el, i) => {
                      return (
                        <IonAccordion
                          key={i}
                          className="item-accord"
                          value={i}
                          toggleIcon={ArrowDown}
                        >
                          <IonItem slot="header">
                            <div>{el?.review_text}</div>
                          </IonItem>
                          <div className="ion-padding" slot="content">
                            {el?.response_text}
                          </div>
                        </IonAccordion>
                      );
                    })}
                  </IonAccordionGroup>
                </div>
              ) : undefined}
              <div className="operator-cont">
                <div className="operator">
                  <div className="title">Организатор путешествия</div>
                  <div className="org">
                    <Icons icon="info-circle" className="gray" />
                    <div className="text">{element?.operator?.name_legal}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right">
              {token && urlSearch?.order ? (
                <div className="right-cont">
                  <div className="races-comp-cont">
                    {order ? (
                      <>
                        <div className="title">Ваш заказ</div>
                        <div className="info">
                          <div className="line">
                            <div className="subtext">Номер заказа</div>
                            <div className="text">{`№ ${order?.id}`}</div>
                          </div>
                          <div className="line">
                            <div className="subtext">Даты</div>
                            <div className="text">{`${new Date(
                              order?.race?.start_date
                            ).toLocaleDateString("ru", {
                              day: "numeric",
                            })} ${new Date(order?.race?.start_date).toLocaleDateString("ru", {
                              month: "short",
                            })} — ${new Date(order?.race?.end_date).toLocaleDateString("ru", {
                              day: "numeric",
                            })} ${new Date(order?.race?.end_date).toLocaleDateString("ru", {
                              month: "short",
                            })}`}</div>
                          </div>
                          <div className="line">
                            <div className="subtext">Формат</div>
                            <div className="text">{element?.duration?.name}</div>
                          </div>
                          <div className="line">
                            <div className="subtext">Класс обслуживания</div>
                            <div className="text">{element?.class?.name}</div>
                          </div>
                          <div className="line">
                            <div className="subtext">Доп. опции</div>
                            {order?.operator_options?.length
                              ? order?.operator_options?.map((el, i) => {
                                  return (
                                    <div key={i} className="text">
                                      {el?.pivot.default_name}
                                    </div>
                                  );
                                })
                              : "-"}
                          </div>
                        </div>
                        <div className="l" />
                        <div className="price-cont">
                          <div className="price">
                            <div className="text">Стоимость тура:</div>
                            <div className="row-price">
                              {arrayPromDataRange?.length > 0 && order ? (
                                <div className="last-price">{`${String(
                                  (order?.summa-orderOptionsCost)?.toFixed(2)
                                ).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`}</div>
                              ) : undefined}
                              <div
                                className={`text ${
                                  arrayPromDataRange?.length > 0 && order ? "promotion" : ""
                                }`}
                              >
                                {arrayPromDataRange?.length > 0 || order
                                  ? `${String(
                                      countProm(arrayPromDataRange, order?.summa-orderOptionsCost)
                                    ).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`
                                  : `${String(+0).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}
                              </div>
                            </div>
                            {/* <div className="text">{`${String(+order?.summa).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          " "
                        )} ₽`}</div> */}
                          </div>
                          <div className="price">
                            <div className="text">Доп. опции:</div>
                            <div className="text">{`${String(+orderOptionsCost).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              " "
                            )} ₽`}</div>
                          </div>
                          <div className="price">
                            <div className="text-b">Всего:</div>
                            {/* <div className="text-b">{`${String(
                          +order?.summa + +orderOptionsCost
                        ).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}</div> */}
                            <div className="text-b">
                              {arrayPromDataRange?.length > 0 || order
                                ? `${String(countProm(arrayPromDataRange, order?.summa)).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    " "
                                  )} ₽`
                                : `${String(+0).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}
                            </div>
                          </div>
                        </div>
                        <div className="l" />
                        <div className="info">
                          {order?.status?.id === 1 ? (
                            <Button
                              loading={loading}
                              disabled={loading}
                              typebtn="bright"
                              className="large btn-continue"
                              onClick={async () => {
                                setLoading(true);
                                await postOrderPay(token, order?.id, {
                                  summa: +order?.summa,
                                  debugmode: 0,
                                })
                                  .then((e) => {
                                    if (e.data.result.status === "SUCCESS") {
                                      window.location.href = e.data.result?.paymentResult?.url;
                                    }
                                    setLoading(false);
                                  })
                                  .catch((e) => {
                                    setOpenToast(true);
                                    setMessageToast({
                                      text:
                                        typeof e.response.data.errors === "string"
                                          ? e.response.data.errors
                                          : "Ошибка оплаты",
                                      type: "error",
                                    });
                                    setLoading(false);
                                  });
                              }}
                            >
                              К оплате <Icons icon="arrow-narrow-right" />
                            </Button>
                          ) : (
                            <div className="line">
                              <div className="subtext">Дата и время оплаты</div>
                              <div className="text">{formatDate(order?.created_at)}</div>
                            </div>
                          )}
                        </div>
                        {(order?.status_id === 2 || order?.status_id === 1) && (
                          <Button
                            typebtn="outline"
                            className="medium"
                            onClick={() => {
                              if (order?.status_id === 2) {
                                dispatch(setOpenModalCancelOrders({ open: true, el: order }));
                              } else if (order?.status_id === 1) {
                                setDelEl(order);
                                setModalDeleteOrder(true);
                              }
                            }}
                          >
                            <Icons icon="x" />
                            Отменить тур
                          </Button>
                        )}
                        <div className="link-event">Детали тура в PDF на момент заказа</div>
                        <div
                          className="link-event"
                          onClick={() => {
                            dispatch(setOpenModalCreateReport(true));
                          }}
                        >
                          Сообщить о проблеме
                        </div>
                      </>
                    ) : (
                      <div className="loading">
                        <ProgressSpinner
                          className="loading-btn"
                          style={{ width: "48px", height: "48px" }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="contacts">
                    <div className="title">Контакты</div>
                    <div className="info">
                      <div className="line">
                        <div className="subtext">Компания</div>
                        <div className="text">{`${element?.operator?.name_legal}`}</div>
                      </div>
                      <div className="line">
                        <div className="subtext">Телефон</div>
                        <div className="text">{`${element?.operator?.phone}`}</div>
                      </div>
                      <div className="line">
                        <div className="subtext">Email</div>
                        <div className="text-link">{`${element?.operator?.email}`}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="races-cont">
                  <div className="races-rate">
                    <Rating rate={element?.rating?.[0]?.avg ? element?.rating?.[0]?.avg : 0} />
                    <div className="value">
                      {element?.rating?.[0]?.avg ? element?.rating?.[0]?.avg : 0}
                    </div>
                    <div className="reviews">{`${reviews?.length} отзывов`}</div>
                  </div>
                  <div className="cont-select">
                    <div className="title-select">Выберите даты</div>
                    <Select
                      value={dateRange || ""}
                      array={races}
                      typesel="medium"
                      optionLabel="range"
                      className="medium input-create-oper-user"
                      onChange={(e) => setDateRange(e.target.value)}
                      placeholder="Выберите даты..."
                    />
                  </div>
                  <div className="ld" />
                  <AlertBox
                    type="info"
                    text={`В стоимость входит до ${element?.number_travelers} участников`}
                    noClose
                  />
                  <div className="info-cost">
                    <div className="cost">
                      <div className="title-cost">Стоимость:</div>
                      <div className="row-price">
                        {arrayPromDataRange?.length > 0 && dateRange ? (
                          <div className="last-price">{`${String(+dateRange?.summa).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            " "
                          )}`}</div>
                        ) : undefined}
                        <div
                          className={`val-cost ${
                            arrayPromDataRange?.length > 0 && dateRange ? "promotion" : ""
                          }`}
                        >
                          {dateRange
                            ? `${String(countProm(arrayPromDataRange, dateRange?.summa)).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                " "
                              )} ₽`
                            : `${String(+0).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}
                        </div>
                      </div>
                      {/* <div className="val-cost">{`${String(+dateRange?.summa || 0).replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        " "
                      )} ₽`}</div> */}
                    </div>
                    <div className="cost">
                      <div className="title-cost">Доп. опции:</div>
                      <div className="val-cost">{`${String(+arrOptionsCost).replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        " "
                      )} ₽`}</div>
                    </div>
                    <div className="cost">
                      <div className="total">Всего:</div>
                      {/* <div className="total">{`${
                        dateRange?.summa?.replace(/\B(?=(\d{3})+(?!\d))/g, " ") || 0
                      } ₽`}</div> */}
                      <div className="total">{`${String(
                        countProm(arrayPromDataRange, dateRange?.summa) + +arrOptionsCost || 0
                      ).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`}</div>
                    </div>
                  </div>
                  <Button
                    disabled={!dateRange || element?.number_travelers < 1}
                    typebtn="bright"
                    className="large btn-continue"
                    onClick={() => {
                      let option = "";
                      if (arrOptions?.length) {
                        for (let i = 0; i < arrOptions?.length; i++) {
                          option += `&option=${arrOptions[i].id}`;
                        }
                      }
                      history.push(
                        `/checkout?id=${urlSearch.id}&race=${dateRange?.id}${
                          arrOptions?.length ? `${option}` : ""
                        }`
                      );
                    }}
                  >
                    продолжить
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="l" />
          <div className="search-reg">
            <div className="title">Ещё туры в этом регионе</div>
            <div className="no-array">
              <img src={backEmpty} className="back-img" />
              <div className="content">
                <div className="text">Туры не найдены</div>
              </div>
            </div>
          </div>
          <div className="l" />
          {/* <div className="similar-reg">
            <div className="title">Похожие направления</div>
          </div> */}
        </div>
        <Footer />
      </div>
      <IonPopover
        class="popover-menu-el-list"
        isOpen={openPopover}
        onWillDismiss={() => setOpenPopover(false)}
        trigger={openPopover !== false ? `bottom-start-d` : undefined}
        side="bottom"
        alignment="start"
      >
        <div className="body-popover">
          {menu.map((el, i) => {
            return (
              <div
                key={i}
                className="cont-el-pop"
                href={el.href}
                onClick={() => {
                  if (el.href) {
                    window.open(el.href);
                    setOpenPopover(false);
                  } else {
                    navigator.clipboard
                      .writeText(`${URL_ORIGIN}tour?id=${urlSearch.id}`)
                      .then(function () {
                        setOpenToast(true);
                        setMessageToast({
                          text: "Ссылка успешно скопирована в буфер обмена",
                          type: "success",
                        });
                        setOpenPopover(false);
                      });
                  }
                }}
              >
                <Icons icon={el?.icon} />
                <div className="text">{el?.name}</div>
              </div>
            );
          })}
        </div>
      </IonPopover>
      <Toast
        open={openToast}
        onClose={setOpenToast}
        message={messageToast.text}
        type={messageToast.type}
      />
      <ClientModalCreateQuestion />
      <ClientModalCreateReport />

      <ClientModalCancelOrders />
      <ModalDelete
        title="Отмена тура"
        text="Действительно хотите отменить тур?"
        btn_delete="Да, отменить"
        open={modalDeleteOrder}
        onClose={setModalDeleteOrder}
        loading={loadbtndel}
        disabled={loadbtndel}
        onClick={() => {
          setLoadbtndel(true);
          let forSend = {
            reason_cancel: "Отмена заказа клиентом",
          };
          getCancelOrder(token, delEl?.id, forSend)
            .then(async (e) => {
              if (!e.data.errors) {
                history?.push("/client");
                // getOrdersClient(token).then((q) => {
                //   dispatch(setOrders(q.data.data));
                //   setModalDeleteOrder(false);
                //   setDelEl(null);
                //   setLoadbtndel(false);
                // });
              } else {
                setLoadbtndel(false);
                setOpenToast(true);
                setMessageToast({
                  text: typeof e.data.errors === "string" ? e.data.errors : "Ошибка отмены тура",
                  type: "error",
                });
              }
            })
            .catch((e) => {
              setLoadbtndel(false);
              setOpenToast(true);
              setMessageToast({
                text:
                  typeof e.response.data.errors === "string"
                    ? e.response.data.errors
                    : "Ошибка отмены тура",
                type: "error",
              });
            });
        }}
      />
    </IonContent>
  );
};

export default ShowTourClient;
